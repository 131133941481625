<template>
  <v-container>
    <v-card elevation="3" class="ma-2">
      <v-toolbar flat color="style-color-main-gray" :extended="$vuetify.breakpoint.smAndDown" dense>
        <v-toolbar-title class="text-wrap">
          Разница учебных планов
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <template v-slot:extension v-if="$vuetify.breakpoint.smAndDown">
          <v-spacer></v-spacer>
        </template>
        <v-toolbar-items>
          <tooltip-btn tooltip="Полная информация" icon="info" small bottom @click="openDialog(modals.detail)"
                       v-if="isAdmin && difference.loaded" :loading="difference.loading"></tooltip-btn>
          <tooltip-btn tooltip="Указать связь" icon="link" small bottom @click="openDialog(modals.link)"
                       v-if="!difference.linked" :loading="difference.loading"></tooltip-btn>
          <tooltip-btn tooltip="Очистить связь" icon="link_off" small bottom @click="openDialog(modals.clear)"
                       v-if="difference.linked" :loading="difference.loading"></tooltip-btn>
          <tooltip-btn tooltip="Обновить данные" icon="update" small bottom @click="openDialog(modals.update_related)"
                       v-if="difference.linked" :loading="difference.loading"></tooltip-btn>
          <tooltip-btn tooltip="Провести поиск связей по дереву" small icon="find_replace" bottom :loading="difference.loading"
                       @click="openDialog(modals.restore_by_tree)" v-if="difference.linked"></tooltip-btn>
          <tooltip-btn tooltip="Обновить идентификатор связи у связанных" small icon="dataset_linked" bottom :loading="difference.loading"
                       @click="openDialog(modals.update_relation_identity)" v-if="difference.linked"></tooltip-btn>
          <tooltip-btn tooltip="Обновить информацию" icon="refresh" small bottom :loading="difference.loading"
                       @click="loadDifference(true)" v-if="difference.linked"></tooltip-btn>
          <tooltip-btn tooltip="Обновить дерево" icon="published_with_changes" small bottom :loading="difference.loading"
                       @click="loadDifference(false)" v-if="difference.linked"></tooltip-btn>
        </v-toolbar-items>
        <link-action
          v-if="!difference.linked"
          v-model="modals.link.open"
          :item="difference"
          :selector-url="modals.link.selector_url"
          :action-url="modals.link.action_url"
          @reload="loadDifference"
          restorable
        >
        </link-action>
        <action-for-tree
          v-if="difference.linked"
          v-model="modals.restore_by_tree.open"
          level="curriculum"
          :action-url="modals.restore_by_tree.action_url"
          action-name="поиска и сопоставления по дереву объектов"
          :item="difference.data"
          @reload="loadDifference"
        >
          <template v-slot:title>Поиск и сопоставление объектов</template>
        </action-for-tree>
        <action-for-tree
          v-if="difference.linked"
          v-model="modals.update_relation_identity.open"
          level="curriculum"
          :action-url="modals.update_relation_identity.action_url"
          action-name="обновления информации о связи в ЭИОС по дереву объектов"
          :item="difference.data"
          @reload="loadDifference"
        >
          <template v-slot:title>Обновление информации о связях в дереве</template>
        </action-for-tree>
        <update-related
          v-if="difference.linked"
          v-model="modals.update_related.open"
          :action-url="modals.update_related.action_url"
          :item="difference.data"
          @reload="loadDifference(true)"
        ></update-related>
        <action-for-tree
          v-if="difference.linked"
          v-model="modals.clear.open"
          level="curriculum"
          :action-url="modals.clear.action_url"
          action-name="очистка информации о связи в ЭИОС и синхронизаторе с 1С Университет по дереву объектов"
          :item="difference.data"
          @reload="cleaned"
          applyable
        >
          <template v-slot:title>Очистка информации о связях в дереве</template>
        </action-for-tree>
        <detail v-model="modals.detail.open" :item="difference"></detail>
      </v-toolbar>
      <v-progress-linear indeterminate :active="difference.loading" absolute></v-progress-linear>
      <Curriculum
        :loaded="difference.loaded"
        :curriculum.sync="difference.data"
        v-if="difference.linked"
        @refresh="loadDifference"
        :loading="difference.loading"
      ></Curriculum>
      <v-card-text v-else class="text-left">
        <v-card-title>
          Учебный план не связан с объектом 1С.
        </v-card-title>
      </v-card-text>
    </v-card>
    <!--    <DebugJsonComponent :json="curriculum.data.is_system"></DebugJsonComponent>-->
  </v-container>
</template>

<script>
import urls from "@/modules/curriculumEditor/urls";
import {loadData} from "@/helper";
import names from "@/modules/curriculumEditor/routers/names";
import Curriculum from './Curriculum.vue'
import selectors from "@/urls/selectors";
import UpdateRelated from "@/modules/administrative/dialogs/UpdateRelated.vue";
import ActionForTree from "@/modules/curriculumEditor/pages/difference/dialogs/ActionForTree.vue";
import LinkAction from "@/modules/administrative/dialogs/LinkAction.vue";
import Detail from "@/modules/administrative/dialogs/Detail.vue";
import TooltipBtn from "@/modules/administrative/components/TooltipBtn.vue";
import DifferenceItemMixin from "@/modules/administrative/mixins/DifferenceItemMixin";
import admin_urls from "@/urls/admin";

export default {
  name: "Difference",
  components: {Curriculum, UpdateRelated,  ActionForTree, LinkAction, Detail, TooltipBtn},
  // mixins: [DifferenceItemMixin],
  data () {
    return {
      router_name: names.CURRICULUM.DETAIL,
      difference: {
        id: this.$route.params.curriculum_id,
        data: {},
        loading: false,
        loaded: false,
        linked: true
      },
      modals: {
        link: {
          open: false,
          action_url: urls.NAMES.EDITOR.UNIVERSITY.LINK.CURRICULUM,
          selector_url: selectors.SELECTORS.UNIVERSITY.CURRICULUM( {'relation_is_empty': true})
        },
        restore_by_tree: {
          open: false,
          action_url: urls.NAMES.EDITOR.UNIVERSITY.RESTORE_FOR_TREE.CURRICULUM
        },
        update_relation_identity: {
          open: false,
          action_url: urls.NAMES.EDITOR.UNIVERSITY.UPDATE_RELATION_IDENTIFY.CURRICULUM
        },
        update_related: {
          open: false,
          action_url: urls.NAMES.EDITOR.UNIVERSITY.UPDATE_RELATED.CURRICULUM
        },
        detail: {
          open: false
        },
        clear: {
          open: false,
          action_url: urls.NAMES.EDITOR.UNIVERSITY.CLEAR.CURRICULUM
        },
      }
    }
  },
  computed: {
    item: function () {
      return this.difference.data
    },
    isLinked: function () {
      return this.item.one !== null && this.item.one !== undefined && this.item.eios !== null && this.item.eios !== undefined
    },
    isOnlyEIOS: function () {
      return (this.item.one === null || this.item.one === undefined) && this.item.eios !== null && this.item.eios !== undefined
    },
    isAdmin: function () {
      return admin_urls.ADMIN.COURSE.IS_LIST_ALLOWED()
    }
  },

  methods: {
    getEIOSId(){
      return this.item.eios !== null ? this.item.eios.id: null
    },
    openDialog(dialog) {
      dialog.open = true;
    },
    displayError(error){
      console.log(error);
    },
    closeAll(){
      for(let k in this.modals){
        this.modals[k].open = false;
      }
    },
    loadDifference: function (without_tree=false) {
      this.opened_form = false
      this.difference.loading = true;
      let saver = (data) => {
        if(without_tree)
          data.subjects = this.difference.data.subjects;
        this.difference.data = data;
        this.difference.loading = false
        this.difference.loaded = true;
        this.difference.linked = true;
      };
      let catcher = (error) => {
        this.difference.linked = false
        this.difference.loading = false
        this.difference.loaded = false
      };
      let url = urls.NAMES.EDITOR.CURRICULUM.DIFFERENCE(this.difference.id, {'without_tree': without_tree});
      loadData(url, saver, catcher);
    },
    cleaned() {
      this.difference.loading = true;
      setTimeout(() => {
        this.difference.data = null;
        this.difference.linked = false;
        this.difference.loaded = false;
        this.difference.loading = false;
      }, 500)
    }
  },
  created() {
    this.loadDifference();
  }
}
</script>

<style scoped>

</style>
