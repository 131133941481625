<template>
  <v-card>
    <v-card-title class="primary white--text">Редактировать преподавателей</v-card-title>
    <v-btn  class="white--text" icon right absolute top @click="$emit('close')">
      <v-icon>close</v-icon>
    </v-btn>
    <v-card-text class="mx-0 px-0">
      <v-toolbar
        elevation="0"
        color="grey lighten-1"
        class="white--text"
      >
        <v-text-field
          dense
          hide-details
          label="Поиск"
          v-model="teachers.search"
        ></v-text-field>
        <v-btn icon><v-icon>search</v-icon></v-btn>
      </v-toolbar>
      <v-row class="mx-0 px-0">
        <v-col style="border-right: 1px dotted gray" class="ma-0 pa-0">
          <v-col class="ma-0 px-5 py-2 text-h6 black--text text-left grey lighten-1">Преподаватели для выбора:</v-col>
          <v-list style="height: calc(100% - 48px);" class="my-0 py-0">
            <v-data-iterator
              :search="teachers.search"
              :items="not_selected_teachers"
              class="d-flex flex-column justify-start fill-height"
              :loading="loading"
              hide-default-footer
            >
              <template v-slot:loading>
                <v-skeleton-loader
                  type="list-item"
                  v-for="i in 5"
                  :key="`loading_${i}`"
                  class="mt-3"
                >
                </v-skeleton-loader>
              </template>
              <template v-slot:item="props">
                <v-list-item class="my-0 pa-0" style="max-height: 53px; border-bottom: 1px solid black" link @click="props.item.selected = true">
                  <v-list-item-content class="px-2">
                    <v-list-item-title>
                      {{props.item.text}}
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn icon small class="mx-3">
                      <v-icon small>add
                      </v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </template>
              <template v-slot:footer="{ pagination, options, updateOptions }">
                <v-data-footer class="mt-auto" :options="options" :pagination="pagination" @update:options="updateOptions" disable-items-per-page></v-data-footer>
              </template>
            </v-data-iterator>
          </v-list>
        </v-col>
        <v-col class="ma-0 pa-0">
          <v-col class="ma-0 px-5 py-2 text-h6 black--text text-left grey lighten-1">Выбранные преподаватели:</v-col>
          <v-list class="my-0 py-0" style="height: calc(100% - 48px);">
            <v-data-iterator
              :search="teachers.search"
              :items="selected_teachers"
              class="d-flex flex-column justify-start fill-height"
              :loading="loading"
              hide-default-footer
            >
              <template v-slot:loading>
                <v-skeleton-loader
                  type="list-item"
                  v-for="i in 5"
                  :key="`loading_${i}`"
                  class="mt-3"
                >
                </v-skeleton-loader>
              </template>
              <template v-slot:item="props">
                <v-list-item class="ma-0 pa-0" style="max-height: 53px; border-bottom: 1px solid black" link>
                  <v-list-item-action  @click="props.item.selected = false">
                    <v-btn icon small class="mx-3">
                      <v-icon small>
                        remove
                      </v-icon>
                    </v-btn>
                  </v-list-item-action>
                  <v-list-item-content @click="props.item.selected = false">
                    <v-list-item-title>
                      {{props.item.text}}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template v-slot:footer="{ pagination, options, updateOptions }">
                <v-data-footer class="mt-auto" :options="options" :pagination="pagination" @update:options="updateOptions" disable-items-per-page  ></v-data-footer>
              </template>
            </v-data-iterator>
          </v-list>
        </v-col>
      </v-row>
      <v-row class="mx-0 px-0">
        <v-col>
          <v-btn color="success" @click="saveLinkedTeachers" :disabled="loading" :loading="loading">Сохранить</v-btn>
        </v-col>
      </v-row>
    </v-card-text>
    <v-snackbar v-model="save_process.alert.visible" right :color="save_process.success?'success':save_process.success !== undefined?'warning':'info'" timeout="3000" top>
      {{save_process.alert.message}}
      <template v-slot:action>
        <v-btn icon @click="save_process.alert.visible = false">
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>

import {loadData, sendPostRequest} from "@/helper";
import cathedras from "@/urls/cathedras";
import selectors from "@/urls/selectors";
import {generateListUidToName} from "@/helper/uidToName";

export default {
  name: "LinkedTeachers",
  props: {
    student_id: {
      type: Number,
      default: undefined,
    },
    subject_id: {
      type: Number,
      default: undefined,
    },
    selected: {
      type: Array,
    },
  },
  data: function () {
    return {
      teachers: {
        data: [],
        search: '',
      },
      loading: false,
      errors: [],
      save_data: undefined,
      save_process: {
        success: undefined,
        errors: {
          data: [],
          getIndexError(teacher_uid) {
            return this.data.findIndex(el => el.uid === teacher_uid);
          },
          hasError(teacher_uid) {
            return this.getIndexError(teacher_uid) !== -1;
          },
          getError(teacher_uid, field) {
            let index = this.getIndexError(teacher_uid);
            if(index !== -1) return this.data[index].message[field];
            else return '';
          },
        },
        alert: {
          visible: false,
          message: ''
        },
      },
    }
  },
  computed: {
    not_selected_teachers(){
      return this.teachers.data.filter(el => {
        return !el.selected
      })
    },
    selected_teachers(){
      return this.teachers.data.filter(el => {
        return el.selected
      })
    },
  },
  methods: {
    loadLinkedTeachers() {
      this.loading = true;
      loadData(
        selectors.SELECTORS.WORKERS.CATHEDRA_PPS(this.$route.params.cathedra_uid),
        (data) => {
          this.teachers.data = data
          this.teachers.data.forEach(el => {
            this.$set(el, 'id', undefined)
            this.$set(el, 'fio', '')
            this.$set(el, 'selected', false)
            this.$set(el, 'was_selected', false)
            this.$set(el, 'user', el.text.split(',')[0].trim());
            let id = this.selected.find(fel => {
              return fel.uid === el.user
            });
            if (id !== undefined) {
              el.id = id.id;
              el.selected = true;
              el.was_selected = true;
            }
            el.text = el.text.split(',')[1].trim();
          });

          let uniqueSerials = []
          let uniqueSerialsSelect = []
          let  selected_uidSelect =  Array.from(new Set(this.selected_teachers.map(item => item.user)));
          selected_uidSelect.forEach(el => {
            let ss =this.teachers.data.filter(obj => {
              return obj.user === el
            })
            uniqueSerialsSelect.push(ss[0])
          });
          let not_selected_uidSelect =  Array.from(new Set(this.not_selected_teachers.map(item => item.user)));
          not_selected_uidSelect.forEach(el => {
            let ff =this.teachers.data.filter(obj => {
              return obj.user === el
            })
            uniqueSerials.push(ff[0])
          });
          this.teachers.data =uniqueSerialsSelect.concat(uniqueSerials)

          generateListUidToName(this.teachers.data, 'user', 'fio', (data) => {
            data.forEach(el => {
              el.text = `${el.fio}, ${el.text}`
            });
            this.loading = false;
          })
        },
      )
    },
    saveLinkedTeachers(){
      this.loading = true
      let data = {
        teachers : [],
        semester: this.subject_id,
      }
      this.teachers.data.forEach(el => {
        if(!(!el.selected && !el.was_selected)){
          data.teachers.push(el.user)
        }
      })
      if(data.teachers.length > 0) {
        sendPostRequest(
          cathedras.CATHEDRA.INDIVIDUAL_STUDENT.TEACHERS_LINK_SAVE(
            this.$route.params.cathedra_uid,
            this.student_id
          ),
          data,
          (res) => {
            this.loading = false
            if (res.errors === undefined) {
              this.save_process.success = true
              this.save_process.alert.visible = true
              this.save_process.alert.message = 'Успешно сохранено'
              this.$emit('saved');
            } else {
              this.save_process.errors.data = res.errors
              this.save_process.success = false
              this.save_process.alert.visible = true
              this.save_process.alert.message = 'В процессе сохранения были ошибки'
            }
          },
        );
      }
      else{
        this.loading = false
        this.save_process.success = undefined
        this.save_process.alert.visible = true
        this.save_process.alert.message = 'Нет изменений для сохранения'
      }
      this.save_data = data
    },
  },
  created() {
    this.loadLinkedTeachers()
  }

}
</script>

<style scoped>

</style>
