<template>
  <v-container v-if="is_self_route">
    <v-card>
      <v-toolbar :extended='$vuetify.breakpoint.smAndDown'
                  elevation="0"
                  class="white--text"
                  color="style-color-main-gray"
                 :style="{'height':`${$vuetify.breakpoint.mdAndUp ? 64: 140}px`} "
        >
        <template v-if="$vuetify.breakpoint.mdAndUp" >
          <v-text-field
            dense
            class="pa-6"
            placeholder="Поиск"
            v-model="search.value"
            @change="searchData"
            messages="Для поиска нажмите Enter или кнопку поиск"
          >
          </v-text-field>
          <v-spacer></v-spacer>
          <v-toolbar-title>
            <v-select
              class="pa-4"
              :items="filter.items"
              v-model="archive"
              hide-details
              label="Фильтр по активности"
            ></v-select>
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </template>

        <template v-slot:extension v-if='$vuetify.breakpoint.smAndDown'>
          <v-col>
            <v-toolbar-title>
              <v-select
                :items="filter.items"
                v-model="archive"
                hide-details
                label="Фильтр по активности"
              ></v-select>
            </v-toolbar-title>

            <v-text-field
              dense
              placeholder="Поиск"
              v-model="search.value"
              @change="searchData"
              messages="Для поиска нажмите Enter или кнопку поиск"
            >
            </v-text-field>
          </v-col>
        </template>


      </v-toolbar>

      <v-card-text>
        <v-data-table
          :headers="groups.headers"
          :items="groups.items"
          :loading="pagination.loading"
          :items-per-page.sync="pagination.size"
          :items-per-page-options="[10, 20, 30]"
          no-data-text="Группы не загружены"
          no-results-text="Группы не были найдены"
          :page.sync="pagination.page"
          :server-items-length="pagination.count"
          :footer-props="{
          'items-per-page-all-text': 'Все',
          'items-per-page-options': [10, 25, 50, 100]
        }"
        >
          <template v-slot:item="props">
            <tr class="text-left"  v-if="pagination.loading">
              <td :colspan="props.headers.length">
                <v-skeleton-loader width="100%" type="table-row"></v-skeleton-loader>
              </td>
            </tr>
            <tr v-else class="text-left">
              <td class="text-left">
                <router-link :to="makeGroupRouteLink(props.item.id)">
                  {{ props.item.litter }}-{{ props.item.course }}{{ props.item.number }}
                </router-link>
              </td>
              <td class="text-left">{{ props.item.level }}</td>
              <td class="text-left">{{ props.item.form }}</td>
              <td class="text-left">{{ props.item.cathedra }}</td>
            </tr>
          </template>
          <template v-slot:loading>
            <v-skeleton-loader type="table-row" v-for="i in pagination.size" :key="`skeletor_${i}`"></v-skeleton-loader>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <ApplyDialog
      :opened="actions.moveToNext.dialogs.applier"
      :get-text="() => `Вы уверены что хотие перевести все группы не окончившее обучение`"
      @close="allMoveToNextCourse($event)"></ApplyDialog>
    <MsgDialog
    :opened="actions.moveToNext.dialogs.result"
    :title="actions.moveToNext.resultText"
    :success="!actions.moveToNext.result.length"
    :warning="!!actions.moveToNext.result.length"
    @close="actions.moveToNext.dialogs.result = false"
    >
      <div>
        <template v-for="(item, index) in actions.moveToNext.result">
          <v-alert
            color="info"
            outlined
            :key="`msg_move_to_next_${index}`">
            <v-layout>
              <v-flex>
                <router-link :to="makeGroupRouteLink(item.id)">
                  {{ item.name }}
                </router-link>
              </v-flex>
              <v-flex>
                {{ item.message }}
              </v-flex>
            </v-layout>
          </v-alert>
        </template>
      </div>
    </MsgDialog>
  </v-container>
  <GroupBase v-else></GroupBase>
</template>

<script>
  import urls from "@/urls/management";
  import {loadData, checkFieldTrue} from "@/helper";
  import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
  import ApplyDialog from "@/modules/core/components/ApplyDialog";
  import MsgDialog from "@/modules/core/components/MsgDialog";
  import GroupBase from "../group/Base";
  import DebugJsonComponent from "@/modules/core/components/DebugJsonComponent";
  import names from "@/modules/educationalManagement/routers/names";
  import OneFieldBaseSearchMixin from "@/mixins/OneFieldBaseSearchMixin";

  export default {
    name: "ActualGroupList",
    components: {
      ApplyDialog,
      MsgDialog,
      GroupBase,
      DebugJsonComponent
    },
    mixins: [
      PaginatedDataMapperMixin, OneFieldBaseSearchMixin
    ],
    computed: {
      loadingData: function () {
        return checkFieldTrue(this.groups, 'loading')
      },
      is_self_route: function() {
        return this.$route.name === names.EDUCATIONAL.GROUP.LIST
      }
    },
    data: function(){
      return {
        title: "Список групп",
        loadErrorMsg: '',
        actions: {
          moveToNext: {
            dialogs: {
              applier: false,
              result: false,
            },
            resultText: "",
            result: []
          }
        },
        archive: false,
        filter: {
          items: [
            {text:'Отобразить архивные',value:true},
            {text:'Отобразить не архивные',value:false},
            {text:'Отобразить все',value:null}
          ]
        },
        groups: {
          items: [],
          headers: [
            {text: "Название", sortable: false, align: 'left'},
            {text: "Уровень", sortable: false, align: 'left'},
            {text: "Форма", sortable: false, align: 'left'},
            {text: "Кафедра",sortable: false, align: 'left'},
          ],
        },
        search: {
          parameter: 'name',
        },
      }
    },

    watch: {
      archive: function() {
        let url = this.pagination.current;
        url = this.addGetParameter(url, 'archive', this.archive)
        this.loadData(url)
      }
    },
    methods: {
      makeGroupRouteLink(id){
        return {name: names.EDUCATIONAL.GROUP.DETAIL, params: {group_id: id}}
      },
      loadData(url=undefined){
        this.setLoading();
        if(url === undefined) {
          url = urls.EDUCATION.GROUP.LIST();
          if(this.archive !== null)
            url = this.addGetParameter(url, 'archive', this.archive)
        }
        loadData(url, (data) => {
          this.groups.items = data.results;
          this.mapPagination(data);
          this.setNotLoading();
          this.setCurrentLoadUrl(url)
        });
      },
      allMoveToNextCourse: function (apply) {
        this.actions.moveToNext.dialogs.applier = false;
        if(apply) {
          let url = urls.EDUCATION.GROUP.ALL_TO_NEXT_COURSE();
          let saver = (data) => {
            this.actions.moveToNext.result = data.errors;
            if(data.errors.length)
              this.actions.moveToNext.resultText = 'Некоторые из групп не были переведены';
            else
              this.actions.moveToNext.resultText = 'Все группы успешно переведны';
            this.actions.moveToNext.dialogs.result = true;
          };
          loadData(url, saver);
        }
      }
    },
    created() {
      this.$store.commit('mainpage/changeTitle', this.title);
      this.loadData();
    }
  }
</script>

<style scoped>

</style>
