import { render, staticRenderFns } from "./DetailTemplate.vue?vue&type=template&id=5602f7d1&scoped=true"
import script from "./DetailTemplate.vue?vue&type=script&lang=js"
export * from "./DetailTemplate.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5602f7d1",
  null
  
)

export default component.exports