<template>
  <AdmissionTemplate :admission="admission" :btnPrint="true" ref="admission">
    <template v-slot:append_body>
      <ApplyDialog
        v-if="printDialog"
        :opened="printDialog"
        :get-text="() => `Напечатать допуск?`"
        :apply-text="`Да`"
        :close-text="`Нет`"
        @close="printAdmission"
      >
      </ApplyDialog>
      <v-card-actions class=" d-flex justify-end" v-if="!admission.closed || admission.reopen">
        <v-btn v-if="admission.reopen"
               :loading="loading"
               @click="reopenAdmission"
               text class="primary">
          Переоткрыть допуск
        </v-btn>
        <v-btn v-if="admission.success == null" text class="primary" @click="opened_form = true">
          Закрытие допуска обучающегося
        </v-btn>
        <scroll-center-dialog @input="closedForm"
                              v-model="opened_form"
                              width="500"
                              @close="opened_form = false"
                              title="Закрытие допуска"
                              :dennyable="false"
                              :applyable="false"
        >
          <v-alert class="pa-2" type="info" outlined>
            Обратите внимание!
            В строке "Балл" необходимо указать набранные баллы за допуск
          </v-alert>

          <v-card-text class="px-0 pa-2 text-left subtitle-1 font-weight-bold" >
            Баллов в семестре: {{admission.in_semester}}
          </v-card-text>
          <v-text-field v-model="in_admission"
                        type="number"
                        label="Балл"
                        :error="hasError('total')"
                        :error-messages="getError('total')"
          ></v-text-field>
          <v-card-text class="px-0 pa-2 text-left subtitle-1 font-weight-bold" >
            Итоговый балл: {{all}}
          </v-card-text>
          <v-text-field v-model="value.officially_closed"
                        type="Date"
                        label="Дата сдачи"
                        :error="hasError('officially_closed')"
                        :error-messages="getError('officially_closed')">
          </v-text-field>
          <v-text-field v-model="value.comment"
                        label="Комментарий"
                        :error="hasError('comment')"
                        :error-messages="getError('comment')">
          </v-text-field>

          <v-alert v-if="hasError('error')">{{ getError('error') }}</v-alert>
          <v-alert class="pa-2" type="warning" outlined>
            <b><span>{{admission.type}} </span> будет закрыт</b>
          </v-alert>
          <v-alert class="pa-2 " type="info" outlined>
            В случае ошибки Вы можете переоткрыть и исправить допуск в течение суток с момента закрытия
          </v-alert>
          <template v-slot:append-actions>
            <v-card-text class="px-0">
              <v-row>
                <v-btn @click="closeAdmission()" :loading="loading" block class="primary">
                  Закрыть
                </v-btn>
              </v-row>
            </v-card-text>
          </template>
        </scroll-center-dialog>
      </v-card-actions>
    </template>
  </AdmissionTemplate>
</template>

<script>
import AdmissionTemplate from '@/modules/templates/admission/AdmissionTemplate.vue'
import {makeGetRequest, makePostRequest, sendGetRequest} from "@/helper";
import urls from "@/modules/teacher/urls";
import {generateUidToName} from "@/helper/uidToName";
import UserDataMixin from "@/mixins/UserDataMixin";
import names from "@/modules/teacher/routers/names";
import UserDataFormMixin from "@/mixins/UserDataFormMixin";
import ScrollCenterDialog from "@/modules/core/components/ScrollCenterDialog";
import ApplyDialog from "@/modules/core/components/ApplyDialog";
import teacher from "@/urls/teacher";

export default {
  name: "DetailAdmission",
  components: {
    ScrollCenterDialog,
    ApplyDialog,
    AdmissionTemplate,
  },
  mixins: [UserDataMixin,UserDataFormMixin],
  data: function () {
    return {
      admission: undefined,
      in_admission: 0,
      printDialog:false,
      loading:false,
      value:{
        comment: "",
        total: 0,
        officially_closed: new Date().toISOString().substring(0,10),
      }
    }
  },
  computed:{
    all(){
      let total = 0
      try {
        total = Number(this.in_admission) + Number(this.admission.in_semester)
      } catch {
        total = 0
      }
      return total
    }
  },
  methods: {
    printAdmission(apply) {
      if(apply){
      this.loadData(()=>{
        this.$nextTick(() => {
          setTimeout(() => {
            this.$refs.admission.PrintAdmission()
            this.printDialog=false
            this.$router.push({name: names.ADMISSIONS.LIST})
          }, "1000");
        });
      })
      }else {
        this.$router.push({name: names.ADMISSIONS.LIST})
      }

    },
    loadData(finalizer=()=>{} ){
      let url = new URL(urls.getUrl('teacher|individual|tolerance-detail', 'retrieve')
        .replace('<id>', this.$route.params.idAdmission))
      let params = {};
      url.search = new URLSearchParams(params).toString();
      let sys = true;
      makeGetRequest(url).then(resp => {
        if (!resp.ok) {
          sys = false
        }
        return resp.json()
      }).then(json => {

        this.admission = json
        this.$set(this.admission, "fio_teacher", "")
        generateUidToName(this.admission, "teacher", "fio_teacher")

        this.$set(this.admission, "fio_student", "")
        generateUidToName(this.admission, "student", "fio_student")
        this.loading=false
      }).then(json =>{
        finalizer(json)
      })
    },
    reopenAdmission(){
      this.loading=true
      let url = teacher.URLS.ADMISSION.REOPEN(this.admission.id)
      sendGetRequest(url,
        ()=>{
          this.loading=true
          this.loadData()
        },
        (error)=>{
          this.loading=false
          alert(error.error)
        })
    },
    closeAdmission() {
      this.value.total =this.all
      this.loading=true
      let url = new URL(urls.getUrl('teacher|individual|tolerance-close', 'close')
        .replace('<id>', this.$route.params.idAdmission))
      let status = true
      makePostRequest(url, this.value)
        .then(resp => {
          this.loading=false
          if (!resp.ok) {
            status = false
          }
          return resp.json();
        })
        .then(json => {
          if (status) {
            this.printDialog=true
          } else {
            this.receiveErrors(json);
          }
        })
    },

  },
  created() {
    this.loadData()
  }
}
</script>

<style scoped>

</style>
