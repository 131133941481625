import store from "@/store";
import {makeGetRequest} from "@/helper/index";

function download(content, fileName, contentType) {
  var a = document.createElement("a");
  a.href = URL.createObjectURL(content);
  a.download = fileName;
  a.click();
}

export function loadFile(links,typeFile="application/pdf"){
  const type = typeFile;
  const name = links.file_name;
  const link = links.link;
  let status = true
  let url = process.env.VUE_APP_API_HOST
    + process.env.VUE_APP_EDUCATION_PREFIX.replace('/', '') +
    (store.getters['urls/getPattern'](
      process.env.VUE_APP_EDUCATION_PREFIX.replace('/', ''),
      link.basename,
      link.action
    ));

  for(let el of Object.keys(link.params)){
    url = url.replace('<'+el+'>',link.params[el])
  }

  makeGetRequest(
    url
  ) .then(resp => {
    if (!resp.ok) {
      status = false
      alert("Не удалось загрузить файл")
    }
    return resp.blob();
  }) .then(blob => {
    if(status)
    download(blob, name, type);
  })

}
