<template>
  <div>
    <v-toolbar flat height="45px" class="body-1" color="style-color-main-gray">
      <v-toolbar-title>Виды занятий, входящие в дисциплину</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="openCreateForm">
        <v-icon>add</v-icon>
      </v-btn>
    </v-toolbar>
    <v-list v-if="lesson_kinds.length > 0">
      <v-list-item>
        <v-row class="text-xs-center bold">
          <v-col cols="4" class="body-2 bold">Вид занятий</v-col>
          <v-col cols="4" class="body-2 bold">Общее количество часов</v-col>
          <v-col cols="4" class="body-2 bold">Действия</v-col>
        </v-row>
      </v-list-item>
      <v-divider></v-divider>
      <template v-for="(item, index) in lesson_kinds" class="body-1">
        <v-list-item :key="`subject_list_tile_key_${index}`" >
          <v-row class="text-xs-center">
            <v-col cols="4" class="body-1 my-auto">{{item.kind}}</v-col>
            <v-col cols="4" class="body-1 my-auto">{{item.hours}}</v-col>
            <v-col cols="4" class="body-1 my-auto">
              <v-btn icon @click="openUpdateForm(item)">
                <v-icon>edit</v-icon>
              </v-btn>
              <v-btn icon @click="openCloseApplier(item)">
                <v-icon>remove</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-list-item>
        <v-divider></v-divider>
      </template>
    </v-list>
    <v-card-text class="text-left" v-else> Виды занятий не прикреплены</v-card-text>
    <Form v-if="opened" :lesson_kind="selected" :opened="opened" :semester_id="semester_id" :is_create="!('id' in selected)" @close="closeChangeForm"></Form>
    <ApplyDialog
      v-if="dialogs.delete"
      :opened="dialogs.delete"
      :get-text="() => `Вы уверены что хотие удалить вид занятий: ${selected.kind}`"
      @close="deleteLessonKind(selected, $event)"></ApplyDialog>
    <MsgDialog
      :opened="dialogs.result.opened"
      :title="dialogs.result.text"
      :success="dialogs.result.success"
      :warning="dialogs.result.warning"
      @close="dialogs.result.opened = false"
    >
      Вид занятий "<span v-if="selected">{{ selected.kind }}</span>"
      <span v-if="!dialogs.result.warning">БЫЛ</span><span v-else style="font-weight: bolder !important;">НЕ БЫЛ</span>
      удален из списка видов занятий реализуемых в рамках преподавания семестра дисциплины.
    </MsgDialog>
  </div>
</template>

<script>
  import Form from "./Form";
  import urls from "../../urls";
  import {makeDeleteRequest, setUrlParameters} from "../../../../helper";
  import ApplyDialog from "../../../core/components/ApplyDialog";
  import MsgDialog from "../../../core/components/MsgDialog";

  export default {
    name: "LessonKindList",
    components: {
      Form,
      ApplyDialog,
      MsgDialog,
    },
    props: {
      lesson_kinds: Array,
      semester_id: Number,
    },
    data(){
      return {
        opened: false,
        title: 'Дисциплины',
        dialogs: {
          delete: false,
          result: {
            opened: false,
            text: '',
            success: true,
            warning: false,
          },
        },
        selected: null,
      }
    },
    methods: {
      // closeDialog(){
      //   this.dialogs.result.opened = false
      // },
      openCreateForm(){
        this.selected = {
          kind: null,
          hours: null,
          zets: null,
        };
        this.opened = true;
      },
      openUpdateForm(selected){
        this.selected = selected;
        this.opened = true;
      },
      updatedSignal(){
        this.$emit('update');
      },
      closeChangeForm(){
        // if(updated)
        this.updatedSignal();
        this.opened = false;
      },
      openCloseApplier(selected){
        this.selected = selected;
        this.dialogs.delete = true;
      },
      deleteLessonKind(item, apply) {
        this.dialogs.delete = false;
        if (apply) {
          // let url = urls.getUrl('editor|curriculum|lesson_kind-detail', 'destroy');
          let url = urls.NAMES.EDITOR.SEMESTER.LESSON_KIND.DESTROY(this.semester_id, item.id);
          // url = setUrlParameters(url, {semester_id: this.semester_id, id: item.id});
          makeDeleteRequest(url)
            .then(resp => {
              if (!resp.ok) {
                resp.json().then((json) => {
                  throw json;
                }).catch((error) => {
                  if(error.hasOwnProperty('detail'))
                    this.dialogs.result.text = error['detail'];
                  else
                    this.dialogs.result.text = error['detail'];
                  this.dialogs.result.warning = true;
                  this.dialogs.result.success = false;
                  this.dialogs.result.opened = true;
                  this.updatedSignal();
                });
              }else{
                this.dialogs.result.text = 'Успешно удален';
                this.dialogs.result.warning = false;
                this.dialogs.result.success = true;
                this.dialogs.result.opened = true;
                this.updatedSignal();
              }
            })
        }
      }

    },
    created() {
    }
  }
</script>

<style scoped>

</style>
