<template>
  <v-container v-if="is_self_route">
    <v-row>
      <v-col cols="12" md="12">
        <v-card>
          <v-toolbar
            elevation="0"
            color="style-color-main-gray"
          >
            <v-text-field
              dense
              placeholder="Поиск"
              v-model="search.value"
              @change="searchData"
              messages="Для поиска нажмите Enter или кнопку поиск"
            >
            </v-text-field>
            <v-toolbar-items>
              <v-btn icon><v-icon>search</v-icon></v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-data-iterator
            :items="groups"
            :items-per-page.sync="pagination.size"
            no-data-text="Кафедральные группы не найдены"
            no-results-text="Кафедральные группы не найдены"
            :page.sync="pagination.page"
            :loading="pagination.loading"
            :server-items-length="pagination.count"
            :class="`${groups.length!==0 ? 'd-flex flex-row flex-wrap':''}`"
            hide-default-footer
          >
            <template v-slot:no-data>
              <v-card-text class=" text-xs-center "> Кафедральные группы не найдены </v-card-text>
            </template>
            <template v-slot:item="group">
              <v-col cols="12" md="4" lg="3">
                <v-hover
                  v-slot:default="{ hover }"
                >
                  <v-card
                    class=" ma-3"
                    :elevation="hover ? 8 : 2"
                    height="200px"
                  >
                    <router-link :to="{
                      name: names.CathedraGroupsDetail,
                      params: {
                        cathedra_uid: cathedra_uid,
                        group_id: group.item.id
                      }
                    }">
                      <div
                        class="cathedra_item_in_list" :class="[color, text_color]"
                        v-ripple
                      >
                        {{ group.item.litter }}-{{ group.item.course }}{{ group.item.number }}
                      </div>
                    </router-link>
                    <small style="position:relative;top: -75px">Численность группы - {{group.item.students_count}} обучающихся</small>
                  </v-card>
                </v-hover>
              </v-col>
            </template>
            <template v-slot:footer="{ pagination, options, updateOptions }">
              <v-col cols="12">
                <v-data-footer
                    class="mt-auto"
                    :options="options"
                    :pagination="pagination"
                    :items-per-page-options="[5,10,50]"
                    @update:options="updateOptions "  ></v-data-footer>
              </v-col>
<!--              <DebugJsonComponent :json="pagination"></DebugJsonComponent>-->
            </template>
          </v-data-iterator>
        </v-card>
      </v-col>
<!--      <DebugJsonComponent :json="pagination"></DebugJsonComponent>-->
    </v-row>
  </v-container>
  <router-view v-else></router-view>
</template>

<script>
  import {loadData} from "../../../../helper";
  import urls from '@/urls/cathedras';
  import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
  import TitledPageMixin from "@/mixins/TitledPageMixin";
  import DebugJsonComponent from "../../../core/components/DebugJsonComponent";
  import names from "../../routers/names";
  import OneFieldBaseSearchMixin from "@/mixins/OneFieldBaseSearchMixin";

  export default {
    name: "Groups",
    components: {
      DebugJsonComponent
    },
    computed: {
      is_self_route: function () {return names.CathedraGroups === this.$route.name},
    },
    mixins: [PaginatedDataMapperMixin, TitledPageMixin, OneFieldBaseSearchMixin],
    data: function(){
      return {
        page_title: 'Список групп',
        names: names,
        cathedra_uid: this.$route.params.cathedra_uid.toUpperCase(),
        groups: [],
        color: 'blue lighten-5',
        text_color: 'black--text',
        search: {
          parameter: 'name',
        },
      }
    },
    watch: {
    },
    methods: {
      loadData: function (url=undefined) {
        this.pagination.loading = true;
        if(url === undefined)
          url = urls.CATHEDRA.GROUPS.LIST.OWNED(this.cathedra_uid);
        this.pagination.current = url;
        loadData(
          url,
          (data) => {
            this.mapResults(data, 'groups');
            this.pagination.loading = false;
          }
        )
      },
    },
    created() {
      this.setPageTitleWithObject("Cathedra", {uid: this.$route.params.cathedra_uid}, ' - ')
      this.loadData();
    },

  }
</script>

<style scoped>
  .cathedra_item_in_list{
    height: 200px;
    text-align: center;
    line-height: 200px;
    font-size: 44px;
    user-select: none;
    text-decoration: underline !important;
  }
</style>
