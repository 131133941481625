<template>
  <v-container style="height: 100%">
    <v-data-iterator
      :items="filterItems"
      :loading="loading"
      :items-per-page="filterItems.length"
      :server-items-length="filterItems.length"
      hide-default-footer
      class="elevation-1"
      no-data-text="Нет данных"
    >
      <template v-slot:header>
        <v-toolbar
          class="mb-2"
          color="#DADADA"
          flat
        >
          <v-row wrap>
            <v-toolbar-title>Перечень доступных факультетов:</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field
              dense
              placeholder="Поиск по наименованию"
              v-model="search"
              hide-details
            >
            </v-text-field>
          </v-row>
        </v-toolbar>
      </template>
      <template v-slot:default="props">
        <v-container fluid>
          <v-row class="align-center">
            <v-col>
              <v-row justify="space-around"
                class="d-flex align-stretch">
                <v-col cols="12" sm="6" lg="4" v-for="(item, index) in props.items" :key="`card_${index}`">
                  <v-card
                    min-height="250"
                    elevation="3"
                    class="ma-3 d-flex"
                    color="style-color-blue-light"
                    rounded
                    @click="setDepartament(item)"
                  >
                    <v-container class="my-auto">
                      <v-row class="my-auto">
                        <v-col class="my-auto title">
                          {{item.name }}
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </v-data-iterator>
  </v-container>
</template>


<script>
  import names from "../routers/names"

  export default {
    name: "Dean",
    props: {
      items: {
        required: true,
        type: Array
      }
    },
    data() {
      return {
        total: 0,
        search:'',
        names:names,
        loading: true,
        pagination: {},
        headers: [
          {text: 'Факультет', sortable: false, align: 'justify-center', value:'name'},
        ]
      }
    },
    computed:{
      filterItems(){
        let search = this.search.toString().toLowerCase()
        let arr = this.items.filter(i =>
          search.replace(/\s+/g, '').length===0||(i.name.toLowerCase().indexOf(search) > -1)
        )
        //console.log(search)
        return arr
      },
    },
    methods: {
      setDepartament(item) {
        this.$router.push({name: names.DEPARTAMENTS.LIST, params: {idFaculty: item.uid}})
      },
      getItems() {},
    },
    watch: {
      items(){
        if(this.items.length === 1)
          this.$router.push({name: names.DEPARTAMENTS.LIST, params: {idFaculty: this.items[0].uid}})
      },
    },
    created() {
      this.$store.commit('mainpage/changeTitle', 'Список факультетов')
      if(this.items.length === 1)
        this.$router.push({name: names.DEPARTAMENTS.LIST, params: {idFaculty: this.items[0].uid}})
    },
  }


</script>
<style scoped>

</style>
