<template>
  <scroll-center-dialog v-model="opened_form" @close="closeDialog" @apply="save" title="Перезачесть семестр" width="600">

    <div class="px-2 pt-2 pb-1">
      <v-text-field
        label="Оценка"
        type="number"
        step="1"
        min="0"
        max="100"
        :error="hasError('rating')"
        :error-messages="getError('rating')"
        v-model="value.rating"
      >
      </v-text-field>
    </div>
  </scroll-center-dialog>
</template>

<script>

import ScrollCenterDialog from "@/modules/core/components/ScrollCenterDialog";
import UserDataFormMixin from "@/mixins/UserDataFormMixin";
import {sendPostRequest} from "@/helper";
import urls from "@/urls/dean"

export default {
  name: "SemesterReloadForm",
  mixins: [UserDataFormMixin],
  components: {
    ScrollCenterDialog,
  },
  props: [ 'id', 'opened_form', ],
  data() {
    return {
      value: {
        rating: 0,
      },
      errors:{},
    }
  },
  methods:{
    closeDialog() {
      this.$emit('ended', '')
    },
    save(){
      let value = {
          rating: this.value.rating,
      }
      let url = urls.DEAN.JOURNAL.SEMESTER.SEMESTER_RELOAD(
        this.$route.params.idFaculty,
        this.$route.params.idStudent,
        this.id
      )
      sendPostRequest(
        url,
        value,
        ()=>{
          this.closeDialog()
        },
        (res)=>{
          this.receiveErrors(res)
        },
      )
    }
  },
  created() {
  }
}
</script>

<style scoped>

</style>
