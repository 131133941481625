import {getUrl, getUrlAllowed, makeMediaHostUrl, getUrlWithParameters} from "@/helper/urls";
import helper from "@/urls/helper";
import {
  ACCOUNTS_INSTANCE,
  ACCOUNT_HOST,
  EDUCATION_INSTANCE,
  EDUCATION_HOST,
  ORGANIZATION_HOST,
  ORGANIZATION_INSTANCE
} from "@/helper/consts";

let getEduUrlWithParameters = helper.getEduUrlWithParameters;
let getAccountsUrlWithParameters = helper.getAccountsUrlWithParameters

export default {
  getEduUrlWithParameters,

  ACCOUNTS: {
    PROFILE:
      (user_uid) => getAccountsUrlWithParameters(
        'users|user|profile',
        'get',
        {
          user_uid: user_uid
        }
      ),
    getUrlAllowed(urlname, action) {
      return getUrlAllowed(ACCOUNTS_INSTANCE, urlname, action)
    },
  },
  WORKER: {
    USER: (gets = null) => getUrlWithParameters(
      ACCOUNT_HOST,
      ACCOUNTS_INSTANCE,
      'users|user|fio', 'post',
      {},
      gets
    ),
  },
  EDUCATION: {
    CHOICES: {
      SEMESTERS:
        (gets=null) => getEduUrlWithParameters(
          'academic_year|choices|semester',
          'get',
          {},
          gets
        ),
    },
    SELECTORS: {
      SEMESTERS:
        (group_id, gets=null) => getEduUrlWithParameters(
          'group|selectors|semester',
          'get',
          {
            group_id: group_id
          },
          gets
        ),
      CATHEDRAS:
        (gets=null) => getEduUrlWithParameters(
          'selectors|relations|cathedra',
          'get',
          {},
          gets
        )
    },
    STUDENT: {
      LIST:
        (gets=null) => getEduUrlWithParameters(
          'worker|students|education-list',
          'list',
          {},
          gets
        ),
      RETRIEVE:
        (user_id, gets=null) => getEduUrlWithParameters(
          'worker|students|education-detail',
          'retrieve',
          {
            id: user_id
          },
          gets
        ),
      FINAL_JOURNAL:
        (user_id, gets=null) => getEduUrlWithParameters(
          'worker|students|education-full-journal',
          'full_journal',
          {
            id: user_id
          },
          gets
        ),
      WORK: {
        LIST:
          (education_id, gets=null) => getEduUrlWithParameters(
            'worker|individual|work-list',
            'list',
            {
              education_id: education_id
            },
            gets
          ),
        RETRIEVE:
          (education_id, work_id, gets=null) => getEduUrlWithParameters(
            'worker|individual|work-detail',
            'retrieve',
            {
              education_id: education_id,
              id: work_id
            },
            gets
          )
      },
      CURRICULUM: {
        LIST:
          (education_id, gets=null) => getEduUrlWithParameters(
            'worker|students|individual|curriculum|semester-list',
            'list',
            {
              education_id: education_id
            },
            gets
          ),
        SEMESTER_DETAIL:
          (education_id, id, gets=null) => getEduUrlWithParameters(
            'worker|students|individual|curriculum|semester-detail',
            'retrieve',
            {
              education_id: education_id,
              id: id,
            },
            gets
          ),
        RETRIEVE:
          (education_id, gets=null) => getEduUrlWithParameters(
            'worker|students|individual|curriculum-detail',
            'retrieve',
            {
              education_id: education_id
            },
            gets
          ),
        SUBJECT: {
          LIST:
            (education_id, gets=null) => getEduUrlWithParameters(
              'worker|curriculum|semester-list',
              'list',
              {
                education_id: education_id,
              },
              gets
            ),
          RETRIEVE:
            (education_id, subject_id, gets=null) => getEduUrlWithParameters(
              'worker|curriculum|semester-detail',
              'retrieve',
              {
                education_id: education_id,
                id: subject_id
              },
              gets
            )
        }
      },
      PORTFOLIO:{
        LIST:
          (education_id, gets=null) => getEduUrlWithParameters(
            'worker|relations|student|achievement|documents-list',
            'list',
            {
              education_id: education_id,
            },
            gets
          ),
      },
      EDUDOC:{
        LIST:
          (education_id, gets = null) => getEduUrlWithParameters(
            'worker|students|education|document-list',
            'list',
            {
              education_id:education_id
            },
            gets
          ),
        CREATE:
          (education_id, gets = null) => getEduUrlWithParameters(
            'worker|students|education|document-list',
            'create',
            {
              education_id:education_id
            },
            gets
          ),
        UPDATE:
          (education_id, id, gets = null) => getEduUrlWithParameters(
            'worker|students|education|document-detail',
            'update',
            {
              id: id,
              education_id:education_id
            },
            gets
          ),
        DELETE:
          (education_id, id, gets = null) => getEduUrlWithParameters(
            'worker|students|education|document-detail',
            'destroy',
            {
              id: id,
              education_id:education_id
            },
            gets
          ),
      },
    },
    GROUP: {
      LIST:
        (gets=null) => getEduUrlWithParameters(
          'worker|group|group-list',
          'list',
          {},
          gets
        ),
      RETRIEVE:
        (group_id, gets=null) => getEduUrlWithParameters(
          'worker|group|group-detail',
          'retrieve',
          {
            id: group_id
          },
          gets
        ),
      ALL_TO_NEXT_COURSE:
        (gets=null) => getEduUrlWithParameters(
          'worker|group|group-move-to-next-course-all-groups',
          'move_to_next_course_all_groups',
          {},
          gets
        ),
      TO_NEXT_COURSE:
        (group_id, gets=null) => getEduUrlWithParameters(
          'worker|group|group-move-to-next-course',
          'move_to_next_course',
          {
            id: group_id
          },
          gets
        ),
      TO_PREV_COURSE:
        (group_id, gets=null) => getEduUrlWithParameters(
          'worker|group|group-move-to-prev-course',
          'move_to_prev_course',
          {
            id: group_id
          },
          gets
        ),
        STUDENTS:
        (group_id, gets=null) => getEduUrlWithParameters(
          'worker|group|group-get-all-student',
          'get_all_student',
          {
            id: group_id
          },
          gets
        ),
      SUBJECTS:
        (group_id, gets=null) => getEduUrlWithParameters(
          'worker|group|group-current-subject',
          'current_subject',
          {
            id: group_id
          },
          gets
        ),
      CURRENT_JOURNAL:
        (group_id, gets=null) => getEduUrlWithParameters(
          'worker|group|group-current-journal',
          'current_journal',
          {
            id: group_id
          },
          gets
        ),
    },
    CURRICULUM: {
      LIST:
        (gets=null) => getEduUrlWithParameters(
          'worker|curriculum|curriculum-list',
          'list',
          {},
          gets
        ),
      RETRIEVE:
        (curriculum_id, gets=null) => getEduUrlWithParameters(
          'worker|curriculum|curriculum-detail',
          'retrieve',
          {
            id: curriculum_id,
          },
          gets
        ),
      SUBJECT: {
        LIST:
          (curriculum_id, gets=null) => getEduUrlWithParameters(
            'worker|curriculum|subject|semester-list',
            'list',
            {
              curriculum_id: curriculum_id,
            },
            gets
          ),
        RETRIEVE:
          (curriculum_id, subject_id, gets=null) => getEduUrlWithParameters(
            'worker|curriculum|subject|semester-detail',
            'retrieve',
            {
              curriculum_id: curriculum_id,
              id: subject_id
            },
            gets
          )
      }
    },
    ACADEMIC_YEAR: {
      LIST: (gets=null) => getEduUrlWithParameters(
        'worker|academic|year|year-list',
        'list',
        {},
        gets
      ),
      DELETE: (id, gets = null) => getEduUrlWithParameters(
        'worker|academic|year|year-detail',
        'destroy',
        {
          id: id,
        },
        gets
      ),
      CREATE: (gets = null) => getEduUrlWithParameters(
        'worker|academic|year|year-list',
        'create',
        {},
        gets
      ),
      UPDATE: (id, gets = null) => getEduUrlWithParameters(
        'worker|academic|year|year-detail',
        'update',
        {
          id: id,
        },
        gets
      ),
      YEAR_SEMESTER:{
        LIST: (year_id, gets=null) => getEduUrlWithParameters(
          'worker|academic|year|semester-list',
          'list',
          {
            year_id: year_id,
          },
          gets
        ),
        DELETE: (year_id, id, gets = null) => getEduUrlWithParameters(
          'worker|academic|year|semester-detail',
          'destroy',
          {
            year_id: year_id,
            id: id,
          },
          gets
        ),
        CREATE: (year_id, gets = null) => getEduUrlWithParameters(
          'worker|academic|year|semester-list',
          'create',
          {
            year_id: year_id,
          },
          gets
        ),
        UPDATE: (year_id, id, gets = null) => getEduUrlWithParameters(
          'worker|academic|year|semester-detail',
          'update',
          {
            year_id: year_id,
            id: id,
          },
          gets
        ),
        SESSION:{
          LIST: (semester_id, gets=null) => getEduUrlWithParameters(
            'worker|academic|year|session-list',
            'list',
            {
              semester_id: semester_id,
            },
            gets
          ),
          DELETE: (semester_id, id, gets = null) => getEduUrlWithParameters(
            'worker|academic|year|session-detail',
            'destroy',
            {
              semester_id: semester_id,
              id: id,
            },
            gets
          ),
          CREATE: (semester_id, gets = null) => getEduUrlWithParameters(
            'worker|academic|year|session-list',
            'create',
            {
              semester_id: semester_id,
            },
            gets
          ),
          UPDATE: (semester_id, id, gets = null) => getEduUrlWithParameters(
            'worker|academic|year|session-detail',
            'update',
            {
              semester_id: semester_id,
              id: id,
            },
            gets
          ),
        }
      }
    },
    ADMISSION: {
      LIST: () => getEduUrlWithParameters(
        'worker|individual|tolerance-list',
        'list',
        {},
        {}
      ),
      DETAIL: (id) => getEduUrlWithParameters(
        'worker|individual|tolerance-detail',
        'retrieve',
        {
          'id': id
        },
        {}
      ),
      LOAD: (gets=null) => getEduUrlWithParameters(
        'worker|individual|tolerance-download-xlsx',
        'download_xlsx',
        {

        },
        gets
      ),
    },
    ADMISSION_REQUEST: {
      LIST: () => getEduUrlWithParameters(
        'worker|individual|tolerance|dumps-list',
        'list',
        {},
        {}
      ),
      SAVE: (uid,gets=null) => getEduUrlWithParameters(
        'worker|individual|tolerance|dumps-detail',
        'retrieve',
        {'uid':uid},
        gets
      ),
      DETAIL: (id) => getEduUrlWithParameters(
        'worker|individual|tolerance-detail',
        'retrieve',
        {
          'id': id
        },
        {}
      ),
    },
    TRAINING_DIRECTION: {
      LIST: () => getEduUrlWithParameters(
        'worker|ministry|training_direction-list',
        'list',
        {},
        {}
      ),
      CREATE:  () => getEduUrlWithParameters(
        'worker|ministry|training_direction-list',
        'create',
        {},
        {}
      ),
      DETAIL: (id) => getEduUrlWithParameters(
        'worker|ministry|training_direction-detail',
        'retrieve',
        {
          'id': id
        },
        {}
      ),
      UPDATE:  (id) => getEduUrlWithParameters(
        'worker|ministry|training_direction-detail',
        'update',
        {
          'id': id
        },
        {}
      ),
      DELETE:  (id) => getEduUrlWithParameters(
        'worker|ministry|training_direction-detail',
        'destroy',
        {
          'id': id
        },
        {}
      ),
    },
    SUBJECTS:{
      LIST: (gets=null) => getEduUrlWithParameters(
        'worker|curriculum|subjects-list',
        'list',
        {},
        gets
      ),
    },
    TRAINING_PROFILE: {
      LIST: () => getEduUrlWithParameters(
        'worker|ministry|training_profile-list',
        'list',
        {},
        {}
      ),
      CREATE:  () => getEduUrlWithParameters(
        'worker|ministry|training_profile-list',
        'create',
        {},
        {}
      ),
      DETAIL: (id) => getEduUrlWithParameters(
        'worker|ministry|training_profile-detail',
        'retrieve',
        {
          'id': id
        },
        {}
      ),
      UPDATE:  (id) => getEduUrlWithParameters(
        'worker|ministry|training_profile-detail',
        'update',
        {
          'id': id
        },
        {}
      ),
      DELETE:  (id) => getEduUrlWithParameters(
        'worker|ministry|training_profile-detail',
        'destroy',
        {
          'id': id
        },
        {}
      ),
      DIRECTION:  (id) => getEduUrlWithParameters(
        'worker|ministry|training_profile-change-direction',
        'change_direction',
        {
          'id': id
        },
        {}
      ),
    },
    PERMISSION:{
      LIST: (gets=null) => getEduUrlWithParameters(
        'worker|relations|workers-list',
        'list',
        {},
        gets
      ),
      DELETE: (id, gets = null) => getEduUrlWithParameters(
        'worker|relations|workers-detail',
        'destroy',
        {
          id: id,
        },
        gets
      ),
      СREATE: (gets = null) => getEduUrlWithParameters(
        'worker|relations|workers-list',
        'create',
        {
        },
        gets
      ),
    },
    REFERENCE:{
      SUBJECTS:{
        LIST: (gets=null) => getEduUrlWithParameters(
          'worker|core|subject-list',
          'list',
          {},
          gets
        ),
        DELETE: (id, gets = null) => getEduUrlWithParameters(
          'worker|core|subject-detail',
          'destroy',
          {
            id: id,
          },
          gets
        ),
        CREATE: (gets = null) => getEduUrlWithParameters(
          'worker|core|subject-list',
          'create',
          {},
          gets
        ),
        UPDATE: (id, gets = null) => getEduUrlWithParameters(
          'worker|core|subject-detail',
          'update',
          {
            id: id,
          },
          gets
        ),
      },
      STATE_STANDARDS:{
        LIST: (gets=null) => getEduUrlWithParameters(
          'worker|ministry|state_standard-list',
          'list',
          {},
          gets
        ),
        DELETE: (id, gets = null) => getEduUrlWithParameters(
          'worker|ministry|state_standard-detail',
          'destroy',
          {
            id: id,
          },
          gets
        ),
        CREATE: (gets = null) => getEduUrlWithParameters(
          'worker|ministry|state_standard-list',
          'create',
          {},
          gets
        ),
        DETAIL: (id) => getEduUrlWithParameters(
          'worker|ministry|state_standard-detail',
          'retrieve',
          {
            'id': id
          },
          {}
        ),
        UPDATE: (id, gets = null) => getEduUrlWithParameters(
          'worker|ministry|state_standard-detail',
          'update',
          {
            id: id,
          },
          gets
        ),
      },
      COMPETENCE:{
        LIST: (gets=null) => getEduUrlWithParameters(
          'worker|ministry|competence-list',
          'list',
          {},
          gets
        ),
        DELETE: (id, gets = null) => getEduUrlWithParameters(
          'worker|ministry|competence-detail',
          'destroy',
          {
            id: id,
          },
          gets
        ),
        CREATE: (gets = null) => getEduUrlWithParameters(
          'worker|ministry|competence-list',
          'create',
          {},
          gets
        ),
        UPDATE: (id, gets = null) => getEduUrlWithParameters(
          'worker|ministry|competence-detail',
          'update',
          {
            id: id,
          },
          gets
        ),
      },
      RATING_SYSTEM:{
        LIST: (gets=null) => getEduUrlWithParameters(
          'worker|academic|year|rating_system-list',
          'list',
          {},
          gets
        ),
        DETAIL: (id, gets=null) => getEduUrlWithParameters(
          'worker|academic|year|rating_system-detail',
          'retrieve',
          {
            id: id,
          },
          gets
        ),
        DELETE: (id, gets = null) => getEduUrlWithParameters(
          'worker|academic|year|rating_system-detail',
          'destroy',
          {
            id: id,
          },
          gets
        ),
        CREATE: (gets = null) => getEduUrlWithParameters(
          'worker|academic|year|rating_system-list',
          'create',
          {},
          gets
        ),
        UPDATE: (id, gets = null) => getEduUrlWithParameters(
          'worker|academic|year|rating_system-detail',
          'update',
          {
            id: id,
          },
          gets
        ),
      },

    },

    getUrl(urlname, action) {
      return getUrl(EDUCATION_HOST, EDUCATION_INSTANCE, urlname, action)
    },
    getUrlAllowed(urlname, action) {
      return getUrlAllowed(EDUCATION_INSTANCE, urlname, action)
    },
    makeMediaHostUrl(url){
      return makeMediaHostUrl(EDUCATION_HOST, url);
    },
    getByRecievedUrlData: (url, gets=null) => getEduUrlWithParameters(
      url.basename,
      url.action,
      url.params,
      gets
    ),
  },

}
