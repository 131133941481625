import {
  clearUserAuth,
  makeGetRequest,
  makeParticalUpdateRequest,
  makePostRequest,
  makeProfileUrl,
  makeUpdateRequest
} from "../helper";

export default {
  data() {
    return {
      URL_CREATE: '',
      URL_UPDATE: '',
      errors: {}
    }
  },
  methods: {
    getData: function () {
      return this.value;
    },
    getDataOld: function () {
      return this.Oldvalue;
    },
    setData: function (json) {
      this.value = json;
    },
    getUpdateUrl: function () {
      let url = makeProfileUrl(this.URL_UPDATE);
      url = url.replace('<id>', this.id);
      return url;
    },
    getCreateUrl: function () {
      return makeProfileUrl(this.URL_CREATE);
    },
    hasError: function (field) {
      if (
        field in this.errors
        && this.errors[field] !== undefined
        && this.errors[field] !== null
      )
        if (this.errors[field].length > 0)
          return true;
      return false;
    },
    getError: function (field) {
      if (
        field in this.errors
        && this.errors[field] !== undefined
        && this.errors[field] !== null
      )
        if (this.errors[field].length > 0)
          return this.errors[field];
      return null;
    },
    getErrorArray: function (field) {
      if (
        field in this.errors
        && this.errors[field] !== undefined
        && this.errors[field] !== null
      )
        if (this.errors[field].length > 0) {
          let err = ""
          let ar = this.errors[field]
          for (let i in ar) {
            err = err + ar[i] + "; "
          }
          return err;
        }

      return null;
    },
    getErrorArrayField: function (field, fieldAr) {
      if (field in this.errors) {
        let objArray = this.errors[field];
        if (objArray[fieldAr] !== undefined) {
          return objArray[fieldAr];
        }
      }
      return null;
    },
    receiveErrors: function (json) {
      let have = false;
      for (let key in json) {
        if (json.hasOwnProperty(key)) {
          if (Array.isArray(json[key])) {
            have = true;
          }
        }
      }
      this.errors = json;
      return have;
    },
    create: function () {
      let url = this.getCreateUrl();
      let data = this.getData();
      let status = true;
      makePostRequest(url, data)
        .then(resp => {
          if (resp.status < 299) {
            status = false;
          }
          if (resp.status === 401) {
            clearUserAuth()
          }
          return resp.json();
        })
        .then(json => {
          if (!status) {
            this.$emit('addItem', json);
          } else {
            this.receiveErrors(json);
          }
        })

    },

    updatePatch: function () {
      let url = this.getUpdateUrl();
      let status = true;
      let dataOld = this.getDataOld()
      let data = this.getData()
      let sendObj = {};
      for (var key in data) {
        if (data[key] !== dataOld[key]){
          sendObj[key] = data[key]
        }
      }
      makeParticalUpdateRequest(url, sendObj)
        .then(resp => {
          if (!resp.ok < 300) {
            status = false;
          }
          if (resp.status === 401) {
            clearUserAuth()
          }
          return resp.json();
        })
        .then(json => {
          if (!status) {
            this.$emit('refreshItem', json);
          } else {
            this.receiveErrors(json);
          }
        });
    },



    update: function () {
      let url = this.getUpdateUrl();
      let status = true;
      let data = this.getData();
      makeUpdateRequest(url, data)
        .then(resp => {
          if (resp.status < 300) {
            status = false;
          }
          if (resp.status === 401) {
            clearUserAuth()
          }
          return resp.json();
        })
        .then(json => {
          if (!status) {
            this.$emit('refreshItem', json);
          } else {
            this.receiveErrors(json);
          }
        });
    },
    apply: function () {
      if (this.id !== undefined) {
        this.update()
      } else {
        this.create()
      }
    },
    getChoices: function (url) {
      return makeGetRequest(url).then(resp => {
        return resp.json()
      }).then(json => {
        return json
      });
    },
    getObjectById: function () {
      let url = makeProfileUrl(this.URL_UPDATE);
      url = url.replace('<id>', this.id);
      makeGetRequest(url).then(resp => {
        return resp.json()
      }).then(json => {
        this.setData(json)
      });
    }
  },
  created() {
  }
}
