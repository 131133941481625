<template>
  <EducationWorks v-if="work" :EducationWorks="work">
  </EducationWorks>
</template>

<script>
import {loadData} from "@/helper";
import cathedra from "@/urls/cathedras"
import {generateUidToName} from "@/helper/uidToName";
import UserDataMixin from "@/mixins/UserDataMixin";
import DebugJsonComponent from "@/modules/core/components/DebugJsonComponent";
import EducationWorks from "@/modules/templates/work/EducationWorks";

export default {
  name: "DetailWork",
  components: {
    DebugJsonComponent,
    EducationWorks
  },
  mixins: [UserDataMixin],
  data: function () {
    return {
      work: null,
      URL_WORK_DETAIL: cathedra.CATHEDRA.TEACHERS.INDIVIDUAL_WORK.WORK_DELETE(
        this.$route.params.idWork,
        this.$route.params.cathedra_uid.toLowerCase(),
        this.$route.params.teacher_uid
      ),

    }
  },
  methods: {
    loadData( ){
      let url = this.URL_WORK_DETAIL
      loadData(url, (data) => {
          this.work = data;
          this.$set(this.work, "fio", "Работа еще никем не проверена")
          generateUidToName(this.work, "teacher", "fio")

          this.$set(this.work.document.file, "fio_student", "Не указано")
          generateUidToName(this.work.document.file, "loader", "fio_student")
        })
    },

  },
  created() {
    this.loadData()
  }
}
</script>

<style scoped>

</style>
