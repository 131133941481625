<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <span
        class="mr-1"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon v-if="item[related_parameter]" :color="colored ? 'success': null">check_circle</v-icon>
        <v-icon v-else :color="colored ? 'warning': null">cancel</v-icon>
      </span>
    </template>
    <span v-if="tooltip !== undefined">{{ tooltip }}</span>
    <span v-else-if="item[related_parameter]">Связан от {{item[`${related_parameter}_created`]}}</span>
    <span v-else>Не связан</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "RelatedLinkIndicator",
  props: {
    item: {
      required: true
    },
    related_parameter: {
      default: 'education_related'
    },
    colored: {
      default: true,
      type: Boolean
    },
    tooltip: {
      default: undefined
    }

  },
}
</script>

<style scoped>

</style>
