const state = {
  enabled: false,
  show: false,
  items: [],
  instances: [],
  currentInstance: undefined,
  activeRouter: undefined,
};

const getters = {
  getLeftMenu(state){
    return state.items
  }
}

const mutations = {
  enableLeftMenu(state) {
    state.enabled = true;
    state.show = true;
  },
  dissable(state) {
    state.enabled = false;
    state.show = false;
  },
  show(state) {
    state.show = true;
  },
  hide(state) {
    state.show = false;
  },
  toggleVisible(state) {
    state.show = !state.show;
  },
  replaceItems(state, items) {
    // console.log(items);
    state.items = items.filter(e => e !== null && e !== undefined)
  },
  replaceInstances(state, instances) {
    state.instances = instances;
  },
  addItem(state, item) {
    state.items.push(item)
  },
  appendItems(state, items) {
    state.items.push(...items)
  },
  appendItem(state, item) {
    state.items.push(item)
  },
  selectInstance(state, name) {
    state.currentInstance = name
  },
  activeRouterChange(state, activeRouterName) {
    state.activeRouter = activeRouterName
  }
};


export default {
  namespaced: true,
  state,
  mutations,
  getters
  // actions,
}
