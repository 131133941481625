<template>
  <v-container>
    <v-card>
<!--      <DebugJsonComponent :json="items"></DebugJsonComponent>-->
      <v-toolbar elevation="0" color="style-color-main-gray">
        <v-toolbar-title>Направления подготовки:</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn @click="opened_form = true" color="secondary" text>
            <v-icon>add</v-icon>
            Добавить
          </v-btn>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                text
                v-on="on"
                @click="reloadData()"
                class="mr-2"
                medium
              >
                <v-icon>
                  update
                </v-icon>
              </v-btn>
            </template>
            <span>Обновить данные</span>
          </v-tooltip>
        </v-toolbar-items>
      </v-toolbar>
      <v-row>
        <v-col cols="12" sm="9">
        <v-text-field v-model="search.value"
                      dense
                      class="pa-3"
                      placeholder="Поиск по наименованию"
                      @keypress.enter="searchData"
                      @click:clear="clearSearchData"
                      messages="Для поиска нажмите Enter"
                      clearable
        ></v-text-field>
        </v-col>
        <v-col cols="12" sm="3">
          <v-select
            class="pa-2"
            v-model="pagination.filters.level"
            label="Уровень"
            :items="selectors.level"
          ></v-select>
        </v-col>

      </v-row>
        <v-data-table
          no-data-text="Нет данных"
          :headers="headers"
          :items="items"
          :loading="pagination.loading"
          :page.sync="pagination.page"
          :items-per-page.sync="pagination.size"
          :server-items-length="pagination.count"
          :footer-props="{'items-per-page-options':[10,25,50], 'items-per-page-text': 'Число элементов'}"
          locale="ru"
        >
          <template v-slot:item.actions="props">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  v-on="on"
                  @click="updateItem(props.item)"
                  medium
                >
                  <v-icon>
                    edit
                  </v-icon>
                </v-btn>
              </template>
              <span>Редактировать</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  v-on="on"
                  @click="openConfirmDelete(props.item)"
                  medium>
                  <v-icon>
                    delete
                  </v-icon>
                </v-btn>
              </template>
              <span>Удалить</span>
            </v-tooltip>
          </template>
          <template slot="no-data">
            <v-card-text>Данные не загружены</v-card-text>
          </template>
        </v-data-table>
      <TrainingDirectionForm @addItem="addItem"
                             @ended="closedForm"
                             @refreshItem="refreshItem"
                             @input="closedForm"
                             :opened_form="opened_form"
                             v-bind="selected"
                             v-if="opened_form"
      >
      </TrainingDirectionForm>
      <v-dialog persistent v-model="opened_confirm" width="500">
        <v-card v-if="deletable">
          <v-card-title>Подтвердите удаление</v-card-title>
          <v-divider></v-divider>
          <v-card-text class="mt-4">
            Вы уверены, что хотите удалить?<br>
          </v-card-text>
          <v-card-actions>
            <v-flex>
              <v-btn @click="deleteItem(deletable.id)" color="warning" text>
                Удалить
              </v-btn>
              <v-btn @click="closeConfirmDelete" text>
                Отмена
              </v-btn>
            </v-flex>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </v-container>
</template>

<script>

  import urls from "@/urls/management";
  import TitledPageMixin from "@/mixins/TitledPageMixin";
  import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
  import TrainingDirectionForm from "@/modules/educationalManagement/forms/TrainingDirectionForm";
  import DebugJsonComponent from "@/modules/core/components/DebugJsonComponent";
  import {addGetParameters, loadData, makeDeleteRequest} from "@/helper"
  import choices from "@/urls/choices";
  import UserDataMixin from "@/mixins/UserDataMixin";

  export default {
    name: "TrainingDirection",
    components: {TrainingDirectionForm, DebugJsonComponent},
    mixins: [TitledPageMixin, PaginatedDataMapperMixin, UserDataMixin],
    props: {},

    data() {
      return {
        page_title: 'Список направлений подготовки',
        headers: [
          {text: 'Уровень образования', value: 'level', align: 'start', sortable: false},
          {text: 'Наименование', value: 'name', align: 'start', sortable: false},
          {text: 'Код', value: 'code', align: 'start', sortable: false},
          {text: 'Действия', value: 'actions', align: 'right', sortable: false},
        ],
        search:{
          parameter: 'name',
          value: '',
        },
        selectors:{
          level:'',
        },
        pagination: {
          filters: {
            level: '',
          },
        },
        items: [],
      }
    },
    methods: {
      loadData(url = undefined) {
        this.setLoading();
        if (!url) {
          url = urls.EDUCATION.TRAINING_DIRECTION.LIST()
          url = this.addFiltersParameter(url);
          this.setCurrentLoadUrl(url);
        }
        loadData(
          url,
          (data) => {
            this.mapPagination(data);
            this.items = data.results;
            this.setCurrentLoadUrl(url);
            this.setNotLoading();
          }
        )
      },
      getItems (url = undefined) {
        this.loadData(this.getCurrentLoadUrl());
        this.setCurrentLoadUrl(url, true);
      },
      loadSelector(){
        loadData(
          choices.EDUCATION.CHOICES.LEVEL(),
          (data) => {
            this.selectors.level = data
            this.selectors.level.unshift({text:'Все',value: ''})
          }
        )
      },
      addItem(){
        this.closedForm();
        this.getItems();
      },
      deleteItem(id) {
        let url = urls.EDUCATION.TRAINING_DIRECTION.DELETE(id);
        makeDeleteRequest(url).then(resp => {
          if (resp.ok) {
            this.closeConfirmDelete();
            this.getItems();
          }
        })
      },
    },

    created(){
      this.$store.commit('mainpage/changeTitle', 'Направления подготовки');
      this.loadSelector();
      this.loadData();
    },
  }
</script>

<style scoped>

</style>
