<template>
  <v-container style="height: 100%" v-if="$route.name === names.STUDENT.ACADEMICWORKS.LIST">
    <Work v-if="change"
          @close="form_close()"
          :data_work = "data_work">
    </Work>
    <v-card flat>
      <v-toolbar
        elevation="0"
        color="style-color-main-gray"
      >
        <v-text-field dense
                      placeholder="Поиск по предмету"
                      v-model="search.value"
                      @keypress.enter="searchData"
                      @click:clear="clearSearchData"
                      clearable
                      messages="Для поиска нажмите Enter"
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              class="mr-2"
              v-on="on"
              @click="addItem"
              medium
            >
              <v-icon>add</v-icon>
            </v-btn>
          </template>
          <span>Загрузить работу</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              v-on="on"
              @click="getItems()"
              class="mr-2"
              medium
            >
              <v-icon>update</v-icon>
            </v-btn>
          </template>
          <span>Обновить данные</span>
        </v-tooltip>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="items"
        class="elevation-1 "
        no-data-text="Нет данных"
        :loading="pagination.loading"
        :page.sync="pagination.page"
        :items-per-page.sync="pagination.size"
        :server-items-length="pagination.count"
        :footer-props="{'items-per-page-options':[10,25,50], 'items-per-page-text': 'Число элементов'}"
      >
        <v-progress-linear v-slot:progress color="blue" indeterminate></v-progress-linear>
        <template v-slot:item.accept="props">
          <span v-if="props.item.accept === null">Работа еще не проверялась</span>
          <span v-else-if="props.item.accept === true">Работа проверена и принята</span>
          <span v-else  >Работа проверена и не принята</span>
        </template>
        <template v-slot:item.actions="props">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                v-on="on"
                @click=getDetail(props.item)
                medium
              >
                <v-icon>
                  article
                </v-icon>
              </v-btn>
            </template>
            <span>Подробнее</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                v-on="on"
                @click=changeItem(props.item)
                medium
              >
                <v-icon>
                  edit
                </v-icon>
              </v-btn>
            </template>
            <span>Редактировать</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                v-on="on"
                @click="openConfirmDelete(props.item)"
                medium
              >
                <v-icon>
                  delete
                </v-icon>
              </v-btn>
            </template>
            <span>Удалить</span>
          </v-tooltip>
        </template>
        <template v-slot:item.file_name="props">
          <span>{{props.item.file_name}}</span>
        </template>
        <template v-slot:pageText="item">
          {{item.pageStart}} - {{item.pageStop}} из {{item.itemsLength}}
        </template>
        <template v-slot:no-data>
          <v-card-text>Данные не загружены</v-card-text>
        </template>
      </v-data-table>

      <v-dialog persistent v-model="opened_confirm" width="500">
        <v-card v-if="deletable">
          <v-card-title>Подтвердите удаление</v-card-title>
          <v-divider></v-divider>
          <v-card-text class="mt-4">
            Вы уверены, что хотите удалить?<br>
          </v-card-text>
          <v-card-actions>
            <v-flex>
              <v-btn
                @click="deleteItem(deletable.id)"
                color="warning"
                text
              >
                Удалить
              </v-btn>
              <v-btn
                @click="closeConfirmDelete"
                text
              >Отмена
              </v-btn>
            </v-flex>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </v-container>
  <router-view :key="$route.fullPath" v-else></router-view>
</template>


<script>
  import UserDataMixin from '@/mixins/UserDataMixin'
  import {loadData, makeDeleteRequest} from "@/helper";
  import urls from "@/urls/dean"
  import names from "@/modules/dean/routers/names";
  import TitledPageMixin from "@/mixins/TitledPageMixin";
  import Work from "@/modules/dean/forms/Work";
  import DebugJsonComponent from "@/modules/core/components/DebugJsonComponent";
  import dean from "@/urls/dean";
  import PaginatedDataMapperMixin from "../../../../../mixins/PaginatedDataMapperMixin";

  export default {
    name: "ListAcademicWorks",
    components: {
      DebugJsonComponent,
      Work
    },
    mixins: [
      TitledPageMixin,
      UserDataMixin,
      PaginatedDataMapperMixin
    ],
    data () {
      return {
        URL_LIST: urls.DEAN.STUDENTS.INDIVIDUAL_WORK.LIST(this.$route.params.idFaculty, this.$route.params.idStudent),
        names:names,
        page_title: 'Учебные работы',
        items: [],
        search: {
          parameter: 'subject',
        },
        data_work: '',
        change: false,
        headers: [
          {text: 'Предмет', sortable: false, align: 'left',value:'semester.subject',width: '20%'},
          {text: 'Курс', sortable: false, align: 'left',value:'semester.course'},
          {text: 'Семестр', sortable: false, align: 'left',value:'semester.semester'},
          {text: 'Тип файла', sortable: false, align: 'left',value:'type'},
          {text: 'Название работы', sortable: false, align: 'left',value:'name'},
          {text: 'Проверено', sortable: false, align: 'left',value:'accept'},
          {text: 'Файл', sortable: false, align: 'left',value:'file_name', width: '15%'},
          {text: "Действия", sortable: false, align: 'center',value:'actions'},
        ]
      }
    },
    watch: {
    },
    methods: {
      getNumberSemestr(semester,course){
        let num =0
        if(semester === 'осенний'){
          num = course * 2 - 1
        }else {
          num = course * 2
        }
        return num + " (" +semester+ ")"
      },
      getDetail(item){
        this.$router.push({name: names.STUDENT.ACADEMICWORKS.DETAIL, params: {idAcademicWorks: item.id}})
      },
      addItem(){
        this.data_work = ''
        this.change = true
      },
      changeItem(data){
        this.data_work = data
        this.change = true
      },
      deleteItem(id){
        let url  = dean.DEAN.STUDENTS.INDIVIDUAL_WORK.DELETE_WORK(this.$route.params.idFaculty, this.$route.params.idStudent, id)
        makeDeleteRequest(url).then(resp=>{
          this.opened_confirm = false
          this.getItems()
        })
      },
      form_close() {
        this.change = false
        this.getItems()
        this.data_work = ''
      },
      loadData(url = undefined) {
        this.getItems(url)
      },
      getItems (url=undefined) {
        this.setLoading()
        if(url === undefined) {
          url = this.URL_LIST
          this.setCurrentLoadUrl(url)
        }
        loadData(
          url,
          (data) => {
            this.mapResults(data, 'items')
            this.setNotLoading()
          }
        )
      },
    },
    created() {
      this.setPageTitleWithObject("Education", {id: this.$route.params.idStudent}, ' - ', true)
      this.getItems()
    },
  }

</script>
<style scoped>

</style>
