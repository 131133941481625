<template>
  <v-container v-if="$route.name === names.STUDENT.ADMISSIONS.LIST ">
    <v-card >
      <v-toolbar
        elevation="0"
        color="style-color-main-gray"
      >

        <v-spacer></v-spacer>
        <v-btn @click="opened_form=true" text ><v-icon>add</v-icon> Создать</v-btn>
      </v-toolbar>
      <v-data-table
            :headers="headers"
            :items="items"
            :loading="loading"
            class="elevation-1"
            no-data-text="Нет данных"
            :options.sync="pagination"
            :server-items-length="total"
            :footer-props="{'items-per-page-options':[10,25,50], 'footer-props.items-per-page-text': 'Число элементов'}"

      >
        <v-progress-linear v-slot:progress color="blue" indeterminate></v-progress-linear>
        <template v-slot:item.action="props">
          <v-tooltip bottom v-if="!props.item.closed">
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                v-on="on"
                @click="updateItem(props.item)"
                class="mr-2"
                medium
              >
                <v-icon>
                  edit
                </v-icon>
              </v-btn>
            </template>
            <span>Редактировать</span>
          </v-tooltip>
          <v-tooltip bottom v-if="!props.item.closed">
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                v-on="on"
                @click="openConfirmDelete(props.item)"
                medium
              >
                <v-icon>
                  delete
                </v-icon>
              </v-btn>
            </template>
            <span>Удалить</span>
          </v-tooltip>
         </template>
        <template v-slot:item.created="props">
          <span>{{displayDate(props.item.created)}}</span>
        </template>
        <template v-slot:item.subject="props">
          <span style="text-decoration: underline; cursor: pointer" @click="getDetail(props.item)">{{props.item.subject}}</span>
        </template>
        <template v-slot:item.semester="props">
          <span>{{getNumberSemestr(props.item.semester,props.item.course)}}  </span>
        </template>
        <template v-slot:item.admission_type="props">
          <v-tooltip bottom >
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">{{getAbbreviation(props.item.academic_difference, props.item.is_exam)}}</span>
            </template>
            <span>{{getAdmissionType(props.item.academic_difference, props.item.is_exam)}}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.success="props">
          <v-icon v-if="props.item.success" color="green">done</v-icon>
          <v-icon v-else-if="props.item.success !== null" color="red">clear</v-icon>
          <span v-else>Не рассмотрен</span>
        </template>
        <template slot="pageText" slot-scope="item">
          {{item.pageStart}} - {{item.pageStop}} из {{item.itemsLength}}
        </template>
        <template slot="no-data">
          <v-card-text>Данные не загружены</v-card-text>
        </template>
      </v-data-table>
      <Admission @ended="closedForm"
                 v-bind="selected"
                 v-if="opened_form"
                 :opened_form="opened_form"
      >
      </Admission>
      <v-dialog persistent v-model="opened_confirm" width="500">
        <v-card v-if="deletable">
          <v-card-title>Подтвердите удаление</v-card-title>
          <v-divider></v-divider>
          <v-card-text class="mt-4">
            Вы уверены, что хотите удалить?<br>
          </v-card-text>
          <v-card-actions>
            <v-flex>
              <v-btn
                @click="deleteItem(deletable.id)"
                color="warning"
                text
              >
                Удалить
              </v-btn>
              <v-btn
                @click="closeConfirmDelete"
                text
              >Отмена
              </v-btn>
            </v-flex>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </v-container>
  <router-view :key="$route.fullPath" v-else></router-view>
</template>

<script>
    import urls from "@/urls/dean"
    import UserDataMixin from '@/mixins/UserDataMixin'
    import {makeGetRequest, } from "@/helper";
    import {generateListUidToName} from "@/helper/uidToName";
    import Admission from "@/modules/dean/pages/student/form/Admission";
    import names from "@/modules/dean/routers/names";
    import TitledPageMixin from "@/mixins/TitledPageMixin";
    import moment from "moment";
    import {makeDeleteRequest} from "../../../../../helper";

    export default {
      name: "ListAdmissions",

      mixins: [UserDataMixin, TitledPageMixin],
      components:{Admission},
      data () {
        return {
          total: 0,
          names:names,
          page_title: 'Список допусков',
          items: [],
          loading: true,
          pagination: {},
          selectors:{

          },
          URL_LIST: urls.DEAN.STUDENTS.ADMISSION.LIST(this.$route.params.idFaculty, this.$route.params.idStudent),
          headers: [
            {text: 'Номер', sortable: true, align: 'left', value: 'number',width: '7%'},
            {text: 'Дата', sortable: true, align: 'left', value: 'created',width: '8%'},
            {text: 'Предмет', sortable: false, align: 'left', value: 'subject',},
            {text: 'Семестр', sortable: false, align: 'left', value: 'semester', width: '8%'},
            {text: 'Контроль', sortable: false, align: 'left', value: 'control_type',},
            {text: 'Группа', sortable: false, align: 'left', value: 'group.name',},
            {text: 'Тип допуска', sortable: false, align: 'left', value: 'admission_type',},
            {text: 'Рейтинг в семестре', sortable: false, align: 'left', value: 'in_semester',},
            {text: 'Рейтинг', sortable: false, align: 'left', value: 'total',},
            {text: 'Статус', sortable: false, align: 'left', value: 'success',},
            {text: 'Действия', sortable: false, align: 'left', value: 'action', width: '10%',},
          ]
        }
      },
      watch: {
        pagination: {
          handler () {
            this.getItems()
          },
          deep: true
        }
      },
      created() {
        this.setPageTitleWithObject("Education", {id: this.$route.params.idStudent}, ' - ', true)
      },
      methods: {
        deleteItem: function (id) {
          let url = urls.DEAN.ADMISSION.DELETE(this.$route.params.idFaculty, id)
          makeDeleteRequest(url).then(resp => {
            if (resp.ok) {
              this.closeConfirmDelete()
              this.getItems();
            }else{
              alert("Не удалось удалить")
            }
          })
        },
        getNumberSemestr(semester,course){
          let num =0
          if(semester === 'осенний'){
            num = course * 2 - 1
          }else {
            num = course * 2
          }
          return num + " (" +semester+ ")"
        },
        displayDate(date, minutes = false) {
          let format = 'DD.MM.YYYY';
          if (minutes)
            format += ' HH:mm';
          return moment(date).format(format)
        },
        getAbbreviation(academic_difference, is_exam){
          if (academic_difference)
            return "А/Р"
          else if (is_exam)
            return "Э"
          else
            return "Д"
        },
        getAdmissionType(academic_difference, is_exam){
          if (academic_difference)
            return "Академическая разница"
          else if (is_exam)
            return "Экзамен/Зачет с оценкой"
          else
            return "Добор/Зачет"
        },
        closedForm: function () {
          this.selected = {};
          this.opened_form = false
          this.getItems();
        },
        getDetail(item){
            this.$router.push({name: names.STUDENT.ADMISSIONS.DETAIL, params: {idAdmission: item.id}})
        },
        addItem(){
          this.closedForm()
        },
        getItems () {
          this.opened_form = false
          this.loading = true
          let url = new URL(this.URL_LIST)
          let params = {};
          if(this.pagination.page !== undefined) {
            params.page = this.pagination.page;
            params.size = this.pagination.itemsPerPage;
          }
          else{
            params.page = "1";
            params.size = "10";
          }
          url.search = new URLSearchParams(params).toString();
          let sys = true
          makeGetRequest(url).then(resp => {
            if (!resp.ok) {
              sys = false
            }
            return resp.json()
          }) .then(json => {
            this.items = json.results
            this.total = json.count
            this.loading = false
            // for (let i in this.items) {
            //   this.$set(this.items[i], "fio","-")
            // }
            // generateListUidToName(this.items,"student","fio")
          })
        },
      }
    }

</script>

<style scoped>

</style>
