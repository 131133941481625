import Base from "../pages/group/Base";
import CurrentJournal from "../pages/group/CurrentJournal";
import StudentList from "../pages/group/StudentList";
import SubjectList from "../pages/group/SubjectList";
import names from "@/modules/educationalManagement/routers/names";
import {ACCOUNTS_INSTANCE, EDUCATION_INSTANCE} from "@/helper/consts";

export default ([
  {
    path: ':group_id([0-9]+)',
    name: names.EDUCATIONAL.GROUP.DETAIL,//.EducationalGroupDetail,//'EducationalManagementGroupDetail',
    component: Base,
    meta: {
      requiredAuth: true,
      breadcrumb: 'Группа',
      instances: [ACCOUNTS_INSTANCE, EDUCATION_INSTANCE,],
    },
    children: [
      {
        path: 'students',
        name: names.EDUCATIONAL.GROUP.STUDENTS,//'EducationalManagementGroupStudentList',
        component: StudentList,
        meta: {
          requiredAuth: true,
          breadcrumb: 'Список обучающихся',
          instances: [ACCOUNTS_INSTANCE, EDUCATION_INSTANCE,],
        },
      },
      {
        path: 'subjects',
        name: names.EDUCATIONAL.GROUP.SUBJECTS,//'EducationalManagementGroupSubjectList',
        component: SubjectList,
        meta: {
          requiredAuth: true,
          breadcrumb: 'Список дисциплин',
          instances: [ACCOUNTS_INSTANCE, EDUCATION_INSTANCE,],
        },
      },
      {
        path: 'current/journal',
        name: names.EDUCATIONAL.GROUP.CURRENT_JOURNAL,//'EducationalManagementGroupCurrentJournal',
        component: CurrentJournal,
        meta: {
          requiredAuth: true,
          breadcrumb: 'Журнал текущей успеваемости',
          instances: [ACCOUNTS_INSTANCE, EDUCATION_INSTANCE,],
        },
        children: []
      },
    ]
  },
])


