<template>
  <List v-if="$route.name === router_name"></List>
  <router-view v-else></router-view>
</template>

<script>
  import List from "./List";
  import names from "@/modules/curriculumEditor/routers/names";

  export default {
    name: "Base",
    components: {
      List
    },
    data(){
      return {
        router_name: names.CURRICULUM.SUBJECTS.LIST,//'CurriculumEditorSubjectsList',
      }
    },
  }
</script>

<style scoped>

</style>
