
<template>
  <EducationWorks v-if="work" :EducationWorks="work">
  </EducationWorks>
</template>

<script>
import moment from 'moment'
import {loadData} from "@/helper";
import urls from "@/urls/dean"
import {generateUidToName} from "@/helper/uidToName";
import UserDataMixin from "@/mixins/UserDataMixin";
import DebugJsonComponent from "@/modules/core/components/DebugJsonComponent";
import EducationWorks from "@/modules/templates/work/EducationWorks";
export default {
  name: "DetailWork",
  components: {
    DebugJsonComponent,
    EducationWorks
  },
  mixins: [UserDataMixin],
  data: function () {
    return {
      work: null,
      loading: false,
      URL_DETAIL: urls.DEAN.STUDENTS.INDIVIDUAL_WORK.DETAIL(
        this.$route.params.idFaculty,
        this.$route.params.idStudent,
        this.$route.params.idAcademicWorks,
      ),
    }
  },
  methods: {
    loadData(){
      loadData(
        this.URL_DETAIL,
        (data) => {
          this.work = data
          if(this.work.teacher){
            this.$set(this.work, "fio", "")
            generateUidToName(this.work, "teacher", "fio")
          }
          if(this.work.student){
            this.$set(this.work, "fio_student", "")
            generateUidToName(this.work, "student", "fio_student")
          }
        }
      )
    },

  },
  created() {
    this.loadData()
  }
}
</script>

<style scoped>

</style>
