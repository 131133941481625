<template>
  <v-container style="height: 100%">
    <v-card flat>
      <v-card-title class="title style-color-main-gray">
        <span>Список курсов:</span>
        <v-spacer></v-spacer>
        <v-btn @click="opened_form = true" color="secondary" text>
          <v-icon>add</v-icon>
          Добавить
        </v-btn>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              text
              v-on="on"
              @click="getItems()"
              medium
            >
              <v-icon>
                update
              </v-icon>
            </v-btn>
          </template>
          <span>Обновить данные</span>
        </v-tooltip>
      </v-card-title>
      <v-card-text class="pb-3 pt-1">
        <v-layout justify-center>
          <v-flex>
            <v-data-table
              :headers="headers"
              :items="items"
              :loading="loading"
              class="elevation-1"
              no-data-text="Нет данных"
              :options.sync="pagination"
              :server-items-length="total"
              :footer-props="{'items-per-page-options':[10,25,50], 'items-per-page-text': 'Число элементов'}"
            >
              <template v-slot:item="props">
                <tr class="text-xl-left">
                  <td class="justify-center">{{props.item.number}}</td>
                  <td class="justify-center">{{props.item.level}}</td>
                  <td class="text-right " v-bind="selected">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          icon
                          v-on="on"
                          @click="updateItem(props.item)"
                          medium
                        >
                          <v-icon>
                            edit
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>Редактировать</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          icon
                          v-on="on"
                          @click="openConfirmDelete(props.item)"
                          medium
                        >
                          <v-icon>
                            delete
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>Удалить</span>
                    </v-tooltip>
                  </td>
                </tr>
              </template>
              <template v-slot:no-data>
                <v-card-text>Данные не загружены</v-card-text>
              </template>
            </v-data-table>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
    <Courses @addItem="addItem"
             @ended="closedForm"
             @refreshItem="refreshItem"
             @input="closedForm"
             :opened_form="opened_form"
             v-bind="selected"
             v-bind:info="$route.params.id"
             v-if="opened_form"
    >
    </Courses>
    <v-dialog persistent v-model="opened_confirm" width="500">
      <v-card v-if="deletable">
        <v-card-title>Подтвердите удаление</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-4">
          Вы уверены, что хотите удалить?<br>
        </v-card-text>
        <v-card-actions>
          <v-flex>
            <v-btn
              @click="deleteItem(deletable.id)"
              color="warning"
              text
            >
              Удалить
            </v-btn>
            <v-btn
              @click="closeConfirmDelete"
              text
            >Отмена
            </v-btn>
          </v-flex>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>


<script>
  import Courses  from "../forms/Courses"
  import UserDataMixin from '@/mixins/UserDataMixin'
  import {makeGetRequest} from "@/helper";
  import urls from '../urls'

  export default {
    name: "ListCourses",
    components: {Courses},
    mixins: [UserDataMixin],
    props: {
      isDetail: Boolean,
    },
    data () {
      return {
        URL_LIST: urls.getUrl('admin|core|course-list', 'list'),
        URL_DELETE: urls.getUrl('admin|core|course-detail', 'destroy'),
        total: 0,
        items: [],
        loading: true,
        pagination: {},
        headers: [
          {text: 'Курс', sortable: false, align: 'left',},
          {text: 'Уровень', sortable: false, align: 'left', },
          {text: 'Действия', sortable: false, align: 'right', },
        ]
      }
    },
    watch: {
      pagination: {
        handler () {
          this.getItems()
        },
        deep: true
      }
    },
    created() {
        this.$store.commit('mainpage/changeTitle', 'Список курсов');
    },
    methods: {
      addItem(){
        this.closedForm()
        this.getItems();
      },
      getItems () {
        this.loading = true
        let url = new URL(this.URL_LIST)
        let params = {};
        if(this.pagination.page !== undefined) {
          params.page = this.pagination.page;
          params.size = this.pagination.itemsPerPage;
        }
        else{
          params.page = "1";
          params.size = "10";
        }
        url.search = new URLSearchParams(params).toString();
        let sys = true
        makeGetRequest(url).then(resp => {
          if (!resp.ok) {
            sys = false
          }
          return resp.json()
        }) .then(json => {
          this.items = json.results
          this.total = json.count
          this.loading = false
        })
      },
    }
  }

</script>
<style scoped>

</style>
