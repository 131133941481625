<template>
  <v-data-table
    :headers="headers"
    :items="items"
    disable-pagination
    disable-filtering
    disable-sort
    hide-default-footer
    dense
    class="rounded-0"
    :loading="localLoading"
  >
    <template v-slot:item="{item}">
      <Kind
        :item.sync="item"
        :semester="semester"
        :parent="semester"
        :loading="localLoading"
        @update:loading="(v) => iloading = v"
        @refresh="refresh"
      > </Kind>
    </template>
  </v-data-table>
</template>

<script>
import Kind from "@/modules/curriculumEditor/pages/difference/Kind.vue";

export default {
  name: "Kinds",
  components: {Kind},
  props: {
    items: undefined,
    semester: undefined,
    loading: {
      default: false
    },
  },
  computed: {
    semester_id() {
      return this.semester.one && this.semester.eios ? this.semester.one.guid : null
    },
    localLoading() {
      return this.loading || this.iloading
    },
  },
  data() {
    return {
      iloading: false,
      headers: [
        {value: 'kind', text: "Вид занятий [Э-1C]", sortable: false, align: 'left', width:'60%'},
        {value: 'hours', text: "Часы [Э-1C]", sortable: false, align: 'left', width:'20%'},
        {value: 'actions', text: "Действия", sortable: false, align: 'right', width:'20%'},
      ],
      expanded: [],
      // item_list: this.items
    }
  },
  watch: {
    // items(nV, oV){
    //   this.item_list = nV
    // },
  },
  methods: {
    refresh() {
      this.$emit('refresh')
    }
  }
}
</script>

<style scoped>

</style>
