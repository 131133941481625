<template>
  <v-container class="page-content px-5" style="  width: 100%">
    <v-toolbar
      class="removeBtnPaddingRight rounded-t-lg"
      color="#DADADA"
      flat
    >
      <v-text-field
        single-line
        outlined
        dense
        clearable
        class="dark-primary"
        :disabled="isLoading"
        v-model="search.value" label="Поиск" hide-details @keydown.enter="searchData">
      </v-text-field>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="items"
      :loading="isLoading"
      :page.sync="pagination.page"
      :items-per-page.sync="pagination.size"
      :server-items-length="count"
      :options.sync="options"
      :footer-props="{'items-per-page-options':[10,50,100]}"
      class="elevation-1 rounded-b-lg"
    >
      <template v-slot:item.actions="{ item }">
        <related-link-indicator :item="item" related_parameter="education_related"></related-link-indicator>
      </template>
      <template v-slot:no-data>
        <v-btn
          color="primary"
          @click="updateData"
        >
          Обновить
        </v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>


<script>
import {mapActions, mapGetters} from "vuex";
import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
import TitledPageMixin from "@/mixins/TitledPageMixin";
import RelatedLinkIndicator from "@/modules/administrative/pages/connector/components/RelatedLinkIndicator.vue";

export default {
  name: "AdminCathedraList",
  components: {RelatedLinkIndicator},
  mixins: [TitledPageMixin, PaginatedDataMapperMixin],
  data() {
    return {
      page_title: "Список факультетов",
      dialog_add_edit: false,
      dialog_delete: false,
      edit_status: undefined,
      cars_data: undefined,
      search: {
        parameter: 'search',
      },
      pagination: {
        filters: {
          'model': null,
        },
      },
      selectors: {
        model: [],
      },
      headers: [
        // {text: 'Код', value: 'code', align: 'start', sortable: true, width: '30%'},
        {text: 'Наименование', value: 'name', align: 'start', sortable: true, width: '30%'},
        {text: 'Идентификатор', value: 'guid', align: 'start', sortable: true, width: '25%'},
        {text: 'Действия', value: 'actions', sortable: false, align: 'center', width: '15%'},
      ],
    }
  },
  computed: {
    ...mapGetters({
      items: 'admin/references/cathedras/getCathedraItems',
      count: 'admin/references/cathedras/getCathedraCount',
      isLoading: 'admin/references/cathedras/isCathedraLoading',
      getCurrentUrl: 'admin/references/cathedras/getCathedraCurrentUrl'
    }),
  },
  watch: {
    car_filter_model() {
      if (this.car_filter_model !== null) {
        this.pagination.filters.model = this.selectors.model[this.car_filter_model - 1].text
      } else {
        this.pagination.filters.model = null
      }
    }
  },
  methods: {
    ...mapActions({
      loadCathedraList: 'admin/references/cathedras/loadCathedraList',
    }),
    // getDetail(item) {
    //   this.$router.push({name: names.REFERENCE.DIRECTIONS, params: {idCathedra: item.id}})
    // },
    updateData(){
      if (this.search.value) {
        this.search.value = ''
      }
      this.pagination.filters = {
        'model': null,
      }
      this.car_filter_model = null
      this.loadData()
    },
    loadData(url = undefined) {
      this.loadCathedraList({
        url: url,
        urlAppend: (url) => {
          return this.addPageParameter(this.addSizeParameter(url));
        },
        finalizer: (data, url) => {
          this.setCurrentLoadUrl(url);
        }
      })
    },
    // loadSelectors() {
    //   this.loadCathedraModelsSelector({
    //     url: undefined,
    //     finalizer: (val) => {
    //       this.selectors.model = val
    //     }
    //   })
    // },
  },
  created() {
    // if (admin.ADMIN.ALLOWED()){
    //   if (this.getCurrentUrl) {
    //     this.loadFromSavedUrl(this.getCurrentUrl)
    //   } else {
    this.loadData()
    //   }
    //   this.loadSelectors()
    // }
    // else{
    //   this.$router.push({name: names_core.MAIN})
    // }
  },
}
</script>

<style scoped>
.removeBtnPaddingRight>>>.v-toolbar__content{
  padding-right: 0px;
}
</style>
