<template>
  <v-container v-if="this.journal !== undefined && this.journal.length > 0">
    <v-card>
      <v-toolbar
        class="mb-2"
        color="style-color-main-gray"
        flat
      >
        <v-toolbar-title>
          По дисциплине
        </v-toolbar-title>
        <v-toolbar-title>
          <v-select :items="journal" item-value="id"
                    :item-text="textSubj" v-model="subject" class="ml-3"
                    hide-details dense style="min-width: 280px;" filled :disabled="save_process.execute">
            <template v-slot:item="props">{{props.item.subject.name}} ({{props.item.control_type}})<v-icon small v-if="props.item.can_update" class="my-auto mx-2">edit</v-icon></template>
          </v-select>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-title>
          <v-text-field v-model="search" clearable placeholder="Поиск" hide-details style="min-width: 100px;" prepend-inner-icon="search" dense hint="" filled :disabled="save_process.execute"></v-text-field>
        </v-toolbar-title>
        <v-toolbar-items v-if="attachment" class="py-1 px-1">
          <v-tooltip top>
            <template v-slot:activator="{on, attrs}">
              <v-btn  v-on="on" text outlined @click="loadStatement()">
                <v-icon class="mr-1" x-large>file_download</v-icon>
              </v-btn>
            </template>
            <span>Сохранить ведомость</span>
          </v-tooltip>
        </v-toolbar-items>
      </v-toolbar>
      <v-row>
        <v-col>
<!--          <v-alert outlined type="info">-->
<!--            Уважаемые пользователи, обращаем ваше внимание, что учебный журнал суммирует баллы введенные в каждую контрольную точку по отдельности.-->
<!--          </v-alert>-->
          <JournalTable v-if="selected.students.basename === undefined" :rows="selected.students"
                        @open="openConfirmCloseReload"
                        :can_update="selected.can_update" :search="search" :save_execute="save_process.execute"
                        :loading="loading" :errors="save_process.errors" :show_marks = true :can_close_reload = true>
            <template v-slot:extra_actions >
              <v-dialog persistent v-model="opened_confirm" width="500" >
                <v-card v-if="closable">
                  <v-card-title>Подтвердите закрытие перезачета</v-card-title>
                  <v-divider></v-divider>
                  <v-card-text>
                    Вы уверены что хотите закрыть перезачет<br>
                  </v-card-text>
                  <v-card-actions>
                    <v-flex>
                      <v-btn
                        @click="CloseReloadItem"
                        color="warning"
                        text
                      >
                        Закрыть
                      </v-btn>
                      <v-btn
                        @click="closeConfirmCloseReload"
                        text
                      >Отмена
                      </v-btn>
                    </v-flex>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>
          </JournalTable>
          <v-skeleton-loader
            v-else
            type="table"
          >
          </v-skeleton-loader>
        </v-col>
      </v-row>
      <v-snackbar v-model="save_process.alert.visible" right :color="save_process.success?'success':save_process.success !== undefined?'warning':'info'" timeout="3000" top>
        {{save_process.alert.message}}
        <template v-slot:action>
          <v-btn icon @click="save_process.alert.visible = false">
            <v-icon>close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-card>
  </v-container>
  <v-container v-else-if="loading">
    <v-row>
      <v-col>
        <v-skeleton-loader
          height="50"
          type="list-item"
        >
        </v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-skeleton-loader
          height="200"
          type="card"
        >
        </v-skeleton-loader>
      </v-col>
    </v-row>
  </v-container>
  <v-container v-else>
    <v-toolbar
      class="mb-2"
      dark
      color="grey darken-4"
      flat
    >
      <v-toolbar-title>
        Нет данных по дисциплинам текущего семестра
      </v-toolbar-title>
    </v-toolbar>
  </v-container>
</template>

<script>
  import names from "../../routers/names";
  import helper from "@/urls/helper";
  import dean from "@/urls/dean";
  import {loadData, makeGetRequest, sendPostRequest} from "../../../../helper";
  import {generateListUidToName} from "@/helper/uidToName";
  import DebugJsonComponent from "../../../core/components/DebugJsonComponent";
  import JournalTable from "@/modules/templates/journal/JournalTableTemplate.vue";
  import TitledPageMixin from "@/mixins/TitledPageMixin";
  import LeftMenuChangerMixin from "@/mixins/LeftMenuChangerMixin";


  export default {
    name: "Base",
    mixins: [TitledPageMixin, LeftMenuChangerMixin],
    components: {DebugJsonComponent, JournalTable},
    props: {

    },
    data: function () {
      return {
        page_title: 'Журнал группы',
        group_id: this.$route.params.idGroup,
        nameGroup: '',
        idFaculty: this.$route.params.idFaculty,
        journal: [],
        search: '',
        attachment: false,
        subject: 0,
        saved_data: undefined,
        save_process: {
          execute: false,
          success: undefined,
          errors: [],
          alert: {
            visible: false,
            message: ''
          },
        },
        selected: {},
        loading: false,
        opened_confirm: false,
        closable: {},
      }
    },
    watch: {
      subject: function (value) {
        if(value !== -1) {
          let finded = this.journal.find(el => {
            return el.id === this.subject
          });
          this.selected = finded;
          if(finded.students_url.basename !== undefined)
            this.loadSemester(finded);
        }
      },
    },
    computed: {},
    methods: {
      openConfirmCloseReload(obj) {
        this.closable = obj
        this.opened_confirm = true
      },
      closeConfirmCloseReload() {
        this.opened_confirm = false
        setTimeout(() => {
          this.closable = null
        }, 500)
      },
      CloseReloadItem(){
        let value = {
          semesters: [{
            semester:  this.closable.id
          }]
        }
        let url = dean.DEAN.JOURNAL.SEMESTER.CANCEL_RELOAD(this.idFaculty, this.closable.student_id)
        sendPostRequest(
          url,
          value,
          ()=>{
            this.opened_confirm = false
            let student_index = this.selected.students.findIndex(el => el.id === this.closable.id)
            if(student_index !== -1)
              this.selected.students[student_index].reload = false
          },
          (res)=>{
            this.receiveErrors(res)
          },
        )
      },
      loadStatement(){
        makeGetRequest(
          dean.DEAN.JOURNAL.STATEMENT(this.idFaculty, this.group_id,{'semester':this.selected.id})
        )
          .then(resp => {
            return resp.blob()
          }).then(data => {
          let a = document.createElement('a');
          let blob = new Blob([data], { 'type':'application/vnd.ms-excel'});
          a.href = window.URL.createObjectURL(blob);
          a.download = `Журнал группы ${this.nameGroup} ${this.selected.subject.name}.xlsx`
          a.click();
        });
      },
      textSubj(item){
        return item.subject.name + " ("+item.control_type+")"
      },
      getLeftMenu(){
        return [
          {
            title: 'Группа',
            icon: 'arrow_back_ios',
            included: false,
            router: {
              name: names.GROUP.DETAIL,
              params: {
                group_id: this.$route.params.idGroup,
                idFaculty: this.$route.params.idFaculty,
                taught: this.$route.params.taught,
                reload_left_menu: true,
              },
              query: this.$route.query,
            }
          },
        ]
      },
      loadJournal(){
        this.loading = true;
        loadData(
          dean.DEAN.JOURNAL.LOAD(this.idFaculty, this.group_id),
          (data) => {
            if(data.length > 0){
              this.journal = data;
              this.journal.forEach(el => {
                this.$set(el, 'students_url', el.students);
                // this.$set(el, 'students', []);
                this.$set(el, 'fio_loaded', false);
              });
              this.subject = data[0].id;
            }
            this.loading = false;
            this.loadGroup()
          }
        )
      },
      loadSemester(selected, finalizer=()=>{}){
        loadData(
          dean.getByRecievedUrlData(selected.students_url, selected.students_url.get_params),
          res => {
            selected.students = res;
            this.loadStudentFios(selected, finalizer);
          }
        );
      },
      loadStudentFios(el, finalizer=()=>{}){
        el.students.forEach(el => {
          this.$set(el, 'fio', '');
          this.$set(el, 'old_exam', el.exam);
          el.medium_control.forEach(sel => {
            this.$set(sel, 'old_value', sel.value);
          });
        });
        generateListUidToName(el.students, 'student_uid', 'fio', () => {
          el.fio_loaded = true;
          el.students.sort((a,b) => a.fio.toUpperCase() > b.fio.toUpperCase() ? 1: -1)
          finalizer();
        });
      },
      loadAttachment() {
        let url = dean.DEAN.CATHEDRA.GROUP.DETAIL(this.$route.params.uidDepartament, this.$route.params.idGroup)
        loadData(
          url,
          (data) => {
            this.attachment = data.attachment
          })
      }
    },
    created() {
      this.setPageTitleWithObject("Group", {id: this.$route.params.idGroup}, ' ', null, (value)=>{this.nameGroup=value})
      this.loadJournal();
      if (this.$route.params.uidDepartament){
        this.loadAttachment()
      }
      else{
        this.attachment = true
      }
    }
  }
</script>

<style scoped>

</style>
