<template>
  <v-container>
    <v-card flat v-if="semester.data.subject">
      <v-toolbar flat  color="style-color-main-gray">
        <v-card-title>{{ semester.data.subject.name }}</v-card-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="change = true">
          <v-icon>edit</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text v-if="semester.loaded" class="subtitle-2" style="color: black">
        <v-row class="text-left py-3">
          <v-col cols="6" xs="6" md="3">
            <v-card-text class="py-0">Семестр прохождения:</v-card-text>
          </v-col>
          <v-col cols="6" xs="6" md="9">
            <v-card-text class="py-0"> {{ semester.data.semester }}</v-card-text>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="text-left  py-3">
          <v-col cols="6" xs="6" md="3">
            <v-card-text class="py-0">Курс прохождения:</v-card-text>
          </v-col>
          <v-col cols="6" xs="6" md="9">
            <v-card-text class="py-0"> {{ semester.data.course.number }}</v-card-text>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="text-left  py-3">
          <v-col cols="6" xs="6" md="3">
            <v-card-text class="py-0">Преподающие кафедры:</v-card-text>
          </v-col>
          <v-col cols="6" xs="6" md="9">
            <v-card-text class="py-0" v-for="(str,index) in semester.data.cathedrals">
              {{str.name}}<span v-if="index!== semester.data.cathedrals.length-1">;</span> <br>
            </v-card-text>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="text-left  py-3">
          <v-col cols="6" xs="6" md="3">
            <v-card-text class="py-0">Тип контроля:</v-card-text>
          </v-col>
          <v-col cols="6" xs="6" md="9">
            <v-card-text class="py-0"> {{ semester.data.control_type }}</v-card-text>
          </v-col>
        </v-row>
        <v-divider></v-divider>
          <v-row class="text-left py-3">
          <v-col cols="6" xs="6" md="3">
            <v-card-text class="py-0">Количество аудиторных часов:</v-card-text>
          </v-col>
          <v-col cols="6" xs="6" md="9">
            <v-card-text class="py-0"> {{ semester.data.classroom_hours }}</v-card-text>
          </v-col>
          </v-row>
        <v-divider></v-divider>
        <v-row class="text-left py-3">
          <v-col cols="6" xs="6" md="3">
            <v-card-text class="py-0">Количество самостоятельных часов:</v-card-text>
          </v-col>
          <v-col cols="6" xs="6" md="9">
            <v-card-text class="py-0"> {{ semester.data.independent_hours }}</v-card-text>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="text-left py-3">
          <v-col cols="6" xs="6" md="3">
            <v-card-text class="py-0">Количество экзаменационных часов:</v-card-text>
          </v-col>
          <v-col cols="6" xs="6" md="9">
            <v-card-text class="py-0"> {{ semester.data.exam_hours }}</v-card-text>
          </v-col>
        </v-row>
        <v-divider></v-divider>
          <v-row class="text-left py-3">
            <v-col cols="12" xs="6" md="3">
              <v-card-text class="py-0">Общее кол-во часов:</v-card-text>
            </v-col>
            <v-col cols="12" xs="6" md="9">
              <v-card-text class="py-0"> {{ semester.data.hours }}</v-card-text>
            </v-col>
          </v-row>
        <v-divider></v-divider>
          <v-row class="text-left py-3">
            <v-col cols="6" xs="6" md="3">
              <v-card-text class="py-0">Общее кол-во зачетных едениц:</v-card-text>
            </v-col>
            <v-col cols="6" xs="6" md="9">
              <v-card-text class="py-0"> {{ semester.data.zets }}</v-card-text>
            </v-col>
          </v-row>
        <v-divider></v-divider>
        <v-row class="text-left py-3" v-if="semester.data.optionally">
          <v-col cols="6" xs="6" md="3">
            <v-card-text class="py-0">Предмет по выбору:</v-card-text>
          </v-col>
          <v-col cols="6" xs="6" md="9">
            <v-card-text class="py-0"> <v-icon>done</v-icon></v-card-text>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="text-left py-3" v-if="semester.data.optionally">
          <v-col cols="6" xs="6" md="3">
            <v-card-text class="py-0">Номер группы предметов по выбору:</v-card-text>
          </v-col>
          <v-col cols="6" xs="6" md="9">
            <v-card-text class="py-0"> {{ semester.data.optionally_group }}</v-card-text>
          </v-col>
        </v-row>
        <v-divider></v-divider>
      </v-card-text>

      <LessonKindList @update="loadSemester()" :semester_id="$route.params.semester_id"
                      :lesson_kinds="semester.data.lesson_kinds"></LessonKindList>
    </v-card>
    <Form
      v-if="change"
      :opened="change"
      :semester_id="$route.params.semester_id"
      :curriculum_id="$route.params.curriculum_id"
      @close="loadSemester()"
    ></Form>
  </v-container>
</template>

<script>
import row_constants from "../../../core/components/row/constants"
import urls from "../../urls";
import {loadData} from "../../../../helper";
import LessonKindList from "../lesson_kind/List";
import Form from "./Form";

export default {
  name: "SemestersDetail",
  components: {
    LessonKindList,
    Form,
  },
  props: {},
  data() {
    return {
      title: 'Семестр дисциплины',
      // :
      curriculum_id: this.$route.params.curriculum_id,
      semester_id: this.$route.params.semester_id,
      semester: {
        data: {},
        loading: false,
        loaded: false,
      },
      change: false,
      ...row_constants,
    }
  },
  methods: {
    loadSemester: function () {
      this.change = false
      let saver = (data) => {
        this.semester.data = data;
        this.semester.loaded = true;
      };
      let catcher = (error) => {
        console.log(error);
      };
      let url = urls.NAMES.EDITOR.SEMESTER.RETRIEVE(this.curriculum_id, this.semester_id);
      loadData(url, saver, catcher);
    },
  },
  created() {
    this.$store.commit('mainpage/changeTitle', this.title);
    this.loadSemester();
  }
}
</script>

<style scoped>

</style>
