<template>
  <v-container style="height: 100%" v-if="$route.name === names.GROUP.LIST">
    <v-card flat>
      <v-toolbar :extended='$vuetify.breakpoint.xsOnly'
        elevation="0"
        color="style-color-main-gray"
      >
        <v-btn @click="opened_form = true" color="secondary" text>
          <v-icon>add</v-icon>
          Добавить
        </v-btn>
        <template v-if="$vuetify.breakpoint.smAndUp" >
          <v-text-field
            dense
            placeholder="Поиск"
            v-model="search"
            @change="getItems()"
            messages="Для поиска нажмите Enter или кнопку поиск"
          >
          </v-text-field>
          <v-btn icon ><v-icon>search</v-icon></v-btn>
        </template>
        <v-switch
          v-model="archive"
          inset
          hide-details
          label="Показать архивные"
          class="pr-5"
        ></v-switch>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              @click="getItems()"
              class="mr-2"
              medium
            >
              update
            </v-icon>
          </template>
          <span>Обновить данные</span>
        </v-tooltip>

        <template v-slot:extension v-if='$vuetify.breakpoint.xsOnly'>
          <v-text-field
            dense
            placeholder="Поиск"
            v-model="search"
            @change="getItems()"
            messages="Для поиска нажмите Enter или кнопку поиск"
          >
          </v-text-field>
          <v-btn icon ><v-icon>search</v-icon></v-btn>
        </template>
      </v-toolbar>
            <v-data-table
              :headers="headers"
              :items="items"
              :loading="loading"
              class="elevation-1"
              no-data-text="Нет данных"
              :options.sync="pagination"
              :server-items-length="total"
              :footer-props="{'items-per-page-options':[10,25,50], 'items-per-page-text': 'Число элементов'}"
              style="cursor: pointer"
            >
              <template v-slot:item.name="{ item }">
                <router-link :to="getDetail(item)">{{item.name}}</router-link>
              </template>
              <template slot="no-data">
                <v-card-text>Данные не загружены</v-card-text>
              </template>
              <template v-slot:item.option="{ item }">
                <v-tooltip bottom v-if="item.can_destroy">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      v-on="on"
                      @click="openConfirmDelete(item)"
                      medium
                    >
                      <v-icon>
                        delete
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Удалить</span>
                </v-tooltip>
              </template>
            </v-data-table>
    </v-card>
    <Group @addItem="addItem"
           @ended="closedForm"
           @refreshItem="refreshItem"
           v-bind="selected"
           :opened_form="opened_form"
           v-if="opened_form"
    >
    </Group>
    <v-dialog persistent v-model="opened_confirm" width="500">
      <v-card v-if="deletable">
        <v-card-title>Подтвердите удаление</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-4">
          Вы уверены, что хотите удалить?<br>
        </v-card-text>
        <v-card-actions>
          <v-flex>
            <v-btn
              @click="deleteItemGroup(deletable.id)"
              color="warning"
              text
            >
              Удалить
            </v-btn>
            <v-btn
              @click="closeConfirmDelete"
              text
            >Отмена
            </v-btn>
          </v-flex>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
  <router-view :key="$route.fullPath" v-else></router-view>
</template>


<script>
  import Group  from "../forms/Group"
  import UserDataMixin from '@/mixins/UserDataMixin'
  import {makeGetRequest} from "@/helper";
  import urls from "@/urls/dean"
  import names from "@/modules/dean/routers/names";
  import TitledPageMixin from "../../../../mixins/TitledPageMixin";

  export default {
    name: "ListGroup",
    components: {Group},
    mixins: [UserDataMixin, TitledPageMixin],
    props: {
    },
    data () {
      return {
        search:'',
        names:names,
        page_title: 'Список групп',
        URL_LIST: urls.DEAN.GROUP.LIST(this.$route.params.idFaculty),
        URL_DELETE:'',
        total: 0,
        items: [],
        loading: true,
        pagination: {},
        archive: false,
        headers: [
          {text: 'Группа', sortable: false, align: 'left', width: '10%', value: 'name'},
          {text: 'Курс', sortable: false, align: 'left', width: '5%',value: 'course'},
          {text: 'Количество обучающихся', sortable: false, align: 'left', width: '5%',value: 'students_count'},
          {text: 'Профиль', sortable: false, align: 'left', width: '30%',value: 'profile'},
          {text: 'Направление', sortable: false, align: 'left', width: '25%',value: 'direction.code'},
          {text: 'Кафедра', sortable: false, align: 'left', width: '25%',value: 'cathedra'},
          {text: 'Действия', sortable: false, align: 'center', width: '5%',value: 'option'},
        ]
      }
    },

    watch: {
      pagination: {
        handler () {
          if(this.$route.name === names.GROUP.LIST)
            this.getItems()
        },
        deep: true
      },
      archive() {
        this.getItems();
      }
    },


    created() {
      this.setPageTitleWithObject("Faculty", {uid: this.$route.params.idFaculty}, ' - ')
    },
    methods: {
      deleteItemGroup (id) {
        this.URL_DELETE = urls.DEAN.GROUP.DELETE(this.$route.params.idFaculty,id)
        this.deleteItem(id)
      },

      getDetail(item){
        return {name: names.GROUP.DETAIL, params: {idGroup: item.id}}
      },

      openChange(item){
        this.selected = item;
        this.opened_form = true;
      },

      addItem(){
        this.closedForm()
        this.getItems();
      },

      getItems () {
        this.loading = true
        let url = new URL(this.URL_LIST)
        let params = {};

        if(this.search.length > 0)
          params.name = this.search;
        if(this.pagination.page !== undefined) {
          params.page = this.pagination.page;
          params.size = this.pagination.itemsPerPage;
        }
        else{
          params.page = "1";
          params.size = "10";
        }
        params.archive = this.archive;

        url.search = new URLSearchParams(params).toString();

        let sys = true
        makeGetRequest(url).then(resp => {
          if (!resp.ok) {
            sys = false
          }
          return resp.json()
        }) .then(json => {
          this.items = json.results
          this.total = json.count
          this.loading = false
        })
      },
    }
  }


</script>
<style scoped>

</style>
