<template>
  <v-container v-if="is_self_route">
    <v-card>
      <v-toolbar
        elevation="0"
        class="white--text"
        color="style-color-main-gray"
      >
        <v-text-field
          dense
          placeholder="Поиск"
          v-model="search.value"
          @change="searchData"
          messages="Для поиска нажмите Enter или кнопку поиск"
        >
        </v-text-field>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-data-table
        :headers="students.headers"
        :items="students.items"
        :loading="pagination.loading"
        :items-per-page.sync="pagination.size"
        :items-per-page-options="[10, 20, 30]"
        no-data-text="Обучающиеся не загружены"
        no-results-text="Обучающиеся не были найдены"
        :page.sync="pagination.page"
        :server-items-length="pagination.count"
        :footer-props="{
          'items-per-page-all-text': 'Все',
          'items-per-page-options': [10, 25, 50, 100]
        }"
      >
        <template v-slot:item.active="{ item }">
          <v-icon v-if="item.active">
            check_box_outline_blank
          </v-icon>
          <v-icon v-else>
            check_box
          </v-icon>
        </template>

        <template v-slot:item.fio="{ item }">
          <v-skeleton-loader v-if="item.fio === ''" type="text"></v-skeleton-loader>
          <StudentLinkToDetail :student_id="item.id" v-else>
            {{ item.fio }}
          </StudentLinkToDetail>
        </template>

        <template v-slot:item.direction.name="{ item }">
          <span v-if="item.profile !== null && 'name' in item.profile">: {{ item.profile.name }}</span>
        </template>

      </v-data-table>
    </v-card>
  </v-container>
  <StudentBase v-else></StudentBase>
</template>

<script>
  /**
  * Компонент отображения спсика
   */
  import urls from "@/urls/management";
  import {loadData} from "@/helper";
  import StudentLinkToDetail from "../student/LinkToDetail";
  import DebugJsonComponent from "../../../core/components/DebugJsonComponent";
  import StudentBase from "../student/Base";
  import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
  import {generateListUidToName} from "@/helper/uidToName";
  import names from "@/modules/educationalManagement/routers/names";
  import OneFieldBaseSearchMixin from "@/mixins/OneFieldBaseSearchMixin";
  import TitledPageMixin from "../../../../mixins/TitledPageMixin";

  export default {
    name: "ActualStudentList",
    components: {
      DebugJsonComponent,
      StudentLinkToDetail,
      StudentBase
    },
    mixins: [PaginatedDataMapperMixin, OneFieldBaseSearchMixin, TitledPageMixin],
    data: function(){
      return {
        route_name: 'EducationalManagementActualStudentList',
        page_title: "Список обучающихся",
        loadErrorMsg: '',
        students: {
          items: [],
          headers: [
            {text: "ФИО", sortable: false, align: 'left', width: '25%',value:'fio'},
            {text: "Уровень", sortable: false, align: 'left', value:'direction.level'},
            {text: "Форма", sortable: false, align: 'left', value:'form'},
            {text: "Финансирование", sortable: false,  align: 'center', value:'financing'},
            {text: "Год поступления", sortable: false,  align: 'center', value:'entry_year'},
            {text: "Курс",sortable: false, align: 'center', value:'course'},
            {text: "Архивная", sortable: false,  align: 'center', value:'active'},
            {text: "Направление: Профиль подготовки", sortable: false,  align: 'left', width: '45%', value:'profile.name'},
          ],
        },
        search: {
          parameter: 'fio',
        },
      }
    },
    computed: {
      is_self_route: function() {
        return this.$route.name === names.EDUCATIONAL.STUDENTS.LIST
      }
    },
    methods: {
      loadData(url=null){
        /*
        * Загрузка данных для отобоажения списка
         */
        this.setLoading();
        if(url === null)
          url = urls.EDUCATION.STUDENT.LIST();
        loadData(url, (data) => {
          this.students.items = data.results;
          this.setNotLoading();
          this.setCurrentLoadUrl(url);
          this.mapPagination(data)
          for (let i in this.students.items) {
            this.$set(this.students.items[i], "fio","")
          }
          generateListUidToName(this.students.items,"student","fio")
          ;
        })
      }
    },
    created() {
      this.loadData();
    }
  }
</script>

<style scoped>

</style>
