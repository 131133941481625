import AcademicYear from "../pages/academic_year/AcademicYear";
import Session from "../pages/academic_year/Session";
import YearSemester from "../pages/academic_year/YearSemester";

import names from "@/modules/educationalManagement/routers/names";
import {EDUCATION_INSTANCE, ACCOUNTS_INSTANCE} from "@/helper/consts";

// const accounts = String(process.env.VUE_APP_ACCOUNTS_PREFIX).replace('/', '');
//
// const education = String(process.env.VUE_APP_EDUCATION_PREFIX).replace('/', '');

export default ([
  {
    path: 'academic/year',
    name: names.EDUCATIONAL.ACADEMIC_YEAR.LIST,//'EducationManagementAcademicYear',
    component: AcademicYear,
    meta: {
      breadcrumb: 'Учебный год',
      requiredAuth: true,
      instances: [
        ACCOUNTS_INSTANCE,
        EDUCATION_INSTANCE,
      ],
    },
    children: [
      {
        path: ':idYear([0-9]+)/semester',
        name: names.EDUCATIONAL.ACADEMIC_YEAR.SEMESTER.DETAIL,//'EducationManagementYearSemester',
        component: YearSemester,
        meta: {
          breadcrumb: 'Семестр учебного года',
          requiredAuth: true,
          instance: [
            ACCOUNTS_INSTANCE,
            EDUCATION_INSTANCE,
          ],
        },
        children: [
          {
            path: ':idSemesters([0-9]+)/session',
            name: names.EDUCATIONAL.ACADEMIC_YEAR.SEMESTER.SESSION,//'EducationManagementSession',
            component: Session,
            meta: {
              breadcrumb: 'Сессия',
              requiredAuth: true,
              instance: [
                ACCOUNTS_INSTANCE,
                EDUCATION_INSTANCE,
              ]
            },
          },
        ]
      },
    ]
  },
])


