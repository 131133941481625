<template>
  <TaughtGroups v-if="taught && is_self_route"></TaughtGroups>
  <ArchiveGroups v-else-if="archive && !taught && is_self_route"></ArchiveGroups>
  <Groups v-else-if="!taught && !archive && is_self_route"></Groups>
  <router-view v-else></router-view>
</template>

<script>
  import names from "../../routers/names";
  import Groups from "./Groups";
  import TaughtGroups from "./TaughtGroups";
  import ArchiveGroups from "./ArchiveGroups";

  export default {
    name: "GroupsListBase",
    components: {
      Groups, TaughtGroups, ArchiveGroups,
    },
    computed: {
      taught: function () {
        return this.$route.params.taught === 'taught'
      },
      archive: function () {
        return this.$route.params.archive === 'archive'
      },
      is_self_route: function () {
        return names.CathedraGroups === this.$route.name
      },
    },
    created() {
      // console.log('GroupsListBase')
      // console.log(this.taught)
      // console.log(this.$route.params)
    }
  }
</script>

<style scoped>

</style>



