<template>
  <scroll-center-dialog v-model="opened"
                        width="600"
                        @close="$emit('close', false)"
                        @apply="save"
                        title="Заменить учебный план"
  >
    <v-autocomplete
      class="pa-1 ma-1 pt-4"
      outline
      :items="selectors.curriculum"
      no-data-text="Нет данных"
      label="Учебный план"
      v-model="curriculum_data"
      :error="hasError('curriculum')"
      :error-messages="getError('curriculum')"
    ></v-autocomplete>
    <!--      <DebugJsonComponent :json="curriculum"></DebugJsonComponent>-->
  </scroll-center-dialog>
</template>

<script>

import DebugJsonComponent from "@/modules/core/components/DebugJsonComponent"
import UserDataFormMixin from "@/mixins/UserDataFormMixin"
import selectors from "@/urls/selectors"
import {makeGetRequest, makeParticalUpdateRequest} from "@/helper";
import urls from "@/urls/dean"
import ScrollCenterDialog from "@/modules/core/components/ScrollCenterDialog";


export default {
  name: "ChangeCurriculumForm",
  mixins: [
    UserDataFormMixin,

  ],
  components: {
    ScrollCenterDialog,
    DebugJsonComponent
  },
  data() {
    return {
      URL_SELECT_CURRICULUM: selectors.SELECTORS.DEAN.CURRICULUM(this.$route.params.idFaculty, this.level),
      URL_UPDATE_CURRICULUM: urls.DEAN.CURRICULUM.UPDATE_CURRICULUM(this.$route.params.idFaculty, this.$route.params.idGroup),
      opened: true,
      selectors: {
        curriculum: [],
      },
      curriculum_data: null,
      error: null,
    }
  },
  props:{
    curriculum: {
      required: false,
      default: '',
    },
    level: {
      required: false,
      default: '',
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
    loadSelector(data, url) {
      let sys = true
      makeGetRequest(url).then(resp => {
        if (!resp.ok) {
          sys = false
        }
        return resp.json()
      }).then(json => {
        this.selectors[data] = json
      })
    },
    save(){
      let sys = true
      makeParticalUpdateRequest(
        this.URL_UPDATE_CURRICULUM,
        {'curriculum': this.curriculum_data}
      ).then(resp => {
        if (!resp.ok) {
          sys = false
        }
        else{
          this.$emit('refreshItem')
        }
        return resp.json()
      }).then(json => {
        if (!sys) {
          // console.log(json)
          this.errors = json
        }

      })
    },

  },
  created() {
    this.loadSelector('curriculum', this.URL_SELECT_CURRICULUM)
    this.curriculum_data = this.curriculum.id
    // alert(this.curriculum_data)
  }
}
</script>

<style scoped>

</style>
