<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="8" offset-md="2">
        <v-timeline
          :dense="$vuetify.breakpoint.smAndDown"
        >
          <v-timeline-item
            icon="person"
          >
            <v-card>
              <v-row class="mx-2">
                <v-col cols="auto" class="text-left">Общее количество обучающихся:</v-col>
                <v-col cols="auto" class="text-right grow">{{cathedra.student_count}}</v-col>
              </v-row>
            </v-card>
          </v-timeline-item>
          <v-timeline-item
            icon="group"
          >
            <v-card>
              <v-row class="mx-2">
                <v-col cols="auto" class="text-left">Количество выпускаемых групп кафедры:</v-col>
                <v-col cols="auto" class="text-right grow">{{cathedra.study_group_count}}</v-col>
              </v-row>
            </v-card>
          </v-timeline-item>
          <v-timeline-item
            icon="groups"
          >
            <v-card>
              <v-row class="mx-2">
                <v-col cols="auto" class="text-left">Количество групп, которым кафедра преподает:</v-col>
                <v-col cols="auto" class="text-right grow">{{cathedra.current_group_count}}</v-col>
              </v-row>
            </v-card>
          </v-timeline-item>
          <v-timeline-item
            icon="menu_book"
          >
            <v-card>
              <v-row class="mx-2">
                <v-col cols="auto" class="text-left">Количество преподаваемых кафедрой дисциплин:</v-col>
                <v-col cols="auto" class="text-right grow">{{cathedra.current_subjects_count}}</v-col>
              </v-row>
            </v-card>
          </v-timeline-item>
          <v-timeline-item
            icon="access_time"
          >
            <v-card>
              <v-row class="mx-2">
                <v-col cols="auto" class="text-left">Общее количество часов преподавания:</v-col>
                <v-col cols="auto" class="text-right grow">{{cathedra.current_hours_count}}</v-col>
              </v-row>
            </v-card>
          </v-timeline-item>
          <v-timeline-item
            icon="school"
          >
            <v-card>
              <v-row class="mx-2">
                <v-col cols="auto" class="text-left">Общее количество преподавателей:</v-col>
                <v-col cols="auto" class="text-right grow">{{cathedra.current_teacher_count}}</v-col>
              </v-row>
            </v-card>
          </v-timeline-item>
        </v-timeline>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6" xl="4" v-for="(item, index) in getLeftMenu()" :key="`cathedra_card_${index}`">
        <router-link :to="getRouterParam(item)">
          <v-card
            elevation="3"
            class="blue lighten-4 elevation-10 ma-2 pa-2 d-flex fill-height"
            min-height="100px"
          >
            <v-row class="black--text title my-auto">
              <v-col style="font-size: 24px;">
                {{ item.title }}
              </v-col>
            </v-row>
          </v-card>
        </router-link>
      </v-col>
    </v-row>

<!--    <DebugJsonComponent :json="cathedra"></DebugJsonComponent>-->
  </v-container>
</template>

<script>
  import DebugJsonComponent from "../../../core/components/DebugJsonComponent";
  import TitledPageMixin from "@/mixins/TitledPageMixin";
  import names from "../../routers/names";
  import cathedras from "@/urls/cathedras";
  import {loadData} from "@/helper";

  export default {
    name: "CathedraDetail",
    mixins: [TitledPageMixin],
    components: {
      DebugJsonComponent
    },
    data() {
      return {
        page_title: 'Кафедра',
        cathedra: {},
        cathedra_uid: this.$route.params.cathedra_uid,
      }
    },
    computed: {
      cathedra_name: function () {
        return this.$parent.name_cathedra;
      }
    },
    methods: {
      loadDetail(){
        loadData(
          cathedras.CATHEDRA.DETAIL(this.cathedra_uid),
          (data) => {
            this.cathedra = data
            this.page_title = this.cathedra.name
          }
        )
      },
      getRouterParam(item){
        if(item.router.name !== undefined)
          return item.router;
        else
          return {name: item.router }
      },
      getLeftMenu() {
        let left = []
        let mass = this.$store.getters["leftmenu/getLeftMenu"]
        for (var i=2; i < mass.length; ++i) {
          if (!mass[i].subs) {
            left.push(mass[i])
          }
          else {
            if (mass[i].subs) {
              left = left.concat(this.getLeftSubs(mass[i].subs))
            }
          }
        }
        return left
      },
      getLeftSubs(second_menu) {
        let subs = []
        for (var i=0; i < second_menu.length; ++i) {
          subs.push(second_menu[i])
        }
        return subs
      },
    },
    created() {
      this.loadDetail();
    }
  }
</script>

<style scoped>
</style>
