export default {
  data() {
    return {
      errors: {
        fields: {},
        non: []
      },

    }
  },
  methods: {
    clearErrors: function () {
      this.errors.fields = {};
      this.errors.non = {};
    },
    mapErrors: function (data) {
      if(data.non_field_errors !== undefined)
        if(data.non_field_errors instanceof Array)
          for(let err of data['non_field_errors'])
            this.addNonFieldError(err);
        else
            this.addNonFieldError(data['non_field_errors']);
      this.errors.fields = data;
    },
    hasError: function (field) {
      if (field in this.errors.fields && this.errors.fields[field] !== undefined) {
        if (this.errors.fields[field].length > 0);
          return true;
      }
      return false;
    },
    getError: function (field) {
      if (field in this.errors.fields && this.errors.fields[field] !== undefined) {
        if (this.errors.fields[field].length > 0) {
          return this.errors.fields[field];
        }
      }
      return null;
    },
    deleteError: function (field, id=undefined) {
      delete this.errors.fields[field];
      if(id !== undefined) {
        this.$delete(this.errors.fields[id], field);
        if (Object.keys(this.errors.fields[id]).length === 0) {
          this.$delete(this.errors.fields, id);
        }
      }else{
        delete this.errors.fields[field];
      }
    },
    deleteNonFieldError: function (error) {
      let errors = this.errors.non;
      errors.splice(errors.indexOf(error), 1);
      this.$set(this.errors, 'non', errors);
    },
    addNonFieldError: function (error) {
      let errors = this.errors.non;
      if (errors.indexOf(error))
        errors.push(error);
      this.$set(this.errors, 'non', errors);
    },
  }
}
