import urls from "../urls";
import {makeGetRequest} from "../../../helper";

export default {
  data() {
    return {
      items: [],
      pagination: {},
      loadingPage: true,
    }
  },
  methods: {
    getUrl: async function (name, action) {
      return urls.getUrl(name, action)
    },
    replaceUrl: function (url, whatToReplace, onThat) {
      let newUrl = url.replace(whatToReplace, onThat);
      return newUrl;
    },

    loadData: function (urlName, urlAction, isNeedChange) {
      this.getUrl(urlName, urlAction)
        .then((url) => {
          if (isNeedChange) {
            let newUrl = this.replaceUrl(url,
              '<cathedra_id>',
              this.$route.params.id);
            return newUrl;
          } else
            return url;
        }).then((urlS) => {

        makeGetRequest(urlS)
          .then((resp) => {
            return resp.json();
          })
          .then((json) => {
            this.items = json;
          })
          .finally(() => {
            this.loadingPage = false;
          })
      })
    }
  }
}
