<template>
  <v-container v-if="$route.name === names.CathedraListSubjects">
    <v-card >
      <v-toolbar :extended='$vuetify.breakpoint.smAndDown'
                 elevation="0"
                 color="style-color-main-gray"
                 :style="{'height':`${$vuetify.breakpoint.mdAndUp ? 64: 150}px`} "
      >
        <v-toolbar-title>Список всех дисциплин кафедры:</v-toolbar-title>


        <template v-if="$vuetify.breakpoint.mdAndUp" >
          <v-text-field class="pa-6"
            dense
            placeholder="Поиск"
            v-model="search.value"
            @keypress.enter="searchData"
            @click:clear="clearSearchData"
            clearable
            messages="Для поиска нажмите Enter"
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-select
            class="py-0 pa-6"
            :items="selectors.year"
            v-model="pagination.filters.year"
            item-value="text"
            placeholder="Год не выбран"
            clearable
          ></v-select>
        </template>

        <template v-slot:extension v-if='$vuetify.breakpoint.smAndDown'>
          <v-col>
            <v-text-field
              class="pt-6"
              dense
              placeholder="Поиск"
              v-model="search.value"
              @keypress.enter="searchData"
              @click:clear="clearSearchData"
              clearable
              messages="Для поиска нажмите Enter"
            ></v-text-field>

            <v-select
              class="py-0 pa-6"
              :items="selectors.year"
              v-model="pagination.filters.year"
              item-value="text"
              placeholder="Год не выбран"
              clearable
            ></v-select>
          </v-col>
        </template>
      </v-toolbar>

      <v-card-text class="pa-0 pt-1 pb-3">
        <v-layout justify-center>
          <v-flex>
            <v-data-table
              :headers="headers"
              :search.sync="search.value"
              @searchEnter="searchData"
              :items="items"
              no-data-text="Нет данных"
              :page.sync="pagination.page"
              :items-per-page.sync="pagination.size"
              :server-items-length="pagination.count"
              :footer-props="pagination.footer_page"
            >
              <v-progress-linear v-slot:progress color="blue" indeterminate></v-progress-linear>
              <template v-slot:item="props">
                <tr  class="text-left" style="cursor: pointer">
                  <td @click="getDetail(props.item)" class="justify-center">{{props.item.subject}} </td>
                  <td @click="getDetail(props.item)" class="justify-center">{{props.item.curriculum.year}} </td>
                  <td @click="getDetail(props.item)" class="justify-center">
                    {{props.item.curriculum.direction.code}} {{props.item.curriculum.direction.name}},
                    {{props.item.curriculum.direction.level}}
                  </td>
                  <td @click="getDetail(props.item)" class="justify-center">{{props.item.curriculum.profile}} </td>
                  <td @click="getDetail(props.item)" class="justify-center">{{props.item.curriculum.form}} </td>

                  <td class="justify-center">
                    <v-btn v-if="props.item.work_program !== null" icon>
                      <a :href="props.item.work_program">
                        <v-icon color="blue">get_app</v-icon>
                      </a>
                    </v-btn>
                    <v-icon v-else>remove</v-icon>
                  </td>
                  <td class="justify-center">
                    <v-btn v-if="props.item.appraisal_tools !== null" icon>
                      <a :href="props.item.appraisal_tools">
                        <v-icon color="blue">get_app</v-icon>
                      </a>
                    </v-btn>
                    <v-icon v-else>remove</v-icon>
                  </td>
                  <td class="justify-center">
                    <v-btn v-if="props.item.methodological_materials !== null" icon>
                      <a :href="props.item.methodological_materials">
                        <v-icon color="blue">get_app</v-icon>
                      </a>
                    </v-btn>
                    <v-icon v-else>remove</v-icon>
                  </td>
                </tr>
              </template>
              <template slot="pageText" slot-scope="item">
                {{item.pageStart}} - {{item.pageStop}} из {{item.itemsLength}}
              </template>
              <template slot="no-data">
                <v-card-text>Данные не загружены</v-card-text>
              </template>
            </v-data-table>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
  </v-container>
  <router-view :key="$route.fullPath" v-else></router-view>
</template>

<script>
import urls from "@/modules/teacher/urls.js"
import UserDataMixin from '@/mixins/UserDataMixin'
import {loadData, makeGetRequest,} from "@/helper";
import names from "@/modules/cathedra/routers/names";
import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
import TitledPageMixin from "../../../../mixins/TitledPageMixin";

export default {
  name: "ListAdmissions",
  mixins: [UserDataMixin, PaginatedDataMapperMixin, TitledPageMixin],
  data () {
    return {
      page_title: 'Список дисциплин',
      names:names,
      items: [],
      // year:'Год не выбран',
      search: {
        parameter: 'info',
      },
      selectors:{
        year:[],
      },
      pagination: {
        filters: {
          year: null,
        },
      },
      URL_LIST: urls.getUrl('administrative|curriculum|subject-list', 'list')
        .replace('<cathedra_uid>', this.$route.params.cathedra_uid.toLowerCase()),
      headers: [
        {text: 'Предмет', sortable: false, align: 'justify-center',},
        {text: 'Год', sortable: false, align: 'justify-center',},
        {text: 'Направление', sortable: false, align: 'justify-center',},
        {text: 'Профиль', sortable: false, align: 'justify-center',},
        {text: 'Форма', sortable: false, align: 'justify-center',},
        {text: 'Файл РПД', sortable: false, align: 'justify-center',},
        {text: 'Файл ОС', sortable: false, align: 'justify-center',},
        {text: 'Файл МУ', sortable: false, align: 'justify-center',},
      ]
    }
  },
  watch: {
  },
  created() {
    this.setPageTitleWithObject("Cathedra", {uid: this.$route.params.cathedra_uid}, ' - ')
    this.loadData()
    this.loadSelector()
  },

  methods: {
    closedForm: function () {
      this.selected = {};
      this.opened_form = false
      this.loadData();
    },
    getDetail(item){
      this.$router.push({name: names.CathedraSubjectsDetail, params: {idDoc: item.id}})
    },
    addItem(){
      this.closedForm()
    },
    loadSelector() {
      let year = (new Date()).getFullYear()+1
      for(let i = year; i >= 2010; i--){
        this.selectors.year.push({text: i,value: i})
      }
    },
    loadData(url=undefined) {
      this.setLoading();
      if(url === undefined) {
        url = this.addFiltersParameter(this.URL_LIST);
        this.setCurrentLoadUrl(url);
      }
      loadData(
        url,
        (data) => {
          this.mapResults(data, 'items')
          this.setNotLoading();
        }
      )
    },
  }
}


</script>

<style scoped>

</style>
