<template>
  <v-container v-if="journal.loaded">
    <v-card>
      <v-toolbar :extended='$vuetify.breakpoint.xsOnly'
                 class="mb-2"
                 color="style-color-main-gray"
                 flat
                 :style="{'height':`${$vuetify.breakpoint.smAndUp ? 64: 150}px`} "
      >
        <v-toolbar-title>
          Журнал обучающегося
        </v-toolbar-title>

        <template v-if="$vuetify.breakpoint.smAndUp" >
          <v-select :items="journal.data" item-text="name" return-object v-model="journal.selected" class="ml-3" hide-details dense style="min-width: 280px;" filled>
          </v-select>
          <v-spacer></v-spacer>
          <v-text-field v-model="search" clearable placeholder="Поиск" hide-details style="min-width: 100px;" prepend-inner-icon="search"   dense hint="" filled></v-text-field>
        </template>

        <template v-slot:extension v-if='$vuetify.breakpoint.xsOnly'>
          <v-col>
            <v-select :items="journal.data" item-text="name" return-object v-model="journal.selected" class="pt-6 pb-2" hide-details dense style="min-width: 280px;" filled>
            </v-select>
            <v-text-field class="pt-2" v-model="search" clearable placeholder="Поиск" hide-details style="min-width: 100px;" prepend-inner-icon="search"   dense hint="" filled></v-text-field>
          </v-col>
        </template>
      </v-toolbar>
      <v-row>
        <v-col>
          <JournalTableTemplate
            :rows="journal.selected.subjects"
            :load_half="true"
            first_row_label="Предмет"
            first_row_value="subject_name"
            :can_update="false"
            :search="search"
            :loading="journal.loading"
            :errors="[]"
          >
          </JournalTableTemplate>
        </v-col>
      </v-row>
    </v-card>

  </v-container>
  <v-container v-else>
    <v-row>
      <v-col>
        <v-skeleton-loader
          height="50"
          type="list-item"
        >
        </v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-skeleton-loader
          height="200"
          type="card"
        >
        </v-skeleton-loader>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import urls from "@/urls/student"
import {loadData} from "@/helper";
import JournalTableTemplate from "@/modules/templates/journal/JournalTableTemplate";
import DebugJsonComponent from "@/modules/core/components/DebugJsonComponent";

export default {
  name: "FinalJournal",
  components: {
    DebugJsonComponent,
    JournalTableTemplate,
  },
  computed: {
    filteredBySemesterJournal() {
      return this.journal.filter(el => el.semester_name === this.semester_selected)
    }
  },
  data: function () {
    return {
      title: 'Итоговый журнал успеваемости обучающегося',
      journal: {
        student_id: this.$route.params.student_id,
        data: [],
        selected: {subjects: []},
        loading: false,
        loaded: false,
      },
      search: '',
    }
  },
  methods: {
    loadJournal() {
      loadData(
        urls.STUDENT.FULL_JOURNAL(this.$route.params.idStudent),
        (data) => {
          for(let course of data.courses){
            for(let semester of course.semesters){
              this.journal.data.push({
                name: `${course.number} курс ${semester.name} семестр`,
                subjects: semester.subjects,
              })
            }
          }
          if(this.journal.data.length > 0)
            this.journal.selected = this.journal.data[0]
          this.journal.loaded = true;
        }
      )
    },
  },
  created() {
    this.$store.commit('mainpage/changeTitle', this.title);
    this.loadJournal();
  }
}
</script>

<style scoped>

</style>

