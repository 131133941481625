<template>
  <scroll-center-dialog v-model="opened_form"
                        @close="closeDialog"
                        :title="!!id ? 'Изменить' : 'Добавить'"
                        :dennyable="false"
                        :applyable="false"
                        :top-color="'style-color-main-gray'"
                        dark="''"
  >
    <v-alert color="warning" v-if="alertMsg!==''">
      {{ alertMsg }}
    </v-alert>
    <v-card-text v-for="(item,i) in value.transfers">
      <v-layout>
        <v-layout column>
          <v-autocomplete
            :items="selectors.faculty"
            label="Перевод на факультет"
            v-model="item.to_department"
            no-data-text="Нет данных"
            :error="hasError(item,'to_department')"
            :error-messages="getError(item,'to_department')"
          ></v-autocomplete>
          <v-layout>
            <loading-autocomplete
              placeholder="Начните вводить ФИО обучающегося"
              label="Обучающийся"
              v-model="item.education"
              :error="hasError(item,'education')"
              :error-messages="getError(item,'education')"
              search-parameter="fio"
              :url="faculty_selector"
            >
              <template v-slot:no-data>
                <div>
                  Для поиска начните набирать ФИО обучающегося, </br> и либо остановитесь на 1 секунду, либо нажмите
                  Enter
                </div>
              </template>
            </loading-autocomplete>
            <v-btn @click="deleteTransfer(i)" text icon v-if="i !==0 || value.transfers.length >1 ">
              <v-icon>remove</v-icon>
            </v-btn>
          </v-layout>

          <v-text-field
            v-model="item.order"
            label="Приказ"
            :error="hasError(item,'order')"
            :error-messages="getError(item,'order')"
          ></v-text-field>

          <v-text-field
            v-model="item.comment_from"
            label="Комментарий"
            :error="hasError(item,'comment_from')"
            :error-messages="getError(item,'comment_from')"
          ></v-text-field>
        </v-layout>
      </v-layout>
    </v-card-text>
    <template v-slot:actions>
      <v-btn @click="addTransfer" text>
        <v-icon>add</v-icon>
        Добавить обучающегося
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn @click="createTransfer" class="pa-0 ma-0" text>
        <v-icon>save</v-icon>
        Сохранить
      </v-btn>
    </template>
  </scroll-center-dialog>
</template>

<script>
import urls from "../../urls"
import dean_urls from "@/urls/dean";
import selectors from "@/urls/selectors";
import UserDataFormMixin from '../../../../mixins/UserDataFormMixin'
import {clearUserAuth, makeGetRequest, makePostRequest, sendGetRequest} from "@/helper";
import {generateListUidToName} from "@/helper/uidToName";
import LoadingAutocomplete from "@/modules/core/components/LoadingAutocomplete";
import ScrollCenterDialog from "@/modules/core/components/ScrollCenterDialog";
// import {makeGetRequest} from "../../../helper";

export default {
  name: "TransferStudentFrom",
  components: {ScrollCenterDialog, LoadingAutocomplete},
  mixins: [UserDataFormMixin],
  props: {
    id: Number,
    opened_form: Boolean,
  },
  data() {
    return {

      URL_CREATE: dean_urls.DEAN.TRANSFER.OUTGOING.CREATE(this.$route.params.idFaculty),
      URL_UPDATE: dean_urls.DEAN.TRANSFER.OUTGOING.UPDATE(this.$route.params.idFaculty),
      selectors:{
        faculty: [],
        student: [],
        students: {
          loading: false,
          list: [],
          search: '',
          timeout: null,
        },
      },
      alertMsg:'',
      loadingPage:false,
      value: {
        transfers:[
          {
            education: null,
            to_department: null,
            comment_from: "",
            order: ""
          }
        ]
      },
      // errors: {}
    }
  },
  computed: {
    faculty_selector(){
      return selectors.URLS.FACULTY.STUDENTS(
        this.$route.params.idFaculty
      )
    }
  },
  watch: {},
  methods: {
    hasError: function (obj,field) {
      if(this.errors.errors){
        let answ = this.errors.errors.find(item => (item.education === obj.education));
        if(answ){
          if(answ.message[field]){
            return true;
          }
        }else {
          return true;
        }
      }
      return false;
    },
    getError: function (obj,field) {
      if(this.errors.errors){
        let answ = this.errors.errors.find(item => (item.education === obj.education));
        if(answ) {
          if (answ.message[field]) {
            return answ.message[field];
          }
        }else {
          return null;
        }
      }
      return null;
    },
    createTransfer: function () {
      let url = this.getCreateUrl();
      let data = this.getData();
      let status = true;
      makePostRequest(url, data)
        .then(resp => {
          if (!resp.ok) {
            status = false;
          }
          if (resp.status === 401) {
            clearUserAuth()
          }
          return resp.json();
        })
        .then(json => {
          if (status) {
            this.$emit('addItem', json);
          } else {
            this.receiveErrors(json);
            let countAssept = this.value.transfers.length
            let countErr = this.errors.errors.length
            this.alertMsg = "Загружено " + (countAssept - countErr) + " из "+ countAssept

            let result = this.value.transfers.filter(transfers => {
              let answ = false
              answ = this.errors.errors.find(item => (item.education === transfers.education));
              return Boolean(answ)
            });
            this.value.transfers = (result)
          }
        })

    },
    addTransfer(){
        this.value.transfers.push({
          education: null,
          to_department: null,
          comment_from: ""
        })
    },
    deleteTransfer(item){
      if (this.value.transfers.length > 1){
        this.value.transfers.splice(item,1)
      //   console.log(this.value.transfers)
      }
    },
    loadSelector(data, url) {
      makeGetRequest(url).then(resp => {
        return resp.json()
      }).then(json => {
        this.selectors[data] = json
      })
    },
    closeDialog() {
      this.$emit('ended', '')
    },
    // searchStudents(item, timeout=true){
    //   if(this.selectors.students.timeout !== null)
    //     clearTimeout(this.selectors.students.timeout);
    //   // console.log(this.selectors.students.list.length === 1 && this.selectors.students.list[0].text.includes(this.selectors.students.search))
    //   // console.log(this.selectors.students.list.length === 1 && this.selectors.students.list[0].value === item.education)
    //   if(this.selectors.students.list.length === 1)
    //     console.log(this.selectors.students.list.length, this.selectors.students.list[0].value, item)
    //   if(this.selectors.students.list.length === 1 && this.selectors.students.list[0].value === item.education)
    //     return;
    //   if(!(this.selectors.students.list.length === 1 && this.selectors.students.list[0].text.includes(this.selectors.students.search))) {
    //     if (this.selectors.students.search) {
    //       if (timeout) {
    //         this.selectors.students.timeout = setTimeout(
    //           () => {
    //             // console.log('yeeeeeee', this.selectors.students.search);
    //             this.loadStudentWhenSearch();
    //           },
    //           1000
    //         )
    //       }else{
    //         this.loadStudentWhenSearch();
    //       }
    //     }
    //     if (this.selectors.students.list.length > 0) {
    //       this.selectors.students.list = []
    //     }
    //   }
    // },
    // loadStudentWhenSearch(){
    //   this.selectors.students.loading = true;
    //   let url = selectors.URLS.FACULTY.STUDENTS(
    //     this.$route.params.idFaculty,
    //     {
    //       size: 25,
    //       fio: this.selectors.students.search,
    //       // group: this.selectors.students.search
    //     }
    //   )
    //   sendGetRequest(url,
    //     data => {
    //       // console.log('LOADED')
    //       data.results.forEach(el => {
    //         this.$set(el, 'fio', '')
    //         let text = el.text.split(', ');
    //         this.$set(el, 'student', text[0])
    //         this.$set(el, 'text', text[1])
    //       })
    //       this.selectors.students.list = data.results;
    //       generateListUidToName(
    //         this.selectors.students.list,
    //         "student",
    //         "fio",
    //         ()=>{
    //           this.selectors.students.list.forEach(el => {
    //             this.$set(el, 'text', `${el.fio}, ${el.text}`)
    //           })
    //           this.selectors.students.loading = false;
    //         }
    //         )
    //     }
    //   );
    // },
    // load: function (app, url, data) {
    //   if (url === null) {
    //     generateListUidToName(app.selectors[data],"student","fio",()=>{ app.loadingPage = true;})
    //     return;
    //   }
    //   makeGetRequest(url)
    //     .then(resp => resp.json())
    //     .then(json => {
    //       let next_url = null;
    //       if (json['next'] !== null)
    //         next_url =  json['next'] + '&' +  "size=100"
    //       for (let i in json['results']) {
    //           app.selectors[data].push(Object.assign({}, json['results'][i]   ));
    //         }
    //         app.load(app, next_url, data);
    //     });
    // },
  },
  created() {
    // let url = urls.getUrl('selectors|relations|faculty',  'get')

    this.loadSelector("faculty",selectors.URLS.FACULTIES())


     // let url = new URL(urls.getUrl('dean|students|education|from_faculty-list', 'list')
     //   .replace('<faculty_uid>', this.$route.params.idFaculty))

    // let params = {};
    // params.size = "50";
    // url.search = new URLSearchParams(params).toString();
    // console.log(url)
    // this.load(this, url, "student")
  }
}

</script>

<style scoped>

</style>
