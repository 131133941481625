<template>
  <v-container style="height: 100%" v-if="is_self_route">
    <v-card flat>
      <v-toolbar flat class="title" color="style-color-main-gray">
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn @click="opened_form = true" color="secondary" text>
            <v-icon>add</v-icon>
            Добавить
          </v-btn>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                text
                v-on="on"
                @click="getItems()"
                class="mr-2"
                medium
              >
                <v-icon>
                  update
                </v-icon>
              </v-btn>
            </template>
            <span>Обновить данные</span>
          </v-tooltip>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="pb-3 pt-0 mx-0 px-0">
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="pagination.loading"
          :page.sync="pagination.page"
          :items-per-page.sync="pagination.size"
          :server-items-length="pagination.count"
          class="elevation-1"
          no-data-text="Нет данных"
          :footer-props="{'items-per-page-options':[10,25,50], 'items-per-page-text': 'Число элементов'}"
        >
          <template v-slot:item.sessions="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn small text outlined @click="getDetail(item)" v-on="on" class="mr-2" width="100%">
                  <v-icon
                    small
                    class="mr-2"
                  >
                    list
                  </v-icon>
                  Перечень сессий
                </v-btn>
              </template>
              <span>Перечень сессий семестра учебного года</span>
            </v-tooltip>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  v-on="on"
                  @click="updateItem(item)"
                  class="mr-2"
                  medium
                >
                  <v-icon>
                    edit
                  </v-icon>
                </v-btn>
              </template>
              <span>Редактировать</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  v-on="on"
                  @click="openConfirmDelete(item)"
                  medium
                >
                  <v-icon>
                    delete
                  </v-icon>
                </v-btn>
              </template>
              <span>Удалить</span>
            </v-tooltip>
          </template>
          <template v-slot:no-data>
            <v-card-text>Данные не загружены</v-card-text>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <YearSemester @addItem="addItem"
                  @ended="closedForm"
                  @refreshItem="refreshItem"
                  @input="closedForm"
                  :opened_form="opened_form"
                  v-bind="selected"
                  v-bind:info="$route.params.id"
                  v-if="opened_form"
    >
    </YearSemester>
    <v-dialog persistent v-model="opened_confirm" width="500">
      <v-card v-if="deletable">
        <v-card-title>Подтвердите удаление</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-4">
          Вы уверены, что хотите удалить?<br>
        </v-card-text>
        <v-card-actions>
          <v-flex>
            <v-btn
              @click="deleteItem(deletable.id)"
              color="warning"
              text
            >
              Удалить
            </v-btn>
            <v-btn
              @click="closeConfirmDelete"
              text
            >Отмена
            </v-btn>
          </v-flex>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
  <router-view :key="$route.fullPath" v-else></router-view>
</template>

<script>
  import YearSemester  from "../../forms/academic_year/YearSemester"
  import UserDataMixin from '@/mixins/UserDataMixin'
  import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
  import {loadData, makeDeleteRequest, makeGetRequest} from "@/helper";
  import urls from "@/urls/management";
  import names from "@/modules/educationalManagement/routers/names";

  export default {
    name: "ListCourses",
    components: {YearSemester},
    mixins: [UserDataMixin, PaginatedDataMapperMixin],
    props: {
      isDetail: Boolean,
    },
    computed: {
      is_self_route: function() {
        this.$store.commit('mainpage/changeTitle', 'Список учебных семестров');
        return this.$route.name === names.EDUCATIONAL.ACADEMIC_YEAR.SEMESTER.DETAIL
      },
    },
    data () {
      return {
        URL_LIST: urls.EDUCATION.ACADEMIC_YEAR.YEAR_SEMESTER.LIST(this.$route.params.idYear),
        items: [],
        pagination: {},
        headers: [
          {text: 'Список сессий', sortable: false, align: 'left', value: 'sessions', width: '15%'},
          {text: 'Уровень образования', sortable: false, align: 'center', value: 'level',},
          {text: 'Семестр', sortable: false, align: 'center', value: 'semester',},
          {text: 'Год', sortable: false, align: 'center', value: 'year',},
          {text: 'Действия', sortable: false, align: 'right', value: 'actions', width: '10%'},
        ]
      }
    },
    watch: {
    },
    created() {
      this.getItems();
      this.$store.commit('mainpage/changeTitle', 'Cеместр учебного года');
    },
    methods: {
      getDetail(item){
        this.$router.push({
          name: names.EDUCATIONAL.ACADEMIC_YEAR.SEMESTER.SESSION,
          params: {
            idYear: this.$route.params.idYear,
            idSemesters: parseInt(item.id)
          }
        })
      },
      deleteItem(id) {
        let url = urls.EDUCATION.ACADEMIC_YEAR.YEAR_SEMESTER.DELETE(this.$route.params.idYear,id)
        makeDeleteRequest(url).then(resp => {
          if (resp.ok) {
            this.closeConfirmDelete()
            this.getItems();
          }
        })
      },
      addItem(){
        this.closedForm()
        this.getItems();
      },
      loadData(url = undefined) {
        this.getItems(url)
      },
      getItems (url=undefined) {
        this.setLoading();
        if(url === undefined) {
          url = this.URL_LIST
          this.setCurrentLoadUrl(url);
        }
        loadData(
          url,
          (data) => {
            this.mapResults(data,'items')
            this.setNotLoading();
          }
        )
      },
    }
  }

</script>
<style scoped>

</style>
