
const ADMIN = {
  BASE: 'Admin',
  REFERENCE: {
    _BASE: undefined,
    CATHEDRAS: undefined,
    FACULTIES: undefined,
    DIRECTIONS: undefined,
    PROFILES: undefined,
    SUBJECTS: undefined
  },
  EDUCATION: {
    _BASE: undefined,
    LIST: undefined,
  },
  GROUP: {
    _BASE: undefined,
    LIST: undefined
  },
  FACE: {
    _BASE: undefined,
    LIST: undefined,
  },
  CURRICULUM: {
    _BASE: undefined,
    LIST: undefined,
  }
};

ADMIN.REFERENCE._BASE = `${ADMIN.BASE}|Reference`
ADMIN.REFERENCE.FACULTIES = `${ADMIN.REFERENCE._BASE}|Faculties`
ADMIN.REFERENCE.CATHEDRAS = `${ADMIN.REFERENCE._BASE}|Cathedras`
ADMIN.REFERENCE.DIRECTIONS = `${ADMIN.REFERENCE._BASE}|Directions`
ADMIN.REFERENCE.PROFILES = `${ADMIN.REFERENCE._BASE}|Profiles`
ADMIN.REFERENCE.SUBJECTS = `${ADMIN.REFERENCE._BASE}|Subjects`

ADMIN.EDUCATION._BASE = `${ADMIN.BASE}|Education`
ADMIN.EDUCATION.LIST = `${ADMIN.EDUCATION._BASE}|List`

ADMIN.GROUP._BASE = `${ADMIN.BASE}|Group`
ADMIN.GROUP.LIST = `${ADMIN.GROUP._BASE}|List`

ADMIN.FACE._BASE = `${ADMIN.BASE}|Faces`
ADMIN.FACE.LIST = `${ADMIN.FACE._BASE}|List`

ADMIN.CURRICULUM._BASE = `${ADMIN.BASE}|Curriculum`
ADMIN.CURRICULUM.LIST = `${ADMIN.CURRICULUM._BASE}|List`

export default {
  ...ADMIN
}
