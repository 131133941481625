<template>
  <v-container style="height: 100%" v-if="is_self_router">
    <PrintAdmissions :admissions="admissionPrint" style="display: none"></PrintAdmissions>
    <v-data-table
      v-model="print"
      :headers="headers"
      :items="admissions"
      :loading="pagination.loading"
      :page.sync="pagination.page"
      :items-per-page.sync="pagination.size"
      :server-items-length="pagination.count"
      no-data-text="Нет данных"
      show-select
      :footer-props="{'items-per-page-options':[10,25,50], 'footer-props.items-per-page-text': 'Число элементов'}"
    >
      <template v-slot:header.data-table-select>
        <v-icon>done</v-icon>
      </template>
      <template v-slot:item.fio="props">
        <router-link v-if="props.item.fio !== ''" :to="makeDetailLink(props.item)">
          {{ props.item.fio }}
        </router-link>
        <v-skeleton-loader v-else type="text"></v-skeleton-loader>
      </template>
      <template v-slot:item.data-table-select="props">
      <v-simple-checkbox
        v-if="props.isSelected || print.length < 10"
        :value="props.isSelected" @input="props.select($event)"></v-simple-checkbox>
      </template>
      <template v-slot:foot>
        <v-btn
          color="primary"
          dark
          class="ma-2"
          :loading="loadingPrint"
          :disabled = 'print.length === 0'
          @click="PrintAdmissions"
        >
          Напечатать
        </v-btn>
      </template>
      <template v-slot:top>
        <v-toolbar
          elevation="0"
          color="style-color-main-gray"
          :extension-height="$vuetify.breakpoint.mdAndDown ? 96 : 48"
        >
          <v-text-field
            dense
            placeholder="Поиск"
            v-model="search.value"
            @keypress.enter="searchData"
            @click:clear="clearSearchData"
            clearable
            messages="Для поиска нажмите Enter или кнопку поиск"
          >
          </v-text-field>
          <template v-slot:extension>
            <v-container class="ma-0 pa-0">
              <v-row no-gutters>
                <v-col cols="6"  md="3">
                  <v-select class="py-0 px-1" :items="selectors.success" v-model="pagination.filters.success" hide-details
                  >
                    <template v-slot:item="data">
                      <template v-if="typeof data.item !== 'object'">
                        <v-list-item-content v-text="data.item"></v-list-item-content>
                      </template>
                      <template v-else>
                        <div class="text-left" style="-moz-hyphens: auto; -webkit-hyphens: auto; -ms-hyphens: auto;">
                          {{ data.item.text }}
                        </div>
                      </template>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="6"  md="3" >
                  <v-select class="py-0 px-1" :items="selectors.session" v-model="pagination.filters.session" hide-details
                  >
                    <template v-slot:item="data">
                      <template v-if="typeof data.item !== 'object'">
                        <v-list-item-content v-text="data.item"></v-list-item-content>
                      </template>
                      <template v-else>
                        <div class="text-left" style="-moz-hyphens: auto; -webkit-hyphens: auto; -ms-hyphens: auto;">
                          {{ data.item.text }}
                        </div>
                      </template>
                    </template>
                  </v-select>
                </v-col>

                <v-col cols="6" md="3">
                  <v-autocomplete class="py-0 px-1" :items="selectors.subject" v-model="pagination.filters.subject" hide-details>
                    <template v-slot:item="data">
                      <template v-if="typeof data.item !== 'object'">
                        <v-list-item-content v-text="data.item"></v-list-item-content>
                      </template>
                      <template v-else>
                        <div class="text-left" style="-moz-hyphens: auto; -webkit-hyphens: auto; -ms-hyphens: auto;">
                          {{ data.item.text }}
                        </div>
                      </template>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="6" md="3">
                  <v-select
                    clearable
                    label="Вид допуска"
                    class="py-0 " :items="selectors.first_tolerance" v-model="pagination.filters.first_tolerance"
                  >
                    <template v-slot:item="data">
                      <template v-if="typeof data.item !== 'object'">
                        <v-list-item-content v-text="data.item"></v-list-item-content>
                      </template>
                      <template v-else>
                        <div class="text-left" style="-moz-hyphens: auto; -webkit-hyphens: auto; -ms-hyphens: auto;">
                          {{ data.item.text }}
                        </div>
                      </template>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
            </v-container>
          </template>
        </v-toolbar>

      </template>
      <template v-slot:item.admission_type="props">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs"
                        v-on="on">{{ getAbbreviation(props.item.academic_difference, props.item.is_exam) }}</span>
          </template>
          <span>{{ getAdmissionType(props.item.academic_difference, props.item.is_exam) }}</span>
        </v-tooltip>
        <v-tooltip bottom v-if="props.item.tolerance_count > 1">
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on"> <v-icon color="red"> done</v-icon></span>
          </template>
          Это {{ props.item.tolerance_count }} допуск данного вида по предмету.
        </v-tooltip>
      </template>
      <template v-slot:item.success="props">
        <v-icon v-if="props.item.success" color="green">done</v-icon>
        <v-icon v-else-if="props.item.success !== null" color="red">clear</v-icon>
        <span v-else>
            <span v-if="props.item.closed && !props.item.success">Архив</span>
            <span v-else>Не рассмотрен</span>
            </span>
        <v-tooltip bottom v-if="props.item.reopen">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" color="green">autorenew</v-icon>
          </template>
          <span>Переоткрыть допуск</span>
        </v-tooltip>
      </template>
      <template v-slot:item.action="props">
        <v-btn icon @click="PrintAdmission(props.item)">
          <v-icon>print</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-container>
  <router-view :key="$route.fullPath" v-else></router-view>
</template>


<script>

import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
import TitledPageMixin from "@/mixins/TitledPageMixin";
import {getUserUid, loadData, makeGetRequest} from "@/helper";
import urls from '@/urls/teacher'
import {generateListUidToName, generateUidToName, uidToPositionHelds} from "@/helper/uidToName";
import names from "@/modules/teacher/routers/names";
import ListTemplate from "@/modules/teacher/pages/student/ListTemplate.vue";
import selectors from "@/urls/selectors"
import PrintAdmissions from "@/modules/templates/admission/PrintAdmission.vue";

export default {
  name: "AdmissionList",
  mixins: [PaginatedDataMapperMixin, TitledPageMixin],
  components: {
    PrintAdmissions
  },
  props: {},
  data () {
    return {
      page_title: 'Список допусков',
      admissions: [],
      success: 'null',
      session: null,
      loadingPrint:false,
      print:[],
      admissionPrint:[],
      subject:null,
      search: {
        parameter: 'search',
      },
      pagination: {
        filters: {
          success: 'null',
          subject: null,
          session: null,
        },
      },
      URL_LIST: urls.URLS.ADMISSION.LIST(),
      selectors:{
        subject:[],
        session:[],
        first_tolerance:[
          {text:'Первичный допуск',value: 'true'},
          {text:'Повторный допуск',value: 'false'},
        ],
        success:[
          {text:'Все допуски',value: ''},
          {text:'Успешно закрытые допуски',value: 'true'},
          {text:'Не успешно закрытые допуски',value: 'false'},
          {text:'Допуски не взятые в работу',value: 'null'},
          {text:'Все закрытые',value: 'all_closed '},
        ],
      },
      headers: [
        {text: 'Номер',value: 'number', sortable: false,},
        {text: 'Обучающийся', value: 'fio', sortable: false},
        {text: 'Предмет', value: 'subject', sortable: false,},
        {text: 'Группа', value: 'group', sortable: false,},
        {text: 'Тип контроля', value: 'control_type', sortable: false,},
        {text: 'Тип допуска', sortable: false, align: 'left', value: 'admission_type',},
        {text: 'Дата сдачи', value: 'officially_closed', sortable: false,},
        {text: 'Статус', value: 'success', sortable: false,},
        {text: 'Действия', value: 'action', sortable: false, align: 'center'},
      ]
    }
  },
  computed: {
    is_self_router(){
      return this.$route.name === names.ADMISSIONS.LIST;
    },
  },
  watch: {
  },
  methods: {
    PrintAdmissions(){
      this.loadingPrint=true
      let prom = []
      this.admissionPrint = []
      this.print.forEach((element) =>
        prom.push(this.loadDataDetail(element.id))
      );
      Promise.all(prom).then((values) => {
          const options = {
            name: '_blank',
            autoClose: false,
            specs: [
              'fullscreen=yes',
              'titlebar=yes',
              'scrollbars=yes'
            ],
            styles: [
              '*'
            ]
          }
        setTimeout(()=>{
          this.loadingPrint=false
          this.$htmlToPaper('printMe',options);
        }, 2000);
        });
    },
    loadDataDetail(idAdmission){
      let url = new URL(urls.URLS.ADMISSION.DETAIL(idAdmission))
      let params = {};
      url.search = new URLSearchParams(params).toString();
      return makeGetRequest(url).then(resp => {
        return resp.json()
      }).then(json => {
        var detail = json
        this.$set(detail, "fio_teacher", "Не указан")
        generateUidToName(detail, "teacher", "fio_teacher")
        this.$set(detail, "fio_student", "Не указан")
        generateUidToName(detail, "student", "fio_student")
        this.$set(detail, "fio_issued_by", "Не указан")
        if(detail.issued_by)
          uidToPositionHelds(detail, "issued_by", "fio_issued_by")
        this.admissionPrint.push(detail)
      })
    },
    getAbbreviation(academic_difference, is_exam) {
      if (academic_difference)
        return "А/Р"
      else if (is_exam)
        return "Э"
      else
        return "Д"
    },
    getAdmissionType(academic_difference, is_exam) {
      if (academic_difference)
        return "Академическая разница"
      else if (is_exam)
        return "Экзамен/Зачет с оценкой"
      else
        return "Добор/Зачет"
    },
    PrintAdmission(item){
      let url = new URL(urls.URLS.ADMISSION.DETAIL(item.id))
      let params = {};
      url.search = new URLSearchParams(params).toString();
      makeGetRequest(url).then(resp => {
        return resp.json()
      }).then(json => {
        this.admissionPrint = [json]
        this.admissionPrint[0].fio_student = item.fio
        this.$set(this.admissionPrint[0], "fio_teacher", "")
        generateUidToName(this.admissionPrint[0], "teacher", "fio_teacher",()=>{
          const options = {
            name: '_blank',
            autoClose: false,
            specs: [
              'fullscreen=yes',
              'titlebar=yes',
              'scrollbars=yes'
            ],
            styles: [
              '*'
            ]
          }
          this.$nextTick(() => {
            this.$htmlToPaper('printMe',options);
          });
        })
      })
      },
    makeDetailLink(item){
      return {
        name: names.ADMISSIONS.DETAIL, params: {idAdmission: item.id}
      }
    },
    loadSelectorSubject(){
      loadData(
        selectors.URLS.SEMESTERS(),
        (data) => {
          this.selectors.subject=data
          this.selectors.subject.unshift({text:'Все предметы',value:null})
          this.loadSelectorSession()
        }
      )
    },
    loadSelectorSession(){
      loadData(
        selectors.URLS.ACADEMIC_YEAR_ALL_SESSION(),
        (data) => {
          this.selectors.session=data
          this.selectors.session.unshift({text:'Все сессии',value:null})
          this.loadData()
        }
      )
    },
    loadData(url=undefined) {
      this.print=[]
      this.setLoading();
      if(url === undefined) {
        url = this.addFiltersParameter(this.URL_LIST)
        this.setCurrentLoadUrl(url);
        this.cleanPaginationData()
      }
      loadData(
        url,
        (data) => {
          this.admissions = data.results;
          this.mapPagination(data);
          for (let el of this.admissions) this.$set(el, "fio","")
          generateListUidToName(this.admissions,"student","fio", () => {this.setNotLoading();})
        }
      )
    },
  },
  created ()  {
    this.loadSelectorSubject()
  },
  beforeRouteUpdate(to, from, next) {
    if (to.name === names.ADMISSIONS.LIST) {
      this.loadSelectorSubject()
    }
    next(vm => {
    })
  },
}


</script>
<style scoped>

</style>
