<template>
  <v-container style="height: 100%">
    <v-card flat>
      <v-toolbar
        elevation="0"
        color="style-color-main-gray"
      >
        <v-text-field class="pa-6"
                      dense
                      placeholder="Поиск"
                      v-model="search"
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              @click="getItems()"
              class="mr-2"
              medium
            >
              update
            </v-icon>
          </template>
          <span>Обновить данные</span>
        </v-tooltip>
      </v-toolbar>
      <v-data-table
              :headers="headers"
              :items="items"
              :search="search"
              :expanded.sync="expanded"
              show-expand
              class="elevation-1"
              :footer-props="{'items-per-page-options':[25,50,100], 'items-per-page-text': 'Число элементов'}"
            >
              <template v-slot:expanded-item="{ headers, item , isExpanded}">
                <td :colspan="headers.length" class="px-0">
                  <v-card class="px-0">
                    <v-card-text class="subtitles text-left">

                      <v-row align-center fill-height justify-center row wrap>
                        <v-col
                          cols="2">
                          Оценка за экзамен: {{ item.exam }}
                        </v-col>
                        <v-col
                          cols="2">
                          Рейтинг за семестр: {{ item.rating_in_semester }}
                        </v-col>
                        <v-col
                          cols="2">
                          Итоговый рейтинг: {{ item.rating }}
                        </v-col>
                        <v-col
                          cols="2">
                          Оценка: {{ item.text_rating }}
                        </v-col>
                        <v-col
                          cols="2">
                          Перезачёт:
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-icon v-on="on" v-if="item.reload">done</v-icon>
                              <v-icon v-on="on" v-else>clear</v-icon>
                            </template>
                            <span v-if="item.reload">Предмет был перезачтен</span>
                            <span v-if="!item.reload">Предмет не был перезачтен</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    </v-card-text>

                    <v-card-text>
                      <v-card-text title class="text-left title style-color-main-gray">
                        <v-row>
                          <v-col
                            cols="10">
                            <span>Выданные допуски:</span>
                          </v-col>
                          <v-col
                            cols="2">
<!--                            <v-btn   text @click="updateItem(item)" v-if="!item.tolerance.find(item => !Boolean(item.success ))">-->
<!--                              <v-icon-->
<!--                                small-->
<!--                                class="mr-2"-->
<!--                              >-->
<!--                                add-->
<!--                              </v-icon>-->
<!--                              Создать-->
<!--                            </v-btn>-->
                          </v-col>
                        </v-row>

                      </v-card-text>
                    </v-card-text>

                      <v-card-text v-if="item.tolerance.length > 0" class="py-0">
                        <v-timeline dense>
                          <v-slide-x-reverse-transition
                            group
                            hide-on-leave
                          >
                            <v-timeline-item
                              v-for="it in item.tolerance"
                              :key="it.id"
                              small
                              fill-dot
                            >
                              <v-card class="elevation-2">
                                <v-layout column>
                                  <v-layout>
                                    <v-card-text class="text-left subtitles pt-0">Начало сессии:
                                      {{ it.session.started }}
                                    </v-card-text>
                                    <v-card-text class="text-left subtitles pt-0"> Завершение сессии:
                                      {{ it.session.ended }}
                                    </v-card-text>
                                  </v-layout>
                                  <v-layout>
                                    <v-card-text class="text-left subtitles pt-0">Баллов набранных в семестре:
                                      {{ it.in_semester }}; Всего баллов:
                                      {{ it.total }}
                                    </v-card-text>
                                    <v-card-text class="text-left subtitles pt-0">Коментарии к допуску:
                                      {{ it.comment }};
                                    </v-card-text>
                                  </v-layout>
                                  <v-layout>
                                    <v-card-text class="text-left subtitles pt-0">Допуск выдан:
                                      {{ displayDate(it.created) }}
                                    </v-card-text>
                                    <v-card-text class="text-left subtitles pt-0" v-if="it.success">Допуск закрыт:
                                      {{ displayDate(it.closed) }}
                                    </v-card-text>
                                  </v-layout>

                                  <v-card-text class="text-left subtitles pt-0">Допуск закрыт:
                                    <v-icon color="teal" v-if="it.success">done</v-icon>
                                    <v-icon v-else>clear</v-icon>
                                  </v-card-text>
                                </v-layout>
                              </v-card>
                            </v-timeline-item>
                          </v-slide-x-reverse-transition>
                        </v-timeline>

                      </v-card-text>
                  </v-card>
                </td>
              </template>
              <template slot="no-data">
                <v-card-text>Данные не загружены</v-card-text>
              </template>
            </v-data-table>
        <Admission @addItem="addItem"
                   @ended="closedForm"
                   @refreshItem="refreshItem"
                   v-bind="selected"
                   v-if="opened_form"
                   :opened_form="opened_form"
        >
        </Admission>
    </v-card>

  </v-container>
</template>

<script>
import UserDataMixin from "@/mixins/UserDataMixin";
import urls from "@/urls/dean"
import {makeGetRequest} from "@/helper";
import Admission from "@/modules/dean/pages/student/form/Admission.vue"
import moment from "moment";
import names from "@/modules/dean/routers/names";
import TitledPageMixin from "@/mixins/TitledPageMixin";

export default {
  name: "ListFinishSubject",
  components: {
    Admission
  },
  mixins: [UserDataMixin, TitledPageMixin],
  props: {},
  watch: {
    'expanded': {
      handler: function (newValue) {
        for (let i in this.expanded) {
          this.getTolerance(this.expanded[i])
        }
      },
    }
  },
  created() {
    this.setPageTitleWithObject("Education", {id: this.$route.params.idStudent}, ' - ', true)
    this.getItems()
  },
  data() {
    return {
      URL_SEMESTER_LIST: urls.DEAN.STUDENTS.SEMESTER.LIST(this.$route.params.idFaculty, this.$route.params.idStudent),
      items: [],
      search:'',
      names:names,
      page_title: 'Пройденные предметы',
      loading: true,
      total: 0,
      pagination: {},
      expanded: [],
      headers: [
        {text: 'Предмет', value: 'subject_name', sortable: false, align: 'left', wight: "50"},
        {text: 'Семестр', value: 'semester', sortable: false, align: 'left',},
        {text: 'Курс', value: 'course', sortable: false, align: 'left',},
        {text: 'Оценка', value: 'text_rating', sortable: false, align: 'left',},
        {text: 'Балл', value: 'rating', sortable: false, align: 'left',},
        {text: '', value: 'data-table-expand'},
      ]
    }
  },
  methods: {
    displayDate: function (date, minutes = false) {
      let format = 'DD.MM.YYYY'
      if (minutes)
        format += ' HH:mm'
      return moment(date).format(format)
    },
    getTolerance(item) {

      let url = new URL(urls.DEAN.STUDENTS.SEMESTER.GET_TOLERANCE(
        this.$route.params.idFaculty,
        this.$route.params.idStudent,
        item.id
      ))

      let params = {};
      url.search = new URLSearchParams(params).toString();
      let sys = true
      makeGetRequest(url).then(resp => {
        if (!resp.ok) {
          sys = false
        }
        return resp.json()
      }).then(json => {
        item.tolerance = json
      })
    },
    getItems() {

      let url = new URL(this.URL_SEMESTER_LIST)

      let params = {};

      url.search = new URLSearchParams(params).toString();

      let sys = true
      makeGetRequest(url).then(resp => {
        if (!resp.ok) {
          sys = false
        }
        return resp.json()
      }).then(json => {
        this.items = json
        for (let i in this.items) {
          this.$set(this.items[i], "tolerance", [])
          // this.getTolerance(this.items[i])
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
