<template>
  <v-container v-if="is_self_route">
    <v-row>
      <v-col cols="12" md="12">
        <v-card>
          <v-toolbar
            elevation="0"
            color="style-color-main-gray"
          >
            <v-text-field
              dense
              placeholder="Поиск"
              v-model="search.value"
              @keypress.enter="searchData"
              @click:clear="clearSearchData"
              clearable
              messages="Для поиска нажмите Enter или кнопку поиск"
              append-icon="search"
              @click:append="searchData"
            >
            </v-text-field>
          </v-toolbar>
          <v-data-table
            :headers="headers"
            :items="groups"
            :expanded.sync="expanded"
            show-expand
            :items-per-page.sync="pagination.size"
            no-data-text="Ведомые группы не загружены"
            no-results-text="Ведомые группы не были найдены"
            :page.sync="pagination.page"
            :loading="pagination.loading"
            :server-items-length="pagination.count"
            :footer-props="{'items-per-page-options':[10,20,50], 'show-current-page': true, 'show-first-last-page': true}"
          >
            <template v-slot:loading>
              <template v-for="i in 10">
                <v-skeleton-loader type="table-row" :key="`skeleton${i}`"></v-skeleton-loader>
              </template>
            </template>
            <template v-slot:header.subjects="props">
                <v-autocomplete
                  class="header-title px-0 mx-0 my-auto"
                  hide-details hide-no-data
                  clearable
                  @click:clear="pagination.filters.subject = null"
                  :items="filters.subjects"
                  v-model="pagination.filters.subject"
                  style="height: 65px"
                  height="45px"
                >
                  <template v-slot:label>
                    <div class="header-title">Дисциплины (для фильтрации нажмите на поле)</div>
                  </template>
                </v-autocomplete>
            </template>
            <template v-slot:item.name="group">
              <router-link :to="{
                    name: names.CathedraGroupsDetail,
                    params: {
                      taught: 'taught',
                      cathedra_uid: cathedra_uid,
                      group_id: group.item.id
                    }
                  }">
                {{group.item.litter}}-{{group.item.course}}{{group.item.number}}
              </router-link>
            </template>
            <template v-slot:item.subjects="group">
              <v-row class="py-6">
                <template v-for="(subject, index) in group.item.subjects" >
                  <span :key="`subjects_${index}`" class="mx-1"  >
                    {{subject.subject}}<template v-if="index + 1 !== group.item.subjects.length">,</template>
                  </span>
                </template>
              </v-row>
            </template>
            <template v-slot:expanded-item="props">
              <td :colspan="props.headers.length" class="py-2">
                <v-row wrap v-if="props.item.semesters !== undefined">
                  <v-col class="text-left" :cols="props.item.semesters.length > 0 && $vuetify.breakpoint.mdAndUp? 6: 12" v-for="(semester, index) in props.item.semesters" :key="`semester_${index}`">
                    <v-card elevation="2" class="pa-0 ma-0" height="100%">
                      <v-card-title>
                        <v-row>
                          <v-col cols="8" class="py-0" style="word-break: break-word;">
                            {{semester.subject}}
                          </v-col>
                          <v-col cols="4" class="text-right py-0">
                            {{semester.control_type}}
                          </v-col>
                        </v-row>
                      </v-card-title>
                      <v-card-subtitle>
                        <v-row>
                          <v-col cols="auto" class="py-2">
                            <v-switch
                              v-model="semester.mottled"
                              :label="'Полусеместр'"
                              :hide-details="semester.mottled ? false : true"
                            >
                            </v-switch>
                          </v-col>
                          <v-col cols="auto" class="pt-7">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-icon
                                  v-on="on"
                                  @click="UpdateHalfSemester(semester, props.item.id) "
                                  :disabled=" isDisabled(semester)"
                                  medium
                                >
                                  save
                                </v-icon>
                              </template>
                              <span>Сохранить изменения</span>
                            </v-tooltip>
                          </v-col>
                          <v-col cols="auto" class="pt-2" v-if="semester.mottled">
                            <v-autocomplete
                              label="Половина"
                              :error="(semester.half === null) && (semester.mottled)"
                              :messages="semester.half === null ? 'Выберите половину семестра' : ' '"
                              :items="choices.group_half"
                              v-model="semester.half"
                              :hide-details="semester.mottled ? false : true"
                            >
                            </v-autocomplete>
                          </v-col>
                          <v-col cols="auto" class="text-right pt-7" sm>
                            {{semester.course}} курс {{semester.semester}} семестр
                          </v-col>
                        </v-row>
                      </v-card-subtitle>
                      <v-card-text>
                        <v-data-table
                          :headers="[{text: 'Преподаватель', value: 'fio', width: $vuetify.breakpoint.mdAndUp ?'70%': '50%', sortable: false},{text: 'Тип занятий', value: 'type', align: 'start', sortable: false},]"
                          :items="semester.teachers"
                          mobile-breakpoint="0"
                          :hide-default-footer="semester.teachers.length < 5"
                          :items-per-page="semester.teachers.length"
                          no-data-text="Прикрепленные преподаватели не загружены"
                          no-results-text="Прикрепленные преподаватели не были найдены"
                          :footer-props="{'items-per-page-options':[5], 'items-per-page-text': 'Число элементов:', 'show-current-page': true}"
                        >
                          <template v-slot:header.type="props2">
                            <v-row class="text-start">
                              <v-col>{{props2.header.text}}</v-col>
                              <v-btn icon  @click="openEditable(semester, props.item, semester.teachers)">
                                <v-icon>edit</v-icon>
                              </v-btn>
                            </v-row>
                          </template>
                        </v-data-table>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row wrap v-else>
                  <v-col class="text-left" :cols="6">
                    <v-skeleton-loader
                      height="100"
                      type="card"
                    >
                    </v-skeleton-loader>
                  </v-col>
                  <v-col class="text-left" :cols="6">
                    <v-skeleton-loader
                      height="100"
                      type="card"
                    >
                    </v-skeleton-loader>
                  </v-col>
                </v-row>
              </td>
            </template>

          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="editable.opened"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <LinkedTeacher
        v-if="editable.opened"
        :group_semester_id="editable.subject.id"
        :subject_id="editable.subject.subject_id"
        :selected="editable.selected"
        @saved="closeEditable()"
        @close="editable.opened = false"
      ></LinkedTeacher>
    </v-dialog>
<!--    <DebugJsonComponent :json="choices.group_half"></DebugJsonComponent>-->
  </v-container>
  <router-view v-else></router-view>
</template>

<script>
import {loadData, makePostRequest, sendPostRequest} from "../../../../helper";
  import urls from '@/urls/cathedras';
  import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
  import DebugJsonComponent from "../../../core/components/DebugJsonComponent";
  import TitledPageMixin from "../../../../mixins/TitledPageMixin";
  import names from "../../routers/names";
  import {generateListUidToName} from "@/helper/uidToName";
  import LinkedTeacher from "../subjects/LinkedTeacher";
  import selectors from "@/urls/selectors";
  import choices from "@/urls/choices"
  import OneFieldBaseSearchMixin from "@/mixins/OneFieldBaseSearchMixin";
  import UserDataFormMixin  from "@/mixins/UserDataFormMixin"

  export default {
    name: "TaughtGroups",
    mixins: [PaginatedDataMapperMixin, TitledPageMixin, OneFieldBaseSearchMixin, UserDataFormMixin ],
    components: {DebugJsonComponent, LinkedTeacher},
    data: function(){
      return {
        names: names,
        page_title: 'Список групп, которые обучаются в этом семестре',
        cathedra_uid: this.$route.params.cathedra_uid,
        groups: [],
        choices:{
          group_half: [],
        },
        expanded: [],
        filters: {
          subjects: [],
        },
        pagination: {
          filters: {
            subject: null,
          },
        },
        headers: [
          {text:'Группа', sortable: false, align:'center', value: 'name', width: '10%'},
          {text: 'Количество обучающихся', sortable: false, align: 'left', width: '5%',value: 'students_count'},
          {text:'Дисциплины', sortable: false, align:'start', value: 'subjects', width: '80%'},
          { text: '', value: 'data-table-expand', width: '5%'},
        ],
        semesters: {
          headers: [
            {text: 'Предмет', value: 'subject', sortable: false},
            {text: 'Курс', value: 'course', sortable: false},
            {text: 'Семестр', value: 'semester', sortable: false},
            {text: 'Кафедра', value: 'cathedra', sortable: false},
            {text: 'Тип контроля', value: 'control_type', sortable: false},
            {text: 'Преподаватели', value: 'teachers', sortable: false},
          ],
        },
        editable: {
          group: undefined,
          subject: undefined,
          selected: [],
          opened: false,
        },
        search: {
          parameter: 'name',
        },
      }
    },
    computed: {
      is_self_route: function () {return names.CathedraGroups === this.$route.name},
    },
    watch: {
      expanded: function (newVal) {
        newVal.forEach(el => {
          if (el.semesters === undefined) {
            this.loadSubjects(el);
          }
        });
      },
    },
    methods: {
      isDisabled(semester){
        if(!semester.mottled)
          return false
        else{
          if(semester.mottled && semester.half != null)
            return false
          else
            return true
        }
      },
      openEditable: function(subject, group, teachers){
        this.editable.group = group;
        this.editable.subject = subject;
        this.editable.selected = teachers;
        this.editable.opened = true;
      },
      closeEditable: function () {
        this.reloadSubject(this.editable.group, this.editable.subject.id);
        this.editable.opened = false;
      },
      loadTeacherFIOs: function (semesters){
        let teachers = [];
        semesters.forEach(semester => {
          semester.teachers.forEach(teacher => {
            if(teacher.fio === undefined && !teachers.find(fel => fel.uid === teacher.uid))
              teachers.push({uid: teacher.teacher, fio: undefined});
          });
        });
        generateListUidToName(teachers, 'uid', 'fio', (data) => {
          semesters.forEach(semester => {
            semester.teachers.forEach(teacher => {
              if (teacher.fio === undefined) {
                let result = data.find(fel => fel.uid === teacher.teacher);
                this.$set(teacher, 'fio', result.fio);
              }
            });
          });
        });
      },
      reloadSubject: function (group, semester_id) {
        let index = group.semesters.findIndex(fel => fel.id === semester_id);
        loadData(
          urls.getByRecievedUrlData(group.semesters_url, {'semester_id': semester_id}),
          data => {
            this.$set(group.semesters, index, data[index]);
            this.loadTeacherFIOs(group.semesters);
          }
        );
      },
      loadSubjects: function (group) {
        loadData(
          urls.getByRecievedUrlData(group.semesters_url),
          data => {
            this.$set(group, 'semesters', data);
            this.loadTeacherFIOs(group.semesters);
          }
        );
      },
      loadData(url=undefined){
        this.setLoading();
        if (url === undefined) {
          url = urls.CATHEDRA.GROUPS.LIST.TAUGHT(this.cathedra_uid);
          this.setCurrentLoadUrl(url);
        }
        loadData(
          url,
          (data) => {
            this.mapResults(data, 'groups');
            this.groups.forEach(group => {
              this.$set(group, 'semesters_url', group.semesters);
              group.semesters = undefined;
            });
            this.setNotLoading();
          }
        )
      },
      loadSelectorSubjectData(){
        loadData(
          selectors.URLS.CATHEDRA.SUBJECTS(this.cathedra_uid),
          (data) => {
            this.filters.subjects = data;
          },
        )
      },
      loadChoiceGroupHalf(){
        loadData(
          choices.EDUCATION.CHOICES.GROUP_HALF(),
          (data) => {
            this.choices.group_half = data
          },
        )
      },
      UpdateHalfSemester(semester, group_id){
        let value = {
          group_semester : semester.id,
          mottled : semester.mottled,
          half : semester.half,
        }
        let url = urls.CATHEDRA.GROUPS.UPDATE_HALF_SEMESTER(this.cathedra_uid, group_id)
        sendPostRequest(
          url,
          value,
          ()=>{},
          (res)=>{
            this.receiveErrors(res)
          },
        )
      }
    },
    created() {
      this.setPageTitleWithObject("Cathedra", {uid: this.$route.params.cathedra_uid}, ' - ')
      this.loadData();
      this.loadSelectorSubjectData();
      this.loadChoiceGroupHalf()
    },
  }
</script>

<style scoped>
  .header-title{
    color: rgba(0,0,0,.6);
    font-size: .75rem;
  }
</style>
