<!--Данный компонент нигде не используется-->
<template>
  <div>
    <v-layout justify-center>
      <v-flex>
        <v-card ref="form">
          <v-toolbar color="primary" dark>
            <v-toolbar-title>
              <span v-if="id != -1">Изменить профили подготовки</span>
              <span v-else>Добавить профиль подготовки</span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-toolbar-items>
                <v-btn @click="closeDialog" class="pa-0 ma-0" dark text icon>
                  <v-icon>close</v-icon>
                </v-btn>
              </v-toolbar-items>
            </v-toolbar-items>
          </v-toolbar>
          <v-card-text>
            <v-text-field
              :error="hasError('name')"
              :error-messages="getError('name')"
              label="Название"
              required
              v-model="value.name"
              class="ml-1"
            ></v-text-field>
            <v-autocomplete
              :error="hasError('direction')"
              :error-messages="getError('direction')"
              :items="selectors.direction"
              label="direction"
              v-model="value.direction"
              no-data-text ="Нет данных"
              item-text = "name"
              item-value = "id"
            ></v-autocomplete>
            <v-autocomplete
              :error="hasError('competences')"
              :error-messages="getError('competences')"
              :items="selectors.competences"
              label="competences"
              v-model="value.competences"
              no-data-text ="Нет данных"
              item-text = "name"
              item-value = "id"
            ></v-autocomplete>
            <v-btn @click="apply" color="primary">Сохранить</v-btn>
          </v-card-text>
          <v-divider class="mt-5"></v-divider>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import urls from "@/urls/management";
  import UserDataFormMixin from '@/mixins/UserDataFormMixin'

  import {clearUserAuth, makeGetRequest, makeParticalUpdateRequest} from "@/helper";
  export default {
    mixins: [UserDataFormMixin],
    name: "FormTrainingProfile",
    props: {
      id: Number,
      name: String,
    },
    data() {
      return {
        URL_CREATE: urls.getUrl('worker|ministry|training_profile-list', 'create'),
        URL_UPDATE: urls.getUrl('worker|ministry|training_profile-detail', 'update'),
        //   worker|ministry|state_standard-list

        selectors:{
          direction:[],
          competences:[],
        },
        rules: {
          required: value => !!value || 'Пустое поле',
        },

        value: {
          name: this.name || "",
          id: this.id || undefined,
          direction: undefined,
          competences: undefined,
        },
        // errors: {}
      }
    },
    methods: {
      load: function (app, url,name) {
        if (url === null) {
          app.loadingPage = true;
          return;
        }
        makeGetRequest(url)
          .then(resp => resp.json())
          .then(json => {
            let next_url = null;
            if (json['next'] !== null)
              next_url = url.split('?')[0] + '?' + json['next'].split('?')[1];
            for (let i in json['results']) {
              app.selectors[name].push(Object.assign({}, json['results'][i]));
            }
            app.load(app, next_url);
          });
      },
      apply: function () {
        if (this.id !== undefined) {
          this.update()
        } else {
          this.create()
        }
      },
      update: function () {
        let url = this.getUpdateUrl();
        let status = true;
        let data = this.getData();
        makeParticalUpdateRequest(url, data)
          .then(resp => {
            if (resp.status < 300) {
              status = false;
            }
            if (resp.status === 401) {
              clearUserAuth()
            }
            return resp.json();
          })
          .then(json => {
            if (!status) {
              this.$emit('refreshItem', json);
            } else {
              this.receiveErrors(json);
            }
          });
      },
      setFile(e) {
        const file = e.target.files[0];
        this.value.file = file;
      },
      closeDialog() {
        this.$emit('ended', '')
      },
    },
    created() {
      let url =  urls.getUrl('worker|ministry|competence-list', 'list')
      this.load(this,url,"competences")

      let url2 =  urls.getUrl('worker|ministry|training_direction-list', 'list')
      this.load(this,url2,"direction")
      if(this.id !== undefined){


        let sys = true
        makeGetRequest(this.getUpdateUrl()).then(resp => {
          if (!resp.ok) {
            sys = false
          }
          return resp.json()
        }) .then(json => {
          if(sys)
            this.value = json

        })


      }
    }
  }
</script>

<style scoped>

</style>
