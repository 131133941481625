<template >
  <div :id="id">
    <div v-for="admission of admissions">
      <div  style="width: 50vw ; page-break-after:always; " v-if="admission">
        <div style="font-size: 12px;text-align:center">Министерство науки и высшего образования Российской Федерации</div>
        <div style="font-size: 11px;text-align:center">Российский химико-технологический университет имени Д.И. Менделеева</div>
        <div style="font-size: 13px;text-align:center;margin: 10px"><strong>ЭКЗАМЕНАЦИОННЫЙ ЛИСТ №</strong> {{admission.number}} <span v-if="admission.academic_difference"> (Академическая разница)</span></div>
        <table style="font-size: 12px; text-align:left;">
          <tr>
            <td >Наименование предмета :</td>
            <td ><strong  v-if="admission.semester">{{ admission.semester.subject }}, {{ getNumberSemestr(admission.semester.semester,admission.semester.course )}} семестр</strong></td>
          </tr>
          <tr>
            <td >Экзаменатор (Ф.И.О., должность) :</td>
            <td > <strong>{{  admission.fio_teacher  }}</strong></td>
          </tr>
          <tr>
            <td >Обучающийся (Ф.И.О.) :</td>
            <td > <strong>{{ admission.fio_student }}</strong></td>
          </tr>
          <tr>
            <td >№ зачетной книжки :</td>
            <td ><strong>{{admission.record_book_number}}</strong> Группа <strong>{{admission.group.name}}</strong></td>
          </tr>
          <tr>
            <td >Направление действительно на</td>
            <td>{{displayDate(admission.officially_closed)}}</td>
<!--            <td > ________________</td>-->
          </tr>
        </table>
        <table style="font-size: 12px;">
          <tr>
            <td style="text-align:left;width: 25vw">Сумма баллов, набранных в семестре:</td>
            <th style="width: 7vw;border: 1px solid   black;">{{admission.in_semester}}</th>
          </tr>
        </table>
        <table style="font-size: 12px;">
          <tr>
            <td style="text-align:left;width: 10vw">Дата выдачи :</td>
            <th style="text-align:left;">{{ displayDate(admission.created)}} {{admission.fio_issued_by}}</th>
          </tr>
        </table>
        <table style="font-size: 12px;">
          <tr>
            <td style="text-align:left;width: 25vw">Сумма баллов на экзамене (зачете):</td>
            <th style="width: 7vw;border: 1px solid   black;">
              <span v-if="admission.total">
                <span v-if="admission.is_exam && !admission.success && !admission.academic_difference">0</span>
                <span v-else>{{admission.total - admission.in_semester}}</span>
              </span>
            </th>
            <th  style="width: 7vw;border: 1px solid   black;"><span>{{admission.type}}</span></th>
          </tr>
          <tr>
            <td style="text-align:left;width: 25vw">Общая сумма баллов:</td>
            <th style="width: 7vw;border: 1px solid   black;">
              <span v-if="admission.is_exam && !admission.success && !admission.academic_difference">{{admission.in_semester}}</span>
              <span v-else>{{admission.total}}</span>
            </th>
          </tr>
        </table>
        <table border="1" style="font-size: 12px;text-align:center;margin: 10px;">
          <caption>Итоговая оценка:</caption>
          <tr style="border:solid">
            <th style="width: 15vw">Цифрой</th>
            <th style="width: 15vw">Прописью</th>
            <th style="width: 15vw">Подпись экзаменатора</th>
          </tr>
          <tr>
            <td style="height: 20px"><span v-if="admission.rating_detail">{{admission.rating_detail.value}}</span> </td>
            <td style="height: 20px"><span v-if="admission.rating_detail">{{admission.rating_detail.text}}</span></td>
            <td style="height: 20px"><span v-if="admission.rating_detail"></span></td>
          </tr>
        </table>
        <div style="font-size: 12px;">Дата сдачи {{displayDate(admission.officially_closed)}}  </div>
        <!--      {{displayDate(admission.officially_closed)}}-->
        <v-divider></v-divider>
        <table style="font-size: 12px;text-align:left;">

          <tr >
            <td  >№ {{admission.number}}</td>
            <td ></td>
          </tr>
          <tr>
            <td >Дата выдачи :</td>
            <td  > <strong>{{ displayDate(admission.created) }}</strong> </td>
          </tr>
          <tr>
            <td >Обучающийся (Ф.И.О.) :</td>
            <td > <strong>{{ admission.fio_student }}</strong></td>
          </tr>
          <tr>
            <td >Наименование предмета :</td>
            <td ><strong v-if="admission.semester"> {{ admission.semester.subject }} {{ admission.semester.semester }} семестр {{ admission.semester.course }}-го курса</strong></td>
          </tr>
          <tr>
            <td style="width: 25vw">Общая сумма баллов:</td>
            <td style="width: 25vw"><strong>
              <span v-if="admission.is_exam && !admission.success && !admission.academic_difference">{{admission.in_semester}}</span>
              <span v-else>{{admission.total}}</span>
            </strong></td>
          </tr>
          <tr>
            <td>Экзаменатор</td>
            <td><strong>{{admission.fio_teacher}}</strong></td>
          </tr>
          <tr>
            <td>Направление действительно на</td>
            <td>{{displayDate(admission.officially_closed)}}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
name: "PrintAdmission",
  props:{
    admissions: {
      required: true,
    },
    id: {
      default: "printMe"
    }
  },
  methods: {
    getNumberSemestr(semester,course){
      let num =0
      if(semester === 'осенний'){
        num = course * 2 - 1
      }else {
        num = course * 2
      }
      return num
    },
    displayDate: function (date, minutes = false) {
      if (!date)
        return ''
      let format = 'DD.MM.YYYY'
      if (minutes)
        format += ' HH:mm'
      return moment(date).format(format)
    },

  },
}
</script>

<style scoped>

</style>
