<template>
  <v-container style="height: 100%" v-if="$route.name === names.CathedraStudentsDetailWorks">
    <Work v-if="change"
          @close="form_close()"
          :data_work = "data_work">
    </Work>
    <v-row>
      <v-col cols="12" md="12" lg="12">
        <v-card>
          <v-toolbar
            elevation="0"
            color="style-color-main-gray"
          >
            <v-text-field class=""
                          dense
                          placeholder="Поиск по предмету"
                          v-model="search.value"
                          @keypress.enter="searchData"
                          @click:clear="clearSearchData"
                          clearable
                          messages="Для поиска нажмите Enter"
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  class="mr-2"
                  v-on="on"
                  @click="addItem"
                  medium
                >
                  <v-icon>add</v-icon>
                </v-btn>
              </template>
              <span>Загрузить работу</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  v-on="on"
                  @click="getItems()"
                  class="mr-2"
                  medium
                >
                  <v-icon>update</v-icon>
                </v-btn>
              </template>
              <span>Обновить данные</span>
            </v-tooltip>
          </v-toolbar>
          <v-data-table
            :headers="headers"
            :items="works"
            class="elevation-1 "
            no-data-text="Нет данных"
            :loading="pagination.loading"
            :page.sync="pagination.page"
            :items-per-page.sync="pagination.size"
            :server-items-length="pagination.count"
            :footer-props="{'items-per-page-options':[10,25,50], 'items-per-page-text': 'Число элементов'}"
          >
            <template v-slot:item.accept="props">
              <span v-if="props.item.accept === null">Работа еще не проверялась</span>
              <span v-else-if="props.item.accept === true">Работа проверена и принята</span>
              <span v-else  >Работа проверена и не принята</span>
            </template>
            <template v-slot:item.actions="props">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    icon
                    v-on="on"
                    @click=getDetail(props.item)
                    medium
                  >
                    <v-icon>
                      article
                    </v-icon>
                  </v-btn>
                </template>
                <span>Подробнее</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    icon
                    v-on="on"
                    @click=changeItem(props.item)
                    medium
                  >
                    <v-icon>
                      edit
                    </v-icon>
                  </v-btn>
                </template>
                <span>Редактировать</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    icon
                    v-on="on"
                    @click="openConfirmDelete(props.item)"
                    medium
                  >
                    <v-icon>
                      delete
                    </v-icon>
                  </v-btn>
                </template>
                <span>Удалить</span>
              </v-tooltip>
            </template>
          </v-data-table>

          <v-dialog persistent v-model="opened_confirm" width="500">
            <v-card v-if="deletable">
              <v-card-title>Подтвердите удаление</v-card-title>
              <v-divider></v-divider>
              <v-card-text class="mt-4">
                Вы уверены, что хотите удалить?<br>
              </v-card-text>
              <v-card-actions>
                <v-flex>
                  <v-btn
                    @click="deleteItem(deletable.id)"
                    color="warning"
                    text
                  >
                    Удалить
                  </v-btn>
                  <v-btn
                    @click="closeConfirmDelete"
                    text
                  >Отмена
                  </v-btn>
                </v-flex>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card>
      </v-col>
    </v-row>
<!--    <DebugJsonComponent :json="works"></DebugJsonComponent>-->
  </v-container>
  <router-view :key="$route.fullPath" v-else></router-view>
</template>

<script>
  import DebugJsonComponent from "../../../../core/components/DebugJsonComponent";
  import {loadData, makeDeleteRequest} from "../../../../../helper";
  import cathedras from "../../../../../urls/cathedras";
  import PaginatedDataMapperMixin from "../../../../../mixins/PaginatedDataMapperMixin";
  import {loadFile} from "@/helper/fileLoader";
  import moment from "moment";
  import Work from "@/modules/cathedra/forms/Work";
  import UserDataMixin from "@/mixins/UserDataMixin";
  import names from "../../../routers/names";
  import TitledPageMixin from "@/mixins/TitledPageMixin";

  export default {
    name: "EducationWorks",
    mixins: [TitledPageMixin, PaginatedDataMapperMixin, UserDataMixin],
    components: {
      DebugJsonComponent,
      Work
    },
    data: function(){
      return {
        names: names,
        works: [],
        page_title: 'Учебные работы',
        search: {
          parameter: 'subject',
        },
        data_work: '',
        change: false,
        loading: false,
        headers: [
          {text: 'Предмет', value: 'semester.subject', filterable: true, sortable: false, width: '20%'},
          {text: 'Курс', value: 'semester.course', filterable: false, sortable: false},
          {text: 'Семестр', value: 'semester.semester', filterable: false, sortable: false},
          {text: 'Тип файла', value: 'type', filterable: false, sortable: false},
          {text: 'Название работы', value: 'document.name', filterable: false, sortable: false},
          {text: 'Проверено', value: 'accept', filterable: false, sortable: false},
          {text: 'Файл', value: 'document.file.file_name', filterable: false, sortable: false},
          {text: "Действия", value:'actions', align: 'center', filterable: false, sortable: false},
        ],
      };
    },
    computed: {},
    methods: {
      displayDate: function (date, minutes = false) {
        let format = 'DD.MM.YYYY'
        if (minutes)
          format += ' HH:mm'
        return moment(date).format(format)
      },
      getDetail(item){
        this.$router.push({name: names.CathedraStudentsDetailWorksDetail, params: {id_work: item.id}})
      },
      addItem(){
        this.data_work = ''
        this.change = true
      },
      changeItem(data){
        this.data_work = data
        this.change = true
      },
      deleteItem(id){
        let url  = cathedras.CATHEDRA.STUDENT.WORKS.DELETE(this.$route.params.cathedra_uid, this.$route.params.student_id, id)
        makeDeleteRequest(url).then(resp=>{
          this.opened_confirm = false
          this.getItems()
        })
      },
      form_close() {
        this.change = false
        this.getItems()
        this.data_work = ''
      },
      makeMediaUrl(document){
        return cathedras.makeEduMediaUrl(document.file.file);
      },
      loadFileWork(url){
        loadFile(url)
      },
      loadData(url = undefined) {
        this.getItems(url)
      },
      getItems (url=undefined) {
        this.setLoading()
        if(url === undefined) {
          url = cathedras.CATHEDRA.STUDENT.WORKS.LIST(this.$route.params.cathedra_uid, this.$route.params.student_id)
          this.setCurrentLoadUrl(url)
        }
        loadData(
          url,
          (data) => {
            this.mapResults(data, 'works')
            this.setNotLoading()
          }
        )
      },
    },
    created() {
      this.setPageTitleWithObject("Education", {id: this.$route.params.student_id}, ' - ', true)
      this.getItems()
    }
  }
</script>

<style scoped>

</style>
