import Vue from 'vue'
import Router from 'vue-router'
// import store from '../store'
import PageNotFound from "@/modules/core/pages/PageNotFound"
import accountsRouter from "@/modules/student/routers";
import coreRouter from "@/modules/core/routers";
import administrativeRouter from "@/modules/administrative/routers";
import educationRouter from "@/modules/teacher/routers";
import cathedraRouter from "@/modules/cathedra/routers";
//import referenceRouter from "@/modules/reference/routers";
import deanRouter from "@/modules/dean/routers";
import educationalManagementRouter from '@/modules/educationalManagement/routers';
import curriculumEditorRouter from '@/modules/curriculumEditor/routers';
// import {makeGetRequest,isAliveToken} from "../helper";
import {authRequiredHook, instanceRequiredHook} from "@/router/hooks";
import {feedbackHook} from "@/store/modules/feedback";

Vue.use(Router)

const baseRoutes = [
  {
    path: "*",
    component: PageNotFound
  },
];
var allRoutes = [];

allRoutes = allRoutes.concat(
  baseRoutes,
  accountsRouter,
  coreRouter,
  educationRouter,
  deanRouter,
  administrativeRouter,
  educationalManagementRouter,
  curriculumEditorRouter,
  cathedraRouter,
);

const router = new Router(
  {
    base: '/education',
    mode: 'history',
    routes: allRoutes,
  }
);
router.beforeResolve(authRequiredHook);
router.beforeEach(instanceRequiredHook);
router.beforeEach(feedbackHook);

export default router;
