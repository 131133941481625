<template>
  <scroll-center-dialog v-model="opened_form"
                        width="500"
                        title="Назначить старосту группы"
                        @close="closeDialog"
                        @apply="update"
  >
    <v-alert type="info" outlined>
      Назначение старосты позволяет изменить текущего старосту группы
    </v-alert>
    <v-autocomplete
      class="mr-3 ml-3"
      outline
      :items="getActiveStudent"
      item-text="fio"
      item-value="id"
      no-data-text="Нет данных"
      label="Староста группы"
      v-model="value.student"
      :error="hasError('student')"
      :error-messages="getError('student')"
      @input="deleteError('student')"
    ></v-autocomplete>
  </scroll-center-dialog>
</template>

<script>
import FormErrorsMixin from "../../../../mixins/FormErrorsMixin";
import dean from "@/urls/dean";
import {sendPatchRequest} from "@/helper";
import ScrollCenterDialog from "@/modules/core/components/ScrollCenterDialog";


export default {
  name: "GroupChangeHeadman",
  components: {ScrollCenterDialog},
  mixins: [
    FormErrorsMixin
  ],
  props: ['id', 'all_students','unvisible', 'opened_form'],
  data() {
    return {
      loading: false,
      value: {
        student: undefined,
      }
    }
  },
  computed: {
    getActiveStudent: function () {
      return this.all_students.filter(e => e.active === true);
    }
  },
  methods: {
    update() {
      this.loading = true
      sendPatchRequest(
        dean.DEAN.GROUP.SELECT_HEADMAN(this.$route.params.idFaculty, this.id),
        {
          education: this.value.student
        },
        (res) => {
          this.loading = false
          this.$emit('refreshItem')
        },
        (error) => {
          this.loading = false
          this.mapErrors(error)
        }
      )
    },
    closeDialog() {
      this.$emit('ended')
    }
  },
  created() {
  }
}


</script>

<style scoped>

</style>
