<template>
  <scroll-center-dialog v-model="opened_form"
                        @close="closeDialog"
                        @apply="apply"
                        :loading="loading"
                        :title="!!id ? 'Изменить профиль подготовки' : 'Добавить профиль подготовки'"
                        width="500"
  >
    <v-text-field
      :error="hasError('name')"
      :error-messages="getError('name')"
      label="Название"
      required
      v-model="value.name"
      class="ml-1"
    ></v-text-field>
    <v-autocomplete
      :error="hasError('direction')"
      :error-messages="getError('direction')"
      :items="selectors.directions"
      label="Направление подготовки"
      :disabled="!!id"
      v-model="value.direction"
      no-data-text="Нет данных"
    ></v-autocomplete>
    <v-autocomplete
      :error="hasError('competences')"
      :error-messages="getError('competences')"
      :items="selectors.competences"
      label="Компетенции"
      v-model="value.competences"
      no-data-text="Нет данных"
      multiple
    ></v-autocomplete>
  </scroll-center-dialog>
</template>

<script>
import {loadData} from "@/helper";
import selectors from "@/urls/selectors";
import urls from "@/urls/management";
import FormErrorsMixin from "@/mixins/FormErrorsMixin";
import UserDataFormMixin from '@/mixins/UserDataFormMixin'
import ScrollCenterDialog from "@/modules/core/components/ScrollCenterDialog";

export default {
  name: "TrainingProfileForm",
  components: {ScrollCenterDialog},
  mixins: [FormErrorsMixin, UserDataFormMixin],
  props: {
    id: Number,
    direction: Object,
    name: String,
    opened_form: Boolean,
  },
  data: function (){
    return {
      URL_CREATE: urls.EDUCATION.TRAINING_PROFILE.CREATE(),
      URL_UPDATE: urls.EDUCATION.TRAINING_PROFILE.UPDATE(this.id),
      loading : false,
      value: {
        direction: null,
        name: this.name,
        competences: [],
      },
      selectors: {
        directions: [],
        competences: [],
      }
    }
  },
  methods: {
    loadSelector(){
      loadData(
        selectors.URLS.REFERENCES.DIRECTIONS(),
        (data) => {
          this.selectors.directions = data;
        }
      )
      loadData(
        selectors.URLS.REFERENCES.COMPETENCES(),
        (data) => {
          this.selectors.competences = data;
        }
      )
    },
    loadCompetences(){
      loadData(
        urls.EDUCATION.TRAINING_PROFILE.DETAIL(this.id),
        (data) => {
          this.value.competences = Array.from(data.competences, (el) => parseInt(el.id));
        },
      )
    },
    closeDialog() {
      this.$emit('ended', '')
    },
  },
  created() {
    this.loadSelector();
    if(this.id){
      this.value.direction = this.direction.id
      this.loadCompetences()
    }
  }
}
</script>

<style scoped>

</style>
