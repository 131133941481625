<template>
  <v-card class="mx-1 text-xl-left" height="100%" v-if="loaded" :elevation="elevation">
    <v-toolbar elevation="0">
      <v-toolbar-title class="font-weight-bold subtitle-1">
        <slot name="title">
            Учебный план
        </slot>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <slot name="append_title"></slot>
    </v-toolbar>
    <v-divider></v-divider>
    <div v-if="is_system && is_student" class="px-2 pt-2">
      <v-alert
        outlined
        type="info"
        border="left"
      >
        <div class="text-justify">
          Уважаемый обучающийся! При создании учебного профиля ваша группа была привязана к системному учебному плану,
          не содержащему актуальную информацию о вашем обучении, поэтому эти данные были скрыты. После того,
          как работники деканата назначат вам актуальный учебный план, информация о нем автоматически отобразится в данном разделе.
        </div>
      </v-alert>
    </div>
    <div v-else-if="is_system && !is_student" class="px-2 pt-2">
      <v-alert
        outlined
        type="info"
        border="left"
      >
        <div class="text-justify">
          При создании учебного профиля группа была привязана к системному учебному плану, не содержащему актуальную информацию,
          поэтому эти данные были скрыты. После того, как работники деканата назначат актуальный учебный план,
          информация о нем автоматически отобразится в данном разделе.
        </div>
      </v-alert>
    </div>
    <v-list dense v-else>
      <slot name="prepend_row"></slot>
      <v-list-item>
        <v-list-item-content>
          <v-row>
            <v-col cols="3" class="text-left py-1 px-3">
              Направление:
            </v-col>
            <v-col cols="9" class="text-right py-1 px-3">
              {{ direction.code }} {{ direction.name }}
            </v-col>
          </v-row>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="profile !== undefined">
        <v-list-item-content>
          <v-row>
            <v-col cols="3" class="text-left py-1 px-3">
              Профиль:
            </v-col>
            <v-col cols="9" class="text-right py-1 px-3">
              <slot name="profile">
                {{ profile }}
              </slot>
            </v-col>
          </v-row>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-content>
          <v-row>
            <v-col cols="3" class="text-left py-1 px-3">
              Год:
            </v-col>
            <v-col cols="9" class="text-right py-1 px-3">
              {{ year }}
            </v-col>
          </v-row>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="cathedra !== ''">
        <v-list-item-content>
          <v-row>
            <v-col cols="3" class="text-left py-1 px-3">
              Кафедра:
            </v-col>
            <v-col cols="9" class="text-right py-1 px-3">
              {{ cathedra }}
            </v-col>
          </v-row>
        </v-list-item-content>
      </v-list-item>
      <slot name="append_row"></slot>
    </v-list>
  </v-card>
</template>

<script>
  export default {
    name: "CUrriculumInfo",
    props: {
      elevation: {
        type: Number,
        default: 2,
      },
      loaded: {
        requirement: true,
        type: Boolean,
      },
      is_student: {
        requirement: false,
        type: Boolean,
      },
      year: {
        requirement: true,
        type: String,
      },
      profile: {
        requirement: true,
        type: String,
      },
      direction: {
        requirement: true,
        type: Object
      },
      is_system: {
        requirement: true,
        type: Boolean,
      },
      cathedra: {
        requirement: false,
        type: String,
        default: '',
      },
    }
  }
</script>

<style scoped>

</style>
