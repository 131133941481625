<template>
  <v-container style="height: 100%" v-if="is_self_router">
    <ListTemplate
      :headers="headers"
      :students="students"
      :page.sync="pagination.page"
      :size.sync="pagination.size"
      :count="pagination.count"
      :loading="pagination.loading"
      :search.sync="search.value"
      :maker_detail_link="makeDetailLink"
      :maker_group_link="makeGroupLink"
      @searchEnter="searchData"
    >
      <template v-slot:header_actions>
        <v-switch
          v-model="toggle_archive"
          inset
          hide-details
          label="Показать архивные"
          class="py-5 d-flex"
        >Выпустившиеся</v-switch>
      </template>
    </ListTemplate>
  </v-container>
  <router-view :key="$route.fullPath" v-else></router-view>
</template>


<script>

import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
import TitledPageMixin from "@/mixins/TitledPageMixin";
import {loadData} from "@/helper";
import urls from '@/urls/teacher'
import {generateListUidToName} from "@/helper/uidToName";
import names from "@/modules/teacher/routers/names";
import ListTemplate from "./ListTemplate";
import DebugJsonComponent from "@/modules/core/components/DebugJsonComponent";

export default {
  name: "StudentDiplomaList",
  mixins: [PaginatedDataMapperMixin, TitledPageMixin],
  components: {
    ListTemplate,
    DebugJsonComponent
  },
  props: {},
  data () {
    return {
      page_title: 'Список дипломников',
      students: [],
      headers: [
        {text: 'Обучающийся', value: 'fio', sortable: false, align: 'start', width: '25%'},
        {text: 'Кафедра', value: 'cathedra', sortable: false, align: 'start', width: '25%'},
        {text: 'Группа', value: 'education_group', sortable: false, align: 'center',},
        {text: 'Год поступления', value: 'entry_year', sortable: false, align: 'center',},
        {text: 'Уровень', value: 'level', sortable: false, align: 'center',},
        {text: 'Форма', value: 'form', sortable: false, align: 'center',},
      ],
      toggle_archive: false,
      pagination: {
        filters: {
          active: null,
        }
      },
      search: {
        parameter: 'fio',
      }
    }
  },
  computed: {
    is_self_router(){
      return this.$route.name === names.STUDENT.DIPLOMA;
    },
  },
  watch: {
    toggle_archive: function () {
      if(this.toggle_archive) {
        this.pagination.filters.active = false;
      }else{
        this.pagination.filters.active = null;
      }
      this.cleanPaginationPageData();
      this.setCurrentLoadUrl(
        this.addFiltersParameter(
          this.getCurrentLoadUrl()
        )
      )
      this.loadData(
        this.addFiltersParameter(
          this.getCurrentLoadUrl()
      ))
    },
  },
  methods: {
    makeGroupLink(item){
      return {name: names.GROUPS.DETAIL, params: {group_id: item.education_group.id, type: "led"}}
    },
    makeDetailLink(item){
      return {
        name: names.STUDENT.DETAIL, params: {student_id: item.id}
      }
    },
    loadData(url=undefined) {
      this.setLoading()
      if(url === undefined){
        url = urls.URLS.STUDENT.DIPLOMA_LIST();
        this.setCurrentLoadUrl(url);
      }
      loadData(
        url,
        (data) => {
          this.students = data.results;
          this.mapPagination(data);
          for (let el of this.students) this.$set(el, "fio","")
          generateListUidToName(this.students,"student","fio")
          this.setNotLoading()
        }
      )
    },
  },
  created ()  {
    this.loadData();
  },
}


</script>
<style scoped>

</style>
