<template>
  <v-container style="height: 100%" v-if="is_self_router">
    <v-card >
      <v-toolbar color="style-color-main-gray" elevation="0">
        <v-text-field
          dense
          placeholder="Поиск"
          v-model="search.value"
          @keypress.enter="searchData"
          messages="Для поиска нажмите Enter или кнопку поиск"
        >
        </v-text-field>
        <v-spacer></v-spacer>
        <v-switch
          v-model="toggle_archive"
          inset
          hide-details
          label="Показать архивные"
          class="pr-5"
        ></v-switch>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              @click="loadData()"
              class="mr-2"
              medium
            >
              update
            </v-icon>
          </template>
          <span>Обновить данные</span>
        </v-tooltip>
      </v-toolbar>
      <v-card-text class="pb-3 px-0 pt-0">
        <v-row justify-center no-gutters>
          <v-col>
            <v-data-table
              :headers="headers"
              :items="groups"
              :loading="pagination.loading"
              :page.sync="pagination.page"
              :items-per-page.sync="pagination.size"
              :server-items-length="pagination.count"
              :footer-props="{'items-per-page-options':[10,25,50]}"
              show-expand
              :expanded.sync="expanded"
              no-data-text="Нет данных"
            >
              <template v-slot:item.name="props">
                <router-link :to="makeDetailLink(props.item)">
                  {{props.item.litter}}-{{ props.item.course }}{{ props.item.number }}
                </router-link>
              </template>
              <template v-slot:item.supervisor="props">
                <span v-if="props.item.fio">{{props.item.fio}}</span>
                <span v-else>Не указан</span>
              </template>
              <template v-slot:expanded-item="{item, headers}">
                <td :colspan="headers.length" style="padding: 0px">
                  <v-card class="ma-1" width="100%">
                    <v-card-title class="my-0 py-2">
                      Список ведомых дисциплин:
                    </v-card-title>
                    <v-card-text class="d-flex">
                      <v-row>
                        <v-col cols="4" v-for="subject in item.subjects_data" :key="subject.id">
                          <div  outlined class="d-flex wrapped_text_chip py-2 px-2 " style="min-height: 80px; align-items: center">
                            {{subject.control_type}}: {{subject.subject}} {{halfSemester(subject)}}
                          </div>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </td>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
  <router-view :key="$route.fullPath" v-else></router-view>
</template>


<script>

import TitledPageMixin from "@/mixins/TitledPageMixin";
import urls from '@/urls/teacher'
import {loadData} from "@/helper";
import {generateListUidToName} from "@/helper/uidToName";
import names from "@/modules/teacher/routers/names";
import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
import DebugJsonComponent from "@/modules/core/components/DebugJsonComponent";

export default {
  name: "LedGroupList",
  mixins: [TitledPageMixin, PaginatedDataMapperMixin],
  components: {DebugJsonComponent},
  data () {
    return {
      page_title: 'Список ведомых групп',
      items: [],
      toggle_archive: false,
      search: {
        parameter: 'name',
      },
      pagination: {
        filters: {
          old: false,
        }
      },
      headers: [
        {text: 'Группа', value: 'name', sortable: false, align: 'left',},
        {text: 'Курс', value: 'course', sortable: false, align: 'left',},
        {text: 'Количество обучающихся', sortable: false, align: 'left', width: '5%',value: 'students_count'},
        {text: 'Уровень', value: 'level', sortable: false, align: 'left',},
        {text: 'Форма', value: 'form', sortable: false, align: 'left',},
        {text: 'Кафедра', value: 'cathedra', sortable: false, align: 'left', width: '40%'},
        {text: 'Куратор', value: 'supervisor',sortable: false, align: 'left', width: '20%'},
        {text: 'Дисциплины', value: 'data-table-expand', sortable: false, align: 'center' },
      ],
      groups: [],
      expanded: [],
    }
  },
  computed: {
    is_self_router(){
      return this.$route.name === names.GROUPS.LIST;
    },
  },
  watch: {
    toggle_archive: function () {
      if(this.toggle_archive) {
        this.pagination.filters.old = true;
      }else{
        this.pagination.filters.old = false;
      }
      // this.cleanPaginationPageData();
      // this.setCurrentLoadUrl(
      //   this.addFiltersParameter(
      //     this.getCurrentLoadUrl()
      //   )
      // )
      // this.loadData(
      //   this.addFiltersParameter(
      //     this.getCurrentLoadUrl()
      //   ))
    },
    expanded: function () {
      this.expanded.forEach(el => {
        if(el.subjects_data === undefined){
          this.loadSubjects(el);
        }
      })
    }
  },
  created() {
    if(this.is_self_router)
      this.loadData();
  },
  methods: {
    halfSemester(subject){
      if(subject.mottled)
        return '(Полусеместр: ' + subject.half + ' половина)'
    },
    makeDetailLink(item){
      return {name: names.GROUPS.DETAIL, params: {group_id: item.id, type: this.$route.params.type, old_filter: this.pagination.filters.old}}
    },
    loadSubjects(group) {
      let gets = null
      if(this.toggle_archive)
        gets = {old: true}
      loadData(
        urls.getEduUrlWithParameters(
          group.subjects.basename,
          group.subjects.action,
          group.subjects.params,
          gets
        ),
        data => {
          this.$set(group, 'subjects_data', data);
        }
      )
    },
    loadData (url=undefined) {
      this.setLoading()
      if(url === undefined) {
        url = urls.URLS.GROUP.LED.LIST();
        this.setCurrentLoadUrl(url);
      }
      loadData(
        url,
        (data) => {
          // this.setNotLoading();
          this.groups = data.results;
          this.mapPagination(data) ;
          this.groups.forEach(el => {
            this.$set(el, 'curator_fio', '');
          })
          generateListUidToName(this.groups,"curator","fio", () => this.setNotLoading())
        }
      )
    },
  }
}


</script>
<style scoped>
  .wrapped_text_chip {
    text-align: left;
    border: #DADADA 1px solid;
    border-radius: 10px !important;
    max-width: 100%;
  }
</style>
