// import {setUrlParameters, addGetParameters} from "../../helper";
import {getUrl, getUrlAllowed, makeMediaHostUrl, getUrlWithParameters} from "@/helper/urls";
import {EDUCATION_INSTANCE, EDUCATION_HOST} from "@/helper/consts";
import store from "../store";

// const host_account = process.env.VUE_APP_API_HOST + process.env.VUE_APP_ACCOUNTS_PREFIX.replace('/', '');
// const host_education = process.env.VUE_APP_API_HOST + process.env.VUE_APP_EDUCATION_PREFIX.replace('/', '');
// const account = String(process.env.VUE_APP_ACCOUNTS_PREFIX).replace('/', '');
// const education = String(process.env.VUE_APP_EDUCATION_PREFIX).replace('/', '');


let getEduUrlWithParameters = function(name, method, parameters, gets) {
  return getUrlWithParameters(
    EDUCATION_HOST, EDUCATION_INSTANCE, name, method, parameters, gets
  );
}

export default {

  getUrl(urlname, action) {
    let url = store.getters['urls/getPattern'](
      EDUCATION_INSTANCE,
      urlname,
      action
    );
    if (url !== '')
      return process.env.VUE_APP_API_HOST + process.env.VUE_APP_EDUCATION_PREFIX.replace('/', '') + url;
    else
      return ''

  },

  EDUCATION: {
    CHOICES: {
      SEMESTERS: (gets=null) => getEduUrlWithParameters('academic_year|choices|semester', 'get', {}, gets),
      AY_SEMESTER: (gets=null) => getEduUrlWithParameters('choices|academic|year|semester', 'get', {}, gets),
      // SEMESTERS: (gets=null) => getUrlWithParameters(host_education, education, 'choices|academic|year|semester', 'get', {}, gets),
      CONTROL_TYPE: (gets=null) => getEduUrlWithParameters('choices|curriculum|control_type', 'get', {}, gets),
      LESSON_KIND: (gets=null) => getEduUrlWithParameters('choices|curriculum|lesson_kind', 'get', {}, gets),
      GROUP_TYPE: (gets=null) => getEduUrlWithParameters('choices|group|type', 'get', {}, gets),
      LEVEL: (gets=null) => getEduUrlWithParameters('choices|ministry|level', 'get', {}, gets),
      FORM: (gets=null) => getEduUrlWithParameters('choices|ministry|form', 'get', {}, gets),
      DOCUMENT_TYPE: (gets=null) => getEduUrlWithParameters('choices|student|education_document_type', 'get', {}, gets),
      WORK_TYPE: (gets=null) => getEduUrlWithParameters('choices|individual|work|type', 'get', {}, gets),
      GROUP_HALF:(gets=null) => getEduUrlWithParameters('choices|group|half', 'get', {}, gets),
      PORTFOLIO_WORK_TYPE: (gets=null) => getEduUrlWithParameters('choices|relations|work|type', 'get', {}, gets),
      PORTFOLIO_KNOWLEDGE_TYPE: (gets=null) => getEduUrlWithParameters('choices|relations|knowledge|type', 'get', {}, gets),
      PORTFOLIO_DOCUMENT_TYPE: (gets=null) => getEduUrlWithParameters('choices|relations|document|type', 'get', {}, gets),
    },
    SELECTORS: {
      SEMESTERS:
        (group_id, gets=null) => getEduUrlWithParameters(
          'group|selectors|semester',
          'get',
          {
            group_id: group_id
          },
          gets
        )
    },
    STUDENT: {
      LIST:
        (gets=null) => getEduUrlWithParameters(
          'worker|students|education-list',
          'list',
          {},
          gets
        ),
      RETRIEVE:
        (user_id, gets=null) => getEduUrlWithParameters(
          'worker|students|education-detail',
          'retrieve',
          {
            id: user_id
          },
          gets
        ),
      FINAL_JOURNAL:
        (user_id, gets=null) => getEduUrlWithParameters(
          'worker|students|education-full-journal',
          'full_journal',
          {
            id: user_id
          },
          gets
        ),
      WORK: {
        LIST:
          (education_id, gets=null) => getEduUrlWithParameters(
            'worker|individual|work-list',
            'list',
            {
              education_id: education_id
            },
            gets
          ),
        RETRIEVE:
          (education_id, work_id, gets=null) => getEduUrlWithParameters(
            'worker|individual|work-detail',
            'retrieve',
            {
              education_id: education_id,
              id: work_id
            },
            gets
          )
      },
      CURRICULUM: {
        RETRIEVE:
          (education_id, gets=null) => getEduUrlWithParameters(
            'worker|students|individual|curriculum-detail',
            'retrieve',
            {
              education_id: education_id
            },
            gets
          ),
        SUBJECT: {
          LIST:
            (education_id, gets=null) => getEduUrlWithParameters(
              'worker|curriculum|semester-list',
              'list',
              {
                education_id: education_id,
              },
              gets
            ),
          RETRIEVE:
            (education_id, subject_id, gets=null) => getEduUrlWithParameters(
              'worker|curriculum|semester-detail',
              'retrieve',
              {
                education_id: education_id,
                id: subject_id
              },
              gets
            )
        }
      },
    },
    GROUP: {
      LIST:
        (gets=null) => getEduUrlWithParameters(
          'worker|group|group-list',
          'list',
          {},
          gets
        ),
      RETRIEVE:
        (group_id, gets=null) => getEduUrlWithParameters(
          'worker|group|group-detail',
          'retrieve',
          {
            id: group_id
          },
          gets
        ),
      ALL_TO_NEXT_COURSE:
        (gets=null) => getEduUrlWithParameters(
          'worker|group|group-move-to-next-course-all-groups',
          'move_to_next_course_all_groups',
          {},
          gets
        ),
      TO_NEXT_COURSE:
        (group_id, gets=null) => getEduUrlWithParameters(
          'worker|group|group-move-to-next-course',
          'move_to_next_course',
          {
            id: group_id
          },
          gets
        ),
      TO_PREV_COURSE:
        (group_id, gets=null) => getEduUrlWithParameters(
          'worker|group|group-move-to-prev-course',
          'move_to_prev_course',
          {
            id: group_id
          },
          gets
        ),
      STUDENTS:
        (group_id, gets=null) => getEduUrlWithParameters(
          'worker|group|group-get-all-student',
          'get_all_student',
          {
            id: group_id
          },
          gets
        ),
      SUBJECTS:
        (group_id, gets=null) => getEduUrlWithParameters(
          'worker|group|group-current-subject',
          'current_subject',
          {
            id: group_id
          },
          gets
        ),
      CURRENT_JOURNAL:
        (group_id, gets=null) => getEduUrlWithParameters(
          'worker|group|group-current-journal',
          'current_journal',
          {
            id: group_id
          },
          gets
        ),
    },
    CURRICULUM: {
      LIST:
        (gets=null) => getEduUrlWithParameters(
          'worker|curriculum|curriculum-list',
          'list',
          {},
          gets
        ),
      RETRIEVE:
        (curriculum_id, gets=null) => getEduUrlWithParameters(
          'worker|curriculum|curriculum-detail',
          'retrieve',
          {
            id: curriculum_id,
          },
          gets
        ),
    },
    ACADEMIC_YEAR: {
      LIST: (gets=null) => getEduUrlWithParameters('', '')
    },

    getUrl(urlname, action) {
      return getUrl(host_education, education, urlname, action)
    },
    getUrlAllowed(urlname, action) {
      return getUrlAllowed(education, urlname, action)
    },
    makeMediaHostUrl(url){
      return makeMediaHostUrl(host_education, url);
    },
  },

}
