<template>
  <v-container style="height: 100%" v-if="$route.name === names.CURRICULUM.LIST">
    <v-card>
      <v-toolbar
        elevation="0"
        color="style-color-main-gray"
      >
        <v-text-field
          dense
          placeholder="Поиск"
          v-model="search.value"
          @keypress.enter="searchData"
          messages="Для поиска нажмите Enter или кнопку поиск"
        >
        </v-text-field>
        <v-btn icon><v-icon>search</v-icon></v-btn>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              @click="loadData() "
              class="mr-2"
              medium
            >
              update
            </v-icon>
          </template>
          <span>Обновить данные</span>
        </v-tooltip>
      </v-toolbar>
      <v-data-table
        :items="curriculum"
        :items-per-page.sync="pagination.size"
        :headers="headers"
        no-data-text="Учебные планы не загружены"
        no-results-text="Учебные планы не были найдены"
        :search.sync="search.value"
        :page.sync="pagination.page"
        :server-items-length="pagination.count"
        :footer-props="{'items-per-page-options':[10, 25, 50], 'items-per-page-text': 'Число элементов'}"
      >
        <template v-slot:item.index="item">
         {{ ((pagination.page-1)*pagination.size+item.index+1)}}
        </template>

        <template v-slot:item.name="{ item }">
          <router-link :to="{name: names.CURRICULUM.DETAIL, params: {idCurriculum: item.id}}">
            {{item.name }}
          </router-link>
        </template>
        <template v-slot:item.is_draft="{ item }">
          <v-icon v-if="item.is_draft">done</v-icon> <v-icon v-else>clear</v-icon>
        </template>
        <template v-slot:item.is_enrollment="{ item }">
          <v-icon v-if="item.is_enrollment">done</v-icon> <v-icon v-else>clear</v-icon>
        </template>
        <template v-slot:item.foreign="{ item }">
          <v-icon v-if="item.foreign">done</v-icon> <v-icon v-else>clear</v-icon>
        </template>
        <template v-slot:item.migrated="{ item }">
          <v-icon v-if="item.migrated">done</v-icon> <v-icon v-else>clear</v-icon>
        </template>
      </v-data-table>

    </v-card>
  </v-container>
  <router-view :key="$route.fullPath" v-else></router-view>
</template>

<script>

  import {loadData} from "@/helper";
  import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
  import names from "@/modules/dean/routers/names.js";
  import UserDataMixin from "@/mixins/UserDataMixin";
  import TitledPageMixin from "../../../../mixins/TitledPageMixin";
  import dean from "@/urls/dean";

  export default {
    name: "CurriculumList",
    mixins:[
      PaginatedDataMapperMixin,
      UserDataMixin,
      TitledPageMixin
    ],

    props: {},
    data(){
      return {
        page_title: "Учебные планы",
        curriculum: [],
          URL_LIST: dean.DEAN.CURRICULUM.LIST(this.$route.params.idFaculty.toLowerCase()),
        search: {
          parameter: 'name',
        },
        names:names,
          headers: [
            {text: "№", sortable: false, align: 'left', value: 'index'},
            {text: "Название", sortable: false, align: 'left',weight:'40%',value: 'name'},
            {text: "Год", sortable: false, align: 'left',weight:'5%',value: 'year'},
            {text: "Форма", sortable: false, align: 'left',weight:'10%',value: 'form'},
            {text: "Уровень",sortable: false, align: 'left',weight:'10%',value: 'level'},
            {text: "Факультет", sortable: false,  align: 'left' ,value: 'faculty'},
            {text: "Черновик", sortable: false,  align: 'left' ,value: 'is_draft'},
            {text: "Зачисляемый", sortable: false,  align: 'left' ,value: 'is_enrollment'},
            {text: "Иностранный", sortable: false,  align: 'left' ,value: 'foreign'},
            {text: "Перенесен со старого EIOS", sortable: false,  align: 'left' ,value: 'migrated'},
          ],

      }
    },
    methods: {
      loadData(url=undefined) {
        this.loadCurriculum(url)
      },
      loadCurriculum: function (url=undefined) {
        this.setLoading()
        if(url === undefined) {
          this.setCurrentLoadUrl(
            this.URL_LIST
          )
          url = this.getCurrentLoadUrl()
        }
        loadData(
          url,
          (data) => {
            this.mapPagination(data)
            this.mapResults(data, 'curriculum')
          }
        )
      },
    },
    created() {
      this.setPageTitleWithObject("Faculty", {uid: this.$route.params.idFaculty}, ' - ')
      this.loadData()
    }
  }
</script>

<style scoped>

</style>
