<template>
  <v-container v-if="this.journal !== undefined && this.journal.length > 0">
    <v-card>
<!--      <DebugJsonComponent :json="journal"></DebugJsonComponent>-->
      <v-toolbar
        class="mb-2"
        color="style-color-main-gray"
        flat
      >
        <v-toolbar-title>
          По дисциплине
        </v-toolbar-title>
        <v-toolbar-title>
          <v-select :items="journal" item-value="id"
                    :item-text="textSubj" v-model="subject" class="ml-3"
                    hide-details dense style="min-width: 280px;" filled :disabled="save_process.execute">
            <template v-slot:item="props">{{props.item.subject.name}} ({{props.item.control_type}}) {{halfSemester(props.item.mottled, props.item.half)}}
              <v-icon small v-if="props.item.can_update" class="my-auto mx-2">edit</v-icon></template>
          </v-select>
        </v-toolbar-title>
        <v-tooltip top>
          <template v-slot:activator="{on, attrs}">
            <v-col cols="auto" class="ma-0 pa-0">
              <v-icon
                class="ml-auto"
                v-on="on"
                v-bind="attrs"
              >
                info
              </v-icon>
            </v-col>
          </template>
          <span>Уважаемые пользователи, заполнение столбца "Экзамен/Зачет" возможно только при типе контроля "экзамен/зачет с оценкой".
            Если форма контроля по предмету указана неверно, пожалуйста, обратитесь в деканат учебной группы для исправления.</span>
        </v-tooltip>
        <v-spacer></v-spacer>
        <v-toolbar-title>
          <v-text-field v-model="search" clearable placeholder="Поиск" hide-details style="min-width: 100px;" prepend-inner-icon="search" dense hint="" filled :disabled="save_process.execute"></v-text-field>
        </v-toolbar-title>
        <v-toolbar-items class="py-1 px-1">
          <v-tooltip top>
            <template v-slot:activator="{on, attrs}">
              <v-btn v-on="on" text outlined @click="loadStatement()">
                <v-icon class="mr-1" x-large>file_download</v-icon>
              </v-btn>
            </template>
            <span>Сохранить ведомость</span>
          </v-tooltip>
        </v-toolbar-items>
      </v-toolbar>
      <v-row>
        <v-col>
          <v-alert outlined type="info">
            Уважаемые пользователи, обращаем ваше внимание, что учебный журнал суммирует баллы, введенные в каждую контрольную точку по отдельности.<br/>
            Вы можете перемещаться между полями, используя стрелочки на клавиатуре, а навигация через кнопку "Tab" работает по столбцам, а не строчкам.
          </v-alert>
          <v-alert outlined type="error" v-if="!selected.synchronized">
            Оценки данного семестра нельзя редактировать, поскольку он был изменен в учебном плане.
            Пожалуйста, обратитесь в деканат учебной группы, чтобы они провели синхронизацию учебного плана.
          </v-alert>
          <JournalTable v-if="selected.students.basename === undefined" :rows="selected.students"
                        :synchronized="selected.synchronized" :control_type="control_type"
                        :can_update="selected.can_update" :search="search" :save_execute="save_process.execute"
                        :loading="loading" :errors="save_process.errors"></JournalTable>
          <v-skeleton-loader
            v-else
            type="table"
          >
          </v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row v-if="selected.can_update">
        <v-spacer></v-spacer>
        <v-col cols="auto mr-5">
          <v-btn color="success" @click="saveJournal"
                 v-if="selected.synchronized"
                 :disabled="save_process.execute" :loading="save_process.execute">Сохранить</v-btn>
        </v-col>
      </v-row>
  <!--    <DebugJsonComponent :json="save_process"></DebugJsonComponent>-->
  <!--    <DebugJsonComponent :json="selected"></DebugJsonComponent>-->
      <v-snackbar v-model="save_process.alert.visible" right :color="save_process.success?'success':save_process.success !== undefined?'warning':'info'" timeout="3000" top>
        {{save_process.alert.message}}
        <template v-slot:action>
          <v-btn icon @click="save_process.alert.visible = false">
            <v-icon>close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-card>
  </v-container>
  <v-container v-else-if="loading">
    <v-row>
      <v-col>
        <v-skeleton-loader
          height="50"
          type="list-item"
        >
        </v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-skeleton-loader
          height="200"
          type="card"
        >
        </v-skeleton-loader>
      </v-col>
    </v-row>
  </v-container>
  <v-container v-else>
    <v-toolbar
      class="mb-2"
      dark
      color="grey darken-4"
      flat
    >
      <v-toolbar-title>
        Нет данных по дисциплинам текущего семестра
      </v-toolbar-title>
    </v-toolbar>
  </v-container>
</template>

<script>
  import names from "../../routers/names";
  import cathedras from "../../../../urls/cathedras";
  import {clearUserAuth, loadData, makeGetRequest, sendPostRequest} from "../../../../helper";
  import {generateListUidToName} from "../../../../helper/uidToName";
  import DebugJsonComponent from "../../../core/components/DebugJsonComponent";
  import JournalTable from "./JournalTable";
  import TitledPageMixin from "../../../../mixins/TitledPageMixin";
  import LeftMenuChangerMixin from "../../../../mixins/LeftMenuChangerMixin";

  export default {
    name: "Base",
    mixins: [TitledPageMixin, LeftMenuChangerMixin],
    components: {DebugJsonComponent, JournalTable},
    props: {

    },
    data: function () {
      return {
        page_title: 'Журнал группы',
        group_id: this.$route.params.group_id,
        cathedra_uid: this.$route.params.cathedra_uid,
        journal: [],
        results: [],
        control_type: undefined,
        search: '',
        subject: 0,
        saved_data: undefined,
        save_process: {
          execute: false,
          success: undefined,
          errors: [],
          alert: {
            visible: false,
            message: ''
          },
        },
        selected: {},
        loading: false,
      }
    },
    watch: {
      subject: function (value) {
        if(value !== -1) {
          this.selected = this.journal.find(el => {
            return el.id === this.subject
          });
           // = finded;
          this.control_type=this.selected.control_type
          if(this.selected.students_url.basename !== undefined)
            this.loadSemester(this.selected);
        }
      },
        selected:{
          handler: function(value){
            if(this.selected.can_update)
            for (let i = 0; i < value.students.length; i++){
              this.results[i] = Number(value.students[i].medium_control[0].value) + Number(value.students[i].medium_control[1].value) +
                Number(value.students[i].medium_control[2].value) + Number(value.students[i].additional)
              if (this.results[i] >= 0 && this.selected.students[i].rating_in_semester >= 0)
                this.selected.students[i].rating_in_semester = Math.round(this.results[i])
            }
          },
          deep: true
        },
    },

    computed: {},
    methods: {
      halfSemester(mottled, half){
        if(mottled)
          return ' (Полусеместр: ' + half + ' половина)'
      },
      loadStatement(){
        makeGetRequest(
          cathedras.CATHEDRA.GROUPS.JOURNAL.STATEMENT(this.cathedra_uid, this.group_id,{'semester':this.selected.id})
        )
          .then(resp => {
            return resp.blob()
          }).then(data => {
          let a = document.createElement('a');
          let blob = new Blob([data],
            { 'type':'application/vnd.ms-excel'});
          a.href = window.URL.createObjectURL(blob);
          a.download = this.page_title.replace('Журнал группы ','') +' '+ this.selected.subject.name +'.xlsx';
          a.click();
        });
      },
      textSubj(item){
        if(item.mottled)
          return item.subject.name + " ("+item.control_type+")" + " (Полусеместр: " + item.half + " половина)"
        else
          return item.subject.name + " ("+item.control_type+")"
      },
      getLeftMenu(){
        return [
          {
            title: 'Группа',
            icon: 'arrow_back_ios',
            included: false,
            router: {
              name: names.CathedraGroupsDetail,
              params: {
                group_id: this.$route.params.group_id,
                cathedra_uid: this.$route.params.cathedra_uid,
                taught: this.$route.params.taught,
                reload_left_menu: true,
              },
              query: this.$route.query,
            }
          },
        ]
      },
      loadJournal(){
        this.loading = true;
        loadData(
          cathedras.CATHEDRA.GROUPS.JOURNAL.LOAD(this.cathedra_uid, this.group_id),
          (data) => {
            if(data.length > 0){
              this.journal = data;
              this.journal.forEach(el => {
                this.$set(el, 'students_url', el.students);
                // this.$set(el, 'students', []);
                this.$set(el, 'fio_loaded', false);
              });
              this.subject = data[0].id;
            }
            this.loading = false;
          }
        )
      },
      loadSemester(selected, finalizer=()=>{}){
        // if(selected.students.basename !== undefined)
        //   selected.students_url = selected.students;
        this.loading = true;
        loadData(
          cathedras.getByRecievedUrlData(selected.students_url, selected.students_url.get_params),
          res => {
            selected.students = res;
            this.loadStudentFios(selected, finalizer);
          }
        );
      },
      loadStudentFios(el, finalizer=()=>{}){
        el.students.forEach(el => {
          this.$set(el, 'fio', '');
          this.$set(el, 'old_exam', el.exam);
          this.$set(el, 'old_additional', el.additional);
          this.$set(el, 'old_automate', el.automate);
          this.$set(el, 'old_absence', el.absence);
          el.medium_control.forEach(sel => {
            this.$set(sel, 'old_value', sel.value);
          });
        });
        generateListUidToName(el.students, 'student_uid', 'fio', () => {
          el.fio_loaded = true;
          let students =   el.students.slice(0);
          students.sort((a,b) => a.fio.toUpperCase() > b.fio.toUpperCase() ? 1: -1)
          el.students = students
          this.loading = false;
          finalizer();
        });
      },
      makeSavedData(selected){
        let data = {
          id: selected.id,
          students: [],
        };
        selected.students.forEach(el => {
          let changed = false;
          let data_el = {
            exam: el.exam,
            additional:el.additional,
            student_education_id: el.student_education_id,
            medium_control: [],
            automate: el.automate,
            absence: el.absence,
          };
          if(el.exam !== el.old_exam) changed = true;
          if(el.additional !== el.old_additional) changed = true;
          if(el.automate !== el.old_automate) changed = true;
          if(el.absence !== el.old_absence) changed = true;
          el.medium_control.forEach(sel => {
            if(sel.value !== sel.old_value){
              changed = true;
              data_el.medium_control.push({id: sel.id, value: sel.value,})
            }
          });
          if(changed) data.students.push(data_el)
        });
        return data
      },
      saveJournal(){
        this.save_process.execute = true;
        let selected = this.selected;
        let data = this.makeSavedData(selected);
        this.saved_data = data;
        if(data.students.length > 0)
          sendPostRequest(
            cathedras.CATHEDRA.GROUPS.JOURNAL.SEMESTER.SAVE(this.cathedra_uid, this.group_id),
            data,
            (res) => {
              if(!res.hasOwnProperty('errors')){
                this.loadSemester(selected, () => {
                  this.showSuccessSaveNotification();
                  this.save_process.execute = false;
                });
              }else{
                this.showSaveWithErrorNotification();
                this.save_process.execute = false;
              }
            },
            (error) => {
              this.save_process.errors = error.errors;
              this.showSaveWithErrorNotification();
              this.save_process.execute = false;
            }
          );
        else {
          this.showNothingSaveNotification();
          this.save_process.execute = false;
        }
      },
      showSaveWithErrorNotification(){
        /**
         * Отобразить уведомление о наличии ошибок
         * */
        this.save_process.success = false;
        this.save_process.alert.message = 'Журнал не был сохранен';
        this.save_process.alert.visible = true
      },
      showSuccessSaveNotification(){
        /**
         * Отобразить уведомление об успехе
         * */
        this.save_process.success = true;
        this.save_process.alert.message = 'Журнал успешно сохранен.';
        this.save_process.alert.visible = true
      },
      showNothingSaveNotification(){
        /**
         * Отобразить уведомление об отсутствии сохраняемых данных
         * */
        this.save_process.success = undefined;
        this.save_process.alert.message = 'В журнал не внесено изменений';
        this.save_process.alert.visible = true
      },
    },
    created() {
      this.setPageTitleWithObject("Group", {id: this.group_id})
      this.loadJournal();
    }
  }
</script>

<style scoped>

</style>
