<template>
  <scroll-center-dialog v-model="opened_form"
                        :title="!!id ? 'Изменить запись' : 'Добавить запись'"
                        @close="closeDialog"
                        @apply="apply"
                        width="500"
  >
    <v-layout>
      <v-text-field
        class="mr-3 ml-3"
        :error="hasError('litter')"
        :error-messages="getError('litter')"
        label="Обозначение"
        ref="number"
        required
        v-model="value.litter"
      ></v-text-field>

      <v-text-field
        class="mr-3 ml-3"
        :error="hasError('number')"
        :error-messages="getError('number')"
        label="Номер"
        ref="number"
        required
        v-model="value.number"
      ></v-text-field>
    </v-layout>

    <v-text-field
      class="mr-3 ml-3"
      :error="hasError('enrollment_year')"
      :error-messages="getError('enrollment_year')"
      label="Год зачисления"
      ref="number"
      required
      v-model="value.enrollment_year"
    ></v-text-field>

    <loading-autocomplete
      class="mr-3 ml-3"
      placeholder="Введите ФИО и выберете из предложенных вариантов"
      label="Куратор"
      v-model="value.curator"
      :error="hasError('curator')"
      :error-messages="getError('curator')"
      search-parameter="fio"
      :url="worker_selector"
      :post-loader="postLoader"
    >
      <template v-slot:no-data>
        <div>
          Для поиска начните набирать ФИО работника, </br> и либо остановитесь на 1 секунду, либо нажмите Enter
        </div>
      </template>
    </loading-autocomplete>

    <!--            <v-autocomplete-->
    <!--              class="mr-3 ml-3"-->
    <!--              :loading="loaders.responsible.status"-->
    <!--              outline-->
    <!--              :error="hasError('curator')"-->
    <!--              :error-messages="getError('curator')"-->
    <!--              :items="selectors.responsible"-->
    <!--              no-data-text="Нет данных"-->
    <!--              label="Куратор"-->
    <!--              v-model="value.curator"-->
    <!--            ></v-autocomplete>-->

    <v-autocomplete
      class="mr-3 ml-3"
      outline
      :error="hasError('cathedra')"
      :error-messages="getError('cathedra')"
      :items="selectors.cathedra"
      no-data-text="Нет данных"
      label="Кафедра"
      v-model="value.cathedra"
      v-if="!unvisible"
    ></v-autocomplete>

    <v-autocomplete
      class="mr-3 ml-3"
      outline
      :error="hasError('form')"
      :error-messages="getError('form')"
      :items="selectors.form"
      no-data-text="Нет данных"
      label="Форма"
      v-model="value.form"
    ></v-autocomplete>

    <v-autocomplete
      class="mr-3 ml-3"
      outline
      :error="hasError('level')"
      :error-messages="getError('level')"
      :items="selectors.level"
      no-data-text="Нет данных"
      label="Уровень"
      v-model="value.level"
    ></v-autocomplete>

    <v-autocomplete
      class="mr-3 ml-3"
      outline
      :error="hasError('curriculum')"
      :error-messages="getError('curriculum')"
      :items="selectors.curriculum"
      no-data-text="Нет данных"
      label="Учебный план"
      v-model="value.curriculum"
      :disabled="value.level === null && selectors.curriculum.length === 0"
      v-if="!unvisible"
    ></v-autocomplete>

    <v-autocomplete
      class="mr-3 ml-3"
      outline
      :error="hasError('course')"
      :error-messages="getError('course')"
      :items="selectors.course"
      no-data-text="Нет данных"
      label="Курс"
      v-model="value.course"
      :disabled="value.level === null && selectors.curriculum.length === 0"
      v-if="!unvisible"
    ></v-autocomplete>

    <v-autocomplete
      class="mr-3 ml-3"
      outline
      :error="hasError('direction')"
      :error-messages="getError('direction')"
      :items="selectors.direction"
      no-data-text="Нет данных"
      label="Направление подготовки"
      v-model="value.direction"
    ></v-autocomplete>

    <v-switch
      v-if="!unvisible"
      class="mr-3 ml-3"
      color="green"
      v-model="value.archive"
      :label="`Архив ${value.archive.toString()}`"></v-switch>
    <v-checkbox
      v-model="value.for_enrollment"
      class="mr-3 ml-3"
      color="primary"
      :label="'Для зачисления'"></v-checkbox>
  </scroll-center-dialog>
</template>

<script>
  import urls from '../../../../modules/teacher/urls'
  import UserDataFormMixin from '../../../../mixins/UserDataFormMixin'
  import {addGetParameters, makeGetRequest, makePostRequest} from "../../../../helper";
  import selectors from "@/urls/selectors";
  import {generateListUidToName} from "@/helper/uidToName";
  import LoadingAutocomplete from "@/modules/core/components/LoadingAutocomplete";
  import dean from "@/urls/dean"
  import choices from "@/urls/choices"
  import ScrollCenterDialog from "@/modules/core/components/ScrollCenterDialog";

  export default {
    name: "PermissionWorker",
    mixins: [UserDataFormMixin],
    components: {ScrollCenterDialog, LoadingAutocomplete},
    props: ['id', 'attachment','unvisible', 'opened_form'],
    watch: {
      "value.level": function (nVal) {
        this.value.curriculum = null;
        this.value.course = null;
        this.loadSelector(
          'curriculum',
          selectors.SELECTORS.DEAN.CURRICULUM(this.$route.params.idFaculty,nVal)
        )

        this.loadSelector(
          'course',
          selectors.SELECTORS.DEAN.COURSE(nVal)
        )
      }
    },
    data() {
      return {
        URL_CREATE: dean.DEAN.GROUP.CREATE((this.$route.query.idFaculty) ? this.$route.query.idFaculty:this.$route.params.idFaculty),
        URL_UPDATE: dean.DEAN.GROUP.UPDATE(this.$route.params.idFaculty, this.$route.params.idGroup),
        URL_SELECT_CATHEDRA: selectors.SELECTORS.DEAN.CATHEDRA(this.$route.params.idFaculty),
        URL_SELECT_DIRECTION: selectors.SELECTORS.DEAN.DIRECTION(),
        URL_CHOICE_FORM: choices.EDUCATION.CHOICES.FORM(),
        URL_CHOICE_LEVEL: choices.EDUCATION.CHOICES.LEVEL(),
        loaders: {
          responsible: {
            status: true,
            handler: () => this.load(this, urls.getUrlOrg('selectors|worker', 'get'))
          },
        },

        selectors: {
          responsible: [],
          cathedra: [],
          form: [],
          level: [],
          curriculum: [],
          course: [],
          direction: [],
        },

        value: {
          archive: false,
          curriculum: null,
          for_enrollment: false,
          litter: '',
          number: '',
          form: null,
          level: null,
          curator: null,
          cathedra: undefined,
          enrollment_year: '2021',
          course: null,
          direction: null,
        },


      }
    },
    computed: {
      worker_selector(){
        return selectors.URLS.WORKERS.ALL()
      }
    },
    methods: {
      postLoader(data, list, end) {
        data.results.forEach(el => {
          this.$set(el, 'fio', '');
        })
        let vals = list(data.results);
        generateListUidToName(
          vals,
          "value",
          "fio",
          ()=>{
            vals.forEach(el => {
              this.$set(el, 'text', `${el.fio}, ${el.text}`)
            })
            end();
          }
        )
      },
      loadSelector(data, url) {
        //console.log(url)
        let sys = true
        makeGetRequest(url).then(resp => {
          if (!resp.ok) {
            sys = false
          }
          return resp.json()
        }).then(json => {
          this.selectors[data] = json
        })
      },
      load: function (app, url) {
        if (url === null) {
          app.loaders.responsible.status = false;
          return;
        }
        makeGetRequest(url)
          .then(resp => resp.json())
          .then(json => {
            let users = [];
            let results = [];
            let next_url = null;
            if (json['next'] !== null)
              next_url = url.split('?')[0] + '?' + json['next'].split('?')[1];
            for (let i in json['results']) {
              users.push(json['results'][i].value);
              results.push(Object.assign({}, json['results'][i]));
            }
            makePostRequest(
              urls.getUrlAcc('users|user|fio', 'post'),
              {'uids': users}
            )
              .then(resp => resp.json())
              .then(json => {
                for (let i in json) {
                  let replaced = results.find((item) => item.value === json[i].value);
                  replaced.text = json[i].text + ' (' + replaced.text + ')';
                  app.selectors.responsible.push(replaced)
                }
                results = null;
                app.load(app, next_url);
              });
          });
      },
      closeDialog() {
        this.$emit('ended', '')
      },
    },
    created() {
      this.loadSelector('cathedra', this.URL_SELECT_CATHEDRA)
      this.loadSelector('form', this.URL_CHOICE_FORM)
      this.loadSelector('level', this.URL_CHOICE_LEVEL)
      this.loadSelector('direction', this.URL_SELECT_DIRECTION)
      // this.loaders.responsible.handler()
      if(this.id !== undefined){
        makeGetRequest(this.URL_UPDATE)
          .then(resp => resp.json())
          .then(json => {
            this.value.course = json.course
            this.value.form = json.form
            this.value.level = json.level
            this.value.litter = json.litter
            this.value.number = json.number
            this.value.curriculum = json.curriculum.id
            this.value.cathedra = json.cathedra_uid
            this.value.curator = json.curator
            this.value.direction = json.direction.id
          })
      }
    }
  }

</script>

<style scoped>

</style>
