<template>
  <v-container>
    <v-card>
        <v-toolbar class="elevation-0" color="style-color-main-gray" v-if="subject.data.subject">
          <v-toolbar-title>
            Предмет учебного плана: {{ subject.data.subject.name }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
      <v-card flat class="px-6">

        <v-row no-gutters class="text-left subtitle-1 py-1">
          <v-col cols="9" md="3">Часы аудиторные</v-col>
          <v-col cols="3" md="9">{{subject.data.classroom_hours}}</v-col>
        </v-row>
        <v-row no-gutters class="text-left subtitle-1 py-1">
          <v-col cols="9" md="3">Часы самостоятельные</v-col>
          <v-col cols="3" md="9" >{{subject.data.independent_hours}}</v-col>
        </v-row>
        <v-row no-gutters class="text-left subtitle-1 py-1">
          <v-col cols="9" md="3">Экзаменационные часы</v-col>
          <v-col cols="3" md="9" >{{subject.data.exam_hours}}</v-col>
        </v-row>
        <v-row no-gutters class="text-left subtitle-1 py-1">
          <v-col cols="9" md="3">Общее количество часов</v-col>
          <v-col cols="3" md="9" >{{subject.data.hours}}</v-col>
        </v-row>
        <v-row no-gutters class="text-left subtitle-1 py-1">
          <v-col cols="12" md="3"> Файл рабочей программы:</v-col>
          <v-col cols="12" md="9" v-if="subject.data.work_program">
            {{subject.data.work_program.file_name}}
            <v-btn @click="loadFileWork(subject.data.work_program)" icon><v-icon >get_app</v-icon></v-btn>
          </v-col>
          <v-col cols="12" md="9" v-else>Файл не загружен</v-col>
        </v-row>
        <v-row no-gutters class="text-left subtitle-1 py-1">
          <v-col cols="12" md="3">Файл методических материалов:</v-col>
          <v-col cols="12" md="9" v-if="subject.data.methodological_materials">
            {{subject.data.methodological_materials.file_name}}
            <v-btn @click="loadFileWork(subject.data.methodological_materials)" icon><v-icon >get_app</v-icon></v-btn>
          </v-col>
          <v-col cols="12" md="9" v-else>Файл не загружен</v-col>
        </v-row>
        <v-row no-gutters class="text-left subtitle-1 py-1">
          <v-col cols="12" md="3">Файл оценочных средств:</v-col>
          <v-col cols="12" md="9" v-if="subject.data.appraisal_tools">
            {{subject.data.appraisal_tools.file_name}}
            <v-btn @click="loadFileWork(subject.data.appraisal_tools)" icon><v-icon >get_app</v-icon></v-btn>
          </v-col>
          <v-col cols="12" md="9" v-else>Файл не загружен</v-col>
        </v-row>
      </v-card>
      <v-card-text>
        <v-toolbar class="elevation-0" height="40">
          <v-toolbar-title>
            Список компетенций:
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="form.dialog = true">
            <v-icon>add</v-icon>
          </v-btn>
        </v-toolbar>
        <v-data-table
          :headers="subject.headers.competences"
          :items="subject.data.competences"
          :footer-props="{'items-per-page-options':[10,25,50], 'items-per-page-text': 'Число элементов'}"
        >
          <template v-slot:item="props">
            <tr class="text-left">
              <td>{{ props.index + 1 }}</td>
              <td>{{ props.item.code }}</td>
              <td>{{ props.item.name }}</td>
              <td class="text-right">
                <v-btn icon @click="deleteCompetences(props.item)">
                  <v-icon>remove</v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
        <v-dialog
          v-model="form.dialog"
          persistent
          max-width="600"
        >
          <v-card>
            <v-card-text>
              <v-card-title class="headline">
                Добавить компетенции
              </v-card-title>
              <v-autocomplete
                label="Компетенции"
                :items="choices.competences"
                v-model="form.competences"
                multiple
              ></v-autocomplete>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="red darken-1"
                  text
                  @click="form.dialog = false"
                >
                  Отменить
                </v-btn>
                <v-btn
                  color="green darken-1"
                  text
                  @click="addCompetences"
                >
                  Сохранить
                </v-btn>
              </v-card-actions>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-toolbar class="elevation-0" height="40">
          <v-toolbar-title>
            Список семестров:
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="form.add_semester = true">
            <v-icon>add</v-icon>
          </v-btn>
        </v-toolbar>
        <ApplyDialog
          v-if="form.delete_semester"
          :opened="form.delete_semester"
          :get-text="() => `Вы уверены, что хотите удалить семестр`"
          @close="deleteSemester"
        >
        </ApplyDialog>
        <v-data-table
          :headers="subject.headers.semesters"
          :items="subject.data.semesters"
          :footer-props="{'items-per-page-options':[10,25,50], 'items-per-page-text': 'Число элементов'}"
        >
          <template v-slot:item="props">
            <tr>
              <td class="text-xs-left ">{{ props.index + 1 }}</td>
              <td class="text-xs-left">{{ props.item.course }}</td>
              <td class="text-xs-left">
                <router-link :to="getDetailLink(props.item)">
                  {{ props.item.semester }}
                </router-link>
              </td>
              <td class="text-xs-left">{{ props.item.cathedra }}</td>
              <td class="text-xs-left">{{ props.item.control_type }}</td>
              <td class="text-xs-left">{{ props.item.classroom_hours }}</td>
              <td class="text-xs-left">{{ props.item.independent_hours }}</td>
              <td class="text-xs-left">{{ props.item.zets }}</td>
              <td class="text-right">
                <v-btn icon @click="openUpdateForm(props.item)">
                  <v-icon>edit</v-icon>
                </v-btn>
                <v-btn icon @click="openApplyDelete(props.item)">
                  <v-icon>remove</v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
        <Form
          v-if="form.add_semester"
          :opened="form.add_semester"
          :semester_id="choices.semester_id"
          :curriculum_id="curriculum_id"
          :subject_name="subject.data.subject.name"
          :subject="subject.data.id"
          @close="closeCreateForm"
        ></Form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import DebugJsonComponent from "../../../core/components/DebugJsonComponent";
import SemesterListTemplate from "../semesters/ListTemplate";
import Row from "../../../core/components/row/Row";
import urls from "../../urls";
import {loadData} from "../../../../helper";
import choices from "@/modules/core/urls/choices";
import {makePostRequest} from "@/helper";
import {loadFile} from "@/helper/fileLoader";
import editor_name from "@/modules/curriculumEditor/routers/names";
import {sendDeleteRequest} from "../../../../helper";
import Form from "@/modules/curriculumEditor/pages/semesters/Form";
import ApplyDialog from "@/modules/core/components/ApplyDialog";

export default {
  name: "SubjectsDetail",
  components: {
    DebugJsonComponent,
    Row,
    SemesterListTemplate,
    ApplyDialog,
    Form,
  },
  props: {},
  data() {
    return {
      title: 'Предмет',
      subject_id: this.$route.params.subject_id,
      curriculum_id: this.$route.params.curriculum_id,
      semester_id: this.$route.params.semester_id,
      form: {
        dialog: false,
        add_semester: false,
        delete_semester: false,
        competences: [],
        semesters: [],
      },
      choices: {
        competences: [],
        semester_id: undefined,
        semester: undefined,
      },
      subject: {
        headers: {
          competences: [
            {text: "№", sortable: false, align: 'left'},
            {text: "Название", sortable: false, align: 'left'},
            {text: "Код", sortable: false, align: 'left'},
            {text: "Удалить", sortable: false, align: 'right'},
          ],
          semesters: [
            {text: "№", sortable: false, align: 'left'},
            {text: "Курс", sortable: false, align: 'center'},
            {text: "Семестр", sortable: false, align: 'center'},
            {text: "Кафедра", sortable: false, align: 'center'},
            {text: "Тип контроля", sortable: false, align: 'center'},
            {text: "Кол-во аудиторных часов", sortable: false, align: 'center'},
            {text: "Кол-во самостоятельных часов", sortable: false, align: 'center'},
            {text: "Зачетные единицы", sortable: false, align: 'center'},
            {text: "Действие", sortable: false, align: 'right'},
          ]
        },
        data: {},
        loading: false,
        loaded: false,
      },
    }
  },
  methods: {
    getDetailLink(item){
      return {name: editor_name.CURRICULUM.SEMESTERS.DETAIL, params: {curriculum_id: this.curriculum_id, semester_id: item.id}}
    },
    loadFileWork(url){
      loadFile(url)
    },
    loadChoices(url, choices) {
      loadData(
        url,
        (data) => {
          this.choices[choices] = data;
        }
      )
    },
    loadCompetences() {
      this.loadChoices(choices.SELECTORS.COMPETENCES(), 'competences')
    },
    openApplyDelete(item){
      this.choices.semester_id = item.id;
      this.choices.semester = item
      this.form.delete_semester = true;
    },
    openUpdateForm(item){
      this.choices.semester_id = item.id
      this.form.add_semester = true;
    },
    deleteSemester(apply) {
      if(apply){
        sendDeleteRequest(
          urls.NAMES.EDITOR.SUBJECT.SEMESTER.DESTROY(this.subject_id, this.choices.semester_id),
          (res) => {
            this.form.delete_semester = false;
            this.subject.data.semesters.splice(this.subject.data.semesters.indexOf(this.choices.semester), 1)
          },
          (error) => {
            this.form.delete_semester = false;
            console.log(error)
          }
        )
      }
      else{
        this.form.delete_semester = false;
      }
      this.choices.semester_id = undefined
    },
    deleteCompetences(item) {
      let status = true;
      let url = urls.NAMES.EDITOR.SUBJECT.UPDATE(this.curriculum_id, this.subject_id);
      let data = {competences: []}
      this.subject.data.competences.splice(this.subject.data.competences.indexOf(item), 1)
      for (let obj in this.subject.data.competences) {
        data.competences.push(this.subject.data.competences[obj].id)
      }
      makePostRequest(url, data, "PATCH")
        .then(resp => {
          if (!resp.ok) {
            status = false;
          }
          return resp.json();
        })
        .then(json => {
          if (status) {

          } else {
            this.loadSubject();
          }
        })
    },
    addCompetences() {
      this.form.dialog = false
      let url = urls.NAMES.EDITOR.SUBJECT.UPDATE(this.curriculum_id, this.subject_id);
      let data = {competences: []}
      for (let obj in this.subject.data.competences) {
        data.competences.push(this.subject.data.competences[obj].id)
      }
      for (let obj in this.form.competences) {
        data.competences.push(this.form.competences[obj])
      }
      this.form.competences = []
      makePostRequest(url, data, "PATCH")
        .then(resp => {
          return resp.json();
        })
        .then(json => {
            this.loadSubject();
        })
    },
    loadSubject: function () {
      let saver = (data) => {
        this.subject.data = data;
        this.subject.loaded = true;
      };
      let catcher = (error) => {
        console.log(error);
      };
      let url = urls.NAMES.EDITOR.SUBJECT.RETRIEVE(this.curriculum_id, this.subject_id);
      loadData(url, saver, catcher);
    },
    closeCreateForm() {
      this.form.add_semester = false
      this.choices.semester_id = undefined
      this.loadSubject()
    },
  },
  created() {
    this.$store.commit('mainpage/changeTitle', this.title);
    this.loadSubject();
    this.loadCompetences();
  }
}
</script>

<style scoped>

</style>
