const TEACHER = {
  BASE: 'Teacher',
  JOURNAL: undefined,
  PORTFOLIO: undefined,
  STUDENT: {
    _BASE: undefined,
    LIST: undefined,
    DETAIL: undefined,
    DIPLOMA: undefined,
  },
  WORKS:{
    _BASE: undefined,
    LIST: undefined,
    DETAIL: undefined,
  },
  ADMISSIONS: {
    _BASE: undefined,
    LIST: undefined,
    DETAIL: undefined,
  },
  GROUPS: {
    _BASE: undefined,
    LIST: undefined,
    DETAIL: undefined,
    JOURNAL: undefined,
    LED: {
      _BASE: undefined,
      LIST: undefined,
      DETAIL: undefined,
    },
    SUPERVISED: {
      _BASE: undefined,
      LIST: undefined,
      DETAIL: undefined,
    }
  },
  SUBJECTS: {
    _BASE: undefined,
    LIST: undefined,
    DETAIL: undefined,
  },
  INDIVIDUAL_STUDENTS: {
    _BASE: undefined,
    LIST: undefined,
    JOURNAL: undefined,
  }
};

TEACHER.JOURNAL = `${TEACHER.BASE}|Journal`
TEACHER.PORTFOLIO = `${TEACHER.BASE}|Portfolio`

TEACHER.STUDENT._BASE = `${TEACHER.BASE}|Student`
TEACHER.STUDENT.LIST = `${TEACHER.STUDENT._BASE}|List`
TEACHER.STUDENT.DIPLOMA = `${TEACHER.STUDENT._BASE}|Diploma`
TEACHER.STUDENT.DETAIL = `${TEACHER.STUDENT._BASE}|Detail`

TEACHER.ADMISSIONS._BASE = `${TEACHER.BASE}|Admissions`
TEACHER.ADMISSIONS.LIST = `${TEACHER.ADMISSIONS._BASE}|List`
TEACHER.ADMISSIONS.DETAIL = `${TEACHER.ADMISSIONS._BASE}|Detail`

TEACHER.WORKS._BASE = `${TEACHER.BASE}|Work`
TEACHER.WORKS.LIST = `${TEACHER.WORKS._BASE}|List`
TEACHER.WORKS.DETAIL = `${TEACHER.WORKS._BASE}|Detail`

TEACHER.GROUPS._BASE = `${TEACHER.BASE}|Group`
TEACHER.GROUPS.LIST = `${TEACHER.GROUPS._BASE}|List`
TEACHER.GROUPS.DETAIL = `${TEACHER.GROUPS._BASE}|Detail`
TEACHER.GROUPS.JOURNAL = `${TEACHER.GROUPS._BASE}|Journal`

TEACHER.SUBJECTS._BASE = `${TEACHER.BASE}|Subject`
TEACHER.SUBJECTS.LIST = `${TEACHER.SUBJECTS._BASE}|List`
TEACHER.SUBJECTS.DETAIL = `${TEACHER.SUBJECTS._BASE}|Detail`

TEACHER.INDIVIDUAL_STUDENTS._BASE = `${TEACHER.BASE}|Individual_Students`
TEACHER.INDIVIDUAL_STUDENTS.LIST = `${TEACHER.INDIVIDUAL_STUDENTS._BASE}|List`
TEACHER.INDIVIDUAL_STUDENTS.JOURNAL = `${TEACHER.INDIVIDUAL_STUDENTS._BASE}|Journal`
export default {
  ...TEACHER
}
