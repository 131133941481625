<template>
  <v-row class="pa-3 ma-3">
    <v-col>
      <v-container fill-height fluid>
        <v-row align-start column>
          <v-col align-start xm12>
            <div align="left">
              <span class="grey--text  relative"><h1>404 Страница не найдена</h1></span><br>
              <strong><span>Вероятно, страница не существует. Возможно она была перемещена, удалена или переименована.</span></strong><br>
              <strong><span>Попробуйте вернуться назад или на главную страницу сайта. </span></strong><br>
              <strong>
                <span>Если вы уверены, что страница существует и адрес правильный, напишите нам на адрес </span>
                <a :href="'mailto:'+'support@muctr.ru'">support@muctr.ru </a>
                <span> с подробным описанием проблемы.</span>
              </strong><br>
            </div>
          </v-col>
        </v-row>
        <v-btn href="/" class="mt-10" rounded color="primary">Перейти на главную</v-btn>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "Error404",
  methods:{
  }
}
</script>

<style scoped>

</style>

