import {getUrl, getUrlAllowed, makeMediaHostUrl, getUrlWithParameters} from "../../helper/urls";

// const host_account = process.env.VUE_APP_API_HOST + process.env.VUE_APP_ACCOUNTS_PREFIX.replace('/', '');
const host_education = process.env.VUE_APP_API_HOST + process.env.VUE_APP_EDUCATION_PREFIX.replace('/', '');
// const account = String(process.env.VUE_APP_ACCOUNTS_PREFIX).replace('/', '');
const education = String(process.env.VUE_APP_EDUCATION_PREFIX).replace('/', '');
import {ACCOUNT_HOST, ACCOUNTS_INSTANCE, ORGANIZATION_HOST, ORGANIZATION_INSTANCE} from "@/helper/consts";


export default {

  NAMES: {
    EDITOR: {
      CURRICULUM: {
        CREATE:
          (gets = null) => getUrlWithParameters(
            host_education,
            education,
            'editor|curriculum|curriculum-list',
            'create',
            {},
            gets
          ),
        LIST:
          (gets = null) => getUrlWithParameters(
            host_education,
            education,
            'editor|curriculum|curriculum-list',
            'list',
            {},
            gets
          ),
        RETRIEVE:
          (curriculum_id, gets = null) => getUrlWithParameters(
            host_education,
            education,
            'editor|curriculum|curriculum-detail',
            'retrieve',
            {
              id: curriculum_id,
            },
            gets
          ),
        SUMMARY:
          (curriculum_id, gets = null) => getUrlWithParameters(
            host_education,
            education,
            'editor|curriculum|curriculum-summary-table',
            'summary_table',
            {
              id: curriculum_id,
            },
            gets
          ),
        DIFFERENCE:
          (curriculum_id, gets = null) => getUrlWithParameters(
            host_education,
            education,
            'editor|curriculum|curriculum-difference',
            'check_difference',
            {
              id: curriculum_id,
            },
            gets
          ),
        UPDATE:
          (curriculum_id, gets = null) => getUrlWithParameters(
            host_education,
            education,
            'editor|curriculum|curriculum-detail',
            'update',
            {
              id: curriculum_id,
            },
            gets
          ),
        PARTIAL_UPDATE:
          (curriculum_id, gets = null) => getUrlWithParameters(
            host_education,
            education,
            'editor|curriculum|curriculum-detail',
            'partial_update',
            {
              id: curriculum_id,
            },
            gets
          ),
        DESTROY:
          (gets = null) => getUrlWithParameters(
            host_education,
            education,
            'editor|curriculum|curriculum-detail',
            'destroy',
            {
            },
            gets
          ),
        CHANGE: {
          CHECK:
            (curriculum_id, gets = null) => getUrlWithParameters(
              host_education,
              education,
              'editor|curriculum|curriculum-check-cathedrals',
              'check_cathedrals',
              {
                id: curriculum_id,
              },
              gets
            ),
          EXTEND:
            (curriculum_id, gets = null) => getUrlWithParameters(
              host_education,
              education,
              'editor|curriculum|curriculum-change-extend',
              'change_extend',
              {
                id: curriculum_id,
              },
              gets
            ),
          DRAFT:
            (curriculum_id, gets = null) => getUrlWithParameters(
              host_education,
              education,
              'editor|curriculum|curriculum-change-draft',
              'change_draft',
              {
                id: curriculum_id,
              },
              gets
            ),
          FACULTY:
            (curriculum_id, gets = null) => getUrlWithParameters(
              host_education,
              education,
              'editor|curriculum|curriculum-change-faculty',
              'change_faculty',
              {
                id: curriculum_id,
              },
              gets
            ),
        },
        DUPLICATION:
          (curriculum_id, gets = null) => getUrlWithParameters(
            host_education,
            education,
            'editor|curriculum|curriculum-duplication',
            'duplication',
            {
              id: curriculum_id,
            },
            gets
          ),
        TRANSFER_LIST:
          (curriculum_id, gets = null) => getUrlWithParameters(
            host_education,
            education,
            'editor|curriculum|curriculum-transfer-list',
            'transfer_list',
            {
              id: curriculum_id,
            },
            gets
          ),
        TRANSFER_SUBJECTS:
          (curriculum_id, gets = null) => getUrlWithParameters(
            host_education,
            education,
            'editor|curriculum|curriculum-transfer-subjects',
            'transfer_subjects',
            {
              id: curriculum_id,
            },
            gets
          ),
        EXTENDED:
          (curriculum_id, gets = null) => getUrlWithParameters(
            host_education,
            education,
            'editor|curriculum|curriculum-get-extended',
            'get_extended',
            {
              id: curriculum_id,
            },
            gets
          ),
      },
      SUBJECT: {
        LIST:
          (curriculum_id, gets = null) => getUrlWithParameters(
            host_education,
            education,
            'editor|curriculum|subject-list',
            'list',
            {
              curriculum_id: curriculum_id,
            },
            gets
          ),
        CREATE:
          (curriculum_id, gets = null) => getUrlWithParameters(
            host_education,
            education,
            'editor|curriculum|subject-list',
            'create',
            {
              curriculum_id: curriculum_id,
            },
            gets
          ),
        RETRIEVE:
          (curriculum_id, subject_id, gets = null) => getUrlWithParameters(
            host_education,
            education,
            'editor|curriculum|subject-detail',
            'retrieve',
            {
              curriculum_id: curriculum_id,
              id: subject_id,
            },
            gets
          ),
        UPDATE:
          (curriculum_id, subject_id, gets = null) => getUrlWithParameters(
            host_education,
            education,
            'editor|curriculum|subject-detail',
            'update',
            {
              curriculum_id: curriculum_id,
              id: subject_id,
            },
            gets
          ),
        PARTIAL_UPDATE:
          (curriculum_id, subject_id, gets = null) => getUrlWithParameters(
            host_education,
            education,
            'editor|curriculum|subject-detail',
            'partial_update',
            {
              curriculum_id: curriculum_id,
              id: subject_id,
            },
            gets
          ),
        DESTROY:
          (curriculum_id, subject_id, gets = null) => getUrlWithParameters(
            host_education,
            education,
            'editor|curriculum|subject-detail',
            'destroy',
            {
              curriculum_id: curriculum_id,
              id: subject_id,
            },
            gets
          ),
        DIFFERENCE:
          (id, curriculum_id, gets = null) => getUrlWithParameters(
            host_education,
            education,
            'editor|curriculum|subject-difference',
            'check_difference',
            {
              id: id,
              curriculum_id: curriculum_id,
            },
            gets
          ),
        DIFFERENCE_LIST:
          (subjects, curriculum_id, gets = null) => getUrlWithParameters(
            host_education,
            education,
            'editor|curriculum|subject-difference-list',
            'check_difference_list',
            {
              curriculum_id: curriculum_id
            },
            {
              "identifiers": JSON.stringify(subjects),
              ...gets
            }
          ),
        CHANGE_CURRICULUM:
          (curriculum_id, subject_id, gets = null) => getUrlWithParameters(
            host_education,
            education,
            'editor|curriculum|subject-change-curriculum',
            'change_curriculum',
            {
              curriculum_id: curriculum_id,
              id: subject_id,
            },
            gets
          ),
        DUPLICATION:
          (curriculum_id, subject_id, gets = null) => getUrlWithParameters(
            host_education,
            education,
            'editor|curriculum|subject-duplication',
            'duplication',
            {
              curriculum_id: curriculum_id,
              id: subject_id,
            },
            gets
          ),
        SEMESTER: {
          LIST:
            (subject_id, gets = null) => getUrlWithParameters(
              host_education,
              education,
              'editor|curriculum|subject|semester-list',
              'list',
              {
                subject_id: subject_id,
              },
              gets
            ),
          CREATE:
            (subject_id, gets = null) => getUrlWithParameters(
              host_education,
              education,
              'editor|curriculum|subject|semester-list',
              'create',
              {
                subject_id: subject_id,
              },
              gets
            ),
          RETRIEVE:
            (subject_id, semester_id, gets = null) => getUrlWithParameters(
              host_education,
              education,
              'editor|curriculum|subject|semester-detail',
              'retrieve',
              {
                subject_id: subject_id,
                id: semester_id
              },
              gets
            ),
          UPDATE:
            (subject_id, semester_id, gets = null) => getUrlWithParameters(
              host_education,
              education,
              'editor|curriculum|subject|semester-detail',
              'update',
              {
                subject_id: subject_id,
                id: semester_id
              },
              gets
            ),
          PARTIAL_UPDATE:
            (subject_id, semester_id, gets = null) => getUrlWithParameters(
              host_education,
              education,
              'editor|curriculum|subject|semester-detail',
              'partial_update',
              {
                subject_id: subject_id,
                id: semester_id
              },
              gets
            ),
          DESTROY:
            (subject_id, semester_id, gets = null) => getUrlWithParameters(
              host_education,
              education,
              'editor|curriculum|subject|semester-detail',
              'destroy',
              {
                subject_id: subject_id,
                id: semester_id
              },
              gets
            ),
          CHANGE_SUBJECT:
            (subject_id, semester_id, gets = null) => getUrlWithParameters(
              host_education,
              education,
              'editor|curriculum|subject|semester-change-subject',
              'change-subject',
              {
                subject_id: subject_id,
                id: semester_id
              },
              gets
            ),
        }
      },
      SEMESTER: {
        LIST:
          (curriculum_id, gets = null) => getUrlWithParameters(
            host_education,
            education,
            'editor|curriculum|semester-list',
            'list',
            {
              curriculum_id: curriculum_id,
            },
            gets
          ),
        CREATE:
          (curriculum_id, gets = null) => getUrlWithParameters(
            host_education,
            education,
            'editor|curriculum|semester-list',
            'create',
            {
              curriculum_id: curriculum_id,
            },
            gets
          ),
        RETRIEVE:
          (curriculum_id, semester_id, gets = null) => getUrlWithParameters(
            host_education,
            education,
            'editor|curriculum|semester-detail',
            'retrieve',
            {
              curriculum_id: curriculum_id,
              id: semester_id
            },
            gets
          ),
        PARTIAL_UPDATE:
          (curriculum_id, semester_id, gets = null) => getUrlWithParameters(
            host_education,
            education,
            'editor|curriculum|semester-detail',
            'partial_update',
            {
              curriculum_id: curriculum_id,
              id: semester_id
            },
            gets
          ),
        // DESTROY:
        //   (curriculum_id, semester_id, gets = null) => getUrlWithParameters(
        //     host_education,
        //     education,
        //     'editor|curriculum|semester-detail',
        //     'destroy',
        //     {
        //       curriculum_id: curriculum_id,
        //       id: semester_id,
        //     },
        //     gets
        //   ),
        DIFFERENCE:
          (id, curriculum_id, gets = null) => getUrlWithParameters(
            host_education,
            education,
            'editor|curriculum|semester-difference',
            'check_difference',
            {
              id: id,
              curriculum_id: curriculum_id,
            },
            gets
          ),
        DIFFERENCE_LIST:
          (semesters, curriculum_id, gets = null) => getUrlWithParameters(
            host_education,
            education,
            'editor|curriculum|semester-difference-list',
            'check_difference_list',
            {
              curriculum_id: curriculum_id
            },
            {
              "identifiers": JSON.stringify(semesters),
              ...gets
            }
          ),
        LESSON_KIND: {
          LIST:
            (semester_id, gets = null) => getUrlWithParameters(
              host_education,
              education,
              'editor|curriculum|lesson_kind-list',
              'list',
              {
                semester_id: semester_id
              },
              gets
            ),
          CREATE:
            (semester_id, gets = null) => getUrlWithParameters(
              host_education,
              education,
              'editor|curriculum|lesson_kind-list',
              'create',
              {
                semester_id: semester_id
              },
              gets
            ),
          RETRIEVE:
            (semester_id, lesson_kind_id, gets = null) => getUrlWithParameters(
              host_education,
              education,
              'editor|curriculum|lesson_kind-detail',
              'retrieve',
              {
                semester_id: semester_id,
                id: lesson_kind_id,
              },
              gets
            ),
          UPDATE:
            (semester_id, lesson_kind_id, gets = null) => getUrlWithParameters(
              host_education,
              education,
              'editor|curriculum|lesson_kind-detail',
              'update',
              {
                semester_id: semester_id,
                id: lesson_kind_id,
              },
              gets
            ),
          PARTIAL_UPDATE:
            (semester_id, lesson_kind_id, gets = null) => getUrlWithParameters(
              host_education,
              education,
              'editor|curriculum|lesson_kind-detail',
              'partial_update',
              {
                semester_id: semester_id,
                id: lesson_kind_id,
              },
              gets
            ),
          DESTROY:
            (semester_id, lesson_kind_id, gets = null) => getUrlWithParameters(
              host_education,
              education,
              'editor|curriculum|lesson_kind-detail',
              'destroy',
              {
                semester_id: semester_id,
                id: lesson_kind_id,
              },
              gets
            ),
          CHANGE_SEMESTER:
            (semester_id, lesson_kind_id, gets = null) => getUrlWithParameters(
              host_education,
              education,
              'editor|curriculum|lesson_kind-change-semester',
              'change_semester',
              {
                semester_id: semester_id,
                id: lesson_kind_id,
              },
              gets
            ),
          DIFFERENCE:
            (kind_id, semester_id, gets = null) => getUrlWithParameters(
              host_education,
              education,
              'editor|curriculum|lesson_kind-difference',
              'check_difference',
              {
                id: kind_id,
                semester_id: semester_id
              },
              gets
            ),
          DIFFERENCE_LIST:
            (kinds, semester_id, gets = null) => getUrlWithParameters(
              host_education,
              education,
              'editor|curriculum|lesson_kind-difference-list',
              'check_difference_list',
              {
                semester_id: semester_id
              },
              {
                "identifiers": JSON.stringify(kinds),
                ...gets
              }
            ),
        },
        CATHEDRA: {
          UPDATE: (subject_id, id, gets = null) => getUrlWithParameters(
            host_education,
            education,
            'editor|curriculum|subject|semester-cathedrals-update',
            'cathedrals_update',
            {
              subject_id: subject_id,
              id: id
            },
            gets
          ),
        }
      },
      WORKER:{
        WORKER:
          (gets = null) => getUrlWithParameters(
            ORGANIZATION_HOST,
            ORGANIZATION_INSTANCE,
            'selectors|employee|worker',
            'get',
          {
          },
          gets
        ),
        USER:
          (gets = null) => getUrlWithParameters(
            ACCOUNT_HOST,
            ACCOUNTS_INSTANCE,
            'users|user|fio', 'post',
            {
            },
            gets
          ),
        LIST:
          (gets = null) => getUrlWithParameters(
            host_education,
            education,
            'editor|relations|workers-list',
            'list',
            {
            },
            gets
          ),
        CREATE:
          ( gets = null) => getUrlWithParameters(
            host_education,
            education,
            'editor|relations|workers-list',
            'create',
            {
            },
            gets
          ),
        DESTROY:
          (gets = null) => getUrlWithParameters(
            host_education,
            education,
            'editor|relations|workers-detail',
            'destroy',
            {},
            gets
          ),
      },
      SELECTORS: {
        WORKER:
          (gets = null) => getUrlWithParameters(
            ORGANIZATION_HOST,
            ORGANIZATION_INSTANCE,
            'selectors|employee|worker',
            'get',
            {
            },
            gets
          ),
      },
      UNIVERSITY: {
        LINK: {
          CURRICULUM: (education_id, gets = null) =>
            getUrlWithParameters(
              host_education,
              education,
              'editor|university|curriculum_link',
              "post",
              {
                'id': education_id
              },
              gets
            ),
          SUBJECT: (education_id, gets = null) =>
            getUrlWithParameters(
              host_education,
              education,
              'editor|university|curriculum_subject_link',
              "post",
              {
                'id': education_id
              },
              gets
            ),
          SEMESTER: (education_id, gets = null) =>
            getUrlWithParameters(
              host_education,
              education,
              'editor|university|curriculum_semester_link',
              "post",
              {
                'id': education_id
              },
              gets
            ),
          KIND: (education_id, gets = null) =>
            getUrlWithParameters(
              host_education,
              education,
              'editor|university|curriculum_kind_link',
              "post",
              {
                'id': education_id
              },
              gets
            ),
        },
        UPDATE_RELATED: {
          CURRICULUM: (education_id, gets = null) =>
            getUrlWithParameters(
              host_education,
              education,
              'editor|university|curriculum_update_related',
              "post",
              {
                'id': education_id
              },
              gets
            ),
          SUBJECT: (education_id, gets = null) =>
            getUrlWithParameters(
              host_education,
              education,
              'editor|university|curriculum_subject_update_related',
              "post",
              {
                'id': education_id
              },
              gets
            ),
          SEMESTER: (education_id, gets = null) =>
            getUrlWithParameters(
              host_education,
              education,
              'editor|university|curriculum_semester_update_related',
              "post",
              {
                'id': education_id
              },
              gets
            ),
          KIND: (education_id, gets = null) =>
            getUrlWithParameters(
              host_education,
              education,
              'editor|university|curriculum_kind_update_related',
              "post",
              {
                'id': education_id
              },
              gets
            ),
        },
        RESTORE_FOR_TREE: {
          CURRICULUM: (education_id, gets = null) =>
            getUrlWithParameters(
              host_education,
              education,
              'editor|university|curriculum_restore_by_tree',
              "post",
              {
                'id': education_id
              },
              gets
            ),
          SUBJECT: (education_id, gets = null) =>
            getUrlWithParameters(
              host_education,
              education,
              'editor|university|curriculum_subject_restore_by_tree',
              "post",
              {
                'id': education_id
              },
              gets
            ),
          SEMESTER: (education_id, gets = null) =>
            getUrlWithParameters(
              host_education,
              education,
              'editor|university|curriculum_semester_restore_by_tree',
              "post",
              {
                'id': education_id
              },
              gets
            ),
        },
        UPDATE_RELATION_IDENTIFY: {
          CURRICULUM: (education_id, gets = null) =>
            getUrlWithParameters(
              host_education,
              education,
              'editor|university|curriculum_restore_update_relation_identify',
              "post",
              {
                'id': education_id
              },
              gets
            ),
          SUBJECT: (education_id, gets = null) =>
            getUrlWithParameters(
              host_education,
              education,
              'editor|university|curriculum_subject_update_relation_identify',
              "post",
              {
                'id': education_id
              },
              gets
            ),
          SEMESTER: (education_id, gets = null) =>
            getUrlWithParameters(
              host_education,
              education,
              'editor|university|curriculum_semester_update_relation_identify',
              "post",
              {
                'id': education_id
              },
              gets
            ),
        },
        CREATE_CHILD_LIST: {
          BY_CURRICULUM: (education_id, gets = null) =>
            getUrlWithParameters(
              host_education,
              education,
              'editor|university|curriculum_subjects_create_by_curriculum',
              "post",
              {
                'id': education_id
              },
              gets
            ),
          BY_SUBJECT: (education_id, gets = null) =>
            getUrlWithParameters(
              host_education,
              education,
              'editor|university|curriculum_semesters_create_by_subject',
              "post",
              {
                'id': education_id
              },
              gets
            ),
          BY_SEMESTER: (education_id, gets = null) =>
            getUrlWithParameters(
              host_education,
              education,
              'editor|university|curriculum_kinds_create_by_semester',
              "post",
              {
                'id': education_id
              },
              gets
            ),
        },
        CLEAR: {
          CURRICULUM: (education_id, gets = null) =>
            getUrlWithParameters(
              host_education,
              education,
              'editor|university|curriculum_clear_relation',
              "post",
              {
                'id': education_id
              },
              gets
            ),
          SUBJECT: (education_id, gets = null) =>
            getUrlWithParameters(
              host_education,
              education,
              'editor|university|curriculum_subject_clear_relation',
              "post",
              {
                'id': education_id
              },
              gets
            ),
          SEMESTER: (education_id, gets = null) =>
            getUrlWithParameters(
              host_education,
              education,
              'editor|university|curriculum_semester_clear_relation',
              "post",
              {
                'id': education_id
              },
              gets
            ),
          KIND: (education_id, gets = null) =>
            getUrlWithParameters(
              host_education,
              education,
              'editor|university|curriculum_kind_clear_relation',
              "post",
              {
                'id': education_id
              },
              gets
            ),
        },
        CREATE_RELATED: {
          SUBJECT: (university_identity, gets = null) =>
            getUrlWithParameters(
              host_education,
              education,
              'editor|university|curriculum_subject_create_related',
              "post",
              {
                'university_identity': university_identity
              },
              gets
            ),
          SEMESTER: (university_identity, gets = null) =>
            getUrlWithParameters(
              host_education,
              education,
              'editor|university|curriculum_semester_create_related',
              "post",
              {
                'university_identity': university_identity
              },
              gets
            ),
          KIND: (university_identity, gets = null) =>
            getUrlWithParameters(
              host_education,
              education,
              'editor|university|curriculum_kind_create_related',
              "post",
              {
                'university_identity': university_identity
              },
              gets
            ),
        },



      }
    }

  },
  getUrlWithParameters(urlname, action) {
    return getUrlWithParameters(host_education, education, urlname, action)
  },
  getUrlAllowed(urlname, action) {
    return getUrlAllowed(education, urlname, action)
  },
  makeMediaHostUrl(url) {
    return makeMediaHostUrl(host_education, url);
  },
  // },
}
