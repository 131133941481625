var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',[_c('v-toolbar',{staticClass:"mb-0",attrs:{"color":"style-color-main-gray","flat":""}},[_c('v-toolbar-title',[_vm._v(" Группа: "+_vm._s(_vm.group.litter)+"-"+_vm._s(_vm.group.course)+_vm._s(_vm.group.number)+" ")]),_c('v-spacer'),_c('v-toolbar-items',{staticClass:"py-1"},[_c('v-btn',{attrs:{"text":"","outlined":"","color":"light-blue darken-4","to":_vm.journalLink}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("ballot")]),_vm._v("Журнал группы ")],1)],1)],1),_c('v-row',{staticClass:"py-3",attrs:{"wrap":""}},[_c('v-col',{staticClass:"pt-0 pr-0",staticStyle:{"border-right":"2px solid rgba(0,0,0,.12)"},attrs:{"cols":"12","lg":_vm.show_curriculum ? 6: 12}},[(_vm.group !== undefined)?_c('GroupTemplate',_vm._b({attrs:{"elevation":0}},'GroupTemplate',_vm.group,false)):_vm._e()],1),(_vm.show_curriculum)?_c('v-col',{staticClass:"pt-0 pl-0",attrs:{"cols":"12","lg":"6"}},[_c('GroupCurriculumTemplate',_vm._b({attrs:{"elevation":0,"loaded":_vm.loaded.curriculum},scopedSlots:_vm._u([{key:"profile",fn:function(){return [(!_vm.$route.params.taught)?_c('router-link',{attrs:{"to":_vm.getDetailedCurriculum(_vm.group.curriculum.id)}},[_vm._v(" "+_vm._s(_vm.group.curriculum.profile)+" ")]):_vm._e()]},proxy:true}],null,false,4184480069)},'GroupCurriculumTemplate',_vm.group.curriculum,false))],1):_vm._e()],1),_c('v-row',{attrs:{"wrap":""}},[(_vm.show_subjects)?_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"12"}},[_c('v-divider',{staticClass:"mx-1"}),(_vm.group.semesters.basename === undefined)?_c('GroupSubjectsTemplate',{attrs:{"elevation":0,"load_half":true,"semesters":_vm.group.semesters,"loaded":_vm.loaded.semesters,"show_extra_actions":false,"expandable":true,"headers":[
              {text: 'Предмет', value: 'subject', sortable: false, width: '50%'},
              // {text: 'Курс', value: 'course', sortable: false},
              // {text: 'Семестр', value: 'semester', sortable: false},
              {text: 'Кафедры', value: 'cathedrals', sortable: false, width: '45%'},
              // {text: 'Контроль', value: 'control_type', sortable: false},
              {text: '', value: 'data-table-expand', width: '5%'},
            ]}}):_vm._e()],1):_vm._e(),(_vm.show_students)?_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"12"}},[_c('v-divider',{staticClass:"mx-1"}),(_vm.group.students.basename === undefined)?_c('GroupStudentsTemplate',{attrs:{"elevation":0,"students":_vm.group.students,"loaded":_vm.group.students.basename === undefined,"group_id":_vm.group.id,"expandable":true,"show_extra_actions":true,"group":true},scopedSlots:_vm._u([{key:"student_link",fn:function({ student }){return [_c('router-link',{attrs:{"to":_vm.makeLink(student.id)}},[_vm._v(" "+_vm._s(student.fio)+" ")])]}}],null,false,1804117805)}):_vm._e()],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }