<template>
  <div>
    <v-layout justify-center>
      <v-flex>
        <v-card ref="form">
          <v-toolbar color="primary" dark>
            <v-toolbar-title>
              <span >Изменить</span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-toolbar-items>
                <v-btn @click="closeDialog" class="pa-0 ma-0" dark text icon>
                  <v-icon>close</v-icon>
                </v-btn>
              </v-toolbar-items>
            </v-toolbar-items>
          </v-toolbar>
          <v-card-text>
          <v-text-field
          label="Классные часы"
          type="number"
          :error="hasError('classroom_hours')"
          :error-messages="getError('classroom_hours')"
          v-model="value.classroom_hours"
          >
          </v-text-field>
          <v-text-field
            label="Самостоятельные часы"
            type="number"
            :error="hasError('independent_hours')"
            :error-messages="getError('independent_hours')"
            v-model="value.independent_hours"
          >
          </v-text-field>
            <v-text-field
              label="Зачетные единицы"
              type="number"
              :error="hasError('zets')"
              :error-messages="getError('zets')"
              v-model="value.zets"
            >
            </v-text-field>
<!--            <DebugJsonComponent :json="curriculum"></DebugJsonComponent>-->
            <v-btn @click="apply" color="primary">Сохранить</v-btn>
          </v-card-text>
          <v-divider class="mt-5"></v-divider>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import urls from "@/urls/dean"
import DebugJsonComponent from "@/modules/core/components/DebugJsonComponent";
import UserDataFormMixin from '@/mixins/UserDataFormMixin'

import {loadData, makeGetRequest, sendPutRequest} from "@/helper";

export default {
  name: "CuriculumForm",
  components: {
    DebugJsonComponent,
  },
  mixins: [UserDataFormMixin],
  props: [
    "curriculum"
  ],

  data() {
    return {
      value:{
        curriculum: 0,
        classroom_hours: 0,
        independent_hours: 0,
        zets: 0
      },
    }
  },
  methods: {
    closeDialog() {
      this.$emit('ended', '')
    },
    apply(){
      let url = urls.DEAN.STUDENTS.CURRICULUM.UPDATE(this.$route.params.idStudent, this.$route.params.idFaculty)
      for (let key in this.value) {
        this.value[key] = Number(this.value[key])
      }
      sendPutRequest(
        url,
        this.value ,()=>{
          this.$emit('refreshItem')
        },()=>{

        }
      )
    }
  },
  created() {
      this.value.curriculum = Number(this.curriculum.curriculum.id)
      this.value.classroom_hours = Number(this.curriculum.classroom_hours)
      this.value.independent_hours = Number(this.curriculum.independent_hours)
      this.value.zets = Number(this.curriculum.zets)
    //
    // if (this.id){
    //
    //   let saver = (data) => {
    //
    //   };
    //   let cather = (data) => {
    //     console.log(data)
    //   };
    //   // loadData(url, saver, cather);
    // }
  }
}

</script>

<style scoped>

</style>
