<template>
  <v-sheet>
    <v-card class="ma-4">
      <v-toolbar dense elevation="0" color="style-color-main-gray">
        <v-toolbar-title>
          Информация
        </v-toolbar-title>
      </v-toolbar>
      <v-container fluid class="text-start px-4">
        <single-dean-top-template></single-dean-top-template>
        <v-row class="mb-5">
          <v-col cols="12">
            Для получения справки следуйте инструкции приведенной ниже:
          </v-col>
          <v-col cols="12">
            <v-timeline dense>
              <v-timeline-item icon="post_add" color="style-color-blue-light" icon-color="black" right>
                <v-card style="vertical-align: center" elevation="2">
                  <v-container>
                    <v-row align-content="center">
                      <v-col>Заполнить заявку на получение справки (форма для заполнения находится ниже).</v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-timeline-item>
              <v-timeline-item icon="schedule" color="style-color-blue-light" icon-color="black" right>
                <v-card style="vertical-align: center" elevation="2">
                  <v-container>
                    <v-row align-content="center">
                      <v-col>
                        В случае возникновения вопросов и уточнений при формировании справки с Вами свяжутся
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-timeline-item>
              <v-timeline-item icon="assignment_late" color="style-color-blue-light" icon-color="black" right>
                <v-card style="vertical-align: center" elevation="2">
                  <v-container>
                    <v-row align-content="center">
                      <v-col>
                        Справка в бумажном виде заверенная подписью изготавливается в течение трех рабочих дней с момента подачи.
                        Получить данную справку можно в каб. 131 (Единый деканат). При себе иметь паспорт или студенческий билет.
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-timeline-item>
              <v-timeline-item icon="email" color="style-color-blue-light" icon-color="black" right>
                <v-card style="vertical-align: center" elevation="2">
                  <v-container>
                    <v-row align-content="center">
                      <v-col>По всем возникшим вопросам обращайтесь на почту  <a href="mailto:dohod@muctr.ru">dohod@muctr.ru</a>. </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-timeline-item>
            </v-timeline>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-card class="ma-4">
      <v-toolbar dense elevation="0" color="style-color-main-gray">
        <v-toolbar-title>
          Форма заказа справки
        </v-toolbar-title>
      </v-toolbar>
      <v-container class="text-start">
        <v-form ref="form">
          <v-col offset="1">
          <v-row dense>
            <v-col cols="10">
              <v-alert outlined color="info">
                <span>
                  <a target="_blank" href="https://muctr.ru/upload/iblock/4bb/4bb1771bf7a80c2e8ea61e77a44104e4.pdf">Пример справки</a>
                </span>
              </v-alert>
            </v-col>
            <v-col cols="10">
              <v-checkbox
                label="Обучение на контрактной основе"
                v-model="formated.finance"
                @change="value.financing = formated.finance ? selector.finance[1].value: selector.finance[0].value"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="10" md="5">
              <v-text-field label="Номер договора" v-model="value.contract_number" :rules="[rules.required,]"></v-text-field>
            </v-col>
            <v-col cols="10" md="5"  >
              <DateFieldWithPicker
                :max="new Date()"
                min="1950-01-01"
                label="Дата заключения договора"
                :loading="loading"
                required
                v-model="value.contract_date"
              ></DateFieldWithPicker>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="10" md="5" >
              <DateFieldWithPicker
                :max="new Date()"
                min="1950-01-01"
                label="Начало периода"
                :loading="loading"
                required
                v-model="value.period_start"
              ></DateFieldWithPicker>
            </v-col>
            <v-col cols="10"  md="5" >
              <DateFieldWithPicker
                :max="new Date()"
                min="1950-01-01"
                label="Конец периода"
                :loading="loading"
                required
                v-model="value.period_end"
              ></DateFieldWithPicker>
            </v-col>
          </v-row>
          <v-col cols="10">
            <!--              <v-btn @click="value.path = null">cl</v-btn>-->
            <v-radio-group label="Способ получения*" required v-model="value.path"
                           :error="hasError('path')"
                           :error-messages="getError('path')"
                           :rules="[rules.required]" >
              <v-radio :value="1" label="На почту в электронном виде"></v-radio>
              <v-radio :value="2" label="Лично в бумажном виде"></v-radio>
              <v-radio :value="3" label="Почтой России"></v-radio>
              <template v-slot:label>
                Способ получения <span class="red--text"><strong>* </strong></span>
              </template>
            </v-radio-group>
          </v-col>
            <v-col cols="10">
              <v-row>
                <v-text-field class="px-2" label="Телефон для связи" v-model="value.phone" :rules="[rules.phone,]"></v-text-field>
                <v-text-field  v-if="value.path === 3" class="px-2" label="Получатель письма" v-model="value.letter_recipient" :rules="[]"></v-text-field>
              </v-row>
            </v-col>
            <v-col cols="10">
              <v-row>
                <v-text-field  v-if="value.path === 3" label="Адрес получателя" v-model="value.address" :rules="[]"></v-text-field>
              </v-row>
            </v-col>
          <v-row dense>
            <v-col cols="9">
              <v-slider
                v-model="value.count"
                :rules="[rules.required,]"
                :max="10"
                :min="1"
                step="1"
                ticks="always"
                thumb-label
                label="Количество экземпляров"
              ></v-slider>
            </v-col>
            <v-col cols="1">
              <v-text-field type="number" v-model="value.count" :rules="[rules.required,]" max="10" min="1" dense></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="10">
              <v-alert outlined color="info">
                <v-checkbox v-model="value.apply" :rules="[rules.apply_required]">
                  <template v-slot:label>
                    <span>
                      Согласен на обработку моих персональных данных,
                      в соответствии с Федеральным законом
                      от 27.07.2006 года №152-ФЗ «О персональных данных»,
                      на условиях и для целей, определенных в
                      <a href="" target="_blank">Согласии на обработку персональных данных</a>
                    </span>
                  </template>
                </v-checkbox>
              </v-alert>
            </v-col>
          </v-row>
          </v-col>
        </v-form>
        <v-row>
          <v-col cols="10" offset="1" v-if="!loaded">
            <v-btn width="100%" color="success" @click="sendRequest" :loading="loading" :disabled="loading">Отправить</v-btn>
          </v-col>
          <v-col cols="10" offset="1"  v-else>
            <v-alert outlined color="success">
              Ваша заявка отправлена.
            </v-alert>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-sheet>
</template>

<script>
import TitledPageMixin from "@/mixins/TitledPageMixin";
import FormErrorsMixin from "@/mixins/FormErrorsMixin";
import SingleDeanTopTemplate from "@/modules/student/pages/SingleDean/SingleDeanTopTemplate";
import DateFieldWithPicker from "@/modules/core/components/DateFieldWithPicker";
import {makeGetRequest, sendPostRequest} from "@/helper";
import {getUrlWithParameters} from "@/helper/urls";
import moment from "moment";

export default {
  name: "Reference",
  components: {
    SingleDeanTopTemplate,
    DateFieldWithPicker,
  },
  mixins: [
    TitledPageMixin,
    FormErrorsMixin
  ],
  data: function (){
    return {
      page_title: 'Заказ справок для получения в едином деканате',
      rules: {
        required: value => !!value || 'Поле обязательно для заполнения.',
        valid_date: value => moment(value, 'DD.MM.YYYY').isValid() || 'Введите правильную дату.',
        period_start: (value) => {
          if(moment(value, 'DD.MM.YYYY') > moment(this.value.period_end, 'DD.MM.YYYY')){
            return 'Начало не может быть раньше конца'
          }else{
            return true
          }
        },
        period_end: (value) => {
          if(moment(value, 'DD.MM.YYYY') < moment(this.value.period_start)){
            return 'Начало не может быть раньше конца'
          }else{
            return true
          }
        },
        phone: value => RegExp(/((\+7)|(8))[0-9]{10}/g).test(value) || value === '' ||
          'Телефонный номер должен начинаться с 8 или +7 и содержать 10 цифр',
          apply_required: value => value || 'Подать запрос электронно без согласия на обработку персональных данных невозможно.'
      },
      selector: {
        finance: [
          {value: 1, text: 'Бюджет'},
          {value: 2, text: 'Внебюджет'},
        ],
      },
      menu: {
        contract_date: false,
        period_start: false,
        period_end: false,
      },
      formated: {
        period_start: null,
        period_end: null,
        contract_date: null,
        finance: false,
      },
      value: {
        type: 11,
        financing: 1,
        period_start: null,
        period_end: null,
        contract_number: null,
        contract_date: null,
        phone: '',
        letter_recipient: '',
        address: '',
        path: 2,
        apply: false,
        count: 1,
        education: this.$route.params.idStudent
      },
      loading: false,
      loaded: false
    }
  },
  watch: {
    'formated.contract_date' (val, oval) {
      if(oval === null || oval.length <= val.length)
        this.$nextTick(() => {
          this.formated.contract_date = this.checkAndReturnDate(val)
        })
    },
    'formated.period_start' (val, oval) {
      if(oval === null || oval.length <= val.length)
        this.$nextTick(() => {
          this.formated.period_start = this.checkAndReturnDate(val)
        })
    },
    'formated.period_end' (val, oval) {
      if(oval === null || oval.length <= val.length)
        this.$nextTick(() => {
          this.formated.period_end = this.checkAndReturnDate(val)
        })
    },
    'value.contract_date' (val) {
      this.formated.contract_date = this.formatDate(this.value.contract_date)
    },
    'value.period_start' (val) {
      this.formated.period_start = this.formatDate(this.value.period_start)
    },
    'value.period_end' (val) {
      this.formated.period_end = this.formatDate(this.value.period_end)
    },
  },
  computed: {
    send_url(){
      return getUrlWithParameters(
        process.env.VUE_APP_API_HOST + 'feedback',
        'feedback',
        'singledean|send-request-reference-bookkeeping',
        'post',
        {},
        {}
      )
    },
  },
  methods: {
    checkAndReturnDate(val) {
      let result = '';
      const dots = [3, 6];
      let i = 0;
      for(let char of val){
        i++;
        if(dots.indexOf(i) === -1){
          if(/^[0-9]$/g.test(char) && i <= 10)
            result += char
        }else{
          result += '.'
          if(char !== '.' && i <= 10)
            result += char
        }
      }
      if((result.length === 2 || result.length === 5) && result[result.length - 1] !== '.')
        result += '.'
      return result
    },
    formatDate (date) {
      if (!date) return null
      return moment(date).format('DD.MM.YYYY')
    },
    parseDate (date) {
      if (!date) return null
      if (!/[0-9]{2}.[0-9]{2}.[0-9]{4}/g.test(date))
        return null
      return moment(date, 'DD.MM.YYYY').format('YYYY-MM-YY')
    },
    sendRequest(){
      if(this.$refs.form.validate()){
        this.loading = true
        sendPostRequest(
          this.send_url,
          this.value,
          () => {
            this.loading = false;
            this.loaded = true;
          },
          (errors) => {
            this.mapErrors(errors);
            this.loading = false;
          }
        )
      }
    }
  },
  created() {
    if(!this.$store.getters['urls/checkAlreadyLoaded']('feedback')){
      makeGetRequest(process.env.VUE_APP_INSTANCE_ALLOWED_URLS + '?instances=feedback')
        .then(resp => resp.json())
        .then(data => {
          this.$store.commit('urls/appendUrls', data);
        });
    }
  }
}
</script>

<style scoped>

</style>
