export default {
  data() {
    return {
    }
  },
  computed: {
  },
  methods: {
    getLeftMenu: function () {
      return []
    },
    isReplaceMenu(menu) {
      return true
    },
    updateLeftMenu: function () {
      let menu = this.getLeftMenu();
      if(this.isReplaceMenu(menu))
        this.$store.commit('leftmenu/replaceItems', menu);
    }
  },
  beforeRouteUpdate(to, from, next){
    this.updateLeftMenu();
    next();
  },
  created() {
    this.updateLeftMenu();
  }
}
