<template>
  <div class="page-content" style="margin: 0px; width: 100%">
    <PageHeader></PageHeader>
    <EducationManagementProfile v-if="is_self_route"/>
    <router-view v-else>
    </router-view>
  </div>
</template>

<script>
  import {mapState} from 'vuex'
  import urls from '@/urls/management'
  import {getInstMenu, getInstNameByRouterName} from "@/helper/instances";
  import EducationManagementProfile from "./base/Profile";
  import LeftMenuChangerMixin from "../../../mixins/LeftMenuChangerMixin";
  import PageHeader from '@/modules/menus/PageHeader.vue'
  import names from "@/modules/educationalManagement/routers/names";

  export default {
    name: "EducationManagementBreadcrumbs",
    mixins: [
      LeftMenuChangerMixin,
    ],
    components: {
      EducationManagementProfile,PageHeader
    },
    data: function () {
      return {}
    },
    computed: {
      ...mapState({
        mainpage: state => state.mainpage,
      }),
      is_self_route: function() {
        return this.$route.name === names.EDUCATIONAL.BASE
      }
    },
    methods: {
      allowed(urlname, action) {
        return urls.getUrlAllowed(urlname, action)
      },
      getLeftMenu(){
        return [
          {title: 'Главная', icon: 'picture_in_picture', router: names.EDUCATIONAL.BASE/*'EducationalManagementMain'*/},
          {title: 'Учебные группы', icon: 'supervised_user_circle', router: names.EDUCATIONAL.GROUP.LIST},//'EducationalManagementActualGroupList'},
          {title: 'Обучающиеся', icon: 'account_box', router: names.EDUCATIONAL.STUDENTS.LIST},//'EducationalManagementActualStudentList'},
          {title: 'Учебные планы', icon: 'view_sidebar', included: true, router: names.EDUCATIONAL.CURRICULUM.LIST},//'EducationalManagementCurriculumList'},
          {title: 'Предметная документация', icon: 'analytics', included: true, router: names.EDUCATIONAL.SUBJECTS_DOC.LIST},
          {title: 'Учебные года', icon: 'today', included: true, router: names.EDUCATIONAL.ACADEMIC_YEAR.LIST},//'EducationManagementAcademicYear'},
          {title: 'Допуски', icon: 'fact_check', router: names.EDUCATIONAL.ADMISSION.LIST},//'EducationManagementAcademicYear'},
          {title: 'Выгрузки допусков', icon: 'reorder', router: names.EDUCATIONAL.ADMISSION_REQUEST.LIST},//'EducationManagementAcademicYear'},
          {title: 'Список прав', icon: 'admin_panel_settings', included: true, router: names.EDUCATIONAL.PERMISSION.LIST},
          {
            // router: 'EducationManagementReferenceBreadcrumbs',
            title: 'Справочники', icon: 'view_quilt', subs: [
              {title: 'Список дисциплин системы', icon: 'subject', router: names.EDUCATIONAL.SUBJECTS.LIST},
              {title: 'Гос. стандарты', icon: 'library_books', router: names.EDUCATIONAL.REFERENCE.STATE_STANDARDS},//'EducationManagementStateStandards'},
              {title: 'Компетенции', icon: 'web', router: names.EDUCATIONAL.REFERENCE.COMPETENCES},//'EducationManagementCompetence'},
              {title: 'Рейтинговые системы', icon: 'import_contacts', router: names.EDUCATIONAL.REFERENCE.RATING_SYSTEMS},//'EducationManagementRatingSystem'},
              {title: 'Направления подготовки', icon: 'list_alt', router: names.EDUCATIONAL.REFERENCE.TRAINING_DIRECTIONS},//'EducationManagementTrainingDirection'},
              {title: 'Профили подготовки', icon: 'view_list', router: names.EDUCATIONAL.REFERENCE.TRAINING_PROFILES},//'EducationManagementTrainingProfile'},
            ]
          },
        ];
      }
    },
    created() {
      this.$store.commit('leftmenu/enableLeftMenu');
      this.$store.commit('leftmenu/activeRouterChange', 'EducationalManagementMain');
      this.$store.commit('mainpage/changeTitle', 'Список кафедр доступных вам для управления');
      this.$store.commit('leftmenu/replaceInstances', getInstMenu());
      this.$store.commit('leftmenu/selectInstance', getInstNameByRouterName('Administrative|Educational'));
    },
  }
</script>

<style scoped>


</style>
}
