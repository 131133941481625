<template>
  <Led v-if="$route.params.type === 'led'"></Led>
  <Supervised v-else-if="$route.params.type === 'supervised'"></Supervised>
  <router-view :key="$route.fullPath" v-else></router-view>
</template>


<script>

import Led from "@/modules/teacher/pages/group/Led";
import Supervised from "@/modules/teacher/pages/group/Supervised";

export default {
  name: "GroupList",
  components: {Led, Supervised},
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {}
}


</script>
<style scoped>

</style>
