<template>
  <v-data-table
    :headers="headers"
    :items="subject.semesters"
    disable-pagination
    disable-filtering
    disable-sort
    group-by="group"
    hide-default-footer
    dense
    :expanded="expanded"
    show-expand
    class="px-3"
    style="border-right: 1px solid rgba(0,0,0,.12); border-left: 1px solid rgba(0,0,0,.12);"
    :loading="isLoading"
  >
    <template v-slot:group.header="attrs">
      <td :colspan="headers.length + 1" class="white">
        {{attrs.group}}
      </td>
    </template>
    <template v-slot:header.data-table-expand>
      <div class="d-flex">
        <tooltip-btn tooltip="Развернуть все" icon="expand_more" small bottom @click="expanded = subject.semesters"
                     ></tooltip-btn>
        <tooltip-btn tooltip="Свернуть все" icon="expand_less" small bottom @click="expanded = []"
                     ></tooltip-btn>
      </div>
    </template>
    <template v-slot:item="attrs">
      <semester
        :ref="`semester[${attrs.item.id}]`"
        :item="getItem(attrs.item.id)"
        :parent="subject"
        :attrs="attrs"
        @expand="attrs.expand"
        @update:item="(val) => subject.semesters[subject.semesters.findIndex((el) => attrs.item.id === el.id)] = val"
        :loading="isLoading"
        @update:loading="v => semesterLoading = v"
        @refresh="refresh"
      ></semester>
    </template>
    <template v-slot:expanded-item="{ item }">
      <tr>
        <td :colspan="headers.length + 1" class="mx-0 px-2">
          <Kinds :items="getItem(item.id).kinds" :semester="getItem(item.id)" @refresh="refreshKind(item.id)"></Kinds>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
import Kinds from "@/modules/curriculumEditor/pages/difference/Kinds.vue";
import Semester from "@/modules/curriculumEditor/pages/difference/Semester.vue";
import TooltipBtn from "@/modules/administrative/components/TooltipBtn.vue";

export default {
  name: "Semesters",
  components: {
    Semester, Kinds, TooltipBtn
  },
  props: {
    subject: {
      required: true
    },
    loading: {
      default: false
    }
  },
  data() {
    return {
      semesterLoading: false,
      headers: [
        {value: 'semester', text: "Семестр [Э-1C]", sortable: false, align: 'left', width:'25%'},
        {value: 'course', text: "Курс [Э-1C]", sortable: false, align: 'left', width:'15%'},
        {value: 'hours', text: "Часы [Э-1C]", sortable: false, align: 'left', width:'15%'},
        {value: 'zets', text: "Зеты [Э-1C]", sortable: false, align: 'left', width:'15%'},
        {value: 'actions', text: "Действия", sortable: false, align: 'right', width:'20%'}
      ],
      expanded: [],
    }
  },
  computed: {
    isLoading(){
      return this.loading || this.semesterLoading
    },
    semesters(){
      return this.subject.semesters
        // .map((el) => {
        //   el.group = el.eios !== null && el.one !== null ? 'Связанные' : el.eios !== null ? 'Только ЭИОС' : 'Только 1С'
        //   return el
        // })
    },
  },
  methods: {
    getItem(id){
      return this.subject.semesters[this.subject.semesters.findIndex((el) => id === el.id)]
    },
    refreshKind(index){
      this.$refs[`semester[${index}]`].refresh()
    },
    refresh(){
      this.$emit('refresh')
    }
  }
}
</script>

<style scoped>

</style>
