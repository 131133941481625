import ReferenceBreadcrumbs from '../pages/reference/ReferenceBreadcrumbs'
import Competence from "../pages/reference/Competence";
import RatingSystem from "../pages/reference/RatingSystem";

import StateStandards from "../pages/reference/StateStandards";
import TrainingDirection from "../pages/reference/TrainingDirection";
import TrainingProfile from "../pages/reference/TrainingProfile";
import ListSubjects from '../pages/reference/ListSubjects'
import names from "@/modules/educationalManagement/routers/names";
import {ACCOUNTS_INSTANCE, EDUCATION_INSTANCE} from "@/helper/consts";

export default ([
  {
    path: 'reference',
    name: names.EDUCATIONAL.REFERENCE.BASE, //'EducationManagementReferenceBreadcrumbs',
    component: ReferenceBreadcrumbs,
    meta: {
      // requiredAuth: true,
      breadcrumb: 'Справочники',
      requiredAuth: true,
      instances: [
        ACCOUNTS_INSTANCE,
        EDUCATION_INSTANCE,
      ],
    },
    children: [
      {
        path: 'subjects',
        name: names.EDUCATIONAL.SUBJECTS.LIST,
        component: ListSubjects,
        meta: {
          breadcrumb: 'Список дисциплин системы',
          requiredAuth: true,
          instances: [ACCOUNTS_INSTANCE, EDUCATION_INSTANCE,],
        },
      },
      {
        path: 'state/standards',
        name: names.EDUCATIONAL.REFERENCE.STATE_STANDARDS, //'EducationManagementStateStandards',
        component: StateStandards,
        meta: {
          breadcrumb: 'Информация о списке государственных стандартов',
          requiredAuth: true,
          instance: [
            ACCOUNTS_INSTANCE,
            EDUCATION_INSTANCE
          ]
        }
      },
      {
        path: 'competence',
        name: names.EDUCATIONAL.REFERENCE.COMPETENCES, //'EducationManagementCompetence',
        component: Competence,
        meta: {
          breadcrumb: 'Компетенция',
          requiredAuth: true,
          instance: [
            ACCOUNTS_INSTANCE,
            EDUCATION_INSTANCE
          ]
        }
      },
      {
        path: 'rating/system',
        name: names.EDUCATIONAL.REFERENCE.RATING_SYSTEMS, //'EducationManagementRatingSystem',
        component: RatingSystem,
        meta: {
          breadcrumb: 'Рейтинговая система',
          requiredAuth: true,
          instance: [
            ACCOUNTS_INSTANCE,
            EDUCATION_INSTANCE
          ]
        }
      },
      {
        path: 'training/direction',
        name: names.EDUCATIONAL.REFERENCE.TRAINING_DIRECTIONS,//'EducationManagementTrainingDirection',
        component: TrainingDirection,
        meta: {
          breadcrumb: 'Список направлений подготовки',
          requiredAuth: true,
          instance: [
            ACCOUNTS_INSTANCE,
            EDUCATION_INSTANCE
          ]
        }
      },//  ________________________________________________________________
      {
        path: 'training/profile',
        name: names.EDUCATIONAL.REFERENCE.TRAINING_PROFILES, //'EducationManagementTrainingProfile',
        component: TrainingProfile,
        meta: {
          breadcrumb: 'Информация о профилях подготовки',
          requiredAuth: true,
          instance: [
            ACCOUNTS_INSTANCE,
            EDUCATION_INSTANCE
          ]
        }
      },
      //  ________________________________________________________________
    ]
  },

])


