<template>
  <v-container>
    <v-card>
      <v-toolbar elevation="0" color="style-color-main-gray">
        <v-toolbar-title> Профили подготовки:</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn @click="opened_form = true" color="secondary" text>
            <v-icon>add</v-icon>
            Добавить
          </v-btn>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                text
                v-on="on"
                @click="reloadData()"
                class="mr-2"
                medium
              >
                <v-icon>
                  update
                </v-icon>
              </v-btn>
            </template>
            <span>Обновить данные</span>
          </v-tooltip>
        </v-toolbar-items>
      </v-toolbar>
      <v-row>
        <v-col cols="7">
          <v-text-field v-model="search.value"
                        dense
                        class="pa-3"
                        placeholder="Поиск по наименованию"
                        @keypress.enter="searchData()"
                        @click:clear="clearSearchData()"
                        messages="Для поиска нажмите Enter"
                        clearable
          ></v-text-field>
        </v-col>
        <v-col cols="5">
          <v-select
            class="pa-2"
            v-model="pagination.filters.direction"
            label="Направление"
            :items="selectors.directions"
          ></v-select>
        </v-col>

      </v-row>
      <v-data-table
        no-data-text="Нет данных"
        :headers="headers"
        :items="items"
        :loading="pagination.loading"
        :page.sync="pagination.page"
        :items-per-page.sync="pagination.size"
        :server-items-length="pagination.count"
        :footer-props="{'items-per-page-options':[10,25,50], 'items-per-page-text': 'Число элементов'}"
        locale="ru"
      >
        <template v-slot:item.direction="props">
          {{props.item.direction.code}} {{props.item.direction.name}}
        </template>
        <template v-slot:item.actions="props">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                v-on="on"
                @click="updateItem(props.item)"
                medium>
                <v-icon>
                  edit
                </v-icon>
              </v-btn>
            </template>
            <span>Редактировать</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                v-on="on"
                @click="openConfirmDelete(props.item)"
                medium>
                <v-icon>
                  delete
                </v-icon>
              </v-btn>
            </template>
            <span>Удалить</span>
          </v-tooltip>
        </template>
        <template slot="no-data">
          <v-card-text>Данные не загружены</v-card-text>
        </template>
      </v-data-table>
      <TrainingProfileForm
        @addItem="addItem"
        @ended="closedForm"
        @refreshItem="refreshItem"
        @input="closedForm"
        :opened_form="opened_form"
        v-bind="selected"
        v-if="opened_form"
      >
      </TrainingProfileForm>
      <v-dialog persistent v-model="opened_confirm" width="500">
        <v-card v-if="deletable">
          <v-card-title>Подтвердите удаление</v-card-title>
          <v-divider></v-divider>
          <v-card-text class="mt-4">
            Вы уверены, что хотите удалить?<br>
          </v-card-text>
          <v-card-actions>
            <v-flex>
              <v-btn @click="deleteItem(deletable.id)" color="warning" text>
                Удалить
              </v-btn>
              <v-btn @click="closeConfirmDelete" text>
                Отмена
              </v-btn>
            </v-flex>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </v-container>
</template>

<script>
import urls from "@/urls/management";
import {addGetParameters, loadData, makeDeleteRequest} from "@/helper"
import TrainingProfileForm from "@/modules/educationalManagement/forms/TrainingProfileForm";
import DebugJsonComponent from "@/modules/core/components/DebugJsonComponent";
import TitledPageMixin from "@/mixins/TitledPageMixin";
import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
import selectors from "@/urls/selectors";
import UserDataMixin from "@/mixins/UserDataMixin"

export default {
  name: "TrainingProfile",
  components: {TrainingProfileForm, DebugJsonComponent},
  mixins: [TitledPageMixin, PaginatedDataMapperMixin, UserDataMixin],
  props: {},
  data() {
    return {
      page_title: 'Список профилей подготовки',
      headers: [
        {text: 'Название', value: 'name', align: 'start', sortable: false, width: '40%',},
        {text: 'Направление', value: 'direction', align: 'start', sortable: false, width: '50%',},
        {text: 'Действия', value: 'actions', align: 'right', sortable: false},
      ],
      selectors:{
        directions: '',
      },
      search:{
        parameter: 'name',
        value: '',
        // direction:'',
        // name:''
      },
      pagination: {
        filters: {
          direction: '',
        },
      },
      items: [],
    }
  },
  methods: {
    loadData(url = undefined) {
      this.setLoading();
      if (url === undefined) {
        url = urls.EDUCATION.TRAINING_PROFILE.LIST();
        url = this.addFiltersParameter(url);
        this.setCurrentLoadUrl();
      }
      loadData(
        url,
        (data) => {
          this.mapPagination(data);
          this.items = data.results;
          this.setCurrentLoadUrl(url);
          this.setNotLoading();
        }
      )
    },
    getItems (url = undefined) {
      this.loadData(this.getCurrentLoadUrl());
      this.setCurrentLoadUrl(url, true);
    },
    addItem(){
      this.closedForm()
      this.getItems()
    },
    deleteItem(id) {
      let url = urls.EDUCATION.TRAINING_PROFILE.DELETE(id)
      makeDeleteRequest(url).then(resp => {
        if (resp.ok) {
          this.closeConfirmDelete()
          this.getItems()
        }
      })
    },
    loadSelector(){
      loadData(
        selectors.URLS.REFERENCES.DIRECTIONS(),
        (data) => {
          this.selectors.directions = data
          this.selectors.directions.unshift({text:'Все',value: ""})
        }
      )
    }
  },
  created(){
    this.$store.commit('mainpage/changeTitle', 'Профили подготовки')
    this.loadSelector();
    this.loadData();
  },
}
</script>

<style scoped>

</style>
