<template>
  <v-container style="height: 100%">
    <v-card flat>
      <v-toolbar
        elevation="0"
        color="style-color-main-gray"
      >
        <span>Список групп:</span>
        <v-spacer></v-spacer>
        <v-text-field
          dense
          placeholder="Поиск"
          v-model="search"
          append-outer-icon="search"
        >
        </v-text-field>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              @click="getItems()"
              class="mr-2"
              medium
            >
              update
            </v-icon>
          </template>
          <span>Обновить данные</span>
        </v-tooltip>
      </v-toolbar>

        <v-layout justify-center>
          <v-flex>
            <v-data-table
              :search = "search"
              :headers="headers"
              :items="items"
              :loading="loading"
              class="elevation-1"
              no-data-text="Нет данных"
              :footer-props="{'items-per-page-options':[10,25,50], 'items-per-page-text': 'Число элементов'}"
            >
              <template v-slot:item="props">
                <tr class="text-left">
                  <td class="justify-center">
                    <router-link  :to="{ name:names.DEPARTAMENTS.GROUP.LED.DETAIL, params: {idGroup: props.item.id }}">
                      {{props.item.name}}
                    </router-link>
                  </td>
                  <td class="justify-center">{{props.item.course}}</td>
                  <td class="justify-center">{{props.item.form}}</td>
                  <td class="justify-center">{{props.item.level}}</td>
                </tr>
              </template>
              <template slot="no-data">
                <v-card-text>Данные не загружены</v-card-text>
              </template>
            </v-data-table>
          </v-flex>
        </v-layout>
    </v-card>
  </v-container>
</template>


<script>

  import UserDataMixin from '@/mixins/UserDataMixin'
  import {makeGetRequest} from "@/helper";
  import urls from "@/urls/dean"
  import names from "../../../../routers/names";

  export default {
    name: "Groups",
    // components: {Courses},
    mixins: [UserDataMixin],
    props: {
    },
    data () {
      return {
        URL_LIST: urls.DEAN.CATHEDRA.TEACHER.GROUP.LIST(this.$route.params.uidDepartament, this.$route.params.uidTeacher),
        names:names,
        items: [],
        search:"",
        loading: true,
        pagination: {},
        headers: [
          {text: 'Группа', sortable: false, align: 'justify-center',value:"name"},
          {text: 'Курс', sortable: false, align: 'justify-center',value:"course"},
          {text: 'Профиль', sortable: false, align: 'justify-center', },
          {text: 'Направление', sortable: false, align: 'justify-center', },

        ]
      }
    },

    created() {
      this.getItems();
    },
    methods: {

      addItem(){
        this.closedForm()
        this.getItems();
      },

      getItems () {
        this.loading = true
        let url = new URL(this.URL_LIST)

        let sys = true
        makeGetRequest(url).then(resp => {
          if (!resp.ok) {
            sys = false
          }
          return resp.json()
        }) .then(json => {
          if (json.results)
            this.items = json.results;
          else
            this.items = json;
          this.total = json.count
          this.loading = false
        })
      },
    }
  }


</script>
<style scoped>

</style>
