<template>
  <v-card>
    <v-alert
      outlined
      type="info"
      border="left"
      v-if="curriculum.is_system"
    >
      <div class="text-justify">
        Группа привязана к системному учебному плану, не содержащему актуальную информацию, поэтому эти данные были скрыты.
        Назначьте, пожалуйста, актуальный учебный план и синхронизируйте.
        После этого информация о нем автоматически отобразится в данном разделе.
      </div>
    </v-alert>
    <template v-else>
      <v-toolbar class="mb-2" color="style-color-main-gray" flat>
        <v-card-title v-if="curriculum.level" class="text-break">
          {{curriculum.level[0].toUpperCase()}}{{curriculum.level.slice(1)}} {{curriculum.form.replace('ая', 'ой')}} формы {{curriculum.year}} года
        </v-card-title>
        <v-spacer></v-spacer>
        <template v-slot:extension v-if="$vuetify.breakpoint.mobile && show_extra_actions">
          <v-toolbar-items @click.stop="" class="mx-auto">
            <slot name="extra_actions"></slot>
          </v-toolbar-items>
        </template>
        <v-toolbar-items @click.stop="" v-if="!$vuetify.breakpoint.mobile && show_extra_actions">
          <slot name="extra_actions"></slot>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="text-left body-2 black--text">
        <slot name="prepend_body"></slot>
        <v-row no-gutters v-if="curriculum ">
          <v-col cols="12" sm="4" class="font-weight-bold">Тип плана:</v-col>
          <v-col v-if="is_individual" cols="12" sm="8" class="text-justify">Индивидуальный</v-col>
          <v-col v-else cols="12" sm="8" class="text-justify">{{personal}}</v-col>
        </v-row>
        <v-divider  v-if="curriculum" class="my-2"></v-divider>
        <v-row no-gutters v-if="curriculum && curriculum.faculty">
          <v-col cols="12" sm="4" class="font-weight-bold">Факультет:</v-col>
          <v-col cols="12" sm="8" class="text-justify">{{curriculum.faculty}}</v-col>
        </v-row>
        <v-divider  v-if="curriculum && curriculum.faculty" class="my-2"></v-divider>
        <v-row no-gutters v-if="curriculum">
          <v-col cols="12" sm="4" class="font-weight-bold">Направление подготовки:</v-col>
          <v-col cols="12" sm="8" class="text-justify">{{curriculum.direction.code}} {{curriculum.direction.name}}</v-col>
        </v-row>
        <v-divider class="my-2"></v-divider>
        <v-row no-gutters v-if="curriculum">
          <v-col cols="12" sm="4" class="font-weight-bold">Профиль подготовки:</v-col>
          <v-col cols="12" sm="8" class="text-justify">{{curriculum.profile.name}}</v-col>
        </v-row>
        <v-divider class="my-2"></v-divider>

        <v-row no-gutters v-if="curriculum">
          <v-col cols="12" sm="4" class="font-weight-bold d-flex align-center">Государственный стандарт:</v-col>
          <v-col cols="12" sm="8" class="text-justify">
            <v-row >
              <v-col cols="auto" class="d-flex align-center">Номер {{curriculum.standard.number}} от {{curriculum.standard.year}} года</v-col>
              <v-spacer></v-spacer>
              <v-col cols="auto" class="d-flex align-center">
                {{curriculum.standard.document.file.file_name}}
                <v-btn @click="loadFileWork(curriculum.standard.document.file)" icon><v-icon >get_app</v-icon></v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-divider class="my-2"></v-divider>

        <v-row no-gutters v-if="curriculum">
          <v-col cols="12" sm="4" class="font-weight-bold">Год поступления:</v-col>
          <v-col cols="12" sm="8" class="text-justify">{{curriculum.year}}</v-col>
        </v-row>
        <v-divider class="my-2"></v-divider>

        <v-row no-gutters v-if="curriculum">
          <v-col cols="12" sm="4" class="font-weight-bold">Уровень образования:</v-col>
          <v-col cols="12" sm="8" class="text-justify">{{curriculum.level}}</v-col>
        </v-row>
        <v-divider class="my-2"></v-divider>
        <v-row no-gutters v-if="curriculum">
          <v-col cols="12" sm="4" class="font-weight-bold">Форма обучения:</v-col>
          <v-col cols="12" sm="8" class="text-justify">{{curriculum.form}}</v-col>
        </v-row>
        <v-divider class="my-2"></v-divider>
        <v-row no-gutters v-if="curriculum  && curriculum.exam_hours">
          <v-col cols="12" sm="4" class="font-weight-bold">Экзаменационные часы:</v-col>
          <v-col cols="12" sm="8" class="text-justify">{{curriculum.exam_hours}}</v-col>
        </v-row>
        <v-divider  v-if="curriculum  && curriculum.independent_hours" class="my-2"></v-divider>
        <v-row no-gutters v-if="curriculum  && curriculum.independent_hours">
          <v-col cols="12" sm="4" class="font-weight-bold">Самостоятельные часы:</v-col>
          <v-col cols="12" sm="8" class="text-justify">{{curriculum.independent_hours}}</v-col>
        </v-row>
        <v-divider  v-if="curriculum  && curriculum.independent_hours" class="my-2"></v-divider>
        <v-row no-gutters v-if="curriculum  && curriculum.classroom_hours">
          <v-col cols="12" sm="4" class="font-weight-bold">Аудиторные часы:</v-col>
          <v-col cols="12" sm="8" class="text-justify">{{curriculum.classroom_hours}}</v-col>
        </v-row>
        <v-divider  v-if="curriculum  && curriculum.hours" class="my-2"></v-divider>
        <v-row no-gutters v-if="curriculum  && curriculum.hours">
          <v-col cols="12" sm="4" class="font-weight-bold">Общее количество часов:</v-col>
          <v-col cols="12" sm="8" class="text-justify">{{curriculum.hours}}</v-col>
        </v-row>
        <v-divider  v-if="curriculum  && curriculum.classroom_hours" class="my-2"></v-divider>
        <v-row no-gutters v-if="curriculum  && curriculum.zets">
          <v-col cols="12" sm="4" class="font-weight-bold">Зачетные единицы:</v-col>
          <v-col cols="12" sm="8" class="text-justify">{{curriculum.zets}}</v-col>
        </v-row>
        <v-divider  v-if="curriculum  && curriculum.zets" class="my-2"></v-divider>
        <v-row no-gutters v-if="curriculum && typeof (curriculum.is_draft) === 'boolean'">
          <v-col cols="12" sm="4" class="font-weight-bold">Черновик:</v-col>
          <v-col cols="12" sm="8" class="text-justify">
            <v-icon v-if="curriculum.is_draft">done</v-icon>
            <v-icon v-else>clear</v-icon> </v-col>
        </v-row>
        <v-divider  v-if="curriculum && typeof (curriculum.is_draft) === 'boolean'" class="my-2"></v-divider>
        <v-row no-gutters v-if="curriculum && typeof (curriculum.is_enrollment) === 'boolean'">
          <v-col cols="12" sm="4" class="font-weight-bold">Для зачисления:</v-col>
          <v-col cols="12" sm="8" class="text-justify">
            <v-icon v-if="curriculum.is_enrollment">done</v-icon>
            <v-icon v-else>clear</v-icon> </v-col>
        </v-row>
        <v-divider  v-if="curriculum && typeof (curriculum.is_enrollment) === 'boolean'" class="my-2"></v-divider>
        <v-row no-gutters  v-if="curriculum && typeof (curriculum.extend) === 'object'">
          <v-col cols="12" sm="4" class="font-weight-bold">Информация о расширяющем УП:</v-col>
          <v-col  v-if="curriculum.extend" cols="12" sm="8" class="text-justify">{{curriculum.extend.year}} {{curriculum.extend.form}}</v-col>
        </v-row>
        <v-divider v-if="curriculum && typeof (curriculum.extend) === 'object'" class="my-2"></v-divider>
        <v-row no-gutters  v-if="curriculum && typeof (curriculum.previous) === 'object'">
          <v-col cols="12" sm="4" class="font-weight-bold">Информация о предыдущем УП:</v-col>
          <v-col v-if="curriculum.previous" cols="12" sm="8" class="text-justify">{{curriculum.previous.year}} {{curriculum.previous.form}}</v-col>
        </v-row>
        <slot name="append_body"></slot>
      </v-card-text>
    </template>
    </v-card>
</template>

<script>

import {loadFile} from "@/helper/fileLoader";

export default {
  name: "CurriculumTemplate",
  components: {},
  computed: {},
  props: {
    show_extra_actions: {
      type: Boolean,
      default: true,
    },
    curriculum: {
      required: true,
      type: Object,
    },
    personal: {
      type: String,
      default: "Общий"
    },
    is_dean: {
      type: Boolean,
      default: false,
    },
    is_individual: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // headers: [
      //   {text: 'Предмет', value: 'subject', width: '30%', filterable: true,},
      //   {text: 'Кафедра', value: 'cathedra', width: '30%', filterable: true,},
      //   {text: 'Курс', value: 'course', width: '10%', filterable: false,},
      //   {text: 'Семестр', value: 'semester', width: '10%', filterable: false, sortable: false,},
      //   {text: 'Форма контроля', value: 'control_type', width: '10%', filterable: false, sortable: false,},
      //   {text: 'Рейтинг', value: 'rating', width: '10%', filterable: false,}
      // ],
      // search: '',
    }
  },
  methods: {
    loadFileWork(url){
      loadFile(url)
    },
  }
}
</script>

<style scoped>

</style>
