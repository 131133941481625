<template>
  <v-container>
    <v-card>
      <v-toolbar elevation="0"
                 color="style-color-main-gray"
      >
        <v-text-field v-model="search.value"
                      dense
                      class="pa-3"
                      placeholder="Поиск по наименованию"
                      @change="searchData"
                      messages="Для поиска нажмите Enter"
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-select
          class="pt-6"
          label="Распределены по кафедрам"
          :items="[{text:'Отображать все',value:null},
          {text:'Не распределены',value:true}
          ,{text:'Распределены',value:false}
          ]"
          v-model="pagination.filters.without_cathedra"
        ></v-select>
        <v-toolbar-items>
          <v-btn text @click="openCreateForm">
            Добавить <v-icon>add</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-data-table
        :headers="semester_list.headers"
        :items="semester_list.items"
        :loading="pagination.loading"
        :items-per-page.sync="pagination.size"
        :page.sync="pagination.page"
        :server-items-length="pagination.count"
        no-data-text="Семестры не загружены"
        no-results-text="Семестры не были найдены"
        :footer-props="{'items-per-page-options':[10, 25, 50, 100], 'items-per-page-text': 'Число элементов'}"
      >
        <template v-slot:item.subject="props">
          <td class="text-left">
            <router-link :to="getDetailLink(props.item)">
              {{ props.item.subject }}
            </router-link>
          </td>
        </template>
        <template v-slot:item.cathedrals="props">
          <td class="text-left">
            <div v-for="(str,index) in props.item.cathedrals" class="align-start">
              {{str.name}}<span v-if="index!==props.item.cathedrals.length-1">;</span> <br>
            </div>
          </td>
        </template>
        <template v-slot:item.actions="props">
          <v-btn icon @click="openUpdateForm(props.item.id)">
            <v-icon>edit</v-icon>
          </v-btn>
          <v-btn icon @click="openDeleteApplier(props.item)">
            <v-icon>remove</v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <Form
        :curriculum_id="curriculum_id"
        :semester_id="dialogs.form.id"
        :opened="dialogs.form.opened"
        v-if="dialogs.form.opened"
        @close="closeCreateForm"
      ></Form>
      <ApplyDialog
        v-if="dialogs.delete.applier"
        :opened="dialogs.delete.applier"
        @close="deleteSemester(dialogs.delete.selected, $event)">
        <template>
          Вы уверены, что хотите удалить <strong>{{dialogs.delete.selected.semester}}</strong> семетр
          <strong>{{dialogs.delete.selected.course}}</strong> курса дисциплины
          <strong v-if='dialogs.delete.selected.subject !== undefined'>{{dialogs.delete.selected.subject}}</strong>
          <v-alert outlined type="warning">Будет удален выбранный семестр со всеми оценками у всех обучающихся учебных групп,
            которые обучаются по этому учебному плану</v-alert>
        </template>
      </ApplyDialog>
      <MsgDialog
        v-if="dialogs.delete.result.opened"
        :opened="dialogs.delete.result.opened"
        :title="dialogs.delete.result.text"
        :success="dialogs.delete.result.success"
        :warning="dialogs.delete.result.warning"
        @close="dialogs.delete.result.opened = false"
      >
        <span v-if="dialogs.delete.selected !== undefined">
          {{ dialogs.delete.selected.semester.slice(0,1).toUpperCase() }}{{ dialogs.delete.selected.semester.slice(1) }}
          семестр {{ dialogs.delete.selected.course }} курса
        </span> <span v-if="dialogs.delete.selected && dialogs.delete.selected.subject !== undefined">дисциплины {{ dialogs.delete.selected.subject }}</span>
        был удален из списка семестров дисциплины.
      </MsgDialog>
    </v-card>
  </v-container>
</template>

<script>
  // import DebugJsonComponent from "../../../core/components/DebugJsonComponent";
  import urls from "../../urls";
  import {loadData, setUrlParameters, makeDeleteRequest} from "../../../../helper";
  import Form from "./Form";
  import ApplyDialog from "../../../core/components/ApplyDialog";
  import MsgDialog from "../../../core/components/MsgDialog";
  import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
  import UserDataMixin from "@/mixins/UserDataMixin";
  import editor_name from "@/modules/curriculumEditor/routers/names";

  export default {
    name: "SemestersList",
    mixins: [
      PaginatedDataMapperMixin,
      UserDataMixin
    ],
    components: {
      // DebugJsonComponent,
      ApplyDialog,
      MsgDialog,
      Form,
    },
    props: {},
    data(){
      return {
        pagination: {
          filters: {
            without_cathedra : null,
          },
        },
        search: {
          parameter: 'subject_name',
        },
        title: "Список семестров учебного плана",
        curriculum_id: parseInt(this.$route.params.curriculum_id),
        dialogs: {
          delete: {
            selected: null,
            applier: false,
            result: {
              opened: false,
              text: '',
              success: true,
              warning: false,
            },
          },
          form: {
            id: undefined,
            opened: false,
          },
        },
        semester_list: {
          items: [],
          headers: [
            // {text: "№", sortable: false, align: 'left', width: 40},
            {text: "Курс", value: "course", sortable: false, align: 'left'},
            {text: "Семестр", value: "semester", sortable: false, align: 'left'},
            {text: "Предмет", value: "subject", sortable: false, align: 'left'},
            {text: "Кафедры", value: "cathedrals", sortable: false, align: 'left'},
            {text: "Тип контроля", value: "control_type", sortable: false, align: 'left'},
            {text: "Действия", value: "actions", sortable: false, align: 'center', width: 160,},
          ],
        }
      }
    },
    methods: {
      getDetailLink(item){
        return {name: editor_name.CURRICULUM.SEMESTERS.DETAIL, params: {curriculum_id: this.curriculum_id, semester_id: item.id}}
      },
      loadData: function (url=undefined) {
        this.setLoading();
        if(url === undefined)
          url = urls.NAMES.EDITOR.SEMESTER.LIST(this.curriculum_id);
        loadData(
          url,
          (data) => {
            this.mapPagination(data);
            this.semester_list.items = data.results;
            this.setCurrentLoadUrl(url);
            this.setNotLoading();
          }
        )
      },
      openCreateForm(){
        this.dialogs.form.id = undefined;
        this.dialogs.form.opened = true;
      },
      openUpdateForm(selected){
        this.dialogs.form.id= selected;
        this.dialogs.form.opened = true;
      },
      closeCreateForm(){
        this.dialogs.form.id = undefined;
        this.dialogs.form.opened = false;
        this.reloadData();
        // this.loadData(this.getCurrentLoadUrl());
      },
      openDeleteApplier(selected){
        this.dialogs.delete.selected = selected;
        this.dialogs.delete.applier = true;
      },
      deleteSemester(item, apply) {
        this.dialogs.delete.applier = false;
        if (apply) {
          let subject_id = this.subject
          if(item.hasOwnProperty('subject_id'))
            subject_id = item.subject_id
          let url = urls.NAMES.EDITOR.SUBJECT.SEMESTER.DESTROY(subject_id, item.id);
          this.dialogs.delete.result.warning = true;
          this.dialogs.delete.result.success = false;
          this.dialogs.delete.result.opened = true;
          url = setUrlParameters(url, {semester_id: this.semester_id});
          makeDeleteRequest(url)
            .then(resp => {
              if (!resp.ok) {
                resp.json().then((json) => {
                  throw json;
                }).catch((error) => {
                  this.dialogs.result.text = error;
                  this.dialogs.result.warning = true;
                  this.dialogs.result.success = false;
                  this.dialogs.result.opened = true;
                  this.reloadData();
                  // this.updatedSignal();
                });
              }else{
                // this.update();
                this.dialogs.result.text = 'Успешно удален';
                this.dialogs.result.warning = false;
                this.dialogs.result.success = true;
                this.dialogs.result.opened = true;
                this.reloadData();
                // this.updatedSignal();
              }
            })

        }
      }
    },
    created() {
      this.$store.commit('mainpage/changeTitle', this.title);
      this.loadData();
    }
  }
</script>

<style scoped>

</style>
