import Vue from 'vue'
import Vuex from 'vuex'
import leftmenu from './modules/leftmenu'
import maintoolbar from './modules/maintoolbar'
import mainpage from './modules/mainpage'
import user from './modules/user'
import urls from './modules/urls'
import feedback from "@/store/modules/feedback";
import stringvalue from "@/store/modules/stringvalue";
import admin from "@/modules/administrative/store"

Vue.use(Vuex);

const anonimusUsername = 'AnonimusUser';

export default new Vuex.Store({
  modules: {
    leftmenu,
    maintoolbar,
    mainpage,
    user,
    urls,
    feedback,
    stringvalue,
    admin
  },
  state: {},
  getters: {},
  mutations: {},
})
