<template>
  <v-card-text v-if="loaded">
    <v-row class="text-left text--black">
      <v-col cols="6" xs="6" md="3">
        <v-card-text class="py-0 font-weight-bold">Направление подготовки:</v-card-text>
      </v-col>
      <v-col cols="6" xs="6" md="9">
        <v-card-text class="py-0"> {{curriculum.direction.name}}  {{curriculum.direction.code}}</v-card-text>
      </v-col>
      <v-col cols="6" xs="6" md="3">
        <v-card-text class="py-0 font-weight-bold">Профиль подготовки:</v-card-text>
      </v-col>
      <v-col cols="6" xs="6" md="9">
        <v-card-text class="py-0"> {{curriculum.profile.name}}  </v-card-text>
      </v-col>
      <v-col cols="6" xs="6" md="3">
        <v-card-text class="py-0 font-weight-bold">Факультет:</v-card-text>
      </v-col>
      <v-col cols="6" xs="6" md="9">
        <v-card-text class="py-0"> {{curriculum.faculty}}  </v-card-text>
      </v-col>
      <v-col cols="6" xs="6" md="3" >
        <v-card-text class="py-0 font-weight-bold">Заменяет:</v-card-text>
      </v-col>
      <v-col cols="6" xs="6" md="9">
        <v-card-text class="py-0" v-if="curriculum.previous"> {{curriculum.previous.year}} {{curriculum.previous.form}}  </v-card-text>
        <v-card-text  class="py-0" v-else>Не указано</v-card-text>
      </v-col>
      <v-col cols="6" xs="6" md="3" >
        <v-card-text class="py-0 font-weight-bold">Расширяет:</v-card-text>
      </v-col>
      <v-col cols="6" xs="6" md="9" >
        <v-card-text class="py-0" v-if="curriculum.extend"> {{curriculum.faculty}}  </v-card-text>
        <v-card-text  class="py-0" v-else>Не указано</v-card-text>
      </v-col>
      <v-col cols="6" xs="6" md="3">
        <v-card-text class="py-0 font-weight-bold">Уровень:</v-card-text>
      </v-col>
      <v-col cols="6" xs="6" md="3">
        <v-card-text class="py-0"> {{curriculum.level}}</v-card-text>
      </v-col>
      <v-col cols="6" xs="6" md="3">
        <v-card-text class="py-0 font-weight-bold">Форма:</v-card-text>
      </v-col>
      <v-col cols="6" xs="6" md="3">
        <v-card-text class="py-0"> {{curriculum.form}}</v-card-text>
      </v-col>
      <v-col cols="6" xs="6" md="3">
        <v-card-text class="py-0 font-weight-bold">Год начала подготовки:</v-card-text>
      </v-col>
      <v-col cols="6" xs="6" md="3">
        <v-card-text class="py-0"> {{curriculum.year}}</v-card-text>
      </v-col>
      <v-col cols="6" xs="6" md="3">
        <v-card-text class="py-0 font-weight-bold">Стандарт:</v-card-text>
      </v-col>
      <v-col cols="6" xs="6" md="3">
        <v-card-text class="py-0">
          {{curriculum.standard.document.file.file_name}} <v-btn @click="loadFileWork(curriculum.standard.document.file)" icon><v-icon >get_app</v-icon></v-btn>
        </v-card-text>
      </v-col>
      <v-col cols="6" xs="6" md="3">
        <v-card-text class="py-0 font-weight-bold">Общее количество часов:</v-card-text>
      </v-col>
      <v-col cols="6" xs="6" md="3">
        <v-card-text class="py-0"> {{curriculum.hours}}</v-card-text>
      </v-col>
      <v-col cols="6" xs="6" md="3">
        <v-card-text class="py-0 font-weight-bold">Общее количество часов самостоятельных занятий:</v-card-text>
      </v-col>
      <v-col cols="6" xs="6" md="3">
        <v-card-text class="py-0"> {{curriculum.independent_hours}}</v-card-text>
      </v-col>
      <v-col cols="6" xs="6" md="3">
        <v-card-text class="py-0 font-weight-bold">Общее количество часов экзаменационных занятий:</v-card-text>
      </v-col>
      <v-col cols="6" xs="6" md="3">
        <v-card-text class="py-0"> {{curriculum.exam_hours}}</v-card-text>
      </v-col>
      <v-col cols="6" xs="6" md="3">
        <v-card-text class="py-0 font-weight-bold">Аудиторные часы:</v-card-text>
      </v-col>
      <v-col cols="6" xs="6" md="3">
        <v-card-text class="py-0"> {{curriculum.classroom_hours}}</v-card-text>
      </v-col>
      <v-col cols="6" xs="6" md="3">
        <v-card-text class="py-0 font-weight-bold">Общее количество зетов:</v-card-text>
      </v-col>
      <v-col cols="6" xs="6" md="3">
        <v-card-text class="py-0"> {{curriculum.zets}}</v-card-text>
      </v-col>
      <v-col cols="6" xs="6" md="3">
        <v-card-text class="py-0 font-weight-bold">Общее количество дисциплин:</v-card-text>
      </v-col>
      <v-col cols="6" xs="6" md="3">
        <v-card-text class="py-0"> {{curriculum.subjects_count}}</v-card-text>
      </v-col>

      <v-col cols="6" xs="6" md="3">
        <v-card-text class="py-0 font-weight-bold">Статус черновика:</v-card-text>
      </v-col>
      <v-col cols="6" xs="6" md="3">
        <v-card-text class="py-0"><v-icon v-if="curriculum.is_draft">done</v-icon> <v-icon v-else>clear</v-icon></v-card-text>
      </v-col>
      <v-col cols="6" xs="6" md="3">
        <v-card-text  class="py-0 font-weight-bold">Доступен к зачислению:</v-card-text>
      </v-col>
      <v-col cols="6" xs="6" md="3">
        <v-card-text class="py-0"><v-icon v-if="curriculum.is_enrollment">done</v-icon> <v-icon v-else>clear</v-icon></v-card-text>
      </v-col>
      <v-col cols="6" xs="6" md="3">
        <v-card-text  class="py-0 font-weight-bold">Перенесен со старого EIOS:</v-card-text>
      </v-col>
      <v-col cols="6" xs="6" md="3">
        <v-card-text class="py-0"><v-icon v-if="curriculum.migrated">done</v-icon> <v-icon v-else>clear</v-icon></v-card-text>
      </v-col>
      <v-col cols="6" xs="6" md="3">
        <v-card-text  class="py-0 font-weight-bold">Учебный план для иностранных обучающихся:</v-card-text>
      </v-col>
      <v-col cols="6" xs="6" md="3">
        <v-card-text class="py-0"><v-icon v-if="curriculum.foreign  ">done</v-icon> <v-icon v-else>clear</v-icon></v-card-text>
      </v-col>
    </v-row>
  </v-card-text>
  <v-skeleton-loader v-else type="card" width="100%" height="300px"></v-skeleton-loader>
</template>

<script>
import {loadFile} from "@/helper/fileLoader";
import moment from "moment";
import urls from "@/modules/curriculumEditor/urls";

export default {
  name: "Detail",
  props: {
    loaded: {
      required: true,
      default: false,
      type: Boolean,
    },
    curriculum: {
      required: true,
      type: Object
    }
  },
  methods: {
    loadFileWork(url){
      loadFile(url)
    },
  },
}
</script>

<style scoped>

</style>
