<template>
  <scroll-center-dialog
    v-model="opened"
    @close="$emit('close', false)"
    @apply="save"
    width="600"
    :title="is_create ? 'Добавление дисциплины учебного плана' : 'Изменения дисциплины учебного плана'">
    <v-autocomplete
      label="Предмет"
      :items="choices.subjects"
      v-model="subject.subject"
      :error="hasError('subject')"
      :error-messages="getError('subject')"
      @input="deleteError('subject')"
    >
    </v-autocomplete>
    <v-autocomplete
      label="Компетенции"
      multiple
      chips
      :items="choices.competences"
      v-model="subject.competences"
      :error="hasError('competences')"
      :error-messages="getError('competences')"
      @input="deleteError('competences')"
    >
    </v-autocomplete>
    <v-alert outlined type="info" class="py-1 mx-0">
      Загружать можно только файлы в формате pdf размером не более 15 Мб
    </v-alert>
    <div v-if="subject.work_program !== null">
      <v-file-input
        v-if="typeof subject.work_program.name === 'string'"
        v-model="subject.work_program"
        accept="application/pdf"
        label="Рабочая программа дисциплины"
        :error="hasError('work_program')"
        :error-messages="getError('work_program')"
      ></v-file-input>
      <v-text-field
        v-else-if="typeof subject.work_program.file_name === 'string'"
        v-model="subject.work_program.file_name"
        label="Рабочая программа дисциплины"
        readonly
        clearable
        append-icon="get_app"
        prepend-icon="attach_file"
        @click:append="loadFileWork(subject.work_program)"
        @click:clear="subject.work_program = null"
      ></v-text-field>
    </div>
    <v-file-input
      v-else
      v-model="subject.work_program"
      accept="application/pdf"
      label="Рабочая программа дисциплины"
      :error="hasError('work_program')"
      :error-messages="getError('work_program')"
    ></v-file-input>
    <div v-if="subject.methodological_materials !== null ">
      <v-file-input
        v-if="typeof subject.methodological_materials.name === 'string'"
        v-model="subject.methodological_materials"
        accept="application/pdf"
        label="Методические материалы"
        :error="hasError('methodological_materials')"
        :error-messages="getError('methodological_materials')"
      ></v-file-input>
      <v-text-field
        v-else-if="typeof subject.methodological_materials.file_name === 'string'"
        v-model="subject.methodological_materials.file_name"
        label="Методические материалы"
        readonly
        clearable
        append-icon="get_app"
        prepend-icon="attach_file"
        @click:append="loadFileWork(subject.methodological_materials)"
        @click:clear="subject.methodological_materials = null"
      ></v-text-field>
    </div>
    <v-file-input
      v-else
      v-model="subject.methodological_materials"
      accept="application/pdf"
      label="Методические материалы"
      :error="hasError('methodological_materials')"
      :error-messages="getError('methodological_materials')"
    ></v-file-input>
    <div v-if="subject.appraisal_tools !== null">
      <v-file-input
        v-if="typeof subject.appraisal_tools.name === 'string'"
        v-model="subject.appraisal_tools"
        accept="application/pdf"
        label="Оценочные средства"
        :error="hasError('appraisal_tools')"
        :error-messages="getError('appraisal_tools')"
      ></v-file-input>
      <v-text-field
        v-else-if="typeof subject.appraisal_tools.file_name === 'string'"
        v-model="subject.appraisal_tools.file_name"
        label="Оценочные средства"
        readonly
        clearable
        append-icon="get_app"
        prepend-icon="attach_file"
        @click:append="loadFileWork(subject.appraisal_tools)"
        @click:clear="subject.appraisal_tools = null"
      ></v-text-field>
    </div>
    <v-file-input
      v-else
      v-model="subject.appraisal_tools"
      accept="application/pdf"
      label="Оценочные средства"
      :error="hasError('appraisal_tools')"
      :error-messages="getError('appraisal_tools')"
    ></v-file-input>
  </scroll-center-dialog>
</template>

<script>
import DebugJsonComponent from "../../../core/components/DebugJsonComponent";
import {loadData, sendPostRequest, sendPutRequest} from "../../../../helper";
import urls from "../../urls";
import choices from "../../../core/urls/choices";
import {makePostRequestFile} from "@/helper";
import {loadFile} from "@/helper/fileLoader";
import FormErrorsMixin from "@/mixins/FormErrorsMixin";
import ScrollCenterDialog from "@/modules/core/components/ScrollCenterDialog";
export default {
  name: "SubjectsForm",
  components: {
    ScrollCenterDialog,
    DebugJsonComponent
  },
  mixins: [
    FormErrorsMixin,
  ],
  props: {
    subject_id: {
      type: Number,
      default: undefined,
    },
    curriculum_id: Number,
    opened: Boolean,
    value: Object
  },
  data() {
    return {
      is_create: this.subject_id === undefined,
      choices: {
        subjects: [],
        competences: [],
      },
      oldSubjectID: null,
      subject: {
        work_program: null,
        appraisal_tools: null,
        methodological_materials: null,
        subject: 0,
        competences: [],
      }
    }
  },
  methods: {
    loadFileWork(url){
      loadFile(url)
    },
    loadData() {
      let catcher = (error) => {
        console.log(error);
      };
      let saver = (data) => {
        this.oldSubjectID = data.subject.id
        this.subject.subject = data.subject.id
        this.subject.work_program = data.work_program
        this.subject.appraisal_tools = data.appraisal_tools
        this.subject.methodological_materials = data.methodological_materials
        for (let st in data.competences) {
          this.subject.competences.push(Number(data.competences[st].id))
        }
      };
      let url = urls.NAMES.EDITOR.SUBJECT.RETRIEVE(this.curriculum_id, this.subject_id);
      loadData(url, saver, catcher);
    },
    loadChoices(url, choices) {
      loadData(
        url,
        (data) => {
          this.choices[choices] = data;
        }
      )
    },
    loadSubjects() {
      this.loadChoices(choices.SELECTORS.SUBJECTS({'curriculum': this.curriculum_id}), 'subjects');
    },
    loadCompetences() {
      this.loadChoices(choices.SELECTORS.COMPETENCES(), 'competences')
    },
    loadCathedras() {
      this.loadChoices(choices.SELECTORS.CATHEDRAS(), 'cathedras');
    },
    save() {
      if (this.is_create)
        this.create();
      else
        this.update();
    },
    update() {
      let url = urls.NAMES.EDITOR.SUBJECT.UPDATE(this.curriculum_id, this.subject_id);
      let formData = new FormData();
      if(this.oldSubjectID !==  this.subject.subject)
        formData.append('subject', this.subject.subject)

      if (typeof this.subject.work_program === 'object' && this.subject.work_program !== null) {
        if(!this.subject.work_program.file_name)
          formData.append('work_program', this.subject.work_program)
      }else if(this.subject.work_program === null)
        formData.append('work_program', '')
      if (typeof this.subject.appraisal_tools === 'object' && this.subject.appraisal_tools !== null) {
        if(!this.subject.appraisal_tools.file_name)
            formData.append('appraisal_tools', this.subject.appraisal_tools)
      }else if(this.subject.appraisal_tools === null)
        formData.append('appraisal_tools', '')
      if (typeof this.subject.methodological_materials === 'object' && this.subject.methodological_materials !== null) {
        if(!this.subject.methodological_materials.file_name)
          formData.append('methodological_materials', this.subject.methodological_materials)
      }else if(this.subject.methodological_materials === null)
        formData.append('methodological_materials', '')

      for (let st in this.subject.competences) {
        formData.append('competences', this.subject.competences[st])
      }
      let status = true;
      makePostRequestFile(url, formData, "PATCH")
        .then(resp => {
          if (!resp.ok) {
            status = false;
          }
          return resp.json();
        })
        .then(json => {
          if (status) {
            this.close();
          } else {
              if(json.hasOwnProperty('detail')){
                this.errors.non.push(json['detail'])
              }
              this.mapErrors(json)
            }
        })
    },
    create() {
      let url = urls.NAMES.EDITOR.SUBJECT.CREATE(this.curriculum_id);
      let formData = new FormData();
      formData.append('subject', this.subject.subject)
      if (typeof this.subject.work_program === 'object' && this.subject.work_program !== null) {
        formData.append('work_program', this.subject.work_program)
      }
      if (typeof this.subject.appraisal_tools === 'object' && this.subject.appraisal_tools !== null) {
        formData.append('appraisal_tools', this.subject.appraisal_tools)
      }
      if (typeof this.subject.methodological_materials === 'object' && this.subject.methodological_materials !== null) {
        formData.append('methodological_materials', this.subject.methodological_materials)
      }
      for (let st in this.subject.competences) {
        formData.append('competences', this.subject.competences[st])
      }
      let status = true;
      makePostRequestFile(url, formData, "POST")
        .then(resp => {
          if (!resp.ok) {
            status = false;
          }
          return resp.json();
        })
        .then(json => {
          if (status) {
            this.close();
          } else {
            if(json.hasOwnProperty('detail')){
              this.errors.non.push(json['detail'])
            }
            this.mapErrors(json)
          }
        })
    },
    close() {
      this.$emit('close');
    },
  },
  created() {
    this.loadSubjects();
    this.loadCompetences();
    if (!this.is_create)
      this.loadData()
  }
}
</script>

<style scoped>
</style>
