import helper from "./helper"

let getEduUrlWithParameters = helper.getEduUrlWithParameters
let makeEduMediaUrl = helper.makeEduMediaUrl


export default {
  getEduUrlWithParameters,
  makeEduMediaUrl,
  getByRecievedUrlData: (url, gets = null) => getEduUrlWithParameters(
    url.basename,
    url.action,
    url.params,
    gets
  ),
  STUDENT:{
    DETAIL: (id, gets=null) => getEduUrlWithParameters(
      'student|students|education-detail',
      'retrieve',
      {
        id: id,
      },
      gets
    ),
    FULL_JOURNAL: (id, gets=null) => getEduUrlWithParameters(
      'student|students|education-full-journal',
      'full_journal',
      {
        id: id,
      },
      gets
    ),
    CURRICULUM:{
      DETAIL: (education_id, gets=null) => getEduUrlWithParameters(
        'student|students|individual|curriculum-detail',
        'retrieve',
        {
          education_id: education_id,
        },
        gets
      ),
      SEMESTER_DETAIL: (curriculum_id, id, gets=null) => getEduUrlWithParameters(
        'student|students|semester-detail',
        'retrieve',
        {
          curriculum_id: curriculum_id,
          id: id,
        },
        gets
      ),
    },
    GROUP:{
      DETAIL: (education_id, gets=null) => getEduUrlWithParameters(
        'student|group|group-detail',
        'retrieve',
        {
          education_id: education_id,
        },
        gets
      ),
      ALL_STUDENT: (education_id, id, gets=null) => getEduUrlWithParameters(
        'student|group|group-get-all-student',
        'get_all_student',
        {
          education_id: education_id,
          id: id,
        },
        gets
      ),
      CURRENT_SUBJECT: (education_id, id, gets=null) => getEduUrlWithParameters(
        'student|group|group-current-subject',
        'current_subject',
        {
          education_id: education_id,
          id: id,
        },
        gets
      ),
    },
    EDU_DOC:{
      LIST: (gets=null) => getEduUrlWithParameters(
        'student|students|education|documents-list',
        'list',
        {},
        gets
      ),
    },
    ADMISSION:{
      LIST: (education_id, gets=null) => getEduUrlWithParameters(
        'student|individual|tolerance-list',
        'list',
        {
          education_id: education_id,
        },
        gets
      ),
      DETAIL: (education_id, id, gets=null) => getEduUrlWithParameters(
        'student|individual|tolerance-detail',
        'retrieve',
        {
          education_id: education_id,
          id: id,
        },
        gets
      ),
    },
    ADMISSION_REQUEST:{
      LIST: (education_id, gets=null) => getEduUrlWithParameters(
        'student|individual|tolerance|request-list',
        'list',
        {
          education_id: education_id,
        },
        gets
      ),
      DETAIL: (education_id, id, gets=null) => getEduUrlWithParameters(
        'student|individual|tolerance|request-detail',
        'retrieve',
        {
          education_id: education_id,
          id: id,
        },
        gets
      ),
      CREATE: (education_id, gets=null) => getEduUrlWithParameters(
        'student|individual|tolerance|request-list',
        'create',
        {
          education_id: education_id,
        },
        gets
      ),
      DELETE: (education_id, id, gets=null) => getEduUrlWithParameters(
        'student|individual|tolerance|request-detail',
        'destroy',
        {
          education_id: education_id,
          id: id,
        },
        gets
      ),
    },
    CERTIFICATE:{
      LIST: (gets=null) => getEduUrlWithParameters(
        'student|students|certificate-list',
        'list',
        {
        },
        gets,
      ),
      SAVE: (id, gets=null) => getEduUrlWithParameters(
        'student|students|certificate-detail',
        'retrieve',
        {
          id: id
        },
        gets,
      ),
    },
    INDIVIDUAL_WORK:{
      WORK_LIST: (education_id, gets=null) => getEduUrlWithParameters(
        'student|individual|work-list',
        'list',
        {
          education_id: education_id,
        },
        gets
      ),
      WORK_DETAIL: (id, education_id, gets=null) => getEduUrlWithParameters(
        'student|individual|work-detail',
        'retrieve',
        {
          id: id,
          education_id: education_id,
        },
        gets
      ),
      WORK_DELETE: (id, education_id, gets=null) => getEduUrlWithParameters(
        'student|individual|work-detail',
        'destroy',
        {
          id: id,
          education_id: education_id,
        },
        gets
      ),
      CURRICULUM_DETAIL: (education_id, gets=null) => getEduUrlWithParameters(
        'student|students|individual|curriculum-detail',
        'retrieve',
        {
          education_id: education_id,
        },
        gets
      ),
      ADD_WORK: (curriculum_id, id, gets=null) => getEduUrlWithParameters(
        'student|students|semester-add-work',
        'add_work',
        {
          curriculum_id: curriculum_id,
          id: id,
        },
        gets
      ),
      UPDATE_WORK: (education_id, id, gets=null) => getEduUrlWithParameters(
        'student|individual|work-detail',
        'partial_update',
        {
          education_id: education_id,
          id: id,
        },
        gets
      ),
    },
    PORTFOLIO:{
      LIST: (gets=null) => getEduUrlWithParameters(
        'student|relations|achievement|documents-list',
        'list',
        {
        },
        gets
      ),
      CREATE: (gets=null) => getEduUrlWithParameters(
        'student|relations|achievement|documents-list',
        'create',
        {
        },
        gets
      ),
      DELETE: (id, gets=null) => getEduUrlWithParameters(
        'student|relations|achievement|documents-detail',
        'destroy',
        {
          id: id,
        },
        gets
      ),
      UPDATE: (id, gets=null) => getEduUrlWithParameters(
        'student|relations|achievement|documents-detail',
        'partial_update',
        {
          id: id,
        },
        gets
      ),
    },
  }

}
