import ListDepartaments from "../pages/list/ListDepartaments";
import DepartamentDetail from "../pages/departament/DepartamentDetail";
import ListLedSubjects from "../pages/departament/list/ListLedSubjects";
import ListLedGroups from "../pages/departament/list/ListLedGroups";
import ListLedStudents from "../pages/departament/list/ListLedStudents";
import ListGroups from "../pages/departament/list/ListGroups";
import ListStudents from "../pages/departament/list/ListStudents";
import ListTeacher from "../pages/departament/list/ListTeacher";
import DetailedLedGroup from "../pages/departament/list/groups/DetailedGroup";
import DetailedGroup from "../pages/departament/list/groups/DetailedGroup";
import TeacherDetail from "../pages/departament/list/teacher/TeacherDetail";
import GroupJournalBase from "@/modules/dean/pages/journal/Base";
import GroupJournalSemesterBase from "@/modules/dean/pages/journal/SemesterBase";

const accounts = String(process.env.VUE_APP_ACCOUNTS_PREFIX).replace('/', '');
const education = String(process.env.VUE_APP_EDUCATION_PREFIX).replace('/', '');
const organization = String(process.env.VUE_APP_ORGANIZATION_PREFIX).replace('/', '');
import names from "@/modules/dean/routers/names";


export default ([
  { ///////////////////////////////////////////////////// I
    path: ':idFaculty/departments',
    name: names.DEPARTAMENTS.LIST,
    component: ListDepartaments,
    meta: {
      breadcrumb: 'Список кафедр',
      requiredAuth: true,
      instances: [
        accounts,
        education,
      ],
    },
    children: [
      {
        path: ':uidDepartament',
        name: names.DEPARTAMENTS.DETAIL,
        component: DepartamentDetail,
        meta: {
          breadcrumb: 'Кафедра',
          requiredAuth: true,
          instances: [
            accounts,
            education,
          ],
        },
        children: [
          // LEFT MENU!!!
          //1 Список ведомых дисциплин
          {
            path: 'led_subjects',
            name: names.DEPARTAMENTS.LED_SUBJECTS.LIST,
            component: ListLedSubjects,
            meta: {
              breadcrumb: 'Список ведомых дисциплин',
              requiredAuth: true,
              instances: [
                accounts,
                education,
              ],
            }
          },
          //2 Список ведомых групп
          {
            path: 'led_groups',
            name: names.DEPARTAMENTS.GROUP.LED.LIST,
            component: ListLedGroups,
            meta: {
              breadcrumb: 'Список ведомых групп кафедры',
              requiredAuth: true,
              instances: [
                accounts,
                education,
              ],
            },
            children: [
              {
                path: 'detailed_led_group/:idGroup',
                name: names.DEPARTAMENTS.GROUP.LED.DETAIL,
                component: DetailedLedGroup,
                meta: {
                  breadcrumb: 'Детальное представление ведомой группы',
                  requiredAuth: true,
                  instances: [
                    accounts,
                    education,
                    organization
                  ],
                },
                children: [
                  {
                    path: 'journal',
                    name: names.DEPARTAMENTS.GROUP.LED.JOURNAL,//'Administrative|Cathedra|Groups|Detail',
                    component: GroupJournalBase,
                    meta: {
                      requiredAuth: true,
                      instances: [
                        accounts,
                        education,
                        organization
                      ],
                      breadcrumb: 'Журнал группы',
                    },
                  },
                  {
                    path: 'journal/semester',
                    name: names.DEPARTAMENTS.GROUP.LED.JOURNALSEMESTER,//'Administrative|Cathedra|Groups|Detail',
                    component: GroupJournalSemesterBase,
                    meta: {
                      requiredAuth: true,
                      instances: [
                        accounts,
                        education,
                        organization
                      ],
                      breadcrumb: 'Полный журнал группы',
                    },
                  },
                ]
              }
            ]
          },
          //3 Список ведомых обучающихся
          {
            path: 'students/led',
            name: names.DEPARTAMENTS.STUDENTS.LED,
            component: ListLedStudents,
            meta: {
              breadcrumb: 'Список ведомых обучающихся',
              requiredAuth: true,
              instances: [
                accounts,
                education,
              ],
            }
          },
          //4 Список групп
          {
            path: 'groups',
            name: names.DEPARTAMENTS.GROUP.LIST,
            component: ListGroups,
            meta: {
              breadcrumb: 'Список групп',
              requiredAuth: true,
              instances: [
                accounts,
                education,
              ],
            },
            children: [
              {
                path: 'detailed/:idGroup',
                name: names.DEPARTAMENTS.GROUP.DETAIL,
                component: DetailedGroup,
                meta: {
                  breadcrumb: 'Детальное представление группы',
                  requiredAuth: true,
                  instances: [
                    accounts,
                    education,
                    organization
                  ],
                },
              }
            ]
          },
          //5 Список обучающихся
          {
            path: 'students',
            name: names.DEPARTAMENTS.STUDENTS.LIST,
            component: ListStudents,
            meta: {
              breadcrumb: 'Список обучающихся',
              requiredAuth: true,
              instances: [
                accounts,
                education,
              ],
            }
          },
          //6 Список преподавателей
          {
            path: 'teacher',
            name: names.DEPARTAMENTS.TEACHER.LIST,
            component: ListTeacher,
            meta: {
              breadcrumb: 'Список преподавателей',
              requiredAuth: true,
              instances: [
                accounts,
                education,
              ],
            },
            children: [
              {
                path: ':uidTeacher',
                name: names.DEPARTAMENTS.TEACHER.DETAIL,
                component: TeacherDetail,
                meta: {
                  breadcrumb: 'Преподаватель',
                  requiredAuth: true,
                  instances: [
                    accounts,
                    education,
                  ],
                }
              },
            ]
          },
        ],
      },
    ]
  }])
