<template class="">
  <v-container class="">
    <v-card v-if="admission !== undefined" class="pa-3" >
      <v-row class="px-0">
        <v-col cols="12" md="6" class="pa-0 ma-0">
          <v-toolbar flat color="style-color-main-gray" elevation="0">
            <v-icon class="pr-4">
              person
            </v-icon>
            <div class="subtitle-1 text-left">
              Обучающийся: {{ admission.fio_student }}
            </div>
          </v-toolbar>
        </v-col>
        <div class="py-3 style-color-main-gray" style="width: 100%" v-if="$vuetify.breakpoint.smAndDown"></div>
        <v-col cols="12" md="6" class="pa-0 ma-0">
          <v-toolbar flat color="style-color-main-gray" elevation="0">
            <v-spacer v-if="!admission.teacher"></v-spacer>
              <template v-if="admission.teacher">
                <v-icon class="pr-4">
                  person
                </v-icon>
                <div class="subtitle-1 text-left">
                Обработан: {{ admission.fio_teacher }}<!-- Василий Васильевич Пупыркинстаж-->
                </div>

              </template>
              <template v-else>Допуск не закрыт</template>
            <v-spacer v-if="admission.teacher"></v-spacer>
              <v-btn  v-if="btnPrint" icon @click="PrintAdmission"><v-icon>print</v-icon></v-btn>
          </v-toolbar>
        </v-col>
      </v-row>
      <slot name="prepend_body"></slot>
      <v-row  class="py-3">
        <v-col cols="12" md="6" class="py-0 pr-md-0 my-0" >
          <v-card-text :style="{'border-right': $vuetify.breakpoint.mdAndUp ? '1px solid #AAAAAA': 'none'}" class="py-0">

            <v-row no-gutters class="text-start subtitle-1 py-1" v-if="admission.faculty">
              <v-col cols="12" md="4" class="font-weight-bold">Факультет:</v-col>
              <v-col cols="12" md="8">{{admission.faculty?.name}}</v-col>
            </v-row>
            <v-row no-gutters class="text-start subtitle-1 py-1" v-if="admission.cathedra">
              <v-col cols="12" md="4" class="font-weight-bold">Кафедра:</v-col>
              <v-col cols="12" md="8">{{admission.cathedra ?.name}}</v-col>
            </v-row>
            <v-row no-gutters class="text-start subtitle-1 py-1">
              <v-col cols="12" md="4" class="font-weight-bold">Предмет:</v-col>
              <v-col cols="12" md="8">{{ admission.semester.subject }}</v-col>
            </v-row>
            <v-row no-gutters class="text-start subtitle-1 py-1">
              <v-col cols="12" md="4" class="font-weight-bold">Тип допуска:</v-col>
              <v-col cols="12" md="8">{{ admission.type }}</v-col>
            </v-row>
            <v-row no-gutters class="text-start subtitle-1 py-1">
              <v-col cols="12" md="4" class="font-weight-bold">Тип контроля:</v-col>
              <v-col cols="12" md="8">{{ admission.semester.control_type }}</v-col>
            </v-row>
            <v-row no-gutters class="text-start subtitle-1 py-1">
              <v-col cols="12" md="4" class="font-weight-bold">Пройден:</v-col>
              <v-col cols="12" md="8">{{ admission.semester.semester }} семестр {{ admission.semester.course }}-го курса</v-col>
            </v-row>
            <v-row no-gutters class="text-start subtitle-1 py-1">
              <v-col cols="12" md="4" class="font-weight-bold">Баллов в семестре:</v-col>
              <v-col cols="12" md="8">{{ admission.in_semester }}</v-col>
            </v-row>

            <v-row no-gutters class="text-start subtitle-1 py-1">
              <v-col cols="12" md="4" class="font-weight-bold">Номер:</v-col>
              <v-col cols="12" md="8">{{admission.number}}</v-col>
            </v-row>
            <v-row no-gutters class="text-start subtitle-1 py-1" v-if="admission.session">
              <v-col cols="12" md="4" class="font-weight-bold">Сессия:</v-col>
              <v-col cols="12" md="8">{{ displayDate(admission.session.started) }} - {{ displayDate(admission.session.ended) }}</v-col>
            </v-row>
            <v-row no-gutters class="text-start subtitle-1 py-1">
              <v-col cols="12" md="4" class="font-weight-bold">Академическая разница:</v-col>
              <v-col cols="12" md="8">
                <td>
                  <v-icon v-if="admission.academic_difference">done</v-icon>
                  <v-icon v-else>clear</v-icon>
                </td>
                </v-col>
            </v-row>
            <v-row no-gutters class="text-start subtitle-1 py-1">
              <v-col cols="12" md="4" class="font-weight-bold">Комментарий работника деканата:</v-col>
              <v-col cols="12" md="8">
                <span v-if="admission.dean_comment">{{ admission.dean_comment }}</span>
                <span v-else>Отсутствует</span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-col>
        <v-col cols="12" md="6" class="py-0 pl-md-0 my-0">
          <v-card-text class="py-0">
            <v-row no-gutters class="text-start subtitle-1 py-1">
              <v-col cols="12" md="4" class="font-weight-bold">Группа:</v-col>
              <v-col v-if="admission.group" cols="12" md="8">
                <span v-if="admission.group.name">{{admission.group.name}}</span>
                <span v-else>{{admission.group.litter}}-{{admission.group.course}}{{admission.group.number}}</span>
                </v-col>
            </v-row>
            <v-row no-gutters class="text-start subtitle-1 py-1">
              <v-col cols="12" md="4" class="font-weight-bold">Допуск к экзамену:</v-col>
              <v-col cols="12" md="8">
                <td>
                  <v-icon v-if="admission.is_exam">done</v-icon>
                  <v-icon v-else>clear</v-icon>
                </td>
              </v-col>
            </v-row>
            <v-row no-gutters class="text-start subtitle-1 py-1">
              <v-col cols="12" md="4" class="font-weight-bold">Итоговые баллы:</v-col>
              <v-col cols="12" md="8">
                <template v-if="admission.total">{{ admission.total }}</template>
                <template v-else>Допуск не закрыт</template>
              </v-col>
            </v-row>
            <v-row no-gutters class="text-start subtitle-1 py-1">
              <v-col cols="12" md="4" class="font-weight-bold">Допуск закрыт:</v-col>
              <v-col cols="12" md="8" class=" d-flex">
                <template v-if="admission.closed && !admission.success">Архивный</template>
                <template v-else-if="admission.success == null">Допуск не закрыт</template>
                <template v-else-if="admission.success">
                  Успешно <v-icon color="success" v-if="admission.success">done</v-icon>
                </template>
                <template v-else>
                  Не успешно <v-icon color="red">clear</v-icon>
                </template>
              </v-col>
            </v-row>
            <v-row no-gutters class="text-start subtitle-1 py-1" v-if="issued_by">
              <v-col cols="12" md="4" class="font-weight-bold">Допуск выдал:</v-col>
              <v-col cols="12" md="8">{{admission.fio_issued_by }}</v-col>
            </v-row>
            <v-row no-gutters class="text-start subtitle-1 py-1">
              <v-col cols="12" md="4" class="font-weight-bold">Дата сдачи:</v-col>
              <v-col cols="12" md="8" >
                <span v-if="admission.success != null">{{ displayDate(admission.officially_closed) }}</span>
                <span v-else>
                  <span v-if="admission.closed"> {{displayDate(admission.closed)}} </span>
                  <span v-else>Допуск не закрыт</span>
                </span>
              </v-col>
            </v-row>
            <v-row no-gutters class="text-start subtitle-1 py-1" v-if="admission.email">
              <v-col cols="12" md="4" class="font-weight-bold">Почта обучающегося:</v-col>
              <v-col cols="12" md="8"><a :href="'mailto:'+admission.email">{{admission.email}}</a></v-col>
            </v-row>
            <v-row no-gutters class="text-start subtitle-1 py-1">
              <v-col cols="12" md="4" class="font-weight-bold">Комментарий преподавателя:</v-col>
              <v-col cols="12" md="8">
                <span v-if="admission.comment">{{ admission.comment }}</span>
                <span v-else>Отсутствует</span>
              </v-col>
            </v-row>
            <v-row no-gutters class="text-start subtitle-1 py-1">
              <v-col cols="12" md="4" class="font-weight-bold">Файл обоснования:</v-col>
              <v-col cols="12" md="8">
                <a  v-if="admission.certificate" :href="admission.certificate.url">
                  {{admission.certificate.name}}
                </a>
                <span v-else>Отсутствует</span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-col>
      </v-row>
      <slot name="append_body"></slot>
      <PrintAdmissions :admissions="[admission]" style="display: none"></PrintAdmissions>
    </v-card>
    <v-card v-else>
      <v-skeleton-loader
        type="card-avatar, article, actions"
      ></v-skeleton-loader>
    </v-card>
  </v-container>
</template>

<script>
import moment from 'moment'
import PrintAdmissions from "@/modules/templates/admission/PrintAdmission";
export default {
  name: "DetailAdmission",
  mixins: [],
  props: {
    btnPrint:{
      default: false,
      type: Boolean,
    },
    issued_by: {
      default: false,
      type: Boolean,
    },
    admission: {
      required: true,

    }
  },
components:{
  PrintAdmissions,
},
  data: function () {
    return {}
  },
  methods: {
    PrintAdmission(){
      const options = {
        name: '_blank',
        autoClose: false,
        specs: [
          'fullscreen=yes',
          'titlebar=yes',
          'scrollbars=yes'
        ],
        styles: [
          '*'
        ]
      }

      this.$htmlToPaper('printMe',options);
    },
    displayDate: function (date, minutes = false) {
      let format = 'DD.MM.YYYY'
      if (minutes)
        format += ' HH:mm'
      return moment(date).format(format)
    },

  },
  created() {
    // this.data = this.loader
  }
}
</script>

<style scoped>
.col-1-2 {
  max-width: 50%;
  float: left;
}
.row {
  margin-bottom: 15px;
}
</style>
