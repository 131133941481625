<template>
  <v-container v-if="is_self_route">
    <v-row>
      <v-col cols="12" md="12">
        <v-card>
          <v-toolbar
            elevation="0"
            color="style-color-main-gray"
          >
            <v-text-field
              dense
              placeholder="Поиск"
              v-model="search.value"
              @change="searchData"
              messages="Для поиска нажмите Enter или кнопку поиск"
            >
            </v-text-field>
            <v-btn icon><v-icon>search</v-icon></v-btn>
          </v-toolbar>
          <v-data-table
            :headers="headers"
            :items="teachers"
            :items-per-page.sync="pagination.size"
            no-data-text="Преподаватели не загружены"
            no-results-text="Преподаватели не были найдены"
            :page.sync="pagination.page"
            :loading="pagination.loading"
            :server-items-length="pagination.count"
            :footer-props="{'items-per-page-options':[10,25,50], 'footer-props.items-per-page-text': 'Число элементов'}"
          >
            <template v-slot:loading>
              <template v-for="i in 10">
                <v-skeleton-loader type="table-row" :key="`skeleton${i}`"></v-skeleton-loader>
              </template>
            </template>
            <template v-slot:item.fio="teacher">
              <v-skeleton-loader v-if="teacher.item.fio === ''" type="text"></v-skeleton-loader>
              <router-link v-else :to="makeLink(teacher.item.user)">
                {{teacher.item.fio}}
              </router-link>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
<!--    <DebugJsonComponent :json="pagination"></DebugJsonComponent>-->
<!--    <DebugJsonComponent :json="teachers"></DebugJsonComponent>-->
  </v-container>
  <router-view v-else></router-view>
</template>

<script>
  import {loadData} from "../../../../helper";
  import urls from '@/urls/cathedras';
  import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
  import DebugJsonComponent from "../../../core/components/DebugJsonComponent";
  // import {generateListUidToName} from "@/helper/uidToName";
  import TitledPageMixin from "../../../../mixins/TitledPageMixin";
  import names from "../../routers/names";
  import OneFieldBaseSearchMixin from "@/mixins/OneFieldBaseSearchMixin";

  export default {
    name: "Teachers",
    components: {
      DebugJsonComponent
    },
    mixins: [PaginatedDataMapperMixin, OneFieldBaseSearchMixin, TitledPageMixin],
    computed: {
      is_self_route: function () {return names.CathedraTeachers === this.$route.name},
    },
    data: function(){
      return {
        page_title: 'Список преподавателей',
        cathedra_uid: this.$route.params.cathedra_uid,
        teachers: [],
        headers: [
          {text:'ФИО', value: 'fio', sortable: false, width: '60%'},
          {text: 'Количество групп', value: 'group_count', sortable: false,},
          {text: 'Количество дисциплин', value: 'subject_count', sortable: false,},
          {text: 'Количество часов', value: 'hours', sortable: false,},
          // {text: 'Староста', value: 'headman', sortable: false,}
        ],
        search: {
          parameter: 'fio',
        },
      }
    },
    methods: {
      makeLink: function(teacher_uid) {
        // console.log(teacher_uid)
        return {
          name: names.CathedraTeachersDetail,
          params: {
            cathedra_uid: this.$route.params.cathedra_uid,
            teacher_uid: teacher_uid,
          }
        }
      },
      loadData: function (url = undefined) {
        this.pagination.loading = true;
        if (url === undefined)
          url = urls.CATHEDRA.TEACHERS.LIST(this.cathedra_uid);
        loadData(
          url,
          (data) => {
            this.mapResults(data, 'teachers');
            this.setCurrentLoadUrl(url);
            this.loadNames(
              this.teachers,
              'user',
              'fio',
              () => this.pagination.loading = false
            );
          }
        )
      },
    },
    created() {
      this.setPageTitleWithObject("Cathedra", {uid: this.$route.params.cathedra_uid}, ' - ')
      this.loadData();
    },
    beforeRouteUpdate(to, from, next){
      this.loadData();
      next();
    }

  }
</script>

<style scoped>

</style>
