// import {setUrlParameters, addGetParameters} from "../../helper";
import {
  ACCOUNT_HOST,
  ACCOUNTS_INSTANCE,
  ORGANIZATION_HOST,
  ORGANIZATION_INSTANCE,
  EDUCATION_HOST,
  EDUCATION_INSTANCE,
} from "@/helper/consts";
import {
  getUrl,
  getUrlAllowed,
  checkUrlAllowedWithParameters,
  makeMediaHostUrl,
  getUrlWithParameters
} from "@/helper/urls";
import callable from "@/helper/callable";


// class UrlBase extends callable.Callable {
//   name = '';
//   method = '';
//   host = '';
//   instance = '';
//   parameterizer = () => {};
//   constructor(host, instance, name, method, parameterizer=()=>{}) {
//     super();
//     this.host = host;
//     this.instance = instance;
//     this.name = name;
//     this.method = method;
//     this.parameterizer = parameterizer;
//     this._call = ()
//
//   }
//   allowed(){
//     return getUrlAllowed(this.instance, this.name, this.method)
//   }
//   allowedWithPars(...args){
//     let inputs = this.parameterizer(...args);
//     return checkUrlAllowedWithParameters(this.instance, this.name, this.method, inputs.parameters)
//   }
//   _call(...args){
//     let inputs = this.parameterizer(...args);
//     return getUrlWithParameters(
//       this.host, this.host, this.name, this.method, inputs.parameters, inputs.gets
//     );
//   }
// }
//
// class EducationUrl extends UrlBase{
//   constructor(name, method, parameterizer=()=>{}) {
//     super(EDUCATION_HOST, EDUCATION_INSTANCE, name, method, parameterizer);
//   }
// }
//
// class AccountsUrl extends UrlBase{
//   constructor(name, method, parameterizer=()=>{}) {
//     super(ACCOUNT_HOST, ACCOUNTS_INSTANCE, name, method, parameterizer);
//   }
// }
//
// class OrganizationUrl extends UrlBase{
//   constructor(name, method, parameterizer=()=>{}) {
//     super(ORGANIZATION_HOST, ORGANIZATION_INSTANCE, name, method, parameterizer);
//   }
// }

class UrlBaseFunctor extends Function {
  name = '';
  method = '';
  host = '';
  instance = '';
  parameters = '';
  gets = '';
  parametrizer = null;
  constructor(host, instance, name, method, parametrizer) {
    super();
    this.host = host;
    this.instance = instance;
    this.name = name;
    this.method = method;
    this.parametrizer = parametrizer;
    // this.parameters = parameters;
    // this.gets = gets;
    console.dir(this)
  }
  allowed(){
    return getUrlAllowed(this.instance, this.name, this.method)
  }
  allowedWithPars(parameters){
    return checkUrlAllowedWithParameters(this.instance, this.name, this.method, parameters)
  }
  getPattern(parameters, gets=null){
    return getUrlWithParameters(
      this.host, this.instance, this.name, this.method, parameters, gets
    )
  }
  call(thisArg, ...argArray) {
    let args = thisArg.caller(...argArray)
    return thisArg.getPattern(...args)
  }
}


class UrlBase extends String {
  name = '';
  method = '';
  host = '';
  instance = '';
  parameters = '';
  gets = '';
  constructor(host, instance, name, method, parameters, gets) {
    super(getUrlWithParameters(
      host, instance, name, method, parameters, gets
      )
    );
    this.host = host;
    this.instance = instance;
    this.name = name;
    this.method = method;
    this.parameters = parameters;
    this.gets = gets;
    console.dir(this)
  }
  allowed(){
    return getUrlAllowed(this.instance, this.name, this.method)
  }
  allowedWithPars(parameters){
    return checkUrlAllowedWithParameters(this.instance, this.name, this.method, parameters)
  }
}

class EducationUrl extends UrlBase{
  constructor(name, method, parameters, gets) {
    super(EDUCATION_HOST, EDUCATION_INSTANCE, name, method, parameters, gets);
  }
}

class EducationUrlFunctor extends UrlBaseFunctor{
  constructor(name, method, caller) {
    super(EDUCATION_HOST, EDUCATION_INSTANCE, name, method, caller);
  }
}

class AccountsUrl extends UrlBase{
  constructor(name, method, parameters, gets) {
    super(ACCOUNT_HOST, ACCOUNTS_INSTANCE, name, method, parameters, gets);
  }
}

class OrganizationUrl extends UrlBase{
  constructor(name, method, parameters, gets) {
    super(ORGANIZATION_HOST, ORGANIZATION_INSTANCE, name, method, parameters, gets);
  }
}

export default {
  UrlBase,
  AccountsUrl,
  OrganizationUrl,
  EducationUrl,
  EducationUrlFunctor,
  getEduUrlWithParameters: function(name, method, parameters, gets) {
    return getUrlWithParameters(
      EDUCATION_HOST, EDUCATION_INSTANCE, name, method, parameters, gets
    );
  },
  allowedEduUrl(name, method){
    return getUrlAllowed(EDUCATION_INSTANCE, name, method)
  },
  allowedEduUrlByParameters(name, method, parameters){
    return checkUrlAllowedWithParameters(EDUCATION_HOST, EDUCATION_INSTANCE, name, method, parameters)
  },
  checkEduUrlAllowed(urlname, action) {
    return getUrlAllowed(EDUCATION_INSTANCE, urlname, action)
  },

  checkEduUrlAllowedWithParameters(urlname, action, pars, finalizer=()=>{}) {
    return checkUrlAllowedWithParameters(EDUCATION_HOST, EDUCATION_INSTANCE, urlname, action, pars, finalizer)
  },

  makeEduMediaUrl: function(append){
    return makeMediaHostUrl(EDUCATION_HOST, append)
  },
  getAccountsUrlWithParameters: function(name, method, parameters, gets) {
    return getUrlWithParameters(
      ACCOUNT_HOST, ACCOUNTS_INSTANCE, name, method, parameters, gets
    );
  },
  getOrganizationUrlWithParameters: function(name, method, parameters, gets) {
    return getUrlWithParameters(
      ORGANIZATION_HOST, ORGANIZATION_INSTANCE, name, method, parameters, gets
    );
  },

  BaseUrls: {
    getUrl(urlname, action) {
      return getUrl(EDUCATION_HOST, EDUCATION_INSTANCE, urlname, action)
    },
    getUrlAllowed(urlname, action) {
      return getUrlAllowed(EDUCATION_INSTANCE, urlname, action)
    },
    makeMediaHostUrl(url){
      return makeMediaHostUrl(EDUCATION_HOST, url);
    },
  },

}
