<template>
  <LoadPage v-bind:loading-page="loadingPage" v-bind:load-page-error-msg="loadErrorMsg">
    <v-container v-if="$route.name === names.EDUCATIONAL.STUDENTS.WORK.LIST">
      <v-card flat>
        <v-data-table
          :headers="works.headers"
          :items="works.items"
          :loading="works.loading"
          no-data-text="Нет ни одной работы"
          :footer-props="{
          'items-per-page-text': 'Число элементов',
          'items-per-page-all-text': 'Все',
          'items-per-page-options': [10, 25, 50, 100]
        }"
        >
          <template v-slot:item="props">
            <tr class="text-left">
              <td >{{ props.index + 1 }}</td>
              <td >
                <router-link :to="getWorkRouter(props.item)">
                  {{ props.item.subtitle }}
                </router-link>
              </td>
              <td >{{ props.item.type }}</td>
              <td >{{ props.item.semester.subject }}</td>
              <td  >{{ props.item.semester.course }}</td>
              <td  >{{ props.item.semester.semester }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
    <router-view></router-view>
  </LoadPage>
</template>

<script>
  import urls from "@/urls/management";
  import {loadData, checkFieldTrue, addGetParameters} from "@/helper";
  import LoadPage from "@/modules/core/pages/LoadPage";
  import DebugJsonComponent from "@/modules/core/components/DebugJsonComponent";
  import names from "@/modules/educationalManagement/routers/names";
  import TitledPageMixin from "../../../../../mixins/TitledPageMixin";

  export default {
    name: "WorkList",
    components: {
      LoadPage,
      DebugJsonComponent,
    },
    mixins: [TitledPageMixin],
    computed: {
      loadingPage: function(){
        return checkFieldTrue(this.works, 'loading');
      },
    },
    data: function () {
      return {
        names:names,
        page_title: 'Список принятых учебных работ',
        loadErrorMsg: '',
        actions: {
          nextCourseApplier: false,
          prevCourseApplier: false,
        },
        works: {
          student_id: this.$route.params.student_id,
          loading: false,
          items: [],
          next: '',
          prev: '',
          size: 0,
          headers: [
            {text: "№", sortable: false, align: 'left'},
            {text: "Название", sortable: false, align: 'left'},
            {text: "Тип", sortable: false, align: 'left'},
            {text: "Предмет", sortable: false, align: 'left'},
            {text: "Курс",sortable: false, align: 'left'},
            {text: "Семестр", sortable: false,  align: 'left'},
          ],
        }
      }
    },
    methods: {
      loadStudent: function () {
        let saver = (data) => {this.works.items = data.results;};
        let catcher = (error) => {
          // console.log(error);
        };
        let url = urls.EDUCATION.STUDENT.WORK.LIST(this.works.student_id);
        url = addGetParameters(url, {accept: true});
        loadData(url, saver, catcher);
      },
      getWorkRouter: function (work) {
        return {
          name: names.EDUCATIONAL.STUDENTS.WORK.DETAIL,
          params: {
            work_id: work.id,
            ...this.$route.params
          }
        }
      }
    },
    created() {
      this.setPageTitleWithObject("Education", {id: this.$route.params.student_id}, ' - ', true)
      this.loadStudent();
    }
  }
</script>

<style scoped>

</style>
