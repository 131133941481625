
import Vue from 'vue'
import App from './App'
import router from './router'
import vuetify from './plugins/vuetify';
import store from './store/index'
import * as Sentry from "@sentry/vue";

import 'vuetify/dist/vuetify.min.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import '../public/css/main.css'

import {checkIsProductionServer, checkIsLocalServer, checkIsDevelopmentServer} from "@/helper";
import VueHtmlToPaper from 'vue-html-to-paper';
import VueBreadcrumbs from 'vue-2-breadcrumbs'
import DatetimePicker from 'vuetify-datetime-picker'

import VueCookie from 'vue-cookies';

import VueYandexMetrika from 'vue-yandex-metrika'

Vue.use(VueCookie);
Vue.use(DatetimePicker);

Vue.use(VueHtmlToPaper);

Sentry.init({
  Vue,
  dsn: "https://a999e63f3247de0088f1600eff4ae15b@sentry.muctr.ru/9",
  integrations: [
    Sentry.browserTracingIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  // tracePropagationTargets: ["localhost","eios.muctr.ru","eios-test.muctr.ru"],
});

if(checkIsProductionServer())
  Vue.use(VueYandexMetrika, {
    id: process.env.VUE_APP_YandexMetrika,
    router: router,
    env: 'production',
    options:{
      clickmap:true,
      trackLinks:true,
      accurateTrackBounce:true,
      webvisor:true
    }
  })

Vue.use(VueBreadcrumbs, {
  mixins: [
    {
      methods: {
        getRoute: function f(crumb) {
          // console.log('VueBreadcrumbs')
          // console.log(crumb);
          // var path = crumb.path;
          // console.log({name: path.name, params: this.$route.params})
          // console.log(this.$route.params)
          // // for (var _i = 0, _a = Object.entries(this.$route.params); _i < _a.length; _i++) {
          // //   var _b = _a[_i], key = _b[0], value = _b[1];
          // //   let re = new RegExp(`:${key}`)
          // //   new RegExp('/:')
          // //   path = path.replace(":" + key, value);
          // // }
          return {name: crumb.name, params: this.$route.params, query: this.$route.query};
          // return crumb.path
        }
      }
    }
  ],
  template:
    `
<nav class="breadcrumb text-left" v-if="$breadcrumbs.length" xmlns="http://www.w3.org/1999/html">
  <template v-for="(crumb, i) in $breadcrumbs">
<!--  {{getPath(crumb)}}-->
    <router-link class="breadcrumb-item" :to="getRoute(crumb)" >
      <v-chip class="breadcrumb-item-chips mb-2 "  color="blue lighten-3 grey--text text--darken-1">
        {{ getBreadcrumb(crumb.meta.breadcrumb) }}
<!--          {{getRoute(crumb)}}-->
      </v-chip center>
    </router-link>
    <span v-if="$breadcrumbs.length - 1 !== i" class="pa-0 ma-0"><v-icon small class="pa-0 ma-0" style="vertical-align: baseline!important;">label_important</v-icon></span>
  </template>
</nav>
`
});

Vue.config.productionTip = false;

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App)
}).$mount('#app');

//HOOOOOOOOKKKK to xp chrome detected
function getChromeVersion () {
    var raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);

    return raw ? parseInt(raw[2], 10) : false;
}
let chrome = getChromeVersion();
if(chrome && chrome <= 49){
  window.onload = function() {
    let element = document.getElementById('base-content');
    element.style.paddingTop = '60px';
    element =  document.getElementById("main-left-menu");
    element.style.marginTop = '60px';
    element.style.maxHeight = 'calc(100% - 60px)';
  }
}

