<template>
  <v-container style="height: 100%" >
    <v-card flat>
      <v-toolbar flat class="title" color="style-color-main-gray">
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn @click="opened_form = true" color="secondary" text>
            <v-icon>add</v-icon>
            Добавить
          </v-btn>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                text
                v-on="on"
                @click="getItems()"
                class="mr-2"
                medium
              >
                <v-icon>
                  update
                </v-icon>
              </v-btn>
            </template>
            <span>Обновить данные</span>
          </v-tooltip>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="pb-3 pt-0 mx-0 px-0">
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="pagination.loading"
          :page.sync="pagination.page"
          :items-per-page.sync="pagination.size"
          :server-items-length="pagination.count"
          class="elevation-1"
          no-data-text="Нет данных"
          :footer-props="{'items-per-page-options':[10,25,50], 'items-per-page-text': 'Число элементов'}"
        >
          <template v-slot:item="props" >
            <tr class="text-start">
              <td>{{props.item.started}}</td>
              <td>{{props.item.ended}}</td>
              <td>
                <v-icon color="success" v-if="props.item.original">done</v-icon>
                <v-icon v-else>clear</v-icon>
              </td>
              <td class="justify-end d-flex align-center" v-bind="selected">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      v-on="on"
                      @click="updateItem(props.item)"
                      class="mr-2"
                      medium
                    >
                      <v-icon>
                        edit
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Редактировать</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      v-on="on"
                      @click="openConfirmDelete(props.item)"
                      medium
                    >
                      <v-icon>
                        delete
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Удалить</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
          <template v-slot:no-data>
            <v-card-text>Данные не загружены</v-card-text>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
      <SemesterSession @addItem="addItem"
                       @ended="closedForm"
                       @refreshItem="refreshItem"
                       @input="closedForm"
                       :opened_form="opened_form"
                       v-bind="selected"
                       v-bind:info="$route.params.id"
                       v-if="opened_form"
      >
      </SemesterSession>
    <v-dialog persistent v-model="opened_confirm" width="500">
      <v-card v-if="deletable">
        <v-card-title>Подтвердите удаление</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-4">
          Вы уверены, что хотите удалить?<br>
        </v-card-text>
        <v-card-actions>
          <v-flex>
            <v-btn
              @click="deleteItem(deletable.id)"
              color="warning"
              text
            >
              Удалить
            </v-btn>
            <v-btn
              @click="closeConfirmDelete"
              text
            >Отмена
            </v-btn>
          </v-flex>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import SemesterSession  from "../../forms/academic_year/SemesterSession"
import UserDataMixin from '@/mixins/UserDataMixin'
import {loadData, makeDeleteRequest} from "@/helper";
import urls from "@/urls/management";
import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin"

export default {
  name: "ListSemester",
  components: {SemesterSession},
  mixins: [UserDataMixin, PaginatedDataMapperMixin],
  props: {
    isDetail: Boolean,
  },

  data () {
    return {
      URL_LIST: urls.EDUCATION.ACADEMIC_YEAR.YEAR_SEMESTER.SESSION.LIST(this.$route.params.idSemesters),
      items: [],
      pagination: {},
      headers: [
        {text: 'Дата начала', sortable: false, align: 'left',},
        {text: 'Дата окончания', sortable: false, align: 'left', },
        {text: 'Базовая', sortable: false, align: 'left', },
        {text: 'Действия', sortable: false, align: 'right', },
      ]
    }
  },
  watch: {
  },
  created() {
    this.getItems();
    this.$store.commit('mainpage/changeTitle', 'Сессия учебного года');
  },
  methods: {
    addItem(){
      this.closedForm()
      this.getItems();
    },
    deleteItem(id) {
      let url = urls.EDUCATION.ACADEMIC_YEAR.YEAR_SEMESTER.SESSION.DELETE(this.$route.params.idSemesters,id)
      makeDeleteRequest(url).then(resp => {
        if (resp.ok) {
          this.closeConfirmDelete()
          this.getItems();
        }
      })
    },
    loadData(url = undefined) {
      this.getItems(url)
    },
    getItems (url=undefined) {
      this.setLoading();
      if(url === undefined) {
        url = this.URL_LIST
        this.setCurrentLoadUrl(url);
      }
      loadData(
        url,
        (data) => {
          this.mapResults(data,'items')
          this.setNotLoading();
        }
      )
    },
  }
}


</script>
<style scoped>

</style>
