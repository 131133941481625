let selectors = {
  educations: [
    '-----',
    'среднее профессиональное образование',
    'высшее образование - бакалавриат',
    'высшее образование - специалитет, магистратура',
    'высшее образование - подготовка кадров высшей квалификации',
  ],
  academic_ranks: [
    '-----',
    'Доцент',
    "Профессор"
  ],
  academic_degrees: [
    '-----',
    'Кандидат наук',
    "Доктор технических наук",
    'Доктор химических наук',
    "Доктор физико-математических наук",
    "Доктор экономических наук",
    "Доктор филосовских наук",
    "Доктор психологических наук",
    "Доктор исторических наук",
    "Доктор юридических наук",
    "Доктор геолого-минералогических наук",
    "Доктор педагогических наук",
    'Кандидат биологических наук',
    'Кандидат филосовских наук',
    'Кандидат экономических наук',
    'Кандидат технических наук',
    'Кандидат химических наук',
    'Кандидат социологических наук',
    'Кандидат физико-математических наук',
    'Кандидат филологических наук',
    'Кандидат психологических наук',
    'Кандидат юридических наук',
    'Кандидат педагогических наук',
    'Кандидат исторических наук',
  ],
  optionally_group_number:[
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9
  ]
};
export default selectors;
