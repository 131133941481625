<template>
  <v-card :flat="isFlat">
    <v-toolbar
      class="mb-2"
      color="style-color-main-gray"
      flat
    >
      <slot name="title">
        <v-toolbar-title class="text-wrap">
          <template v-if="student.fio !== undefined">
            {{student.fio}}
          </template>
          <template v-else>
            {{student.user.lastname}} {{student.user.firstname}} {{student.user.midname}}
          </template>
        </v-toolbar-title>
      </slot>
      <v-spacer></v-spacer>
      <template v-slot:extension v-if="$vuetify.breakpoint.mobile && show_extra_actions">
        <v-toolbar-items @click.stop="" class="mx-auto">
          <slot name="extra_actions"></slot>
        </v-toolbar-items>
      </template>
      <v-toolbar-items @click.stop="" v-if="!$vuetify.breakpoint.mobile && show_extra_actions">
        <slot name="extra_actions"></slot>
      </v-toolbar-items>
    </v-toolbar>
    <v-row>
      <slot name="prepend_body"></slot>
      <v-col cols="12" sm="4" class="d-flex flex-column justify-center" v-if="show_avatar">
        <v-img :src="(avatar !== null) ? avatar: NotAvatar "
               class="ma-5 d-flex"
               aspect-ratio="1"
               contain>
        </v-img>
      </v-col>

      <v-col class="text-left d-flex flex-column px-5" height="100%" cols="12" :sm="show_avatar ? 8: 12">
        <student-info-row :visible="!hides.group" label="Учебная группа:"
                          :divider-no-show="!hides.group && (student.scientific_director !== undefined && !hides.scientific_director)"
        >
          <template v-slot:value>
            <slot name="education_group_title" v-bind:education_group="student.education_group">
              {{student.education_group.litter}}-{{student.education_group.course}}{{student.education_group.number}}
            </slot>
          </template>
        </student-info-row>
        <student-info-row :visible="!hides.direction" label="Направление подготовки:"
                          :value="`${student.direction.code} ${student.direction.name}`">
        </student-info-row>
        <student-info-row v-if="student.profile" :visible="!hides.profile" label="Профиль подготовки:"
                          :value="student.profile.name">
        </student-info-row>
        <student-info-row :visible="!hides.cathedra" label="Кафедра:"
                          :value="student.education_group.cathedra || student.cathedra">
        </student-info-row>
        <student-info-row :visible="student.user && !hides.email" label="Год поступления:" :value="student.entry_year">
        </student-info-row>
        <student-info-row :visible="!hides.level" label="Уровень образования:"
                          :value="student.education_group.level || student.level || student?.direction?.level">
        </student-info-row>
        <student-info-row :visible="!hides.form" label="Форма обучения:"
                          :value="student.education_group.form || student.form">
        </student-info-row>
        <student-info-row :visible="student.financing && !hides.financing" label="Финансирование:" :value="student.financing">
        </student-info-row>
        <student-info-row :visible="student.record_book_number && !hides.record_book_number" label="Номер зачетной книжки:" :value="student.record_book_number">
        </student-info-row>
        <student-info-row
          :visible="student.scientific_director !== undefined && !hides.scientific_director"
          label="Научный руководитель:"

        >
          <template v-slot:value>
            <slot name="scientific_director" v-bind:scientific_director="student.scientific_director">
              <span v-if="student.scientific_director === null">Не назначен</span>
              <span class="d-flex justify-space-between">
                <span class="mr-auto">
                  {{student.scientific_director_fio}}
                </span>
              </span>
            </slot>
          </template>
        </student-info-row>
        <student-info-row v-if="student.status!==null && typeof(this.student.status)==='object' && student.status.reason!==null"
                          :visible="student.status.status && !hides.status"
                          label="Статус:"
                          :value="student.status.status + ' (' + student.status.reason + ')'">
          <template v-slot:icon >
            <slot slot name="append_body">
            </slot>
          </template>
        </student-info-row>
        <student-info-row v-else-if="student.status!==null && typeof(this.student.status)==='object' && student.status.reason===null"
                          :visible="student.status.status && !hides.status"
                          label="Статус:"
                          :value="student.status.status">
          <template v-slot:icon >
            <slot slot name="append_body">
            </slot>
          </template>
        </student-info-row>
        <student-info-row v-else-if="student.status!==null && typeof(this.student.status)==='string'"
                          :visible="student.status && !hides.status"
                          label="Статус:"
                          :value="student.status">
      </student-info-row>
        <student-info-row v-if="student.user" :visible="student.user && !hides.birth" label="Дата рождения:" :value="student.user.birth">
          <template v-slot:value>
            <template>{{displayDate(student.user.birth)}}</template>
          </template>
        </student-info-row>
        <student-info-row v-if="student.user.sex" :visible="student.user && !hides.sex" label="Пол:" :value="student.user.sex">
        </student-info-row>
        <student-info-row v-if="student.user" :visible="student.user && !hides.country" label="Гражданство:" :value="student.user.country">
        </student-info-row>
        <student-info-row v-if="student.user" :visible="student.user && !hides.country" label="Внешняя электронная почта:" :value="student.user.email">
          <template v-slot:value>
            <slot name="student_user_email" v-bind:email="student.user.email">
               {{student.user.email}}
            </slot>
          </template>
        </student-info-row>
        <student-info-row v-if="student.user" :visible="student.user && !hides.last_login" label="Последний вход:">
          <template v-slot:value>
            <template v-if="student.user.last_login !== null">{{displayDate(student.user.last_login,true)}}</template>
            <template v-else>Ни разу не заходил</template>
          </template>
        </student-info-row>
        <slot name="append_rows"></slot>
      </v-col>
    </v-row>
<!--    <DebugJsonComponent :json="student"></DebugJsonComponent>-->
  </v-card>
</template>

<script>
  import NotAvatar from "@/assets/NotAvatar.png";
  import StudentInfoRow from "./StudentInfoRow";
  import moment from "moment";
  import DebugJsonComponent from "@/modules/core/components/DebugJsonComponent"
  import {makeGetFileRequest} from "@/helper";


  export default {
    name: "StudentDetailTemplate",
    components: {
      DebugJsonComponent,
      StudentInfoRow
    },
    computed: {
      NotAvatar: () => NotAvatar,
    },
    props: {
      isFlat: {
        type: Boolean,
        default: false,
      },
      show_extra_actions: {
        type: Boolean,
        default: true,
      },
      is_dean: {
        type: Boolean,
        default: false,
      },
      student: {
        required: true,
        type: Object,
      },
      show_last_login: {
        type: Boolean,
        default: true,
      },
      hides: {
        type: Object,
        default: () => {return {}},
      },
      show_avatar: {
        type: Boolean,
        default: true,
      }
    },
    data() {
      return {
        avatar: null,
      }
    },
    methods: {
      displayDate(date, minutes = false) {
        let format = 'DD.MM.YYYY';
        if (minutes)
          format += ' HH:mm';
        return moment(date).format(format)
      },
      loadAvatar(){
        if (this.student.user.avatar) {
          makeGetFileRequest(this.student.user.avatar, {},{responseType: 'arraybuffer'})
            .then(
              response => response.blob()
            ).then(
            data => {
              this.avatar = URL.createObjectURL(data)
            });
        }
      },
    },
    created() {
      this.loadAvatar();
    }
  }
</script>

<style scoped>

</style>
