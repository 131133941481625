<template>
  <v-container fluid>
    <PortfolioList
      :items="items"
      @openConfirmDelete="openConfirmDelete"
      @updateItem="updateObj"
      @createItem="opened_form=true ; errors = {}"
    ></PortfolioList>
    <PortfolioForm
      :errors="errors"
      @ended="closedForm"
      @createItem="createItem"
      :opened_form="opened_form"
      :choices="choices"
      v-bind="selected"
      v-if="opened_form"
    >
    </PortfolioForm>
    <v-dialog persistent v-model="opened_confirm" width="500">
      <v-card v-if="deletable">
        <v-card-title>Подтвердите удаление</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-4">
          Вы уверены, что хотите удалить?<br>
        </v-card-text>
        <v-card-actions>
          <v-flex>
            <v-btn
              @click="deleteItemId(deletable.id)"
              color="warning"
              text
            >
              Удалить
            </v-btn>
            <v-btn
              @click="closeConfirmDelete"
              text
            >Отмена
            </v-btn>
          </v-flex>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>

</template>

<script>
import urls from "@/urls/student"
import choices from "@/urls/choices";
import UserDataMixin from '@/mixins/UserDataMixin'
import {finalizeRequest, makeGetRequest, makePostRequestFile,loadData} from "../../../../helper";
import PortfolioList from "@/modules/templates/portfolio/PortfolioList"
import PortfolioForm from "@/modules/templates/portfolio/PortfolioForm"

export default {
  name: "ListAdmissions",
  components: {PortfolioList, PortfolioForm},
  mixins: [UserDataMixin],

  data() {
    return {
      items: [],
      loading: true,
      URL_LIST: urls.STUDENT.PORTFOLIO.LIST(),
      URL_CREATE: urls.STUDENT.PORTFOLIO.CREATE(),
      choices: {
        work_type: [],
        knowledge_type: [],
        document_type: [],
      },
    }
  },

  methods: {
    loadChoices(url, choices){
      loadData(
        url,
        (data) => {
          this.choices[choices] = data;
        }
      )
    },
    deleteItemId(id){
      this.URL_DELETE = urls.STUDENT.PORTFOLIO.DELETE(id)
      this.deleteItem(id)
    },
    updateObj(item) {
      this.selected = item
      this.errors = {}
      this.opened_form = true
    },
    receiveErrors: function (json) {
      let have = false;
      for (let key in json) {
        if (json.hasOwnProperty(key)) {
          if (Array.isArray(json[key])) {
            have = true;
          }
        }
      }
      this.errors = json;
      return have;
    },
    createItem(item) {
      this.errors = {}
      let url = this.URL_CREATE
      let metod = 'POST'
      if (item.id) {
        url = urls.STUDENT.PORTFOLIO.UPDATE(item.id)
        metod = 'PATCH'
      }
      let formData = new FormData();
      formData.append('title', item.title)
      formData.append('description', item.description)
      formData.append('work_type', item.work_type)
      formData.append('knowledge_field', item.knowledge_field)
      formData.append('document_type', item.document_type)
      if(item.number) formData.append('number', item.number)
      if(item.volume>0) formData.append('volume', item.volume)
      if(item.start) formData.append('start', item.start)
      if(item.end) formData.append('end', item.end)
      if (typeof item.file === 'object' && item.file !== null){
        if (String(item.file.name).length < 130 )
          formData.append('file', item.file)
        else {
          alert('Слишком длинное имя файла для загрузки')
          item.file = null
          return
        }
      }
      finalizeRequest(makePostRequestFile(url, formData, metod),
        (json) => {
          this.closedForm();
          this.getItems();
        },
        (json) => {
          this.receiveErrors(json);
        })
    },
  },
  created() {
    this.$store.commit('mainpage/changeTitle', 'Список достижений');
    this.getItems();
    this.loadChoices(choices.EDUCATION.CHOICES.PORTFOLIO_WORK_TYPE(),'work_type')
    this.loadChoices(choices.EDUCATION.CHOICES.PORTFOLIO_KNOWLEDGE_TYPE(),'knowledge_type')
    this.loadChoices(choices.EDUCATION.CHOICES.PORTFOLIO_DOCUMENT_TYPE(),'document_type')
  },
}


</script>

<style scoped>

</style>
