import { render, staticRenderFns } from "./ReferencePXTY.vue?vue&type=template&id=e51c7bdc&scoped=true"
import script from "./ReferencePXTY.vue?vue&type=script&lang=js"
export * from "./ReferencePXTY.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e51c7bdc",
  null
  
)

export default component.exports