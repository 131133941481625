<template>
  <scroll-center-dialog v-model="opened_form"
                        @close="closeDialog"
                        @apply="saveScienceDirector"
                        :title="'Сменить научного руководителя'"
                        width="800"
  >

    <v-alert type="info" outlined>
      В качестве научного руководителя можно выбрать любого работника университета.
    </v-alert>

    <v-text-field
      readonly
      :value="teacher_fio"
      placeholder="Не выбран научный руководитель"
      class="mr-3 ml-3"
      label="Текущий научный руководитель"
    >
    </v-text-field>

    <loading-autocomplete
      class="mr-3 ml-3"
      placeholder="Введите ФИО и выберете из предложенных вариантов"
      label="Научный руководитель"
      v-model="selected"
      search-parameter="fio"
      :url="worker_selector"
      :post-loader="postLoader"
    >
      <template v-slot:no-data>
        <div>
          Для поиска начните набирать ФИО работника, </br> и либо остановитесь на 1 секунду, либо нажмите Enter
        </div>
      </template>
    </loading-autocomplete>
    <!--          :error="hasError('curator')"-->
    <!--          :error-messages="getError('curator')"-->
    <!--          <v-autocomplete-->
    <!--            class="mr-3 ml-3"-->
    <!--            outline-->
    <!--            :error="hasError('cathedra')"-->
    <!--            :error-messages="getError('cathedra')"-->
    <!--            :items="selectors.cathedra"-->
    <!--            no-data-text="Нет данных"-->
    <!--            label="Сменить на кафедру"-->
    <!--            v-model="value.cathedra"-->
    <!--          ></v-autocomplete>-->


    <slot
      name="snackbar"
      v-bind="{
        notification,
        color: saved === undefined ? 'info' : saved ? 'success' : 'warning',
        timeout: '3000',
        msg,
      }">
      <v-snackbar
        v-model="notification"
        timeout="3000"
        :color="saved === undefined ? 'info' : saved ? 'success' : 'warning'"
        top
        right
        app
        style="border-top-left-radius: 0; border-top-right-radius: 0;"
      >
        {{ msg }}
      </v-snackbar>
    </slot>
  </scroll-center-dialog>
</template>

<script>
import UserDataFormMixin from "@/mixins/UserDataFormMixin";
import LoadingAutocomplete from "@/modules/core/components/LoadingAutocomplete";
// import urls from "@/modules/teacher/urls";
import {clearUserAuth, makeGetRequest, makeParticalUpdateRequest, makePostRequest, sendPostRequest} from "@/helper";
import selectors from "@/urls/selectors";
// import dean from "@/urls/dean";
import cathedras from "@/urls/cathedras";
import {generateListUidToName} from "@/helper/uidToName";
import ScrollCenterDialog from "@/modules/core/components/ScrollCenterDialog";

export default {
  name: "GroupChangeCathedra",
  mixins: [UserDataFormMixin],
  components: {
    ScrollCenterDialog,
    LoadingAutocomplete
  },
  props: {
    opened_form: Boolean,
    group_id: {
      required: true,
      type: Number
    },
    student_fio: {
      required: true,
      type: String
    },
    student_id: {
      required: true,
      type: Number,
    },
    teacher_uid: {
      required: true,
    },
    teacher_fio: {
      required: false,
    }
  },
    // ['id', 'unvisible', 'selected',],
  watch: {},
  data() {
    return {
      selected: '',
      search: '',
      saved: undefined,
      notification: false,
      msg: '',

    }
  },
  computed: {
    worker_selector(){
      return selectors.URLS.WORKERS.ALL()
    }
  },
  methods: {
    postLoader(data, list, end) {
      // console.log('postLoader')
      data.results.forEach(el => {
        this.$set(el, 'fio', '');
      })
      let vals = list(data.results.filter(el => el.value !== this.teacher_uid));
      generateListUidToName(
        vals,
        "value",
        "fio",
        ()=>{
          vals.forEach(el => {
            this.$set(el, 'text', `${el.fio}, ${el.text}`)
          })
          end();
        }
      )
    },
    saveScienceDirector: function (){
      // console.log('saveScienceDirector')
      // console.log(cathedras.CATHEDRA.GROUPS.SCIENCE_DIRECTORS.SAVE(this.$route.params.cathedra_uid, this.group_id))
      this.loading = true;
      let data = {
        'students': [{
          student: this.student_id,
          scientific_director: this.selected
        }]
      };
      if(this.selected !== this.teacher_uid){
        sendPostRequest(
          cathedras.CATHEDRA.GROUPS.SCIENCE_DIRECTORS.SAVE(this.$route.params.cathedra_uid, this.group_id),
          data,
          (res) => {
            this.saved = true;
            this.notification = true;
            this.loading = false;
            this.msg = 'Успешно сохранено';
            this.$emit('saved', this.selected);
            this.selected = '';
          },
          (res) => {
            this.saved = false;
            this.notification = true;
            this.loading = false;
            this.msg = res;
          }
        )
      }else{
        this.saved = undefined;
        this.notification = true;
        this.loading = false;
        this.msg = 'Данные не были изменены';
      }
    },
    closeDialog() {
      this.$emit('close', '')
    },
  },
  created() {
    // this.loadSelector('cathedra', selectors.URLS.FACULTY.CATHEDRAS(this.$route.params.idFaculty))
  }
}
</script>

<style scoped>

</style>
