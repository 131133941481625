<template>
  <scroll-center-dialog v-model="opened_form"
                        @close="closeDialog"
                        @apply="apply"
                        :loading="loading"
                        :title="!!id ? 'Изменить систему рейтинга' : 'Добавить систему рейтинга'"
                        width="500"
  >
    <v-text-field
      :error="hasError('name')"
      :error-messages="getError('name')"
      v-model="value.name"
      label="Наименование"
      required
    />
    <v-text-field
      :error="hasError('excellent')"
      :error-messages="getError('excellent')"
      v-model="value.excellent"
      label="Нижняя граница отличной оценки"
      type="number"
      step="1"
      required
    />
    <v-text-field
      :error="hasError('good')"
      :error-messages="getError('good')"
      v-model="value.good"
      label="Нижняя граница хорошей оценки"
      type="number"
      step="1"
      required
    />
    <v-text-field
      :error="hasError('satisfactory')"
      :error-messages="getError('satisfactory')"
      v-model="value.satisfactory"
      label="	Нижняя граница удовлетворительной оценки"
      type="number"
      step="1"
      required
    />
    <v-text-field
      :error="hasError('credit')"
      :error-messages="getError('credit')"
      v-model="value.credit"
      label="Нижняя граница зачета без оценки"
      type="number"
      step="1"
      required
    />
    <v-text-field
      :error="hasError('satisfactory_automate ')"
      :error-messages="getError('satisfactory_automate ')"
      v-model="value.satisfactory_automate"
      label="Нижняя граница автомата на 3"
      type="number"
      step="1"
      required
    />
    <v-text-field
      :error="hasError('good_automate ')"
      :error-messages="getError('good_automate ')"
      v-model="value.good_automate "
      label="Нижняя граница автомата на 4"
      type="number"
      step="1"
      required
    />
    <v-text-field
      :error="hasError('excellent_automate  ')"
      :error-messages="getError('excellent_automate  ')"
      v-model="value.excellent_automate  "
      label="Нижняя граница автомата на 5"
      type="number"
      step="1"
      required
    />
    <v-text-field
      :error="hasError('satisfactory_additional   ')"
      :error-messages="getError('satisfactory_additional   ')"
      v-model="value.satisfactory_additional   "
      label="Количество добавляемых баллов при автомате на 3"
      type="number"
      step="1"
      required
    />
    <v-text-field
      :error="hasError('good_additional   ')"
      :error-messages="getError('good_additional   ')"
      v-model="value.good_additional   "
      label="Количество добавляемых баллов при автомате на 4"
      type="number"
      step="1"
      required
    />
    <v-text-field
      :error="hasError('excellent_additional   ')"
      :error-messages="getError('excellent_additional   ')"
      v-model="value.excellent_additional"
      label="Количество добавляемых баллов при автомате на 5"
      type="number"
      step="1"
      required
    />
    <v-text-field
      :error="hasError('formula')"
      :error-messages="getError('formula')"
      v-model="value.formula"
      label="Формула подсчета общего рейтинга"
      required
    />
  </scroll-center-dialog>
</template>

<script>
import urls from "@/urls/management";
import UserDataFormMixin from '@/mixins/UserDataFormMixin'
import ScrollCenterDialog from "@/modules/core/components/ScrollCenterDialog";
import {loadData} from "@/helper";

export default {
  components: {ScrollCenterDialog},
  mixins: [UserDataFormMixin],
  name: "RatingSystemForm",
  props: {
    open: Boolean,
    name: String,
    id: Number,
    opened_form: Boolean,
  },
  data: function (){
    return {
      URL_CREATE: urls.EDUCATION.REFERENCE.RATING_SYSTEM.CREATE(),
      URL_UPDATE: urls.EDUCATION.REFERENCE.RATING_SYSTEM.UPDATE(this.id),
      loading : false,
      value: {
        name: "",
        excellent: 0,
        good: 0,
        satisfactory: 0,
        credit: 0,
        formula: "",
        satisfactory_automate: 0,
        good_automate : 0,
        excellent_automate : 0,
        satisfactory_additional : 0,
        good_additional : 0,
        excellent_additional : 0,
      },
    }
  },
  methods: {
    closeDialog() {
      this.$emit('ended', '')
    },
  },
  created() {
    if(this.id) {
      let url = this.URL_UPDATE.replace('<id>',this.id)
      let saver = (data) => {
        this.value.name = data.name
        this.value.excellent = data.excellent
        this.value.good = data.good
        this.value.satisfactory = data.satisfactory
        this.value.credit = data.credit
        this.value.formula = data.formula
        this.value.satisfactory_automate = data.satisfactory_automate
        this.value.good_automate = data.good_automate
        this.value.excellent_automate = data.excellent_automate
        this.value.satisfactory_additional = data.satisfactory_additional
        this.value.good_additional = data.good_additional
        this.value.excellent_additional = data.excellent_additional
      }

      let cather = (data) => {
        this.receiveErrors(data);
      };
      loadData(url, saver, cather);
    }
  }
}
</script>

<style scoped>

</style>
