<template>
  <div v-if="is_self_route">
<!--    <DebugJsonComponent :json="this.$route.params"></DebugJsonComponent>-->
    <DetailTemplate v-if="group" :group="group" show_curriculum :loaded="loaded" show_students show_subjects></DetailTemplate>
    <v-container v-else>
      <v-row>
        <v-col>
          <v-skeleton-loader
            height="50"
            type="list-item"
          >
          </v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-skeleton-loader
            height="200"
            type="card"
          >
          </v-skeleton-loader>
        </v-col>
        <v-col>
          <v-skeleton-loader
            height="200"
            type="card"
          >
          </v-skeleton-loader>
        </v-col>
      </v-row>
    </v-container>

<!--        <DebugJsonComponent :json="group"></DebugJsonComponent>-->
  </div>
  <router-view v-else></router-view>
</template>

<script>

import TitledPageMixin from "../../../../mixins/TitledPageMixin";
import DetailTemplate from "./detail/ArchiveBase";
import {loadData, makeGetRequest} from "@/helper";
import cathedras from "../../../../urls/cathedras";
import {generateListUidToName} from "@/helper/uidToName";
import names from "../../routers/names";
import DebugJsonComponent from "@/modules/core/components/DebugJsonComponent";

export default {
  name: "ArchiveDetail",
  // mixins: [LoadData],
  mixins: [
    TitledPageMixin,
  ],
  components: {
    DetailTemplate,
    DebugJsonComponent,
  },
  data: function () {
    return {
      page_title: 'Детальное представление группы',
      opened_form: false,
      year_semester: null,
      // items: undefined,
      attachment: false,
      cathedra_uid: this.$route.params.cathedra_uid,
      group_id: this.$route.params.group_id,
      group: null,
      loaded: {
        group: false,
        curriculum: false,
        semesters: false,
        students: false,
      }
    }
  },
  computed: {
    is_self_route: function () {
      return names.CathedraArchiveGroupsDetail === this.$route.name
    },
    archive: function () {
      return this.$route.params.archive === 'archive'
    },
  },
  methods: {
    loadGroup() {
      if (this.$route.query.year_semester=== undefined){
        this.year_semester = null
      }
      else if (typeof this.$route.query.year_semester === "string"){
        this.year_semester = this.$route.query.year_semester
      }
      else{
        this.year_semester = this.$route.query.year_semester.value
      }
      loadData(
        cathedras.CATHEDRA.GROUPS.ARCHIVE.DETAIL(this.cathedra_uid, this.group_id,{year_semester: this.year_semester}),
        (data) => {
          this.group = data
          this.loaded.group = false
          this.loaded.curriculum = true
          loadData(
            cathedras.getEduUrlWithParameters(
              this.group.students.basename,
              this.group.students.action,
              this.group.students.params,
              this.group.students.get_params
            ),
            (data) => {
              this.group.students = data
              this.group.students.forEach((el) => {
                this.$set(el, 'fio', '')
              });
              generateListUidToName(this.group.students, 'user', 'fio');
              this.loaded.students = true
            }
          )
          loadData(
            cathedras.getEduUrlWithParameters(
              this.group.semesters.basename,
              this.group.semesters.action,
              this.group.semesters.params,
              this.group.semesters.get_params
            ),
            (data) => {
              this.group.semesters = data
              let teachers = []
              this.group.semesters.forEach(el => {
                el.teachers.forEach(el => {
                  if(!teachers.find(fel => {return fel.uid === el.teacher}))
                    teachers.push({uid: el.teacher, fio: ''})
                });
              });
              generateListUidToName(teachers, 'uid', 'fio', (data) => {
                this.group.semesters.forEach(el => {
                  el.teachers.forEach(el => {
                    let teacher = data.find(fel => {return fel.uid === el.teacher})
                    this.$set(el, 'fio', teacher.fio);
                  });
                });
              });
              this.loaded.semesters = true
            }
          )
        }
      )
    },
  },
  created() {
    this.loadGroup()
  }
}
</script>

<style scoped>

</style>
