import {sendPostRequest} from "@/helper";
import FormErrorsMixin from "@/mixins/FormErrorsMixin";

export default {
  mixins: [FormErrorsMixin],
  props: {
    value: {
      required: true
    },
    item: {
      required: true
    },
    actionUrl: {
      required: true,
    },
  },
  data() {
    return {
      inValue: undefined,
      loading: false,
      errors: {
        raw: null
      },
    }
  },
  watch: {
    inValue(nV, oV) {
        this.$emit('input', this.inValue)
    },
    value(nv, oV) {
        this.inValue = this.value
      if(this.value){
        this.clearState()
        this.onOpenPreAction()
      }
    },
  },
  methods: {
    clearState(){
      this.clearErrors()
      this.errors.raw = null
    },
    onOpenPreAction(){

    },
    emitReloadAfterAction(){
      this.$emit('reload')
    },
    emitApplyAction(data){
      this.$emit('apply', {item: this.item, data: data})
    },
    closeLinkDialog(){
      this.inValue = false
    },
    setActionLoading(){
      this.loading = true
    },
    setActionNotLoading(){
      this.loading = false
    },
    getActionUrl(education_id){
      return this.actionUrl(education_id)
    },
    getRequestMethod(){
      return sendPostRequest
    },
    getEducationId(){
      return this.item.eios.id
    },
    getActionArgs(kwargs){
      return {}
    },
    hasErrors(){
      return Object.keys(this.errors.fields).length > 0 || this.errors.non.length > 0 || this.errors.raw !== null
    },
    checkHasError(data){
      return !!data && Object.hasOwn(data, 'action_log') && !!data.action_log.error
    },
    mapRawError(data){
      this.errors.raw = data.action_log.error;
    },
    execute(kwargs){
      this.setActionLoading()
      this.clearErrors()
      this.getRequestMethod()(
        this.getActionUrl(this.getEducationId()),
        this.getActionArgs(kwargs),
        (data) => {
          if (this.checkHasError(data)){
            this.mapRawError(data)
            this.mapErrors(data)
            this.setActionNotLoading()
          } else {
            this.emitReloadAfterAction()
            this.setActionNotLoading()
            this.emitApplyAction(data)
            this.closeLinkDialog()
          }
        },
        (errors) => {
          this.setActionNotLoading()
          if (errors)
            if (Object.hasOwn(errors, 'detail'))
              this.errors.raw = errors.detail
            else
              this.mapErrors(errors)
        }
      )
    }
  },
}
