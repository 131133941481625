<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    min-width="auto"
    offset-y
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="format_date"
        :rules="[required ? irules.required(value_date) : true, irules.valid_date, irules.max_date, irules.min_date, ...rules]"
        :label="label"
        :hint="hint"
        persistent-hint
        :error="error"
        :error-messages="errorMessages"
        offset-y
        @input="inputValueByText"
      >
        <template v-slot:label>
          {{label}} <span v-if="required" class="red--text"><strong>* </strong></span>
        </template>
        <template v-slot:prepend>
          <v-icon v-on="on" v-bind="attrs">calendar_today</v-icon>
        </template>
      </v-text-field>
    </template>
    <v-date-picker
      ref="picker"
      v-model="value_date"
      :max="maxDate"
      :min="minDate"
      no-title
      @input="inputValueByPicker"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import moment from "moment";

export default {
  name: "DateFieldWithPicker",
  props: {
    required: {
      required: false,
      default: false,
      type: Boolean
    },
    loading: {
      required: false,
      default: false,
    },
    errorMessages:{
      required: false,
    },
    error:{
      required: false,
    },
    label: {
      required: false,
      default: '',
    },
    hint: {
      required: false,
      default: 'ДД.MM.ГГГГ',
    },
    min: {
      required: false,
      default: null,
    },
    max: {
      required: false,
      default: null,
    },
    rules: {
      required: false,
      default: () => [],
      type: Array
    },
    value: {
      required: true,
    }
  },
  data: function (){
    return {
      irules: {
        required: value => !!value || 'Поле обязательно для заполнения.',
        valid_date: value => moment(value, 'DD.MM.YYYY').isValid() || 'Введите правильную дату.',
        min_date: value => moment(value, 'DD.MM.YYYY').isSameOrAfter(this.minDate),
        max_date: value => moment(value, 'DD.MM.YYYY').isSameOrBefore(this.maxDate)
      },
      menu: false,
      format_date: this.formatDate(this.value),
      value_date: this.value,
      loaded: false
    }
  },
  watch: {
    'format_date' (val, oval) {
      //console.log('aaaa')
      if(oval === null || oval.length <= val.length)
        this.$nextTick(() => {
          this.format_date = this.checkAndReturnDate(val)
        })
    },
    // 'value_date' (val) {
    //   this.format_date = this.formatDate(this.value_date)
    // },
  },
  computed: {
    minDate(){
      return this.min ? moment(this.min).format('YYYY-MM-DD') : null
    },
    maxDate(){
      return this.max ? moment(this.max).format('YYYY-MM-DD') : null
    }
  },
  methods: {
    checkAndReturnDate(val) {
      let result = '';
      const dots = [3, 6];
      let i = 0;
      for(let char of val){
        i++;
        if(dots.indexOf(i) === -1){
          if(/^[0-9]$/g.test(char) && i <= 10)
            result += char
        }else{
          result += '.'
          if(char !== '.' && i <= 10)
            result += char
        }
      }
      if((result.length === 2 || result.length === 5) && result[result.length - 1] !== '.')
        result += '.'
      return result
    },
    formatDate (date) {
      if (!date) return null
      return moment(date).format('DD.MM.YYYY')
    },
    parseDate (date) {
      if (!date) return null
      if (!/[0-9]{2}.[0-9]{2}.[0-9]{4}/g.test(date))
        return null
      return moment(date, 'DD.MM.YYYY').format('YYYY-MM-YY')
    },
    inputValueByPicker(){
      this.format_date = this.formatDate(this.value_date)
      this.emitInputValueUpdate()
      this.menu = false
    },
    inputValueByText(){
      this.value_date = this.parseDate(this.format_date);
      if(this.value_date !== null)
        this.emitInputValueUpdate();
    },
    emitInputValueUpdate(){
      this.$emit('input', this.value_date);
    }
  },
}
</script>

<style scoped>

</style>
