import {getInstMenu, makeGetRequest, makeProfileUrl} from "@/helper";

const HOST_ACCOUNT = process.env.VUE_APP_API_HOST + process.env.VUE_APP_ACCOUNTS_PREFIX;
const HOST_ORGANIZATION = process.env.VUE_APP_API_HOST + process.env.VUE_APP_ORGANIZATION_PREFIX;


function get_domain(){
  /*
  *
   */
  if(process.env.VUE_APP_COOKIE_DOMAIN !== undefined){
    return process.env.VUE_APP_COOKIE_DOMAIN;
  }else{
    return null;
  }
}

function clear_stores() {
  /*
  * Очистка всех используемых стораджей относящихся непосредсвтенно к браузеру.
  * На данный момент это localstorage и cookie
  */
  localStorage.removeItem('user-token'); // clear your user's token from localstorage
  localStorage.removeItem('isWorker');
  localStorage.removeItem('isStudent');
  localStorage.removeItem('username');
  let domain = get_domain();
  window.$cookies.remove("userMuctr",null,domain);
}

function set_local_store(token, username, uid) {
  /*
  * Заполняем локальное хранилище
   */
  localStorage.setItem('user-token', token);
  localStorage.setItem('username', username);
  localStorage.setItem('user-uid', uid);
}

function set_cookie_store(token, username, uid){
  /*
  * Заполняем кукисы
   */
  window.$cookies.remove("userMuctr",null, get_domain()); // удалим старый
  window.$cookies.set(
    'userMuctr',
    {
      userToken: token,
      userName: username,
      userUid: uid,
    },
    "3y",
    null,
    get_domain(),
    null
  );
}

function set_stores(token, username, uid) {
  /*
  * Сохранения в стораджи браузера нужных параметров, для того что бы они сохранялись от входа до входа
   */
  set_cookie_store(token, username, uid)
  set_local_store(token, username, uid);
}

const state = {
  uid: null,
  token: null,
  status: null,
  error: null,
  username: null,
  last_check: null,
  isStudent: false,
  isWorker: null,
  need_change_password: false,
  loading: false,
};

let count = 0;
const getters = {
  getToken: state => state.token,
  authStatus: state => state.status,
  hasCookie: state => () => {
    return window.$cookies.isKey('userMuctr')
  },
  isCookieLoaded: state => !!state.token,
  isAuthenticate: state => state.token !== null,
  isNeedChangePassword: state => state.need_change_password,
  isCheckExpired: state => () => {
    return (new Date() - state.last_check) > (60 * 1000)
  },
};

const actions = {
  SET_IS_STUDENT:  ({commit, dispatch}, student) => {
    commit('SET_IS_STUDENT', student);
  },
  LOAD_FROM_COOKIE: ({commit}) => {
    let userMUCTR = window.$cookies.get("userMuctr");
    if (userMUCTR !== undefined && userMUCTR !== null) {
      commit('SET_TOKEN', userMUCTR.userToken);
      commit('SET_USERNAME', userMUCTR.userName);
      commit('SET_UID', userMUCTR.userUid);
    }
  },
  AUTH_REQUEST: ({commit, dispatch}, user) => {
    commit('AUTH_REQUEST');
    commit('SET_LOADING');
    return new Promise((resolve, reject) => { // The Promise used for router redirect in login
      fetch(process.env.VUE_APP_AUTH_URL_LOGIN, {
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(user),
        method: 'POST'
      })
        .then(respounce => {
          if(respounce.ok || respounce.status === 400) {
            return respounce.json()
              .catch(err => {
                clear_stores()
                commit('SET_NOT_LOADING');
                reject(err)
              });
          } else {
            clear_stores()
            let error = `${respounce.status}: ${respounce.statusText}`
            commit('AUTH_ERROR', error);
            commit('SET_NOT_LOADING');
            reject(error);
          }
        })
        .then(json => {
          if (!!json.error) {
            commit('AUTH_ERROR', json.error);
            commit('SET_NOT_LOADING');
            reject(json.error)
          } else if (!!json.detail){
            commit('AUTH_ERROR', json.detail);
            commit('SET_NOT_LOADING');
            reject(json.detail)
          } else {
            set_stores(json.token, user.username, json.uid)
            commit('AUTH_SUCCESS', {token: json.token, username: user.username});
            commit('SET_NEED_CHANGE_PASSWORD', json.need_change_password);
            commit('SET_CHECK_TIME');
            commit('SET_NOT_LOADING');
            resolve(json)
          }
        })
    })
  },
  AUTH_LOGOUT: ({commit, dispatch}) => {
    return new Promise((resolve, reject) => {
      let token = localStorage.getItem('user-token');
      fetch(process.env.VUE_APP_AUTH_URL_LOGOUT, {
        method: 'post',
        headers: {
          'Authorization': 'Token ' + token
        }
      });
      commit('AUTH_LOGOUT');
      clear_stores();
      resolve()
    })
  },
  RELOAD_USER: ({commit, dispatch}, user) => {
    commit('AUTH_SUCCESS', {token: user.token, username: user.username});
    set_stores(user.token, user.username, user.uid);
    commit('urls/clearUrls', null, { root: true });
  },
  CHECK_TOKEN_ALIVE: ({commit, dispatch, getters}) => {
    commit('SET_LOADING')
    return new Promise((resolve, reject) => {
      if(getters.hasCookie){
        if(!getters.isCookieLoaded){
          dispatch('LOAD_FROM_COOKIE');
        }
        fetch(
          `${HOST_ACCOUNT}authenticate/token/alive/`,
          {
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({token: getters.getToken}),
            method: 'POST'
          }
        ).then(resp => {
          if(!resp.ok) {
            clear_stores();
            commit('SET_NOT_LOADING');
            reject(Error(resp.statusText))
          }else {
            return resp.json()
          }
        }).then(json => {
          if (json.alive === false) {
            clear_stores()
            commit('AUTH_LOGOUT').then(() => {
              reject(json);
            });
          } else {
            commit('SET_NEED_CHANGE_PASSWORD', json.need_change_password);
            commit('SET_CHECK_TIME');
            commit('SET_NOT_LOADING');
            resolve();
          }
        }).catch(error => {
          clear_stores();
          commit('SET_NOT_LOADING');
          reject(error);
        });
      }else{
        commit('SET_NOT_LOADING');
        reject();
      }
    })
  },
  CHECK_USER_IS_WORKER({commit}, after=()=>{}){
    makeGetRequest(
      `${HOST_ORGANIZATION}profile/worker/exist/`,
    ).then(resp => {
      return resp.json()
    }).then(json => {
      if (json === true) {
        commit('SET_YES_IS_WORKER');
      } else {
        commit('SET_NO_IS_WORKER');
      }
      after();
    });
  }
};

const mutations = {
  AUTH_LOGOUT: (state) => {
    state.status = 'logout';
    state.error = null;
    state.isWorker = null;
    state.isStudent = false;
    state.token = null;
    state.uid = null;
    state.username = null;
    state.last_check = null;
    state.need_change_password = false;
  },
  AUTH_REQUEST: (state) => {
    state.status = 'loading';
    state.error = '';
  },
  AUTH_SUCCESS: (state, {token, username}) => {
    state.status = 'success';
    state.token = token;
    state.username = username;
  },
  AUTH_ERROR: (state, error) => {
    state.status = 'error';
    state.error = error
  },
  SET_YES_IS_WORKER: (state) => {
    state.isWorker = true;
    localStorage.setItem('isWorker', true);
  },
  SET_IS_STUDENT: (state,student) => {
    localStorage.setItem('isStudent', student);
    state.isStudent = (localStorage.getItem('isStudent') === 'true');
  },
  SET_NO_IS_WORKER: (state) => {
    state.isWorker = false;
    localStorage.setItem('isWorker', false);
  },
  SET_CHECK_TIME: (state) => {
    state.last_check = new Date();
  },
  CLEAR_CHECK_TIME: (state) => {
    state.last_check = null;
  },
  SET_NEED_CHANGE_PASSWORD: (state, need_change) => {
    state.need_change_password = need_change;
  },
  CLEAR_ERROR: (state) => {
    state.status = ''
    state.error = ''
  },
  SET_TOKEN: (state, token) => {
    state.token = token
    localStorage.setItem('user-token', token)
  },
  SET_USERNAME: (state, username) => {
    state.username = username
    localStorage.setItem('username', username)
  },
  SET_UID: (state, uid) => {
    state.uid = uid
    localStorage.setItem('user-uid', uid)
  },
  SET_LOADING: (state) => {
    state.loading = true
  },
  SET_NOT_LOADING: (state) => {
    state.loading = false
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
