<template>
  <v-container>
    <v-card  >
    <v-toolbar :extended='$vuetify.breakpoint.xsOnly'
               elevation="0"
               color="style-color-main-gray"
    >
      <v-text-field v-model="search"
                    dense
                    placeholder="Поиск по наименованию"
                    class="pa-3"
      ></v-text-field>
      <v-spacer></v-spacer>

    </v-toolbar>
  <v-data-table
    dense
    :headers="headers"
    :items="items.subjects"
    :search="search"
    :loading="load"
    fixed-header
    height='calc(100vh - 300px)'
    item-key="name"

    :footer-props="{'items-per-page-options':[ -1], }"
  >
    <template v-slot:header.zets="{ header }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">{{header.text}}</span>
        </template>
        <span>Общее количество зетов</span>
      </v-tooltip>
    </template>
    <template v-slot:header.hours="{ header }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on"> {{header.text}}</span>
        </template>
        <span>Общее количество часов</span>
      </v-tooltip>
    </template>
    <template v-slot:header.lecture_hours="{ header }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on"> {{header.text}}</span>
        </template>
        <span>Общее количество лекционных часов</span>
      </v-tooltip>
    </template>
    <template v-slot:header.laboratory_hours="{ header }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on"> {{header.text}}</span>
        </template>
        <span>Общее количество лабораторных часов</span>
      </v-tooltip>
    </template>
    <template v-slot:header.independent_hours="{ header }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on"> {{header.text}}</span>
        </template>
        <span>Общее количество часов самостоятельных занятий</span>
      </v-tooltip>
    </template>
    <template v-slot:header.practical_hours="{ header }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on"> {{header.text}}</span>
        </template>
        <span>Общее количество практических часов </span>
      </v-tooltip>
    </template>
    <template v-slot:header.coursework_hours="{ header }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on"> {{header.text}}</span>
        </template>
        <span>Общее количество курсовых часов </span>
      </v-tooltip>
    </template>
    <template v-slot:header.exam_hours="{ header }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on"> {{header.text}}</span>
        </template>
        <span>Общее количество экзаменационных часов </span>
      </v-tooltip>
    </template>
    <template v-slot:header.attestation_hours="{ header }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on"> {{header.text}}</span>
        </template>
        <span>Общее количество аттестационных часов </span>
      </v-tooltip>
    </template>
    <template v-slot:item.semester_number="{ item }">
      {{item.semester_number}} ({{item.semester}})
    </template>
    <template v-slot:item.cathedrals="props">
      <td class="text-left">
        <div v-for="(str,index) in props.item.cathedrals" class="align-start">
          {{str.name}}<span v-if="index!==props.item.cathedrals.length-1">;</span> <br>
        </div>
      </td>
    </template>
  </v-data-table>
  </v-card>
  </v-container>
</template>

<script>
import urls from "@/modules/curriculumEditor/urls";
import {loadData} from "@/helper";

export default {
name: "SummaryList",
    data() {
      return {
        title: 'Сводная таблица',
        items:[],
        search:'',
        load:true,
        curriculum_id: this.$route.params.curriculum_id,
        headers: [
          { text: 'Предмет', value: 'subject',   },
          { text: 'Кафедра', value: 'cathedrals', width: '15%' },
          { text: 'Курс', value: 'course' , width: '5%' },
          { text: 'Семестр', value: 'semester_number', width: '8%' },
          { text: 'Тип контроля', value: 'control_type' , width: '6%' },
          { text: 'ОЧ', value: 'hours' , width: '5%' },
          { text: 'ОЗ', value: 'zets' , width: '5%' },
          { text: 'ЛекЧ', value: 'lecture_hours' , width: '6%' },
          { text: 'ЛабЧ', value: 'laboratory_hours' , width: '6%' },
          { text: 'СЧ', value: 'independent_hours'  , width: '5%'},
          { text: 'ПЧ', value: 'practical_hours' , width: '5%'},
          { text: 'КЧ', value: 'coursework_hours' , width: '5%' },
          { text: 'ЭЧ', value: 'exam_hours'  , width: '5%'},
          { text: 'АЧ', value: 'attestation_hours' , width: '5%'},
        ],
      }
    },
  created() {
    let saver = (data) => {
      this.items  = data;
      this.$store.commit('mainpage/changeTitle',data.curriculum_name);
      this.load =false
    };
    let catcher = (error) => {
      console.log(error);
    };
    let url = urls.NAMES.EDITOR.CURRICULUM.SUMMARY(this.curriculum_id);
    loadData(url, saver, catcher);
  }
}
</script>

<style scoped>

</style>
