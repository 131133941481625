<template>
  <scroll-center-dialog v-model="opened_form"
                        @close="closeDialog"
                        @apply="save"
                        :title="!!id ? 'Изменить' : 'Добавить'"
                        width="500"
  >
    <v-text-field
      :error="hasError('title')"
      :error-messages="getError('title')"
      v-model="value.title"
      class="pa-1 ma-1"
    >
      <template v-slot:label>
        {{"Название"}}<span class="red--text">*</span>
      </template>
    </v-text-field>
    <v-textarea
      label="Описание"
      :error="hasError('description')"
      :error-messages="getError('description')"
      required
      v-model="value.description"
      class="pa-1 ma-1"
    >
    </v-textarea>
    <v-autocomplete
      label="Тип пройденной программы"
      :error="hasError('work_type')"
      :error-messages="getError('work_type')"
      :items="choices.work_type"
      v-model="value.work_type"
      class="pa-1 ma-1"
    ></v-autocomplete>
    <v-autocomplete
      label="Сфера"
      :error="hasError('knowledge_field')"
      :error-messages="getError('knowledge_field')"
      :items="choices.knowledge_type"
      v-model="value.knowledge_field"
      class="pa-1 ma-1"
    ></v-autocomplete>
    <v-autocomplete
      label="Тип документа"
      :error="hasError('document_type')"
      :error-messages="getError('document_type')"
      :items="choices.document_type"
      v-model="value.document_type"
      class="pa-1 ma-1"
    ></v-autocomplete>
    <v-text-field
      :error="hasError('number')"
      :error-messages="getError('number')"
      label="Номер документа"
      v-model="value.number"
      class="pa-1 ma-1"
    ></v-text-field>
    <v-text-field
      type="number"
      :error="hasError('volume')"
      :error-messages="getError('volume')"
      label="Объем пройденной программы"
      v-model="value.volume"
      class="pa-1 ma-1"
    ></v-text-field>
    <DateFieldWithPicker
      :max="new Date()"
      min="1950-01-01"
      label="Дата начала изучения программы"
      v-model="value.start"
      :error="hasError('start')"
      :error-messages="getError('start')"
    ></DateFieldWithPicker>
    <DateFieldWithPicker
      :max="new Date()"
      min="1950-01-01"
      label="Дата конца изучения программы"
      v-model="value.end"
      :error="hasError('end')"
      :error-messages="getError('end')"
    ></DateFieldWithPicker>

    <v-file-input
      v-if="file === ''"
      required
      class="pa-1 ma-1"
      :error="hasError('file')"
      :error-messages="getError('file')"
      v-model="value.file"
      accept="application/pdf"
      :messages="file"
    >
      <template v-slot:label>
        {{"Документ"}}<span class="red--text">*</span>
      </template>
    </v-file-input>

    <v-text-field
      v-else
      class="pa-1 ma-1"
      clearable
      readonly
      v-model="text"
      @click:clear="file = ''"
      @click="openFile"
      @click:append="openFile"
      append-icon="file_open"
      prepend-icon="attach_file"
    ></v-text-field>
    <v-alert v-if="hasError('user')" type="error">{{getError('user')[0]}}</v-alert>
  </scroll-center-dialog>
</template>

<script>

  import ScrollCenterDialog from "@/modules/core/components/ScrollCenterDialog";
  import DateFieldWithPicker from "@/modules/core/components/DateFieldWithPicker";
  export default {
    name: "Courses",
    components: {ScrollCenterDialog, DateFieldWithPicker},
    props: {
      id:{
        type:Number ,
      },
      title:{
        type:String,
        default:''
      },
      description:{
        type:String,
        default:''
      },
      work_type:{
        type:String,
        default:''
      },
      knowledge_field:{
        type:String,
        default:''
      },
      document_type:{
        type:String,
        default:''
      },
      number:{
        type:String,
        default:''
      },
      volume:{
        type:Number,
        default: 0
      },
      start:{
        type:String,
        default:''
      },
      end:{
        type:String,
        default:''
      },
      file:{
        type:String,
        default:''
      },
      errors:{
        type:Object,
      },
      opened_form: Boolean,
      choices:{
        type:Object,
      }
    },

    data() {
      return {
        value: {
          id: this.id || '',
          title: this.title || '',
          description: this.description || '',
          work_type: this.work_type || 'Иное',
          knowledge_field: this.knowledge_field || 'Иное',
          document_type: this.document_type || 'Иное',
          number: this.number || '',
          volume: this.volume || 0,
          start: this.start || '',
          end: this.end || '',
          file: null,
        },
        text: this.title + ' (Нажмите чтобы открыть)',
      }
    },
    methods: {
      hasError: function (field) {
        if (
          field in this.errors
          && this.errors[field] !== undefined
          && this.errors[field] !== null
        )
          if (this.errors[field].length > 0)
            return true;
        return false;
      },
      getError: function (field) {
        if (
          field in this.errors
          && this.errors[field] !== undefined
          && this.errors[field] !== null
        )
          if (this.errors[field].length > 0)
            return this.errors[field];
        return null;
      },
      save(){
        this.$emit('createItem', this.value);
      },
      openFile(){
        window.open(this.file)
      },
      closeDialog() {
        this.$emit('ended', '')
      },
    },
    created() {
    }
  }

</script>

<style scoped>

</style>
