<template>
  <v-card class="text-left" v-if="$route.name === names.TRANSFER.DETAIL_TO">
    <v-card-text class="title" v-if="detail">
      <v-dialog  v-model="opened_form" width="700" persistent>
        <v-card>
          <v-toolbar color="primary" dark>
            <v-toolbar-title>
              <span>Отменить</span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-toolbar-items>
                <v-btn @click="opened_form = false" class="pa-0 ma-0" dark text icon>
                  <v-icon>close</v-icon>
                </v-btn>
              </v-toolbar-items>
            </v-toolbar-items>
          </v-toolbar>
          <v-card-text>
            <v-text-field v-model="sendParamForm.comment_to" label="Комментарий"></v-text-field>
            <v-btn @click="rejectTransfer">Отклонить запрос</v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>
      <TransferTemplate :detail="detail" :journal="journal" :loading="loading" :save_process="save_process">
        <template v-slot:actions>
          <v-btn text v-if="detail.status==='инициирована'" color="green" style="" @click="findDiffrence()">Принять заявку</v-btn>
          <v-btn v-if="detail.status==='инициирована'" text color="red" @click=" opened_form = true">Отклонить заявку</v-btn>
        </template>
      </TransferTemplate>
    </v-card-text>
  </v-card>
  <router-view :key="$route.fullPath" v-else></router-view>
</template>

<script>
import DebugJsonComponent from "../../../core/components/DebugJsonComponent";
import {generateUidToName} from "@/helper/uidToName"
import urls from "@/modules/dean/urls";
import {displayDate, loadData, makePostRequest} from "@/helper";
import JournalTable from "@/modules/cathedra/pages/journal/JournalTable";
import names from "@/modules/dean/routers/names"
import dean from "@/urls/dean";
import TransferTemplate from "@/modules/dean/pages/transferStudents/TransferTemplate";

export default {
  name: "TransferDetail",
  components: {
    TransferTemplate,
    DebugJsonComponent,
    JournalTable
  },
  data() {
    return {
      names:names,
      detail: null,
      education_group: null,
      difference: null,
      sendParamForm:{
        comment_to: '',
        group:null
      },
      journal: [],
      typejournal:1,
      search: '',
      saved_data: undefined,
      selected: {},
      loading: false,
      save_process: {
        execute: false,
        success: undefined,
        errors: [],
        alert: {
          visible: false,
          message: ''
        },
      },
      opened_form: false,
      selectors: {
        group: []
      }
    }
  },
  watch: {
    typejournal: function (val) {
      this.loadJournal()
    },
  },
  methods: {
    loadJournal() {
      loadData(
        dean.DEAN.TRANSFER.INCOMING.JOURNAL(this.$route.params.idFaculty, this.$route.params.idDetail, this.typejournal),
        // urls.getUrl('dean|students|education|transfer|to-journal', 'journal').replace('<id>', this.$route.params.idDetail).replace('<faculty_uid>', this.$route.params.idFaculty)+'?from='+this.typejournal,
        (data) => {
          this.journal = data;
          this.journal.forEach(el => {
            this.$set(el, 'old_exam', el.exam);
            el.medium_control.forEach(el => {
              this.$set(el, 'old_value', el.value)
            });
          });
          // this.attachment = data.url.attachment;
        }
      )
    },
    rejectTransfer() {
      let url = dean.DEAN.TRANSFER.INCOMING.REJECT(this.$route.params.idFaculty, this.$route.params.idDetail);
        // urls.getUrl('dean|students|education|transfer|to-reject-transfer', 'reject_transfer')
        // .replace('<faculty_uid>', this.$route.params.idFaculty)
        // .replace('<id>', this.$route.params.idDetail);

      let data = {comment_to: this.sendParamForm.comment_to}
      let sys = true
      makePostRequest(url, data).then(resp => {
        if (!resp.ok) {
          sys = false
        }
        return resp.json()
      }).then(json => {
        if (sys){
          this.opened_form=false
          this.loadDetail()
        }

      })

    },
    setDate(date) {
      return displayDate(date)
    },
    findDiffrence() {
      this.$router.push({name:names.TRANSFER.APPROVE})
    },

    loadDetail: function () {
      let saver = (data) => {
        this.detail = data;
        generateUidToName(this.detail, 'student', 'student')
        this.loadJournal()
      };
      let catcher = (error) => {
        console.log(error);
      };
      let url = dean.DEAN.TRANSFER.INCOMING.DETAIL(this.$route.params.idFaculty, this.$route.params.idDetail)
        // urls.getUrl('dean|students|education|transfer|to-detail', 'retrieve')
        // .replace('<faculty_uid>', this.$route.params.idFaculty)
        // .replace('<id>', this.$route.params.idDetail);
      loadData(url, saver, catcher);
    },
  },
  created() {
    this.loadDetail()
  }
}
</script>

<style scoped>

</style>
