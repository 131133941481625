<template>
  <v-container>
    <v-card class="pa-3">
      <v-row v-if="EducationWorks">
        <v-col cols="12" md="6" class="px-0 py-0 pr-md-0" >
          <v-toolbar flat color="style-color-main-gray" class="subtitle-1">
            <v-icon>
              account_box
            </v-icon>
            Обучающийся: {{EducationWorks.fio_student}}
          </v-toolbar>
          <v-card-text :style="{'border-right': $vuetify.breakpoint.mdAndUp ? '1px solid #AAAAAA': 'none'}">
            <v-row no-gutters class="text-start subtitle-1 py-1">
              <v-col cols="6">Предмет:</v-col>
              <v-col cols="6">{{EducationWorks.semester.subject}}</v-col>
            </v-row>
            <v-row no-gutters class="text-start subtitle-1 py-1">
              <v-col cols="6">Семестр:</v-col>
              <v-col cols="6">{{EducationWorks.semester.semester}}</v-col>
            </v-row>
            <v-row no-gutters class="text-start subtitle-1 py-1">
              <v-col cols="6">Курс:</v-col>
              <v-col cols="6">{{EducationWorks.semester.course}}</v-col>
            </v-row>
            <v-row no-gutters class="text-start subtitle-1 py-1">
              <v-col cols="6">Название работы:</v-col>
              <v-col cols="6">{{EducationWorks.document.name}}</v-col>
            </v-row>
            <v-row no-gutters class="text-start subtitle-1 py-1">
              <v-col cols="6">Описание работы:</v-col>
              <v-col cols="6">{{EducationWorks.subtitle}}</v-col>
            </v-row>
            <v-row no-gutters class="text-start subtitle-1 py-1">
              <v-col cols="6">Тип документа:</v-col>
              <v-col cols="6">{{EducationWorks.type}}</v-col>
            </v-row>
          </v-card-text>
        </v-col>
        <v-col cols="12" md="6" class="px-0 py-0 pl-md-0">
          <v-toolbar flat color="style-color-main-gray">
            <v-icon>
              account_box
            </v-icon>
            Проверил: {{EducationWorks.fio}}
          </v-toolbar>
          <v-card-text>
            <v-row no-gutters class="text-start subtitle-1 py-1">
              <v-col cols="6">Дата загрузки:</v-col>
              <v-col cols="6">{{displayDate(EducationWorks.date)}}</v-col>
            </v-row>
            <v-row no-gutters class="text-start subtitle-1 py-1">
              <v-col cols="6">Статус проверки:</v-col>
              <v-col cols="12" md="6" >
                <span v-if="EducationWorks.accept === null">Работа еще не проверялась</span>
                <span v-else-if="EducationWorks.accept ">Работа  проверена и принята</span>
                <span v-else >Работа проверена и не принята</span>
              </v-col>
            </v-row>
            <v-row v-if="EducationWorks.accept" no-gutters class="text-start subtitle-1 py-1">
              <v-col cols="6">Дата проверки:</v-col>
              <v-col cols="6">{{ displayDate(EducationWorks.accepeted)}}</v-col>
            </v-row>
            <v-row no-gutters class="text-start subtitle-1 py-1">
              <v-col cols="6">Документ  :</v-col>
              <v-col cols="12" md="6" v-if="EducationWorks.document">
                {{EducationWorks.document.file.file_name}} <v-btn @click="loadFileWork(EducationWorks.document.file)" icon><v-icon >get_app</v-icon></v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-col>
      </v-row>
      <slot name="append_body"></slot>
    </v-card>
  </v-container>

</template>

<script>

    import moment from "moment";
    import {loadFile} from "@/helper/fileLoader"

    export default {
        name: "DetailEducationWorks",
        props: {
          EducationWorks: {
            required: true,
            type: Object
          }
        },
        methods: {
          loadFileWork(url){
            loadFile(url)
          },
          displayDate: function (date, minutes = false) {
            let format = 'DD.MM.YYYY'
            if (minutes)
              format += ' HH:mm'
            return moment(date).format(format)
          },
        },

    }
</script>

<style scoped>

</style>
