import List from "../pages/semesters/Base";
import Detail from "../pages/semesters/Detail";
import names from "@/modules/curriculumEditor/routers/names";

const education = String(process.env.VUE_APP_EDUCATION_PREFIX).replace('/', '');

export default ([
  {
    path: 'semesters',
    // name: 'CurriculumEditorSemestersList',
    name: names.CURRICULUM.SEMESTERS.LIST,//'CurriculumEditorSemestersList',
    component: List,
    meta: {
      requiredAuth: true,
      breadcrumb: 'Список семестров',
      instances: [education,],
    },
    children: [
      {
        path: ':semester_id([0-9]+)',
        // name: 'CurriculumEditorSemestersDetail',
        name: names.CURRICULUM.SEMESTERS.DETAIL,//'CurriculumEditorSemestersDetail',
        component: Detail,
        meta: {
          requiredAuth: true,
          breadcrumb: 'Семестр',
          instances: [education,],
        },
        children: []
      },
    ]
  },
])


