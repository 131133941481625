<template>
  <v-card class="mt-2 " flat>
    <v-card-title class="style-color-main-gray title">
     <span   style="word-break: break-word!important;">Информация об образованиях:</span>
    </v-card-title>
    <v-card-text :key="idx" v-for="(item, idx) in educations">
      <EducationTemplate :education="item" :title="true" :endDivider="true"></EducationTemplate>
    </v-card-text>
  </v-card>
</template>

<script>
  import EducationTemplate from "./EducationTemplate";
  export default {
    name: "EducationListTemplate",
    components: {EducationTemplate,},
    props: {
      educations: Array
    },
  }
</script>

<style scoped>

</style>
