<template>
  <div>
    <tooltip-btn tooltip="Полная информация" icon="info" small bottom @click="openDialog(detail)"
                 v-if="isAdmin" :loading="loading"></tooltip-btn>
    <tooltip-btn tooltip="Указать связь" icon="link" small bottom @click="openDialog(link)"
                 v-if="!isLinked" :loading="loading"></tooltip-btn>
    <tooltip-btn tooltip="Очистить связь" icon="link_off" small bottom @click="openDialog(clear)"
                 v-if="isLinked" :loading="loading"></tooltip-btn>
    <tooltip-btn tooltip="Обновить данные" icon="update" small bottom @click="openDialog(update_related)"
                 v-if="isLinked" :loading="loading"></tooltip-btn>
    <tooltip-btn tooltip="Обновить идентификатор связи у связанных" small icon="dataset_linked" bottom :loading="loading"
                 @click="openDialog(update_relation_identity)" v-if="isLinked"></tooltip-btn>
    <tooltip-btn tooltip="Создать объект" icon="add_circle" small bottom :loading="loading"
                 @click="openDialog(create_related)" v-if="!isLinked"></tooltip-btn>
    <tooltip-btn tooltip="Обновить информацию" icon="refresh" small bottom :loading="loading"
                 @click="refresh" v-if="isLinked"></tooltip-btn>
    <slot name="default"></slot>
    <detail v-model="detail.open" :item="item"></detail>
    <link-action
      v-if="!isLinked"
      v-model="link.open"
      :item="item"
      :selector-url="actionUrls.link.selector_url"
      :action-url="actionUrls.link.action_url"
      @reload="refresh"
      preloadable
    >
    </link-action>
    <action-for-object
      v-if="isLinked"
      v-model="update_relation_identity.open"
      level="subject"
      :action-url="actionUrls.update_relation_identity"
      action-name="обновления информации о связи в ЭИОС по дереву объектов"
      :item="item"
      :object-id="itemId"
      :type-name="typeName"
      :object-name="objectName"
      @reload="refresh"
    ></action-for-object>
    <update-related
      v-if="isLinked"
      v-model="update_related.open"
      :action-url="actionUrls.update_related"
      :item="item"
      @reload="refresh"
    ></update-related>
    <action-for-object
      v-if="isLinked"
      v-model="clear.open"
      :action-url="actionUrls.clear"
      action-name="очистка информации о связи в ЭИОС и синхронизаторе с 1С Университет по дереву объектов"
      :object-id="itemId"
      :type-name="typeName"
      :object-name="objectName"
      :item="item"
      @reload="refresh"
      applyable
    >
      <template v-slot:title>Очистка информации о связях в дереве</template>
    </action-for-object>
    <action-for-object
      v-if="!isLinked"
      v-model="create_related.open"
      :item="item"
      action-name="Создание объекта ЭИОС на основании объекта 1С"
      :object-id="itemId"
      :type-name="typeName"
      :object-name="objectName"
      :action-url="actionUrls.create_related"
      @reload="refresh"
    ></action-for-object>
  </div>
</template>

<script>
import ActionForObject from "@/modules/administrative/dialogs/ActionForObject.vue";
import TooltipBtn from "@/modules/administrative/components/TooltipBtn.vue";
import UpdateRelated from "@/modules/administrative/dialogs/UpdateRelated.vue";
import LinkAction from "@/modules/administrative/dialogs/LinkAction.vue";
import Detail from "@/modules/administrative/dialogs/Detail.vue";
import DifferenceItemMixin from "@/modules/administrative/mixins/DifferenceItemMixin";
import admin_urls from "@/urls/admin";

export default {
  name: "RefrenceActions",
  components: {TooltipBtn, ActionForObject, UpdateRelated, LinkAction, Detail},
  props: {
    actionUrls: {
      required: true,
      type: Object
    },
    item: {
      required: true,
      type: Object
    },
    typeName: {
      required: true,
      type: String
    },
    objectName: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      loading: false,
      link: {
        open: false,
      },
      update_related: {
        open: false,
      },
      update_relation_identity: {
        open: false,
      },
      clear: {
        open: false,
      },
      create_related: {
        open: false,
      },
      detail: {
        open: false,
      }
    }
  },
  computed: {
    isLinked: function () {
      return this.null !== null
    },
    isAdmin: function () {
      return admin_urls.ADMIN.COURSE.IS_LIST_ALLOWED()
    },
    itemId: function () {
      return this.item.guid
    }
  },
  methods: {
    refresh() {
      this.$emit('refresh')
    },
    openDialog(modal) {
      modal.open = true
    },
  }
}
</script>

<style scoped>

</style>
