<template>
  <v-container style="height: 100%">
    <v-card flat>
      <v-toolbar
        elevation="0"
        color="style-color-main-gray"
      >
        <span>Список обучающихся:</span>
        <v-spacer></v-spacer>
        <v-text-field
          dense
          placeholder="Поиск"
          v-model="search.value"
          @keypress.enter="searchData"
          messages="Для поиска нажмите Enter или кнопку поиск"
        >
        </v-text-field>
        <v-btn icon><v-icon>search</v-icon></v-btn>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              @click=" loadData()"
              class="mr-2"
              medium
            >
              update
            </v-icon>
          </template>
          <span>Обновить данные</span>
        </v-tooltip>
      </v-toolbar>

      <v-card-text class="pb-3 px-0">
        <v-row justify-center no-gutters>
          <v-col>
            <v-data-table
              :headers="headers"
              :items="student.items"
              :loading="student.loading"
              class="elevation-1"
              no-data-text="Нет данных"
              :page.sync="pagination.page"
              :items-per-page.sync="pagination.size"
              :server-items-length="pagination.count"
              :footer-props="{'items-per-page-options':[10,25,50], 'footer-props.items-per-page-text': 'Число элементов'}"
            >
              <template v-slot:item="props">
                <tr class="text-left">
                  <td class="justify-center">
<!--                    <router-link  :to="{ name: names.STUDENT.DETAIL,params:{idFaculty: $route.params.idFaculty,idStudent:props.item.id}}">-->
                      <v-card-text v-if="props.item.fio !== '-'">{{ props.item.fio}}</v-card-text>
                      <v-skeleton-loader v-else type="list-item" class="mx-auto"></v-skeleton-loader>
<!--                    </router-link>-->
                  </td>

                  <td class="justify-center">{{props.item.cathedra}}</td>
                  <td class="justify-center">
                    <v-icon v-if="props.item.headman">verified_user</v-icon>
                  </td>
                </tr>
              </template>
              <template slot="no-data">
                <v-card-text>Данные не загружены</v-card-text>
              </template>
            </v-data-table>

          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>


<script>

  import UserDataMixin from '../../../../../../mixins/UserDataMixin'
  import {loadData, makeGetRequest} from "../../../../../../helper";
  import urls from "@/urls/dean";
  import {generateListUidToName} from "../../../../../../helper/uidToName";
  import names from "../../../../routers/names";
  import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
  import TitledPageMixin from "@/mixins/TitledPageMixin";

  export default {
    name: "Groups",
    // components: {Courses},

    mixins: [PaginatedDataMapperMixin, TitledPageMixin],
    props: {
    },
    data () {
      return {
        URL_LIST: urls.DEAN.CATHEDRA.TEACHER.STUDENT.LIST(this.$route.params.uidDepartament, this.$route.params.uidTeacher),
        names:names,
        page_title: 'Список обучающихся',
        search: {
          parameter: 'fio',
        },
        student: {
          items: [],
          loading: true,
          loaded: false,
        },
        headers: [
          {text: 'Обучающийся', sortable: false, align: 'justify-center',},
          {text: 'Кафедра', sortable: false, align: 'justify-center', },
          {text: 'Староста', sortable: false, align: 'justify-center', },
        ]
      }
    },

    created() {
      this.loadData();
    },
    methods: {
      loadData (url=undefined) {
        this.student.loading = true;
        if(url === undefined)
          url = this.URL_LIST;
        loadData(
          url,
          (data) => {
            this.student.loaded = true;
            this.student.items = data.results;
            // pagination(data, url);
            this.mapPagination(data) ;
            this.setCurrentLoadUrl(url);
            this.student.items.forEach(el => {
              this.$set(el, 'fio', '');
            })
            generateListUidToName(this.student.items,"student","fio", () => this.student.loading = false)
          }
        )
      },
    }
  }


</script>
<style scoped>

</style>
