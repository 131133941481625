<template>
  <v-container v-if="load">
    <v-card class="mx-1">
      <v-toolbar
        class="mb-2"
        color="style-color-main-gray"
        flat
      >
        <v-row>
          <v-col cols="3">
            <v-autocomplete
              class="mt-6"
              v-if="selectors.year_semesters"
              label="Семестр"
              :items="selectors.year_semesters"
              v-model="semester_id"
            >
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-toolbar>
      <div class="px-2 pt-2 pb-1">
        <v-alert outlined type="info" border="left">
          <div class="text-justify">
            Если к семестру учебного плана привязано больше одной кафедры, необходимо распределить обучающихся по кафедрам, преподающим указанные предметы.
          </div>
        </v-alert>
      </div>
<!--      <DebugJsonComponent :json="students"></DebugJsonComponent>-->
      <template v-if="semesters">
        <v-expansion-panels v-model="panel">
          <v-expansion-panel
            v-for="item in this.semesters"
            :key="item.value"
          >
            <v-expansion-panel-header>
              <template v-slot:default="{open}">
                <v-toolbar-title>
                <v-row class="pt-3">
                  <v-col cols="10">
                      {{item.text}}
                  </v-col>
                  <v-col cols="2">
                    <v-btn @click.native.stop :disabled="!open || !load_cathedrals || !load_semester" :loading="load_save" color="primary" dark class="mb-2" @click="SaveChanges">
                      Сохранить
                    </v-btn>
                  </v-col>
                </v-row>
                </v-toolbar-title>
              </template>
              <template v-slot:actions>
                <v-btn icon>
                  <v-icon color="primary">
                    $expand
                  </v-icon>
                </v-btn>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-data-table
                v-if="load_semester && load_cathedrals"
                :headers="headers"
                :items="semester_cathedrals"
                no-data-text="Нет данных"
                :items-per-page="semester_cathedrals.length"
                hide-default-footer
              >
                <template v-slot:item.student="props">
                  <div class="pt-6">
                    <span> {{props.item.fio}}</span>
                  </div>
                </template>
                <template  v-slot:item.cathedra="props" >
                  <v-autocomplete
                    v-model="props.item.cathedra"
                    :items="selectors.cathedrals"
                    hide-details
                  >
                  </v-autocomplete>
                </template>
              </v-data-table>
              <v-progress-linear
                v-else
                indeterminate
                color="primary"
              ></v-progress-linear>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </template>
      <div v-else class="px-2 pt-2 pb-1">
        <v-alert outlined type="info" border="left">
          <div class="text-justify">
            У данной учебной группы нет предметов c несколькими кафедрами.
          </div>
        </v-alert>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import names from "@/modules/dean/routers/names"
import LeftMenuChangerMixin from "@/mixins/LeftMenuChangerMixin"
import TitledPageMixin from "@/mixins/TitledPageMixin"
import DebugJsonComponent from "../../../core/components/DebugJsonComponent"
import dean from "@/urls/dean"
import selectors from "@/urls/selectors"
import {loadData, sendPostRequest} from "@/helper"
import {generateListUidToName} from "@/helper/uidToName"
import UserDataFormMixin from "@/mixins/UserDataFormMixin"

export default {
  name: "Base",
  mixins: [TitledPageMixin, LeftMenuChangerMixin, UserDataFormMixin,],
  components: {DebugJsonComponent},
  data() {
    return {
      page_title: 'Привязка к кафедрам обучающихся  группы',
      load: false,
      semesters: [],
      students_list: [],
      semester_cathedrals: {},
      selectors:{
        cathedrals: [],
        year_semesters: [],
      },
      semester_id: null,
      year_semester: null,
      panel: [],
      old_panel: null,
      load_semester: false,
      load_cathedrals: false,
      load_save: false,
      errors: [],
      headers: [
        {text: "ФИО", value: 'student', sortable: false, align: 'start'},
        {text: "Выбранная кафедра", value: 'cathedra', sortable: false, align: 'start'},
      ],
    }
  },
  watch: {
    panel() {
      if(this.panel!= undefined && this.panel != this.old_panel) {
        this.load_semester = false
        this.load_cathedrals = false
        this.loadStudentSemesterCathedrals(this.semesters[this.panel].value)
        this.loadCathedrals(this.semesters[this.panel].value)
        this.old_panel = this.panel
      }
    },
    semester_id() {
      this.semesters = null
      loadData(
        selectors.SELECTORS.DEAN.MULTI_CATHEDRA_SEMESTER(this.$route.params.idGroup, {semester_of_year: this.semester_id}),
        (data) => {
          this.semesters = data
        }
      )
    }
  },
  methods: {
    getLeftMenu(){
      return [
        {
          title: 'Группа',
          icon: 'arrow_back_ios',
          included: false,
          router: {
            name: names.GROUP.DETAIL,
            params: {
              group_id: this.$route.params.idGroup,
              idFaculty: this.$route.params.idFaculty,
              taught: this.$route.params.taught,
              reload_left_menu: true,
            },
            query: this.$route.query,
          }
        },
      ]
    },
    loadYearSemesterSelector(){
      loadData(
        selectors.SELECTORS.DEAN.YEAR_SEMESTER(this.$route.params.idGroup),
        (data) => {
          this.selectors.year_semesters = data
        }
      )
    },
    loadSelector(){
      loadData(
        selectors.SELECTORS.DEAN.MULTI_CATHEDRA_SEMESTER(this.$route.params.idGroup),
        (data) => {
          this.semesters = data
          this.loadStudents()
        }
      )
    },
    loadStudents(){
      let url = dean.DEAN.GROUP.STUDENTS.LIST(this.$route.params.idFaculty, this.$route.params.idGroup,)
      loadData(
        url,
        (data) => {
          this.students_list = data.filter(el => el.active)
          this.load = true
        })
    },
    loadStudentSemesterCathedrals(semester){
      let url = dean.DEAN.GROUP.STUDENT_SEMESTER_CATHEDRALS(this.$route.params.idFaculty, this.$route.params.idGroup,
        {
          group_semester: semester,
        })
      loadData(
        url,
        (data) => {
          this.semester_cathedrals = data
          this.semester_cathedrals.forEach(el => {
            this.$set(el, 'education', '')
            this.$set(el, 'old_cathedra', '')
              el.old_cathedra = el.cathedra
            let idx = this.students_list.findIndex(el1 => el1.user === el.student)
            if(idx !== -1) {
              el.education = this.students_list[idx].id
            }
          })
          this.loadStudentFios(this.semester_cathedrals)
        })
    },
    loadStudentFios(students, finalizer=()=>{}){
      students.forEach(el => {
        this.$set(el, 'fio', '')
      })
      generateListUidToName(students, 'student', 'fio', () => {
        students.sort((a,b) => a.fio.toUpperCase() > b.fio.toUpperCase() ? 1: -1)
        finalizer()
        this.load_semester = true
      })
    },
    loadCathedrals(semester){
      loadData(
        selectors.SELECTORS.DEAN.GROUP_SEMESTER_CATHEDRALS(semester),
        (data) => {
          this.selectors.cathedrals = data
          this.load_cathedrals = true
        }
      )
    },
    SaveChanges() {
      this.load_save = true
      let cathedra_students = []
      for (let obj in this.semester_cathedrals){
        if(this.semester_cathedrals[obj].old_cathedra != this.semester_cathedrals[obj].cathedra){
          cathedra_students.push({education : this.semester_cathedrals[obj].education, cathedra : this.semester_cathedrals[obj].cathedra})
        }
      }
      if(cathedra_students.length != 0 ) {
        let url = dean.DEAN.GROUP.UPDATE_STUDENT_CATHEDRALS(this.$route.params.idFaculty, this.$route.params.idGroup)
        let data= {
          group_semester: this.semesters[this.panel].value,
          students:  cathedra_students
        }
        sendPostRequest(url,data,
          ()=>{
            this.loadStudentSemesterCathedrals(this.semesters[this.panel].value)
            this.load_save  = false
          },
          (error)=>{
            this.receiveErrors(error)
            this.load_save  = false
          }
        )
      }
    }
  },
  created() {
    this.setPageTitleWithObject("Group", {id: this.$route.params.idGroup}, ' ', null, (value) => {
      this.nameGroup = value
    })
    this.loadSelector()
    this.loadYearSemesterSelector()
  },
}
</script>

<style scoped>
.v-expansion-panel-header{
  pointer-events: none;
}
.v-btn{
  pointer-events: All;
}
</style>
