<template>
  <v-container v-if="$route.name === names.ADMISSIONS_REQUEST.LIST">
    <v-card >
      <v-toolbar
        class="mb-2"
        color="style-color-main-gray"
        flat
      >
        <template v-if="$vuetify.breakpoint.mdAndUp" >
          <v-text-field class="pa-6"
                        dense
                        placeholder="Поиск"
                        v-model="search.value"
                        @keypress.enter="searchData"
                        @click:clear="clearSearchData"
                        clearable
                        messages="Для поиска нажмите Enter"
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-select class="py-0 pa-6" :items="selectors.status" v-model="pagination.filters.status"
          ></v-select>
          <v-autocomplete class="py-0 pa-6" :items="selectors.subject" v-model="pagination.filters.subject_id"
          ></v-autocomplete>
        </template>

        <template v-slot:extension v-if='$vuetify.breakpoint.smAndDown'>
          <v-col>
            <v-text-field
              class="pt-6"
              dense
              placeholder="Поиск"
              v-model="search.value"
              @keypress.enter="searchData"
              @click:clear="clearSearchData"
              clearable
              messages="Для поиска нажмите Enter"
            ></v-text-field>
            <v-select class="py-0 pa-6" :items="selectors.subject" v-model="pagination.filters.subject_id"
            ></v-select>
            <v-autocomplete  :items="selectors.status" v-model="pagination.filters.status"
            ></v-autocomplete>
          </v-col>
        </template>
      </v-toolbar>
          <v-data-table
            :headers="headers"
            :loading="pagination.loading"
            :page.sync="pagination.page"
            :items-per-page.sync="pagination.size"
            :server-items-length="pagination.count"
            :footer-props="{'items-per-page-options':[10,25,50]}"
            :items="items"
          >
            <v-progress-linear v-slot:progress color="blue" indeterminate></v-progress-linear>
            <template v-slot:item.subject_name="props">
              <span style="text-decoration: underline; cursor: pointer" @click="getDetail(props.item)">{{props.item.semester.subject_name}}</span>
            </template>
            <template v-slot:item.rating="props">
              <span>{{props.item.semester.rating}}</span>
            </template>
            <template v-slot:item.rating_in_semester="props">
              <span>{{props.item.semester.rating_in_semester}}</span>
            </template>
            <template v-slot:item.control_type="props">
              <span>{{props.item.semester.control_type}}</span>
            </template>
            <template v-slot:item.date_create="props">
              <span>{{displayDate(props.item.date_create,true)}}</span>
            </template>
            <template v-slot:item.accepted="props">
              <v-icon v-if="props.item.accepted">done</v-icon>
              <v-icon v-else-if="props.item.accepted !== null">clear</v-icon>
              <span v-else>Не рассмотрен</span>
            </template>
            <template v-slot:item.semester="props">
              <span style="text-decoration: underline; cursor: pointer" @click="getDetail(props.item)">{{props.item.semester}}</span>
            </template>
            <template slot="no-data">
              <v-card-text>Данные не загружены</v-card-text>
            </template>
          </v-data-table>
    </v-card>

  </v-container>
  <router-view :key="$route.fullPath" v-else></router-view>
</template>

<script>
    import moment from "moment"
    import selectors from "@/urls/selectors";
    import urls from "@/urls/dean";
    import UserDataMixin from '@/mixins/UserDataMixin'
    import {addGetParameters, loadData, makeGetRequest,} from "@/helper";
    import names from "@/modules/dean/routers/names";
    import TitledPageMixin from "@/mixins/TitledPageMixin";
    import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
    import {generateListUidToName} from "@/helper/uidToName";

    export default {
      name: "ListAdmissions",

      mixins: [
        TitledPageMixin,
        PaginatedDataMapperMixin,
        UserDataMixin],
      components: { },

      data () {
        return {
          search: {
            parameter: 'fio',
          },
          // status:'Все статусы',
          // subject:null,
          selectors:{
            subject:[],
            status:[
              {text:'Все статусы',value:null},
              {text:'Приняты',value:'true'},
              {text:'Не приняты',value:'false'},
              {text:'Не рассмотрены',value:'null'},
            ],
          },
          page_title: 'Список заказанных допусков',
          total: 0,
          names:names,
          items: [],
          loading: true,
          pagination: {
            filters: {
              subject_id: null,
              status: null,
            }
          },
          URL_LIST: urls.DEAN.ADMISSIONS_REQUEST.LIST(this.$route.params.idFaculty),
          headers: [
            {text: 'Предмет', sortable: false, align: 'left', value: 'subject_name',},
            {text: 'Рейтинг в семестре', sortable: false, align: 'left', value: 'rating_in_semester',},
            {text: 'Рейтинг', sortable: false, align: 'left', value: 'rating',},
            {text: 'Контроль', sortable: false, align: 'left', value: 'control_type',},
            {text: 'Создано', sortable: false, align: 'left', value: 'date_create',},
            {text: '№ зачетки', sortable: false, align: 'left', value: 'record_book_number',},
            {text: 'Обучающийся', sortable: false, align: 'left', value: 'fio_student',},
            {text: 'Группа', sortable: false, align: 'left', value: 'group',},
            {text: 'Статус', sortable: false, align: 'left', value: 'accepted',},
          ]
        }
      },
      created() {
        this.loadSelector();
      },
      beforeRouteUpdate(to, from, next) {
        if(to.name === names.ADMISSIONS_REQUEST.LIST)
        this.loadSelector();
        next()
      },
      watch: {
      },
      methods: {
        displayDate: function (date, minutes = false) {
          let format = 'DD.MM.YYYY'
          if (minutes)
            format += ' HH:mm'
          return moment(date).format(format)
        },
        loadSelector(){
          loadData(
            selectors.URLS.SEMESTERS(),
            (data) => {
              this.selectors.subject=data
              this.selectors.subject.unshift({text:'Все предметы',value:null},)
              this.loadData();
            }
          )
        },
        loadData(url = undefined) {
          this.setLoading();
          if(!url) {
            url = this.addFiltersParameter(this.URL_LIST);
            this.setCurrentLoadUrl(url);
          }
          loadData(
            url,
            (data) => {
              this.mapResults(data, 'items')
              this.items.forEach(el => {
                this.$set(el, 'fio_student', '');
              });
              generateListUidToName(this.items, 'student', 'fio_student');
              this.setNotLoading();
            }
          )
        },
        getItems (url = undefined) {
          this.loadData(this.getCurrentLoadUrl());
          this.setCurrentLoadUrl(url, true);
        },
        getDetail(item){
            this.$router.push({name: names.ADMISSIONS_REQUEST.DETAIL, params: {idAdmission: item.id}})
        },

        addItem(){
          this.closedForm()
          this.getItems();
        },

      }
    }


</script>

<style scoped>

</style>
