<template>
  <v-container>
    <CurriculumTemplate v-if="curriculum.loaded"
                        :curriculum="curriculum.data.curriculum"
                        :is_dean="is_dean"
                        :is_individual="curriculum.data.is_individual"
    >
    </CurriculumTemplate>
    <template v-if="curriculum.data.curriculum!==undefined && !curriculum.data.curriculum.is_system">
      <v-toolbar   color="style-color-main-gray" flat>
        <v-toolbar-title>
          Список дисциплин учебного плана
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn @click="opened_subject_form = true" color="secondary" text>
          Добавить предмет
        </v-btn>
      </v-toolbar>
      <SubjectList  v-if="curriculum.loaded && group_loaded" :group_id="group_id" :level="curriculum.data.curriculum.level"></SubjectList>
    </template>
    <v-dialog @input="closedForm()" v-model="opened_form" width="800" persistent>
      <CuriculumForm
            @ended="closedForm()"
            @refreshItem="loadCurriculum()"
            :curriculum="curriculum.data"
            v-if="opened_form"
      >
      </CuriculumForm>
    </v-dialog>
    <OptionallySubjectAddForm
      @ended="closedForm"
      v-bind:opened_form="opened_subject_form"
      v-if="opened_subject_form"
    >
    </OptionallySubjectAddForm>
<!--    <DebugJsonComponent :json="curriculum"></DebugJsonComponent>-->
  </v-container>
</template>

<script>
import urls from "@/urls/dean"
import {loadData, checkFieldTrue} from "@/helper";
import DebugJsonComponent from "@/modules/core/components/DebugJsonComponent";
import CurriculumTemplate from "@/modules/templates/curriculum/CurriculumTemplate";
import SubjectList from "@/modules/dean/pages/student/curriculum/SubjectList";
import TitledPageMixin from "@/mixins/TitledPageMixin";
import CuriculumForm from "@/modules/dean/pages/student/curriculum/CuriculumForm.vue"
import OptionallySubjectAddForm from "@/modules/dean/pages/student/curriculum/OptionallySubjectAddForm";
export default {
  name: "PersonalCuriculum",
  components: {
    DebugJsonComponent,
    SubjectList,
    CurriculumTemplate,
    CuriculumForm,
    OptionallySubjectAddForm
  },
  mixins: [TitledPageMixin],
  computed: {
    loadingPage: function(){
      return checkFieldTrue(this.student, 'loading');
    },
  },
  data: function () {
    return {
      opened_form: false,
      opened_subject_form: false,
      page_title: 'Учебный план',
      loadErrorMsg: '',
      group_id: [],
      group_loaded: false,
      actions: {
        nextCourseApplier: false,
        prevCourseApplier: false,
      },
      URL_DETAIL: urls.DEAN.STUDENTS.CURRICULUM.DETAIL(this.$route.params.idStudent, this.$route.params.idFaculty),
      curriculum: {
        student_id: this.$route.params.student_id,
        data: {},
        loading: false,
        loaded: false,
        status: '',
      },
      is_dean: true,
    }
  },
  methods: {
    closedForm(){
      this.opened_form = false
      this.opened_subject_form = false
    },
    loadCurriculum: function () {
      this.opened_form = false
      this.curriculum.loading = true;
      loadData(
        this.URL_DETAIL,
        (data) => {
          this.curriculum.data = data;
          this.curriculum.loaded = true;
          this.curriculum.loading = false;
        },
        (error) => {
          this.curriculum.status = error;
          this.curriculum.loading = false;
        }
        );
    },
    loadGroup(){
      loadData(
        urls.DEAN.STUDENTS.DETAIL(this.$route.params.idFaculty, this.$route.params.idStudent),
        (data) => {
          this.group_id = data.education_group.id
          this.group_loaded = true
        }
      )
    }
  },
  created() {
    this.setPageTitleWithObject("Education", {id: this.$route.params.idStudent}, ' - ', true)
    this.loadCurriculum();
    this.loadGroup()
  }
}
</script>

<style scoped>

</style>
