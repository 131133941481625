<template>
  <v-container v-if="$route.name === names.StudentEducationAdmissionRequest">
    <v-card >
      <v-toolbar
        class="mb-2"
        color="style-color-main-gray"
        flat
      >
      <v-card-title class="title px-0"  style="word-break: break-word !important;">
        Заказ допусков
      </v-card-title>
        <v-spacer></v-spacer>
        <v-btn @click="opened_form = true" color="secondary" text>
          <v-icon>add</v-icon>
          Добавить
        </v-btn>
      </v-toolbar>
          <v-data-table
            :headers="headers"
            :loading="pagination.loading"
            :page.sync="pagination.page"
            :items-per-page.sync="pagination.size"
            :server-items-length="pagination.count"
            :footer-props="{'items-per-page-options':[10,25,50]}"
            :items="items"
          >
            <v-progress-linear v-slot:progress color="blue" indeterminate></v-progress-linear>
            <template v-slot:item.semester="props">
              <span style="text-decoration: underline; cursor: pointer" @click="getDetail(props.item)">{{props.item.semester}}</span>
            </template>
            <template v-slot:item.date_create="props">
              <span>{{displayDate(props.item.date_create)}}</span>
            </template>
            <template v-slot:item.reason="props">
              <span v-if="props.item.reason">{{props.item.reason}}</span>
            </template>
            <template v-slot:item.accepted="props">
              <v-icon v-if="props.item.accepted">done</v-icon>
              <v-icon v-else-if="props.item.accepted !== null">clear</v-icon>
              <span v-else>Не рассмотрен</span>
            </template>
            <template v-slot:item.action="props">
              <v-tooltip bottom v-if="props.item.accepted === null">
                <template v-slot:activator="{ on }">
                  <v-btn
                    icon
                    v-on="on"
                    @click="openConfirmDelete(props.item)"
                    medium
                  >
                    <v-icon>
                      delete
                    </v-icon>
                  </v-btn>
                </template>
                <span>Удалить</span>
              </v-tooltip>
            </template>
            <template slot="no-data">
              <v-card-text>Данные не загружены</v-card-text>
            </template>
          </v-data-table>
    </v-card>
    <Form @addItem="addItem"
          @ended="closedForm"
          @refreshItem="refreshItem"
          v-bind="selected"
          v-bind:info="$route.params.id"
          v-if="opened_form"
          :opened_form="opened_form"
    >
    </Form>
    <v-dialog persistent v-model="opened_confirm" width="500">
      <v-card v-if="deletable">
        <v-card-title>Подтвердите удаление</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-4">
          Вы уверены, что хотите удалить?<br>
        </v-card-text>
        <v-card-actions>
          <v-flex>
            <v-btn
              @click="deleteItemId(deletable.id)"
              color="warning"
              text
            >
              Удалить
            </v-btn>
            <v-btn
              @click="closeConfirmDelete"
              text
            >Отмена
            </v-btn>
          </v-flex>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
  <router-view :key="$route.fullPath" v-else></router-view>
</template>

<script>
  import moment from "moment"
  import urls from "@/urls/student"
  import UserDataMixin from '@/mixins/UserDataMixin'
  import {loadData, makeGetRequest,} from "@/helper";
  import names from "@/modules/student/routers/names.js"
  import Form from "@/modules/student/pages/AdmissionRequest/Form.vue";
  import TitledPageMixin from "@/mixins/TitledPageMixin";
  import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";

    export default {
      name: "ListAdmissions",
      mixins: [
        TitledPageMixin,
        PaginatedDataMapperMixin,
        UserDataMixin],
      components: { Form},

      data () {
        return {
          page_title: 'Заказ допусков',
          total: 0,
          names:names,
          items: [],
          pagination: {},
          URL_LIST: urls.STUDENT.ADMISSION_REQUEST.LIST(this.$route.params.idStudent),
          headers: [
            {text: 'Предмет', sortable: false, align: 'left', value: 'semester',},
            {text: 'Создано', sortable: false, align: 'left', value: 'date_create',},
            {text: 'Статус', sortable: false, align: 'left', value: 'accepted',},
            {text: 'Комментарий', sortable: false, align: 'left', value: 'reason',},
            {text: 'Действия', sortable: false, align: 'center', value: 'action',},
          ]
        }
      },

      created() {
        this.loadData();
      },
      methods: {
        displayDate: function (date, minutes = false) {
          let format = 'DD.MM.YYYY'
          if (minutes)
            format += ' HH:mm'
          return moment(date).format(format)
        },
        deleteItemId(id){
          this.URL_DELETE = urls.STUDENT.ADMISSION_REQUEST.DELETE(this.$route.params.idStudent,id)
          this.deleteItem(id)
        },
        loadData(url = undefined) {
          this.getItems(url)
        },
        getItems (url=undefined) {
          this.setLoading();
          if(url === undefined) {
            url = this.URL_LIST
            this.setCurrentLoadUrl(url);
          }
          loadData(
            url,
            (data) => {
              this.items = data.results;
              this.mapPagination(data) ;
              this.setNotLoading();
            }
          )
        },
        getDetail(item){
            this.$router.push({name: names.StudentEducationAdmissionRequestDetail, params: {idAdmission: item.id}})
        },
        addItem(){
          this.closedForm()
          this.getItems();
        },

      }
    }


</script>

<style scoped>

</style>
