
const LoadPortal = `LoadPortal`
const Student = `Student`
const StudentProfile = `Profile`
const StudentEducation = `${Student}|Education`
const StudentEducationCurriculum = `${StudentEducation}|Curriculum`
const StudentEducationCurriculumDetailSubject = `${StudentEducationCurriculum}|DetailSubject`
const StudentEducationGroup = `${StudentEducation}|Group`
const StudentEducationListEducationWorks = `${StudentEducation}|ListEducationWorks`
const StudentEducationDetailEducationWorks = `${StudentEducation}|DetailEducationWorks`
const StudentEducationPortfolio = `${StudentEducation}|Portfolio`
const StudentEducationListEduDoc = `${StudentEducation}|ListEduDoc`
const StudentEducationProgressJournal = `${StudentEducation}|ProgressJournal`
const StudentEducationAdmission = `${StudentEducation}|Admission`
const StudentEducationAdmissionDetail = `${StudentEducationAdmission}|Detail`
const StudentEducationCertificate = `${StudentEducation}|Certificate`
const StudentEducationAdmissionRequest = `${StudentEducation}|AdmissionRequest`
const StudentEducationAdmissionRequestDetail = `${StudentEducationAdmissionRequest}|Detail`

const StudentEducationSingleDean = `${StudentEducation}|Single`
const StudentEducationSingleDeanApplication = `${StudentEducationSingleDean}|Application`
const StudentEducationSingleDeanReferences =  `${StudentEducationSingleDean}|Reference`
const StudentEducationSingleDeanReferencesIFNS =  `${StudentEducationSingleDeanReferences}|IFNS`
const StudentEducationSingleDeanReferencesIncome =  `${StudentEducationSingleDean}|Income`
const StudentEducationSingleDeanReferencesAnother =  `${StudentEducationSingleDean}|Another`
const StudentEducationSingleDeanReferencesPXTY =  `${StudentEducationSingleDean}|PXTY`

export default {
  LoadPortal,

  StudentProfile,
  StudentEducation,
  StudentEducationCurriculum,
  StudentEducationCurriculumDetailSubject,
  StudentEducationGroup,
  StudentEducationListEducationWorks,
  StudentEducationDetailEducationWorks,
  StudentEducationPortfolio,
  StudentEducationListEduDoc,
  StudentEducationProgressJournal,
  StudentEducationAdmission,
  StudentEducationAdmissionDetail,
  StudentEducationCertificate,
  StudentEducationAdmissionRequest,
  StudentEducationAdmissionRequestDetail,

  StudentEducationSingleDeanApplication,
  StudentEducationSingleDeanReferencesIFNS,
  StudentEducationSingleDeanReferencesIncome,
  StudentEducationSingleDeanReferencesAnother,
  StudentEducationSingleDeanReferencesPXTY,
}
