// import Vue from 'vue'
import {
  // sendPostRequest,
  // sendDeleteRequest,
  sendGetRequest,
  // sendPutRequest
} from "@/helper";
// import {executeRequest} from "../utils";
// import store from "@/store";
import urls from "@/urls/admin";

const state = {
  items: [],
  count: 0,
  next: null,
  prev: null,
  detail: null,
  id: null,
  loading: false,
  currentUrl: false,
  error: null,
};

const getters = {
  getCathedraItems: state => state.items,
  getCathedraCount: state => state.count,
  getCathedraCurrentUrl: state => state.currentUrl,
  getCathedraDetail: state => state.detail,
  isCathedraLoading: state => state.loading,
  getCathedraError: state => state.error,

};

const actions = {
  loadCathedraList: ({commit, getters}, {url, urlAppend, finalizer=()=>{}, catcher=()=>{}}) => {
    if (!url) {
      url = urls.ADMIN.REFERENCES.DIRECTION.LIST()
      if (urlAppend) url = urlAppend(url)
    }
    if (!getters.isCathedraLoading) {
      commit('SET_CATHEDRA_LOADING');
      sendGetRequest(
        url,
        (data, url) => {
          commit('SET_CATHEDRA', data['results']);
          commit('SET_CATHEDRA_COUNT', data['count']);
          commit('SET_CATHEDRA_CURRENT_URL', url);
          commit('SET_CATHEDRA_LOADING_END');
          finalizer(data, url);
        },
        (data, url) => {
          commit('SET_CATHEDRA_LOADING_END');
          commit('SAVE_CATHEDRA_ERROR', data);
          catcher(data, url);
        }
      )
    }
  },
  loadCarsDetail: ({commit}, {url, id, finalizer=()=>{}, catcher=()=>{}}) => {
    if (!url) url = urls.ADMIN.CATHEDRA.DETAIL(id)
    sendGetRequest(
      url, (data, url) => {
        commit('SET_CATHEDRA_DETAIL', data);
        finalizer(data, url);
      }, (data, url) => {
        commit('SAVE_CATHEDRA_ERROR', data);
        catcher(data, url);
      }
    )
  },
  // createCars: ({dispatch, commit, getters}, {data, finalizer=()=>{}, catcher=()=>{}}) => {
  //   let url = urls.ADMIN.CATHEDRA.CREATE()
  //   executeRequest('Cars', sendPostRequest, {url, data, finalizer, catcher}, {dispatch, commit, getters});
  // },
  // changeCars: ({dispatch, commit, getters}, {data, finalizer,catcher}) => {
  //   let url = urls.ADMIN.CATHEDRA.UPDATE(data.id)
  //   executeRequest('Cars', sendPutRequest, {url, data, finalizer, catcher}, {dispatch, commit, getters});
  // },
  // deleteCars: ({dispatch, commit, getters}, {id, finalizer, catcher}) => {
  //   let url = urls.ADMIN.CATHEDRA.DELETE(id)
  //   executeRequest('Cars', sendDeleteRequest, {url, finalizer, catcher}, {dispatch, commit, getters});
  // },

};

const mutations = {
  SET_CATHEDRA: (state, cars) => {
    state.items = cars
  },
  SET_CATHEDRA_DETAIL: (state, value) => {
    state.detail = value
  },
  SET_CATHEDRA_COUNT: (state, value) => {
    state.count = value
  },
  SET_CATHEDRA_CURRENT_URL: (state, value) => {
    state.currentUrl = value
  },
  SET_CATHEDRA_LOADING: (state) => {
    state.loading = true
  },
  SET_CATHEDRA_LOADING_END: (state) => {
    state.loading = false
  },
  SAVE_CATHEDRA_ERROR: (state, error) => {
    state.error = error
  }

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
