<template>
  <v-container style="height: 100%" v-if="$route.name === names.CathedraCurriculumList">
    <v-card>
      <v-toolbar
        elevation="0"
        color="style-color-main-gray"
      >
        <v-text-field
          dense
          placeholder="Поиск"
          v-model="search.value"
          @keypress.enter="searchData"
          messages="Для поиска нажмите Enter или кнопку поиск"
        >
        </v-text-field>
        <v-btn icon><v-icon>search</v-icon></v-btn>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              @click="loadData() "
              class="mr-2"
              medium
            >
              update
            </v-icon>
          </template>
          <span>Обновить данные</span>
        </v-tooltip>
      </v-toolbar>

      <v-data-table
        :headers="headers"
        :search.sync="search.value"
        @searchEnter="searchData"
        :items="curriculum"
        no-data-text="Нет данных"
        :page.sync="pagination.page"
        :items-per-page.sync="pagination.size"
        :server-items-length="pagination.count"
        :footer-props="{'items-per-page-options':[10,25,50], 'items-per-page-text': 'Число элементов'}"
      >
        <template v-slot:item.index="item">
          {{ ((pagination.page-1)*pagination.size+item.index+1)}}
        </template>

        <template v-slot:item.name="{ item }">
          <router-link :to="{name: names.CathedraCurriculumDetail, params: {idCurriculum: item.id}}">
            {{item.name }}
          </router-link>
        </template>
        <template v-slot:item.is_draft="{ item }">
          <v-icon v-if="item.is_draft">done</v-icon> <v-icon v-else>clear</v-icon>
        </template>
        <template v-slot:item.is_enrollment="{ item }">
          <v-icon v-if="item.is_enrollment">done</v-icon> <v-icon v-else>clear</v-icon>
        </template>
        <template v-slot:item.foreign="{ item }">
          <v-icon v-if="item.foreign">done</v-icon> <v-icon v-else>clear</v-icon>
        </template>
        <template v-slot:item.migrated="{ item }">
          <v-icon v-if="item.migrated">done</v-icon> <v-icon v-else>clear</v-icon>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
  <router-view :key="$route.fullPath" v-else></router-view>
</template>

<script>

import {loadData} from "@/helper"
import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin"
import names from "@/modules/cathedra/routers/names.js"
import UserDataMixin from "@/mixins/UserDataMixin"
import cathedras from "@/urls/cathedras";

export default {
  name: "CurriculumList",
  mixins:[
    PaginatedDataMapperMixin,
    UserDataMixin
  ],

  props: {},
  data(){
    return {
      title: "Учебные планы",
      curriculum: [],
      URL_LIST: cathedras.CATHEDRA.CURRICULUM.LIST(this.$route.params.cathedra_uid.toLowerCase()),
      search: {
        parameter: 'name',
      },
      names: names,
      headers: [
        {text: "№", sortable: false, align: 'left', value: 'index'},
        {text: "Название", sortable: false, align: 'left',weight:'40%',value: 'name'},
        {text: "Год", sortable: false, align: 'left',weight:'5%',value: 'year'},
        {text: "Форма", sortable: false, align: 'left',weight:'10%',value: 'form'},
        {text: "Уровень",sortable: false, align: 'left',weight:'10%',value: 'level'},
        {text: "Факультет", sortable: false,  align: 'left' ,value: 'faculty'},
        {text: "Черновик", sortable: false,  align: 'left' ,value: 'is_draft'},
        {text: "Зачисляемый", sortable: false,  align: 'left' ,value: 'is_enrollment'},
        {text: "Иностранный", sortable: false,  align: 'left' ,value: 'foreign'},
        {text: "Перенесен со старого EIOS", sortable: false,  align: 'left' ,value: 'migrated'},
      ],
    }
  },

  methods: {
    loadData(url=undefined) {
      this.setLoading();
      if(url === undefined) {
        this.setCurrentLoadUrl(
          this.URL_LIST
        )
          url = this.getCurrentLoadUrl()
      }
      loadData(
        url,
        (data) => {
          this.mapResults(data, 'curriculum')
          this.setNotLoading();
        }
      )
    },
  },
  created() {
    this.loadData()
    this.$store.commit('mainpage/changeTitle', this.title)
  }
}

</script>

<style scoped>

</style>
