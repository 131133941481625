<template>
  <v-container class="px-5" >
    <v-row>
      <v-col cols="12" md="12">
        <v-card>
          <v-toolbar
            elevation="0"
            color="style-color-main-gray"
          >
            <v-btn icon><v-icon>search</v-icon></v-btn>
            <v-text-field
              dense
              placeholder="Поиск"
              v-model="search.value"
              @change="searchData"
              messages="Для поиска нажмите Enter или кнопку поиск"
              class="grow"
            >
            </v-text-field>
          </v-toolbar>
          <v-data-table
            :headers="headers"
            :items="subjects"
            :expanded.sync="expanded"
            show-expand
            :items-per-page.sync="pagination.size"
            no-data-text="Дисциплины не загружены"
            no-results-text="Дисциплны не были найдены"
            :page.sync="pagination.page"
            :loading="pagination.loading"
            :server-items-length="pagination.count"
            :footer-props="{'items-per-page-options':[10,25,50], 'footer-props.items-per-page-text': 'Число элементов'}"
          >
            <template v-slot:header.level="{header}">
              <v-select
                class="header-title px-0 mx-0 my-auto"
                hide-details hide-no-data
                clearable
                @click:clear="clearFilter"
                :items="filters.level.list"
                v-model="filters.level.value"
                style="height: 60px"
                height="40px"
              >
                <template v-slot:label>
                  <div class="header-title">{{ header.text }}</div>
                </template>
              </v-select>
            </template>
            <template v-slot:expanded-item="props">
              <td  v-if="expanded.indexOf(props.item) !== -1" :colspan="props.headers.length" class="py-2">
                <v-row wrap v-if="props.item.groups instanceof Array">
                  <v-col cols="12" class="ma-2 pr-3 pa-0">
                    <v-toolbar elevation="4" short dense>
                      <v-toolbar-title>
                        Список групп
                      </v-toolbar-title>
                      <v-spacer></v-spacer>
  <!--                    <v-btn></v-btn>-->
                    </v-toolbar>
                  </v-col>
                  <v-col class="text-left" :md="props.item.groups.length > 0? 6: 12" sm="12" v-for="(group_semester, index) in props.item.groups" :key="index">
                    <v-card elevation="2" class="py-0 px-2 ma-0" height="100%">
                      <v-card-title>
                        <v-row justify="space-between">
                          <v-col cols="6" md="6" class="py-0">
                            <router-link :to="{
                          name: names.CathedraGroupsDetail,
                            params: {
                            taught: 'taught',
                            cathedra_uid: cathedra_uid,
                            group_id: group_semester.group.id
                            }
                          }">
                            {{group_semester.group.name}}
                            </router-link>
                          </v-col>
                          <v-col cols="6" md="6" class="py-0 text-right" >
                            {{group_semester.control_type}}
                          </v-col>

<!--                          <v-col cols="12" md="4" class="py-0 d-flex justify-end">-->
<!--                            <v-btn icon small class="d-flex" @click="openEditable(props.item, group_semester)">-->
<!--&lt;!&ndash;                              Редактировать&ndash;&gt;-->
<!--                              <v-icon small>edit</v-icon>-->
<!--                            </v-btn>-->
<!--                          </v-col>-->
                        </v-row>
                      </v-card-title>
                      <v-card-text>
                        <v-data-table
                          :headers="[{text: 'ФИО', value: 'fio', sortable: false, width: '60%'},{text: 'Ведомые занятия', value: 'type', sortable: false, align: 'center', width: '60%'}]"
                          :items="filterList(group_semester.teachers, filters.lesson_kind.value[group_semester.id])"
                          :hide-default-footer="group_semester.teachers.length < 5"
                          no-data-text="Не назначено ни одного преподавателя"
                          :items-per-page="5"
                        >
                          <template v-slot:header.fio="{header}">
                            <v-row>
                              <v-col>{{header.text}}</v-col>
                            </v-row>
                          </template>
                          <template v-slot:header.type="{header}">
                            <v-row>
                              <v-col class="grow">
                                <v-select
                                  class="header-title px-0 mx-0 my-auto"
                                  height="24"
                                  dense hide-details hide-no-data
                                  clearable
                                  @click:clear="filters.lesson_kind.value[group_semester.id] = null"
                                  :items="filters.lesson_kind.list"
                                  v-model="filters.lesson_kind.value[group_semester.id]"
                                >
                                  <template v-slot:label>
                                    <div class="header-title text-center" style="margin-top: -2px;">{{ header.text }}</div>
                                  </template>
                                </v-select>
                              </v-col>
                              <v-btn icon small right @click="openEditable(props.item, group_semester)" class="d-flex my-auto">
                                <v-icon small>edit</v-icon>
                              </v-btn>
                            </v-row>

                          </template>
                        </v-data-table>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
                <v-container v-else>
                  <v-row>
                    <v-col>
                      <v-skeleton-loader
                        height="100"
                        type="card"
                      >
                      </v-skeleton-loader>
                    </v-col>
                    <v-col>
                      <v-skeleton-loader
                        height="100"
                        type="card"
                      >
                      </v-skeleton-loader>
                    </v-col>
                  </v-row>
                </v-container>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
<!--    <DebugJsonComponent :json="pagination"></DebugJsonComponent>-->
<!--    <DebugJsonComponent :json="editable"></DebugJsonComponent>-->
<!--    <DebugJsonComponent :json="subjects"></DebugJsonComponent>-->
    <v-dialog
      v-model="editable.opened"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
<!--      <PortalTarget name="editable"></PortalTarget>-->
      <linked-teacher v-if="editable.opened" :group_semester_id="editable.group_semester.id" :subject_id="editable.subject.id" :selected="editable.group_semester.teachers" @saved="closeTeacherLinker()" @close="closeTeacherLinker()"></linked-teacher>
    </v-dialog>
  </v-container>
</template>

<script>
  import {loadData} from "../../../../helper";
  import urls from '@/urls/cathedras';
  import choices from "@/urls/choices";
  import names from "@/modules/cathedra/routers/names.js";
  import TitledPageMixin from "@/mixins/TitledPageMixin";
  import DebugJsonComponent from "../../../core/components/DebugJsonComponent";
  import PaginatedDataMapperMixin from "../../../../mixins/PaginatedDataMapperMixin";
  import {generateListUidToName} from "@/helper/uidToName";
  import LinkedTeacher from "../subjects/LinkedTeacher";
  import cathedras from "../../../../urls/cathedras";
  import OneFieldBaseSeachMixin from "@/mixins/OneFieldBaseSearchMixin";

  export default {
    name: "TaughtSubjects",
    components: {
      DebugJsonComponent,
      LinkedTeacher,
    },
    mixins: [
      TitledPageMixin,
      PaginatedDataMapperMixin,
      OneFieldBaseSeachMixin
    ],
    data: function(){
      return {
        names:names,
        page_title: 'Список дисциплин, которые ведет в этом семестре',
        cathedra_uid: this.$route.params.cathedra_uid,
        subjects: [],
        expanded: [],
        headers: [
          {text:'Предмет', value: 'name', sortable: false, width: '70%'},
          {text: 'Уровень образования', value: 'level', sortable: false, width: '30%'},
          { text: '', value: 'data-table-expand', sortable: false },
        ],
        editable: {
          subject: undefined,
          group_semester: undefined,
          opened: false,
        },
        filters: {
          level: {
            list: [],
            value: null,
          },
          lesson_kind: {
            list: [],
            value: [],
          }
        },
        search: {
          parameter: 'name',
        },
      }
    },
    watch: {
      expanded: function (value) {
        value.forEach(el => {
          if(!(el.groups instanceof Array)) {
            this.loadGroups(el)
          }
        })
      },
      'filters.level.value': function (value) {
        if(this.filters.level.value !== null){
          this.loadData(this.addGetParameter(this.pagination.current,'level', value))
        }
      }
    },
    methods: {
      clearFilter(){
        this.filters.level.value = null
        this.loadData()
      },
      filterList (list, value) {
        if (value){
          return list.filter(
            el => el.type.toLowerCase() === value.toLowerCase()
          )
        }
        else {
          return list
        }
      },
      openEditable: function(subject, group_semester){
        this.editable.subject = subject;
        this.editable.group_semester = group_semester;
        this.editable.opened = true;
      },
      loadLevelsData: function () {
        loadData(
          choices.EDUCATION.CHOICES.LEVEL(),
          (data) => {
            this.filters.level.list = data;
          }
        )
      },
      loadLessonKindData: function () {
        loadData(
          choices.EDUCATION.CHOICES.GROUP_TYPE(),
          (data) => {
            this.filters.lesson_kind.list = [
              {
                text: 'все',
                value: 'все'
              },
              ...data
            ];
          }
        )
      },
      loadData: function (url = undefined) {
        this.pagination.loading = true;
        if (url === undefined)
          url = urls.CATHEDRA.SUBJECT.LIST(this.cathedra_uid);
        loadData(
          url,
          (data) => {
            this.mapResults(data, 'subjects');
            this.pagination.loading = false;
            this.setCurrentLoadUrl(url);
          }
        )
      },
      loadGroups: function (item) {
        if(!(item.groups instanceof Array)){
          this.$set(item, 'groups_url', item.groups)
          this._loadGroups(item, urls.getByRecievedUrlData(item.groups_url))
        }
      },
      closeTeacherLinker: function () {
        this._loadGroup(this.editable.subject, this.editable.group_semester.id);
        this.editable.opened = false;
      },
      _loadGroup: function (subject, group_id) {
        this.pagination.loading = true;
        loadData(
          urls.getByRecievedUrlData(
            subject.groups_url,
            {
              group_semester: group_id
            }
          ),
          data => {

            let teachers = [];
            let fel_id = subject.groups.findIndex(el => el.id === group_id);
            this.$set(subject.groups, fel_id, data[0]);
            let group = subject.groups[fel_id];
            group.teachers.forEach(teacher => {
              if(!teachers.find(fel => {return fel.uid === teacher.uid}))
                teachers.push({uid: teacher.teacher, fio: ''});
            });
            generateListUidToName(teachers, 'uid', 'fio', (data) => {
              group.teachers.forEach(teacher => {
                let result = data.find(fel => {return fel.uid === teacher.teacher});
                this.$set(teacher, 'fio', result.fio);
              });
            });
            this.pagination.loading = false;
          }
        )
      },
      _loadGroups: function (subject, url) {
        this.pagination.loading = true;
        loadData(
          url,
          (data) => {
            subject.groups = data;
            let teachers = [];
            subject.groups.forEach(group => {
              group.teachers.forEach(teacher => {
                if(!teachers.find(fel => {return fel.uid === teacher.uid}))
                  teachers.push({uid: teacher.teacher, fio: ''});
              });
            });
            generateListUidToName(teachers, 'uid', 'fio', (data) => {
              subject.groups.forEach(group => {
                group.teachers.forEach(teacher => {
                  let result = data.find(fel => {return fel.uid === teacher.teacher});
                  this.$set(teacher, 'fio', result.fio);
                });
              });
            });
            this.pagination.loading = false;
          }
        );
      }
    },
    created() {
      this.setPageTitleWithObject("Cathedra", {uid: this.$route.params.cathedra_uid}, ' - ')
      this.loadData();
      this.loadLevelsData();
      this.loadLessonKindData();
    },

  }
</script>

<style scoped>
  .header-title{
    color: rgba(0,0,0,.6);
    font-size: .75rem;
  }
</style>
