<template>
  <v-container v-if="student.loaded">
    <StudentTemplate v-if="student.loaded" :student="student.data">
      <template v-slot:scientific_director="{scientific_director}">
        <span v-if="student.data.scientific_director === null">Не назначен</span>
        <span v-else class="d-flex justify-space-between">
          <span class="mr-auto">
           {{student.data.scientific_director_fio}}
          </span>
        </span>
      </template>
    </StudentTemplate>
    <StudentContactsTemplate :phones="student.data.user.phones" :addresses="student.data.user.addresses" :emails="student.data.user.emails"></StudentContactsTemplate>
    <v-card>
    </v-card>
  </v-container>
</template>

<script>
  import urls from "@/urls/management";
  import {loadData, checkFieldTrue} from "@/helper";
  import StudentTemplate from "@/modules/templates/students/StudentTemplate";
  import StudentContactsTemplate from "@/modules/templates/students/StudentContactsTemplate";
  import DebugJsonComponent from "../../../core/components/DebugJsonComponent";
  import NotAvatar from "@/assets/NotAvatar.png";
  import {generateUidToName} from "@/helper/uidToName";

  export default {
    name: "StudentDetail",
    components: {
      DebugJsonComponent,
      StudentTemplate,
      StudentContactsTemplate,
    },
    computed: {
      loadingPage: function(){
        return checkFieldTrue(this.student, 'loading');
      },
    },
    data: function () {
      return {
        NotAvatar: NotAvatar,
        title: 'Профиль обучающегося',
        loadErrorMsg: '',
        actions: {
          nextCourseApplier: false,
          prevCourseApplier: false,
        },
        student: {
          id: this.$route.params.student_id,
          data: {},
          loading: false,
          loaded: false,
        }
      }
    },
    methods: {
      loadStudent: function () {
        let url = urls.EDUCATION.STUDENT.RETRIEVE(this.student.id);
        loadData(
          url,
          (data) => {
            this.student.data = data;
            this.$set(this.student.data, 'scientific_director_fio', '');
            loadData(
              urls.ACCOUNTS.PROFILE(this.student.data.student),
              (data) => {
                this.student.data.user = data;
                this.student.loaded = true;
                generateUidToName(this.student.data, 'scientific_director', 'scientific_director_fio');
              });
          }
        );
      },
    },
    created() {
      this.$store.commit('mainpage/changeTitle', this.title);
      this.loadStudent();
    }
  }
</script>

<style scoped>

</style>
