
<template>
  <v-container v-if="journal.loaded">
    <v-toolbar
      class="mb-2"
      color="style-color-main-gray"
      flat
    >
      <v-toolbar-title>
        Журнал обучающегося
      </v-toolbar-title>
      <v-toolbar-title>
        <v-select :items="journal.data" item-text="name" return-object v-model="journal.selected" class="ml-3" hide-details dense style="min-width: 280px;" filled>
        </v-select>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-title>
        <v-text-field v-model="search" clearable placeholder="Поиск" hide-details style="min-width: 100px;" prepend-inner-icon="search" dense hint="" filled></v-text-field>
      </v-toolbar-title>
      <v-toolbar-items>
      </v-toolbar-items>
    </v-toolbar>
    <v-row>
      <v-col>
        <JournalTableTemplate
          :rows="journal.selected.subjects"
          first_row_label="Предмет"
          first_row_value="subject_name"
          :can_update="false"
          :search="search"
          :loading="journal.loading"
          :errors="[]"
        ></JournalTableTemplate>
      </v-col>
    </v-row>
  </v-container>
  <v-container v-else>
    <v-row>
      <v-col>
        <v-skeleton-loader
          height="50"
          type="list-item"
        >
        </v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-skeleton-loader
          height="200"
          type="card"
        >
        </v-skeleton-loader>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import {loadData} from "@/helper";
import JournalTableTemplate from "@/modules/templates/journal/JournalTableTemplate";
import DebugJsonComponent from "@/modules/core/components/DebugJsonComponent";
import cathedras from "@/urls/cathedras";
import {generateUidToName} from "@/helper/uidToName";
import TitledPageMixin from "@/mixins/TitledPageMixin";

export default {
  name: "FinalJournal",
  mixins: [TitledPageMixin],
  components: {
    DebugJsonComponent,
    JournalTableTemplate,
  },
  computed: {
    filteredBySemesterJournal() {
      return this.journal.filter(el => el.semester_name === this.semester_selected)
    },
    detailUrl: function () {
        return cathedras.CATHEDRA.STUDENT.OWNED.DETAIL
      }
  },
  data: function () {
    return {
      page_title: 'Итоговый журнал успеваемости обучающегося',
      journal: {
        student_id: this.$route.params.student_id,
        data: [],
        selected: {subjects: []},
        loading: false,
        loaded: false,
      },
      student: null,
      search: '',
    }
  },
  methods: {
    loadJournal() {
      loadData(
        cathedras.CATHEDRA.STUDENT.JOURNAL.FROM(this.$route.params.cathedra_uid, this.$route.params.student_id),
        (data) => {

          for(let course of data.courses){
            for(let semester of course.semesters){
              this.journal.data.push({
                name: `${course.number} курс ${semester.name} семестр`,
                subjects: semester.subjects,
              })
            }
          }
          if(this.journal.data.length > 0)
            this.journal.selected = this.journal.data[0]
          this.journal.loaded = true;
        }
      )
    },
    loadStudent() {
      this.journal.loaded = false;
      loadData(
        this.detailUrl(this.$route.params.cathedra_uid.toLowerCase(), this.$route.params.student_id),
        (data) => {
          // console.log(data)
          loadData(
            cathedras.getByRecievedUrlData(data.url),
            (data) => {
              this.student = data
              this.$set(this.student, 'fio', '')
              generateUidToName(this.student, 'student', 'fio',()=>{
                this.page_title = 'Итоговый журнал успеваемости обучающегося:' + ' ' + String(this.student.fio)
              })
              this.journal.loaded = true
            }
          )
        }
      )
    },
  },
  created() {
    this.loadJournal();
    this.loadStudent()
  }
}
</script>

<style scoped>

</style>

