<template>
    <v-container grid-list-md text-xs-center>
      <v-toolbar
        class="mb-2"
        color="style-color-main-gray"
        flat
      >
        <v-row>
          <v-toolbar-title>
            Группа: {{group.data.litter}}-{{ group.data.course }}{{group.data.number}}
          </v-toolbar-title>
        <v-spacer></v-spacer>
        </v-row>

      </v-toolbar>
      <v-row wrap v-if="group.loaded">
        <v-col cols="12" lg="6">
          <GroupTemplate v-bind="group.data">
            <template v-slot:append_title>
              <v-spacer></v-spacer>
              <v-tooltip bottom v-if="group.data.for_enrollment">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    color="success"
                  >person_add</v-icon>
                </template>
                <span>Группа для зачисления</span>
              </v-tooltip>
              <v-tooltip bottom v-if="group.data.archive">
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" color="info">archive</v-icon>
                </template>
                <span>Архивная группа</span>
              </v-tooltip>
            </template>
          </GroupTemplate>
        </v-col>
        <v-col cols="12" lg="6">
          <GroupCurriculumTemplate
            :direction="group.data.direction"
            :profile="group.data.profile"
            :year="group.data.curriculum.year"
            :cathedra="group.data.cathedra"
            :loaded="true"
            :is_system="group.data.curriculum.is_system"
          >
          </GroupCurriculumTemplate>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <GroupSubjectsTemplate
            v-if="subjects.loaded"
            :loaded="subjects.loaded"
            :semesters="subjects.data"
            :expandable="false"
          ></GroupSubjectsTemplate>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <GroupStudentsTemplate
            v-if="students.loaded"
            :loaded="students.loaded"
            :students="students.data"
            :expandable="false"
          >
            <template v-slot:student_link="{student}">
              <router-link :to="makeLink(student.id)">
                {{ student.fio }}
              </router-link>
            </template>
          </GroupStudentsTemplate>
        </v-col>
      </v-row>
      <ApplyDialog
        :opened="actions.nextCourse.dialogs.applier"
        :get-text="() => `Вы уверены что хотие перевести группу ${group.data.litter}-${group.data.course}${group.data.number} на следующия курс`"
        @close="executeAction($event, moveToNextCourse, 'nextCourse')"></ApplyDialog>
      <MsgDialog
        :opened="actions.nextCourse.dialogs.result"
        :title="actions.nextCourse.resultText"
        :success="!actions.nextCourse.result"
        :warning="!!actions.nextCourse.result"
        @close="actions.nextCourse.dialogs.result = false"
      >
        {{ actions.nextCourse.result }}
      </MsgDialog>
      <ApplyDialog
        :opened="actions.prevCourse.dialogs.applier"
        :get-text="() => `Вы уверены что хотие перевести группу ${group.data.litter}-${group.data.course}${group.data.number} на предыдущий курс`"
        @close="executeAction($event, moveToPrevCourse, 'prevCourse')"></ApplyDialog>
      <MsgDialog
        :opened="actions.prevCourse.dialogs.result"
        :title="actions.prevCourse.resultText"
        :success="!actions.prevCourse.result"
        :warning="!!actions.prevCourse.result"
        @close="actions.prevCourse.dialogs.result = false"
      >
        {{ actions.prevCourse.result }}
      </MsgDialog>
    </v-container>
</template>

<script>
  import urls from "@/urls/management";
  import {loadData, checkFieldTrue, sendGetRequest} from "@/helper";
  import ApplyDialog from "@/modules/core/components/ApplyDialog";
  import DebugJsonComponent from "@/modules/core/components/DebugJsonComponent";
  import MsgDialog from "@/modules/core/components/MsgDialog";
  import GroupTemplate from "@/modules/templates/group/GroupTemplate";
  import GroupCurriculumTemplate from "@/modules/templates/group/GroupCurriculumTemplate";
  import GroupSubjectsTemplate from "@/modules/templates/group/GroupSubjectsTemplate";
  import GroupStudentsTemplate from "@/modules/templates/group/GroupStudentsTemplate";
  import {generateListUidToName} from "@/helper/uidToName";
  import names from "@/modules/educationalManagement/routers/names";

  export default {
    name: "Detail",
    components: {
      DebugJsonComponent,
      ApplyDialog,
      MsgDialog,
      GroupTemplate,
      GroupCurriculumTemplate,
      GroupSubjectsTemplate,
      GroupStudentsTemplate
    },
    computed: {
      loadingPage: function(){
        return checkFieldTrue(this.group, 'loading');
      },
    },
    data: function () {
      return {
        title: 'Профиль группы',
        loadErrorMsg: '',
        actions: {
          nextCourse: {
            dialogs: {
              applier: false,
              result: false
            },
            result: [],
            resultText: "",
          },
          prevCourse: {
            dialogs: {
              applier: false,
              result: false
            },
            result: [],
            resultText: "",
          }
        },
        group: {
          id: this.$route.params.group_id,
          data: {},
          loading: false,
          loaded: false,
        },
        subjects: {
          data: [],
          loading: false,
          loaded: false,
        },
        students: {
          data: [],
          loading: false,
          loaded: false,
        }
      }
    },
    methods: {
      loadSubjects: function () {
        this.subjects.loading = true;
        loadData(
          urls.EDUCATION.GROUP.SUBJECTS(this.group.id),
          (data) => {
            this.subjects.data = data;
            this.subjects.loaded = true;
            this.subjects.loading = false;
          }
        );
      },
      loadStudents: function () {
        this.students.loading = true;
        // console.log(urls.EDUCATION.GROUP.STUDENTS(this.group.id))
        // console.log(this.$store.state.urls.urls)
        loadData(
          urls.EDUCATION.GROUP.STUDENTS(this.group.id),
          (data) => {
            console.log('loadStudents')
            console.log(data)
            this.students.data = data;
            for (let el of this.students.data) this.$set(el, "fio","")
            generateListUidToName(
              this.students.data,
              "user",
              "fio",
              (data) => {
                this.students.loading = false;
                this.students.loaded = true;
                this.students.data.sort(function (a, b) {
                  if (a.fio > b.fio) {
                    return 1;
                  }
                  if (a.fio < b.fio) {
                    return -1;
                  }
                  return 0;
                });
              }
            );
          },
        );
      },
      loadGroup: function () {
        loadData(
          urls.EDUCATION.GROUP.RETRIEVE(this.group.id),
          (data) => {
            this.group.data = data;
            this.group.loaded = true;
            this.loadSubjects();
            this.loadStudents();
          }
        );
      },
      executeAction: function (apply, executor, applier) {
        if(apply)
          executor();
        this.actions[applier].dialogs.applier = false;
      },
      executeUrlAction: function (url, finalizer, catcher) {
        sendGetRequest(url, finalizer, catcher);
      },
      moveToNextCourse: function () {
        let final = (data) => {
          this.actions.nextCourse.result = data.error;
          if(data.error !== "")
            this.actions.nextCourse.resultText = 'Группа не перевелена';
          else
            this.actions.nextCourse.resultText = 'Группа переведена';
          this.actions.nextCourse.dialogs.result = true;
        };
        this.executeUrlAction(urls.EDUCATION.GROUP.TO_NEXT_COURSE(this.group.id), final, final);
      },
      moveToPrevCourse: function () {
        let final = (data) => {
          this.actions.prevCourse.result = data.error;
          if(data.error)
            this.actions.prevCourse.resultText = 'Группа не перевелена';
          else
            this.actions.prevCourse.resultText = 'Группа переведена';
          this.actions.prevCourse.dialogs.result = true;
        };
        this.executeUrlAction(urls.EDUCATION.GROUP.TO_PREV_COURSE(this.group.id), final, final);
      },
      makeLink(student_id) {
        return {
          name: names.EDUCATIONAL.STUDENTS.DETAIL,
          params: {
            'student_id': student_id
          }
        }
      }
    },
    created() {
      this.$store.commit('mainpage/changeTitle', this.title);
      this.loadGroup();
    }
  }
</script>

<style scoped>

</style>
