import { render, staticRenderFns } from "./ScienceDirectorStudentChange.vue?vue&type=template&id=09e1a89c&scoped=true"
import script from "./ScienceDirectorStudentChange.vue?vue&type=script&lang=js"
export * from "./ScienceDirectorStudentChange.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09e1a89c",
  null
  
)

export default component.exports