<template>

      <div v-if="$route.name === names.StudentEducation">
        <v-card flat class="pa-2 ma-2">
          <StudentTemplate v-if="education.loaded" :student="education.data">
            <template v-slot:extra_actions>
              <v-toolbar class="mb-4" color="style-color-main-gray" flat  v-if="!$vuetify.breakpoint.mobile">
                <v-toolbar-title>
                  <v-chip input-value="false" color="blue lighten-4" class="title black--text">
                    {{education.data.status}}
                  </v-chip>
                </v-toolbar-title>
              </v-toolbar>
              <v-toolbar-title class="justify-start d-flex" v-else> {{education.data.status}}</v-toolbar-title>
            </template>
          </StudentTemplate>
          <StudentContactsTemplate  v-if="education.loaded"
                                    :phones="education.data.user.phones"
                                    :addresses="education.data.user.addresses"
                                    :emails="education.data.user.emails">
          </StudentContactsTemplate>
        </v-card>

      </div>
      <router-view :key="$route.fullPath" v-else></router-view>

</template>

<script>
  import urls from "@/urls/student"
  import LoadPage from "../../core/pages/LoadPage";
  import {checkFieldTrue, loadData} from "../../../helper";
  import StudentTemplate from "@/modules/templates/students/StudentTemplate";
  import urlsM from "@/urls/management";
  import StudentContactsTemplate from "@/modules/templates/students/StudentContactsTemplate";
  import names from "@/modules/student/routers/names";

  export default {
    name: "Education",
    components: {
      LoadPage,
      StudentContactsTemplate,
      StudentTemplate
    },
    computed: {
      loadingPage: function() {
        return checkFieldTrue(this.education, 'loadingData');
      },
      names: () => names
    },
    data: function () {
      return {
        loadErrorMsg: '',
        education: {
          data:{},
          loaded: false,
        }
      }
    },
    methods: {
      loadEducationMenu: function(){
        let params = {
          idStudent: this.$route.params.student_id
        };
        let menu = [

          {title: 'Образование', icon: 'account_balance', included: false,
            router: {name: names.StudentEducation, params: {idStudent: this.$route.params.idStudent}}
          },

          {title: 'Учебный план', icon: 'dashboard',included:true, router:{name: names.StudentEducationCurriculum, params: {idStudent: this.$route.params.idStudent}}},
          {title: 'Группа', icon: 'group', router:{name: names.StudentEducationGroup, params: {idStudent: this.$route.params.idStudent}}},
          {title: 'Работы обучающегося', icon: 'description',included:true, router:{name: names.StudentEducationListEducationWorks, params: {idStudent: this.$route.params.idStudent}}},
          {title: 'Портфолио', icon: 'file_present',included:true, router:{name: names.StudentEducationPortfolio, params: {idStudent: this.$route.params.idStudent}}},
          {title: 'Документы об образовании', icon: 'cases',included:true, router:{name: names.StudentEducationListEduDoc, params: {idStudent: this.$route.params.idStudent}}},
          {title: 'Журнал', icon: 'fact_check', router:{name: names.StudentEducationProgressJournal, params: {idStudent: this.$route.params.idStudent}}},
          {title: 'Допуски', icon: 'article',included:true, router:{name: names.StudentEducationAdmission, params: {idStudent: this.$route.params.idStudent}}},
          {title: 'Справки', icon: 'description', included: [], router: {name: names.StudentEducationCertificate, params: {idStudent: this.$route.params.idStudent}}},
          {title: 'Заказ допусков', icon: 'grading',included:true, router:{name: names.StudentEducationAdmissionRequest, params: {idStudent: this.$route.params.idStudent}}},
          {title: 'Единый деканат', icon: 'article',included:false,
            subs: [
              {title: 'Подача заявлений', icon: 'article',included:true, router:{name: names.StudentEducationSingleDeanApplication, params: {idStudent: this.$route.params.idStudent}}},
              {title: 'Заказ справки в ИФНС', icon: 'article',included:true, router:{name: names.StudentEducationSingleDeanReferencesIFNS, params: {idStudent: this.$route.params.idStudent}}},
              {title: 'Заказ справки о доходах', icon: 'article',included:true, router:{name: names.StudentEducationSingleDeanReferencesIncome, params: {idStudent: this.$route.params.idStudent}}},
              {title: 'Заказ иной справки', icon: 'article',included:true, router:{name: names.StudentEducationSingleDeanReferencesAnother, params: {idStudent: this.$route.params.idStudent}}},
              {title: 'Заказ справки об обучении', icon: 'article',included:true, router:{name: names.StudentEducationSingleDeanReferencesPXTY, params: {idStudent: this.$route.params.idStudent}}},
              // {title: 'Заказ справки', icon: 'article',included:true, router:{name: names.EDUCATION.SINGLE_DEAN.REFERENCES, params: {idStudent: this.$route.params.idStudent}}},
            ]
          },
        ];
        this.$store.commit('leftmenu/replaceItems', menu);
      },
      loadEducation: function () {
        let url = urls.STUDENT.DETAIL(this.$route.params.idStudent)
        loadData(url, (data) => {
          this.education.data = data;
          this.loadEducationMenu()
          loadData(
            urlsM.ACCOUNTS.PROFILE(this.education.data.user),
            (data) => {
              this.education.data.user = data;
              this.education.loaded = true;
            });
        })
      }
    },
    beforeRouteUpdate(to, from, next){
      if (to.name === names.StudentEducation){
        this.$store.commit('mainpage/changeTitle', 'Образование обучающегося');
      }
      next()
    },
    created() {
      this.loadEducation();
      this.$store.commit('mainpage/changeTitle', 'Образование обучающегося');
    },

  }

</script>

<style scoped>

</style>
