import { render, staticRenderFns } from "./SubjectDetail.vue?vue&type=template&id=011a21cd&scoped=true"
import script from "./SubjectDetail.vue?vue&type=script&lang=js"
export * from "./SubjectDetail.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "011a21cd",
  null
  
)

export default component.exports