<template>
  <v-container v-if="admission !== undefined" class="pa-3" flat>
    <v-row  class="py-3">
      <v-col cols="12" class="py-0   my-0" >
        <v-card  class="py-0" >
          <v-toolbar flat color="style-color-main-gray" elevation="0" class="pb-5">
            <v-toolbar-title>
              {{ admission.semester.subject_name}}
            </v-toolbar-title>
          </v-toolbar>
          <v-row no-gutters class="text-start subtitle-1 py-1 px-4">
            <v-col cols="12" md="4" class="font-weight-bold">Семестр:</v-col>
            <v-col cols="12" md="8">{{ admission.semester.semester}}</v-col>
          </v-row>
          <v-row no-gutters class="text-start subtitle-1 py-1 px-4">
            <v-col cols="12" md="4" class="font-weight-bold">Курс:</v-col>
            <v-col cols="12" md="8">{{ admission.semester.course.number}}</v-col>
          </v-row>
          <v-row no-gutters class="text-start subtitle-1 py-1 px-4">
            <v-col cols="12" md="4" class="font-weight-bold">Квалификация:</v-col>
            <v-col cols="12" md="8">{{ admission.semester.course.level}}</v-col>
          </v-row>
          <v-row no-gutters class="text-start subtitle-1 py-1 px-4">
            <v-col cols="12" md="4" class="font-weight-bold">Кафедра:</v-col>
            <v-col cols="12" md="8">{{ admission.semester.cathedra}}</v-col>
          </v-row>
          <v-row no-gutters class="text-start subtitle-1 py-1 px-4">
            <v-col cols="12" md="4" class="font-weight-bold">Дата создания:</v-col>
            <v-col cols="12" md="8">{{ displayDate(admission.date_create)}}</v-col>
          </v-row>
          <v-row no-gutters class="text-start subtitle-1 py-1 px-4">
            <v-col cols="12" md="4" class="font-weight-bold">Комментарий:</v-col>
            <v-col cols="12" md="8" v-show="admission.reason">{{admission.reason}}</v-col>
          </v-row>
        </v-card>
      </v-col>

    </v-row>
  </v-container>
  <v-card v-else>
    <v-skeleton-loader
      type="card-avatar, article, actions"
    ></v-skeleton-loader>
  </v-card>
</template>

<script>
  import moment from 'moment'
  import urls from "@/urls/student"
  import {loadData} from "@/helper";
  import TitledPageMixin from "@/mixins/TitledPageMixin";
  import UserDataMixin from "@/mixins/UserDataMixin";

  export default {
    name: "DetailAdmission",
    mixins: [TitledPageMixin, UserDataMixin],
    data: function () {
      return {
        page_title: 'Допуск',
        admission: undefined
      }
    },
    methods: {
      displayDate: function (date, minutes = false) {
        let format = 'DD.MM.YYYY'
        if (minutes)
          format += ' HH:mm'
        return moment(date).format(format)
      },
      loadData(){
        loadData(
          urls.STUDENT.ADMISSION_REQUEST.DETAIL(this.$route.params.idStudent, this.$route.params.idAdmission),
          (data) => {
            this.admission = data
          }
        )
      },
    },
    created() {
      this.loadData();
    }
  }
</script>

<style scoped>

</style>
