<template>
  <v-container v-if="is_self_route">
    <v-card>
      <v-toolbar
        elevation="0"
        color="style-color-main-gray"
      >
      <v-text-field v-model="search.value"
                    dense
                    class="pa-3"
                    placeholder="Поиск по наименованию"
                    @change="searchData"
                    messages="Для поиска нажмите Enter или кнопку поиск"
      ></v-text-field>
      </v-toolbar>
      <v-data-table
        :headers="curriculum_list.headers"
        :items="curriculum_list.items"
        :loading="curriculum_list.loading"
        :server-items-length="pagination.count"
        :page.sync="pagination.page"
        :items-per-page.sync="pagination.size"
        :footer-props="{'items-per-page-options':[10, 25, 50], 'items-per-page-text': 'Число элементов'}"
      >

        <template v-slot:item.is_enrollment="props">
          <v-icon v-if="props.item.is_enrollment">done</v-icon>
          <v-icon v-else>clear</v-icon>
        </template>

        <template v-slot:item.name="props">
          <router-link :to="makeLink(props.item.id)">
            {{ props.item.name }}
          </router-link>
        </template>

      </v-data-table>
    </v-card>
  </v-container>
  <router-view v-else></router-view>
</template>

<script>
  import urls from "@/urls/management";
  import {loadData} from "@/helper";
  import DebugJsonComponent from "@/modules/core/components/DebugJsonComponent";
  import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
  import names from "@/modules/educationalManagement/routers/names";
  import OneFieldBaseSearchMixin from "@/mixins/OneFieldBaseSearchMixin";

  export default {
    name: "CurriculumList",
    mixins: [
      PaginatedDataMapperMixin, OneFieldBaseSearchMixin
    ],
    components: {
      DebugJsonComponent,
    },
    computed: {
      is_self_route: function() {
        return this.$route.name === names.EDUCATIONAL.CURRICULUM.LIST
      },
    },
    data: function(){
      return {
        title: "Список учебных планов",
        loadErrorMsg: '',
        search: {
          parameter: 'name',
        },
        curriculum_list: {
          loading: false,
          items: [],
          next: '',
          prev: '',
          size: 0,
          headers: [
            {text: "Название", value: 'name', sortable: false, align: 'left', width: '40%'},
            {text: "Кафедра", value: 'faculty', sortable: false,  align: 'left', width: '25%'},
            {text: "Год", value: 'year', sortable: false, align: 'left', width: '5%'},
            {text: "Форма", value: 'form', sortable: false, align: 'left', width: '10%'},
            {text: "Уровень", value: 'level', sortable: false, align: 'left', width: '10%'},
            {text: "Для зачисления", value: 'is_enrollment', sortable: false, align: 'left', width: '10%'},

          ],
        }
      }
    },
    methods: {
      makeLink(curriculum_id){
        return {name: names.EDUCATIONAL.CURRICULUM.DETAIL, params: {curriculum_id: curriculum_id}}
      },
      loadData: function (url=undefined) {
        this.curriculum_list.loading = true;
        if(url === undefined){
          url = urls.EDUCATION.CURRICULUM.LIST();
        }

        loadData(
          url,
          (data) => {
            this.curriculum_list.items = data.results;
            this.mapPagination(data)
            this.setCurrentLoadUrl(url)
            this.curriculum_list.loading = false;
          }
        );
      }
    },
    created() {
      this.$store.commit('mainpage/changeTitle', this.title);
      this.loadData();
    }
  }
</script>

<style scoped>

</style>
