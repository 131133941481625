import helper from "./helper";
import user from "../store/modules/user";

let getOrganizationUrlWithParameters = helper.getOrganizationUrlWithParameters;

export default {
  getOrganizationUrlWithParameters,
  getByRecievedUrlData: (url, gets=null) => getOrganizationUrlWithParameters(
    url.basename,
    url.action,
    url.params,
    gets
  ),
  WORKER: {
    DETAIL:
      (user_uid, gets=null) => getOrganizationUrlWithParameters(
        "external|employee|worker|main|position|title",
        "title",
        {user: user_uid},
        gets
      ),
  },
}
