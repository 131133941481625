<template>
 <EducationWorks v-if="EducationWorks" :EducationWorks="EducationWorks"></EducationWorks>
</template>

<script>
    import {loadData, setUrlParameters} from "@/helper";
    import {generateUidToName} from "@/helper/uidToName";
    import EducationWorks from "@/modules/templates/work/EducationWorks"
    import student from "@/urls/student";

    export default {
        name: "DetailEducationWorks",
        components:{EducationWorks},
        data: function () {
            return {
              EducationWorks: null,
              URL_WORK_DETAIL: student.STUDENT.INDIVIDUAL_WORK.WORK_DETAIL(this.$route.params.idWorks, this.$route.params.idStudent),
            }
        },
        methods: {
          loadData(){
            let url = this.URL_WORK_DETAIL
            loadData(url, (data) => {
              this.EducationWorks = data;
              this.$set(this.EducationWorks, "fio", "Работа еще никем не проверена")
              generateUidToName(this.EducationWorks, "teacher", "fio")

              this.$set(this.EducationWorks.document.file, "fio_student", "Не указано")
              generateUidToName(this.EducationWorks.document.file, "loader", "fio_student")
            })
          }
        },
        created() {
          this.loadData()
        }
    }
</script>

<style scoped>

</style>
