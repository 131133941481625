<template>
    <v-card class="mx-auto" min-width="300px">
      <v-btn icon absolute right class="mx-0" style="right: 5px; top: 5px;" @click="$emit('close')">
        <v-icon>close</v-icon>
      </v-btn>
      <v-card-title>
        <v-row class="mx-0 px-0 pt-2">
          <v-col cols="12" md="6" class="mx-0 px-0 text-left" order-sm="2">
            Назначение научных работников
          </v-col>
          <v-col cols="12" md="6" class="mx-0 px-0" order-sm="3">
            <v-text-field
              dense
              hide-details
              label="Поиск"
              v-model="search"
              append-icon="search"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="json"
          :items-per-page="json.length"
          :search="search"
          hide-default-footer
          :loading="loading"
        >
          <template v-slot:item="{item, isMobile, headers}">
            <tr v-if="!isMobile">
              <td>{{item.student_fio}}</td>
              <td>
                <v-select
                  v-model="item.scientific_director"
                  :items="teachers"
                  clearable
                  :loading="loading"
                  :disabled="loading"
                  :error="errors.haveError(item)"
                  :error-messages="errors.getMessage(item, 'scientific_director')"
                ></v-select>
              </td>
            </tr>
            <tr v-else>
              <td>
                <v-row>
                  <v-col class="text-left" cols="12">
                    {{headers[0].text}}
                  </v-col>
                  <v-col cols="12">
                    {{item.student_fio}}
                  </v-col>
                </v-row>
                <v-row justify="space-around">
                  <v-col class="text-left" cols="12">
                    {{headers[1].text}}
                  </v-col>
                  <v-col cols="12">
                    <v-autocomplete
                      v-model="item.scientific_director"
                      :items="teachers"
                      full-width
                      clearable
                      :loading="loading"
                      :disabled="loading"
                      :error="errors.haveError(item)"
                      :error-messages="errors.getMessage(item, 'scientific_director')"
                      @change="errors.clearError(item, 'scientific_director', $event)"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </td>
            </tr>
          </template>
        </v-data-table>
        <v-row>
          <v-col>
            <v-btn
              width="100%"
              color="success"
              @click="saveScienceDirectors"
              style="border-bottom-right-radius: 0; border-bottom-left-radius: 0;"
              :loading="loading"
              :disabled="loading"
            >
              Сохранить
            </v-btn>
          </v-col>
        </v-row>

      </v-card-text>
      <v-snackbar
        v-model="notifi_open"
        timeout="3000"
        :color="saved?'success':error?'warning':'info'"
        top
        right
        app
        style="border-top-left-radius: 0; border-top-right-radius: 0;"
      >
        {{saved?'Список научных руководителей успешно сохранен':error?'Список научных руководителей не сохранен из за ошибки':'Нет изменений для сохранения'}}
      </v-snackbar>
<!--      <DebugJsonComponent :json="errors" status="errors"></DebugJsonComponent>-->
    </v-card>
</template>

<script>
  import cathedras from "../../../../urls/cathedras";
  import DebugJsonComponent from "../../../core/components/DebugJsonComponent";
  import {loadData, sendPostRequest} from "../../../../helper";
  // import LoadData from "../../../dean/mixins/LoadData";
  import {generateListUidToName} from "../../../../helper/uidToName";
  import selectors from "../../../../urls/selectors";

  export default {
    name: "ScienceDirectorChanger",
    components: {DebugJsonComponent},
    props: {
      group_id: {
        requirement: true,
        type: Number,
      }
    },
    data: function () {
      return {
        loading: false,
        saved: undefined,
        notifi_open: false,
        error: false,
        errors: {
          data: [],
          haveError(item){
            return this.data.findIndex(el => {return el.id === item.id}) !== -1;
          },
          getMessage(item, field){
            let index = this.data.findIndex(el => {return el.id === item.id});
            if(index !== -1)
              return this.data[index][field];
            return '';
          },
          clearError(item, field){
            let index = this.data.findIndex(el => {return el.id === item.id});
            if(index !== -1)
              delete this.data[index][field];
          }
        },
        posteddata: [],
        json: [],
        headers: [
          {text: 'Обучающийся', value: 'student_fio', width: '40%', align: 'center', sortable: false},
          {text: 'Научный руководитель', value: 'teacher_fio', width: '60%', align: 'center', sortable: false}
        ],
        teachers: [],
        search: '',
      };
    },
    methods: {
      loadScienceDirectors: function (){
        this.loading = true;
        loadData(
          cathedras.CATHEDRA.GROUPS.SCIENCE_DIRECTORS.LIST(this.$route.params.cathedra_uid, this.group_id),
          (res) => {
            this.json = res;
            this.json.forEach((el) => {
              this.$set(el, 'student_fio', '');
              this.$set(el, 'teacher_fio', '');
              this.$set(el, 'old_scientific_director', el.scientific_director);
            });
            generateListUidToName(this.json, 'student', 'student_fio');
            generateListUidToName(this.json, 'scientific_director', 'teacher_fio')
            this.loading = false;
          }
        )
      },
      saveScienceDirectors: function (){
        this.loading = true;
        this.errors.data = [];
        let data = {'students': []};
        this.posteddata = data;
        this.json.forEach((el)=> {
          if(el.scientific_director !== el.old_scientific_director)
            data.students.push({
              student: el.id,
              scientific_director: el.scientific_director,
            });
        });
        if(data.students.length !== 0) {
          this.error = false;
          sendPostRequest(
            cathedras.CATHEDRA.GROUPS.SCIENCE_DIRECTORS.SAVE(this.$route.params.cathedra_uid, this.group_id),
            data,
            (res) => {
              this.errors.data = res.students;
              this.saved = false;
              this.error = true;
              this.loading = false;
              this.notifi_open = true;
            },
            () => {
              this.saved = true;
              this.loading = false;
              this.notifi_open = true;
            }
          )
        }else{
          this.error = false;
          this.saved = false;
          this.loading = false;
          this.notifi_open = true;
        }
      },
      loadTeachers: function () {
        this.loading = true;
        loadData(
          selectors.SELECTORS.WORKERS.CATHEDRA_PPS(this.$route.params.cathedra_uid),
          (data) => {
            this.teachers = data;
            this.teachers.forEach(el => {
              this.$set(el, 'fio', '');
              this.$set(el, 'value', el.text.split(',')[0].trim());
              el.text = el.text.split(',')[1].trim();
            });
            generateListUidToName(this.teachers, 'value', 'fio', (data) => {
              data.forEach(el => {
                el.text = `${el.fio}, ${el.text}`
              });
              this.loading = false;
            });
          },
        )
      },
      // saveData: function () {
      //   loadData(
      //     selectors.SELECTORS.WORKERS.CATHEDRA(this.$route.params.cathedra_uid),
      //     (data) => {
      //       this.teachers.data = data;
      //       this.teachers.data.forEach(el => {
      //         this.$set(el, 'fio', '');
      //         this.$set(el, 'user', el.text.split(',')[0].trim());
      //         el.text = el.text.split(',')[1].trim();
      //       });
      //       generateListUidToName(this.teachers.data, 'user', 'fio', (data) => {
      //         data.forEach(el => {
      //           el.text = `${el.fio}, ${el.text}`
      //         });
      //       });
      //     },
      //   )
      // }
    },
    created() {
      this.loadScienceDirectors();
      this.loadTeachers();
    }
  }
</script>

<style scoped>

</style>
