<template>
  <v-card class="text-left">
    <v-card-text class="title" v-if="detail">
      <TransferTemplate :detail="detail" :journal="journal" :loading="loading" :save_process="save_process">
        <template v-slot:actions>
          <v-btn text v-if="detail.status==='инициирована'" color="red"  @click="rejectTransfer()">Отклонить заявку</v-btn>
        </template>
      </TransferTemplate>
    </v-card-text>
  </v-card>
</template>

<script>
  // import DebugJsonComponent from "../../../core/components/DebugJsonComponent";
  import TransferTemplate from "@/modules/dean/pages/transferStudents/TransferTemplate";
  // import urls from "@/modules/dean/urls";
  import {displayDate, loadData, makeGetRequest, makePostRequest} from "@/helper";
  import {generateUidToName} from "@/helper/uidToName";
  // import JournalTable from "@/modules/cathedra/pages/journal/JournalTable";
  import dean from "@/urls/dean";

  export default {
    name: "TransferDetail",
    components: {
      // DebugJsonComponent,
      // JournalTable,
      TransferTemplate
    },
    watch: {
      typejournal: function (val) {
        this.loadJournal()
      },
    },
    data() {
      return {
        saved_data: undefined,
        save_process: {
          execute: false,
          success: undefined,
          errors: [],
          alert: {
            visible: false,
            message: ''
          },
        },
        selected: {},
        loading: false,
        detail: null,
        journal: [],
        typejournal: 1,
        search: '',
      }
    },
    methods: {
      setDate(date) {
        return displayDate(date)
      },
      rejectTransfer() {
        let url = dean.DEAN.TRANSFER.OUTGOING.CANCEL(this.$route.params.idFaculty, this.$route.params.idDetail)
          // urls.getUrl('dean|students|education|transfer|from-cancel-transfer', 'cancel_transfer')
          // .replace('<faculty_uid>', this.$route.params.idFaculty)
          // .replace('<id>', this.$route.params.idDetail);

        let data = {};
        let sys = true;
        makePostRequest(url, {}).then(resp => {
          if (!resp.ok) {
            sys = false
          }
          return resp.json()
        }).then(json => {
          if (sys)
            this.loadData()
        })
      },
      loadJournal() {
        loadData(
          dean.DEAN.TRANSFER.OUTGOING.JOURNAL(this.$route.params.idFaculty, this.$route.params.idDetail, this.typejournal),
          // urls.getUrl('dean|students|education|transfer|from-journal', 'journal')
          // .replace('<id>', this.$route.params.idDetail)
          // .replace('<faculty_uid>', this.$route.params.idFaculty) + '?from=' + this.typejournal,
          (data) => {
            this.journal = data;
            this.journal.forEach(el => {
              this.$set(el, 'old_exam', el.exam);
              el.medium_control.forEach(el => {
                this.$set(el, 'old_value', el.value)
              });
            });
            // this.attachment = data.url.attachment;
          }
        )
      },
      loadData: function () {
          // urls.getUrl('dean|students|education|transfer|from-detail', 'retrieve')
          // .replace('<faculty_uid>', this.$route.params.idFaculty)
          // .replace('<id>', this.$route.params.idDetail);
        loadData(
          dean.DEAN.TRANSFER.OUTGOING.DETAIL(this.$route.params.idFaculty, this.$route.params.idDetail),
          (data) => {
            this.detail = data;
            generateUidToName(this.detail, 'student', 'student')
            this.loadJournal()
          },
          (error) => {
            console.log(error);
          }
        );
      },
    },
    created() {
      this.loadData()

    }
  }
</script>

<style scoped>

</style>
