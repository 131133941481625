<template>
  <v-container class="page-content px-5" style="  width: 100%">
    <v-toolbar
      class="removeBtnPaddingRight rounded-t-lg"
      color="#DADADA"
      flat
    >
      <v-text-field
        single-line
        outlined
        dense
        clearable
        class="dark-primary"
        :disabled="isLoading"
        v-model="search.value" label="Поиск" hide-details @keydown.enter="searchData">
      </v-text-field>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="items"
      :loading="isLoading"
      :page.sync="pagination.page"
      :items-per-page.sync="pagination.size"
      :server-items-length="count"
      :options.sync="options"
      :footer-props="{'items-per-page-options':[10,50,100]}"
      class="elevation-1 rounded-b-lg"
    >
<!--      <template v-slot:item.actions="{ item }">-->
<!--&lt;!&ndash;        <reference-action&ndash;&gt;-->
<!--&lt;!&ndash;          :object-name="`${item.name}[${item.code}]`"&ndash;&gt;-->
<!--&lt;!&ndash;          type-name="Направление подготовки"&ndash;&gt;-->
<!--&lt;!&ndash;          :item="item"&ndash;&gt;-->
<!--&lt;!&ndash;          :action-urls="actionsUrls"&ndash;&gt;-->
<!--&lt;!&ndash;        >&ndash;&gt;-->
<!--&lt;!&ndash;        </reference-action>&ndash;&gt;-->
<!--      </template>-->
      <template v-slot:item.link="{ item }">
        <related-link-indicator :item="item"></related-link-indicator>
      </template>
      <template v-slot:no-data>
        <v-btn
          color="primary"
          @click="updateData"
        >
          Обновить
        </v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>


<script>
import {mapActions, mapGetters} from "vuex";
import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
import TitledPageMixin from "@/mixins/TitledPageMixin";
import RelatedLinkIndicator from "@/modules/administrative/pages/connector/components/RelatedLinkIndicator.vue";
import ReferenceAction from "@/modules/administrative/pages/connector/references/RefrenceActions.vue"
import urls from "@/urls/admin";
import selectors from "@/urls/selectors";

export default {
  name: "AdminDirectionList",
  components: {RelatedLinkIndicator, ReferenceAction},
  mixins: [TitledPageMixin, PaginatedDataMapperMixin],
  data() {
    return {
      page_title: "Список направлений подготовки",
      dialog_add_edit: false,
      dialog_delete: false,
      edit_status: undefined,
      cars_data: undefined,
      search: {
        parameter: 'search',
      },
      pagination: {
        filters: {
          'model': null,
        },
      },
      selectors: {
        model: [],
      },
      headers: [
        {text: 'Код', value: 'code', align: 'start', sortable: true, width: '10%'},
        {text: 'Наименование', value: 'name', align: 'start', sortable: true, width: '40%'},
        {text: 'Идентификатор', value: 'guid', align: 'start', sortable: true, width: '20%'},
        {text: 'Связан', value: 'link', sortable: false, align: 'center', width: '10%'},
        // {text: 'Действия', value: 'actions', sortable: false, align: 'right', width: '20%'},
      ],
      actionsUrls: {
        link: {
          action_url: urls.ADMIN.REFERENCES.DIRECTION.LINK,
          selector_url: selectors.SELECTORS.UNIVERSITY.DIRECTION()
        },
        update_related: {
          action_url: urls.ADMIN.REFERENCES.DIRECTION.UPDATE_RELATED,
        },
        update_relation_identity: {
          action_url: urls.ADMIN.REFERENCES.DIRECTION.UPDATE_RELATION_IDENTIFY,
        },
        clear: {
          action_url: urls.ADMIN.REFERENCES.DIRECTION.CLEAR
        },
        create_related: {
          action_url: urls.ADMIN.REFERENCES.DIRECTION.CREATE_RELATED
        },
      }
    }
  },
  computed: {
    ...mapGetters({
      items: 'admin/references/directions/getDirectionItems',
      count: 'admin/references/directions/getDirectionCount',
      isLoading: 'admin/references/directions/isDirectionLoading',
      getCurrentUrl: 'admin/references/directions/getDirectionCurrentUrl'
    }),
  },
  watch: {
    car_filter_model() {
      if (this.car_filter_model !== null) {
        this.pagination.filters.model = this.selectors.model[this.car_filter_model - 1].text
      } else {
        this.pagination.filters.model = null
      }
    }
  },
  methods: {
    ...mapActions({
      loadDirectionList: 'admin/references/directions/loadDirectionList',
    }),
    // getDetail(item) {
    //   this.$router.push({name: names.REFERENCE.DIRECTIONS, params: {idDirection: item.id}})
    // },
    updateData(){
      if (this.search.value) {
        this.search.value = ''
      }
      this.pagination.filters = {
        'model': null,
      }
      this.car_filter_model = null
      this.loadData()
    },
    loadData(url = undefined) {
      this.loadDirectionList({
        url: url,
        urlAppend: (url) => {
          return this.addPageParameter(this.addSizeParameter(url));
        },
        finalizer: (data, url) => {
          this.setCurrentLoadUrl(url);
        }
      })
    },
    // loadSelectors() {
    //   this.loadDirectionModelsSelector({
    //     url: undefined,
    //     finalizer: (val) => {
    //       this.selectors.model = val
    //     }
    //   })
    // },
  },
  created() {
    // if (admin.ADMIN.ALLOWED()){
    //   if (this.getCurrentUrl) {
    //     this.loadFromSavedUrl(this.getCurrentUrl)
    //   } else {
        this.loadData()
    //   }
    //   this.loadSelectors()
    // }
    // else{
    //   this.$router.push({name: names_core.MAIN})
    // }
  },
}
</script>

<style scoped>
.removeBtnPaddingRight>>>.v-toolbar__content{
  padding-right: 0px;
}
</style>
