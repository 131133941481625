<template>
  <scroll-center-dialog
    v-model="opened"
    width="600"
    @close="CloseForm"
    @apply="save"
    :disabled="isDisabled(semester)"
    :title="semester_id ? 'Изменения семестра дисциплины уч. плана' : 'Добавление семестра дисциплины уч. плана'"
  >
    <v-card-text v-if="semester_id || subject_name">
      {{ subjectname }}
    </v-card-text>
    <v-autocomplete
      v-else
      label="Предмет"
      :items="choices.subject"
      v-model="semester.subject"
      :error="hasError('subject')"
      :error-messages="getError('subject')"
      @input="deleteError('subject')"
    >
    </v-autocomplete>
    <v-autocomplete
      label="Семестр"
      :items="choices.semester_list"
      v-model="selected"
      :error="hasError('semester')"
      :error-messages="getError('semester')"
      @input="deleteError('semester')"
    >
    </v-autocomplete>
    <v-autocomplete
      label="Тип контроля"
      :items="choices.control_type"
      v-model="semester.control_type"
      :error="hasError('control_type')"
      :error-messages="getError('control_type')"
      @input="deleteError('control_type')"
    >
    </v-autocomplete>
    <v-alert type="info" outlined>
      Важно!
      При изменении или удалении учебной кафедры, преподаватели, связанные с этой кафедрой и преподающие дисциплину, также будут удалены из списка преподавателей этой дисциплины.<br>
      В случае, если преподаватель работает на нескольких кафедрах, связанных с данным учебным планом, он все равно будет удален из списка преподавателей этой дисциплины.
    </v-alert>
    <v-autocomplete
      class="my-5"
      :key="`selected_cathedra`"
      @change="addToSelected($event)"
      :items="add_cathedra.filteredSelector()"
      :value="add_cathedra.last"
      :loading="add_cathedra.loading"
      :disabled="add_cathedra.loading"
      :error="hasError('cathedrals')"
      :error-messages="getError('cathedrals')"
      :hide-details="!hasError('cathedrals')"
      @input="deleteError('cathedrals')"
      clearable
      label="Выберите кафедру"
    ></v-autocomplete>
    <template v-for="(selected, index) in add_cathedra.selected">
      <v-autocomplete
        v-model="add_cathedra.selected[index]"
        :items="add_cathedra.filteredSelector(selected)"
        :key="`selected_cathedra_${selected}`"
        @change="updateSelected($event, selected)"
        :loading="add_cathedra.loading"
        :disabled="add_cathedra.loading"
        :error="hasErrorCathedra(selected)"
        :error-messages="getErrorCathedra(selected)"
        :hide-details="!hasErrorCathedra(selected)"
        label="Кафедра"
        class="my-3"
      >
        <template v-slot:prepend>
          <v-btn icon @click="removeFromSelected(selected)">
            <v-icon>remove</v-icon>
          </v-btn>
        </template>
      </v-autocomplete>
    </template>
    <v-text-field
      label="Количество зачетных единиц"
      v-model="zets"
      type="number"
      :error="hasError('zets')"
      :error-messages="getError('zets')"
      @input="deleteError('zets')"
    ></v-text-field>
    <v-text-field
      label="Количество часов"
      v-model="semester.hours"
      type="number"
      :error="hasError('hours')"
      :error-messages="getError('hours')"
      @input="deleteError('hours')"
    ></v-text-field>
    <v-checkbox
      label="Предмет по выбору"
      v-model="semester.optionally"
      :error="hasError('optionally')"
      :error-messages="getError('optionally')"
    ></v-checkbox>
    <v-autocomplete
      v-if="semester.optionally"
      label="Номер группы выбора"
      :items="selectors.optionally_group_number"
      v-model="group_number"
      :error="hasError('optionally_group')"
      :error-messages="getError('optionally_group')"
      @input="deleteError('optionally_group')"
    >
    </v-autocomplete>
    <template v-if="group_number">
      <div class="subtitle-1 text-left">
        Список предметов по выбору в группе:
      </div>
      <v-row v-for="item in selectors.optionally_group" :key="item.value" class="mt-2">
        <v-col cols="12" class="text-left">
          {{ item.text }}
        </v-col>
      </v-row>
    </template>
    <v-alert type="error" :key="`error_${index}`" v-for="(error, index) in errors.non" dismissible class="my-1"
             rounded="0"> {{ error }}
    </v-alert>
    <v-alert type="error" v-if="hasError('subject')">
      {{ String(getError('subject')) }}
    </v-alert>
  </scroll-center-dialog>
</template>

<script>
  import DebugJsonComponent from "../../../core/components/DebugJsonComponent";
  import {loadData,makePostRequest, makeParticalUpdateRequest, makeGetRequest, sendPostRequest} from "../../../../helper";
  import urls from "../../urls";
  import choices from "../../../core/urls/choices";
  import FormErrorsMixin from "@/mixins/FormErrorsMixin";
  import selectors from "@/urls/selectors"
  import select_option from "@/helper/selectors"
  import ScrollCenterDialog from "@/modules/core/components/ScrollCenterDialog";


  export default {
    name: "SemestersForm",
    mixins: [
      FormErrorsMixin,
    ],
    components: {
      ScrollCenterDialog,
      DebugJsonComponent
    },
    props: {
      semester_id: Number,
      curriculum_id: undefined,
      subject_name: undefined,
      opened: Boolean,
      subject: {
        type: Number,
        default: undefined,
      },
    },
    data(){
      return {
        error:null,
        subjectname:null,
        hours: null,
        curriculum_subject:null,
        curriculum:{
          level : '',
          form : '',
        },
        semester: {
          subject: null,
          course: null,
          semester: null,
          cathedrals: null,
          control_type: null,
          hours: 0,
          zets: 0,
          optionally: false,
          optionally_group: null,
        },
        add_cathedra: {
          selector: [],
          selected: [],
          filteredSelector (value=null) {return this.selector.filter(el => this.selected.indexOf(el.value) === -1 || el.value === value)},
          last: '',
          opened: false,
          loading: false
        },
        choices: {
          course: [],
          semester: [],
          cathedra: [],
          control_type: [],
          subject: [],
          semester_list: [{
            text: null,
            value: null
          }],
        },
        selectors:{
          optionally_group_number: null,
          optionally_group: null,
        },
        course_number: null,
        selected: null,
        group_number: null,
        zets: 0,
        subject_optionally_group: null,
        cathedrals_current: [],
        cathedrals_old: [],
        cathedrals: {
          create: [],
          update: [],
          delete: [],
        },
        cathedrals_error: [],
      }
    },
    watch: {
      selected: function () {
        this.semesterSelect()
      },
      group_number(){
        this.selectors.optionally_group = null,
          this.loadSelector('optionally_group',
            selectors.SELECTORS.CURRICULUM.SEMESTERS.OPTIONALLY_GROUP(
              this.$route.params.curriculum_id,
              {optionally_group: this.group_number }
            )
          )
        this.semester.optionally_group = this.group_number
      },
      zets(){
        if(this.zets === "0.00" && this.hours !== "0.00"){
          this.semester.hours = this.hours
        }
        else {
          this.semester.hours = this.zets*36
        }
      }
    },
    methods: {
      changeCathedrals(){
        let url =  urls.NAMES.EDITOR.SEMESTER.CATHEDRA.UPDATE(this.curriculum_subject, this.semester_id)
        sendPostRequest(
          url,
          this.cathedrals,
          ()=>{
            this.update()
          },
          (res)=>{
            this.add_cathedra.selected = []
            this.cathedrals_old.forEach(el => {
              this.add_cathedra.selected.push(el)
            })
            this.cathedrals.create = []
            this.cathedrals.update = []
            this.cathedrals.delete = []
            this.cathedrals_error = res
          },
        )
      },
      hasErrorCathedra: function (cathedra) {
        if(this.cathedrals_error.errors!== undefined && this.cathedrals_error.errors.length > 0){
          for (let el of this.cathedrals_error.errors){
            let idx = el.findIndex(el1 => el1.uid === cathedra)
            if(idx !== -1) {
              return true
            }
          }
        }
        return false
      },
      getErrorCathedra: function (cathedra) {
        if(this.cathedrals_error.errors!== undefined && this.cathedrals_error.errors.length > 0){
          for (let el of this.cathedrals_error.errors){
            let idx = el.findIndex(el1 => el1.uid === cathedra)
            if(idx !== -1) {
              if (el[idx].cathedra !== undefined)
                return el[idx].cathedra
              else if (el[idx].old_cathedra !== undefined)
                return el[idx].old_cathedra
              else (el[idx].new_cathedra !== undefined)
                return el[idx].new_cathedra
            }
          }
        }
        return []
      },
      addToSelected(value){
        if (value !== '' && value !== undefined && value !== null){
          this.add_cathedra.selected.push(value)
          this.add_cathedra.last = ''
          if(this.semester_id) {
            let idx = this.cathedrals_current.findIndex(el => el === value)
            let delete_index = this.cathedrals.delete.findIndex(el => el === value)
            if(delete_index !== -1) {
              this.cathedrals.delete.splice(delete_index, 1)
              this.cathedrals_current.push(value)
            }
            else if(idx === -1){
              this.cathedrals_current.push(value)
              this.cathedrals.create.push(value)
            }
          }
        }
      },
      updateSelected(value, old){
        if(this.semester_id){
          let idx = this.cathedrals_current.findIndex(el => el === old)
          let update_index = this.cathedrals.update.findIndex(el => el.new === old)
          let create_index = this.cathedrals.create.findIndex(el => el === old)
          if(create_index !== -1){
            this.cathedrals.create[create_index] = value
            this.cathedrals_current[idx] = value
          }
          else if(update_index !== -1) {
            this.cathedrals.update[update_index].new = value
            this.cathedrals_current[idx] = value
          }
          else {
            let oldest_value = this.cathedrals_current[idx]
            this.cathedrals.update.push({"new": value, "old":  oldest_value})
            this.cathedrals_current[idx] = value
          }
        }
      },
      removeFromSelected(value) {
        this.add_cathedra.selected.splice(this.add_cathedra.selected.indexOf(value), 1);
        this.add_cathedra.last = ''
        if(this.semester_id) {
          let idx = this.cathedrals_current.findIndex(el => el === value)
          let create_index = this.cathedrals.create.findIndex(el => el === value)
          let update_index = this.cathedrals.update.findIndex(el => el.new === value)
          if(create_index !== -1) {
            this.cathedrals.create.splice(create_index, 1)
            this.cathedrals_current.splice(this.cathedrals_current.indexOf(value), 1)
          }
          else if(update_index !== -1){
            let update_old = this.cathedrals.update[update_index].old
            this.cathedrals_current.splice(update_index, 1)
            this.cathedrals.update.splice(this.cathedrals.update.indexOf(update_old), 1)
            this.cathedrals.delete.push(update_old)
          }
          else if(idx !== -1){
            this.cathedrals_current.splice(this.cathedrals_current.indexOf(value), 1)
            this.cathedrals.delete.push(value)
          }
        }
      },
      isDisabled(semester){
        if(!semester.optionally)
          return false
        else{
          if(semester.optionally && semester.optionally_group != null && semester.optionally_group != 0)
            return false
          else
            return true
        }
      },
      semesterSelect() {
        let value
        if (this.selected['value'])
          value = this.selected['value'].split(":")
        else
          value = this.selected.split(":")
        this.semester.course = Number(value[0])
        this.semester.semester = value[1]
      },
      getCourseSemesterSelect(){
        let num = null
        if (this.semester.semester === "весенний") {
          num = this.course_number * 2
        }
        if (this.semester.semester === "осенний") {
          num = this.course_number * 2 - 1
        }
        return {
          text: `${num} (${this.semester.semester} семестр, ${this.course_number} курс)`,
          value: `${this.semester.course}:${this.semester.semester}`
        }
      },
      createCourseSemesterList(){
        let count = 1
        this.choices.semester_list.splice(0, 1)
        for (let course in this.choices.course) {
          if(course === "2" && this.curriculum.level === 'магистратура' && this.curriculum.form === 'очная') break
          for (let semester in this.choices.semester) {
            if(course === "2" && semester === "1" && this.curriculum.level === 'магистратура' && this.curriculum.form === 'заочная') break
            this.choices.semester_list.push({
              text: `${count} (${this.choices.semester[semester].text} семестр, ${this.choices.course[course].text} курс)`,
              value: `${this.choices.course[course].value}:${this.choices.semester[semester].text}`
            })
            count++
          }
        }
      },
      loadData(){
        let catcher = (error) => {
          // console.log(error);
        };
        let saver = (data) => {
          this.curriculum_subject = data.curriculum_subject
          this.semester.course = data.course.id
          this.course_number = data.course.number
          this.semester.semester= data.semester
          this.semester.cathedrals = data.cathedrals
          this.semester.control_type= data.control_type
          this.subjectname= data.subject.name
          this.semester.subject= data.subject.id
          this.hours= data.hours
          this.zets= data.zets
          this.semester.optionally = data.optionally
          if(data.optionally)
            this.group_number = data.optionally_group
          this.selected = this.getCourseSemesterSelect()
          data.cathedrals.forEach(el => {
            this.add_cathedra.selected.push(el.uid)
            this.cathedrals_current.push(el.uid)
            this.cathedrals_old.push(el.uid)
          })
        }
        let url = urls.NAMES.EDITOR.SEMESTER.RETRIEVE(Number(this.curriculum_id),Number(this.semester_id));
        loadData(url, saver, catcher);
      },
      loadChoices(url, choices, finalizer=()=>{}){
        loadData(
          url,
          (data) => {
            this.choices[choices] = data;
            finalizer();
          }
        )
      },
      loadCurriculum(){
        loadData(
          urls.NAMES.EDITOR.CURRICULUM.RETRIEVE(this.curriculum_id),
          (data) => {
            this.curriculum.form = data.form
            this.curriculum.level = data.level
            this.loadCourses(this.curriculum.level)
          },
          (error) => {
            // console.log(error);
          })
      },
      loadCourses(level){
        this.loadChoices(choices.SELECTORS.COURSES({level: level}), 'course', () => {
          this.createCourseSemesterList()
        });
      },
      loadSubjects(){
        this.loadChoices(choices.SELECTORS.SUBJECTS_CURRICULUM(this.curriculum_id), 'subject');
      },
      loadSemesters(){
        this.loadChoices(choices.CHOICES.SEMESTERS(), 'semester');
      },
      loadCathedra(){
        this.add_cathedra.opened = true
        this.add_cathedra.loading = true
        loadData(
          choices.SELECTORS.CATHEDRAS(),
          (data) => {
            this.add_cathedra.selector = data
            this.add_cathedra.loading = false
            this.add_cathedra.last = ''
          }
        )
      },
      loadControlType(){
        this.loadChoices(choices.CHOICES.CONTROL_TYPE(), 'control_type')
      },
      create(){
        if (this.subject_name)
          this.semester.subject = this.subject
        this.error = null
        let sys = true
        let url = urls.NAMES.EDITOR.SEMESTER.CREATE(Number(this.curriculum_id))
        this.semester.cathedrals = this.add_cathedra.selected
        let clone = Object.assign({}, this.semester)
        if(!clone.optionally_group)
          delete clone.optionally_group
        makePostRequest(url,clone).then(resp => {
          if (!resp.ok) {
            sys = false
          }
          return resp.json()
        }) .then(json => {
          if(sys)
            this.$emit('close')
          else {
            if(json.hasOwnProperty('detail')){
              this.errors.non.push(json['detail'])
            }
            this.mapErrors(json)
          }
        })
      },
      update(){
        this.error = null
        let sys = true
        if(this.cathedrals_copy !== this.add_cathedra.selected){
          this.semester.cathedrals = this.add_cathedra.selected
        }
        let url = urls.NAMES.EDITOR.SUBJECT.SEMESTER.PARTIAL_UPDATE(this.curriculum_subject,Number(this.semester_id))
        let clone = Object.assign({}, this.semester);
        delete clone.subject;
        if(!clone.optionally_group)
          delete clone.optionally_group;
        makeParticalUpdateRequest(url,clone).then(resp => {
          if (!resp.ok) {
            sys = false
          }
          return resp.json()
        }) .then(json => {
          if(sys)
            this.$emit('close')
          else {
            if(json.hasOwnProperty('detail')){
              this.errors.non.push(json['detail'])
            }
            // else{
            this.mapErrors(json)
            // }
          }
        })
      },
      save(){
        this.semester.zets = this.zets
        if(this.semester_id)
          this.changeCathedrals()
        else
          this.create()
      },
      loadSelector(data, url) {
        let sys = true
        makeGetRequest(url).then(resp => {
          if (!resp.ok) {
            sys = false
          }
          return resp.json()
        }).then(json => {
          this.selectors[data] = json
        })
      },
      CloseForm(){
        this.add_cathedra.opened = false;
        this.add_cathedra.selected = [];
        this.add_cathedra.selector = [];
        this.last = '';
        this.$emit('close', false)
      }
    },
    created() {
      this.loadSemesters();
      this.selectors.optionally_group_number = select_option.optionally_group_number
      this.loadControlType();
      this.loadCurriculum()
      this.loadCathedra();
      this.loadSubjects();
      if(this.semester_id)
        this.loadData();
      else
        this.subjectname = this.subject_name
    }
  }
</script>

<style scoped>

</style>
