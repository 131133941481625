<template>
  <v-container>
    <v-card v-if="subjects.loaded">
      <v-toolbar class="elevation-0" color="style-color-main-gray" v-if="subjects.data.subject">
        <v-toolbar-title>
          Предмет учебного плана: {{ subjects.data.subject }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog=true">
          <v-icon>edit</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card flat class="px-6">
        <v-row no-gutters class="text-left subtitle-1 py-1">
          <v-col cols="3">Учебный год:</v-col>
          <v-col cols="9">{{subjects.data.curriculum.year}}</v-col>
        </v-row>
        <v-row no-gutters class="text-left subtitle-1 py-1">
          <v-col cols="3">Форма:</v-col>
          <v-col cols="9">{{subjects.data.curriculum.form}}</v-col>
        </v-row>
        <v-row no-gutters class="text-left subtitle-1 py-1">
          <v-col cols="3">Уровень образования:</v-col>
          <v-col cols="9">{{ subjects.data.curriculum.direction.level }}</v-col>
        </v-row>
        <v-row no-gutters class="text-left subtitle-1 py-1">
          <v-col cols="3">Профиль:</v-col>
          <v-col cols="9">{{subjects.data.curriculum.profile}}</v-col>
        </v-row>
        <v-row no-gutters class="text-left subtitle-1 py-1">
          <v-col cols="3">Направление:</v-col>
          <v-col cols="9">{{ subjects.data.curriculum.direction.code }} {{ subjects.data.curriculum.direction.name }}</v-col>
        </v-row>
        <v-row no-gutters class="text-left subtitle-1 py-1">
          <v-col cols="3"> Файл рабочей программы:</v-col>
          <v-col cols="9" v-if="subjects.data.work_program">
            {{subjects.data.work_program.file_name}}
            <v-btn @click="loadFileWork(subjects.data.work_program)" icon><v-icon >get_app</v-icon></v-btn>
          </v-col>
          <v-col cols="9" v-else>Файл не загружен</v-col>
        </v-row>
        <v-row no-gutters class="text-left subtitle-1 py-1">
          <v-col cols="3">Файл методических материалов:</v-col>
          <v-col cols="9" v-if="subjects.data.methodological_materials">
            {{subjects.data.methodological_materials.file_name}}
            <v-btn @click="loadFileWork(subjects.data.methodological_materials)" icon><v-icon >get_app</v-icon></v-btn>
          </v-col>
          <v-col cols="9" v-else>Файл не загружен</v-col>
        </v-row>
        <v-row no-gutters class="text-left subtitle-1 py-1">
          <v-col cols="3">Файл оценочных средств:</v-col>
          <v-col cols="9" v-if="subjects.data.appraisal_tools">
            {{subjects.data.appraisal_tools.file_name}}
            <v-btn @click="loadFileWork(subjects.data.appraisal_tools)" icon><v-icon >get_app</v-icon></v-btn>
          </v-col>
          <v-col cols="9" v-else>Файл не загружен</v-col>
        </v-row>
      </v-card>
      <Form v-if="dialog"
            :opened="dialog"
            :appraisal_tools="subjects.data.appraisal_tools"
            :work_program="subjects.data.work_program"
            :methodological_materials="subjects.data.methodological_materials"
        @close="closeCreateForm"></Form>

    </v-card>
  </v-container>
</template>

<script>
import DebugJsonComponent from "../../../core/components/DebugJsonComponent";
import {loadData} from "@/helper";
import urls from "@/modules/teacher/urls";
import TitledPageMixin from "@/mixins/TitledPageMixin";
import Form from "@/modules/cathedra/pages/document_subjects/Form.vue";
import {loadFile} from "@/helper/fileLoader";

export default {
  name: "Detail",
  mixins: [TitledPageMixin],
  components: {
    DebugJsonComponent,
    Form
  },
  props: {
    // subject_id: Number,
    // curriculum_id: Number,
  },
  data() {
    return {
      page_title: 'Предмет',
      dialog:false,
      subjects: {
        data: {},
        loading: false,
        loaded: false,
      }
    }
  },
  methods: {
    loadFileWork(url){
      loadFile(url)
    },
    closeCreateForm: function (){
      this.dialog = false
      this.load();
    },
    load: function () {
      let saver = (data) => {
        this.subjects.data = data;
        this.subjects.loaded = true;

      };
      let catcher = (error) => {console.log(error);};
      let url = urls.getUrl('administrative|curriculum|subject-detail', 'retrieve')
          .replace('<cathedra_uid>', this.$route.params.cathedra_uid.toLowerCase())
          .replace('<id>', this.$route.params.idDoc)
      loadData(url, saver, catcher);
    },
  },
  created() {
    this.load();
  }
}
</script>

<style scoped>

</style>
