<template>
  <scroll-center-dialog v-model="opened_form"
                        @close="closeDialog"
                        @apply="apply"
                        :loading="loading"
                        :title="!!id ? 'Изменить' : 'Добавить'"
                        width="500"
  >
    <v-text-field
      :error="hasError('started')"
      :error-messages="getError('started')"
      label="Начало сессии"
      v-model="value.started"
      type="date"
    ></v-text-field>
    <v-text-field
      :error="hasError('ended')"
      :error-messages="getError('ended')"
      label="Окончание сессии"
      v-model="value.ended"
      type="date"
    ></v-text-field>
    <v-checkbox
      label="Базовая"
      :error="hasError('original')"
      :error-messages="getError('original')"
      v-model="value.original"
    ></v-checkbox>
  </scroll-center-dialog>
</template>

<script>

import UserDataFormMixin from '@/mixins/UserDataFormMixin'
import urls from "@/urls/management";
import ScrollCenterDialog from "@/modules/core/components/ScrollCenterDialog";

export default {
  name: "SemesterSession",
  components: {ScrollCenterDialog},
  mixins: [UserDataFormMixin],
  props: {
    id: Number,
    original: Boolean,
    started: String,
    ended: String,
    opened_form: Boolean,
  },
  data() {
    return {
      URL_CREATE:  urls.EDUCATION.ACADEMIC_YEAR.YEAR_SEMESTER.SESSION.CREATE(this.$route.params.idSemesters),
      URL_UPDATE: urls.EDUCATION.ACADEMIC_YEAR.YEAR_SEMESTER.SESSION.UPDATE(this.$route.params.idSemesters, this.id),
      loading : false,
      oldValue: {},
      value: {
        id: this.id || undefined,
        started: this.started || undefined,
        ended: this.ended || undefined,
        original: this.original || false ,
      },
      // errors: {}
    }
  },
  methods: {
    closeDialog() {
      this.$emit('ended', '')
    },
  },
  created() {
    if (this.id) {
      this.Oldvalue = Object.assign({}, this.value)
    }
  }
}

</script>

<style scoped>

</style>
