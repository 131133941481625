<template>
<div></div>
</template>

<script>
import store from "@/store";
import teacher_names from "@/modules/teacher/routers/names";
import dean_names from "@/modules/dean/routers/names";
import cathedra_names from "@/modules/cathedra/routers/names";
import educational_names from "@/modules/educationalManagement/routers/names";
import editor_names from "@/modules/curriculumEditor/routers/names";

export default {
  name: "Roles",
  created() {
    if (store.getters['urls/getAllowed']( 'education', 'teacher|students|education-list', 'list'))
      this.$router.push({ name: teacher_names.BASE,});
    else if (store.getters['urls/getAllowed']( 'education', 'student|students|student', 'get'))
      this.$router.push({name: 'Profile'});
    else if (store.getters['urls/getAllowed']( 'education', 'dean|relations|faculty-list', 'list'))
      this.$router.push({name: dean_names.FACULTIES});
    else if (store.getters['urls/getAllowed']( 'education', 'administrative|relations|cathedra-list', 'list'))
      this.$router.push({name: cathedra_names.CathedraList});
    else if (store.getters['urls/getAllowed']( 'education', 'admin|core|course-detail', 'list'))
      this.$router.push({name: 'AdministrativeBreadcrumbs'});
    else if (store.getters['urls/getAllowed']( 'education', 'worker|ministry|training_profile-list', 'list'))
      this.$router.push({name: educational_names.EDUCATIONAL.BASE});
    else if (store.getters['urls/getAllowed']( 'education', 'editor|curriculum|curriculum-list', 'list'))
      this.$router.push({name: editor_names.CURRICULUM.LIST});
    else
      this.$router.push({name: 'LoadPortal'});
  }
}
</script>

<style scoped>

</style>
