<template>
  <v-container fluid>
    <EduDocList
      :createBtn=true
      :deleteBtn=true
      :updateBtn=true
      :items="items"
      @openConfirmDelete="openConfirmDelete"
      @updateItem="updateObj"
      @createItem="opened_form=true ; errors = {}"
    ></EduDocList>
    <EduDocForm
      :selector_doc_type="selector_doc_type"
      :errors="errors"
      :opened_form="opened_form"
      @ended="closedForm"
      @createItem="createItem"
      v-bind="selected"
      v-if="opened_form"
    >
    </EduDocForm>
    <v-dialog persistent v-model="opened_confirm" width="500">
      <v-card v-if="deletable">
        <v-card-title>Подтвердите удаление</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-4">
          Вы уверены, что хотите удалить?<br>
        </v-card-text>
        <v-card-actions>
          <v-flex>
            <v-btn
              @click="deleteItemId(deletable.id)"
              color="warning"
              text
            >
              Удалить
            </v-btn>
            <v-btn
              @click="closeConfirmDelete"
              text
            >Отмена
            </v-btn>
          </v-flex>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>

</template>

<script>
  import urls from "@/urls/dean"
  import choices from "@/urls/choices"
  import UserDataMixin from '@/mixins/UserDataMixin'
  import {finalizeRequest, loadData, makeGetRequest, makePostRequestFile,} from "@/helper";
  import EduDocList from "@/modules/templates/education_document/EduDocList"
  import EduDocForm from "@/modules/templates/education_document/EduDocForm"
  import TitledPageMixin from "@/mixins/TitledPageMixin";

export default {
  name: "EduDoc",
  components: {EduDocList,EduDocForm},
  mixins: [UserDataMixin, TitledPageMixin],

  data() {
    return {
      items: [],
      page_title: 'Документы об образовании',
      loading: true,
      selector_doc_type:[],
      URL_LIST: urls.DEAN.EDUDOC.LIST(this.$route.params.idFaculty,this.$route.params.idStudent),
      URL_DELETE: '',
      URL_CREATE:  '',
      URL_UPDATE:  '',
    }
  },

  created() {
    this.setPageTitleWithObject("Education", {id: this.$route.params.idStudent}, ' - ', true)
    this.getItems();
    let doc_type = choices.EDUCATION.CHOICES.DOCUMENT_TYPE()
    loadData(doc_type,(json)=>{
      this.selector_doc_type = json
    },(json)=>{
      console.log(json)
    })
  },
  methods: {
    deleteItemId(id){
      this.URL_DELETE = urls.DEAN.EDUDOC.DELETE(this.$route.params.idFaculty,this.$route.params.idStudent,id)
      this.deleteItem(id)
    },
    updateObj(item) {
      this.selected = item
      this.errors = {}
      this.opened_form = true
    },
    receiveErrors: function (json) {
      let have = false;
      for (let key in json) {
        if (json.hasOwnProperty(key)) {
          if (Array.isArray(json[key])) {
            have = true;
          }
        }
      }
      this.errors = json;
      return have;
    },
    createItem(item) {
      this.errors = {}
      let url = urls.DEAN.EDUDOC.CREATE(this.$route.params.idFaculty,this.$route.params.idStudent)
      let metod = 'POST'
      if (item.id) {
        url = urls.DEAN.EDUDOC.UPDATE(this.$route.params.idFaculty,this.$route.params.idStudent,item.id)
        metod = 'PATCH'
      }
      let formData = new FormData();
      formData.append('number', item.number)
      formData.append('type', item.type)
      formData.append('who', item.who)
      formData.append('when', item.when)
      formData.append('where', item.where)
      if (typeof item.document === 'object' && item.document !== null){
        if (String(item.document.name).length < 130 )
          formData.append('document', item.document)
        else {
          alert('Слишком длинное имя файла для загрузки')
          item.document = null
          return
        }
      }
      finalizeRequest(makePostRequestFile(url, formData, metod),
        (json) => {
          this.closedForm();
          this.getItems();
        },
        (json) => {
          this.receiveErrors(json);
        })
    },
  }
}


</script>

<style scoped>

</style>
