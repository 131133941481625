<template>
  <v-card flat dense tile class="py-0" v-if="lesson_kinds !== undefined">
    <v-subheader>Список форм занятий</v-subheader>
    <v-divider></v-divider>
    <v-data-table
      :headers="headers"
      :items="lesson_kinds"
      hide-default-header
      hide-default-footer
      no-data-text="Формы занятий не определены"
    ></v-data-table>
  </v-card>
</template>

<script>
export default {
  name: "LessonKindsTableTemplate",
  props: {
    lesson_kinds: {
      requirement: true,
      type: Array,
    }
  },
  data: function (){
    return {
      headers: [
        {text: 'Форма', value: 'kind'},
        {text: 'Часов в класе', value: 'classroom_hours'},
        {text: 'Часов самоподготовки', value: 'independent_hours'},
        {text: 'ЗЕТ', value: 'zets'}
      ]
    }
  },

}
</script>

<style scoped>

</style>
