<template>
<!--  width="500"-->
  <v-dialog persistent v-model="opened" width="500">
    <v-card>
      <v-card-title class="title primary white--text">
        <v-flex text-xs-center v-if="is_create">
          Добавление вида занятий
        </v-flex>
        <v-flex text-xs-center v-else>
          Изменения вида занятий
        </v-flex>
        <v-btn dark icon @click="$emit('close', false)">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-autocomplete
          label="Вид занятий"
          :error="hasError('kind')"
          :error-messages="getError('kind')"
          @input="deleteError('kind')"
          :items="choices.kinds"
          v-model="lesson_kind.kind"
        >
        </v-autocomplete>
        <v-text-field
          label="Количество часов"
          :error="hasError('hours')"
          :error-messages="getError('hours')"
          @input="deleteError('hours')"
          v-model="lesson_kind.hours"
          type="number"
        ></v-text-field>
      </v-card-text>
      <v-alert type="error" v-for="(error, index) in errors.non" :key="`error_${index}`" dismissible> {{error}}</v-alert>
      <v-card-actions>
        <v-flex>
          <v-btn
            @click="save"
            color="info"
            text
          >
            Сохранить
          </v-btn>
        </v-flex>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import FormErrorsMixin from "../../../../mixins/FormErrorsMixin";
  import {sendPostRequest, sendPutRequest, setUrlParameters, loadData} from "../../../../helper";
  import urls from "../../urls";
  import choices from "../../../core/urls/choices";

  export default {
    name: "LessonKindForm",
    mixins: [
      FormErrorsMixin,
    ],
    components: {},
    props: {
      opened: Boolean,
      is_create: Boolean,
      lesson_kind: Object,
      semester_id: String,
    },
    data(){
      return {
        choices: {
          kinds: []
        },
      }
    },
    methods: {
      createLessonKind(saver=()=>{}, catcher=(data)=> this.mapErrors(data)){
        let url = urls.NAMES.EDITOR.SEMESTER.LESSON_KIND.CREATE(this.semester_id);
        sendPostRequest(
          url,
          this.lesson_kind,
          saver,
          catcher
        )
      },
      updateLessonKind(saver=()=>{}, catcher=(data)=> this.mapErrors(data)){
        let url = urls.NAMES.EDITOR.SEMESTER.LESSON_KIND.UPDATE(this.semester_id, this.lesson_kind.id);
        sendPutRequest(
          url,
          this.lesson_kind,
          saver,
          catcher
        )
      },
      save(){
        let saver = () => {
          this.$emit('close', true);
        };
        let catcher = (errors) => {
          if(errors.hasOwnProperty('detail'))
            this.errors.non.push(errors['detail'])
          this.mapErrors(errors)
        };
        if(this.is_create){
          this.createLessonKind(saver, catcher);
        }else{
          this.updateLessonKind(saver, catcher);
        }
      },
      loadLessonKinds(){
        let url = choices.CHOICES.LESSON_KIND();
        loadData(
          url,
          (data) => {
            this.choices.kinds = data;
          }
        )
      },
    },
    created() {

      this.loadLessonKinds();
    }
  }
</script>

<style scoped>

</style>
