<template>
  <v-tooltip :bottom="bottom" :top="top" :left="left" :right="right">
    <template v-slot:activator="{ on }">
      <v-btn icon :medium="!small" :small="small" v-on="on" @click="$emit('click')" :loading="loading">
        <v-icon>{{icon}}</v-icon>
      </v-btn>
    </template>
    <span>{{tooltip}}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "TooltipBtn",
  props: {
    bottom: {
      type: Boolean,
      default: false,
    },
    top: {
      type: Boolean,
      default: false,
    },
    left: {
      type: Boolean,
      default: false,
    },
    right: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      required: true
    },
    tooltip: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  methods: {
  }
}
</script>

<style scoped>

</style>
