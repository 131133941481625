<template>
  <v-layout column :class="[row_margin]" >

    <v-layout row>
      <template v-for="(col, index) in cols">
        <v-flex :key="`in_row_label_${index}`"
        :class="`font-weight-bold ${getLabelClasses(col)}`" text-sm-left >
          {{ col.label }}:
        </v-flex>
        <v-flex :key="`in_row_value_${index}`" text-sm-left
        :class="getValueClasses(col)"
        v-html="col.value"
        >
        </v-flex>
      </template>
    </v-layout>
    <v-divider></v-divider>
  </v-layout>
</template>

<script>
  export default {
    name: "Row",
    props: {
      cols: Array,
      margin: Number,
    },
    computed: {
      row_margin(){
        return `my-${this.margin || 3}`;
      },
    },
    methods: {
      getFieldClasses: function (col, field){
        if('classes' in col && field in col.classes){
          if(typeof col.classes[field] === Array)
            return col.classes[field].join(' ');
          else
            return col.classes[field]
        }
        return [];
      },
      getLabelClasses: function (col) {
        return this.getFieldClasses(col, 'label');
      },
      getValueClasses: function (col) {
        return this.getFieldClasses(col, 'value');
      }
    },
    created() {
     // console.log(this.cols)
    }
  }
</script>

<style scoped>

</style>
