import helper from "./helper";

let getEduUrlWithParameters = helper.getEduUrlWithParameters
let getOrganizationUrlWithParameters = helper.getOrganizationUrlWithParameters


export default {
  URLS: {
    CERTIFICATE_TYPE:
      (gets = null) => getEduUrlWithParameters(
        'choices|student|certificate_type',
        'get',
        {},
        gets
      ),
    YEAR:      ( gets = null) => getEduUrlWithParameters(
      'selectors|academic|year|year|lite',
      'get',
      {},
      gets
    ),
    ACADEMIC_YEAR_SESSION: (gets = null) => getEduUrlWithParameters(
      "selectors|academic|year|session",
      'get',
      {
      },
      gets
    ),
    ACADEMIC_YEAR_ALL_SESSION: (gets = null) => getEduUrlWithParameters(
      "selectors|academic|year|all|session",
      'get',
      {
      },
      gets
    ),
    ACTIVE_GROUP: (gets = null) => getEduUrlWithParameters(
      "selectors|group|group",
      'get',
      {
      },
      gets
    ),
    COURSES: (gets = null) => getEduUrlWithParameters(
        'selectors|core|course',
        'get',
      {},
        gets
      ),
    SEMESTERS:
      ( gets = null) => getEduUrlWithParameters(
        'selectors|core|subject',
        'get',
        {},
        gets
      ),
    CATHEDRA: {
      SUBJECTS:
        (cathedra_uid, gets = null) => getEduUrlWithParameters(
          'selectors|group|cathedra|semester',
          'get',
          {
            'cathedra_uid': cathedra_uid.toLowerCase()
          },
          gets
        )
    },
    FACULTIES: (gets) => getEduUrlWithParameters(
      'selectors|relations|faculty',
      'get',
      {},
      gets
    ),
    FACULTY: {
      CATHEDRAS: (faculty_uid) => getEduUrlWithParameters(
        'selectors|relations|cathedra',
        'get',
        {},
        {
          faculty: faculty_uid
        }
      ),
      STUDENTS: (faculty_uid, gets=null) => getEduUrlWithParameters(
        'selectors|students|students',
        'get',
        {
          faculty_uid: faculty_uid
        },
        gets
      ),
      GROUPS: (faculty_uid, gets=null) => getEduUrlWithParameters(
        'selectors|group|faculty|group',
        'get',
        {
          faculty_uid: faculty_uid
        },
        gets
      ),
      CURRICULUM: (faculty_uid=undefined) => getEduUrlWithParameters(
        'selectors|curriculum|curriculum',
        'get',
        {},
        {
          faculty: faculty_uid
        }
      ),
      WORKERS: (faculty_uid=undefined) => getEduUrlWithParameters(
        'selectors|relations|faculty_position_held',
        'get',
        {
          faculty_uid: faculty_uid,
        },
        {}
      ),

    },
    REFERENCES: {
      SUBJECTS:
        (gets = null) => getEduUrlWithParameters(
          'selectors|core|subject',
          'get',
          {},
          gets
        ),
      DIRECTIONS:
        (gets = null) => getEduUrlWithParameters(
          'selectors|ministry|training_direction',
          'get',
          {},
          gets
        ),
      PROFILES:
        (gets = null) => getEduUrlWithParameters(
          'selectors|ministry|training_profile',
          'get',
          {},
          gets
        ),
      COMPETENCES:
        (gets = null) => getEduUrlWithParameters(
          'selectors|ministry|competence',
          'get',
          {},
          gets
        ),
    },
    WORKERS: {
      CATHEDRA: (cathedra_uid, gets = null) => getEduUrlWithParameters(
        "selectors|relations|cathedra_pps_position_held",
        'get',
        {
          cathedra_uid: cathedra_uid.toLowerCase()
        },
        gets
      ),
      ALL: (gets = null) => getOrganizationUrlWithParameters(
        "selectors|employee|worker",
        'get',
        {},
        gets
      )
    }
  },
  SELECTORS: {
    STUDENT:{
      ADMISSION: (education_id, gets = null) => getEduUrlWithParameters(
        "selectors|students|semester|tolerance",
        'get',
        {
          education_id:education_id
        },
        gets
      ),
    },
    WORKERS: {
      CATHEDRA: (cathedra_uid, gets = null) => getEduUrlWithParameters(
        "selectors|relations|cathedra_position_held",
        'get',
        {
          cathedra_uid: cathedra_uid.toLowerCase()
        },
        gets
      ),
      CATHEDRA_PPS: (cathedra_uid, gets = null) => getEduUrlWithParameters(
        "selectors|relations|cathedra_pps_position_held",
        'get',
        {
          cathedra_uid: cathedra_uid.toLowerCase()
        },
        gets
      ),
      ALL: (gets = null) => getOrganizationUrlWithParameters(
        "selectors|employee|worker",
        'get',
        {},
        gets
      )
    },
    TEACHER: {
      STUDENT: (gets = null) => getEduUrlWithParameters(
        "selectors|students|teacher|students",
        'get',
        {},
        gets
      ),
      STUDENT_SEMESTER: (student_uid, gets = null) => getEduUrlWithParameters(
        "selectors|students|teacher|student|semester",
        'get',
        {
          'student_uid': student_uid
        },
        gets
      ),
    },
    CATHEDRA: {
      TEACHER: {
        STUDENT: (cathedra_uid, teacher_uid, gets = null) => getEduUrlWithParameters(
          "selectors|students|administrative|teacher|students",
          'get',
          {
            cathedra_uid: cathedra_uid.toLowerCase(),
            teacher_uid: teacher_uid,
          },
          gets
        ),
        STUDENT_SEMESTER: (cathedra_uid, teacher_uid, student_uid, gets = null) => getEduUrlWithParameters(
          "selectors|students|administrative|teacher|student|semester",
          'get',
          {
            cathedra_uid: cathedra_uid.toLowerCase(),
            teacher_uid: teacher_uid,
            student_uid: student_uid
          },
          gets
        ),
      },
      ARCHIVE:{
        PREVIOUS_SEMESTER: (cathedra_uid, gets = null) => getEduUrlWithParameters(
          "selectors|academic|year|previous_semester",
          'get',
          {
            cathedra_uid: cathedra_uid,
          },
          gets
        ),
      },
    },
    DEAN: {
      STUDENT: (faculty_uid, gets = null) => getEduUrlWithParameters(
        "selectors|students|students",
        'get',
        {
          faculty_uid: faculty_uid,
        },
        gets
      ),
      STUDENT_SEMESTER: (faculty_uid, education_id, gets = null) => getEduUrlWithParameters(
        "selectors|students|education|semesters",
        'get',
        {
          faculty_uid: faculty_uid,
          education_id: education_id
        },
        gets
      ),
      CURRICULUM: (faculty, level, gets = null) => getEduUrlWithParameters(
        "selectors|curriculum|curriculum",
        'get',
        {},
        {
          faculty: faculty,
          level: level,
        }
      ),
      COURSE: (level, gets = null) => getEduUrlWithParameters(
        "selectors|core|course",
        'get',
        {
          level: level,
        },
        {
          level: level,
        },
      ),
      CATHEDRA: (faculty, gets = null) => getEduUrlWithParameters(
        "selectors|relations|cathedra",
        'get',
        {
          faculty: faculty,
        },
        gets
      ),
      DIRECTION: (faculty, gets = null) => getEduUrlWithParameters(
        "selectors|ministry|training_direction",
        'get',
        {
        },
        gets
      ),
      FACULTY_POSITION_HELD: (faculty_uid, gets = null) => getEduUrlWithParameters(
        "selectors|relations|faculty_position_held",
        'get',
        {
          faculty_uid: faculty_uid,
        },
        gets
      ),
      STUDENT_STATUS: (status, gets = null) => getEduUrlWithParameters(
        "selectors|students|new|status",
        'get',
        {
          status: status,
        },
        gets
      ),
      STUDENT_STATUS_REASON: (status, gets = null) => getEduUrlWithParameters(
        "selectors|students|status|reason",
        'get',
        {
          status: status,
        },
        gets
      ),
      OPTIONALLY_GROUP: (group_id, gets = null) => getEduUrlWithParameters(
        "selectors|curriculum|group|optionally|group",
        'get',
        {
          group_id: group_id,
        },
        gets
      ),
      OPTIONALLY_GROUP_SEMESTER: (group_id, gets = null) => getEduUrlWithParameters(
        "selectors|curriculum|optionally|group|semesters",
        'get',
        {
          group_id: group_id,
        },
        gets
      ),
      YEAR_SEMESTER: (group_id, gets = null) => getEduUrlWithParameters(
        "selectors|group|year|semester",
        'get',
        {
          group_id: group_id,
        },
        gets
      ),
      YEAR_SEMESTER_STUDENT: (education_id, gets = null) => getEduUrlWithParameters(
        "selectors|students|year|semester",
        'get',
        {
          education_id: education_id,
        },
        gets
      ),
      MULTI_CATHEDRA_SEMESTER: (group_id, gets = null) => getEduUrlWithParameters(
        "selectors|group|multi|cathedra|semester",
        'get',
        {
          group_id: group_id,
        },
        gets
      ),
      GROUP_SEMESTER_CATHEDRALS: (semester_id, gets = null) => getEduUrlWithParameters(
        "selectors|group|semester|cathedrals",
        'get',
        {
          semester_id: semester_id,
        },
        gets
      ),
      EDUCATION_MISSING_OPTIONALLY:(education_id, gets = null) => getEduUrlWithParameters(
        "selectors|curriculum|education|missing|optionally",
        'get',
        {
          education_id: education_id,
        },
        gets
      ),
    },
    CURRICULUM:{
      SEMESTERS:{
        OPTIONALLY_GROUP: (curriculum_id, gets = null) => getEduUrlWithParameters(
          "selectors|curriculum|optionally|curriculum|semesters",
          'get',
          {
            curriculum_id: curriculum_id,
          },
          gets
        ),
        LIST: (education_id, gets = null) => getEduUrlWithParameters(
          "selectors|students|education|semesters",
          'get',
          {
            education_id: education_id,
          },
          gets
        ),
      }
    },
    ACADEMIC_YEAR:{
      RATING_SYSTEM: (gets = null) => getEduUrlWithParameters(
        "selectors|academic|year|rating_system",
        'get',
        {},
        gets
      ),
    },
    UNIVERSITY: {
      CURRICULUM: (gets = null) => getEduUrlWithParameters(
        "selectors|university|curriculums",
        'get',
        {},
        gets
      ),
      CURRICULUM_SUBJECTS: (curriculum, gets = null) => getEduUrlWithParameters(
        "selectors|university|curriculum_subjects",
        'get',
        {curriculum: curriculum},
        gets
      ),
      CURRICULUM_SEMESTERS: (subject, gets = null) => getEduUrlWithParameters(
        "selectors|university|curriculum_semesters",
        'get',
        {
          subject: subject
        },
        gets
      ),
      CURRICULUM_KINDS: (semester, gets = null) => getEduUrlWithParameters(
        "selectors|university|curriculum_kinds",
        'get',
        {
          semester: semester
        },
        gets
      ),
      DIRECTION: (gets = null) => getEduUrlWithParameters(
        "selectors|university|eios|directions",
        'get',
        {},
        gets
      ),
      PROFILES: (gets = null) => getEduUrlWithParameters(
        "selectors|university|eios|profiles",
        'get',
        {},
        gets
      ),
      SUBJECTS: (gets = null) => getEduUrlWithParameters(
        "selectors|university|eios|subjects",
        'get',
        {},
        gets
      ),
    }
  },
}
