<template>
  <scroll-center-dialog v-model="opened"
                        width="600"
                        @close="$emit('close', false)"
                        @apply="save"
                        title="Изменить статус"
  >
    <v-autocomplete
      class="pa-1 ma-1 pt-4"
      outline
      :items="selectors.reason"
      no-data-text="Нет данных"
      label="Причина"
      v-model="status.reason"
      :error="hasError('reason')"
      :error-messages="getError('reason')">
    </v-autocomplete>
    <v-text-field
      type="date"
      v-model="status.date"
      :error="hasError('date')"
      :error-messages="getError('date')">
    </v-text-field>
  </scroll-center-dialog>
</template>

<script>
import DebugJsonComponent from "../../../../core/components/DebugJsonComponent"
import UserDataFormMixin from "@/mixins/UserDataFormMixin"
import selectors from "@/urls/selectors"
import {makeGetRequest,sendPutRequest} from "@/helper"
import urls from "@/urls/dean"
import moment from "moment";
import ScrollCenterDialog from "@/modules/core/components/ScrollCenterDialog";

export default {
  name: "ChangeStatus",
  components: {
    ScrollCenterDialog,
    DebugJsonComponent,
  },
  mixins:[
    UserDataFormMixin,
  ],
  data() {
    return {
      URL_SELECT_REASON: selectors.SELECTORS.DEAN.STUDENT_STATUS_REASON(this.student_status.status),
      URL_UPDATE_STATUS: urls.DEAN.STUDENTS.STATUSES.UPDATE(this.$route.params.idFaculty, this.$route.params.idStudent,this.student_status.id),
      opened: true,
      selectors: {
        reason: [],
      },
      status: {
        reason: null,
        date: null,
      },
    }
  },
  props:{
    student_status: {
      required: false,
      default: '',
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
    loadSelector(data, url) {
      let sys = true
      makeGetRequest(url).then(resp => {
        if (!resp.ok) {
          sys = false
        }
        return resp.json()
      }).then(json => {
        this.selectors[data] = json
      })
    },
    displayDate(date, minutes = false) {
      let format = 'DD.MM.YYYY';
      if (minutes)
        format += ' HH:mm';
      return moment(date).format(format)
    },
    save(){
      sendPutRequest(
        this.URL_UPDATE_STATUS,
        {'reason': this.status.reason, 'date': this.status.date}, () => {
          this.$emit('refreshItem')
          this.close()
        }, (json) => {
          this.receiveErrors(json)
        }
      )
    },
  },
  created() {
    this.loadSelector('reason', this.URL_SELECT_REASON)
    this.status.date = this.student_status.date
    this.status.reason = this.student_status.reason
  }
}
</script>

<style scoped>

</style>
