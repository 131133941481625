import {getUrl, getUrlAllowed, makeMediaHostUrl, getUrlWithParameters} from "@/helper/urls";
import helper from "@/urls/helper";
import {ACCOUNTS_INSTANCE, ACCOUNT_HOST, EDUCATION_INSTANCE, EDUCATION_HOST} from "@/helper/consts";

let getEduUrlWithParameters = helper.getEduUrlWithParameters;

export default {
  getEduUrlWithParameters,

  URLS: {
    GROUP: {
      LED: {
        LIST:
          (gets = null) => getEduUrlWithParameters(
            'teacher|group|group-list',
            'list',
            {},
            gets
          ),
          // teacher|group|detail|group-detail
        // URL_LIST: urls.getUrl('teacher|group|group-list', 'list'),
      },
      SUPERVISED: {
        LIST:
          (gets = null) => getEduUrlWithParameters(
            'teacher|group|curator|group-list',
            'list',
            {},
            gets
          ),
      },
      DETAIL:
        (group_id, gets = null) => getEduUrlWithParameters(
          'teacher|group|detail|group-detail',
          'retrieve',
          {
            id: group_id
          },
          gets
        ),
      STUDENTS:
        (group_id, gets = null) => getEduUrlWithParameters(
          'teacher|group|detail|group-get-all-student',
          'get_all_student',
          {
            id: group_id
          },
          gets
        ),
      SUBJECTS:
        (group_id, gets = null) => getEduUrlWithParameters(
          'teacher|group|detail|group-get-teacher-subject',
          'get_teacher_subject',
          {
            id: group_id
          },
          gets
        ),
      JOURNAL: {
        STATEMENT: ( subject, gets = null) =>
          getEduUrlWithParameters(
            'teacher|group|group|subject-get-statement-xlsx',
            "get_statement_xlsx",
            {
              id: subject
            },
            gets
          ),
        LOAD: (group_id, gets = null) => getEduUrlWithParameters(
          'teacher|group|group|subject-get-medium-control',
          'get_medium_control',
          {
            id: group_id
          },
          gets
        ),
        SAVE_MIDDLE: (group_id, gets = null) => getEduUrlWithParameters(
          'teacher|group|group|subject-update-point-medium-control',
          'update_point_medium_control',
          {
            id: group_id
          },
          gets
        ),
        SAVE_END: (group_id, gets = null) => getEduUrlWithParameters(
          'teacher|group|group|subject-update-exam',
          'update_exam',
          {
            id: group_id
          },
          gets
        ),
      }
    },
    STUDENT: {
      DIPLOMA_LIST: (gets = null) => getEduUrlWithParameters(
        'teacher|students|scientific|education-list',
        'list',
        {},
        gets
      ),
      LIST:
        (gets = null) => getEduUrlWithParameters(
          'teacher|students|education-list',
          'list',
          {},
          gets
        ),
        // teacher|students|education-list
      DETAIL:
        (student_id, gets = null) => getEduUrlWithParameters(
          'teacher|students|detail|education-detail',
          'retrieve',
          {id: student_id},
          gets
        ),
      STUDENT_PORTFOLIO:
        (education_id, gets=null) => getEduUrlWithParameters(
          'teacher|relations|student|achievement|documents-list',
          'list',
          {
            education_id: education_id,
          },
          gets
        ),
      PORTFOLIO:{
        LIST:
          (education_id, gets=null) => getEduUrlWithParameters(
            'worker|relations|student|achievement|documents-list',
            'list',
            {
              education_id: education_id,
            },
            gets
          ),
      }
    },
    SUBJECT: {
      LIST:
        (gets = null) => getEduUrlWithParameters(
          'teacher|core|subject-list',
          'list',
          {},
          gets
        ),
      GROUPS:
        (subject_id, gets = null) => getEduUrlWithParameters(
          'teacher|core|subject-get-groups',
          'get_groups',
          {id: subject_id},
          gets
        ),
      MIDDLE_POINTS: {
        LIST:
          (subject_id, gets = null) => getEduUrlWithParameters(
            'teacher|group|group|subject-get-header-medium-control',
            'get_header_medium_control',
            {id: subject_id},
            gets
          ),
        CREATE:
          (subject_id, gets = null) => getEduUrlWithParameters(
            'teacher|group|group|subject-create-medium-control',
            'create_medium_control',
            {id: subject_id},
            gets
          ),
        UPDATE:
          (subject_id, gets = null) => getEduUrlWithParameters(
            'teacher|group|group|subject-update-medium-control',
            'update_medium_control',
            {id: subject_id},
            gets
          ),
        DELETE:
          (subject_id, gets = null) => getEduUrlWithParameters(
            'teacher|group|group|subject-delete-medium-control',
            'delete_medium_control',
            {id: subject_id},
            gets
          ),
      }
    },
    PORTFOLIO:{
      LIST:
        ( gets = null) => getEduUrlWithParameters(
          'teacher|relations|achievement|documents-list',
          'list',
          {},
          gets
        ),
      CREATE:
        ( gets = null) => getEduUrlWithParameters(
          'teacher|relations|achievement|documents-list',
          'list',
          {},
          gets
        ),
      UPDATE:
        ( id,gets = null) => getEduUrlWithParameters(
          'teacher|relations|achievement|documents-detail',
          'update',
          {id: id},
          gets
        ),
      DELETE:
        (id, gets = null) => getEduUrlWithParameters(
          'teacher|relations|achievement|documents-detail',
          'destroy',
          {id: id},
          gets
        ),
    },
    INDIVIDUAL_WORK:{
      WORK_LIST: (gets=null) => getEduUrlWithParameters(
        'teacher|individual|work-list',
        'list',
        gets
      ),
      WORK_DETAIL: (id, gets=null) => getEduUrlWithParameters(
        'teacher|individual|work-detail',
        'retrieve',
        {
          id: id,
        },
        gets
      ),
      ADD_WORK: (gets=null) => getEduUrlWithParameters(
        'teacher|individual|work-list',
        'create',
        {},
        gets
      ),
      UPDATE_WORK: (id, gets=null) => getEduUrlWithParameters(
        'teacher|individual|work-detail',
        'partial_update',
        {
          id: id,
        },
        gets
      ),
      WORK_DELETE: (id, gets=null) => getEduUrlWithParameters(
        'teacher|individual|work-detail',
        'destroy',
        {
          id: id,
        },
        gets
      ),
    },
    ADMISSION:{
      LIST: (gets=null) => getEduUrlWithParameters(
        'teacher|individual|tolerance-list',
        'list',
        {
        },
        gets,
      ),
      DETAIL: (id, gets=null) => getEduUrlWithParameters(
        'teacher|individual|tolerance-detail',
        'retrieve',
        {
          id: id
        },
        gets,
      ),
      REOPEN: ( id, gets=null) => getEduUrlWithParameters(
        'teacher|individual|tolerance-reopen',
        'reopen',
        {
          id: id
        },
        gets,
      ),
    },
    INDIVIDUAL_STUDENT: {
      LIST: (gets=null) => getEduUrlWithParameters(
        'teacher|students|individual|education-list',
        'list',
        {},
        gets,
      ),
      JOURNAL: (id, gets=null) => getEduUrlWithParameters(
        'teacher|students|individual|education-student-journals',
        'student_journals',
        {
          id: id,
        },
        gets
      ),
      SAVE_JOURNAL: (id, gets=null) => getEduUrlWithParameters(
        'teacher|students|individual|education-update-semester-rating',
        'update_semester_rating',
        {
          id: id,
        },
        gets
      ),
      STATEMENT:(id, gets=null) => getEduUrlWithParameters(
        'teacher|students|individual|education-get-statement-xlsx',
        'get_statement_xlsx',
        {
          id: id,
        },
        gets
      ),
    },
    getUrl(urlname, action) {
      return getUrl(EDUCATION_HOST, EDUCATION_INSTANCE, urlname, action)
    },
    getUrlAllowed(urlname, action) {
      return getUrlAllowed(EDUCATION_INSTANCE, urlname, action)
    },
    makeMediaHostUrl(url){
      return makeMediaHostUrl(EDUCATION_HOST, url);
    },
    getByRecievedUrlData: (url, gets=null) => getEduUrlWithParameters(
      url.basename,
      url.action,
      url.params,
      gets
    ),
  },

}
