<template>
  <scroll-center-dialog v-model="opened_form"
                        @close="closeDialog"
                        @apply="apply"
                        width="500"
                        :loading="loading"
                        :title="!!id ? 'Изменить дисциплину' : 'Добавить дисциплину'"
  >
    <v-text-field
      :error="hasError('name')"
      :error-messages="getError('name')"
      label="Название"
      v-model="value.name"
    ></v-text-field>
    <v-autocomplete
      :error="hasError('level')"
      :error-messages="getError('level')"
      :items="selectors.level"
      label="Уровень"
      v-model="value.level"
      no-data-text="Нет данных"
    ></v-autocomplete>
  </scroll-center-dialog>
</template>

<script>
  import urls from "@/urls/management"
  import UserDataFormMixin from '@/mixins/UserDataFormMixin'
  import {loadData} from "@/helper"
  import choices from "@/urls/choices"
  import ScrollCenterDialog from "@/modules/core/components/ScrollCenterDialog";

  export default {
    name: "Subjects",
    components: {ScrollCenterDialog},
    mixins: [UserDataFormMixin],
    props: {
      id: Number,
      name: String,
      level: String,
      opened_form: Boolean,
    },
    data() {
      return {
        URL_CREATE: urls.EDUCATION.REFERENCE.SUBJECTS.CREATE(),
        URL_UPDATE: urls.EDUCATION.REFERENCE.SUBJECTS.UPDATE(this.id),
        loading : false,
        selectors:{
          level: [],
        },
        value: {
          id: this.id || '',
          name: this.name || '',
          level: this.level || false,
        },
        // errors: {}
      }
    },
    methods: {
      loadSelector(data, url) {
        loadData(
          url,
          (json) => {
            this.selectors[data] = json
          }
        )
      },
      closeDialog() {
        this.$emit('ended', '')
      },
    },
    created() {
      this.loadSelector('level', choices.EDUCATION.CHOICES.LEVEL())
    }
  }

</script>

<style scoped>

</style>
