import store from '../store'
import {makeGetRequest, isAliveToken, isAuthenticated, goToLoginPage} from "../helper";


// export function authRequiredHook (to, from, next) {
//   if(to.meta.requiredAuth){
//     if(store.getters['user/isAuthenticate']){
//       isAliveToken(
//         (json)=>{
//               next()
//             // else
//             //   document.location.href = process.env.VUE_APP_LOGIN_HOST + `?from=${document.location.href}`
//
//           // else
//           //   document.location.href = process.env.VUE_APP_LOGIN_HOST + `?from=${document.location.href}`
//         },
//         // () => document.location.href = process.env.VUE_APP_LOGIN_HOST + `?from=${document.location.href}`
//       )
//     }else{
//       if(store.getters['user/isCheckExpired']){
//         isAliveToken(
//           ()=>next(),
//           // () => document.location.href = process.env.VUE_APP_LOGIN_HOST + `?from=${document.location.href}`
//         )
//       }else{
//         // document.location.href = process.env.VUE_APP_LOGIN_HOST + `?from=${document.location.href}`;
//       }
//     }
//   }else{
//     next()
//   }
// }

export function authRequiredHook(to, from, next){
  /* Во время загрузки роутера хук проверяет требуется ли для перехода авторизация,
   * Если авторизация требуется, то проверяются есть ли куки для сайта
   * Если куки есть, то идет попытка проверить жив ли токен, котрая загружает их, если они на загружены и выполняет alive запрос
   * Если токен жив, то проверяется необходимость принудительной смены пароля, если она требуется редирект на страницу
   * Если токен жив, но смена пароля не требуется, пропускает на доступ к соответсвующему роутеру
   * Если токен не жив, или произошла какая либо ошибка, переход на логин
   * Если куков нет, то переход на логин
   * Если авторизация для урла не трубется, то проверяем что бы урл не был открыт только для неавторизованных
   * пользователей, и проверяем авторизацию пользователя если она есть, редиректим на профиль
   */

  if (to.meta.requiredAuth) {

    if (store.getters['user/hasCookie']) {
      store.commit('maintoolbar/setStartLoading');
      isAliveToken(
        () => {
          store.commit('maintoolbar/setEndRLoading');
          if(store.getters['user/isNeedChangePassword']){
            goToLoginPage();
          } else {
            store.commit('maintoolbar/setEndRLoading');
            next()
          }
        },
        () => {
          goToLoginPage();
        }
      )
    } else {
      goToLoginPage();
    }
  } else {
    if ( !isAuthenticated()){
      goToLoginPage();
    } else {
      next()
    }
  }
}

export function instanceRequiredHook(to, from, next){
  store.dispatch('user/LOAD_FROM_COOKIE').then(() => {
    let instances = [];
    to.matched.forEach(el => {
      if(el.meta.instances !== undefined && el.meta.instances.length > 0){
        el.meta.instances.forEach(el => {
          if(instances.indexOf(el) === -1)
            instances.push(el)
        });
      }
    });
    let query = '?';
    instances.forEach((el, idx) => {
      if(!store.getters['urls/checkAlreadyLoaded'](el)) {
        query = `${query}instances=${el}`;
        if(idx !== instances.length - 1)
          query = `${query}&`;
      }
    });
    if(query !== '?'){
      makeGetRequest(process.env.VUE_APP_INSTANCE_ALLOWED_URLS + query)
        .then(resp => resp.json())
        .then(data => {
          store.commit('urls/appendUrls', data);
          next()
        });
    }else
      next()
  })
}
