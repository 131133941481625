<template>
  <v-container>
    <ChangeStatusForm v-if="status_change"
                      :student_status = "data_status"
                      @refreshItem="refreshItem"
                      @close="closeDialog()">
    </ChangeStatusForm>
    <scroll-center-dialog v-model="opened"
                          width="800"
                          @close="closeHistory()"
                          :applyable="false"
                          :dennyable="false"
                          title="История изменения статусов"
    >
          <div class="justify-center d-flex align-center" v-if="statuses===null">
            <semipolar-spinner
              :animation-duration="2000"
              :size="115"
              color="#1935ff"
            />
          </div>
        <template v-else>
          <v-list v-for="(item, index) in statuses" :key="item.id">
            <v-list-item>
              <v-list-item-content>
                <v-col cols="4" class="px-0 text-left" >
                  <span v-if="item.reason_rus!==null">
                     {{item.status_rus + ' (' + item.reason_rus + ')'}}
                  </span>
                  <span v-else>
                    {{item.status_rus}}
                  </span>
                </v-col>
                <v-col cols="4" class="px-0 text-left" >
                  <span v-if="item.caption!==null" >
                     {{item.caption}}
                  </span>
                </v-col>
                <v-col cols="2" class="px-0 text-right" >
                  <span>
                    {{displayDate(item.date)}}
                  </span>
                </v-col>
                <v-col  cols="2" class="px-0 text-right">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        @click="changeItem(item)"
                        class="mr-2"
                        medium
                      >
                        edit
                      </v-icon>
                    </template>
                    <span>Редактировать</span>
                  </v-tooltip>
                </v-col>
              </v-list-item-content>
            </v-list-item>
            <v-divider
              v-if="index <statuses.length - 1"
              :key="index"
            ></v-divider>
          </v-list>
        </template>
    </scroll-center-dialog>
  </v-container>
</template>

<script>
import moment from "moment";
import ChangeStatusForm from "@/modules/dean/pages/student/status/ChangeStatus"
import DebugJsonComponent from "../../../../core/components/DebugJsonComponent"
import {loadData} from "@/helper"
import urls from "@/urls/dean"
import {SemipolarSpinner} from 'epic-spinners'
import ScrollCenterDialog from "@/modules/core/components/ScrollCenterDialog";
export default {
  name: "StatusHistory",
  components: {
    ScrollCenterDialog,
    DebugJsonComponent,
    ChangeStatusForm,
    SemipolarSpinner,
  },
  data() {
    return {
      URL_STATUSES_LIST: urls.DEAN.STUDENTS.STATUSES.LIST(this.$route.params.idFaculty, this.$route.params.idStudent),
      opened: true,
      status_change: false,
      data_status: '',
      statuses: null,
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
    closeDialog() {
      this.status_change = false
    },
    displayDate(date, minutes = false) {
      let format = 'DD.MM.YYYY';
      if (minutes)
        format += ' HH:mm';
      return moment(date).format(format)
    },
    refreshItem(){
      this.status_change = false
      this.loadStatuses()
    },
    changeItem(data){
      this.data_status = data
      this.status_change = true
    },
    loadStatuses: function () {
      let url = this.URL_STATUSES_LIST
      loadData(
        url,
        (data) => {
          this.statuses = data
        }
      )
    },
    closeHistory(){
      this.$emit('refreshItem')
      this.close()
    },
  },
  created() {
    this.loadStatuses()
  }

}
</script>

<style scoped>

</style>
