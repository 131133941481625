import DeanBreadcrumbs from '../pages/DeanBreadcrumbs.vue'
import ListGroup from "../pages/list/ListGroup"
import ListStudent from "../pages/list/ListStudent"
import ListTransferStudentFrom from "../pages/transferStudents/ListTransferStudentFrom"
import ListTransferStudentTo from "../pages/transferStudents/ListTransferStudentTo"
import PermissionWorkerList from "../pages/list/PermissionWorkerList"
import departament from "./departament";
import student from "./student";
import DetailedLedGroup from "../pages/departament/list/groups/DetailedGroup";
import  TransferDetailFrom from "../pages/transferStudents/TransferDetailFrom.vue"
import  TransferDetailTo from "../pages/transferStudents/TransferDetailTo.vue"
import ApproveTransfer from "../pages/transferStudents/ApproveTransfer"
import names from "./names"
import ListCurriculum from "../pages/curriculum/List";
import DetailAdmission from "@/modules/dean/pages/admission/Detail.vue";
import ListAdmission from "@/modules/dean/pages/admission/List.vue";
import DetailedCurriculum from "../pages/curriculum/Detail";
import GroupJournalBase from "@/modules/dean/pages/journal/Base";
import GroupJournalSemesterBase from "@/modules/dean/pages/journal/SemesterBase";
import DetailAdmissionRequest from "@/modules/dean/pages/admissionRequest/DetailAdmission.vue";
import ListAdmissionRequest from "@/modules/dean/pages/admissionRequest/ListAdmissions.vue";
import CertificateList from "@/modules/dean/pages/certificate/List.vue";
import GroupOptionallyGroup from "@/modules/dean/pages/optionallyGroup/Base.vue"
import StudentSemesterCathedra from "@/modules/dean/pages/studentSemesterCathedra/Base"

const accounts = String(process.env.VUE_APP_ACCOUNTS_PREFIX).replace('/', '');
const organization = String(process.env.VUE_APP_ORGANIZATION_PREFIX).replace('/', '');
const education = String(process.env.VUE_APP_EDUCATION_PREFIX).replace('/', '');

export default ([

  {
    path: '/dean',
    name: names.FACULTIES,
    component: DeanBreadcrumbs,
    meta: {
      breadcrumb: 'Работник деканата',
      requiredAuth: true,
      instances: [
        accounts,
        education,
      ],
    },
    // ------------------------DEPARTMENTS-КАФЕДРА-------------------------------------------------
    children: [

      ...departament,
      // ------------------------DEPARTMENTS-КАФЕДРА-------------------------------------------------

      { ///////////////////////////////////////////////////// I
        path: ':idFaculty/groups/',
        name: names.GROUP.LIST,
        component: ListGroup,
        meta: {
          breadcrumb: 'Список групп',
          requiredAuth: true,
          instances: [
            accounts,
            education,
            organization
          ],
        },
        children: [
          //  детальное представление о группе
          {
            path: 'detailed_group/:idGroup',
            name: names.GROUP.DETAIL,
            component: DetailedLedGroup,
            meta: {
              breadcrumb: 'Детальное представление ведомой группы',
              requiredAuth: true,
              instances: [
                accounts,
                education,
                organization
              ],
            },
            children: [
              {
                path: 'journal/semester',
                name: names.GROUP.JOURNALSEMESTER,//'Administrative|Cathedra|Groups|Detail',
                component: GroupJournalSemesterBase,
                meta: {
                  requiredAuth: true,
                  instances: [
                    accounts,
                    education,
                    organization
                  ],
                  breadcrumb: 'Полный журнал группы',
                },
              },
              {
                path: 'journal',
                name: names.GROUP.JOURNAL,//'Administrative|Cathedra|Groups|Detail',
                component: GroupJournalBase,
                meta: {
                  requiredAuth: true,
                  instances: [
                    accounts,
                    education,
                    organization
                  ],
                  breadcrumb: 'Журнал группы',
                },
              },
              {
                path: 'optionally_group',
                name: names.GROUP.OPTIONALLYGROUP,//'Administrative|Cathedra|Groups|Detail',
                component: GroupOptionallyGroup,
                meta: {
                  requiredAuth: true,
                  instances: [
                    accounts,
                    education,
                    organization
                  ],
                  breadcrumb: 'Предметы по выбору',
                },
              },
              {
                path: 'student_semester_cathedras',
                name: names.GROUP.STUDENTSEMESTERCATHEDRAS,//'Administrative|Cathedra|Groups|Detail',
                component: StudentSemesterCathedra ,
                meta: {
                  requiredAuth: true,
                  instances: [
                    accounts,
                    education,
                    organization
                  ],
                  breadcrumb: 'Привязка обучающихся к кафедрам',
                },
              },
            ]
          }
        ] // children end
      },
      {
        path: ':idFaculty/certificate',
        name: names.CERTIFICATE.LIST,
        component: CertificateList,
        meta: {
          breadcrumb: 'Заказанные справки',
          requiredAuth: true,
          instances: [
            accounts,
            education,
            organization
          ],
        },
      },
      {
        path: ':idFaculty/admission_request',
        name: names.ADMISSIONS_REQUEST.LIST,
        component: ListAdmissionRequest,
        meta: {
          breadcrumb: 'Заказанные допуски ',
          requiredAuth: true,
          instances: [
            accounts,
            education,
            organization
          ],
        },
        children: [
          {
            path: ':idAdmission',
            name: names.ADMISSIONS_REQUEST.DETAIL,
            component: DetailAdmissionRequest,
            meta: {
              breadcrumb: 'Подробнее',
              requiredAuth: true,
              instances: [
                accounts,
                education,
                organization
              ],
            },
          }
        ],
      },
      {
        path: ':idFaculty/admission',
        name: names.ADMISSIONS.LIST,
        component: ListAdmission,
        meta: {
          breadcrumb: 'Список допусков',
          requiredAuth: true,
          instances: [
            accounts,
            education,
            organization
          ],
        },
        children: [
          {
            path: ':idAdmission',
            name: names.ADMISSIONS.DETAIL,
            component: DetailAdmission,
            meta: {
              breadcrumb: 'Подробнее',
              requiredAuth: true,
              instances: [
                accounts,
                education,
                organization
              ],
            },
          }
        ],
      },
      {
        path: ':idFaculty/curriculum',
        name: names.CURRICULUM.LIST,
        component: ListCurriculum,
        meta: {
          breadcrumb: 'Учебный план',
          requiredAuth: true,
          instances: [
            accounts,
            education,
            organization
          ],
        },
        children: [
          {
            path: ':idCurriculum',
            name: names.CURRICULUM.DETAIL,
            component: DetailedCurriculum,
            meta: {
              breadcrumb: 'Подробнее',
              requiredAuth: true,
              instances: [
                accounts,
                education,
                organization
              ],
            },
          }
        ],
      },
      {
        path: ':idFaculty/permission',
        name: names.PERMISSION.LIST,
        component: PermissionWorkerList,
        meta: {
          breadcrumb: 'Список прав',
          requiredAuth: true,
          instances: [
            accounts,
            education,
            organization
          ],
        },
      },
      { ///////////////////////////////////////////////////// I
        path: ':idFaculty/students',
        name: names.STUDENT.LIST,
        component: ListStudent,
        meta: {
          breadcrumb: 'Список обучающихся',
          requiredAuth: true,
          instances: [
            accounts,
            education,
          ],
        },
        children: [
          ...student
        ]
      },
      { ///////////////////////////////////////////////////// I
        path: ':idFaculty/transfer/students/from',
        name: names.TRANSFER.LIST_FROM,
        component: ListTransferStudentFrom,
        meta: {
          breadcrumb: 'Список исходящих запросов на перевод с другого факультета',
          requiredAuth: true,
          instances: [
            accounts,
            education,
          ],
        },
        children: [
          {
            path: 'detail/:idDetail',
            name:  names.TRANSFER.DETAIL_FROM,
            component: TransferDetailFrom,
            meta: {
              breadcrumb: 'Подробнее',
              requiredAuth: true,
              instances: [
                accounts,
                education,
              ],
            },
          }
        ]
      },
      { ///////////////////////////////////////////////////// I
        path: ':idFaculty/transfer/students/to',
        name: names.TRANSFER.LIST_TO,
        component: ListTransferStudentTo,
        meta: {
          breadcrumb: 'Список входящих запросов на перевод с другого факультета',
          requiredAuth: true,
          instances: [
            accounts,
            education,
          ],
        },
        children: [
          {
            path: 'detail/:idDetail',
            name: names.TRANSFER.DETAIL_TO,
            component: TransferDetailTo,
            meta: {
              breadcrumb: 'Подробнее',
              requiredAuth: true,
              instances: [
                accounts,
                education,
              ],
            },
            children: [
              {
                path: 'approve',
                name: names.TRANSFER.APPROVE,
                component: ApproveTransfer,
                meta: {
                  breadcrumb: 'Перевод',
                  requiredAuth: true,
                  instances: [
                    accounts,
                    education,
                  ],
                },

              }
            ]
          }
        ]
      }
    ]
  },
])
