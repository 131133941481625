<template>
  <v-container v-if="$route.name === names.StudentEducationCurriculum" class="elevation-1">
<!--    {{curriculum.data.curriculum}}-->
    <template v-if="curriculum.data.curriculum!==undefined">
      <v-alert
        outlined
        type="info"
        border="left"
        v-if="curriculum.data.curriculum.is_system"
      >
        <div class="text-justify">
          Уважаемый обучающийся! При создании учебного профиля ваша группа была привязана к системному учебному плану,
          не содержащему актуальную информацию о вашем обучении, поэтому эти данные были скрыты.
          После того, как работники деканата назначат вам актуальный учебный план, информация о нем автоматически отобразится в данном разделе.
        </div>
      </v-alert>
      <template v-else >
        <CurriculumTemplate v-if="curriculum.loaded" :curriculum="curriculum.data.curriculum"></CurriculumTemplate>
        <v-card-title class="style-color-main-gray" style="word-break: break-word!important;"  flat>
            Список дисциплин учебного плана
          <v-spacer></v-spacer>
        </v-card-title>
        <!--    <DebugJsonComponent :status="curriculum.status" :json="curriculum"></DebugJsonComponent>-->
        <SubjectList v-if="curriculum.loaded" :subject_url="curriculum.data.subjects" :curriculum_id = String(curriculum.data.id)></SubjectList>
      </template>
    </template>
  </v-container>
  <router-view :key="$route.fullPath" v-else></router-view>
</template>

<script>
import urls from "@/urls/student"
import names from "@/modules/student/routers/names"
import {loadData, checkFieldTrue} from "@/helper";
import DebugJsonComponent from "@/modules/core/components/DebugJsonComponent";
import CurriculumTemplate from "@/modules/templates/curriculum/CurriculumTemplate";
import SubjectList from "@/modules/student/pages/Curriculum/SubjectList.vue";


export default {
  name: "PersonalCuriculum",
  components: {
    DebugJsonComponent,
    SubjectList,
    CurriculumTemplate,
  },
  computed: {
    loadingPage: function(){
      return checkFieldTrue(this.student, 'loading');
    },
    names: () => names
  },
  data: function () {
    return {
      title: 'Учебный план обучающегося',
      loadErrorMsg: '',
      actions: {
        nextCourseApplier: false,
        prevCourseApplier: false,
      },
      curriculum: {
        student_id: this.$route.params.idStudent,
        data: {},
        loading: false,
        loaded: false,
        status: '',
      }
    }
  },
  methods: {
    loadCurriculum: function () {
      this.curriculum.loading = true;
      loadData(
        urls.STUDENT.CURRICULUM.DETAIL(this.$route.params.idStudent),
        (data) => {
          this.curriculum.data = data;
          this.curriculum.loaded = true;
          this.curriculum.loading = false;
        },
        (error) => {
          this.curriculum.status = error;
          this.curriculum.loading = false;
        });
    },
  },
  created() {
    this.$store.commit('mainpage/changeTitle', this.title);
    this.loadCurriculum();
  }
}
</script>

<style scoped>

</style>
