import { render, staticRenderFns } from "./ReferenceIFNS.vue?vue&type=template&id=f01f3854&scoped=true"
import script from "./ReferenceIFNS.vue?vue&type=script&lang=js"
export * from "./ReferenceIFNS.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f01f3854",
  null
  
)

export default component.exports