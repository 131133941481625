import {
  // sendPostRequest,
  // sendDeleteRequest,
  sendGetRequest, sendPostRequest,
  // sendPutRequest
} from "@/helper";
import urls from "@/urls/admin";

const state = {
  items: [],
  count: 0,
  next: null,
  prev: null,
  detail: null,
  id: null,
  loading: false,
  currentUrl: false,
  error: null,
};

const getters = {
  getCurriculumItems: state => state.items,
  getCurriculumCount: state => state.count,
  getCurriculumCurrentUrl: state => state.currentUrl,
  getCurriculumDetail: state => state.detail,
  isCurriculumLoading: state => state.loading,
  getCurriculumError: state => state.error,

};

const actions = {
  loadCurriculumList: ({commit, getters}, {url, urlAppend, finalizer=()=>{}, catcher=()=>{}}) => {
    if (!url) {
      url = urls.ADMIN.CURRICULUM.LIST()
      if (urlAppend) url = urlAppend(url)
    }
    if (!getters.isCurriculumLoading) {
      commit('SET_CURRICULUM_LOADING');
      sendGetRequest(
        url,
        (data, url) => {
          commit('SET_CURRICULUM', data['results']);
          commit('SET_CURRICULUM_COUNT', data['count']);
          commit('SET_CURRICULUM_CURRENT_URL', url);
          commit('SET_CURRICULUM_LOADING_END');
          finalizer(data, url);
        },
        (data, url) => {
          commit('SET_CURRICULUM_LOADING_END');
          commit('SAVE_CURRICULUM_ERROR', data);
          catcher(data, url);
        }
      )
    }
  },
  loadCurriculumDetail: ({commit}, {url, id, finalizer=()=>{}, catcher=()=>{}}) => {
    if (!url) url = urls.ADMIN.CURRICULUM.DETAIL(id)
    sendGetRequest(
      url, (data, url) => {
        commit('SET_CURRICULUM_DETAIL', data);
        finalizer(data, url);
      }, (data, url) => {
        commit('SAVE_CURRICULUM_ERROR', data);
        catcher(data, url);
      }
    )
  },
  // createCars: ({dispatch, commit, getters}, {data, finalizer=()=>{}, catcher=()=>{}}) => {
  //   let url = urls.ADMIN.CURRICULUM.CREATE()
  //   executeRequest('Cars', sendPostRequest, {url, data, finalizer, catcher}, {dispatch, commit, getters});
  // },
  // changeCars: ({dispatch, commit, getters}, {data, finalizer,catcher}) => {
  //   let url = urls.ADMIN.CURRICULUM.UPDATE(data.id)
  //   executeRequest('Cars', sendPutRequest, {url, data, finalizer, catcher}, {dispatch, commit, getters});
  // },
  // deleteCars: ({dispatch, commit, getters}, {id, finalizer, catcher}) => {
  //   let url = urls.ADMIN.CURRICULUM.DELETE(id)
  //   executeRequest('Cars', sendDeleteRequest, {url, finalizer, catcher}, {dispatch, commit, getters});
  // },

};

const mutations = {
  SET_CURRICULUM: (state, cars) => {
    state.items = cars
  },
  SET_CURRICULUM_DETAIL: (state, value) => {
    state.detail = value
  },
  SET_CURRICULUM_COUNT: (state, value) => {
    state.count = value
  },
  SET_CURRICULUM_CURRENT_URL: (state, value) => {
    state.currentUrl = value
  },
  SET_CURRICULUM_LOADING: (state) => {
    state.loading = true
  },
  SET_CURRICULUM_LOADING_END: (state) => {
    state.loading = false
  },
  SAVE_CURRICULUM_ERROR: (state, error) => {
    state.error = error
  }

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
