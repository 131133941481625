import cathedras from "./cathedras";
import faculties from "./faculties";
import directions from "./directions";
import profiles from "./profiles";
import subjects from "./subjects";

export default {
  namespaced: true,
  modules: {
    directions,
    profiles,
    subjects,
    faculties,
    cathedras
  }
}
