<template>
  <v-container>
    <v-toolbar
      class="mb-2"
      color="style-color-main-gray"
      flat
    >
      <v-toolbar-title>
        Преподаватель: {{teacher.fio}}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-title class="text-caption" v-if="teacher.profile">
        (Последний раз был {{displayDate(teacher.profile.last_login,true)}})
      </v-toolbar-title>
      <v-toolbar-items>
        <!--            <v-btn v-if="attachment" @click="opened_form = true">редактировать</v-btn>-->
      </v-toolbar-items>
    </v-toolbar>
    <v-row>
      <v-col cols="12" sm="4" class="d-flex flex-column justify-center">
        <v-img :src="NotAvatar"
               class="ma-5 d-flex"
               aspect-ratio="1"
               contain>
        </v-img>
      </v-col>
      <v-col class="text-left d-flex flex-column px-5" height="100%" cols="12" sm="8">
        <v-row no-gutters v-if="teacher.worker">
          <v-col cols="12" sm="6" class="font-weight-bold">Официальная должность:</v-col>
          <v-col cols="12" sm="6" class="text-justify">
            {{
              teacher.worker.title.substring(
                teacher.worker.title.lastIndexOf('(') + 1,
                teacher.worker.title.lastIndexOf(',')
              )
            }}</v-col>
        </v-row>
        <v-divider class="my-1"></v-divider>
        <v-row no-gutters>
          <v-col cols="12" sm="6" class="font-weight-bold">Количество ведомых дисциплин:</v-col>
          <v-col cols="12" sm="6" class="text-justify">{{teacher.subject_count}}</v-col>
        </v-row>
        <v-divider class="my-1"></v-divider>
        <v-row no-gutters>
          <v-col cols="12" sm="6" class="font-weight-bold">Количество ведомых групп:</v-col>
          <v-col cols="12" sm="6" class="text-justify">{{teacher.group_count}}</v-col>
        </v-row>
        <v-divider class="my-1"></v-divider>
        <v-row no-gutters>
          <v-col cols="12" sm="6" class="font-weight-bold">Количество ведомых обучающихся:</v-col>
          <v-col cols="12" sm="6" class="text-justify">{{teacher.student_count}}</v-col>
        </v-row>
        <v-divider class="my-1"></v-divider>
        <v-row no-gutters>
          <v-col cols="12" sm="6" class="font-weight-bold">Количество дипломников:</v-col>
          <v-col cols="12" sm="6" class="text-justify">{{teacher.scientific_student_count}}</v-col>
        </v-row>
        <v-divider class="my-1"></v-divider>
        <v-row no-gutters>
          <v-col cols="12" sm="6" class="font-weight-bold">Общее количество часов:</v-col>
          <v-col cols="12" sm="6" class="text-justify">{{teacher.hours}}</v-col>
        </v-row>
        <v-divider class="my-1"></v-divider>
        <v-row no-gutters v-if="teacher.profile">
          <v-col cols="12" sm="6" class="font-weight-bold">Телефоны для связи:</v-col>
          <v-col cols="12" sm="6" class="text-justify"><v-chip v-for="phone in teacher.profile.phone">{{phone}}</v-chip></v-col>
        </v-row>
        <v-divider class="my-1"></v-divider>
        <v-row no-gutters v-if="teacher.profile">
          <v-col cols="12" sm="6" class="font-weight-bold">Почты для связи:</v-col>
          <v-col cols="12" sm="6" class="text-justify"><v-chip class="py-0 px-2 mx-1 my-0" v-for="email in teacher.profile.emails">
            <a :href="setEmail(email.email)">{{email.email}}</a>
            </v-chip></v-col>
        </v-row>
        <v-divider class="my-1"></v-divider>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import NotAvatar from "@/assets/NotAvatar.png";
  import TitledPageMixin from "@/mixins/TitledPageMixin";
  import teachers from "@/modules/cathedra/routers/teachers";
  import {displayDate} from "@/helper";

  export default {
    name: "DetailTeacherTemplate",
    mixins: [TitledPageMixin],
    methods:{
      setEmail(email){
        return 'mailto:'+email
      },
      displayDate:displayDate,
    },
    computed: {
      NotAvatar: () => NotAvatar,
      page_title(){
        return this.teacher.fio
      },
    },
    props: {
      teacher: {
        required: true,
        type: Object,
      }
    },
  }
</script>

<style scoped>

</style>
