<template>
  <router-link :to="makeLink(student_id)">
    <slot></slot>
  </router-link>
</template>

<script>
  import names from "@/modules/educationalManagement/routers/names";

  export default {
    name: "LinkToDetail",
    props: {
      student_id: Number,
    },
    methods: {
      makeLink(student_id) {
        return {
          name: names.EDUCATIONAL.STUDENTS.DETAIL,
          params: {
            'student_id': student_id
          }
        }
      }
    }
  }
</script>

<style scoped>

</style>
