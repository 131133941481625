<template>
  <v-card>
    <v-toolbar elevation="0" color="style-color-main-gray" dense>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <!--          <v-hover v-slot="{ hover }">-->
        <slot name="actions">
<!--          <v-btn text v-if="detail.status==='инициирована'" color="red" style="" @click="findDiffrence()">Принять заявку</v-btn>-->
<!--          <v-btn v-if="detail.status==='инициирована'" text color="green" @click=" opened_form = true">Отклонить заявку</v-btn>          -->
        </slot>
      </v-toolbar-items>
    </v-toolbar>
    <v-container class="py-1">
      <v-row dense>
        <v-col cols="6" class="py-3" style="border-right: 1px solid #DADADA">
          <v-row dense>
            <v-col cols="12">
              <v-card-text class="title py-2">
                <strong>Переводимый обучающийся: </strong>{{ detail.student }}
              </v-card-text>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="6" class="py-0">
              <v-card-text class="py-1"><strong>Исходящий: </strong>{{ detail.from_department_name.toLowerCase() }} </v-card-text>
            </v-col>
            <v-col cols="6" class="py-0">
              <v-card-text class="py-1"> <strong>Входящий: </strong>{{ detail.to_department_name.toLowerCase() }}</v-card-text>
            </v-col>
          </v-row >
          <v-row dense>
            <v-col cols="6" class="py-0">
              <v-card-text class="py-1"><strong>Дата создания заявки:</strong></v-card-text>
            </v-col>
            <v-col cols="6" class="py-0">
              <v-card-text class="py-1">{{ setDate(detail.initiated) }}</v-card-text>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="6" class="py-0">
              <v-card-text class="py-1" v-if="detail.completed"><strong>Дата приема заявки:</strong></v-card-text>
            </v-col>
            <v-col cols="6" class="py-0">
              <v-card-text class="py-1" v-if="detail.completed"> {{ setDate(detail.completed) }}</v-card-text>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="6" class="py-0">
              <v-card-text class="py-1"><strong>Приказ о переводе:</strong>  </v-card-text>
            </v-col>
            <v-col cols="6" class="py-0">
              <v-card-text class="py-1"> {{ detail.order }}</v-card-text>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="6" class="py-0">
              <v-card-text class=" py-1"><strong>Статус заявки:</strong>  </v-card-text>
            </v-col>
            <v-col cols="6" class="py-0">
              <v-card-text class=" py-1"> {{ detail.status }}</v-card-text>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="6" class="py-0">
              <v-card-text class=" py-1" v-if="detail.comment_from"><strong>Комментарии отправителя:</strong>  </v-card-text>
            </v-col>
            <v-col cols="6" class="py-0">
              <v-card-text class=" py-1" v-if="detail.comment_from"> {{ detail.comment_from }}</v-card-text>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="6" class="py-0">
              <v-card-text class=" py-1" v-if="detail.comment_to"><strong>Комментарии приема:</strong>  </v-card-text>
            </v-col>
            <v-col cols="6" class="py-0">
              <v-card-text class=" py-1" v-if="detail.comment_to"> {{ detail.comment_to }}</v-card-text>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6" class="py-3">
          <v-row dense>
            <v-col cols="12">
              <v-card-text class="title py-2">
                <strong>Информация об образовании: </strong>
              </v-card-text>
            </v-col>
          </v-row>
          <!--                <v-card-text class="title"><strong>Информация об образовании:</strong></v-card-text>-->
          <v-row dense>
            <v-col cols="6" class="py-0">
              <v-card-text class="py-1"><strong>Направление:</strong>  </v-card-text>
            </v-col>
            <v-col cols="6" class="py-0">
              <v-card-text class="py-1"> {{ detail.direction_from.code }} {{ detail.direction_from.name }}</v-card-text>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="6" class="py-0">
              <v-card-text class="py-1"><strong>Профиль:</strong>  </v-card-text>
            </v-col>
            <v-col cols="6" class="py-0">
              <v-card-text class="py-1"> {{ detail.profile_from.name }}</v-card-text>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="6" class="py-0">
              <v-card-text class="py-1"><strong>Группа:</strong>  </v-card-text>
            </v-col>
            <v-col cols="6" class="py-0">
              <v-card-text class="py-1"> {{ detail.education_group_from.litter }}-{{
                  detail.education_group_from.course
                }}{{ detail.education_group_from.number }}</v-card-text>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="6" class="py-0">
              <v-card-text class="py-1"><strong>Уровень образования:</strong>  </v-card-text>
            </v-col>
            <v-col cols="6" class="py-0">
              <v-card-text class="py-1"> {{ detail.education_group_from.level }}</v-card-text>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="6" class="py-0">
              <v-card-text class="py-1"><strong>Учебный план :</strong>  </v-card-text>
            </v-col>
            <v-col cols="6" class="py-0">
              <v-card-text class="py-1"> {{ detail.education_group_from.curriculum.year }}</v-card-text>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="6" class="py-0">
              <v-card-text class="py-1"><strong>Кафедра:</strong>  </v-card-text>
            </v-col>
            <v-col cols="6" class="py-0">
              <v-card-text class="py-1"> {{detail.education_group_from.cathedra }}</v-card-text>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="6" class="py-0">
              <v-card-text class="py-1"><strong>Форма обучения:</strong>  </v-card-text>
            </v-col>
            <v-col cols="6" class="py-0">
              <v-card-text class="py-1"> {{ detail.education_group_from.form }}</v-card-text>
            </v-col>
          </v-row>
          <!--            <v-card-actions>-->
          <!--              <v-spacer></v-spacer> <v-btn v-if="detail.status==='инициирована'" class="primary" @click="findDiffrence()">Принять заявку</v-btn>-->
          <!--            </v-card-actions>-->
        </v-col>
      </v-row>
    </v-container>
    <v-toolbar
      class="mb-2"
      color="style-color-main-gray"
      flat
    >
      <v-toolbar-title>
        Индивидуальный журнал обучающегося
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-title>
        <!--          <v-select v-model="typejournal"-->
        <!--                    class="px-2"-->
        <!--                    :items="[{value:0,text:'журнал по старому образованию'},{value:1,text:'журнал по новому образованию'}]"></v-select>-->
      </v-toolbar-title>
      <v-toolbar-title>
        <v-text-field v-model="search" clearable placeholder="Поиск" hide-details style="min-width: 100px;" prepend-inner-icon="search" dense hint="" filled :disabled="save_process.execute"></v-text-field>
      </v-toolbar-title>
    </v-toolbar>
    <v-container v-if="journal.length > 0">
<!--      {{journal}}-->
      <v-row>
        <v-col>
          <JournalTable
            :append_headers="[{text: 'Семестр', value: 'semester', sortable: false, filterable: false, width: '5%', align: 'center'},]"
            :rows="journal" first_row_label="Предмет" first_row_value="subject_name"
            :can_update="false" :search="search" :save_execute="save_process.execute"
            :loading="loading" :errors="save_process.errors">
<!--            <template v-slot:item.subject_name="props">-->
<!--              {{props.item.subject_name}}<span style="margin-right: auto">({{ ((parseInt(props.item.course.number) - 1) * 2 + (props.item.semester === 'осенний' ? 1: 2)) }} семестр)</span>-->
<!--            </template>-->
            <template v-slot:item.semester="props">
              {{ ((parseInt(props.item.course.number) - 1) * 2 + (props.item.semester === 'осенний' ? 1: 2)) }}
            </template>
          </JournalTable>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-else>
      <v-row>
        <v-col>
          <v-skeleton-loader
            height="50"
            type="list-item"
          >
          </v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-skeleton-loader
            height="200"
            type="card"
          >
          </v-skeleton-loader>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import JournalTable from "@/modules/cathedra/pages/journal/JournalTable";
import {displayDate} from "@/helper";

export default {
  name: "TransferTemplate",
  components: {
    JournalTable
  },
  props: {
    detail: {
      required: true,
      type: Object,
    },
    journal: {
      required: true,
      type: Array,
    },
    save_process: {
      required: true,
      type: Object,
    },
    loading: {
      required: true,
      type: Boolean,
    }
  },
  data(){
    return {
      search: '',
    }
  },
  methods: {
    setDate(date) {
      return displayDate(date)
    },
  }
}
</script>

<style scoped>

</style>
