<template>
  <div class="page-content" style="margin: 0px; width: 100%">
    <PageHeader></PageHeader>
    <Teacher v-if="is_self_router"></Teacher>
    <router-view v-else></router-view>
  </div>
</template>

<script>
  import PageHeader from '@/modules/menus/PageHeader.vue'
  import Teacher from './Teacher'
  import urls from '../urls'
  import LeftMenuChangerMixin from "@/mixins/LeftMenuChangerMixin";
  import {getInstMenu, getInstNameByRouterName} from "@/helper/instances";
  import names from "@/modules/teacher/routers/names";

  export default {
    name: "TeacherBreadcrumbs",
    components: {Teacher, PageHeader},
    mixins: [LeftMenuChangerMixin],
    data: function () {
      return {}
    },
    computed: {
      is_self_router(){
        return this.$route.name === names.BASE;
      },
    },
    methods: {
      allowed(urlname, action) {
        return urls.getUrlAllowed(urlname, action)
      },
      getLeftMenu(){
          return   [
            {title: 'Преподаватель', icon: 'account_box', included: false, router: names.BASE},
            {title: 'Обучающиеся', icon: 'groups', included: true, router: {name: names.STUDENT.LIST, params: {}}},
            {title: 'Обучающиеся с ИУП', icon: 'how_to_reg', included: true, router: {name: names.INDIVIDUAL_STUDENTS.LIST, params: {}}},
            {title: 'Дипломники', icon: 'view_quilt', included: true, router: names.STUDENT.DIPLOMA},
            {title: 'Допуски', icon: 'article', included: true, router: {name: names.ADMISSIONS.LIST, params: {}}},
            {title: 'Работы обучающихся', icon: 'description', included: true, router: names.WORKS.LIST},
            {title: 'Обучаемые группы', icon: 'group', included: ['type'], params: ['type'], router: {name: names.GROUPS.LIST, params: {type: 'led'}}},
            {title: 'Курируемые группы', icon: 'menu', included: ['type'], params: ['type'], router: {name: names.GROUPS.LIST, params: {type: 'supervised'}}},
            {title: 'Преподаваемые дисциплины', icon: 'subject', included: true, router: names.SUBJECTS.LIST},
            {title: 'Портфолио', icon: 'file_present',included:true, router:{name: names.PORTFOLIO, params: {}}},

          ]
      }
    },
    created() {
      this.$store.commit('leftmenu/enableLeftMenu');
      this.$store.commit('mainpage/changeTitle', 'Преподаватель');
      this.$store.commit('leftmenu/replaceInstances', getInstMenu());
      this.$store.commit('leftmenu/selectInstance', getInstNameByRouterName(names.BASE));
    },

  }
</script>

<style scoped>


</style>
}
