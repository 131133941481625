<template>
  <v-card elevation="0">
<!--        <DebugJsonComponent :json="subjects"></DebugJsonComponent>-->
    <v-container fluid>
      <v-data-iterator
        :items="subjects.data.subjects"
        hide-default-footer
        :footer-props="{'items-per-page-options':[10,25,50], 'items-per-page-text': 'Число элементов'}"
      >
        <template v-slot:default="props">
          <v-row>
            <v-expansion-panels v-model="panels">
              <v-expansion-panel v-for="item in props.items">
                <v-expansion-panel-header>
                  <v-card-title class="subheading font-weight-bold py-0">
                    {{ item.course}} Курс
                  </v-card-title>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-card flat>
                    <v-card-title class="subheading font-weight-bold"
                                  v-for="(semester, index) in item.subjects_in_course">
                    {{semester.semester}} семестр
                      <v-card-text class="py-0" v-for="(subjects, index) in semester.subjects_in_semester">
                        <v-list-item class="text-left">
                          <v-row>
                          <v-col cols="12" md="6">
                            <v-list-item-content style="cursor: pointer" @click="openDetail(subjects)">
                              <v-list-item-title class="text-wrap" style="word-break: break-word!important;">
                                {{ subjects.name }}
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                {{ subjects.control_type }}
                              </v-list-item-subtitle>
                              <v-list-item-subtitle>
                                <v-chip v-if="subjects.is_individual"
                                        small
                                >
                                  Индивидуальный
                                </v-chip>
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-col>

                            <v-col  cols="12" md="2">
                              <template v-if="subjects.documents!==null && subjects.documents.appraisal_tools!==null">
                                Файл РПД:
                                <v-btn @click="loadFileWork(subjects.documents.appraisal_tools)"
                                       icon
                                >
                                  <v-icon color="blue">get_app</v-icon>
                                </v-btn>
                              </template>
                            </v-col>
                            <v-col cols="12" md="2">
                              <template v-if="subjects.documents!==null && subjects.documents.methodological_materials!==null">
                                Файл МУ:
                                <v-btn @click="loadFileWork(subjects.documents.methodological_materials)"
                                       icon
                                >
                                  <v-icon color="blue">get_app</v-icon>
                                </v-btn>
                              </template>
                            </v-col>
                            <v-col cols="12" md="2">
                              <template v-if="subjects.documents!==null && subjects.documents.work_program!==null">
                                Файл ОС:
                                <v-btn @click="loadFileWork(subjects.documents.work_program)"
                                       icon
                                >
                                  <v-icon color="blue">get_app</v-icon>
                                </v-btn>
                              </template>
                            </v-col>
                          </v-row>
                        </v-list-item>
                        <v-divider></v-divider>
                      </v-card-text>
                    </v-card-title>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-row>
        </template>
      </v-data-iterator>

    </v-container>

  </v-card>
</template>

<script>
import urls from "@/urls/management";
import {checkFieldTrue, loadData} from "@/helper";
import DebugJsonComponent from "@/modules/core/components/DebugJsonComponent";
import Work from  "@/modules/student/forms/Work";
import names from "@/modules/student/routers/names"
import {loadFile} from "@/helper/fileLoader"

export default {
  name: "SubjectList",

  components: {
    DebugJsonComponent,
    Work
  },
  props: {
    subject_url: Object,
    curriculum_id: String
  },
  computed: {
    loadingPage: function () {
      return checkFieldTrue(this.student, 'loading');
    },
    names: () => names
  },
  data: function () {
    return {
      select:{
        id:null,
        curriculum_id:null,
      },
      panels:0,
      change:false,
      loadErrorMsg: '',
      actions: {
        nextCourseApplier: false,
        prevCourseApplier: false,
      },
      subjects: {
        student_id: this.$route.params.student_id,
        data: {},
        expanded: [],
        loading: false,
        loaded: false,
        headers: [
          {text: "Предмет", value: 'subject', sortable: true, align: 'left', width: '30%'},
          {text: "Кафедра", value: 'cathedra', sortable: true, align: 'left', width: '30%'},
          {text: "Курс", value: 'course', sortable: true, align: 'left'},
          {text: "Семестр", value: 'semester', sortable: true, align: 'left'},
          {text: "Общее количество часов", value: 'independent_hours', sortable: true, align: 'left'},
          {text: "Зеты", value: 'zets', sortable: true, align: 'left'},
          {text: '', value: 'data-table-expand', width: '5%'},
        ],
      }
    }
  },
  methods: {
    openDetail(item){
      this.$router.push({name: names.StudentEducationCurriculumDetailSubject, params: {curriculum_id:this.curriculum_id,idSubject:item.semester_id}})
    },
    loadData: function (url = undefined) {
      this.subjects.loading = true;
      if (url === undefined)
        url = urls.getEduUrlWithParameters(
          this.subject_url.basename,
          this.subject_url.action,
          this.subject_url.params
        )
      loadData(
        url,
        (data) => {
          this.subjects.data = data;
          this.subjects.loaded = true;
          this.subjects.loading = false;
        }
      );
    },
    loadFileWork(url){
      loadFile(url)
    },
  },
  created() {
    this.loadData();
  }
}
</script>

<style scoped>

</style>
