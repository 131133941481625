import names from "@/modules/administrative/routers/names";

import FacultyList from '@/modules/administrative/pages/connector/references/faculties/List.vue'
import CathedraList from '@/modules/administrative/pages/connector/references/cathedras/List.vue'
import DirectionList from '@/modules/administrative/pages/connector/references/directions/List.vue'
import ProfileList from '@/modules/administrative/pages/connector/references/profiles/List.vue'
import SubjectList from '@/modules/administrative/pages/connector/references/subjects/List.vue'

const accounts = String(process.env.VUE_APP_ACCOUNTS_PREFIX).replace('/', '');
const education = String(process.env.VUE_APP_EDUCATION_PREFIX).replace('/', '');


export default ([

  {
    path: 'reference/direction',
    name: names.REFERENCE.DIRECTIONS,
    component: DirectionList,
    meta: {
      breadcrumb: 'Список направлений 1С',
      requiredAuth: true,
      instances: [
        education,
      ],
    },
  },
  {
    path: 'reference/profiles',
    name: names.REFERENCE.PROFILES,
    component: ProfileList,
    meta: {
      breadcrumb: 'Список профилей 1С',
      requiredAuth: true,
      instances: [
        education,
      ],
    },
  },
  {
    path: 'reference/faculty',
    name: names.REFERENCE.FACULTIES,
    component: FacultyList,
    meta: {
      breadcrumb: 'Список факультетов 1С',
      requiredAuth: true,
      instances: [
        education,
      ],
    },
  },
  {
    path: 'reference/cathedra',
    name: names.REFERENCE.CATHEDRAS,
    component: CathedraList,
    meta: {
      breadcrumb: 'Список кафедр 1С',
      requiredAuth: true,
      instances: [
        education,
      ],
    },
  },
  {
    path: 'reference/subjects',
    name: names.REFERENCE.SUBJECTS,
    component: SubjectList,
    meta: {
      breadcrumb: 'Список предметов 1С',
      requiredAuth: true,
      instances: [
        education,
      ],
    },
  },
])
