<template>
  <v-container v-if="loaded">
    <v-toolbar
      class="mb-2"
      color="style-color-main-gray"
      flat
    >
      <v-toolbar-title>
        Журнал обучающегося
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-title>
        <v-text-field v-model="search" clearable placeholder="Поиск" hide-details style="min-width: 100px;" prepend-inner-icon="search" dense hint="" filled></v-text-field>
      </v-toolbar-title>
    </v-toolbar>
    <v-row>
      <v-col>
        <v-alert outlined type="info">
          Уважаемые пользователи, обращаем ваше внимание, что учебный журнал суммирует баллы, введенные в каждую контрольную точку по отдельности.<br/>
          Вы можете перемещаться между полями, используя стрелочки на клавиатуре, а навигация через кнопку "Tab" работает по столбцам, а не строчкам.
        </v-alert>
        <JournalTable
          :rows="journal.semesters"
          :can_update="can_update"
          :loading="loaded"
          :search="search"
          :save_execute="save_process.execute"
          :errors="save_process.errors"
        ></JournalTable>
      </v-col>
    </v-row>
    <v-row v-if="can_update">
      <v-spacer></v-spacer>
      <v-col cols="auto mr-5">
        <v-btn color="success" @click="saveJournal"
               :disabled="save_process.execute" :loading="save_process.execute">Сохранить</v-btn>
      </v-col>
    </v-row>
    <v-snackbar v-model="save_process.alert.visible" right :color="save_process.success?'success':save_process.success !== undefined?'warning':'info'" timeout="3000" top>
      {{save_process.alert.message}}
      <template v-slot:action>
        <v-btn icon @click="save_process.alert.visible = false">
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>

import {loadData, sendPostRequest} from "@/helper";
import DebugJsonComponent from "@/modules/core/components/DebugJsonComponent";
import JournalTable from "@/modules/teacher/pages/individual_students/JournalTable"
import urls from "@/urls/teacher"
import TitledPageMixin from "@/mixins/TitledPageMixin";
import LeftMenuChangerMixin from "../../../../mixins/LeftMenuChangerMixin";
import names from "@/modules/teacher/routers/names";

export default {
  name: "IndividualStudentJournal",
  mixins: [TitledPageMixin, LeftMenuChangerMixin],
  components:{
    JournalTable,
    DebugJsonComponent
  },
  data: function() {
    return {
      page_title: 'Журнал успеваемости обучающегося',
      journal: null,
      results: [],
      loaded: false,
      can_update: true,
      search: '',
      saved_data: undefined,
      save_process: {
        execute: false,
        success: undefined,
        errors: [],
        alert: {
          visible: false,
          message: ''
        },
      },
    }
  },
  watch: {
    journal:{
      handler: function(value) {
        for (let i = 0; i < value.semesters.length; i++) {
          this.results[i] = Number(value.semesters[i].medium_control[0].value) + Number(value.semesters[i].medium_control[1].value) +
            Number(value.semesters[i].medium_control[2].value) + Number(value.semesters[i].additional)
          if (this.results[i] >= 0 && this.journal.semesters[i].rating_in_semester >= 0)
            this.journal.semesters[i].rating_in_semester = Math.round(this.results[i])
        }
      },
      deep: true
    },
  },
  methods: {
    loadJournal(finalizer = () => {
    }) {
      this.loaded = false
      loadData(
        urls.URLS.INDIVIDUAL_STUDENT.JOURNAL(this.$route.params.student_id),
        (data) => {
          this.journal = data
          this.journal.semesters.forEach(el => {
            this.$set(el, 'old_exam', el.exam);
            this.$set(el, 'old_additional', el.additional);
            this.$set(el, 'old_automate', el.automate);
            this.$set(el, 'old_absence', el.absence);
            el.medium_control.forEach(sel => {
              this.$set(sel, 'old_value', sel.value);
            })
          })
          this.page_title = 'Журнал успеваемости обучающегося:' + ' ' + String(this.journal.student_fio)
          this.loaded = true
          finalizer();
        }
      )
    },
    makeSavedData(selected) {
      let data = {
        semesters: [],
      };
      selected.semesters.forEach(el => {
        let changed = false;
        let data_el = {
          exam: el.exam,
          additional: el.additional,
          id: el.id,
          medium_control: [],
          automate: el.automate,
          absence: el.absence,
        }
        if (el.exam !== el.old_exam) changed = true;
        if (el.additional !== el.old_additional) changed = true;
        if (el.automate !== el.old_automate) changed = true;
        if (el.absence !== el.old_absence) changed = true;
        el.medium_control.forEach(sel => {
          if (sel.value !== sel.old_value) {
            changed = true;
            data_el.medium_control.push({id: sel.id, value: sel.value,})
          }
        })
        if (changed) data.semesters.push(data_el)
      })
      return data
    },
    saveJournal() {
      this.save_process.execute = true
      let selected = this.journal
      let data = this.makeSavedData(selected)
      this.saved_data = data;
      if (data.semesters.length > 0)
        sendPostRequest(
          urls.URLS.INDIVIDUAL_STUDENT.SAVE_JOURNAL(this.$route.params.student_id),
          data,
          (res) => {
            if (!res.hasOwnProperty('errors')) {
              this.loadJournal(() => {
                this.showSuccessSaveNotification()
                this.save_process.execute = false
              });
            } else {
              this.showSaveWithErrorNotification()
              this.save_process.execute = false
            }
          },
          (error) => {
            this.save_process.errors = error.errors
            this.showSaveWithErrorNotification()
            this.save_process.execute = false
          }
        )
      else {
        this.showNothingSaveNotification();
        this.save_process.execute = false;
      }
    },
    showSaveWithErrorNotification() {
      /**
       * Отобразить уведомление о наличии ошибок
       * */
      this.save_process.success = false;
      this.save_process.alert.message = 'Журнал не был сохранен';
      this.save_process.alert.visible = true
    },
    showSuccessSaveNotification() {
      /**
       * Отобразить уведомление об успехе
       * */
      this.save_process.success = true;
      this.save_process.alert.message = 'Журнал успешно сохранен.';
      this.save_process.alert.visible = true
    },
    showNothingSaveNotification() {
      /**
       * Отобразить уведомление об отсутствии сохраняемых данных
       * */
      this.save_process.success = undefined;
      this.save_process.alert.message = 'В журнал не внесено изменений';
      this.save_process.alert.visible = true
    },
    getLeftMenu() {
      return [
        {
          title: 'Список обучающихся',
          icon: 'arrow_back_ios',
          included: false,
          router: {
            name: names.INDIVIDUAL_STUDENTS.LIST,
            params: {
              reload_left_menu: true,
            },
            query: this.$route.query,
          }
        },
      ]
    },
  },
  created() {
    this.loadJournal()
  }
}
</script>

<style scoped>

</style>
