<template>
  <v-container v-if="$route.name === names.ADMISSIONS.LIST">
    <v-card >
      <v-toolbar
        class="mb-2"
        color="style-color-main-gray"
        flat
      >
        <v-text-field class=""
                      dense
                      placeholder="Поиск"
                      v-model="search.value"
                      @keypress.enter="searchData"
                      @click:clear="clearSearchData"
                      clearable
                      messages="Для поиска нажмите Enter"
        ></v-text-field>
        <template v-slot:extension>
          <v-row>
            <v-col cols="3">
              <v-select class="py-0 " :items="selectors.success" v-model="pagination.filters.success"
              ></v-select>
            </v-col>
            <v-col cols="3">
              <v-select class="py-0 " :items="selectors.session" v-model="pagination.filters.session"
              ></v-select>
            </v-col>
            <v-col cols="3">
              <v-select class="py-0 " :items="selectors.admission_type" v-model="admission_type"
              ></v-select>
            </v-col>
            <v-col>
              <v-autocomplete class="py-0 " :items="selectors.subject" v-model="pagination.filters.subject"></v-autocomplete>
            </v-col>
          </v-row>
        </template>
        <v-btn @click="opened_form=true" text ><v-icon>add</v-icon>добавить</v-btn>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              @click="opened_load= true"
              medium
            >
              get_app
            </v-icon>
          </template>
          <span>Загрузить допуски</span>
        </v-tooltip>

      </v-toolbar>
      <v-data-table
        v-model="admission"
        :headers="headers"
        :loading="pagination.loading"
        :page.sync="pagination.page"
        :items-per-page.sync="pagination.size"
        :server-items-length="pagination.count"
        :options.sync="options"
        multi-sort
        show-select
        :footer-props="{'items-per-page-options':[10,25,50]}"
        :items="items"
      >
        <v-progress-linear v-slot:progress color="blue" indeterminate></v-progress-linear>
        <template v-slot:header.data-table-select>
          <v-icon>done</v-icon>
        </template>
        <template v-slot:foot>
          <PrintAdmissions  :admissions="admissionPrint" style="display: none"></PrintAdmissions>
            <v-btn
              color="primary"
              dark
              class="ma-2"
              :loading="loadingPrint"
              :disabled = 'admission.length === 0'
              @click="PrintAdmission"
            >
              Напечатать
            </v-btn>

        </template>
        <template v-slot:item.data-table-select="props">
          <v-simple-checkbox
            style="max-width: 60px"
            v-if="props.isSelected || admission.length < 10"
            :value="props.isSelected" @input="props.select($event)">
          </v-simple-checkbox>
        </template>
        <template v-slot:item.subject="props">
          <span style="text-decoration: underline; cursor: pointer" @click="getDetail(props.item)">{{props.item.subject}}</span>
        </template>
        <template v-slot:item.rating="props">
          <span>{{props.item.semester.rating}}</span>
        </template>
        <template v-slot:item.semester="props">
          <span>{{getNumberSemestr(props.item.semester,props.item.course)}}  </span>
        </template>
        <template v-slot:item.control_type="props">
          <span>{{props.item.control_type}}</span>
        </template>
        <template v-slot:item.group="props">
          <span>{{props.item.group.name}}</span>
        </template>
        <template v-slot:item.created="props">
          <span>{{displayDate(props.item.created,true)}}</span>
        </template>
        <template v-slot:item.admission_type="props">
          <v-tooltip bottom >
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">{{getAbbreviation(props.item.academic_difference, props.item.is_exam)}}</span>
            </template>
            <span>{{getAdmissionType(props.item.academic_difference, props.item.is_exam)}}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.success="props">
          <v-icon v-if="props.item.success" color="green">done</v-icon>
          <v-icon v-else-if="props.item.success !== null" color="red">clear</v-icon>
          <span v-else>
            <span v-if="props.item.closed && !props.item.success">Архив</span>
            <span v-else>Не рассмотрен</span>
          </span>
        </template>
        <template v-slot:item.option="{ item }">
          <v-tooltip bottom v-if="!item.closed">
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                v-on="on"
                @click="updateItem(item)"
                medium
              >
                <v-icon>
                  edit
                </v-icon>
              </v-btn>
            </template>
            <span>Редактировать</span>
          </v-tooltip>
          <v-tooltip bottom v-if="!item.closed">
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                v-on="on"
                @click="openConfirmDelete(item)"
                medium
              >
                <v-icon>
                  delete
                </v-icon>
              </v-btn>
            </template>
            <span>Удалить</span>
          </v-tooltip>
        </template>
        <template slot="no-data">
          <v-card-text>Данные не загружены</v-card-text>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog persistent v-model="opened_confirm" width="500">
      <v-card v-if="deletable">
        <v-card-title>Подтвердите удаление</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-4">
          Вы уверены, что хотите удалить?<br>
        </v-card-text>
        <v-card-actions>
          <v-flex>
            <v-btn
              @click="deleteItem(deletable.id)"
              color="warning"
              text
            >
              Удалить
            </v-btn>
            <v-btn
              @click="closeConfirmDelete"
              text
            >Отмена
            </v-btn>
          </v-flex>
        </v-card-actions>
      </v-card>
    </v-dialog>
      <Admission   @ended="closedForm"
                   v-bind="selected"
                   v-if="opened_form"
                   :opened_form="opened_form"
      >
      </Admission>

    <v-dialog   v-model="opened_load" width="500" persistent>
      <LoadXlsx   @ended="opened_load = false"  v-if="opened_load"

      >
      </LoadXlsx>
    </v-dialog>

  </v-container>
  <router-view :key="$route.fullPath" v-else></router-view>
</template>

<script>
  import selectors from "@/urls/selectors";
  import urls from "@/urls/dean";
  import UserDataMixin from '@/mixins/UserDataMixin'
  import {addGetParameters, loadData, makeGetRequest,} from "@/helper";
  import names from "@/modules/dean/routers/names";
  import TitledPageMixin from "@/mixins/TitledPageMixin";
  import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
  import {generateListUidToName, generateUidToName, uidToPositionHelds} from "@/helper/uidToName";
  import Admission from "@/modules/dean/pages/admission/Form";
  import moment from "moment";
  import {makeDeleteRequest} from "../../../../helper";
  import LoadXlsx from "./LoadXlsx"
  import ScrollCenterDialog from "@/modules/core/components/ScrollCenterDialog.vue";
  import PrintAdmissions from "@/modules/templates/admission/PrintAdmission.vue";

  export default {
    name: "ListAdmissions",

    mixins: [
      TitledPageMixin,
      PaginatedDataMapperMixin,
      UserDataMixin],
    components: {PrintAdmissions, ScrollCenterDialog, Admission,LoadXlsx},

    data () {
      return {
        search: {
          parameter: 'search',
        },
        admission:[],
        admissionPrint:[],
        loadingPrint:false,
        success: 'null',
        opened_load:false,
        subject:null,
        session:null,
        admission_type:null,
        selectors:{
          admission_type:[
            {text:'Все допуски',value: null},
            {text:'Добор/Зачет',value: 'dobor'},
            {text:'Экзамен/Зачет с оценкой',value: 'is_exam'},
            {text:'Академическая разница',value: 'academic_difference'},
          ],
          subject:[],
          session:[],
          success:[
            {text:'Все допуски',value: null},
            {text:'Успешно закрытые допуски',value: 'true'},
            {text:'Не успешно закрытые допуски',value: 'false'},
            {text:'Допуски не взятые в работу',value: 'null'},
          ],
        },
        page_title: 'Список допусков',
        total: 0,
        names:names,
        items: [],
        loading: true,
        pagination: {
          filters: {
            success: 'null',
            academic_difference: null,
            is_exam: null,
            subject: null,
            session: null,
          },
        },
        URL_LIST: urls.DEAN.ADMISSION.LIST(this.$route.params.idFaculty),
        headers: [
          {  align: 'left',  value: 'data-table-select'},
          {text: 'Номер', sortable: true, align: 'left', value: 'number',width: '8%'},
          {text: 'Дата выдачи', sortable: true, align: 'left', value: 'created',width: '8%'},
          {text: 'Предмет', sortable: false, align: 'left', value: 'subject',width: '8%'},
          {text: 'Семестр', sortable: false, align: 'left', value: 'semester', width: '8%',},
          {text: 'Контроль', sortable: false, align: 'left', value: 'control_type',width: '8%'},
          {text: 'Обучающийся', sortable: false, align: 'left', value: 'fio_student',width: '8%'},
          {text: 'Группа', sortable: false, align: 'left', value: 'group',width: '8%'},
          {text: 'Тип допуска', sortable: false, align: 'left', value: 'admission_type',width: '8%'},
          {text: 'Рейтинг в семестре', sortable: false, align: 'left', value: 'in_semester',width: '8%'},
          {text: 'Рейтинг', sortable: false, align: 'left', value: 'total',width: '8%'},
          {text: 'Статус', sortable: false, align: 'left', value: 'success',width: '8%'},
          {text: 'Действия', sortable: false, align: 'center',  value: 'option',width: '8%' },
        ]
      }
    },
    created() {
      this.loadSelectorSubject()
    },
    watch: {
      admission_type(){
        if(this.admission_type === 'academic_difference'){
          this.pagination.filters.is_exam = null
          this.pagination.filters.academic_difference = true
        }
        else if(this.admission_type === 'is_exam'){
          this.pagination.filters.is_exam = true
          this.pagination.filters.academic_difference = null
        }
        else if(this.admission_type === 'dobor'){
          this.pagination.filters.is_exam = false
          this.pagination.filters.academic_difference = null
        }
        else{
          this.pagination.filters.is_exam = null
          this.pagination.filters.academic_difference = null
        }
      }
    },
    methods: {
      PrintAdmission(){
        this.loadingPrint=true
        this.admissionPrint = []
        let prom = []
        this.admission.forEach((element) =>
          prom.push(this.loadDataDetail(element.id))
        );
        Promise.all(prom).then((values) => {
            const options = {
              name: '_blank',
              autoClose: false,
              specs: [
                'fullscreen=yes',
                'titlebar=yes',
                'scrollbars=yes'
              ],
              styles: [
                '*'
              ]
            }
            setTimeout(()=>{
              this.loadingPrint=false
              this.$htmlToPaper('printMe',options);
            }, 2000);
        })
      },
      deleteItem: function (id) {
        let url = urls.DEAN.ADMISSION.DELETE(this.$route.params.idFaculty, id)
        makeDeleteRequest(url).then(resp => {
          if (resp.ok) {
            this.closeConfirmDelete()
            this.loadData(this.getCurrentLoadUrl())
          }else{
            alert("Не удалось удалить")
          }
        })
      },
      displayDate(date, minutes = false) {

        let format = 'DD.MM.YYYY';
        if (minutes)
          format += ' HH:mm';
        return moment(date).format(format)
      },
      getNumberSemestr(semester,course){
        let num =0
        if(semester === 'осенний'){
          num = course * 2 - 1
        }else {
          num = course * 2
        }
        return num + " (" +semester+ ")"
      },
      getAbbreviation(academic_difference, is_exam){
        if (academic_difference)
          return "А/Р"
        else if (is_exam)
          return "Э"
        else
          return "Д"
      },
      getAdmissionType(academic_difference, is_exam){
        if (academic_difference)
          return "Академическая разница"
        else if (is_exam)
          return "Экзамен/Зачет с оценкой"
        else
          return "Добор/Зачет"
      },
      loadSelectorSubject(){
        loadData(
          selectors.URLS.SEMESTERS(),
          (data) => {
            this.selectors.subject=data
            this.selectors.subject.unshift({text:'Все предметы',value:null})
            this.loadSelectorSession()
          }
        )
      },
      loadSelectorSession(){
        loadData(
          selectors.URLS.ACADEMIC_YEAR_ALL_SESSION(),
          (data) => {
            this.selectors.session=data
            this.selectors.session.unshift({text:'Все сессии',value:null})
            this.loadData();
          }
        )
      },
      loadData(url = undefined) {
        this.admission=[]
        this.setLoading();
        if(!url) {
          url = this.addFiltersParameter(this.URL_LIST);
          this.setCurrentLoadUrl(url);
        }
        loadData(
          url,
          (data) => {
            this.mapResults(data, 'items')
            this.items.forEach(el => {
              this.$set(el, 'fio_student', '');
            });
            generateListUidToName(this.items, 'student', 'fio_student');
            this.setNotLoading();
          }
        )
      },
      getItems (url = undefined) {
        this.loadData(this.getCurrentLoadUrl());
        this.setCurrentLoadUrl(url, true);
      },
      getDetail(item){
        this.$router.push({name: names.ADMISSIONS.DETAIL, params: {idAdmission: item.id}})
      },
      loadDataDetail(idAdmission){
        let url = new URL( urls.DEAN.ADMISSION.DETAIL(this.$route.params.idFaculty, idAdmission))
        let params = {};
        url.search = new URLSearchParams(params).toString();
        return makeGetRequest(url).then(resp => {
          return resp.json()
        }).then(json => {
          var detail = json
          this.$set(detail, "fio_teacher", "Не указан")
          generateUidToName(detail, "teacher", "fio_teacher")
          this.$set(detail, "fio_student", "Не указан")
          generateUidToName(detail, "student", "fio_student")
          this.$set(detail, "fio_issued_by", "Не указан")
          if(detail.issued_by)
          uidToPositionHelds(detail, "issued_by", "fio_issued_by")
          this.admissionPrint.push(detail)
        })
      },
      addItem(){
        this.closedForm()
        this.getItems();
      },
      closedForm() {
        this.selected = {};
        this.opened_form = false
        this.loadData(this.getCurrentLoadUrl())
      },
    }
  }


</script>

<style scoped>

</style>

