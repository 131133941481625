
const EDITOR = {
  BASE: 'Editor',
  PERMISSION: undefined,
  CURRICULUM: {
    _BASE: undefined,
    LIST: undefined,
    SUMMARY: undefined,
    DIFFERENCE: undefined,
    DETAIL: undefined,
    SEMESTERS: {
      _BASE: undefined,
      LIST: undefined,
      DETAIL: undefined,
    },
    SUBJECTS: {
      _BASE: undefined,
      LIST: undefined,
      DETAIL: undefined,
    }
  },

};

EDITOR.PERMISSION = `${EDITOR.BASE}|Permissions`
EDITOR.CURRICULUM._BASE = `${EDITOR.BASE}|Curriculum`
EDITOR.CURRICULUM.LIST = `${EDITOR.CURRICULUM._BASE}|List`
EDITOR.CURRICULUM.SUMMARY = `${EDITOR.CURRICULUM._BASE}|Summary`
EDITOR.CURRICULUM.DIFFERENCE = `${EDITOR.CURRICULUM._BASE}|Difference`
EDITOR.CURRICULUM.DETAIL = `${EDITOR.CURRICULUM._BASE}|Detail`
EDITOR.CURRICULUM.SUBJECTS._BASE = `${EDITOR.CURRICULUM._BASE}|Subjects`
EDITOR.CURRICULUM.SUBJECTS.LIST = `${EDITOR.CURRICULUM.SUBJECTS._BASE}|List`
EDITOR.CURRICULUM.SUBJECTS.DETAIL = `${EDITOR.CURRICULUM.SUBJECTS._BASE}|Detail`
EDITOR.CURRICULUM.SEMESTERS._BASE = `${EDITOR.CURRICULUM._BASE}|Semesters`
EDITOR.CURRICULUM.SEMESTERS.LIST = `${EDITOR.CURRICULUM.SEMESTERS._BASE}|List`
EDITOR.CURRICULUM.SEMESTERS.DETAIL = `${EDITOR.CURRICULUM.SEMESTERS._BASE}|Detail`


export default {
  ...EDITOR
}
