import store from "../store";
import {addGetParameters, setUrlParameters} from "./index";
import {loadData} from "./index";

export function makeMediaHostUrl(host, url){
  return host + url
}

export function getUrl(host, base, urlname, action) {
  //console.log(base, urlname, action)
  let url = store.getters['urls/getPattern'](
    base,
    urlname,
    action
  );
  if (url !== '')
    return host + url;
  else
    return ''

}
export function getUrlAllowed(base, urlname, action) {
  return store.getters['urls/getAllowed'](
    base,
    urlname,
    action
  );
}

export function checkUrlsAllowedWithParameters(host, base, urls, finalizer=()=>{}) {
  return new Promise((resolve, reject) => {
    let checks = [];
    console.log(urls)
    for(let url of urls){
      checks.push({
        name: url.name,
        parameters: url.parameters
      })
    }
    loadData(`${host}/instance/named/url/parametrize/list/allowed/?urls=${JSON.stringify(checks)}`,
      (data) => {
        let result = [];
        for(let url of urls){
          let el = data.urls.find(x => x.name === url.name).methods.find(x => x.action === url.action)
          if(el)
            result.push({
              name: url.name,
              action: url.action,
              allowed: el.allowed,
            })
        }
        finalizer(result)
        resolve(result)
      },
      reject
    )
  })
}

export function checkUrlAllowedWithParameters(host, base, urlname, action, parameters, finalizer=()=>{}) {
  return checkUrlsAllowedWithParameters(host, base, [{name: urlname, action: action, parameters: parameters}], finalizer)
}

export function getUrlWithParameters(host, base, urlname, action, parameters={}, get_parameters=null) {
  // console.log(parameters)
  let url = setUrlParameters(
    getUrl(
      host,
      base,
      urlname,
      action
    ),
    parameters
  );
  if(get_parameters !== null){
    return addGetParameters(url, get_parameters);
  }
  return url
}

