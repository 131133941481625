<template>
  <tr>
    <td>
      <v-btn icon small @click="$emit('expand', !attrs.isExpanded)">
        <v-icon v-if="!attrs.isExpanded">expand_more</v-icon>
        <v-icon v-else>expand_less</v-icon>
      </v-btn>
    </td>
    <td>
      <difference-column :item="item" one-name="alter_semester" eios-name="semester"></difference-column>
    </td>
    <td>
      <difference-column :item="item" eios-name="course"></difference-column>
    </td>
    <td>
      <difference-column :item="item" eios-name="hours"></difference-column>
    </td>
    <td>
      <difference-column :item="item" eios-name="zets"></difference-column>
    </td>
    <td class="d-flex">
      <v-spacer></v-spacer>
      <tooltip-btn tooltip="Полная информация" icon="info" small bottom @click="openDialog(modals.detail)"
                   v-if="isAdmin" :loading="loading"></tooltip-btn>
      <tooltip-btn tooltip="Указать связь" icon="link" small bottom @click="openDialog(modals.link)"
                   v-if="!isLinked && isOnlyEIOS && isParentLinked" :loading="loading"></tooltip-btn>
      <tooltip-btn tooltip="Очистить связь" icon="link_off" small bottom @click="openDialog(modals.clear)"
                   v-if="isLinked" :loading="loading"></tooltip-btn>
      <tooltip-btn tooltip="Обновить данные" icon="update" small bottom @click="openDialog(modals.update_related)"
                   v-if="isLinked && isParentLinked" :loading="loading"></tooltip-btn>
      <tooltip-btn tooltip="Провести поиск связей по дереву" small icon="find_replace" bottom :loading="loading"
                   @click="openDialog(modals.restore_by_tree)" v-if="isLinked && isParentLinked"></tooltip-btn>
      <tooltip-btn tooltip="Обновить идентификатор связи у связанных" small icon="dataset_linked" bottom :loading="loading"
                   @click="openDialog(modals.update_relation_identity)" v-if="isLinked && isParentLinked"></tooltip-btn>
      <tooltip-btn tooltip="Создать связанные" icon="playlist_add" small bottom :loading="loading"
                   @click="openDialog(modals.create_child_list)" v-if="isLinked && isParentLinked"></tooltip-btn>
      <tooltip-btn tooltip="Обновить информацию" icon="refresh" small bottom :loading="loading"
                   @click="refresh" v-if="isLinked && isParentLinked"></tooltip-btn>
      <tooltip-btn tooltip="Создать объект" icon="add_circle" small bottom :loading="loading"
                   @click="openDialog(modals.create_related)" v-if="isOnlyOne && isParentLinked"></tooltip-btn>
      <link-action
        v-if="!isLinked && isOnlyEIOS && isParentLinked"
        v-model="modals.link.open"
        :item="item"
        :selector-url="modals.link.selector_url"
        :action-url="modals.link.action_url"
        @reload="refreshByParent"
        restorable
        preloadable
      >
      </link-action>
      <action-for-tree
        v-if="isLinked && isParentLinked"
        v-model="modals.restore_by_tree.open"
        level="semester"
        :action-url="modals.restore_by_tree.action_url"
        action-name="поиска и сопоставления по дереву объектов"
        :item="item"
        @reload="refresh"
      >
        <template v-slot:title>Поиск и сопоставление объектов</template>
      </action-for-tree>
      <action-for-tree
        v-if="isLinked && isParentLinked"
        v-model="modals.update_relation_identity.open"
        level="semester"
        :action-url="modals.update_relation_identity.action_url"
        action-name="обновления информации о связи в ЭИОС по дереву объектов"
        :item="item"
        @reload="refresh"
      >
        <template v-slot:title>Обновление информации о связях в дереве</template>
      </action-for-tree>
      <update-related
        v-if="isLinked && isParentLinked"
        v-model="modals.update_related.open"
        :action-url="modals.update_related.action_url"
        :item="item"
        @reload="refresh"
      ></update-related>
      <create-child-list
        v-if="isLinked && isParentLinked"
        v-model="modals.create_child_list.open"
        :action-url="modals.create_child_list.action_url"
        :item="item"
        child-type="Вид занятий"
        :base-object-name="`семестра учебного плана '${item.one.semester}'`"
        @reload="refresh"
      ></create-child-list>
      <action-for-tree
        v-if="isLinked"
        v-model="modals.clear.open"
        level="semester"
        :action-url="modals.clear.action_url"
        action-name="очистки информации о связи в ЭИОС и синхронизаторе с 1С Университет по дереву объектов"
        :item="item"
        @reload="refreshByParent"
        applyable
      >
        <template v-slot:title>Очистка информации о связях в дереве</template>
        <template v-slot:apply>Это действие не может быть отменено! Чтобы внести изменения, потребуется повторно связать данный объект.</template>
      </action-for-tree>
      <curriculum-action-for-object
        v-if="isOnlyOne && isParentLinked"
        v-model="modals.create_related.open"
        :item="item"
        action-name="Создание объекта ЭИОС на основании объекта 1С"
        level="semester"
        :action-url="modals.create_related.action_url"
        one
        @reload="refreshByParent"
      ></curriculum-action-for-object>
      <detail v-model="modals.detail.open" :item="item"></detail>
    </td>

  </tr>
</template>

<script>
import DifferenceColumn from "@/modules/curriculumEditor/pages/difference/components/DifferenceColumn.vue";
import urls from "@/modules/curriculumEditor/urls";
import selectors from "@/urls/selectors";
import UpdateRelated from "@/modules/administrative/dialogs/UpdateRelated.vue";
import CreateChildList from "@/modules/curriculumEditor/pages/difference/dialogs/CreateChildList.vue";
import ActionForTree from "@/modules/curriculumEditor/pages/difference/dialogs/ActionForTree.vue";
import LinkAction from "@/modules/administrative/dialogs/LinkAction.vue";
import DifferenceItemMixin from "@/modules/administrative/mixins/DifferenceItemMixin";
import Detail from "@/modules/administrative/dialogs/Detail.vue";
import TooltipBtn from "@/modules/administrative/components/TooltipBtn.vue";
import CurriculumActionForObject from "@/modules/curriculumEditor/pages/difference/dialogs/CurriculumActionForObject.vue";

export default {
  name: "Semester",
  components: {DifferenceColumn, UpdateRelated, CreateChildList, ActionForTree, LinkAction, Detail, TooltipBtn, CurriculumActionForObject},
  mixins: [DifferenceItemMixin],
  props: {
    item: undefined,
    attrs: undefined,
    loading: {
      default: false
    },
  },
  data() {
    return {

      modals: {
        link: {
          open: false,
          action_url: urls.NAMES.EDITOR.UNIVERSITY.LINK.SEMESTER,
          selector_url: this.callIfParentOne(selectors.SELECTORS.UNIVERSITY.CURRICULUM_SEMESTERS, this.getParentOneGUID(), {'relation_is_empty': true})
        },
        restore_by_tree: {
          open: false,
          action_url: urls.NAMES.EDITOR.UNIVERSITY.RESTORE_FOR_TREE.SEMESTER,
        },
        update_relation_identity: {
          open: false,
          action_url: urls.NAMES.EDITOR.UNIVERSITY.UPDATE_RELATION_IDENTIFY.SEMESTER,
        },
        update_related: {
          open: false,
          action_url: urls.NAMES.EDITOR.UNIVERSITY.UPDATE_RELATED.SEMESTER,
        },
        create_child_list: {
          open: false,
          action_url: urls.NAMES.EDITOR.UNIVERSITY.CREATE_CHILD_LIST.BY_SEMESTER,
        },
        detail: {
          open: false,
        },
        clear: {
          open: false,
          action_url: urls.NAMES.EDITOR.UNIVERSITY.CLEAR.SEMESTER
        },
        create_related: {
          open: false,
          action_url: urls.NAMES.EDITOR.UNIVERSITY.CREATE_RELATED.KIND
        }
      }
    }
  },
  computed: {
  },
  methods: {
    getDifferenceUrl() {
      return urls.NAMES.EDITOR.SEMESTER.DIFFERENCE(this.item.eios.id, this.$route.params.curriculum_id);
    },
  },
  created() {
  }
}
</script>

<style scoped>

</style>
