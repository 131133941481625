<template>
  <v-autocomplete
    clearable
    :placeholder="placeholder"
    :error="error"
    :error-messages="errorMessages"
    :label="label"
    :item-text="$props['item-text']"
    :item-value="$props['item-value']"
    :value="value"
    :readonly="loading"
    @input="emitInput($event)"
    :loading="loading"
    :items="list"
    :search-input.sync="search"
    :no-filter="noFilter"
    @keydown.enter.prevent="searchStudents(false)"
    @update:search-input="searchStudents()"
    @click:clear="searched = false"
  >
    <template v-slot:no-data>
      <slot name="no-data">
        <div v-if="!searched">
          Для поиска начните набирать текст, </br> и либо остановитесь на 1 секунду, либо нажмите Enter
        </div>
        <v-alert outlined class="py-1 my-0 mx-2" type="error" v-else-if="caught_error">
          Произошла ошибка обработки запроса
          <v-btn icon @click="searchStudents(false)">
            <v-icon>refresh</v-icon>
          </v-btn>
        </v-alert>
        <v-alert outlined class="py-1 my-0 mx-2" type="info" v-else>
          Поиск не дал результатов.
        </v-alert>
      </slot>
    </template>
  </v-autocomplete>
</template>

<script>
import {addGetParameters, sendGetRequest} from "@/helper";
import {generateListUidToName} from "@/helper/uidToName";

export default {
  name: "LoadedAutocomplete",
  props: {
    error: {
      default: false,
    },
    'error-messages': {
      default: () => [],
    },
    'value': {
      required: true,
    },
    'label': {
      default: '',
    },
    'item-text': {
      default: 'text',
    },
    'item-value': {
      default: 'value',
    },
    'placeholder': {
      default: 'Начните вводить текст',
    },
    'url': {
      required: true,
      type: String
    },
    'search-parameter': {
      required: true,
      type: String,
    },
    'post-loader': {
      required: false,
      type: Function
    },
    loadFios: {
      required: false,
      type: Boolean,
      default: true
    },
    noFilter: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      loading: false,
      list: [],
      search: '',
      timeout: null,
      selected: null,
      searched: false,
      caught_error: false,
    }
  },
  methods: {
    emitInput(event){
      this.selected = event;
      this.$emit('input', event)
    },
    searchStudents(timeout=true){
      if(this.list.findIndex(el => el.value === this.selected) !== -1)
        return;
      if(this.timeout !== null)
        clearTimeout(this.timeout);
      if(this.list.length === 1 && this.list[0].value === this.value)
        return;
      if(!(this.list.length === 1 && this.list[0].text.includes(this.search))) {
        if (this.search) {
          if (timeout) {
            this.timeout = setTimeout(
              () => {
                this.loadStudentWhenSearch();
              },
              1000
            )
          }else{
            this.loadStudentWhenSearch();
          }
        }
        if (this.list.length > 0) {
          this.list = []
        }
      }
    },
    loadStudentWhenSearch(){
      this.loading = true;
      this.caught_error = false;
      let parameters = {size: 25}
      parameters[`${this.searchParameter}`] = this.search
      let url = addGetParameters(
        this.url,
        parameters
      )
      sendGetRequest(url,
        data => {
          if(this.postLoader){
            this.postLoader(
              data,
              (vals) => {
                this.list = vals
                return this.list
              },
              () => {
               this.loading = false
              }
            );
          }else{
            let vals = data;
            if(data.hasOwnProperty('results'))
              vals = data.results
            if (this.loadFios) {
              vals.forEach(el => {
                this.$set(el, 'fio', '')
                let text = el.text.split(', ');
                this.$set(el, 'user', text[0])
                if (text.length > 1)
                  this.$set(el, 'text', text[1])
                else {
                  this.$set(el, 'text', '')
                }
              })
            }
            this.list = vals;
            if (this.loadFios) {
              generateListUidToName(
                this.list,
                "user",
                "fio",
                () => {
                  this.list.forEach(el => {
                    if (el.text !== '')
                      this.$set(el, 'text', `${el.fio}, ${el.text}`)
                    else
                      this.$set(el, 'text', `${el.fio}`)
                  })
                  this.loading = false;
                }
              )
            } else {
              this.loading = false;
              this.searched = true;
            }
          }
        }, (error) => {
          this.loading = false;
          this.caught_error = true;
          this.searched = true;
          console.log(error);
        }
      );
    },
  }
}
</script>

<style scoped>

</style>
