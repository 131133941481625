import { render, staticRenderFns } from "./Admission.vue?vue&type=template&id=65761b96&scoped=true"
import script from "./Admission.vue?vue&type=script&lang=js"
export * from "./Admission.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65761b96",
  null
  
)

export default component.exports