<template>
  <scroll-center-dialog v-model="opened_form"
                        width="500"
                        @close="closeDialog"
                        @apply="update"
                        title="Сменить выпускающую кафедру группы"
  >
    <v-alert type="info" outlined>
      Смена выпускающей кафедры позволяет изменить основную кафедру за которой закреплена группа.
    </v-alert>
    <v-text-field
      readonly
      :value="selected.text"
      class="mr-3 ml-3"
      label="Текущая кафедра"
    >
    </v-text-field>
    <v-autocomplete
      class="mr-3 ml-3"
      outline
      :error="hasError('cathedra')"
      :error-messages="getError('cathedra')"
      :items="selectors.cathedra"
      no-data-text="Нет данных"
      label="Сменить на кафедру"
      v-model="value.cathedra"
    ></v-autocomplete>
  </scroll-center-dialog>
</template>

<script>
import UserDataFormMixin from "@/mixins/UserDataFormMixin";
import urls from "@/modules/teacher/urls";
import {clearUserAuth, makeGetRequest, makeParticalUpdateRequest, makePostRequest} from "@/helper";
import selectors from "@/urls/selectors";
import dean from "@/urls/dean";
import ScrollCenterDialog from "@/modules/core/components/ScrollCenterDialog";

export default {
  name: "GroupChangeCathedra",
  components: {ScrollCenterDialog},
  mixins: [UserDataFormMixin],
  props: ['id', 'unvisible', 'selected', 'opened_form'],
  watch: {},
  data() {
    return {
      URL_CREATE: '',
      URL_UPDATE: dean.DEAN.GROUP.CHANGE_CATHEDRA(this.$route.params.idFaculty, this.id),
      selectors: {
        cathedra: [],
      },
      value: {
        cathedra: undefined,
      },
    }
  },
  methods: {
    loadSelector(data, url) {
      makeGetRequest(url).then(resp => {
        return resp.json()
      }).then(json => {
        this.selectors[data] = json.filter(el => el.value !== this.selected.value)
        //console.log(this.selectors)
      })
    },
    update: function () {
      let url = this.getUpdateUrl();
      let status = true;
      let data = this.getData();
      makeParticalUpdateRequest(url, data)
        .then(resp => {
          if (resp.status < 300) {
            status = false;
          }
          if (resp.status === 401) {
            clearUserAuth()
          }
          return resp.json();
        })
        .then(json => {
          if (!status) {
            this.$emit('refreshItem', json);
          } else {
            this.receiveErrors(json);
          }
        });
    },
    load: function (app, url) {
      if (url === null) {
        app.loaders.responsible.status = false;
        return;
      }
      makeGetRequest(url)
        .then(resp => resp.json())
        .then(json => {
          let users = [];
          let results = [];
          let next_url = null;
          if (json['next'] !== null)
            next_url = url.split('?')[0] + '?' + json['next'].split('?')[1];
          for (let i in json['results']) {
            users.push(json['results'][i].value);
            results.push(Object.assign({}, json['results'][i]));
          }
          makePostRequest(
            urls.getUrlAcc('users|user|fio', 'post'),
            {'uids': users}
          )
            .then(resp => resp.json())
            .then(json => {
              for (let i in json) {
                let replaced = results.find((item) => item.value === json[i].value);
                replaced.text = json[i].text + ' (' + replaced.text + ')';
                app.selectors.responsible.push(replaced)
              }
              results = null;
              app.load(app, next_url);
            });
        });
    },
    closeDialog() {
      this.$emit('ended', '')
    },
  },
  created() {
    this.loadSelector('cathedra', selectors.URLS.FACULTY.CATHEDRAS(this.$route.params.idFaculty))
  }
}
</script>

<style scoped>

</style>
