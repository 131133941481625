<template>
  <v-container v-if="subjects !== undefined && subjects.length > 0">
    <v-card>
      <v-toolbar
        class="mb-2"
        color="style-color-main-gray"
        flat
      >
        <v-toolbar-title v-show="$vuetify.breakpoint.mdAndUp">
          По дисциплине
        </v-toolbar-title>
        <v-toolbar-title>
          <v-select
            :items="subjects" return-object v-model="subject" class="ml-3" hide-details
            dense style="min-width: 280px;" filled :disabled="save_process.execute || loading"
            :item-text="textSubj"
          >
          </v-select>
        </v-toolbar-title>
        <v-tooltip top>
          <template v-slot:activator="{on, attrs}">
            <v-col cols="auto" class="ma-0 pa-0" v-show="$vuetify.breakpoint.mdAndUp">
              <v-icon
                class="ml-auto"
                v-on="on"
                v-bind="attrs"
              >
                info
              </v-icon>
            </v-col>
          </template>
          <span>Уважаемые пользователи, заполнение столбца "Экзамен/Зачет" возможно только при типе контроля "экзамен/зачет с оценкой".
            Если форма контроля по предмету указана неверно, пожалуйста, обратитесь в деканат учебной группы для исправления.</span>
        </v-tooltip>
        <v-spacer></v-spacer>
        <v-toolbar-title>
          <v-text-field
            v-show="$vuetify.breakpoint.mdAndUp"
            v-model="search" clearable placeholder="Поиск" hide-details style="min-width: 100px;" prepend-inner-icon="search"
            dense hint="" filled :disabled="save_process.execute"
          ></v-text-field>
        </v-toolbar-title>
        <v-toolbar-items class="py-1 px-1">
          <v-tooltip top>
            <template v-slot:activator="{on, attrs}">
              <v-btn  v-on="on" text outlined @click="loadStatement()">
                <v-icon class="mr-1" x-large>file_download</v-icon>
              </v-btn>
            </template>
            <span>Сохранить ведомость</span>
          </v-tooltip>
        </v-toolbar-items>
      </v-toolbar>
      <v-row>
        <v-col  v-on:keyup.enter="enterSave()">
          <JournalTable
            v-if="journal !== undefined && journal.length > 0"
            :can_change_additional="can_change_additional"
            :middles="middles"
            :rows="journal"
            :headers="headers"
            :is_exam="is_exam"
            :search="search"
            :save_execute="save_process.execute"
            :loading="loading"
            :errors="save_process.errors">
          </JournalTable>
          <v-container v-else-if="journal.length === 0">
            <v-toolbar
              class="mb-2"
              flat
            >
              <v-toolbar-title>
                Нет данных по обучающимся
              </v-toolbar-title>
            </v-toolbar>
          </v-container>
          <v-skeleton-loader
            v-else
            type="table"
          >
          </v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row v-if="journal.length > 0">
        <v-spacer></v-spacer>
        <v-col cols="auto mr-5" >
          <v-btn
            color="success" width="100%" @click="saveJournal"
            :disabled="save_process.execute" :loading="save_process.execute"
            v-if="gets_param.old !== true"
          >Сохранить</v-btn>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
      <v-snackbar
        v-model="save_process.alert.visible" right timeout="3000" top
        :color="save_process.alert.color()">
        {{save_process.alert.message}}
        <template v-slot:action>
          <v-btn icon @click="save_process.alert.visible = false">
            <v-icon>close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-card>
  </v-container>
  <v-container v-else-if="loading">
    <v-row>
      <v-col>
        <v-skeleton-loader
          height="50"
          type="list-item"
        >
        </v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-skeleton-loader
          height="200"
          type="card"
        >
        </v-skeleton-loader>
      </v-col>
    </v-row>
  </v-container>
  <v-container v-else>
    <v-toolbar
      class="mb-2"
      dark
      color="grey darken-4"
      flat
    >
      <v-toolbar-title>
        Нет данных по дисциплинам текущего семестра
      </v-toolbar-title>
    </v-toolbar>
  </v-container>
</template>

<script>
import names from "@/modules/teacher/routers/names";
import urls from "@/urls/teacher";
import {loadData, sendPostRequest} from "@/helper";
import {generateListUidToName} from "@/helper/uidToName";
import DebugJsonComponent from "@/modules/core/components/DebugJsonComponent";
import JournalTable from "./Template";
import TitledPageMixin from "@/mixins/TitledPageMixin";
import LeftMenuChangerMixin from "@/mixins/LeftMenuChangerMixin";
import {makeGetRequest} from "../../../../helper";

export default {
  name: "Journal",
  mixins: [TitledPageMixin, LeftMenuChangerMixin],
  components: {DebugJsonComponent, JournalTable},
  props: {},
  data: function () {
    return {
      page_title: 'Журнал группы',
      group_subject_id: undefined,
      gets_param: {old: this.$route.params.old_filter},
      group_id: this.$route.params.group_id,
      subjects: [],
      journal: [],
      group: {},
      results:[],
      middles: [],
      students: [],
      is_exam: false,
      control_type: ' ',
      can_change_additional: false,
      search: '',
      subject: undefined,
      save_process: {
        execute: false,
        success: undefined,
        errors: {
          middles: [],
          additional: [],
          exam: [],
        },
        alert: {
          visible: false,
          message: '',
          color: () => {
            if(this.save_process.success)
              return 'success'
            else if(this.save_process.success !== undefined)
              return 'warning'
            else
              return 'info'
          },
        },
      },
      selected: {},
      loading: false,
    }
  },
  watch: {
    subject: function (value) {
      /**
       * Следим за выбором другого предмета, для того что бы догрузить данные по оценкам обучающихся
       * */
      if(value !== 0) {
        this.loadJournal();
      }
    },
    journal: {
      handler: function(value){
        for (let i = 0; i < value.length; i++){
          this.results[i] = Number(value[i].medium_control[0].value) + Number(value[i].medium_control[1].value) +
            Number(value[i].medium_control[2].value) + Number(value[i].additional)
          this.journal[i].rating_in_semester = Math.round(this.results[i])
        }
      },
      deep: true
    }

  },
  computed: {
    headers: function() {
      if (this.control_type === 'экзамен') {
        return [
          {text: 'Обучающийся', value: 'fio', sortable: false, filterable: true, width: '20%'},
          {text: 'Промежуточный контроль', value: 'medium_control', sortable: false, filterable: false, align: 'start'},
          {text: 'Доп. опрос', value: 'addition', sortable: false, filterable: false,},
          {text: 'Рейтинг в семестре', value: 'rating_in_semester', sortable: false, filterable: false, },
          {text: 'Неявка', value: 'absence', sortable: false, filterable: false,},
          {text: 'Экзамен', value: 'exam', sortable: false, filterable: false, width: '8%'},
          {text: 'Автомат', value: 'automate', sortable: false, filterable: false, width: '5%'},
          {text: 'Итоговая оценка', value: 'rating', sortable: false, filterable: false, width: '10%' },]
      }
      else if (this.control_type === 'зачет с оценкой'){
        return [
          {text: 'Обучающийся', value: 'fio', sortable: false, filterable: true, width: '20%'},
          {text: 'Промежуточный контроль', value: 'medium_control', sortable: false, filterable: false, align: 'start'},
          {text: 'Доп. опрос', value: 'addition', sortable: false, filterable: false,},
          {text: 'Рейтинг в семестре', value: 'rating_in_semester', sortable: false, filterable: false, },
          {text: 'Неявка', value: 'absence', sortable: false, filterable: false,},
          {text: 'Зачет', value: 'exam', sortable: false, filterable: false, width: '8%'},
          {text: 'Автомат', value: 'automate', sortable: false, filterable: false, width: '5%'},
          {text: 'Итоговая оценка', value: 'rating', sortable: false, filterable: false, width: '10%'},]
      }
      else{
        return [
          {text: 'Обучающийся', value: 'fio', sortable: false, filterable: true, width: '20%'},
          {text: 'Промежуточный контроль', value: 'medium_control', sortable: false, filterable: false, width: '40%', align: 'start'},
          {text: 'Доп. опрос', value: 'addition', sortable: false, filterable: false, width: '10%'},
          {text: 'Рейтинг в семестре', value: 'rating_in_semester', sortable: false, filterable: false, width: '10%'},
          {text: 'Итоговая оценка', value: 'rating', sortable: false, filterable: false, width: '10%'},]
      }
    },
    // page_title(){
    //   /**
    //    * Собираемый на основании данных компонента заголовок
    //    * */
    //   if(this.group.litter)
    //     return `Журнал группы ${this.group.litter}-${this.group.course}${this.group.number}`;
    //   else
    //     return  "Журнал группы"
    // },
  },
  methods: {
    enterSave(){
      if(!this.save_process.execute && !this.save_process.execute)
        this.saveJournal()
    },
    loadStatement(){
      makeGetRequest(
        urls.URLS.GROUP.JOURNAL.STATEMENT(this.subject.id, this.gets_param)
      )
        .then(resp => {
          return resp.blob()
        }).then(data => {
        let a = document.createElement('a');
        let blob = new Blob([data], { 'type':'application/vnd.ms-excel'});
        a.href = window.URL.createObjectURL(blob);
        a.download = this.page_title.replace('Журнал группы ','') +' '+ this.subject.subject +'.xlsx';
        a.click();
      });
    },
    textSubj(item){
      if(item.mottled)
        return item.subject + " (" + item.control_type + ") " + "(Полусеместр: " + item.half + " половина)"
      else
        return item.subject + " (" + item.control_type + ")"
    },
    getFio(student_uid, container=this.students){
      /**
       * Вытаскиваем фио из кеша
       * student_uid: идентификатор обучающигося
       * container: контейнер кеша, на случае если вдруг что то поменяем, по умолчанию атрибут students текущего компонента
       * */
      let student = container.findIndex((el) => el.student === student_uid)
      return student !== -1 ? container[student].fio: student_uid
    },
    getLeftMenu(){
      /**
       * Заменяем левое меню на пункт назад, для возврата на предыдущую страницу.
       * */
      return [
        {
          title: 'Назад',
          icon: 'arrow_back_ios',
          included: false,
          router: {
            name: names.GROUPS.DETAIL,
            params: {
              group_id: this.group_id,
              old_filter:this.gets_param.old
            },
          }
        },
      ]
    },
    loadSubjects() {
      /**
      * Подгружаем список предметов ведомых преподавателем у этой группы для селектора предметов
      * */
      this.loading = true;
      loadData(
        urls.URLS.GROUP.SUBJECTS(this.group_id, this.gets_param),
        (data) => {
          if(data.length > 0){
            this.subjects = data;
            this.subject = data[0];
          }
          this.loading = false;
        }
      )
    },
    loadJournal(finalizer=()=>{}){
      /**
      * Подгружаем журнал группы по конкретному предмету
       * finalizer: функция которая вызовется в конце, для доп действий
      * */
      if(!this.loading) {
        this.loading = true;
        loadData(
          urls.URLS.GROUP.JOURNAL.LOAD(this.subject.id, this.gets_param),
          (data) => {
            /**
            * Сохраняем присланные данные в удобном нам формате, отдельно определяем что журнал для экзаменационного
            * предмета и предмета с зачетом с оценкой, и узнаем можно ли менять доп опрос
            * */
            if (data.students.length > 0) {
              if (data.control_type === 'экзамен' || data.control_type ==='зачет с оценкой'){
                this.is_exam = true
              }
              this.control_type = data.control_type;
              this.can_change_additional = data.can_change_additional;
              this.middles = data.control_points;
              this.journal = data.students;
              this.journal.forEach(el => {
                /**
                * В загруженные и используемые данные добавляем параметры для отслеживания изменений значений,
                * что бы не отправлять лишние данные в запросе и не вызывать лишних пересчетов.
                * */
                this.$set(el, 'old_exam', el.exam);
                this.$set(el, 'old_additional', el.additional);
                this.$set(el, 'old_automate', el.automate);
                this.$set(el, 'old_absence', el.absence);
                el.medium_control.forEach(mel => {
                  /**
                  * Такое же отслеживание изменения добавляем для всех контрольных и подконтрольных точек
                  * */
                  this.$set(mel, 'old_value', mel.value);
                  mel.sub_control.forEach(sel => {
                    this.$set(sel, 'old_value', sel.value);
                  });
                });
                if (this.students.find(fel => fel.student === el.student) === undefined) {
                  /**
                   * Наполняем кеш фио обучающихся, если грузим впервые, для сокращения лишних запросов на подгрузку фио обучающихся
                   * */
                  this.$set(el, 'fio', '');
                  this.students.push({
                    student: el.student,
                    fio: ''
                  })
                } else {
                  /**
                   * Если фио уже загружались, вытаскиваем их из кеша
                   * */
                  this.$set(el, 'fio', this.getFio(el.student));
                }
              });
              if (this.students.filter(el => el.fio === el.student || el.fio === '').length) {
                /**
                 * Если грузим в первый раз, то подгружаем дополнительно фио обучающихся
                 * */
                generateListUidToName(this.journal, 'student', 'fio', () => {
                  this.students.forEach(el => {
                    this.$set(el, 'fio', this.getFio(el.student, this.journal))
                  })
                  this.journal.sort((a, b) => a.fio.toUpperCase() > b.fio.toUpperCase() ? 1 : -1)
                  this.loading = false;
                  finalizer();
                });
              } else {
                this.loading = false;
                this.journal.sort((a,b) => a.fio.toUpperCase() > b.fio.toUpperCase() ? 1: -1)
                finalizer();
              }
            }
          }
        )
      }
    },
    makeSavedData(){
      /**
       * Подготавливаем данные для сохранения, так как мы не хотим вызывать лишних пересчетов,
       * то обязательно проверяем вседанные на измененя, поэтому:
       * */
      let data = {};
      this.middles.forEach((el, index) => {
        /**
         * Поскольку у нас разные уровни вложенности загруженных и отправляемых данных, мы собираем одномерную структуру
         * для отправки, используем идею о том что вторичных контрольных точек у нас не более 10 для каждой основной,
         * делаем каждой кт двузначные индексы, и делаем так что у основной кт у индекс всегда с 0, а вложенные кт начинаются с 1
         * */
        data[(index + 1) * 10] = {
          origin: el.id,
          students: []
        }
        el.sub_control_points.forEach((sel, sindex) => {
          data[(index + 1) * 10 + sindex + 1] = {
            origin: sel.id,
            students: []
          }
        });
      });
      this.journal.forEach(el => {
        /**
         * Наполняем нашу структуру значениями на основании того, изменились ли данные относительно тех что пришли.
         * */
        el.medium_control.forEach((mel, index) => {
          if (mel.value !== mel.old_value){
            data[(index + 1) * 10].students.push({
              education: el.education,
              value: mel.value,
              comment: mel.comment || undefined
            })
          }
          mel.sub_control.forEach((sel, sindex) => {
            if (sel.value !== sel.old_value){
              data[(index + 1) * 10 + sindex + 1].students.push({
                education: el.education,
                value: sel.value,
                comment: sel.comment || undefined
              })
            }
          })
        });
      });
      return data
    },
    saveJournal(){
      /**
       * При сохранении вызываем функцию подготовки данных к отправке, и потом для каждой кт проверяем что бы
       * были хоть какие то изменения, только тогда посылаем данные по этой кт, так же запускаем отдельно сохранение экзамена.
       * */
      this.save_process.execute = true;
      let selected = this.selected;
      let data = this.makeSavedData(selected);
      let controls = []
      for(let middle of Object.keys(data))
        if(data[middle].students.length > 0)
          controls.push(data[middle])
      if (controls.length > 0) {
        sendPostRequest(
          urls.URLS.GROUP.JOURNAL.SAVE_MIDDLE(this.subject.id),
          {'controls': controls},
          (res) => {
            /**
             * По завершении проверяем что бы все было хорошо, и запускаем сохранение экзамена и доп опроса
             * */
            if (res === undefined) {
              this.clearMiddleErrors();
              this.saveExam(true);
              this.save_process.execute = false;
            } else {
              this.loadJournal(() => {
                this.save_process.errors.middles = res.controls;
                this.showSaveWithErrorNotification();
                this.save_process.execute = false;
              });
            }
          },
          (error) => {
            this.loadJournal(() => {
              this.save_process.errors.middles = error.controls;
              this.showSaveWithErrorNotification();
              this.save_process.execute = false;
            });
          }
        );
      }else{
        /**
         * Если в контрольных точках нечего сохранять то просто сохраняем доп опрос и экзамен
         * */
        this.saveExam(false);
      }
    },
    saveExam(is_saved){
      /**
       * Отправляем данные для сохранения экзамена и доп опроса.
       * is_saved: показывает было ли сохранение данных на предыдущем этапе
       * */
      this.save_process.execute = true;
      let ExamSend = []
      this.journal.forEach(sel => {
        /**
         * Собираем данные для отправки с проверкой на изменение, для сокрашения передачи, отправляем список структур
         * {education: id образования, absence: неявка на экзамен, value_exam: новые балы за экзамен, value_additional: новые балы доп опроса}
         * */
        let data = {
          education: sel.education
        };
        let need_send = false;
        if(sel.absence !== sel.old_absence){
          data.absence = sel.absence
          need_send = true
        }
        if(sel.automate !== sel.old_automate && this.is_exam){
          data.automate = sel.automate;
          need_send = true;
        }
        if(sel.exam !== sel.old_exam && this.is_exam){
          data.value_exam = sel.exam;
          need_send = true;
        }
        if(sel.additional !== sel.old_additional) {
          data.value_additional = sel.additional;
          need_send = true;
        }
        if(need_send)
          ExamSend.push(data);
      });
      if (ExamSend.length > 0)
        sendPostRequest(
          urls.URLS.GROUP.JOURNAL.SAVE_END(this.subject.id),
          {'students':ExamSend},
          (res) => {
            /**
             * Если все ок, перезагружаем журнал, иначе отображаем ошибки
             * */
            if(res === undefined){
              this.loadJournal(() => {
                this.clearAllErrors();
                this.showSuccessSaveNotification();
                this.save_process.execute = false;
              });
            } else {
              this.loadJournal(() => {
                this.clearAndMapExamAdditionalError(res.students);
                this.showSaveWithErrorNotification();
                this.save_process.execute = false;
              });
            }
          },
          (error) => {
            /**
             * Отображаем ошибки, предварительно укладываем их в структуру
             * */
            this.clearAndMapExamAdditionalError(error.students);
            this.showSaveWithErrorNotification();
            this.save_process.execute = false;
          }
        );
      else{
        if (!is_saved) {
          /**
           * Отображаем что сохранять нечего, так как ничего не меняли
           * */
          this.showNothingSaveNotification();
          this.save_process.execute = false;
        }
        else {
          this.loadJournal(() => {
            this.clearAllErrors();
            this.showSuccessSaveNotification();
            this.save_process.execute = false;
          });
        }
      }
    },
    clearMiddleErrors(){
      /**
       * Очистка хранилища ошибок контрольных точек
       * */
      this.save_process.errors.middles = [];
    },
    clearAllErrors(){
      /**
       * Очистка хранилища всех ошибок
       * */
      this.clearMiddleErrors();
      this.save_process.errors.additional = [];
      this.save_process.errors.exam = [];
    },
    clearAndMapExamAdditionalError(errors){
      /**
       * Очистка и заполнение хранилища ошибок экзамена и доп опроса
       * */
      this.save_process.errors.additional = [];
      this.save_process.errors.exam = [];
      errors.forEach(el => {
        if(el.value_exam)
          this.save_process.errors.exam.push({value_exam: el.value_exam, education: el.education});
        if(el.value_additional)
          this.save_process.errors.additional.push({value_additional: el.value_additional, education: el.education});
      })
    },
    showSaveWithErrorNotification(){
      /**
       * Отобразить уведомление о наличии ошибок
       * */
      this.save_process.success = false;
      this.save_process.alert.message = 'Журнал частично не был сохранен';
      this.save_process.alert.visible = true
    },
    showSuccessSaveNotification(){
      /**
       * Отобразить уведомление об успехе
       * */
      this.save_process.success = true;
      this.save_process.alert.message = 'Журнал успешно сохранен.';
      this.save_process.alert.visible = true
    },
    showNothingSaveNotification(){
      /**
       * Отобразить уведомление об отсутствии сохраняемых данных
       * */
      this.save_process.success = undefined;
      this.save_process.alert.message = 'В журнал не внесено изменений';
      this.save_process.alert.visible = true
    },
    // loadGroup: function () {
    //   /**
    //    * Грузим название группы
    //    * */
    //   loadData(
    //     urls.URLS.GROUP.DETAIL(this.group_id, this.gets_param),
    //     (data) => {
    //       this.group = data;
    //     }
    //   );
    // },
  },
  created() {
    this.loadSubjects();
    // this.loadGroup();
    this.setPageTitleWithObject("Group", {id: this.group_id})
  }
}
</script>

<style scoped>

</style>
