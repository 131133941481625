<template>
  <v-container fluid>
    <PortfolioList
      :items="items"
    ></PortfolioList>
  </v-container>

</template>

<script>
import urls from "@/urls/student"
import UserDataMixin from '@/mixins/UserDataMixin'
import PortfolioList from "@/modules/templates/education_document/EduDocList"

export default {
  name: "EduDoc",
  components: {PortfolioList},
  mixins: [UserDataMixin],

  data() {
    return {
      items: [],
      loading: true,
      URL_LIST: urls.STUDENT.EDU_DOC.LIST(),
    }
  },

  created() {
    this.$store.commit('mainpage/changeTitle', 'Документы об образовании');
    this.getItems();
  },
  methods: {

  }
}


</script>

<style scoped>

</style>
