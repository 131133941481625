let getUrlObject = (urls, instancename, urlname, action) => {
  return urls[instancename]
    .find(x => x.name === urlname).methods
    .find(x => x.action === action)
};

const state = {
  urls: {},
};

const mutations = {
  appendUrls(state, urls){
    // console.log()
    let new_keys = Object.keys(urls);
    let existed_keys = Object.keys(state.urls);
    for(let i in new_keys){
      if(!(new_keys[i] in existed_keys)){
        state.urls[new_keys[i]] = urls[new_keys[i]];
      }
    }
  },
  clearUrls(state){
      state.urls = {};
  }
};


const getters = {
  getInstance: (state) => (instance) => {
    try{
      let urls = state.urls[instance];
      urls.getUrl = function (name) {
        return this[name];
      };
      return urls;
    } catch (e) {
      return {};
    }
  },

  getPattern: (state) => (instancename, urlname, action) => {
    try {
      return getUrlObject(state.urls, instancename, urlname, action).pattern
    }catch (e) {
      return ''
    }
  },


  getAllowed: (state ) => (instancename, urlname, action) => {
    try {
      return getUrlObject(state.urls, instancename, urlname, action).allowed;
    }catch (e) {
      return ''
    }
  },

  getUrl: (state ) => (instancename, urlname, action) => {
    try {
      return getUrlObject(state.urls, instancename, urlname, action);
    }catch (e) {
      return null
    }
  },

  checkAlreadyLoaded: (state) => (instancename) => {
    if(instancename in state.urls)
      return true;
    return false;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
}
