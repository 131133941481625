<template>
  <v-card flat class="ma-2 pa-0" v-if="!subject.loading">
    <v-card-title class="title text-sm-left ">
      <v-toolbar class="elevation-0" dense>
        <v-btn icon @click="$emit('back')">
          <v-icon>keyboard_arrow_up</v-icon>
        </v-btn>
        <v-toolbar-title>
          {{subject.data.subject}}
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
    </v-card-title>
    <LessonKindTableTemplate class="px-5" :lesson_kinds="subject.data.lesson_kinds"></LessonKindTableTemplate>
    <TeacherTableTemplate class="px-5" :teachers="subject.data.teachers"></TeacherTableTemplate>
  </v-card>
  <v-progress-circular v-else color="primary" indeterminate></v-progress-circular>
</template>

<script>
import urls from "@/urls/dean";
import {loadData} from "@/helper";
import LessonKindTableTemplate from "@/modules/templates/curriculum/LessonKindTableTemplate";
import TeacherTableTemplate from "@/modules/templates/curriculum/TeacherTableTemplate";
import {generateListUidToName} from "@/helper/uidToName";

export default {
  name: "SubjectDetail",
  components: {
    LessonKindTableTemplate,
    TeacherTableTemplate
  },
  props: {
    subject_id: Number
  },
  computed: {},
  data: function(){
    return {
      faculty_uid: this.$route.params.idFaculty,
      education_id: this.$route.params.idStudent,
      subject: {
        loading: false,
        data: [],
      },
    }
  },
  methods: {
    loadSubject: function () {
      this.subject.loading = true;
      let url = urls.DEAN.STUDENTS.CURRICULUM.SEMESTER.DETAIL(this.faculty_uid, this.education_id, this.subject_id);
      let saver = (data) => {
        this.subject.data = data;
        this.subject.loading = false;
        for (let i in this.subject.data.teachers) {
          this.$set(this.subject.data.teachers[i], "fio","-")
        }
        generateListUidToName(this.subject.data.teachers,"teacher","fio")
      };
      loadData(url, saver);
    }
  },
  created() {
    this.loadSubject();
  }
}
</script>

<style scoped>

</style>
