<template>
    <v-card >
      <v-toolbar
        class="mb-2"
        color="style-color-main-gray"
        flat
      >
        <v-text-field v-model="search" class="ma-2"prepend-inner-icon="search"></v-text-field>
        <v-spacer></v-spacer>
        <v-btn text icon @click="createItem()" v-if="createPortfolio">
          <v-icon
            medium
          >
            add
          </v-icon>
        </v-btn>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="items"
        :search="search"
        class="elevation-1"
        no-data-text="Нет данных"
        :footer-props="{'items-per-page-options':[10,25,50], 'items-per-page-text': 'Число элементов'}"
      >
        <v-progress-linear v-slot:progress color="blue" indeterminate></v-progress-linear>
        <template v-slot:item.title="props">
          <a :href="props.item.file" target="_blank">{{props.item.title}}</a>
        </template>
        <template v-slot:item.volume="props">
          <span v-if="props.item.volume>0"> {{props.item.volume}}</span>
        </template>
        <template v-slot:item.start="props">
          <span v-if="props.item.start"> {{ displayDate(props.item.start)}}</span>
        </template>
        <template v-slot:item.end="props">
          <span v-if="props.item.end"> {{ displayDate(props.item.end)}}</span>
        </template>
        <template v-slot:item.obj="props">
          <v-tooltip bottom v-if="updateBtnPortfolio">
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                v-on="on"
                @click="updateItem(props.item)"
                medium
              >
                <v-icon>
                  edit
                </v-icon>
              </v-btn>
            </template>
            <span>Редактировать</span>
          </v-tooltip>
          <v-tooltip bottom v-if="deleteBtnPortfolio">
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                v-on="on"
                @click="openConfirmDelete(props.item)"
                medium
              >
                <v-icon>
                  delete
                </v-icon>
              </v-btn>
            </template>
            <span>Удалить</span>
          </v-tooltip>
        </template>

        <template slot="no-data">
          <v-card-text>Данные не загружены</v-card-text>
        </template>
      </v-data-table>
    </v-card>
</template>

<script>
import {displayDate} from "@/helper";

  export default {
    name: "ListAdmissions",
    components:{ },
    props:{
        items:{
          type:Array
        },
        createPortfolio:{
          type:Boolean,
          default: true
        },
        deleteBtnPortfolio:{
          type:Boolean,
          default: true
        },
        updateBtnPortfolio:{
          type:Boolean,
          default: true
        }
    },

    data () {
      return {
        search:'',
        headers: [
          {text: 'Название', sortable: false, align: 'left',value:'title', width: '17%'},
          {text: 'Описание', sortable: false, align: 'left',value:'description', width: '25%'},
          {text: 'Тип пройденной программы', sortable: false, align: 'left',value:'work_type', width: '8%'},
          {text: 'Сфера', sortable: false, align: 'left',value:'knowledge_field', width: '8%'},
          {text: 'Тип документа', sortable: false, align: 'left',value:'document_type', width: '8%'},
          {text: 'Номер документа', sortable: false, align: 'left',value:'number', width: '8%'},
          {text: 'Объем пройденной программы', sortable: false, align: 'left',value:'volume', width: '8%'},
          {text: 'Дата начала изучения программы', sortable: false, align: 'left',value:'start', width: '8%'},
          {text: 'Дата конца изучения программы', sortable: false, align: 'left',value:'end', width: '8%'},
          {text: 'Действия', sortable: false, align: 'right',value:'obj', width: '10%'},
        ]
      }
    },
    methods: {
      displayDate: displayDate,
      updateItem(item){
        this.$emit('updateItem', item);
      },
      openConfirmDelete(item){
        this.$emit('openConfirmDelete', item);
      },
      createItem(){
        this.$emit('createItem', '');
      }
    },
    created() {

    },
  }


</script>

<style scoped>

</style>
