<template>
  <v-card class="mx-1" v-if="loaded" :elevation="elevation">
    <v-toolbar flat class="mx-0 px-0" :extended="$vuetify.breakpoint.mobile && show_extra_actions">
        <v-toolbar-title class="font-weight-bold subtitle-1">
          <slot name="title">
            Обучающиеся
          </slot>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <template v-slot:extension v-if="$vuetify.breakpoint.mobile && show_extra_actions">
          <v-toolbar-items @click.stop="" class="mx-auto">
            <slot name="extra_actions"></slot>
          </v-toolbar-items>
        </template>
        <v-toolbar-items @click.stop="" v-if="!$vuetify.breakpoint.mobile && show_extra_actions">
          <slot name="extra_actions"></slot>
        </v-toolbar-items>
        <v-btn v-if="expandable" icon @click.stop="expandableToggle">
          <v-icon v-if="expand_card">
            expand_less
          </v-icon>
          <v-icon v-else>
            expand_more
          </v-icon>
        </v-btn>
    </v-toolbar>
    <v-divider></v-divider>
    <v-expand-transition>
      <v-container fluid grid-list-md v-show="expand_card">
        <slot name="append"></slot>
        <v-row v-if="$vuetify.breakpoint.smAndDown">
          <v-spacer></v-spacer>
          <v-col cols="8" md="4">
            <v-text-field
              dense
              hide-details
              label="Поиск"
              v-model="search"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-data-table
          v-if="this.students.length !== 0"
          :headers="headers"
          :items="sortItem"
          :items-per-page="sortItem.length"
          :search="search"
          group-by="status"
          hide-default-footer
          mobile-breakpoint="0"
        >
          <template v-slot:group.header="{group, headers, isOpen, toggle}">
            <td :colspan="headers.length" class="text-start">
              <v-row justify="space-between">
                <v-col class="ma-0 py-0 pl-10 d-flex" cols="auto">
                  <span class="my-auto subtitle-1">
                    {{group}}
                  </span>
                </v-col>
                <v-spacer></v-spacer>
                <v-col class="ma-0 py-0 pr-10" cols="auto">
                  <v-btn @click="toggle" icon>
                    <v-icon v-if="isOpen">keyboard_arrow_up</v-icon>
                    <v-icon v-else>keyboard_arrow_down</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </td>
          </template>
          <template v-slot:header.fio="props" v-if="$vuetify.breakpoint.mdAndUp">
            <v-text-field
              class="header-title my-2 py-2"
              dense
              hide-details
              :label="props.header.text"
              v-model="search"
              height="20"
            >
              <template v-slot:label>
                <div class="header-title" style="margin-top: -2px;">{{ props.header.text }}</div>
              </template>
            </v-text-field>
          </template>
          <template v-slot:item="props">
            <tr class="text-left">
              <td>{{ props.index + 1 }}</td>
              <td>
                <slot v-if="props.item.fio" name="student_link" v-bind:student="props.item" :class="{'font-weight-bold': props.item.headman}">
                  <router-link v-if="attachment" :to="makeLink(props.item.id)"> {{ props.item.fio }}</router-link>
                  <span v-else>{{ props.item.fio }}</span>
                </slot>
                <v-skeleton-loader width="100%" type="text" v-else></v-skeleton-loader>
              </td>
              <td>
                <span >{{ props.item.record_book_number }}</span>
              </td>
              <td class="text-center">
                <v-icon color="success" v-if="props.item.headman">
                  check_circle_outline
                </v-icon>
              </td>
            </tr>
          </template>
        </v-data-table>
        <span v-else>
          В учебной группе отсутствуют обучающиеся. Пожалуйста, обратитесь в деканат учебной группы.
        </span>
      </v-container>
    </v-expand-transition>
  </v-card>
</template>

<script>
  export default {
    name: "GroupStudentsTemplate",
    props: {
      elevation: {
        type: Number,
        default: 2,
      },
      show_extra_actions: {
        type: Boolean,
        default: true,
      },
      students: {
        requirement: true,
        type: Array,
      },
      loaded: {
        requirement: true,
        type: Boolean,
      },
      attachment: {
        requirement: true,
        type: Boolean,
        default: false,
      },
      makeLink: {
        requirement: true,
        default: ()=>'',
        type: Function
      },
      expandable: {
        requirement: false,
        type: Boolean,
        default: true,
      },
      expand_default: {
        requirement: false,
        type: Boolean,
        default: true,
      },
      group: {
        requirement: false,
        type: Boolean,
        default: true,
      },
    },
    data: function() {
      return {
        headers: [
          {text: "№", sortable: false, align: 'start', width: '5%',},
          {text: "ФИО", value: 'fio', sortable: false, align: 'start', width: '70%'},
          {text: "Номер зачетной книги", sortable: false, align: 'left', width: '10%',},
          {text: "Староста", sortable: false, align: 'center', width: '15%',},
        ],
        editor: {
          open: false,
          selector: [],
        },
        search: '',
        expand_card: this.expand_default,
      }
    },
    computed:{
      sortItem: function () {
        return this.students.sort(function (a, b) {
          if (a.fio > b.fio) {
            return 1;
          }
          if (a.fio < b.fio) {
            return -1;
          }
          return 0;
        });
      }
    },
    methods: {
      expandableToggle() {
        if (this.expandable){
          this.expand_card = !this.expand_card;
        }
      },
    },
  }
</script>

<style scoped>
  .header-title{
    color: rgba(0,0,0,.6);
    font-size: .75rem;
  }
</style>
