<template>
  <v-container v-if="individual">
    <v-row justify="center">
      <v-col cols="12" md="12" lg="12" align-self="center">
        <CurriculumTemplate :curriculum="individual.curriculum"></CurriculumTemplate>
<!--        <v-card>-->
<!--          <v-toolbar-->
<!--            class="mb-2"-->
<!--            dark-->
<!--            flat-->
<!--          >-->
<!--            <v-toolbar-title>-->
<!--              {{individual.curriculum.level[0].toUpperCase()}}{{individual.curriculum.level.slice(1)}} {{individual.curriculum.form.replace('ая', 'ой')}} формы {{individual.curriculum.year}} года-->
<!--            </v-toolbar-title>-->
<!--            <v-spacer></v-spacer>-->
<!--          </v-toolbar>-->
<!--          <v-card-text class="text-left body-2 black&#45;&#45;text">-->

<!--    &lt;!&ndash;      <v-row>&ndash;&gt;-->
<!--    &lt;!&ndash;        <v-col class="text-left d-flex flex-column px-5" height="100%" cols="12" sm="8">&ndash;&gt;-->
<!--              <v-row no-gutters v-if="individual">-->
<!--                <v-col cols="12" sm="4" class="font-weight-bold">Направление подготовки:</v-col>-->
<!--                <v-col cols="12" sm="8" class="text-justify">{{individual.curriculum.direction.code}} {{individual.curriculum.direction.name}}</v-col>-->
<!--              </v-row>-->
<!--              <v-divider class="my-2"></v-divider>-->
<!--              <v-row no-gutters v-if="individual">-->
<!--                <v-col cols="12" sm="4" class="font-weight-bold">Профиль подготовки:</v-col>-->
<!--                <v-col cols="12" sm="8" class="text-justify">{{individual.curriculum.profile.name}}</v-col>-->
<!--              </v-row>-->
<!--              <v-divider class="my-2"></v-divider>-->

<!--              <v-row no-gutters v-if="individual">-->
<!--                <v-col cols="12" sm="4" class="font-weight-bold">Государственный стандарт:</v-col>-->
<!--                <v-col cols="12" sm="8" class="text-justify">-->
<!--                  <v-row class="px-3">-->
<!--                    <v-col cols="auto" class="pa-0 my-0">Номер {{individual.curriculum.standard.number}} от {{individual.curriculum.standard.year}} года</v-col>-->
<!--                    <v-spacer></v-spacer>-->
<!--                    <v-col cols="auto" class="pa-0 px-5"><v-btn icon small><v-icon>get_app</v-icon></v-btn></v-col>-->
<!--                  </v-row>-->
<!--                </v-col>-->
<!--              </v-row>-->
<!--              <v-divider class="my-2"></v-divider>-->

<!--              <v-row no-gutters v-if="individual">-->
<!--                <v-col cols="12" sm="4" class="font-weight-bold">Год поступления:</v-col>-->
<!--                <v-col cols="12" sm="8" class="text-justify">{{individual.curriculum.year}}</v-col>-->
<!--              </v-row>-->
<!--              <v-divider class="my-2"></v-divider>-->

<!--              <v-row no-gutters v-if="individual">-->
<!--                <v-col cols="12" sm="4" class="font-weight-bold">Уровень образования:</v-col>-->
<!--                <v-col cols="12" sm="8" class="text-justify">{{individual.curriculum.level}}</v-col>-->
<!--              </v-row>-->
<!--              <v-divider class="my-2"></v-divider>-->
<!--              <v-row no-gutters v-if="individual">-->
<!--                <v-col cols="12" sm="4" class="font-weight-bold">Форма обучения:</v-col>-->
<!--                <v-col cols="12" sm="8" class="text-justify">{{individual.curriculum.form}}</v-col>-->
<!--              </v-row>-->
<!--              <v-divider class="my-2"></v-divider>-->
<!--          </v-card-text>-->

    <!--        </v-col>-->
    <!--      </v-row>-->
<!--          <v-card-title class="text-left py-2 grey lighten-2">-->
<!--            <v-col cols="8">Список семестров</v-col>-->
<!--            <v-col cols="4"><v-text-field v-model="search" append-icon="search" dense hide-details ></v-text-field></v-col>-->
<!--          </v-card-title>-->

<!--          <v-card-text>-->
<!--            <v-data-table-->

<!--              :headers="headers"-->
<!--              :items="individual.semesters"-->
<!--              :search="search"-->
<!--            >-->
<!--            </v-data-table>-->
<!--          </v-card-text>-->
<!--        </v-card>-->
        <CurriculumSubjectsTemplate v-if="!individual.curriculum.is_system" :semesters="individual.semesters" :searchable="false"></CurriculumSubjectsTemplate>
<!--        <DebugJsonComponent :json="individual"></DebugJsonComponent>-->
      </v-col>
    </v-row>
  </v-container>
  <v-container v-else>
    <v-row justify="center">
      <v-col cols="12" md="12" lg="12" align-self="center">
        <v-skeleton-loader type="article"></v-skeleton-loader>
        <v-skeleton-loader type="table"></v-skeleton-loader>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import {loadData} from "../../../../helper";
  import DebugJsonComponent from "../../../core/components/DebugJsonComponent";
  import cathedras from "../../../../urls/cathedras";
  import TitledPageMixin from "../../../../mixins/TitledPageMixin";
  import CurriculumTemplate from "@/modules/templates/curriculum/CurriculumTemplate";
  import CurriculumSubjectsTemplate from "@/modules/templates/curriculum/CurriculumSubjectsTemplate";

  export default {
    name: "IndividualCurriculum",
    mixins: [
      TitledPageMixin
    ],
    components: {
      CurriculumTemplate,
      CurriculumSubjectsTemplate,
      DebugJsonComponent,
    },
    data: function(){
      return {
        page_title: 'Индивидуальный учебный план',
        individual: undefined,
        search: '',
        headers: [
          {text: 'Предмет', value: 'subject', width: '30%', filterable: true,},
          {text: 'Кафедра', value: 'cathedra', width: '30%', filterable: true,},
          {text: 'Курс', value: 'course', width: '10%', filterable: false,},
          {text: 'Семестр', value: 'semester', width: '10%', filterable: false, sortable: false,},
          {text: 'Форма контроля', value: 'control_type', width: '10%', filterable: false, sortable: false,},
          {text: 'Рейтинг', value: 'rating', width: '10%', filterable: false,}
        ],
      };
    },
    computed: {},
    methods: {
      loadCurriculum() {
        loadData(
          cathedras.CATHEDRA.STUDENT.CUTTICULUM.FROM(this.$route.params.cathedra_uid, this.$route.params.student_id),
          (data) => {
            this.individual = data;
            // this.attachment = data.url.attachment;
          }
        )
      },
    },
    created() {
      this.loadCurriculum()
    }
  }
</script>

<style scoped>

</style>
