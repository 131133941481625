import TeacherBreadcrumbs from '@/modules/teacher/pages/TeacherBreadcrumbs'
import Admissions from '@/modules/teacher/pages/student/AdmissionList'
import ListStudents from '../pages/student/List'
import DiplomaListStudents from "../pages/student/DiplomaList";
import DetailAdmission from "@/modules/teacher/pages/student/AdmissionDetail.vue";
import StudentDetail  from "../pages/student/Detail.vue"
import ListWorks from "@/modules/teacher/pages/studentWorks/ListWorks"
import DetailWork from "@/modules/teacher/pages/studentWorks/DetailWork"
import PortfolioList from '@/modules/teacher/pages/Portfolio/Portfolio'
import List from "../pages/group/List";
import Detail from "../pages/group/Detail";
import TeacherSubjectList from "../pages/subjects/List";
import ListIndividualStudents from "@/modules/teacher/pages/individual_students/ListStudents"
import IndividualStudentJournal from "@/modules/teacher/pages/individual_students/IndividualStudentJournal"
import names from "./names";
import {ACCOUNTS_INSTANCE, EDUCATION_INSTANCE, ORGANIZATION_INSTANCE} from "@/helper/consts";
import Journal from "@/modules/teacher/pages/journal/Journal.vue";

export default ([

  {
    path: '/teacher',
    name: names.BASE,
    component: TeacherBreadcrumbs,
    meta: {
      breadcrumb: 'Преподаватель',
      requiredAuth: true,
      instances: [ACCOUNTS_INSTANCE, EDUCATION_INSTANCE,],
    },
    children:[
      {
        path: 'students',
        name: names.STUDENT.LIST,
        component: ListStudents,
        meta: {
          breadcrumb: 'Список обучающихся',
          requiredAuth: true,
          instances: [ACCOUNTS_INSTANCE, EDUCATION_INSTANCE,],
        },
        children:[
          {
            path: ':student_id([0-9]+)',
            name: names.STUDENT.DETAIL,
            component: StudentDetail,
            meta: {
              breadcrumb: 'Обучающийся',
              requiredAuth: true,
              instances: [ACCOUNTS_INSTANCE, EDUCATION_INSTANCE,],
            },
          },
        ]
      },
      {
        path: 'diploma/students',
        name: names.STUDENT.DIPLOMA,
        component: DiplomaListStudents,
        props: {taught: false},
        meta: {
          breadcrumb: 'Список обучающихся дипломников',
          requiredAuth: true,
          instances: [ACCOUNTS_INSTANCE, EDUCATION_INSTANCE,],
        },
      },
      {
        path: 'admissions',
        name: names.ADMISSIONS.LIST,
        component: Admissions,
        meta: {
          breadcrumb: 'Допуски',
          requiredAuth: true,
          instances: [ACCOUNTS_INSTANCE, EDUCATION_INSTANCE,ORGANIZATION_INSTANCE],
        },
        children:[
          {
            path: ':idAdmission([0-9]+)',
            name: names.ADMISSIONS.DETAIL,
            component: DetailAdmission,
            meta: {
              breadcrumb: 'Допуск',
              requiredAuth: true,
              instances: [ACCOUNTS_INSTANCE, EDUCATION_INSTANCE,ORGANIZATION_INSTANCE],
            },
          },
        ]
      },
      {
        path: 'works',
        name: names.WORKS.LIST,
        component: ListWorks,
        meta: {
          breadcrumb: 'Работы обучающихся',
          requiredAuth: true,
          instances: [ACCOUNTS_INSTANCE, EDUCATION_INSTANCE,],
        },
        children:[
          {
            path: ':idWork([0-9]+)',
            name: names.WORKS.DETAIL,
            component: DetailWork,
            meta: {
              breadcrumb: 'Работа',
              requiredAuth: true,
              instances: [ACCOUNTS_INSTANCE, EDUCATION_INSTANCE,],
            },
          },
        ]
      },
      {
        path: 'groups/:type(supervised|led)/:old_filter?',
        name: names.GROUPS.LIST,
        component: List,
        meta: {
          breadcrumb: function(props) {
            if(props.type === 'led')
              return 'Ведомые группы'
            if(props.type === 'supervised')
              return 'Курируемые группы'
          },
          requiredAuth: true,
          instances: [ACCOUNTS_INSTANCE, EDUCATION_INSTANCE,],
        },
        children:[
          {
            path: ':group_id([0-9]+)',
            name: names.GROUPS.DETAIL,
            component: Detail,
            meta: {
              breadcrumb: 'Группа',
              requiredAuth: true,
              instances: [ACCOUNTS_INSTANCE, EDUCATION_INSTANCE,],
            },
            children:[
              {
                path: 'journal',
                name: names.GROUPS.JOURNAL,
                component: Journal,
                meta: {
                  breadcrumb: 'Журнал',
                  requiredAuth: true,
                  instances: [ACCOUNTS_INSTANCE, EDUCATION_INSTANCE,],
                },
                children:[]
              },
            ]
          },
        ]
      },
      {
        path: 'individual_students',
        name: names.INDIVIDUAL_STUDENTS.LIST,//'Administrative|Cathedra|Teachers',
        component: ListIndividualStudents,
        meta: {
          requiredAuth: true,
          breadcrumb: 'Список обучающихся с индивидуальным учебным планом',
          instances: [ACCOUNTS_INSTANCE, EDUCATION_INSTANCE,],
        },
        children: [
          {
            path: ':student_id(\\d+)/journal',
            name: names.INDIVIDUAL_STUDENTS.JOURNAL,
            component: IndividualStudentJournal,
            meta: {
              requiredAuth: true,
              breadcrumb: 'Журнал оценок',
            },
          },
        ]
      },
      {
        path: 'subjects',
        name: names.SUBJECTS.LIST,
        component: TeacherSubjectList,
        meta: {
          breadcrumb: 'Преподаваемые дисциплины',
          requiredAuth: true,
          instances: [ACCOUNTS_INSTANCE, EDUCATION_INSTANCE,],
        },
        children:[
        ]
      },
      {
        path: 'portfolio',
        name: names.PORTFOLIO,
        component: PortfolioList,
        meta: {
          breadcrumb: 'Список достижений',
          requiredAuth: true,
          instances: [ACCOUNTS_INSTANCE, EDUCATION_INSTANCE,],
        },
      },
    ]
  },
])
