<template >
  <v-card  class="py-0 my-0">
<!--          <DebugJsonComponent :json="subjects.data"></DebugJsonComponent>-->
    <v-toolbar class="mb-2"
               flat
               :style="{'height':`${$vuetify.breakpoint.smAndUp ? 64: 150}px`} "
    >
      <template v-if="$vuetify.breakpoint.mdAndUp" >
        <v-row>
          <v-col cols="8">
            <v-text-field class="pt-4"
                          dense
                          placeholder="Поиск"
                          v-model="search.value"
                          @keypress.enter="searchData"
                          @click:clear="clearSearchData"
                          clearable
                          messages="Для поиска нажмите Enter"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-autocomplete class="py-3"  hide-details label="Индивидуальность" :items="selectors.is_individual" v-model="pagination.filters.is_individual"
            ></v-autocomplete>
          </v-col>
          <v-col cols="2">
            <v-autocomplete  class="py-3 "  hide-details label="Семестр" :items="selectors.semesters" v-model="pagination.filters.year_semester"
            ></v-autocomplete>
          </v-col>
        </v-row>
      </template>
      <template v-slot:extension v-if='$vuetify.breakpoint.smAndDown' >
        <v-col>
          <v-text-field
            dense
            placeholder="Поиск"
            v-model="search.value"
            @keypress.enter="searchData"
            @click:clear="clearSearchData"
            clearable
            messages="Для поиска нажмите Enter или кнопку поиск"
          ></v-text-field>
          <v-autocomplete class="py-3" hide-details label="Индивидуальность" :items="selectors.is_individual" v-model="pagination.filters.is_individual"
          ></v-autocomplete>
          <v-autocomplete  class="py-3" hide-details label="Семестр" :items="selectors.semesters" v-model="semester"
          ></v-autocomplete>
        </v-col>
      </template>
    </v-toolbar>
    <v-skeleton-loader v-if="!subjects.loaded"
                       type="table-heading, list-item-two-line, image, table-tfoot"
    ></v-skeleton-loader>
    <v-data-table
      v-if="subjects.loaded"
      :headers="subjects.headers"
      :items="subjects.data"
      :loading="pagination.loading"
      :expanded.sync="subjects.expanded"
      :server-items-length="pagination.count"
      :items-per-page.sync="pagination.size"
      :page.sync="pagination.page"
      show-expand
      :footer-props="{'items-per-page-options':[50,100]}"
    >
      <template v-slot:item.rating="props">
        <v-row class=" fill-height" style="min-width: 120px;">
          <v-col  cols="4" class="ma-0 pa-0 my-auto">
            <span>{{props.item.rating}}</span>
          </v-col>
          <v-col  cols="8" class="ma-0 pa-0 d-flex justify-space-between my-auto" >
            <v-chip v-if="props.item.reload"
                    small :close="props.item.old ? false : true"
                    @click:close="openConfirmClose({id: props.item.id}, false)">
              Перезачет
            </v-chip>
          </v-col>
        </v-row>
      </template>
      <template v-slot:expanded-item="props">
        <td v-if="!props.item.old" :colspan="props.headers.length">
          <SubjectDetail :subject_id="props.item.id" @back="subjects.expanded.splice(subjects.expanded.indexOf(props.item), 1)"></SubjectDetail>
        </td>
        <td v-else :colspan="props.headers.length">
          <v-card flat class="ma-2 pa-0">
            <v-alert outlined type="info">
              Отсутствует в учебном плане группы
            </v-alert>
          </v-card>
        </td>
      </template>
      <template v-slot:item.is_individual="props">
        <v-chip v-if="props.item.is_individual"
                small :close="true"
                @click:close="openConfirmClose({id: props.item.id}, true)">
          Индивидуальный
        </v-chip>
      </template>
      <template v-slot:item.action="props">
        <v-tooltip bottom v-if="!props.item.closed && !props.item.old">
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              v-on="on"
              @click="updateItem(props.item)"
              class="mr-2"
              medium
            >
              <v-icon>
                edit
              </v-icon>
            </v-btn>
          </template>
          <span>Редактировать</span>
        </v-tooltip>
        <v-tooltip bottom v-if="!props.item.closed && !props.item.old && !props.item.reload">
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              v-on="on"
              @click="openReloadForm(props.item)"
              class="mr-2"
              medium
            >
              <v-icon>
                playlist_add_check
              </v-icon>
            </v-btn>
          </template>
          <span>Добавить перезачет</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-dialog persistent v-model="opened_confirm" width="600" >
      <v-card v-if="closable">
        <v-card-title>
          {{`${opened_confirm_individual ? 'Привести в соответствие с учебным планом':'Отмена перезачета'}`}}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text v-if="!opened_confirm_individual">
          <div class="text-subtitle-1 text-left">
            Вы уверены, что хотите отменить перезачет?
          </div>
          <v-checkbox
            v-model="reset"
            label="Аннулировать все оценки"
          ></v-checkbox>
        </v-card-text>
        <div  v-else class="px-2 pt-2 pb-1">
          <v-alert  outlined type="warning" border="left" >
            <div class="text-justify">
              Внимание!</br>
              —  Снятие отметки "Индивидуальный" с предмета по выбору (если он не единственный в группе выбора у обучающегося) приведет к удалению со всей успеваемостью. </br>
              —  Предмет, не относящийся к категории "по выбору", при снятии отметки будет приведен в соответствие с общим учебным планом.
            </div>
          </v-alert>
        </div>
        <v-card-actions>
          <v-flex>
            <v-btn
              class="mr-2"
              @click="CloseItem"
              :color="`${!opened_confirm_individual ? 'warning':''}`"
            >
              {{`${opened_confirm_individual ? 'Да':'Подтвердить'}`}}
            </v-btn>
            <v-btn
              class="ml-2"
              @click="closeConfirmClose"
            >{{`${opened_confirm_individual ? 'Нет':'Отмена'}`}}
            </v-btn>
          </v-flex>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <IndividualSemesterChangeForm   @ended="closedForm"
                                    v-bind="selected"
                                    v-bind:opened_form="opened_form"
                                    v-if="opened_form"
                                    :level="level"
    >
    </IndividualSemesterChangeForm>
    <SemesterReloadForm @ended="closeReloadForm"
                        v-bind="selected"
                        v-bind:opened_form="opened_reload_form"
                        v-if="opened_reload_form"

    >
    </SemesterReloadForm>
  </v-card>
</template>

<script>
  import urls from "@/urls/dean"
  import selectors from "@/urls/selectors"
  import {loadData, sendPostRequest, sendPutRequest} from "@/helper"
  import DebugJsonComponent from "@/modules/core/components/DebugJsonComponent";
  import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin"
  import UserDataMixin from "@/mixins/UserDataMixin"
  import SubjectDetail from "./SubjectDetail"
  import IndividualSemesterChangeForm from "@/modules/dean/pages/student/curriculum/IndividualSemesterChangeForm";
  import SemesterReloadForm from "@/modules/dean/pages/student/curriculum/SemesterReloadForm";

  export default {
    name: "SubjectList",
    mixins: [
      PaginatedDataMapperMixin,
      UserDataMixin
    ],
    components: {
      IndividualSemesterChangeForm,
      SemesterReloadForm,
      DebugJsonComponent,
      SubjectDetail,
    },
    props:{
      group_id: {
        required: false,
        default: '',
      },
      level: {
        required: false,
        default: '',
      },
    },
    data: function () {
      return {
        search: {
          parameter: 'subject_name',
        },
        URL_LIST: urls.DEAN.STUDENTS.CURRICULUM.SEMESTER.LIST(this.$route.params.idFaculty,this.$route.params.idStudent),
        opened_confirm: false,
        opened_confirm_individual: false,
        opened_reload_form: false,
        closable: {},
        reset: false,
        single_expand: true,
        semester: null,
        loadErrorMsg: '',
        actions: {
          nextCourseApplier: false,
          prevCourseApplier: false,
        },
        selectors: {
          semesters: [],
          is_individual:[
            {text:'Все предметы',value: null},
            {text:'Индивидуальные предметы',value: 'true'},
            {text:'Не индивидуальные',value: 'false'},
          ],
        },
        pagination: {
          size:50,
          filters: {
            year_semester: null,
            is_individual: null
          }
        },
        subjects: {
          data: {},
          expanded: [],
          loading: false,
          loaded: false,
          headers: [
            {text: "Предмет", value: 'subject', sortable: false, align: 'left', width: '25%'},
            {text: "Кафедра", value: 'cathedra', sortable: false, align: 'left', width: '25%'},
            {text: "Индивидуальный", value: 'is_individual', sortable: false, align: 'left'},
            {text: "Курс", value: 'course', sortable: false, align: 'left'},
            {text: "Семестр", value: 'semester', sortable: false, align: 'left'},
            {text: "Форма контроля", value: 'control_type', sortable: false, align: 'left'},
            {text: "Количество часов", value: 'hours',sortable: false, align: 'left'},
            {text: "Зеты", value: 'zets', sortable: false,  align: 'left'},
            {text: "Итоговые баллы", value: 'rating', sortable: false,  align: 'left', width: '15%'},
            {text: "Действия", value: 'action', sortable: false,  align: 'left', width: '10%'},
            {text: '', value: 'data-table-expand', width: '5%'},
          ],
        }
      }
    },
    watch: {
    },
    methods: {
      closedForm() {
        this.opened_form = false
        this.loadData(this.getCurrentLoadUrl())
      },
      openConfirmClose(obj, individual) {
        this.closable = obj
        this.opened_confirm = true
        this.opened_confirm_individual = individual
      },
      closeConfirmClose() {
        this.opened_confirm = false
        setTimeout(() => {
          this.closable = null
        }, 500)
      },
      CloseItem(){
        if(!this.opened_confirm_individual)
          this.CloseItemReload()
        else
          this.ClearIndividual()
      },
      openReloadForm(item){
        this.selected = item
        this.opened_reload_form = true
      },
      closeReloadForm(){
        this.opened_reload_form = false
        this.loadData(this.getCurrentLoadUrl())
      },
      CloseItemReload(){
        let value = {
          semesters: [{
            semester:  this.closable.id,
            reset: this.reset
          }]
        }
        let url = urls.DEAN.JOURNAL.SEMESTER.CANCEL_RELOAD(this.$route.params.idFaculty, this.$route.params.idStudent)
        sendPostRequest(
          url,
          value,
          ()=>{
            this.opened_confirm = false
            this.reset = false
            let subject_index = this.subjects.data.findIndex(el => el.id === this.closable.id)
            if(subject_index !== -1)
              this.subjects.data[subject_index].reload = false
            this.loadData(this.getCurrentLoadUrl())
          },
          (res)=>{
            this.receiveErrors(res)
          },
        )
      },
      ClearIndividual(){
        sendPutRequest(
          urls.DEAN.STUDENTS.CURRICULUM.SEMESTER.INDIVIDUAL.CLEAR_INDIVIDUAL(
            this.$route.params.idFaculty,
            this.$route.params.idStudent,
            this.closable.id
          ),
          {},
          ()=>{
            this.opened_confirm = false
            this.getItems()
          },
          (error)=>{
            this.receiveErrors(error)
          }
        )
      },
      loadData(url=undefined) {
        this.setLoading();
        if(!url) {
          url = this.addFiltersParameter(this.URL_LIST);
          url = this.addSizeParameter(url);
          this.setCurrentLoadUrl(url);
        }
        loadData(
          url,
          (data) => {
            this.subjects.data = data.results
            this.mapPagination(data)
            this.setCurrentLoadUrl(url)
            this.subjects.loaded = true
            this.subjects.loading = false
            this.setNotLoading()
          }
        )
      },
      getItems(url = undefined){
        this.loadData(this.getCurrentLoadUrl())
        this.setCurrentLoadUrl(url, true)
      },
      loadSelector(){
        loadData(
          selectors.SELECTORS.DEAN.YEAR_SEMESTER(this.group_id),
          (data) => {
            this.selectors.semesters= data
            this.selectors.semesters.unshift({text:'Все семестры',value:null},)
          }
        )
      },
    },
    created() {
      this.loadData()
      this.loadSelector()
    }
  }
</script>

<style scoped>

</style>
