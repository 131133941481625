<template>
  <EducationWorks v-if="work" :EducationWorks="work">
    <template v-slot:append_body>

      <scroll-center-dialog v-model="form"
                            width="500"
                            title="Изменить статус работы"
                            @close="form = false"
                            @apply="checkWork"
      >
        <v-autocomplete class="pa-2 py-4"
                        label="Статус работы"
                        v-model="value.accept"
                        :items="[{text:'Принята',value:true},{text:'Не принята',value:false}]">
        </v-autocomplete>
      </scroll-center-dialog>

      <div class="py-2">
        <v-toolbar dense elevation="0">
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn elevation="1" color="info" @click="form = true">Изменить статус работы</v-btn>
          </v-toolbar-items>
        </v-toolbar>
      </div>
    </template>
  </EducationWorks>
</template>

<script>
import {makeGetRequest, makePostRequest} from "@/helper";
import urls from "@/modules/teacher/urls";
import {generateUidToName} from "@/helper/uidToName";
import UserDataMixin from "@/mixins/UserDataMixin";
import DebugJsonComponent from "@/modules/core/components/DebugJsonComponent";
import EducationWorks from "@/modules/templates/work/EducationWorks";
import ScrollCenterDialog from "@/modules/core/components/ScrollCenterDialog";

export default {
  name: "DetailWork",
  components: {
    ScrollCenterDialog,
    DebugJsonComponent,
    EducationWorks
  },
  mixins: [UserDataMixin],
  data: function () {
    return {
      work: null,
      form:false,
      value:{accept:false,}
    }
  },
  methods: {
    setLabel(){
       if (this.value.accept === null )
         return 'Работа еще не проверялась'
       else if  (this.value.accept)
         return 'Работа  проверена и принята '
       else return  'Работа проверена и не принята'
    },
    checkWork(){
      let url = new URL(urls.getUrl('teacher|individual|work-check', 'check')
        .replace('<id>', this.$route.params.idWork))
      let status = true;
      makePostRequest(url, this.value)
        .then(resp => {
          if (!resp.ok) {
            status = false;
          }
          return resp.json();
        })
        .then(json => {
          if (status) {
            this.loadData()
            this.form = false
          } else {

          }
        })

    },
    loadData( ){
      let url = new URL(urls.getUrl('teacher|individual|work-detail', 'retrieve')
        .replace('<id>', this.$route.params.idWork))
      let params = {};
      url.search = new URLSearchParams(params).toString();
      let sys = true;
      makeGetRequest(url).then(resp => {
        if (!resp.ok) {
          sys = false
        }
        return resp.json()
      }).then(json => {
        this.work = json
        this.value.accept = this.work.accept
        this.$set(this.work, "fio_teacher", "")
        generateUidToName(this.work, "teacher", "fio_teacher")

        this.$set(this.work, "fio_student", "")
        generateUidToName(this.work, "student", "fio_student")

      })
    },

  },
  created() {
    this.loadData()
  }
}
</script>

<style scoped>

</style>
