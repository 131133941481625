<template>
  <v-app>
    <Topmenu />
    <Leftmenu style="height: calc(var(--doc-height))" />
    <v-main
      id="base-content"
    >
      <div
        class="overflow-y-auto"
        style="height: calc(var(--doc-height) - 60px);"
      >
        <router-view />
      </div>
    </v-main>
  </v-app>
</template>

<script>
  import {mapState} from 'vuex'
  import Leftmenu from "./modules/menus/LeftMenu";
  import Topmenu from "./modules/menus/TopMenu";
  import {isAliveToken, isWorker, getInstMenu} from "./helper";

  export default {
    components: {Topmenu, Leftmenu},
    data() {
      return {
        clipped: true,
        drawer: true,
        surls: {},
      }
    },
    computed: mapState({
      leftmenu: state => state.leftmenu,
      maintoolbar: state => state.maintoolbar,
      user: state => state.user,
      urls: state => state.urls,
    }),
    watch: {
      urls() {
        this.surls = this.urls.urls
      }
    },
    methods: {
      documentHeight () {
        const doc = document.documentElement
        doc.style.setProperty('--doc-height', `${window.innerHeight}px`)
      }
    },
    mounted () {
      window.addEventListener("resize", this.documentHeight)
      this.documentHeight()
    },
    unmounted () {
      window.removeEventListener("resize", this.documentHeight)
    },
    created: async function () {

    }
  }
</script>

<style>
  #app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    /*color: #2cc30e50;*/
    /*height: 100%;*/
  }
</style>
