<template>
  <scroll-center-dialog v-model="opened"
                        width="600"
                        :title="!data_work ? 'Загрузка документа' : 'Редактирование загруженного документа'"
                        @close="$emit('close', false)"
                        @apply="save"
                        :disabled="loading"
                        :loading="loading"
  >

    <!--      <DebugJsonComponent :json="curriculum.base_curriculum.params.education_id"></DebugJsonComponent>-->
    <!--      <DebugJsonComponent :json="work.data.document.file"></DebugJsonComponent>-->

    <v-autocomplete
      label="Предмет"
      :disabled="Boolean(this.data_work.id)"
      :items="subjects.selector"
      v-model="subjects.subject"
      item-text="text"
      item-value="value"
      class="pa-1 ma-1 pt-4"
    >
    </v-autocomplete>
    <v-text-field
      :error="hasError('name')"
      :error-messages="getError('name')"
      label="Название"
      v-model="work.name"
      class="pa-1 ma-1"
    ></v-text-field>
    <v-text-field
      :error="hasError('subtitle')"
      :error-messages="getError('subtitle')"
      label="Описание"
      v-model="work.subtitle"
      class="pa-1 ma-1"
    ></v-text-field>
    <v-autocomplete
      label="Тип"
      :error="hasError('type')"
      :error-messages="getError('type')"
      :items="choices.type"
      v-model="work.type"
      class="pa-1 ma-1"
    >
    </v-autocomplete>
    <v-file-input
      v-if="typeof work.file !== 'string'"
      class="pa-1 ma-1"
      :error="hasError('file')"
      :error-messages="getError('file')"
      v-model="work.file"
      :messages="setInputFile(work.file)"
      accept="application/pdf"
      label="Файл (Формат .pdf, до 15МБ)"
    ></v-file-input>
    <v-text-field
      v-else
      v-model="work.file"
      class="pa-1 ma-1"
      clearable
      append-icon="download"
      prepend-icon="attach_file"
      @click:append="loadFileWork"
      @click:clear="work.file = null"
    ></v-text-field>
    <v-alert v-if="hasError('student')" type="error">{{getError('student')[0]}}</v-alert>
  </scroll-center-dialog>
</template>

<script>
import DebugJsonComponent from "@/modules/core/components/DebugJsonComponent"
import {loadData, makeGetRequest, sendPostRequest, sendPutRequest, setUrlParameters} from "@/helper"
import selectors from "@/urls/selectors";
import {makePostRequestFile, makeUpdateRequest} from "@/helper"
import UserDataFormMixin from "@/mixins/UserDataFormMixin"
import dean from "@/urls/dean";
import choices from "@/urls/choices"
import {loadFile} from "@/helper/fileLoader"
import ScrollCenterDialog from "@/modules/core/components/ScrollCenterDialog";

export default {
  name: "SubjectsForm",
  mixins: [UserDataFormMixin],
  components: {
    ScrollCenterDialog,
    DebugJsonComponent
  },
  data(){
    return {
      URL_SELECTORS_SUBJECT: selectors.SELECTORS.CURRICULUM.SEMESTERS.LIST(this.$route.params.idStudent, {faculty: this.$route.params.idFaculty}),
      URL_CHOICE_WORK_TYPE: choices.EDUCATION.CHOICES.WORK_TYPE(),
      opened: true,
      curriculum:null,
      subjects: {
        subject:null,
        selector:[]
      },
      loading: false,
      error:null,
      choices: {
        type: [
        ],
      },
      work: {
        name: null,
        type: '',
        subtitle: null,
        file: null,
        id: null,
        data: null,
      },
    }
  },
  props:{
    data_work: {
      required: false,
      default: '',
    }
  },
  methods: {
    setInputFile(str){
      if (typeof str === 'string'){
        return [str]
      }
      return []
    },
    loadChoices(url, choices){
      loadData(
        url,
        (data) => {
          this.choices[choices] = data;
        }
      )
    },
    loadSelector(url) {
      let sys = true
      this.loading = true
      makeGetRequest(url).then(resp => {
        if (!resp.ok) {
          sys = false
          this.loading = false
        }
        return resp.json()
      }).then(json => {
        this.subjects.selector = json
        this.loading = false
      })
    },
    save(){
      this.loading = true
      if (!this.data_work){
        this.create()
      }
      else{
        this.change()
      }
    },
    create(){
      let url = dean.DEAN.STUDENTS.INDIVIDUAL_WORK.ADD_WORK(this.$route.params.idFaculty, this.$route.params.idStudent);
      let formData = new FormData();
      formData.append('name', this.work.name)
      formData.append('type', this.work.type)
      formData.append('semester', this.subjects.subject)
      formData.append('subtitle', this.work.subtitle)
      formData.append('file', this.work.file)
      let status = true;
      makePostRequestFile(url, formData,"POST")
        .then(resp => {
          if (!resp.ok) {
            status = false;
          }
          return resp.json();
        })
        .then(json => {
          if (status) {
            this.loading = false
            this.close();
          } else {
            this.loading = false
            this.receiveErrors(json);
          }
        })
    },
    change(){
      let url = dean.DEAN.STUDENTS.INDIVIDUAL_WORK.UPDATE_WORK(this.$route.params.idFaculty, this.$route.params.idStudent, this.work.id)
      let formData = new FormData();
      formData.append('name', this.work.name)
      if (typeof this.work.file !== 'string'){
        formData.append('file', this.work.file)
      }
      formData.append('type', this.work.type)
      formData.append('subtitle', this.work.subtitle)

      let status = true;
      makePostRequestFile(url, formData, "PATCH")
        .then(resp => {
          if (!resp.ok) {
            status = false;
          }
          return resp.json();
        })
        .then(json => {
          if (status) {
            this.loading = false
            this.close();
          } else {
            this.loading = false
            this.receiveErrors(json)
          }
        })
    },
    close(){
      this.$emit('close');
    },
    LoadWorkName(){
      let url = dean.DEAN.STUDENTS.INDIVIDUAL_WORK.DETAIL(this.$route.params.idFaculty, this.$route.params.idStudent, this.data_work.id)
      loadData(url, (data) => {
        this.work.name = data.document.name
        this.work.id = data.id
        this.work.data = data
      })
    },
    loadFileWork(){
      loadFile(this.work.data.document.file)
    },
  },
  created() {
    if(this.$route.params.idSubject){
      this.subjects.subject = Number(this.$route.params.idSubject)
    }
    this.loadChoices(this.URL_CHOICE_WORK_TYPE,'type')
    this.loadSelector(this.URL_SELECTORS_SUBJECT)
    if (this.data_work) {
      this.subjects.subject = this.data_work.semester.id
      this.work.subtitle = this.data_work.subtitle
      this.work.type = this.data_work.type
      this.work.file = this.data_work.file_name
      this.LoadWorkName()
    }
  }
}
</script>

<style scoped>

</style>
