<template>
  <v-container v-if="detail">
    <v-toolbar dense class="mb-2" color="style-color-main-gray" flat>
      <v-toolbar-title>
          Предмет
      </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-row>
      <v-col cols="12" md="6" class="py-md-4 mb-2">
        <v-card class="pa-4 pb-2" min-height="240" elevation="4">
          <v-row no-gutters class="text-start subtitle-1 py-1">
            <v-col cols="12" md="4" class="font-weight-bold">Предмет:</v-col>
            <v-col cols="12" md="8" class=" d-flex">{{detail.subject_name}}</v-col>
          </v-row>
          <v-row no-gutters class="text-start subtitle-1 py-1">
            <v-col cols="12" md="4" class="font-weight-bold">Кафедра:</v-col>
            <v-col cols="12" md="8" class=" d-flex">{{detail.cathedra}}</v-col>
          </v-row>
          <v-row no-gutters class="text-start subtitle-1 py-1">
            <v-col cols="12" md="4" class="font-weight-bold">Семестр:</v-col>
            <v-col cols="12" md="8" class=" d-flex">{{detail.semester}}</v-col>
          </v-row>
          <v-row no-gutters class="text-start subtitle-1 py-1">
            <v-col cols="12" md="4" class="font-weight-bold">Курс:</v-col>
            <v-col cols="12" md="8" class=" d-flex">{{detail.course.number}} ({{detail.course.level}})</v-col>
          </v-row>
          <v-row no-gutters class="text-start subtitle-1 py-1">
            <v-col cols="12" md="4" class="font-weight-bold">Файл рабочей программы:</v-col>
            <v-col cols="12" md="8" class=" d-flex">
              <span v-if="detail.documents == null || !detail.documents.appraisal_tools">Файл не загружен</span>
              <span v-else>
                {{detail.documents.appraisal_tools.file_name}}
                <v-btn @click="loadFileWork(detail.documents.appraisal_tools)" icon><v-icon >get_app</v-icon></v-btn>
              </span>
            </v-col>
          </v-row>
          <v-row no-gutters class="text-start subtitle-1 py-1">
            <v-col cols="12" md="4" class="font-weight-bold">Файл методических материалов:</v-col>
            <v-col cols="12" md="8" class=" d-flex">
            <span v-if="detail.documents == null || !detail.documents.methodological_materials">Файл не загружен</span>
            <span v-else>
              {{detail.documents.methodological_materials.file_name}}
              <v-btn @click="loadFileWork(detail.documents.methodological_materials)" icon><v-icon >get_app</v-icon></v-btn>
            </span>
          </v-col>
          </v-row>
          <v-row no-gutters class="text-start subtitle-1 py-1">
            <v-col cols="12" md="4" class="font-weight-bold">Файл оценочных средств:</v-col>
            <v-col cols="12" md="8" class=" d-flex">
              <span v-if="detail.documents == null || !detail.documents.work_program">Файл не загружен</span>
              <span v-else>
                {{detail.documents.work_program.file_name}}
                <v-btn @click="loadFileWork(detail.documents.work_program)" icon><v-icon >get_app</v-icon></v-btn>
              </span>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" class="py-md-4 " >
        <v-card class="pa-4 pb-2" min-height="360" elevation="4">
          <v-row no-gutters class="text-start subtitle-1 py-1">
            <v-col cols="12" md="4" class="font-weight-bold">Рейтинг:</v-col>
            <v-col cols="12" md="8" class=" d-flex">{{detail.rating}}</v-col>
          </v-row>
          <v-row no-gutters class="text-start subtitle-1 py-1">
            <v-col cols="12" md="4" class="font-weight-bold">Экзамен:</v-col>
            <v-col cols="12" md="8" class=" d-flex">{{detail.exam}}</v-col>
          </v-row>
          <v-row no-gutters class="text-start subtitle-1 py-1">
            <v-col cols="12" md="4" class="font-weight-bold">Рейтинг в семестре:</v-col>
            <v-col cols="12" md="8" class=" d-flex">{{detail.rating_in_semester}}</v-col>
          </v-row>
          <v-row no-gutters class="text-start subtitle-1 py-1">
            <v-col cols="12" md="4" class="font-weight-bold">Оценка:</v-col>
            <v-col cols="12" md="8" class=" d-flex">{{detail.text_rating}}</v-col>
          </v-row>
          <v-row no-gutters class="text-start subtitle-1 py-1">
            <v-col cols="12" md="4" class="font-weight-bold">Тип дисциплины:</v-col>
            <v-col cols="12" md="8" class=" d-flex">{{detail.control_type}}</v-col>
          </v-row>
          <v-row no-gutters class="text-start subtitle-1 py-1">
            <v-col cols="12" md="4" class="font-weight-bold">Индивидуальный:</v-col>
            <v-col cols="12" md="8">
              <td>
                <v-icon v-if="detail.is_individual">done</v-icon>
                <v-icon v-else>clear</v-icon>
              </td>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-toolbar dense class="mb-2" color="style-color-main-gray" flat v-if="detail.medium_control.length > 0">
      <v-toolbar-title>
        Контрольные точки
      </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>

    <div v-for="item in detail.medium_control">
      <v-col class="py-md-1 ">
      <v-card class="pa-2 pb-2 px-4 py-3" elevation="2">
        <v-row>
          <v-row no-gutters class="text-start subtitle-1 py-1">
            <v-col cols="12" md="4" class="font-weight-bold">Балл:</v-col>
            <v-col cols="12" md="8" class=" d-flex">{{item.value}}</v-col>
          </v-row>
          <v-row no-gutters class="text-start subtitle-1 py-1">
            <v-col cols="12" md="4" class="font-weight-bold">Дата:</v-col>
            <v-col cols="12" md="8" class=" d-flex">{{displayDate(item.date)}}</v-col>
          </v-row>
        </v-row>
      </v-card>
      </v-col>
    </div>

    <v-toolbar dense class="my-2" color="style-color-main-gray" flat >
      <v-toolbar-title>
        Прикрепленные работы
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn text icon @click="change=true">
        <v-icon
          medium
        >
          add
        </v-icon>
      </v-btn>
    </v-toolbar>

    <div  v-if="detail.work.length > 0">
      <v-card-text class="text-left  subtitle-1 " v-for="item in detail.work" >
        <span v-if=" item.accept === null">Работа еще не проверялась</span>
        <span v-else-if=" item.accept === true">Работа проверена и принята</span>
        <span v-else  >Работа проверена и не принята</span>
        <router-link  :to="{name: names.StudentEducationDetailEducationWorks, params: {idWorks: item.id}}">
          ({{item.type}})
        </router-link>
      </v-card-text>
    </div>

    <v-toolbar class="mb-2" color="style-color-main-gray" flat  v-if="detail.tolerance.length > 0">
      <v-toolbar-title>
        Допуски
      </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <div v-for="item in detail.tolerance">
      <v-card-text class="text-left  subtitle-1 ">
      <router-link  :to="{name: names.StudentEducationAdmissionDetail, params: {idAdmission: item.id}}">
        {{item.subject}} {{item.semester}} семестр {{item.course}} курс
      </router-link>
       </v-card-text>
    </div>
    <Work v-if="change"
          @close="form_close()">
    </Work>
<!--    <DebugJsonComponent :json="detail" ></DebugJsonComponent>-->
  </v-container>

</template>

<script>
import DebugJsonComponent from "@/modules/core/components/DebugJsonComponent";
import Work from "@/modules/student/forms/Work";
import urls from "@/urls/student"
import {loadData} from "@/helper";
import moment from "moment";
import {loadFile} from "@/helper/fileLoader"
import names from "@/modules/student/routers/names"

export default {
name: "DetailSubject",
  components: {
    DebugJsonComponent,
    Work
  },
  computed: {
    names: () => names
  },
  data: function () {
    return {
      title: 'Учебный план обучающегося',
      detail: null,
      change:false
    }
  },
  methods:{
    getItems(){
      let url = urls.STUDENT.CURRICULUM.SEMESTER_DETAIL(this.$route.params.curriculum_id, this.$route.params.idSubject)
      loadData(
        url,
        (data) => {
          this.detail = data;
        },
        (error) => {
        });
    },
    form_close(){
      this.change =false
      this.getItems()
    },
    displayDate: function (date, minutes = false) {
      let format = 'DD.MM.YYYY'
      if (minutes)
        format += ' HH:mm'
      return moment(date).format(format)
    },
    loadFileWork(url){
      loadFile(url)
    },
  },
  created() {
    this.getItems()
  }
}
</script>

<style scoped>

</style>
