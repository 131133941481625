<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="8" offset-md="2">
        <v-row>
          <v-col cols="12" md="6" xl="6" v-for="(item, index) in getLeftSubs()" :key="`menu_card_${index}`">
            <v-card class="elevation-10 ma-3" >
              <router-link :to="{name: getName(item)}">
                <div
                  class="blue lighten-4 black--text item_in_list">{{ item.title }}</div>
              </router-link>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import names from "@/modules/educationalManagement/routers/names";

  export default {
    name: 'Reference',
    methods:{
      getLeftSubs(){

          return [
            {title: 'Гос. стандарты', icon: 'view_quilt', router: names.EDUCATIONAL.REFERENCE.STATE_STANDARDS},//'EducationManagementStateStandards'},
            {title: 'Компетенции', icon: 'view_quilt', router: names.EDUCATIONAL.REFERENCE.COMPETENCES},//'EducationManagementCompetence'},
            {title: 'Рейтинговые системы', icon: 'view_quilt', router: names.EDUCATIONAL.REFERENCE.RATING_SYSTEMS},//'EducationManagementRatingSystem'},
            {title: 'Направления подготовки', icon: 'view_quilt', router: names.EDUCATIONAL.REFERENCE.TRAINING_DIRECTIONS},//'EducationManagementTrainingDirection'},
            {title: 'Профили подготовки', icon: 'view_quilt', router: names.EDUCATIONAL.REFERENCE.TRAINING_PROFILES},//'EducationManagementTrainingProfile'},
          ]
      },
      getName(el){

        if (typeof el.router === 'object')
          return el.router.name
        else
          return el.router
      }
    },
    data() {
      return {

      }
    },

  }
</script>

<style scoped>
.item_in_list{
  height: 200px;
  text-align: center;
  line-height: 200px;
  font-size: 24px;
  user-select: none;
  text-decoration: underline !important;
}
</style>
