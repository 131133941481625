<template>
  <v-container>
    <v-row>
      <v-col cols="10" offset="1">
        <v-row>
          <v-col cols="12" md="6" xl="4" v-for="(item, index) in massRouter" :key="`cathedra_card_${index}`">
            <v-card class="elevation-10 ma-3" >
              <router-link :to="{name: item.name }">
                <div
                  class="blue lighten-4 black--text administrative" >{{ item.title }}</div>
              </router-link>
                 </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>

</template>

<script>
    export default {
        name: "Administrative",
      data: function () {
        return {
          page_title: 'Кафедры',
          width: '300px',
          height: '300px',
          massRouter: [{ name: 'AdministrativeListCourses',title:'Курсы'},
            ],
        }
      },
    }
</script>

<style scoped>
  .administrative{
    height: 200px;
    text-align: center;
    line-height: 200px;
    font-size: 28px;
    user-select: none;
    text-decoration: underline !important;
  }
</style>
