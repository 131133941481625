<template>
  <v-dialog persistent v-model="opened" width="500">
    <v-card>
      <v-card-title>Подтвердите изменение</v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5">
        <span v-if="getText !== undefined" v-html="getText()"></span>
        <slot v-else></slot>
      </v-card-text>
      <v-card-actions>
        <v-flex>
          <v-btn
            @click="applyDialog"
            color="warning"
            text
            :disabled="disableApply"
          >
            {{applyText}}
          </v-btn>
          <v-btn
            @click="closeDialog"
            text
          >
            {{closeText}}
          </v-btn>
        </v-flex>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: "ApplyChangeReadonlyBoolean",
    props: {
      applyText:{
        type: String,
        default: 'Подтвердить',
      },
      closeText:{
        type: String,
        default: 'Отменить',
      },
      getText: {
        type: Function,
        default: undefined,
      },
      opened: Boolean,
      disableApply: {
        type: Boolean,
        default: false,
      },
    },
    data: function () {
      return {
        // opened: true
      }
    },
    methods: {
      closeDialog: function () {
        this.$emit('close', false)
      },
      applyDialog: function () {
        this.$emit('close', true)
      }

    }
  }
</script>

<style scoped>

</style>
