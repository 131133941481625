import references from "./references";
import faces from "./faces";
import educations from "./educations";
import curriculums from "./curriculum";
import groups from "./groups"

export default {
  namespaced: true,
  modules: {
    references,
    faces,
    educations,
    groups,
    curriculums
  }
}
