import Profile from '@/modules/student/pages/Profile'
import LoadPortal from '@/modules/core/pages/LoadPortal'
import ListEducationWorks from '../pages/EducationWorker/ListEducationWorks'
import Education from '../pages/Education'
import Admissions from '../pages/Admission/ListAdmissions'
import Curriculum from '../pages/Curriculum/Curriculum'
import DetailSubject from '../pages/Curriculum/DetailSubject'
import ProgressJournal from '../pages/Journal/ProgressJournal'
import Group from '../pages/Group'
import PortfolioList from '@/modules/student/pages/Portfolio/Portfolio'
import CertificateList from '@/modules/student/pages/Certificate/List'
import ReferenceIFNS from "@/modules/student/pages/SingleDean/ReferenceIFNS";
import ReferencePXTY from "@/modules/student/pages/SingleDean/ReferencePXTY";
import ReferenceIncome from "@/modules/student/pages/SingleDean/ReferenceIncome";
import ReferenceAnother from "@/modules/student/pages/SingleDean/ReferenceAnother";
import Applications from "@/modules/student/pages/SingleDean/Applications";
import EduDoc from "@/modules/student/pages/educationDocument/EduDoc";
import DetailEducationWorks from '../pages/EducationWorker/DetailEducationWorks'
import DetailAdmission from '../pages/Admission/DetailAdmission'
import names from "./names"
import AdmissionsRequest from '../pages/AdmissionRequest/ListAdmissions'
import DetailAdmissionRequest from '../pages/AdmissionRequest/DetailAdmission'
import Student from "../../dean/pages/departament/list/teacher/Student";

const accounts = String(process.env.VUE_APP_ACCOUNTS_PREFIX).replace('/', '');

const education = String(process.env.VUE_APP_EDUCATION_PREFIX).replace('/', '');
export default ([

  {
    path: '/exit',
    name: names.LoadPortal,
    component: LoadPortal,
    meta: {
      requiredAuth: true,
      breadcrumb: 'Выход',
      instances: [
        accounts,
        education,
      ],
    },

  },

  {
    path: '/student',
    name: names.StudentProfile,
    component: Profile,
    meta: {
      requiredAuth: true,
      breadcrumb: 'Обучающийся',
      instances: [
        accounts,
        education,
      ],
    },
    children: [
      {
        path: ':idStudent([0-9]+)',
        name: names.StudentEducation,
        component: Education,
        meta: {
          // requiredAuth: true,
          breadcrumb: 'Образование',
          requiredAuth: true,
          instances: [
            accounts,
            education,
          ],
        },
        children: [
          {
            path: 'curriculum',
            name: names.StudentEducationCurriculum,
            component: Curriculum,
            meta: {
              breadcrumb: 'Индивидуальный учебный план',
              requiredAuth: true,
              instances: [
                accounts,
                education,
              ],
            },
            children: [
              {
                path: ':curriculum_id([0-9]+)/:idSubject([0-9]+)/subject',
                name: names.StudentEducationCurriculumDetailSubject,
                component: DetailSubject,
                meta: {
                  breadcrumb: 'Предмет',
                  requiredAuth: true,
                  instances: [
                    accounts,
                    education,
                  ],
                },

              },
            ]
          },
          {
            path: 'group',
            name: names.StudentEducationGroup,
            component: Group,
            meta: {
              breadcrumb: 'Группа',
              requiredAuth: true,
              instances: [
                accounts,
                education,
              ],
            },
          },
          {
            path: 'works',
            name: names.StudentEducationListEducationWorks,
            component: ListEducationWorks,
            meta: {
              breadcrumb: 'Список учебных работ',
              requiredAuth: true,
              instances: [
                accounts,
                education,
              ],
            },
            children: [
              {
                path: ':idWorks([0-9]+)',
                name: names.StudentEducationDetailEducationWorks,
                component: DetailEducationWorks,
                meta: {
                  breadcrumb: 'Учебная работа подробно',
                  requiredAuth: true,
                  instances: [
                    accounts,
                    education,
                  ],
                },
              },
            ]
          },
          {
            path: 'portfolio',
            name: names.StudentEducationPortfolio,
            component: PortfolioList,
            meta: {
              breadcrumb: 'Список достижений',
              requiredAuth: true,
              instances: [
                accounts,
                education,
              ],
            },
          },
          {
            path: 'edu/doc',
            name: names.StudentEducationListEduDoc,
            component: EduDoc,
            meta: {
              breadcrumb: 'Список документов об образовании',
              requiredAuth: true,
              instances: [
                accounts,
                education,
              ],
            },
          },
          {
            path: 'journal/progress',
            name: names.StudentEducationProgressJournal,
            component: ProgressJournal,
            meta: {
              breadcrumb: 'Журнал успеваемости',
              requiredAuth: true,
              instances: [
                accounts,
                education,
              ],
            },
          },
          {
            path: 'admissions',
            name: names.StudentEducationAdmission,
            component: Admissions,
            meta: {
              breadcrumb: 'Допуски',
              requiredAuth: true,
              instances: [
                accounts,
                education,
              ],
            },
            children:[
              {
                path: ':idAdmission([0-9]+)',
                name: names.StudentEducationAdmissionDetail,
                component: DetailAdmission,
                meta: {
                  breadcrumb: 'Допуск',
                  requiredAuth: true,
                  instances: [
                    accounts,
                    education,
                  ],
                },
              },
            ]
          },
          {
            path: 'certificate',
            name: names.StudentEducationCertificate,
            component: CertificateList,
            meta: {
              breadcrumb: 'Список справок',
              requiredAuth: true,
              instances: [
                accounts,
                education,
              ],
            },
          },
          {
            path: 'admissions_request',
            name: names.StudentEducationAdmissionRequest,
            component: AdmissionsRequest,
            meta: {
              breadcrumb: 'Допуски',
              requiredAuth: true,
              instances: [
                accounts,
                education,
              ],
            },
            children:[
              {
                path: ':idAdmission([0-9]+)',
                name: names.StudentEducationAdmissionRequestDetail,
                component: DetailAdmissionRequest,
                meta: {
                  breadcrumb: 'Допуск',
                  requiredAuth: true,
                  instances: [
                    accounts,
                    education,
                  ],
                },
              },
            ]
          },
          {
            path: 'single/application',
            name: names.StudentEducationSingleDeanApplication,
            component: Applications,
            meta: {
              breadcrumb: 'Подача заявлений',
              requiredAuth: true,
              instances: [
                accounts,
                education,
              ],
            },
          },
          {
            path: 'single/reference/ifns',
            name: names.StudentEducationSingleDeanReferencesIFNS,
            component: ReferenceIFNS,
            meta: {
              breadcrumb: 'Получение справки в ИФНС',
              requiredAuth: true,
              instances: [
                accounts,
                education,
              ],
            },
          },
          {
            path: 'single/reference/income',
            name: names.StudentEducationSingleDeanReferencesIncome,
            component: ReferenceIncome,
            meta: {
              breadcrumb: 'Получение справок о доходах',
              requiredAuth: true,
              instances: [
                accounts,
                education,
              ],
            },
          },
          {
            path: 'single/reference/another',
            name: names.StudentEducationSingleDeanReferencesAnother,
            component: ReferenceAnother,
            meta: {
              breadcrumb: 'Получение прочей справки',
              requiredAuth: true,
              instances: [
                accounts,
                education,
              ],
            },
          },
          {
            path: 'single/reference/pxty',
            name: names.StudentEducationSingleDeanReferencesPXTY,
            component: ReferencePXTY,
            meta: {
              breadcrumb: 'Получение справки об обучении в РХТУ',
              requiredAuth: true,
              instances: [
                accounts,
                education,
              ],
            },
          },

        ]
      },
    ]
  },
])


