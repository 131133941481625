<template>
  <v-sheet>
    <v-card class="ma-4">
      <v-toolbar dense elevation="0" color="style-color-main-gray">
        <v-toolbar-title>
          Информация
        </v-toolbar-title>
      </v-toolbar>
      <v-container fluid class="text-start px-4">
        <single-dean-top-template></single-dean-top-template>
        <v-row class="mb-5">
          <v-col cols="12">
            Для получения справки следуйте инструкции, приведенной ниже:
          </v-col>
          <v-col cols="12">
            <v-timeline dense>
              <v-timeline-item icon="post_add" color="style-color-blue-light" icon-color="black" right>
                <v-card style="vertical-align: center" elevation="2">
                  <v-container>
                    <v-row align-content="center">
                      <v-col>Заполнить заявку в электронном виде(форма на данной странице)</v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-timeline-item>
              <v-timeline-item icon="schedule" color="style-color-blue-light" icon-color="black" right>
                <v-card style="vertical-align: center" elevation="2">
                  <v-container>
                    <v-row align-content="center">
                      <v-col>
                        Дождаться уведомления от Единого деканата о сроках готовности и получения документов.
                        При возникновении вопросов при составлении справок, работник Единого деканата с Вами свяжется.
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-timeline-item>
              <v-timeline-item icon="get_app" color="style-color-blue-light" icon-color="black" right>
                <v-card style="vertical-align: center" elevation="2">
                  <v-container>
                    <v-row align-content="center">
                      <v-col>Получить запрашиваемые документы в Едином деканате лично или на указанную Вами почту</v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-timeline-item>
            </v-timeline>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-card class="ma-4">
      <v-toolbar dense elevation="0" color="style-color-main-gray">
        <v-toolbar-title>
          Форма заказа справки
        </v-toolbar-title>
      </v-toolbar>
      <v-container class="text-start">
        <v-form ref="form">
          <v-col offset="1">
          <v-row dense>
            <v-col cols="10">
              <v-select
                label="Вид справки"
                :rules="[rules.required]"
                v-model="value.type"
                :items="selector.type"
              >
                <template v-slot:label>
                  Тип справки<span class="red--text"><strong>* </strong></span>
                </template>
                <template v-slot:item="data">
                  <template v-if="typeof data.item !== 'object'">
                    <v-list-item-content v-text="data.item"></v-list-item-content>
                  </template>
                  <template v-else>
                    <div class="text-left" style="-moz-hyphens: auto; -webkit-hyphens: auto; -ms-hyphens: auto;">
                      {{ data.item.text }}
                    </div>
                  </template>
                </template>
              </v-select>
            </v-col>
            <v-col cols="10">
              <!--              <v-btn @click="value.path = null">cl</v-btn>-->
              <v-radio-group label="Способ получения*"
                             :error="hasError('path')"
                             :error-messages="getError('path')"
                             required v-model="value.path" :rules="[rules.required]"  >
                <v-radio :value="1" label="На почту в электронном виде"  v-if="value.type === 22"></v-radio>
                <v-radio :value="2" label="Лично в бумажном виде"></v-radio>
                <v-radio :value="3" label="Почтой России"></v-radio>
                <template v-slot:label>
                  Способ получения <span class="red--text"><strong>* </strong></span>
                </template>
              </v-radio-group>
            </v-col>
            <v-col cols="10">
              <v-row>
                <v-text-field class="px-2" label="Телефон для связи" v-model="value.phone" :rules="[rules.phone,]"></v-text-field>
              </v-row>
              <v-row v-if="value.path === 3">
                <v-text-field class="px-1" label="Получатель письма" v-model="value.letter_recipient" :rules="[]"></v-text-field>
                <v-text-field class="px-1" label="Адрес получателя" v-model="value.address" :rules="[]"></v-text-field>
              </v-row>
            </v-col>
            <v-col cols="10">
              <v-alert outlined color="info" v-if="value.type === 21">
                <span>
                  Справка о размере стипендии на предоставления субсидии делается на срок
                  6 месяцев и предоставляется только в бумажном виде.<br/>
                  <a target="_blank" href="https://muctr.ru/upload/iblock/c41/c412c8175e03e6f1bde19651e8fbc53d.pdf">Пример справки</a>
                </span>
              </v-alert>
              <v-textarea v-if="value.type === 22" v-model="value.description"
              label="Требования к запрашиваемой информации"
                          :error="hasError('description')"
                          :error-messages="getError('description')"
              >
              </v-textarea>
            </v-col>
            <v-col cols="10">
              <v-checkbox
                label="Обучение на контрактной основе"
                v-model="formated.finance"
                @change="value.financing = formated.finance ? 2: 1"
              ></v-checkbox>
            </v-col>
            <v-col cols="10">
              <v-alert outlined color="info">
                <v-checkbox v-model="value.apply" :rules="[rules.apply_required]">
                  <template v-slot:label>
                    <span>
                      Согласен на обработку моих персональных данных,
                      в соответствии с Федеральным законом
                      от 27.07.2006 года №152-ФЗ «О персональных данных»,
                      на условиях и для целей, определенных в
                      <a href="" target="_blank">Согласии на обработку персональных данных</a>
                    </span>
                  </template>
                </v-checkbox>
              </v-alert>
            </v-col>
          </v-row>
          </v-col>
        </v-form>
        <v-row>
          <v-col cols="10" offset="1"  v-if="!loaded">
            <v-btn width="100%" color="success" @click="sendRequest" :loading="loading" :disabled="loading">Отправить</v-btn>
          </v-col>
          <v-col cols="10" offset="1"  v-else>
            <v-alert outlined color="success">
              Ваша заявка отправлена.
            </v-alert>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-sheet>
</template>

<script>
import TitledPageMixin from "@/mixins/TitledPageMixin";
import FormErrorsMixin from "@/mixins/FormErrorsMixin";
import SingleDeanTopTemplate from "@/modules/student/pages/SingleDean/SingleDeanTopTemplate";
import {makeGetRequest, makePostRequestFile, sendPostRequest} from "@/helper";
import {mapGetters} from "vuex";
import {getUrlWithParameters} from "@/helper/urls";

export default {
  name: "Reference",
  components: {
    SingleDeanTopTemplate,
  },
  mixins: [
    TitledPageMixin,
    FormErrorsMixin
  ],
  data: function (){
    return {
      page_title: 'Заказ справок для получения в едином деканате',
      rules: {
        required: value => !!value || 'Поле обязательно для заполнения.',
        phone: value => RegExp(/((\+7)|(8))[0-9]{10}/g).test(value) || value === '' ||
          'Телефоный номер должен начинаться с 8 или +7 и содержать 10 цифр',
          apply_required: value => value || 'Подать запрос электронно без согласия на обработку персональных данных невозможно.'
      },
      selector: {
        type: [
          {value: 21, text: 'Справка о размере стипендии на предоставление субсидии(6 месяцев)'},
          {value: 22, text: 'Прочие справки'},
        ],
      },
      formated: {
        finance: false,
      },
      value: {
        type: null,
        description: null,
        financing: 1,
        phone: '',
        letter_recipient: '',
        address: '',
        path: null,
        apply: false,
        education: this.$route.params.idStudent
      },
      loading: false,
      loaded: false
    }
  },
  computed: {
    send_url(){
      return getUrlWithParameters(
        process.env.VUE_APP_API_HOST + 'feedback',
        'feedback',
        'singledean|send-request-reference-bookkeeping',
        'post',
        {},
        {}
      )
    }
  },
  methods: {
    // sendRequest() {
    //   if(this.$refs['form'].validate()){
    //     alert('Yea')
    //   }
    // },
    sendRequest(){
      if(this.$refs.form.validate()){
        this.loading = true
        if(this.value.type === 21) {
          this.value.path = 2;
          this.value.description = this.selector.type[0].text
        }
        sendPostRequest(
          this.send_url,
          this.value,
          () => {
            this.loading = false;
            this.loaded = true;
          },
          (errors) => {
            this.mapErrors(errors);
            this.loading = false;
          }
        )
      }
    }
  },
  created() {
    if(!this.$store.getters['urls/checkAlreadyLoaded']('feedback')){
      makeGetRequest(process.env.VUE_APP_INSTANCE_ALLOWED_URLS + '?instances=feedback')
        .then(resp => resp.json())
        .then(data => {
          this.$store.commit('urls/appendUrls', data);
        });
    }
  }
}
</script>

<style scoped>

</style>
