<template>
  <scroll-center-dialog
    v-model="inValue" @close="inValue = false" @apply="execute({education_id: item.eios.id})"
    :loading="loading || fields.loading">
    <template v-slot:title>
      <slot name="title">Принудительное обновление данных объекта ЭИОС</slot>
    </template>
    <slot></slot>
    <v-card-text class="text-left subtitle-1">
      Выберете поля для принудительного обновления:
      <v-skeleton-loader type="list-item" v-if="fields.loading"></v-skeleton-loader>
      <v-skeleton-loader type="list-item" v-if="fields.loading"></v-skeleton-loader>
      <v-skeleton-loader type="list-item" v-if="fields.loading"></v-skeleton-loader>
      <v-checkbox
        v-for="(el, i) in fields.items"
        :key="`field_selector_key_${i}`"
        :label="el.text"
        :value="el.value"
        hide-details
        v-model="fields.value"
      ></v-checkbox>
    </v-card-text>
  </scroll-center-dialog>
</template>

<script>
import ScrollCenterDialog from "@/modules/core/components/ScrollCenterDialog.vue";
import FormErrorsMixin from "@/mixins/FormErrorsMixin";
import ActionExecutroMixin from "@/modules/administrative/mixins/ActionExecutroMixin";
import {sendGetRequest} from "@/helper";
import admin_urls from "@/urls/admin";

export default {
  name: "UpdateRelated",
  components: {ScrollCenterDialog},
  mixins: [FormErrorsMixin, ActionExecutroMixin],
  props: {},
  data() {
    return {
      fields: {
        items: [],
        value: [],
        loading: false,
      }
    }
  },
  methods: {
    clearState(){
      this.errors = {}
      this.fields.value = []
      this.fields.items = []
    },
    onOpenPreAction() {
      this.loadSelector()
    },
    loadSelector(){
      this.fields.loading = true
      sendGetRequest(
        this.actionUrl(this.item.eios.id),
        (data) => {
          // this.emitReloadAfterAction()
          if(admin_urls.ADMIN.COURSE.IS_LIST_ALLOWED()) {
            data.splice(data.findIndex(el => el.value === 'university_identifier'), 1)
          }
          this.fields.items = data
          this.fields.loading = false
        }
      )
    },
    getActionArgs(kwargs){
      return {
        fields: this.fields.value
      }
    },
  },
  created() {
  }
}
</script>

<style scoped>

</style>
