<template>
  <AdmissionTemplate :admission="admission" :issued_by="true">
    <template v-slot:append_body>
      <v-card-actions class=" d-flex justify-end">
        <v-btn v-if="!(admission.closed && !admission.success)" text class="primary" @click="opened_confirm = true">Отменить допуск</v-btn>
        <scroll-center-dialog v-model="opened_confirm"
                              width="500"
                              @close="opened_confirm = false"
                              title="Отменить допуск"
                              :applyable="false"
                              :dennyable="true"
        >
          <v-alert class="pa-2" type="info" outlined>
            Отмененный допуск не возможно восстановить
          </v-alert>
          <template v-slot:actions>
            <v-btn class="primary" @click="closeAdmission()">Отменить допуск</v-btn>
            <v-spacer></v-spacer>
            <v-btn class="primary" @click="opened_confirm = false">Закрыть</v-btn>
          </template>
        </scroll-center-dialog>
      </v-card-actions>
    </template>
  </AdmissionTemplate>

</template>

<script>
import moment from 'moment'
import AdmissionTemplate from '@/modules/templates/admission/AdmissionTemplate.vue'
import {makeDeleteRequest, makeGetRequest} from "@/helper";
import urls from "@/urls/dean"
import {generateUidToName} from "@/helper/uidToName";
import Admission from "@/modules/dean/pages/student/form/Admission";
import UserDataMixin from "@/mixins/UserDataMixin";
import UserDataFormMixin from "@/mixins/UserDataFormMixin";
import names from "@/modules/dean/routers/names";
import ScrollCenterDialog from "@/modules/core/components/ScrollCenterDialog";

export default {
  name: "DetailAdmission",
  components: {
    ScrollCenterDialog,
    AdmissionTemplate,
    Admission
  },
  mixins: [UserDataMixin,UserDataFormMixin],
  data: function () {
    return {
      admission: undefined,
      URL_DETAIL: urls.DEAN.STUDENTS.ADMISSION.DETAIL(
        this.$route.params.idFaculty,
        this.$route.params.idStudent,
        this.$route.params.idAdmission,
      )
    }
  },
  methods: {

    loadData( ){
      let url = new URL(this.URL_DETAIL)
      let params = {};
      url.search = new URLSearchParams(params).toString();

      let sys = true;
      makeGetRequest(url).then(resp => {
        if (!resp.ok) {
          this.$router.push({name: names.STUDENT.ADMISSIONS.LIST})
        }
        return resp.json()
      }).then(json => {
        this.admission = json
        this.$set(this.admission, "fio_teacher", "Не указан")
        generateUidToName(this.admission, "teacher", "fio_teacher")

        this.$set(this.admission, "fio_student", "Не указан")
        generateUidToName(this.admission, "student", "fio_student")

        this.$set(this.admission, "fio_issued_by", "Не указан")
        generateUidToName(this.admission, "issued_by", "fio_issued_by")
      })
    },

    closeAdmission() {
      let url = new URL(this.URL_DETAIL)
      makeDeleteRequest(url).then(resp => {
        if (resp.ok) {
          this.$router.push({name: names.STUDENT.ADMISSIONS.LIST})
        }
      })

    }

  },
  created() {
    this.loadData()
  }
}
</script>

<style scoped>

</style>
