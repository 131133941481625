import {
  // sendPostRequest,
  // sendDeleteRequest,
  sendGetRequest,
  // sendPutRequest
} from "@/helper";
import urls from "@/urls/admin";

const state = {
  items: [],
  count: 0,
  next: null,
  prev: null,
  detail: null,
  id: null,
  loading: false,
  currentUrl: false,
  error: null,
};

const getters = {
  getEducationItems: state => state.items,
  getEducationCount: state => state.count,
  getEducationCurrentUrl: state => state.currentUrl,
  getEducationDetail: state => state.detail,
  isEducationLoading: state => state.loading,
  getEducationError: state => state.error,

};

const actions = {
  loadEducationList: ({commit, getters}, {url, urlAppend, finalizer=()=>{}, catcher=()=>{}}) => {
    if (!url) {
      url = urls.ADMIN.EDUCATION.LIST()
      if (urlAppend) url = urlAppend(url)
    }
    if (!getters.isEducationLoading) {
      commit('SET_EDUCATION_LOADING');
      sendGetRequest(
        url,
        (data, url) => {
          commit('SET_EDUCATION', data['results']);
          commit('SET_EDUCATION_COUNT', data['count']);
          commit('SET_EDUCATION_CURRENT_URL', url);
          commit('SET_EDUCATION_LOADING_END');
          finalizer(data, url);
        },
        (data, url) => {
          commit('SET_EDUCATION_LOADING_END');
          commit('SAVE_EDUCATION_ERROR', data);
          catcher(data, url);
        }
      )
    }
  },
  loadEducationDetail: ({commit}, {url, id, finalizer=()=>{}, catcher=()=>{}}) => {
    if (!url) url = urls.ADMIN.EDUCATION.DETAIL(id)
    sendGetRequest(
      url, (data, url) => {
        commit('SET_EDUCATION_DETAIL', data);
        finalizer(data, url);
      }, (data, url) => {
        commit('SAVE_EDUCATION_ERROR', data);
        catcher(data, url);
      }
    )
  },
  // createCars: ({dispatch, commit, getters}, {data, finalizer=()=>{}, catcher=()=>{}}) => {
  //   let url = urls.ADMIN.EDUCATION.CREATE()
  //   executeRequest('Cars', sendPostRequest, {url, data, finalizer, catcher}, {dispatch, commit, getters});
  // },
  // changeCars: ({dispatch, commit, getters}, {data, finalizer,catcher}) => {
  //   let url = urls.ADMIN.EDUCATION.UPDATE(data.id)
  //   executeRequest('Cars', sendPutRequest, {url, data, finalizer, catcher}, {dispatch, commit, getters});
  // },
  // deleteCars: ({dispatch, commit, getters}, {id, finalizer, catcher}) => {
  //   let url = urls.ADMIN.EDUCATION.DELETE(id)
  //   executeRequest('Cars', sendDeleteRequest, {url, finalizer, catcher}, {dispatch, commit, getters});
  // },

};

const mutations = {
  SET_EDUCATION: (state, cars) => {
    state.items = cars
  },
  SET_EDUCATION_DETAIL: (state, value) => {
    state.detail = value
  },
  SET_EDUCATION_COUNT: (state, value) => {
    state.count = value
  },
  SET_EDUCATION_CURRENT_URL: (state, value) => {
    state.currentUrl = value
  },
  SET_EDUCATION_LOADING: (state) => {
    state.loading = true
  },
  SET_EDUCATION_LOADING_END: (state) => {
    state.loading = false
  },
  SAVE_EDUCATION_ERROR: (state, error) => {
    state.error = error
  }

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
