import dean_names from "@/modules/dean/routers/names";
import store from "@/store";
import helper from "@/urls/helper"
import names from "@/modules/dean/routers/names";



export function getInstMenuDean(uidFaculty, nameFaculty) {
  const education = String(process.env.VUE_APP_EDUCATION_PREFIX).replace('/', '');
  let menu =[
    {title: nameFaculty, icon: 'view_quilt', router: dean_names.FACULTIES},
    {
      title: 'Список кафедр',
      icon: 'view_agenda',
      included: [],
      router: {name: dean_names.DEPARTAMENTS.LIST, params: {idFaculty: uidFaculty}}
    },
    {
      title: 'Исходящие переводы',
      icon: 'redo',
      included: [],
        // ['idDetail'],
      router: {name: dean_names.TRANSFER.LIST_FROM, params: {idFaculty: uidFaculty}}
    },
    {
      title: 'Входящие переводы',
      icon: 'undo',
      included: [],
        // ['idDetail'],
      router: {name: dean_names.TRANSFER.LIST_TO, params: {idFaculty: uidFaculty}}
    },
    {
      title: 'Список групп',
      icon: 'group',
      included: [],
      router: {name: dean_names.GROUP.LIST, params: {idFaculty: uidFaculty}}
    },
    {
      title: 'Список обучающихся',
      icon: 'groups',
      included: [],
      router: {name: dean_names.STUDENT.LIST, params: {idFaculty: uidFaculty}}
    },
    {
      title: 'Список допусков',
      icon: 'menu',
      included: [],
      router: {name: dean_names.ADMISSIONS.LIST, params: {idFaculty: uidFaculty}}
    },
    {
      title: 'Заказы допусков',
      icon: 'grading',
      included: [],
      router: {name: dean_names.ADMISSIONS_REQUEST.LIST, params: {idFaculty: uidFaculty}}
    },
    {
      title: 'Выдача справок',
      icon: 'description',
      included: [],
      router: {name: dean_names.CERTIFICATE.LIST, params: {idFaculty: uidFaculty}}
    },
    {
      title: 'Список учебных планов',
      icon: 'list',
      included: [],
      router: {name: dean_names.CURRICULUM.LIST, params: {idFaculty: uidFaculty}}
    },

  ]

  helper.checkEduUrlAllowedWithParameters('dean|relations|workers-list', 'list', {'faculty_uid': uidFaculty})
    .then(results => {
      let allowed = true;
      results.forEach(el => allowed = allowed && el.allowed)
      if(allowed){
        store.commit('leftmenu/appendItem', {
          title: 'Список прав',
          icon: 'admin_panel_settings',
          router: {name: dean_names.PERMISSION.LIST, params: {idFaculty: uidFaculty}}
        })
      }
    })
  // if (store.getters['urls/getAllowed']( education, 'dean|relations|workers-list', 'list'))
  //   menu.push(  {
  //     title: 'Список прав',
  //     icon: 'admin_panel_settings',
  //     router: {name: dean_names.PERMISSION.LIST, params: {idFaculty: uidFaculty}}
  //   },)


  return menu
}

export function getInstStudentMenuDean(uidStudent, uidFaculty, back=undefined){
  return  [
    {
      title: 'Назад',
      icon: 'undo',
      router: back || {
        name: dean_names.STUDENT.LIST,
        params: {idFaculty: uidFaculty, reload_left_menu: true},
        // meta: {}
      }
    },
    {
      title: 'Обучающийся',
      icon: 'account_circle',
      router: {name: dean_names.STUDENT.DETAIL, params: {idStudent: uidStudent,idFaculty: uidFaculty}}
    },
    {
      title: 'Перс. уч. план',
      icon: 'analytics',
      router: {name: dean_names.STUDENT.CURCULUM, params: {idStudent: uidStudent,idFaculty: uidFaculty}}
    },
    {
      title: 'Полный журнал',
      icon: 'dashboard',
      router: {name: dean_names.STUDENT.JOURNAL, params: {idStudent: uidStudent,idFaculty: uidFaculty}}
    },
    {
      title: 'Список допусков',
      icon: 'article',
      router: {name: dean_names.STUDENT.ADMISSIONS.LIST, params: {idStudent: uidStudent,idFaculty: uidFaculty}}
    },
    {
      title: 'Пройденные предметы',
      icon: 'book',
      router: {name: dean_names.STUDENT.FINISHSUBJECT, params: {idStudent: uidStudent,idFaculty: uidFaculty}}
    },
    {
      title: 'Учебные работы',
      icon: 'description',
      router: {name: dean_names.STUDENT.ACADEMICWORKS.LIST, params: {idStudent: uidStudent,idFaculty: uidFaculty}}
    },
    {
      title: 'Портфолио',
      icon: 'file_present',
      router: {name: dean_names.STUDENT.PORTFOLIO, params: {idStudent: uidStudent,idFaculty: uidFaculty}}
    },
    {
      title: 'Документы об образовании',
      icon: 'cases',
      router: {name: dean_names.STUDENT.EDUDOC, params: {idStudent: uidStudent,idFaculty: uidFaculty}}
    },
  ]
}
// back=undefined
export function getInsDepartmentMenu(uidFaculty, uidCathedra, cathedraName){
  return [
    {
      title: 'Назад', icon: 'undo',
      router: {
        name: names.DEPARTAMENTS.LIST,
        params: {
          idFaculty: uidFaculty,
          reload_left_menu: true
        }
      }
    },
    {
      title: cathedraName, icon: 'view_quilt',
      router: {
        name: names.DEPARTAMENTS.DETAIL,
        params:{
          idFaculty: uidFaculty,
          uidDepartament: uidCathedra
        }
      }
    },
    {
      title: 'Преподаваемые дисциплины', included: [], icon: 'subject',
      router: {
        name: names.DEPARTAMENTS.LED_SUBJECTS.LIST,
        params:{
          idFaculty: uidFaculty,
          uidDepartament: uidCathedra
        }
      }
    },
    {
      title: 'Обучаемые группы', included: [], icon: 'group',
      router: {
        name: names.DEPARTAMENTS.GROUP.LED.LIST,
        params:{
          idFaculty: uidFaculty,
          uidDepartament: uidCathedra
        }
      }
    },
    {
      title: 'Ведомые обучающиеся', included: [], icon: 'groups',
      router: {
        name: names.DEPARTAMENTS.STUDENTS.LED,
        params: {
          idFaculty: uidFaculty,
          uidDepartament: uidCathedra
        }
      }
    },
    {
      title: 'Группы кафедры', included: [], icon: 'people_alt',
      router: {
        name: names.DEPARTAMENTS.GROUP.LIST,
        params: {
          idFaculty: uidFaculty,
          uidDepartament: uidCathedra
        }
      }
    },
    {
      title: 'Обучающиеся кафедры', included: [], icon: 'people',
      router: {
        name: names.DEPARTAMENTS.STUDENTS.LIST,
        params: {
          idFaculty: uidFaculty,
          uidDepartament: uidCathedra
        }
      }
    },
    {
      title: 'Преподаватели', included: [], icon: 'school',
      router: {
        name: names.DEPARTAMENTS.TEACHER.LIST,
        params: {
          idFaculty: uidFaculty,
          uidDepartament: uidCathedra
        }
      }
    },
  ]
}
