<template>
  <v-container style="height: 100%" v-if="$route.name === names.STUDENT.LIST">
    <v-card flat>
        <v-toolbar
          elevation="0"
          color="style-color-main-gray"
        >
          <v-text-field
            dense
            placeholder="Поиск"
            v-model="search.value"
            @keypress.enter="searchData"
            @click:clear="clearSearchData"
            clearable
            messages="Для поиска нажмите Enter"
          >
          </v-text-field>
          <v-spacer></v-spacer>
          <v-select class="pt-1" :items="selectors.is_individual" v-model="pagination.filters.is_individual"
          ></v-select>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                v-on="on"
                @click="getItems()"
                class="mr-2"
                medium
              >
                <v-icon>
                  update
                </v-icon>
              </v-btn>
            </template>
            <span>Обновить данные</span>
          </v-tooltip>
        </v-toolbar>
        <v-data-table
          :headers="headers"
          :loading="pagination.loading"
          :page.sync="pagination.page"
          :items-per-page.sync="pagination.size"
          :server-items-length="pagination.count"
          :options.sync="options"
          multi-sort
          :footer-props="{'items-per-page-options':[10,25,50]}"
          :items="items"
        >
          <template v-slot:item.fio="{ item }">
            <router-link  :to="{ name: names.STUDENT.DETAIL,params:{idStudent:item.id}}">
              <v-card-text v-if="item.fio !== '-'">{{item.fio}}</v-card-text>
              <v-skeleton-loader v-else type="list-item" class="mx-auto"></v-skeleton-loader>
            </router-link>
          </template>
          <template v-slot:item.education_group.litter="{ item }">
            <span>{{item.education_group.litter}}-{{item.education_group.course}}{{item.education_group.number}}</span>
          </template>
          <template v-slot:item.active="{ item }">
            <v-icon color="teal" v-if="item.active">done</v-icon>
            <v-icon v-else>clear</v-icon>
          </template>

          <template slot="no-data">
            <v-card-text>Данные не загружены</v-card-text>
          </template>
        </v-data-table>
    </v-card>

  </v-container>
  <router-view :key="$route.fullPath" v-else></router-view>
</template>


<script>
  import UserDataMixin from '@/mixins/UserDataMixin'
  import {loadData, makeGetRequest} from "@/helper";
  import urls from "@/urls/dean"
  import {generateListUidToName} from "@/helper/uidToName";
  import names from "@/modules/dean/routers/names";
  import TitledPageMixin from "../../../../mixins/TitledPageMixin";
  import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";

  export default {
    name: "Groups",
    // components: {Courses},
    mixins: [UserDataMixin, TitledPageMixin, PaginatedDataMapperMixin,],
    props: {
    },
    data () {
      return {
        URL_LIST: urls.DEAN.STUDENTS.LIST(this.$route.params.idFaculty),
        names:names,
        page_title: 'Список обучающихся',
        items: [],
        search: {
          parameter: 'fio',
        },
        pagination: {
          filters: {
            is_individual: ''
          },
        },
        selectors:{
          is_individual:[
            {text:'Все обучающиеся',value: ''},
            {text:'С индивидуальным уч. планом',value: 'true'},
            {text:'Без индивидуального уч. плана',value: 'false'},
          ],
        },
        headers: [
          {text: 'Обучающийся', sortable: false, align: 'left',value: 'fio'},
          {text: 'Программа', sortable: false, align: 'left',value: 'level'},
          {text: 'Номер зачетной книжки', value: 'record_book_number', sortable: false, align: 'start', width: '10%'},
          {text: 'Форма', sortable: false, align: 'left', value: 'form'},
          {text: 'Год поступления', sortable: false, align: 'left', value: 'entry_year'},
          {text: 'Группа', sortable: false, align: 'left', value: 'education_group.litter'},
          {text: 'Кафедра', sortable: false, align: 'left', value: 'cathedra'},
          {text: 'Обучается', sortable: false, align: 'left', value: 'active'},
        ]
      }
    },
    created() {
      if(this.$route.name === names.STUDENT.LIST){
        this.setPageTitleWithObject("Faculty", {uid: this.$route.params.idFaculty}, ' - ')
      }
      this.getItems()
    },
    watch: {
    },
    methods: {
      getDetail(item){
        this.$router.push({name: names.STUDENT.DETAIL, params: {idStudent: item.id}})
      },
      addItem(){
        this.closedForm()
        this.getItems();
      },

      getItems (url = undefined) {
        this.loadData(this.getCurrentLoadUrl());
        this.setCurrentLoadUrl(url, true);
      },
      loadData(url = undefined) {
        this.setLoading();
        if(!url) {
          url = this.addFiltersParameter(this.URL_LIST);
          this.setCurrentLoadUrl(url);
        }
        loadData(
          url,
          (data) => {
            this.mapResults(data, 'items')
            for (let i in this.items) {
              this.$set(this.items[i], "fio","-")
            }
            generateListUidToName(this.items, 'student', 'fio')
            this.setNotLoading()
          }
        )
      },
    }
  }


</script>
<style scoped>

</style>
