
const Administrative = `Administrative`;
const CathedraList =`${Administrative}|Cathedra`;
const CathedraDetail = `${CathedraList}|Detail`;
const CathedraAdmins = `${CathedraList}|Admins`;

const CathedraListSubjects = `${CathedraList}|Subjects`;
const CathedraSubjectsDetail = `${CathedraListSubjects}|Detail`;

const CathedraGroups = `${CathedraList}|Groups`;
const CathedraGroupsDetail = `${CathedraGroups}|Detail`;
const CathedraGroupsDetailJournal = `${CathedraGroupsDetail}|Journal`;

const CathedraAdmission = `${CathedraList}|Admission`;
const CathedraAdmissionDetail = `${CathedraAdmission}|Detail`;

const CathedraTeachers = `${CathedraList}|Teachers`;
const CathedraTeachersDetail = `${CathedraTeachers}|Detail`;
const CathedraTeachersDetailGroups = `${CathedraTeachersDetail}|Groups`;
const CathedraTeachersDetailStudents = `${CathedraTeachersDetail}|Students`;
const CathedraTeachersDetailSubjects = `${CathedraTeachersDetail}|Subjects`;
const CathedraTeachersDetailDiploms = `${CathedraTeachersDetail}|Diploms`;
const CathedraTeachersPortfolio = `${CathedraTeachersDetail}|Portfolio`;
const CathedraTeachersDetailWorks = `${CathedraTeachersDetail}|Works`;
const CathedraTeachersDetailWorksDetail = `${CathedraTeachersDetailWorks}|Detail`;

const CathedraStudents = `${CathedraList}|Students`;
const CathedraStudentsDetail = `${CathedraStudents}|Detail`;
const CathedraStudentsDetailWorks = `${CathedraStudentsDetail}|Works`;
const CathedraStudentsDetailWorksDetail = `${CathedraStudentsDetailWorks}|Detail`;
const CathedraStudentsDetailThesis = `${CathedraStudentsDetail}|Thesis`;
const CathedraStudentsDetailJournal = `${CathedraStudentsDetail}|Journal`;
const CathedraStudentsDetailCurriculum = `${CathedraStudentsDetail}|Curriculum`;
const CathedraStudentsPortfolio= `${CathedraStudentsDetail}|Portfolio`;
const CathedraStudentsDocEdu = `${CathedraStudentsDetail}|DocEdu`;

const CathedraIndividualStudents = `${CathedraList}|Individual_Students`;
const CathedraIndividualStudentsJournal = `${CathedraIndividualStudents}|Journal`

const CathedraTaught = `${CathedraList}|Taught`;
const CathedraTaughtGroups = `${CathedraTaught}|Groups`;
const CathedraTaughtGroupsDetail = `${CathedraTaughtGroups}|Detail`;
const CathedraTaughtSubjects = `${CathedraTaught}|Subjects`;
const CathedraTaughtStudents = `${CathedraTaught}|Students`;

const CathedraArchive = `${CathedraList}|Archive`;
const CathedraArchiveGroups = `${CathedraArchive}|Groups`;
const CathedraArchiveGroupsDetail = `${CathedraArchiveGroups}|Detail`;
const CathedraArchiveGroupsDetailJournal = `${CathedraArchiveGroupsDetail}|Journal`;

const CathedraCurriculum = `${CathedraList}|Curriculum`
const CathedraCurriculumList = `${CathedraCurriculum}|List`
const CathedraCurriculumDetail = `${CathedraCurriculum}|Detail`

export default {
  CathedraList,
  CathedraDetail,
  CathedraAdmins,

  CathedraGroups,
  CathedraGroupsDetail,
  CathedraGroupsDetailJournal,

  CathedraTeachers,
  CathedraTeachersDetail,
  CathedraTeachersDetailGroups,
  CathedraTeachersDetailDiploms,
  CathedraTeachersDetailStudents,
  CathedraTeachersDetailSubjects,
  CathedraTeachersDetailWorks,
  CathedraTeachersDetailWorksDetail,
  CathedraTeachersPortfolio,

  CathedraStudents,
  CathedraStudentsDetail,
  CathedraStudentsDetailWorks,
  CathedraStudentsDetailWorksDetail,
  CathedraStudentsDetailThesis,
  CathedraStudentsDetailJournal,
  CathedraStudentsDetailCurriculum,
  CathedraStudentsPortfolio,
  CathedraStudentsDocEdu,

  CathedraIndividualStudents,
  CathedraIndividualStudentsJournal,

  CathedraAdmission,
  CathedraAdmissionDetail,

  CathedraTaughtGroups,
  CathedraTaughtGroupsDetail,
  CathedraTaughtStudents,
  CathedraTaughtSubjects,

  CathedraListSubjects,
  CathedraSubjectsDetail,

  CathedraArchiveGroups,
  CathedraArchiveGroupsDetail,
  CathedraArchiveGroupsDetailJournal,

  CathedraCurriculumList,
  CathedraCurriculumDetail
}
