var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.is_self_route)?_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-card',[_c('v-toolbar',{attrs:{"elevation":"0","color":"style-color-main-gray"}},[_c('v-text-field',{attrs:{"dense":"","placeholder":"Поиск","messages":"Для поиска нажмите Enter или кнопку поиск"},on:{"change":_vm.searchData},model:{value:(_vm.search.value),callback:function ($$v) {_vm.$set(_vm.search, "value", $$v)},expression:"search.value"}}),_c('v-toolbar-items',[_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v("search")])],1)],1)],1),_c('v-data-iterator',{class:`${_vm.groups.length!==0 ? 'd-flex flex-row flex-wrap':''}`,attrs:{"items":_vm.groups,"items-per-page":_vm.pagination.size,"no-data-text":"Кафедральные группы не найдены","no-results-text":"Кафедральные группы не найдены","page":_vm.pagination.page,"loading":_vm.pagination.loading,"server-items-length":_vm.pagination.count,"hide-default-footer":""},on:{"update:itemsPerPage":function($event){return _vm.$set(_vm.pagination, "size", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.pagination, "size", $event)},"update:page":function($event){return _vm.$set(_vm.pagination, "page", $event)}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-card-text',{staticClass:"text-xs-center"},[_vm._v(" Кафедральные группы не найдены ")])]},proxy:true},{key:"item",fn:function(group){return [_c('v-col',{attrs:{"cols":"12","md":"4","lg":"3"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-card',{staticClass:"ma-3",attrs:{"elevation":hover ? 8 : 2,"height":"200px"}},[_c('router-link',{attrs:{"to":{
                      name: _vm.names.CathedraGroupsDetail,
                      params: {
                        cathedra_uid: _vm.cathedra_uid,
                        group_id: group.item.id
                      }
                    }}},[_c('div',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"cathedra_item_in_list",class:[_vm.color, _vm.text_color]},[_vm._v(" "+_vm._s(group.item.litter)+"-"+_vm._s(group.item.course)+_vm._s(group.item.number)+" ")])]),_c('small',{staticStyle:{"position":"relative","top":"-75px"}},[_vm._v("Численность группы - "+_vm._s(group.item.students_count)+" обучающихся")])],1)]}}],null,true)})],1)]}},{key:"footer",fn:function({ pagination, options, updateOptions }){return [_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-footer',{staticClass:"mt-auto",attrs:{"options":options,"pagination":pagination,"items-per-page-options":[5,10,50]},on:{"update:options":updateOptions}})],1)]}}],null,false,3851854432)})],1)],1)],1)],1):_c('router-view')
}
var staticRenderFns = []

export { render, staticRenderFns }