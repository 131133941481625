<template>
  <v-card>
    <v-toolbar class="mb-2" color="style-color-main-gray" flat>
      <v-toolbar-title>
        <slot name="title">
          Список семестров учебного плана
        </slot>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <template v-slot:extension v-if="$vuetify.breakpoint.mobile && show_extra_actions">
        <v-toolbar-items @click.stop="" class="mx-auto">
          <slot name="extra_actions"></slot>
        </v-toolbar-items>
      </template>
      <v-toolbar-items @click.stop="" v-if="!$vuetify.breakpoint.mobile && show_extra_actions">
        <slot name="extra_actions"></slot>
      </v-toolbar-items>
    </v-toolbar>
    <slot name="prepend_semesters"></slot>
    <v-card-title class="text-left py-2 grey lighten-2" v-if="searchable">
      <v-spacer></v-spacer>
      <v-col cols="8" sm="4"><v-text-field v-model="search" append-icon="search" dense hide-details ></v-text-field></v-col>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="semesters"
        :search="search"
        :page="page"
        @update:page="$emit('update:page', $event)"
        @update:items-per-page="$emit('update:items-per-page', $event)"
        :server-items-length="count"
        :footer-props="footerprops"
      >
        <template v-slot:item.cathedrals="props">
          <td class="text-left" v-if="props.item.cathedrals">
            <div v-for="(str,index) in props.item.cathedrals" class="align-start">
              {{str.name}}<span v-if="index!==props.item.cathedrals.length-1">;</span> <br>
            </div>
          </td>
          <td class="text-left" v-else>
            {{props.item.cathedra}}
          </td>
        </template>
      </v-data-table>
    </v-card-text>
    <slot name="append_semesters"></slot>
  </v-card>
</template>

<script>

export default {
  name: "CurriculumSubjectsTemplate",
  components: {},
  computed: {},
  props: {
    show_extra_actions: {
      type: Boolean,
      default: true,
    },
    semesters: {
      required: true,
      type: Array,
    },
    page: {
      // required: true,
      type: Number,
    },
    count: {
      // required: true,
      type: Number,
      default: undefined,
    },

    searchable: {
      type: Boolean,
      default: true,
    },
    append_headers: {
      default: () => {
        return [
          {text: 'Рейтинг', value: 'rating', width: '10%', filterable: false,}
        ]
      }
    },
    footerprops: {
      default: () => {
        return [
          {'items-per-page-options':[10,25,50, -1], 'items-per-page-text': 'Число элементов'}
        ]
      }
    }
  },
  data() {
    return {
      headers: [
        {text: 'Предмет', value: 'subject', sortable: false, width: '30%', filterable: true,},
        {text: 'Кафедра', value: 'cathedrals', sortable: false, width: '30%', filterable: true,},
        {text: 'Курс', value: 'course', sortable: false, width: '10%', filterable: false,},
        {text: 'Семестр', value: 'semester', sortable: false, width: '10%', filterable: false,},
        {text: 'Форма контроля', value: 'control_type', sortable: false, width: '10%', filterable: false,},
        ...this.append_headers
        // {text: 'Рейтинг', value: 'rating', width: '10%', filterable: false,}
      ],
      search: '',
    }
  },
  methods: {}
}
</script>

<style scoped>

</style>
