<template>
  <scroll-center-dialog
    v-model="inValue"
    @close="inValue = false"
    @apply="execute({education_id: objectId})"
    :loading="loading"
    :disabled="!apply && applyable"
    applyText="Выполнить"
  >
    <template v-slot:title>
      <slot name="title">{{actionName}}</slot>
    </template>
    <v-alert type="info" outlined>
      Нажав на кнопку «Выполнить», вы запустите процесс "{{actionName}}".
    </v-alert>
    <v-card-title class="text-left">
      <span style="word-break: break-word">Процесс затронет объект "{{ objectName }}"({{ typeName }})
      <span v-if="one" style="display: contents"> [1C]</span></span></v-card-title>
    <v-card-text>
      <v-alert type="warning" outlined>
        Невозможно отменить это действие! Чтобы изменить данные, необходимо повторно связать данный объект.
        <v-switch v-if="applyable" v-model="apply" label="Я уверен и понимаю"></v-switch>
      </v-alert>
    </v-card-text>
    <debug-json-component :json="errors" v-if="hasErrors()" :status="errors.raw || 'Ошибка'" hidable hide-at-open></debug-json-component>
    <slot></slot>
  </scroll-center-dialog>
</template>

<script>
import ScrollCenterDialog from "@/modules/core/components/ScrollCenterDialog.vue";
import FormErrorsMixin from "@/mixins/FormErrorsMixin";
import ActionExecutroMixin from "@/modules/administrative/mixins/ActionExecutroMixin";
import DebugJsonComponent from "@/modules/core/components/DebugJsonComponent.vue";


export default {
  name: "ActionForObject",
  components: {DebugJsonComponent, ScrollCenterDialog},
  mixins: [FormErrorsMixin, ActionExecutroMixin],
  props: {
    item: {
      required: true,
    },
    actionName: {
      required: true
    },
    objectName: {
      required: true,
      type: String,
    },
    typeName: {
      required: true,
      type: String,
    },
    objectId: {
      required: true,
      type: String
    },
    applyable: {
      default: false,
      type: Boolean
    },
    one: {
      default: false,
      type: Boolean
    },
  },
  data() {
    return {
      apply: false
    }
  },
  computed: {
  },
  methods: {
    getActionArgs(kwargs){
      if (this.applyable)
        return {
          apply: this.apply
        }
      else
        return {}
    },
    getEducationId(){
      return this.objectId
    },
  },
  created() {
  }
}
</script>

<style scoped>

</style>
