<template>
  <v-container v-if="journal.loaded">
    <v-toolbar :extended='$vuetify.breakpoint.xsOnly'
                class="mb-2"
                color="style-color-main-gray"
                flat
               :style="{'height':`${$vuetify.breakpoint.smAndUp ? 64: 150}px`} "
    >
      <v-toolbar-title>
        Журнал обучающегося
      </v-toolbar-title>
      <template v-if="$vuetify.breakpoint.smAndUp" >
        <v-toolbar-title>
          <v-select :items="journal.data" item-text="name" return-object v-model="journal.selected" class="ml-3" hide-details dense style="min-width: 280px;" filled>
          </v-select>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-title>
          <v-text-field v-model="search" clearable placeholder="Поиск" hide-details style="min-width: 100px;" prepend-inner-icon="search"  dense hint="" filled></v-text-field>
        </v-toolbar-title>
      </template>
      <template v-slot:extension v-if='$vuetify.breakpoint.xsOnly'>
        <v-col>
          <v-toolbar-title>
            <v-select :items="journal.data" item-text="name" return-object v-model="journal.selected" class="pt-6 pb-2" hide-details dense style="min-width: 280px;" filled>
            </v-select>
          </v-toolbar-title>
          <v-toolbar-title>
            <v-text-field v-model="search" clearable placeholder="Поиск" hide-details style="min-width: 100px;" prepend-inner-icon="search"  dense hint="" filled></v-text-field>
          </v-toolbar-title>
        </v-col>
      </template>
    </v-toolbar>
    <v-row>
      <v-col>
        <JournalTableTemplate
          :rows="journal.selected.subjects"
          first_row_label="Предмет"
          first_row_value="subject_name"
          :can_update="false"
          :search="search"
          :loading="journal.loading"
          :errors="[]"
          :show_marks = true
        ></JournalTableTemplate>
      </v-col>
    </v-row>
<!--    <DebugJsonComponent :json="journal"></DebugJsonComponent>-->
  </v-container>
  <v-container v-else>
    <v-row>
      <v-col>
        <v-skeleton-loader
          height="50"
          type="list-item"
        >
        </v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-skeleton-loader
          height="200"
          type="card"
        >
        </v-skeleton-loader>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import urls from "@/urls/dean"
import {loadData} from "@/helper";
import JournalTableTemplate from "@/modules/templates/journal/JournalTableTemplate";
import DebugJsonComponent from "@/modules/core/components/DebugJsonComponent";
import TitledPageMixin from "@/mixins/TitledPageMixin";

export default {
  name: "FinalJournal",
  mixins: [TitledPageMixin],
  components: {
    DebugJsonComponent,
    JournalTableTemplate,
  },
  computed: {
    filteredBySemesterJournal() {
      return this.journal.filter(el => el.semester_name === this.semester_selected)
    }
  },
  data: function () {
    return {
      page_title: 'Итоговый журнал успеваемости',
      journal: {
        student_id: this.$route.params.student_id,
        data: [],
        selected: {subjects: []},
        loading: false,
        loaded: false,
      },
      search: '',
    }
  },
  methods: {
    getNumberSemestr(semester,course){
      let num =0
      if(semester === 'осенний'){
        num = course * 2 - 1
      }else {
        num = course * 2
      }
      return num
    },
    loadJournal() {
      this.journal.loading = true;
      loadData(
        new URL(urls.DEAN.STUDENTS.FULL_JOURNAL(this.$route.params.idFaculty, this.$route.params.idStudent)),
        (data) => {
          for(let course of data.courses){
            for(let semester of course.semesters){
              this.journal.data.push({
                name: `${course.number} курс, ${this.getNumberSemestr(semester.name,course.number)} (${semester.name}) семестр`,
                subjects: semester.subjects,
              })
            }
          }
          if(this.journal.data.length > 0)
            this.journal.selected = this.journal.data[0]
          this.journal.loaded = true;
          this.journal.loading = false;
        }
      )
    },
  },
  created() {
    this.setPageTitleWithObject("Education", {id: this.$route.params.idStudent}, ' - ', true)
    this.loadJournal();
  }
}
</script>

<style scoped>

</style>
