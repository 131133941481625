
<template>
  <v-container style="height: 100%" v-if=" !$route.name.includes('Journal') && !$route.name.includes('OptionallyGroup') && !$route.name.includes('StudentSemesterCathedras')">
    <ChangeCurriculumForm v-if="opened_change_cur_form"
                          @close="closedForm"
                          @refreshItem="refreshForm"
                          :curriculum="group.data.curriculum"
                          :level="group.data.level"
    >
    </ChangeCurriculumForm>
    <Group
      @ended="closedForm"
      @refreshItem="refreshForm"
      v-bind="{'id':this.$route.params.idGroup,
               'attachment':this.attachment
               ,'unvisible':true}"
      :opened_form="opened_form"
      v-if="opened_form"
    >
    </Group>

    <GroupChangeCathedra
      @ended="closedForm"
      @refreshItem="refreshForm"
      :id="$route.params.idGroup"
      :selected="{value: group.data.cathedra_uid, text: group.data.cathedra}"
      :unvisible="true"
      :opened_form="opened_change_cathedra_form"
      v-if="opened_change_cathedra_form"
    >
    </GroupChangeCathedra>

    <GroupChangeRecordBook
      v-if="opened_change_record"
      :id="$route.params.idGroup"
      :all_students="students.data"
      :opened_form="opened_change_record"
      @ended="closedForm"
      @refreshItem="refreshForm"
    >
    </GroupChangeRecordBook>

    <GroupChangeHeadman
      v-if="opened_change_headman"
      :id="$route.params.idGroup"
      :all_students="students.data"
      :unvisible="true"
      :opened_form="opened_change_headman"
      @ended="closedForm"
      @refreshItem="refreshForm"
    >
    </GroupChangeHeadman>

    <v-toolbar
      class="mb-2"
      color="style-color-main-gray"
      flat
      v-if="group.data.litter"
    >
      <v-toolbar-title >
        Группа: {{group.data.litter}}-{{ group.data.course }}{{group.data.number}}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items v-if="!$vuetify.breakpoint.mdAndDown && !group.data.curriculum.is_system">
        <v-btn text :to="getJournalLink(true)">
          <v-icon class="mr-1">ballot</v-icon>Журнал группы
        </v-btn>
        <v-btn v-if="attachment || group.data.attachment" text :to="getJournalLink(false)">
          <v-icon class="mr-1">ballot</v-icon>Полный журнал группы
        </v-btn>
        <v-menu v-if="attachment || group.data.attachment">
          <template v-slot:activator="{on, attrs}">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>
                more_vert
              </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-btn text width="100%"  @click="opened_form = true">редактировать</v-btn>
            <v-btn v-if="attachment || group.data.attachment" text width="100%"
                   :to="getStudentSemesterCathedralslLink()">Привязка обучающихся к кафедрам</v-btn>
            <v-btn v-if="attachment || group.data.attachment" text width="100%"
                   :to="getOptionallyGrouplLink()">Предметы по выбору</v-btn>
            <v-btn text width="100%"  @click="opened_change_cathedra_form = true">Сменить выпускающую кафедру</v-btn>
            <v-btn text width="100%"  @click="opened_change_record = true">Изменить номера зачетных книжек</v-btn>
          </v-list>
        </v-menu>
        <slot name="extra_actions"></slot>
      </v-toolbar-items>
      <v-menu v-else-if="!group.data.curriculum.is_system">
        <template v-slot:activator="{on, attrs}">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>
              more_vert
            </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-btn v-if="attachment || group.data.attachment" text width="100%"
                 :to="getStudentSemesterCathedralslLink()">Привязка обучающихся к кафедрам</v-btn>
          <v-btn v-if="attachment || group.data.attachment" text width="100%"
                 :to="getOptionallyGrouplLink()">Предметы по выбору</v-btn>
          <v-btn text width="100%"
                 :to="getJournalLink(true)" >Журнал</v-btn>
          <v-btn v-if="attachment || group.data.attachment" text width="100%"
                 :to="getJournalLink(false)" >Полный журнал группы</v-btn>
            <v-btn text width="100%" v-if="attachment || group.data.attachment" @click="opened_form = true">редактировать</v-btn>
            <v-btn text width="100%" v-if="attachment || group.data.attachment" @click="opened_change_cathedra_form = true">Сменить выпускающую кафедру</v-btn>
            <v-btn text width="100%" v-if="attachment || group.data.attachment" @click="opened_change_record = true">Изменить номера зачетных книжек</v-btn>
        </v-list>
      </v-menu>
    </v-toolbar>

    <v-row wrap>
      <v-col cols="12" wrap  v-if="group.data.curriculum">
        <v-alert
          outlined
          type="warning"
          border="left"
          v-if="group.data.curriculum.is_system"
        >
          <v-row wrap >
            <v-col cols="12" md="12">
              Уважаемые работники, обращаем ваше внимание, что данная группа привязана к пустому системному учебному плану,
              который не может быть отредактирован. Поэтому, для корректной работы с группой переведите ее на учебный план
              вашего факультета, после чего учебный план будет помечен как учебный план требующий синхронизации и в ближайший
              период синхронизации группа будет заполнена данными из учебного плана.
            </v-col>
            <v-col cols="12" md="12">
              <v-btn  class="mt-4" text @click="opened_change_cur_form = true">
                <v-icon>drive_file_rename_outline</v-icon> Замена учебного плана
              </v-btn>
            </v-col>
          </v-row>
        </v-alert>
      </v-col>
      <v-col cols="12" md="6">
        <GroupTemplate
          v-if="group.loaded"
          v-bind="group.data"
          :hides="{course: true}"
          :data="group.data"
          :appends="[{field: 'cathedra', title: 'Кафедра'}]"
        >
        </GroupTemplate>
      </v-col>
      <v-col cols="12" md="6">
        <GroupCurriculumTemplate
          v-if="group.loadingCurriculum"
          :direction="group.data.curriculum.direction"
          :profile="group.data.curriculum.profile.name"
          :year="group.data.curriculum.year"
          :cathedra="group.data.cathedra"
          :loaded="true"
        >
          <template v-if="attachment || group.data.attachment" v-slot:profile>
            <router-link :to="getDetailedCurriculum(group.data.curriculum.id)"   >
            <span v-if="group.data.curriculum.profile">{{ group.data.curriculum.profile.name }}</span>
            </router-link>
          </template>
          <template v-if="attachment || group.data.attachment" v-slot:append_title>
            <v-btn v-if="hasCurriculumEditorPermissions() && !group.data.curriculum.is_system" text @click="getCurriculumEditor(group.data.curriculum.id)">
              <v-icon>edit</v-icon> Редактировать
            </v-btn>
          </template>
        </GroupCurriculumTemplate>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <GroupSubjectsTemplate
          v-if="subjects.loaded"
          :load_half="true"
          :loaded="subjects.loaded"
          :semesters="subjects.data"
          :expandable="false"
          :headers="headers"
        >
          <template v-slot:title>
            Дисциплины в семестре
          </template>

        </GroupSubjectsTemplate>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <GroupStudentsTemplate
          v-if="students.loaded"
          :loaded="students.loaded"
          :students="students.data"
          :expandable="false"
          :attachment="attachment"
          :makeLink="makeLink"
        >
          <template v-slot:title>
            Обучающиеся
          </template>
          <template v-slot:extra_actions>
            <v-btn text v-if="attachment || group.data.attachment" @click="opened_change_headman = true">
              Назначить старосту
            </v-btn>
          </template>
        </GroupStudentsTemplate>
      </v-col>
    </v-row>
<!--    <DebugJsonComponent :json="$route.params"></DebugJsonComponent>-->
  </v-container>
  <router-view :key="$route.fullPath" v-else></router-view>
</template>

<script>
import TitledPageMixin from "@/mixins/TitledPageMixin";
import {loadData} from "@/helper";
import {generateListUidToName} from "@/helper/uidToName";
import Group from "@/modules/dean/pages/forms/Group.vue"
import DebugJsonComponent from "@/modules/core/components/DebugJsonComponent";
import GroupTemplate from "@/modules/templates/group/GroupTemplate";
import GroupCurriculumTemplate from "@/modules/templates/group/GroupCurriculumTemplate";
import GroupSubjectsTemplate from "@/modules/templates/group/GroupSubjectsTemplate";
import GroupStudentsTemplate from "@/modules/templates/group/GroupStudentsTemplate";
import urlsteacher from "@/urls/teacher"
import names_dean from "@/modules/dean/routers/names";
import names_editor from "@/modules/curriculumEditor/routers/names";
import LoadData from "@/modules/dean/mixins/LoadData";
import GroupChangeCathedra from "@/modules/dean/pages/forms/GroupChangeCathedra";
import GroupChangeHeadman from "@/modules/dean/pages/forms/GroupChangeHeadman";
import GroupChangeRecordBook from "@/modules/dean/pages/forms/GroupChangeRecordBook";
import dean from "@/urls/dean";
import {getUrlAllowed} from "@/helper/urls";
import ChangeCurriculumForm from "@/modules/dean/pages/forms/ChangeCurriculumForm"

export default {
  store: getUrlAllowed(),
  name: "GroupDetail",
  mixins: [TitledPageMixin,LoadData],
  components: {
    ChangeCurriculumForm,
    GroupChangeCathedra,
    GroupChangeRecordBook,
    Group,
    DebugJsonComponent,
    GroupChangeHeadman,
    GroupTemplate,
    GroupCurriculumTemplate,
    GroupSubjectsTemplate,
    GroupStudentsTemplate,
  },
  data() {
    return {
      names_dean:names_dean,
      page_title: 'Профиль группы',
      opened_form: false,
      opened_change_cur_form: false,
      opened_change_cathedra_form: false,
      opened_change_headman: false,
      opened_change_record: false,
      attachment: false,
      dialog: false,
      headers:[
        {text: 'Предмет', value: 'subject', sortable: false},
      ],
      group: {
        id: this.$route.params.group_id,
        data: {},
        loading: false,
        loaded: false,
        loadingCurriculum: false,
      },
      subjects: {
        data: [],
        loading: false,
        loaded: false,
      },
      students: {
        data: [],
        loading: false,
        loaded: false,
      }
    }
  },
  computed: {

  },
  methods: {
    getJournalLink(full){
      if(full){
        if(this.$route.params.uidDepartament)
          return { name: names_dean.DEPARTAMENTS.GROUP.LED.JOURNAL}
        else
          return { name: names_dean.GROUP.JOURNAL}
      }else{
        if(this.$route.params.uidDepartament)
          return { name: names_dean.DEPARTAMENTS.GROUP.LED.JOURNALSEMESTER}
        else
          return { name: names_dean.GROUP.JOURNALSEMESTER}
      }
    },
    getOptionallyGrouplLink(){
      return { name: names_dean.GROUP.OPTIONALLYGROUP}
    },
    getStudentSemesterCathedralslLink(){
      return { name: names_dean.GROUP.STUDENTSEMESTERCATHEDRAS}
    },
    hasCurriculumEditorPermissions() {
      return getUrlAllowed('education','editor|curriculum|curriculum-list', 'list')
    },
    getDetailedCurriculum(curriculum_id) {
      return {
        name: names_dean.CURRICULUM.DETAIL,
        params: {
          idFaculty: this.$route.params.idFaculty,
          idCurriculum: curriculum_id
        }
      }
    },
    getCurriculumEditor(curriculum_id) {
      this.$router.push({
        name: names_editor.CURRICULUM.DETAIL,
        params: {
          curriculum_id: curriculum_id,
        },
        query: {
          faculty: this.$route.params.idFaculty
        }
      })
    },
    Change(){

    },
    closedForm() {
      this.opened_form = false
      this.opened_change_cur_form = false
      this.opened_change_cathedra_form = false
      this.opened_change_headman = false
      this.opened_change_record = false
    },
    refreshForm(){
      this.closedForm();
      this.group.loaded = false;
      this.group.loadingCurriculum = false;
      this.subjects.loaded = false;
      this.students.loaded = false;
      this.loadGroup()
    },
    loadSubjects: function (url_object) {
      this.subjects.loading = true;
      let urlSemesters = urlsteacher.URLS.getByRecievedUrlData(url_object)
      loadData(
        urlSemesters,
        (data) => {
          this.subjects.data = data;
          this.subjects.loaded = true;
          this.subjects.loading = false;
        }
      );
    },
    loadStudents: function (url_object) {
      this.students.loading = true;
      loadData(
        urlsteacher.URLS.getByRecievedUrlData(url_object),
        (data) => {
          this.students.data = data;
          for (let el of this.students.data) this.$set(el, "fio","")
          generateListUidToName(
            this.students.data,
            "user",
            "fio",
            () => {
              this.students.loading = false;
              this.students.loaded = true;
              this.students.data.sort(function (a, b) {
                if (a.fio > b.fio) {
                  return 1;
                }
                if (a.fio < b.fio) {
                  return -1;
                }
                return 0;
              });
            }
          );
        },
      );
    },
    loadGroup: function () {
      let url = '';
      if (this.$route.name !== names_dean.DEPARTAMENTS.GROUP.LED.DETAIL) {
        url = dean.DEAN.GROUP.DETAIL(this.$route.params.idFaculty, this.$route.params.idGroup)
        this.attachment = true
      }else{
        url = dean.DEAN.CATHEDRA.GROUP.DETAIL(this.$route.params.uidDepartament, this.$route.params.idGroup)
      }
      loadData(
        url,
        (data) => {
          this.group.data = data;
          this.group.loaded = true;
          this.loadStudents(this.group.data.students);

          if (this.attachment){
            this.headers.push(
              {text: 'Кафедры', value: 'cathedrals', sortable: false, width: '20%'},
              {text: 'Самост часы', value: 'independent_hours', sortable: false, width: '5%'},
              {text: 'Аудиторные часы', value: 'independent_hours', sortable: false, width: '5%'},
              {text: 'ЗЕ', value: 'zets', sortable: false, width: '5%'})
            if (this.group.data.attachment) {
              this.loadSubjects(this.group.data.all_semesters);
            }else{
              this.loadSubjects(this.group.data.current_semesters);
            }
            let urlCurriculum = urlsteacher.URLS.getByRecievedUrlData(this.group.data.curriculum)
            let saverCurriculum = (data) => {
              this.group.data.curriculum = data;
              this.group.loadingCurriculum = true
            };
            loadData(urlCurriculum, saverCurriculum);
          }else{
            this.group.loadingCurriculum = true
            this.subjects.loading = false;
            this.subjects.loaded = true;
            this.loadSubjects(this.group.data.semesters);
          }

        }
      );
    },
    makeLink(student_id) {
      return {
        name: names_dean.STUDENT.DETAIL,
        params: {
          'idStudent': student_id
        }
      }
    }
  },
  created() {
    if (!this.$route.name.includes('Journal') && !this.$route.name.includes('OptionallyGroup') && !this.$route.name.includes('StudentSemesterCathedras'))
      this.loadGroup();
  }

}
</script>

<style scoped>

</style>
