<template>
  <v-container v-if="is_self_route">
    <DetailTemplate :teacher="teacher"  v-if="teacher"></DetailTemplate>
<!--    <DebugJsonComponent :json="teacher"></DebugJsonComponent>-->
  </v-container>
  <router-view v-else v-bind:teacher_fio="fio"></router-view>
</template>

<script>
  import DebugJsonComponent from "../../../core/components/DebugJsonComponent";
  import {loadData} from "../../../../helper";
  import cathedras from "../../../../urls/cathedras";
  import accounts from "../../../../urls/accounts";
  import organization from "../../../../urls/organization";
  import names from "../../routers/names";
  import LeftMenuChangerMixin from "../../../../mixins/LeftMenuChangerMixin";
  import {generateUidToName} from "../../../../helper/uidToName";
  import DetailTemplate from "./DetailTemplate";
  import user from "../../../../store/modules/user";

  export default {
    name: "Base",
    components: {
      DebugJsonComponent,
      DetailTemplate,
    },
    mixins: [LeftMenuChangerMixin],
    data: function(){
      return {
        teacher: undefined,
        fio: undefined,
        loaded: false,
      };
    },
    computed: {
      is_self_route: function () {return names.CathedraTeachersDetail === this.$route.name},
    },
    methods: {
      getLeftMenu() {
        let params = {
          cathedra_uid: this.$route.params.cathedra_uid,
          teacher_uid: this.$route.params.teacher_uid,
        };
        let menus = [
          {title: 'Преподаватель', icon: 'person', included: false, router: {
              name: names.CathedraTeachersDetail,
              params: params
            }
          },
          {title: 'Группы', icon: 'group',
            router: {
              name: names.CathedraTeachersDetailGroups,
              params: params
            }},
          {title: 'Обучающиеся', icon: 'groups',
            router: {
              name: names.CathedraTeachersDetailStudents,
              params: params
            }},
          {title: 'Дисциплины', icon: 'subject', router: {
              name: names.CathedraTeachersDetailSubjects,
              params: params
            }},
          {title: 'Дипломники', icon: 'menu_book', router: {
              name: names.CathedraTeachersDetailDiploms,
              params: params
            }},
          {title: 'Работы обучающихся', icon: 'description', router: {
              name: names.CathedraTeachersDetailWorks,
              params: params
            }},
          {title: 'Портфолио', icon: 'file_present', router: {
              name: names.CathedraTeachersPortfolio,
              params: params
            }},
        ];
        return [
          {
            title: 'К списку', icon: 'arrow_back_ios',
            included: false,
            router: {
              name: names.CathedraTeachers,
              params: {
                ...params,
                reload_left_menu: true,
              }
            }
          },
          ...menus
        ];
      },
      loadTeacherFio(){
        let teacher = {
          user: this.$route.params.teacher_uid,
          fio: '',
        }
        generateUidToName(teacher, 'user', 'fio', (data) => {
          this.fio = data.fio;
          // console.log(this.fio)
        });
      },
      loadDetail() {
        loadData(
          cathedras.CATHEDRA.TEACHERS.DETAIL(this.$route.params.cathedra_uid, this.$route.params.teacher_uid),
          (data) => {
            this.teacher = data;
            // console.log(cathedras.getByRecievedUrlData(this.teacher.scientific_student))
            // console.log(this.teacher.scientific_student)
            loadData(
              cathedras.getByRecievedUrlData(this.teacher.scientific_student),
              data => {
                this.teacher.scientific_student = data;
              }
            );
            this.$set(this.teacher, 'fio', '');
            generateUidToName(this.teacher, 'user', 'fio');
            this.loaded = true;
            loadData(
              accounts.PROFILE.DETAIL(this.teacher.user),
              (data) => {
                this.$set(this.teacher, 'profile', data);
              }
            )
            // console.log(this.$store.state.urls)
            // console.log(organization.WORKER.DETAIL(this.teacher.user))
            loadData(
              organization.WORKER.DETAIL(this.teacher.user),
              (data) => {
                this.$set(this.teacher, 'worker', data);
              }
            )
          }
        )
      },
    },
    updated(){
      if(this.is_self_route && this.teacher === undefined)
        this.loadDetail()
      else if(this.fio === undefined)
        this.loadTeacherFio()
      // console.log(this.fio === undefined)
    },
    created() {
      if(this.is_self_route && this.teacher === undefined)
        this.loadDetail()
      else
        this.loadTeacherFio()
    }
  }
</script>

<style scoped>

</style>
