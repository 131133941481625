<template>
  <v-card class="ma-4">
    <v-toolbar dense elevation="0" color="style-color-main-gray">
      <v-toolbar-title>
        Информация
      </v-toolbar-title>
    </v-toolbar>
    <v-container fluid class="text-start px-4">
      <single-dean-top-template></single-dean-top-template>
      <v-row class="mb-5">
        <v-col cols="12">
          Для получения справки следуйте инструкции, приведенной ниже:
        </v-col>
        <v-col cols="12">
          <v-timeline dense>
            <v-timeline-item icon="get_app" color="style-color-blue-light" icon-color="black" right>
              <v-card style="vertical-align: center" elevation="2">
                <v-container>
                  <v-row align-content="center">
                    <v-col><a href="https://muctr.ru/university/departments/ed/docs/">Скачать бланк заявления</a></v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-timeline-item>
            <v-timeline-item icon="post_add" color="style-color-blue-light" icon-color="black" right>
              <v-card style="vertical-align: center" elevation="2">
                <v-container>
                  <v-row align-content="center">
                    <v-col>
                      Заполнить его, приложить к заявлению копии необходимых документов и подать в Единый деканат
                      (131 кабинет) лично или по почте <a href="mailto:student@muctr.ru">student@muctr.ru</a>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-timeline-item>
            <v-timeline-item icon="schedule" color="style-color-blue-light" icon-color="black" right>
              <v-card style="vertical-align: center" elevation="2">
                <v-container>
                  <v-row align-content="center">
                    <v-col>
                      Дождаться уведомления от Единого деканата о сроках готовности и получения документов.
                      При возникновении вопросов при обработке заявления, работник Единого деканата с Вами свяжется.
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-timeline-item>
            <v-timeline-item icon="assignment_turned_in" color="style-color-blue-light" icon-color="black" right>
              <v-card style="vertical-align: center" elevation="2">
                <v-container>
                  <v-row align-content="center">
                    <v-col>
                      Получить запрашиваемые документы в Едином деканате (в случае запроса о выдаче).
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-timeline-item>
            <v-timeline-item icon="assignment_late" color="style-color-blue-light" icon-color="black" right>
              <v-card style="vertical-align: center" elevation="2">
                <v-container>
                  <v-row align-content="center">
                    <v-col>
                      При подаче заявлений на предоставление скидки на обучение, расторжении договора и возврате
                      денежных средств или переводе на бюджетную форму обучения – информация о предоставлении или
                      отказе в запросе будет направлена на адрес указанной в заявлении почты.
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-timeline-item>
          </v-timeline>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import TitledPageMixin from "@/mixins/TitledPageMixin";
import SingleDeanTopTemplate from "@/modules/student/pages/SingleDean/SingleDeanTopTemplate";

export default {
  name: "Applications",
  components: {
    SingleDeanTopTemplate
  },
  mixins: [
    TitledPageMixin
  ],
  data: function (){
    return {
      page_title: 'Подача заявления через единый деканат'
    }
  }
}
</script>

<style scoped>

</style>
