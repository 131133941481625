<template>
  <v-card class="mx-1" v-if="loaded" :elevation="elevation">
    <v-toolbar flat class="mx-0 px-0" :extended="$vuetify.breakpoint.mobile && show_extra_actions">
<!--      @click="expandableToggle"-->
      <v-toolbar-title class="font-weight-bold subtitle-1">
        <slot name="title">
          Дисциплины в семестре
        </slot>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <template v-slot:extension v-if="$vuetify.breakpoint.mobile && show_extra_actions">
        <v-toolbar-items @click.stop="" class="mx-auto">
          <slot name="extra_actions"></slot>
        </v-toolbar-items>
      </template>
      <v-toolbar-items @click.stop="" v-if="!$vuetify.breakpoint.mobile && show_extra_actions">
        <slot name="extra_actions"></slot>
      </v-toolbar-items>
      <v-btn v-if="expandable" icon @click.stop="expandableToggle">
        <v-icon v-if="expand_card">
          expand_less
        </v-icon>
        <v-icon v-else>
          expand_more
        </v-icon>
      </v-btn>
    </v-toolbar>
    <v-divider></v-divider>
    <v-expand-transition>
      <v-container fluid grid-list-md v-show="expand_card">
        <slot name="append"></slot>
        <v-row v-if="$vuetify.breakpoint.smAndDown">
          <v-spacer></v-spacer>
          <v-col cols="8" md="4">
            <v-text-field
              dense
              hide-details
              label="Поиск"
              v-model="search"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-data-table
          :headers="headers"
          :expanded="expanded"
          :items="semesters"
          :items-per-page="semesters.length"
          :search="search"
          sort-by="name"
          hide-default-footer
          :show-expand="expandable"
          group-by="control_type"
          group-desc

        >
          <template v-for="(_, slot) of table_slots" v-slot:[slot]="scope"><slot :name="slot" v-bind="scope"/></template>
          <template v-slot:group.header="{group, headers, isOpen, toggle}">
            <td :colspan="headers.length" class="text-start">
              <v-row justify="space-between">
                <v-col class="ma-0 py-0 pl-10 d-flex" cols="auto">
                  <span class="my-auto subtitle-1">Форма контроля: {{ group }}</span>
                </v-col>
                <v-spacer></v-spacer>
                <v-col class="ma-0 py-0 pr-10" cols="auto">
                  <v-btn @click="toggle" icon>
                    <v-icon v-if="isOpen">keyboard_arrow_up</v-icon>
                    <v-icon v-else>keyboard_arrow_down</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </td>
          </template>
          <template v-slot:header.subject="props" v-if="$vuetify.breakpoint.mdAndUp">
            <v-text-field
              class="header-title my-auto"
              dense
              hide-details
              :label="props.header.text"
              v-model="search"
              height="24"
            >
              <template v-slot:label>
                <div class="header-title" style="margin-top: -2px;">{{ props.header.text }}</div>
              </template>
            </v-text-field>
          </template>
          <template v-slot:item.subject="props" v-if="load_half">
            <div>
              <v-tooltip bottom v-if="props.item.mottled">
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    class="ma-0"
                    medium
                    :style="Rotate(props.item.half)"
                  >
                    hourglass_bottom
                  </v-icon>
                </template>
                <span>{{props.item.half}} половина семестра</span>
              </v-tooltip>
              {{props.item.subject}}
            </div>
          </template>
          <template v-slot:item.cathedrals="props">
            <td class="text-left">
              <div v-for="(str,index) in props.item.cathedrals" class="align-start">
                {{str.name}}<span v-if="index!==props.item.cathedrals.length-1">;</span> <br>
              </div>
            </td>
          </template>
          <template v-slot:expanded-item="props" v-if="expandable">
            <slot name="expanded-item" v-bind="props">
              <v-expand-transition>
                <td :colspan="props.headers.length" class="mx-0 pa-1">
                  <v-card dense tile elevation="1" class="py-0" v-if="props.item.lesson_kinds !== undefined">
                    <v-subheader>Список форм занятий</v-subheader>
                    <v-divider></v-divider>
                    <v-data-table
                      :headers="[
                        {text: 'Форма', value: 'kind'},
                        {text: 'Часов в класе', value: 'classroom_hours'},
                        {text: 'Часов самоподготовки', value: 'independent_hours'},
                        {text: 'ЗЕТ', value: 'zets'}
                      ]"
                      hide-default-header
                      hide-default-footer
                      :items="props.item.lesson_kinds"
                      no-data-text="Формы занятий не определены"
                    ></v-data-table>
                  </v-card>
                  <v-card dense tile elevation="1" class="py-0">
                    <v-subheader>Список преподавателей</v-subheader>
                    <v-divider></v-divider>
                    <v-data-table
                      :headers="[{text: 'Фио', value: 'fio'}, {text: 'Ведомые виды занятий', value: 'type'}]"
                      :hide-default-footer="props.item.teachers.length < 5"
                      no-data-text="Не назначено ни одного преподавателя"
                      :items-per-page="5"
                      :items="props.item.teachers"
                    ></v-data-table>
                  </v-card>
                </td>
              </v-expand-transition>
            </slot>
          </template>
        </v-data-table>
      </v-container>
    </v-expand-transition>
<!--    <div v-for="(_, name) in $slots">{{name}}, {{_}}</div>-->
  </v-card>
</template>

<script>
  export default {
    name: "Subjects",
    props: {
      elevation: {
        type: Number,
        default: 2,
      },
      load_half: {
        type: Boolean,
        default: false,
      },
      show_extra_actions: {
        type: Boolean,
        default: true,
      },
      semesters: {
        requirement: true,
        type: Array,
      },
      headers: {
        requirement: false,
        type: Array,
        default: function () {
          return [
            {text: 'Предмет', value: 'subject', sortable: false, width: '35%'},
            {text: 'Кафедры', value: 'cathedrals', sortable: false, width: '35%'},
            {text: 'Общее число часов', value: "hours", sortable: false, width: '5%'},
            {text: 'Часов экзаменнационных', value: "exam_hours" , sortable: false, width: '5%'},
            {text: 'Аудиторных часов', value: 'classroom_hours', sortable: false, width: '5%'},
            {text: 'Часов самоподготовки', value: 'independent_hours', sortable: false, width: '5%'},
            {text: 'Зеты', value: 'zets', sortable: false, width: '5%'},
            {text: '', value: 'data-table-expand', width: '5%'},
          ];
        }
      },
      loaded: {
        requirement: true,
        type: Boolean,
      },
      expandable: {
        requirement: false,
        type: Boolean,
        default: true,
      },
      expand_default: {
        requirement: false,
        type: Boolean,
        default: true,
      }
    },
    data: function() {
      return {
        expanded: [],
        expand_card: this.expand_default,
        search: '',
      }
    },
    computed: {
      table_slots() {
        let slots = {};
        for(let slot of Object.keys(this.$scopedSlots)){
          if(this.headers.findIndex((el) => `item.${el.value}` === slot) !== -1)
            slots[slot] = this.$scopedSlots[slot];
        }
        return slots
      }
    },
    methods: {
      expandableToggle() {
        if (this.expandable){
          this.expand_card = !this.expand_card;
        }
      },
      Rotate(half){
        let sign = ''
        if(half === 'вторая')
          sign = '-'
        return 'transform: rotate('+ sign + '90deg)'
      }
    }
  }
</script>

<style scoped>
  .header-title{
    color: rgba(0,0,0,.6);
    font-size: .75rem;
  }
</style>
