<template>
  <v-container v-if="loaded">
    <v-card class="mb-4">
      <v-toolbar
        class="mb-2"
        color="style-color-main-gray"
        flat
      >
        <v-toolbar-title>
          По дисциплине
        </v-toolbar-title>
        <v-toolbar-title>
          <v-autocomplete
            :items="journal.data"
            item-text="subject.name"
            v-model="journal.selected"
            class="ml-3"
            hide-details
            dense
            style="min-width: 280px;"
            filled
            return-object
          >
            <template v-slot:item="props">
              <div class="text-start">
                {{props.item.subject.name}}
              </div>
            </template>
          </v-autocomplete>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-title>
          <v-text-field
            v-model="search"
            clearable
            placeholder="Поиск"
            hide-details
            style="min-width: 100px;"
            prepend-inner-icon="search"
            dense
            hint=""
            filled
          ></v-text-field>
        </v-toolbar-title>
        <v-toolbar-items>
        </v-toolbar-items>
      </v-toolbar>
      <v-row>
        <v-col>
          <JournalTableTemplate
            v-if="journal.selected.students_loaded"
            :rows="journal.selected.students"
            :can_update="false"
            :search="search"
            :save_execute="false"
            :loading="!journal.selected.fio_loaded"
            :errors="[]"
          ></JournalTableTemplate>
          <v-skeleton-loader
            v-else
            type="table"
          >
          </v-skeleton-loader>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
  <v-container v-else>
    <v-row>
      <v-col>
        <v-skeleton-loader
          height="50"
          type="list-item"
        >
        </v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-skeleton-loader
          height="200"
          type="card"
        >
        </v-skeleton-loader>
      </v-col>
    </v-row>
  </v-container>
  <v-container v-else>
    <v-card>
      <v-toolbar
        class="mb-2"
        dark
        color="grey darken-4"
        flat
      >
        <v-toolbar-title>
          Нет данных по дисциплинам текущего семестра
        </v-toolbar-title>
      </v-toolbar>
    </v-card>
  </v-container>
</template>

<script>
  import urls from "@/urls/management";
  import {loadData, checkFieldTrue} from "@/helper";
  import DebugJsonComponent from "@/modules/core/components/DebugJsonComponent";
  import JournalTableTemplate from "@/modules/templates/journal/JournalTableTemplate";
  import {generateListUidToName} from "@/helper/uidToName";

  export default {
    name: "CurrentJournal",
    components: {
      JournalTableTemplate,
      DebugJsonComponent
    },
    computed: {
      loadingPage: function(){
        return checkFieldTrue(this.journal, 'loading');
      },
    },
    data: function () {
      return {
        title: 'Журнал текущей успеваемости',
        loadErrorMsg: '',
        selectors: {
          subjects: [],
          selected: null,
        },
        loaded: false,
        search: '',
        journal: {
          data: [],
          selected: null,
          loading: false,
        }
      }
    },
    watch: {
      'journal.selected': function () {
        this.loadSemester(this.journal.selected)
      },
    },
    methods: {
      loadData: function () {
        let catcher = (error) => {console.log(error)};
        let url = urls.EDUCATION.GROUP.CURRENT_JOURNAL(this.$route.params.group_id);
        loadData(
          url,
          (data) => {
            this.journal.data = data;
            this.journal.selected = data[0];
            this.loaded = true;
          },
          catcher
        );
      },
      loadSemester(selected, reload=false){
        if(selected.students.basename !== undefined) {
          this.$set(selected, 'students_url', selected.students);
          this.$set(selected, 'students_loaded', false);
        }
        if (!selected.students_loaded || reload){
          loadData(
            urls.EDUCATION.getByRecievedUrlData(
              selected.students_url,
              selected.students_url.get_params
            ),
            res => {
              selected.students = res;
              this.loadStudentFios(selected, () => { selected.students_loaded = true; });
            }
          );
        }
      },
      loadStudentFios(el, finalizer=()=>{}){
        el.students.forEach(el => {
          this.$set(el, 'fio', '');
        })
        generateListUidToName(el.students, 'student_uid', 'fio', () => {
            el.students.sort((a,b) => a.fio.toUpperCase() > b.fio.toUpperCase() ? 1: -1)
            el.fio_loaded = true;
            finalizer();
          }
        );
      },
    },
    created() {
      this.loadData();
      this.$store.commit('mainpage/changeTitle', this.title);
    }
  }
</script>

<style scoped>

</style>
