
<template>
  <v-container style="height: 100%" v-if="$route.name === names.DEPARTAMENTS.LIST">

    <v-card flat>
      <v-toolbar
        elevation="0"
        color="style-color-main-gray"
      >
        <v-card-title>Список кафедр:</v-card-title>
      </v-toolbar>
      <v-data-table
              :headers="headers"
              :items="items"
              :loading="loading"
              class="elevation-1"
              no-data-text="Нет данных"
              :options.sync="pagination"
              :server-items-length="total"
              :footer-props="{'items-per-page-options':[15,25,50], 'items-per-page-text': 'Число элементов'}"
            >
              <template v-slot:item="props">
                <tr @click="getDetail(props.item)" style="cursor: pointer">
                  <td class="justify-center text-left">{{props.item.name}}</td>
                </tr>
              </template>
              <template slot="no-data">
                <v-card-text>Данные не загружены</v-card-text>
              </template>
            </v-data-table>
    </v-card>

  </v-container>
  <router-view :key="$route.fullPath" v-else></router-view>
</template>


<script>
  import UserDataMixin from '@/mixins/UserDataMixin'
  import {makeGetRequest} from "@/helper";
  import urls from "@/urls/dean"
  import names from "@/modules/dean/routers/names";
  import TitledPageMixin from "../../../../mixins/TitledPageMixin";

  export default {
    name: "ListSupervisedGroups",

    mixins: [UserDataMixin, TitledPageMixin],

    data () {
      return {
        CATHEDRA_URL_LIST: urls.DEAN.CATHEDRA.LIST(this.$route.params.idFaculty),
        DEAN_URL_LIST: urls.DEAN.LIST(),
        names:names,
        page_title: 'Список кафедр',
        total: 0,
        items: [],
        loading: true,
        pagination: {},
        headers: [
          {text: 'Кафедра', sortable: false, align: 'left',},
        ]
      }
    },

    watch: {
      pagination: {
        handler () {
          if(this.$route.name === names.DEPARTAMENTS.LIST)
            this.getItems()
        },
        deep: true
      }
    },


    created() {
      if(this.$route.params.idFaculty === undefined){
        this.$router.push({name: names.DEPARTAMENTS.LIST})
      }
      this.setPageTitleWithObject("Faculty", {uid: this.$route.params.idFaculty}, ' - ')
    },
    methods: {
      getDetail(item){
        this.$router.push({name: names.DEPARTAMENTS.DETAIL, params: {uidDepartament: item.uid}})
      },
      addItem(){
        this.closedForm()
        this.getItems();
      },

      getItems () {
        if(this.$route.params.idFaculty !== undefined){
        this.loading = true
        let url = new URL(this.CATHEDRA_URL_LIST)
        let params = {};


        if(this.pagination.page !== undefined) {
          params.page = this.pagination.page;
          params.size = this.pagination.itemsPerPage;
        }
        else{
          params.page = "1";
          params.size = "15";
        }

        url.search = new URLSearchParams(params).toString();


        let sys = true
        makeGetRequest(url).then(resp => {
          if (!resp.ok) {
            sys = false
          }
          return resp.json()
        }) .then(json => {
          this.items = json.results
          this.total = json.count
          this.loading = false
        })
      }
    },

      loadLeftMenu() {
        // console.log(1)
        let url = new URL(this.DEAN_URL_LIST)
        let params = {};

        url.search = new URLSearchParams(params).toString();

        let   menu = [
              {title: 'Список факультетов', icon: 'view_quilt', router: names.DETAIL},
              {
                title: 'Список кафедр',
                icon: 'view_quilt',
                router: {name: names.DEPARTAMENTS.LIST, params: {idFaculty: this.$route.params.idFaculty}}
              },
              {
                title: 'Список ведомых дисциплин',
                icon: 'view_quilt',
                router: {name: names.DEPARTAMENTS.LED_SUBJECTS.LIST, params: {idFaculty: this.$route.params.idFaculty}}
              },
              {
                title: ' Обучаемые группы',
                icon: 'view_quilt',
                router: {name: names.DEPARTAMENTS.GROUP.LED.LIST, params: {idFaculty: this.$route.params.idFaculty}}
              },

              {
                title: 'Список ведомых обучающихся',
                icon: 'view_quilt',
                router: {name: names.DEPARTAMENTS.STUDENTS.LIST, params: {idFaculty: this.$route.params.idFaculty}}
              },
              {
                title: 'Список групп',
                icon: 'view_quilt',
                router: {name: names.DEPARTAMENTS.GROUP.LIST, params: {idFaculty: this.$route.params.idFaculty}}
              },
              {
                title: 'Список обучающихся',
                icon: 'view_quilt',
                router: {name: names.DEPARTAMENTS.STUDENTS.LIST, params: {idFaculty: this.$route.params.idFaculty}}
              },
              {
                title: 'Список преподавателей',
                icon: 'view_quilt',
                router: {name:names.DEPARTAMENTS.TEACHER.LIST, params: {idFaculty: this.$route.params.idFaculty}}
              },
            ];
          this.$store.commit('leftmenu/replaceItems', menu);
        // })
      },
    }
  }


</script>
<style scoped>

</style>
