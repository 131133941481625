<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="12" lg="12">
        <v-toolbar
          elevation="0"
          color="style-color-main-gray"
        >
          <v-text-field
            dense
            placeholder="Поиск"
            v-model="search"
            hide-details
            class="grow"
          ></v-text-field>
          <v-btn icon><v-icon>search</v-icon></v-btn>
        </v-toolbar>
        <v-data-table
          locale="ru"
          :search="search"
          :headers="headers"
          :items="groups"
          :expanded.sync="expanded"
          show-expand
          :footer-props="{'items-per-page-options':[10,25,50], 'items-per-page-text': 'Число элементов:', 'show-current-page': true, 'show-first-last-page': true}"
        >
          <template v-slot:item.name="group">
            <router-link :to="makeLink(group.item)">
              {{group.item.name}}
            </router-link>

          </template>
          <template v-slot:item.curriculum="group">
            {{group.item.curriculum.direction.code}} {{group.item.curriculum.profile.name}} ({{group.item.curriculum.year}})
          </template>
          <template v-slot:expanded-item="group">
            <td :colspan="group.headers.length" class="py-2">
              <v-row wrap v-if="group.item.semesters !== undefined">
                <v-col class="text-left" cols="12" md="6" v-for="(semester, index) in group.item.semesters" :key="`semester_${index}`">
                  <v-card elevation="2" class="pa-0 ma-0" height="100%">
                    <v-card-title>
                      <v-row>
                        <v-col cols="8" class="py-0" style="word-break: break-word;">
                          {{semester.subject}}
                        </v-col>
                        <v-col cols="4" class="text-right py-0">
                          {{semester.control_type}}
                        </v-col>
                      </v-row>
                    </v-card-title>
                    <v-card-title>
                      <v-row>
                        <v-col cols="12" class="py-1 d-flex justify-center align-end">
                          <v-btn @click="untieTeacherFromGroup(group.item, semester)">Отвязать преподавателя от группы</v-btn>
                        </v-col>
                      </v-row>
                    </v-card-title>
                  </v-card>
                </v-col>
              </v-row>
              <v-row wrap v-else>
                <v-col class="text-left" :cols="6">
                  <v-skeleton-loader
                    height="100"
                    type="card"
                  >
                  </v-skeleton-loader>
                </v-col>
                <v-col class="text-left" :cols="6">
                  <v-skeleton-loader
                    height="100"
                    type="card"
                  >
                  </v-skeleton-loader>
                </v-col>
              </v-row>
<!--              <DebugJsonComponent :json="group.item.semesters" :status="group.headers.length"></DebugJsonComponent>-->
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
<!--    <DebugJsonComponent :json="groups"></DebugJsonComponent>-->
  </v-container>
</template>

<script>
  import DebugJsonComponent from "../../../core/components/DebugJsonComponent";
  import TitledPageMixin from "../../../../mixins/TitledPageMixin";
  import cathedras from "../../../../urls/cathedras";
  import {loadData, sendPostRequest} from "../../../../helper";
  import names from "../../routers/names";

  export default {
    name: "Groups",
    components: {
      DebugJsonComponent,
    },
    mixins: [TitledPageMixin],
    props: {
      teacher_fio: {
        required: false,
        type: String,
      }
    },
    data: function(){
      return {
        groups: undefined,
        expanded: [],
        loading: false,
        current: undefined,
        headers: [
          {text: 'Группа', value: 'name', searched: true,},
          {text: 'Курс', value: 'course', sortable: false,},
          {text: 'Форма', value: 'form', sortable: false,},
          {text: 'Уровень', value: 'level', sortable: false,},
          {text: 'Профиль', value: 'curriculum', sortable: false,},
          {text: '', value: 'data-table-expand'},
        ],
        search: '',
      };
    },
    computed: {
      page_title(){
        return `${this.teacher_fio}: Список групп`
      }
    },
    watch: {
      expanded: function (value) {
        value.forEach(el => {
          if(el.semesters === undefined) {
            this.loadSemesters(el)
          }
        })
      }
    },
    methods: {
      getCurrentUrl(){
        return this.current
      },
      makeLink(group){
        return {
          name: names.CathedraGroupsDetail,
          params: {
            cathedra_uid: this.$route.params.cathedra_uid,
            group_id: group.id,
            taught: !group.attachment? 'taught': undefined,
          },
          query: {
            teacher: this.$route.params.teacher_uid
          }
        }
      },
      loadSemesters(group, finalizer=()=>{}) {
        loadData(
          cathedras.getByRecievedUrlData(group.semesters_url, group.semesters_url.get_params),
          data => {
            group.semesters = data;
            finalizer(group);
          },
        )
      },
      loadData(url=undefined) {
        this.loading = false;
        if(url === undefined)
          url = cathedras.CATHEDRA.TEACHERS.GROUPS(this.$route.params.cathedra_uid, this.$route.params.teacher_uid)
        loadData(
          url,
          (data) => {
            // console.log(data)
            this.groups = data;
            this.groups.forEach(el => {
              this.$set(el, 'semesters_url', el.semesters);
              this.$set(el, 'name',`${el.litter}-${el.course}${el.number}`);
              el.semesters = undefined;
              this.loading = true;
            });
            this.current = url;
          }
        )
      },
      untieTeacherFromGroup(group, group_subject){
        group.semesters = undefined;
        // this.loadSemesters(group, group.semester_url);
        sendPostRequest(
          cathedras.CATHEDRA.SUBJECT.TEACHERS_LINK_SAVE(
            this.$route.params.cathedra_uid,
            group_subject.subject_id,
          ),
          {
            teachers: [
              {
                teacher: this.$route.params.teacher_uid,
                group_semester: group_subject.id,
              }
            ],
          },
          () => {
            this.loadSemesters(group, (group) => {
              if(group.semesters.length === 0) {
                let gindex = this.groups.findIndex(el => el.id === group.id);
                this.$delete(this.groups, gindex);
              }
            });
          }
        )
      }
    },
    created() {
      this.loadData()
    }
  }
</script>

<style scoped>

</style>
