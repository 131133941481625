<template>
  <v-container>
    <v-card>
      <v-toolbar elevation="0" color="style-color-main-gray">
        <v-toolbar-title>Компетенции:</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn @click="opened_form = true" color="secondary" text>
            <v-icon>add</v-icon>
            Добавить
          </v-btn>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                text
                v-on="on"
                @click=" getItems()"
                class="mr-2"
                medium
              >
                <v-icon>
                  update
                </v-icon>
              </v-btn>
            </template>
            <span>Обновить данные</span>
          </v-tooltip>
        </v-toolbar-items>
      </v-toolbar>

      <v-data-table
        :headers="headers"
        :items="items"
        :loading="pagination.loading"
        :page.sync="pagination.page"
        :items-per-page.sync="pagination.size"
        :server-items-length="pagination.count"
        no-data-text="Нет данных"
        class="elevation-1"
        :footer-props="{'items-per-page-options':[10,25,50], 'items-per-page-text': 'Число элементов'}"
        locale="ru"
      >
        <template v-slot:item.actions="props">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                v-on="on"
                @click="updateItem(props.item)"
                medium>
                <v-icon>
                  edit
                </v-icon>
              </v-btn>
            </template>
            <span>Редактировать</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                v-on="on"
                @click="openConfirmDelete(props.item)"
                medium>
                <v-icon>
                  delete
                </v-icon>
              </v-btn>
            </template>
            <span>Удалить</span>
          </v-tooltip>
        </template>
        <template slot="no-data">
          <v-card-text>Данные не загружены</v-card-text>
        </template>
      </v-data-table>
      <CompetenceForm
        @addItem="addItem"
        @ended="closedForm"
        @refreshItem="refreshItem"
        @input="closedForm"
        :opened_form="opened_form"
        v-bind="selected"
        v-if="opened_form"
      >
      </CompetenceForm>
      <v-dialog persistent v-model="opened_confirm" width="500">
        <v-card v-if="deletable">
          <v-card-title>Подтвердите удаление</v-card-title>
          <v-divider></v-divider>
          <v-card-text class="mt-4">
            Вы уверены, что хотите удалить?<br>
          </v-card-text>
          <v-card-actions>
            <v-flex>
              <v-btn @click="deleteItem(deletable.id)" color="warning" text>
                Удалить
              </v-btn>
              <v-btn @click="closeConfirmDelete" text>
                Отмена
              </v-btn>
            </v-flex>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </v-container>
</template>

<script>

import urls from "@/urls/management";
import TitledPageMixin from "@/mixins/TitledPageMixin";
import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
import CompetenceForm from "@/modules/educationalManagement/forms/CompetenceForm";
import DebugJsonComponent from "@/modules/core/components/DebugJsonComponent";
import UserDataMixin from '@/mixins/UserDataMixin'
import {loadData, makeDeleteRequest} from "@/helper";

export default {
  name: "TrainingDirection",
  components: {CompetenceForm, DebugJsonComponent},
  mixins: [TitledPageMixin, PaginatedDataMapperMixin, UserDataMixin],
  props: {},

  data() {
    return {
      page_title: 'Список компетенций',
      URL_LIST: urls.EDUCATION.REFERENCE.COMPETENCE.LIST(),
      items: [],
      headers: [
        {text: 'Наименование', value: 'name', align: 'start', sortable: false},
        {text: 'Код', value: 'code', align: 'start', sortable: false},
        {text: 'Действия', value: 'actions', align: 'right', sortable: false},
      ],
    }
  },
  methods: {
    addItem(){
      this.closedForm()
      this.getItems()
    },
    deleteItem(id) {
      let url = urls.EDUCATION.REFERENCE.COMPETENCE.DELETE(id)
      makeDeleteRequest(url).then(resp => {
        if (resp.ok) {
          this.closeConfirmDelete()
          this.getItems()
        }
      })
    },
    loadData(url = undefined) {
      this.getItems(url)
    },
    getItems (url=undefined) {
      this.setLoading();
      if(url === undefined) {
        url = this.URL_LIST,
          this.setCurrentLoadUrl(url)
      }
      loadData(
        url,
        (data) => {
          this.mapResults(data,'items')
          this.setNotLoading()
        }
      )
    },
  },
  created(){
    this.getItems()
    this.$store.commit('mainpage/changeTitle', 'Компетенции');
  },
}
</script>

<style scoped>

</style>
