<template>
  <scroll-center-dialog v-model="opened_form"
                        @close="closeDialog"
                        @apply="save"
                        :title="!!id ? 'Изменить' : 'Добавить'"
                        width="500"
  >
    <v-autocomplete
      :items="selector_doc_type"
      :error="hasError('type')"
      :error-messages="getError('type')"
      v-model="value.type"
      class="pa-1 ma-1"
    >
      <template v-slot:label>
        {{"Тип документа"}}<span class="red--text">*</span>
      </template>
    </v-autocomplete>
    <v-text-field
      :error="hasError('number')"
      :error-messages="getError('number')"
      label="	Номер документа"
      v-model="value.number"
      class="pa-1 ma-1"
    >
      <template v-slot:label>
        {{"Номер документа"}}<span class="red--text">*</span>
      </template>
    </v-text-field>
    <v-text-field
      :error="hasError('when')"
      :error-messages="getError('when')"
      label="Дата выдачи"
      type="date"
      v-model="value.when"
      class="pa-1 ma-1"
    >
      <template v-slot:label>
        {{"Дата выдачи"}}<span class="red--text">*</span>
      </template>
    </v-text-field>
    <v-text-field
      :error="hasError('who')"
      :error-messages="getError('who')"
      label="	Кем выдано"
      v-model="value.who"
      class="pa-1 ma-1"
    >
      <template v-slot:label>
        {{"Кем выдано"}}<span class="red--text">*</span>
      </template>
    </v-text-field>
    <v-text-field
      :error="hasError('where')"
      :error-messages="getError('where')"
      label="Где выдано"
      v-model="value.where"
      class="pa-1 ma-1"
    >
      <template v-slot:label>
        {{"Где выдано"}}<span class="red--text">*</span>
      </template>
    </v-text-field>
    <v-file-input
      v-if="document === ''"
      class="pa-1 ma-1"
      :error="hasError('document')"
      :error-messages="getError('document')"
      v-model="value.document"
      accept="application/pdf"
      label="Файл документа об образовании"
      :messages="document"
    >
      <template v-slot:label>
        {{"Файл документа об образовании"}}<span class="red--text">*</span>
      </template>
    </v-file-input>
    <v-text-field
      v-else
      class="pa-1 ma-1"
      clearable
      readonly
      v-model="text"
      @click:clear="document = ''"
      @click="openFile"
      @click:append="openFile"
      append-icon="file_open"
      prepend-icon="attach_file"
    ></v-text-field>
  </scroll-center-dialog>
</template>

<script>

  import ScrollCenterDialog from "@/modules/core/components/ScrollCenterDialog";
  export default {
    name: "Courses",
    components: {ScrollCenterDialog},
    props: {
      selector_doc_type:{
        type:Array,
        default: ()=>{return []}
      },
      id:{
        type:Number ,
      },
      who:{
        type:String,
        default:''
      },
      when:{
        type:String,
        default:''
      },
      where:{
        type:String,
        default:''
      },
      number:{
        type:String,
        default:''
      },
      type:{
        type:String,
        default:''
      },
      document:{
        type:String,
        default:''
      },
      errors:{
        type:Object,
      },
      opened_form: Boolean,
    },

    data() {
      return {
        value: {
          id: this.id || '',
          type: this.type || '',
          number: this.number || '',
          when: this.when || '',
          who: this.who || '',
          where: this.where || '',
          document: null,
        },
        text: this.type + ' (Нажмите чтобы открыть)',
      }
    },
    methods: {
      hasError: function (field) {
        if (
          field in this.errors
          && this.errors[field] !== undefined
          && this.errors[field] !== null
        )
          if (this.errors[field].length > 0)
            return true;
        return false;
      },
      getError: function (field) {
        if (
          field in this.errors
          && this.errors[field] !== undefined
          && this.errors[field] !== null
        )
          if (this.errors[field].length > 0)
            return this.errors[field];
        return null;
      },
      save(){
        this.$emit('createItem', this.value);
      },

      closeDialog() {
        this.$emit('ended', '')
      },
      openFile(){
        window.open(this.document)
      },
    },
    created() {

    }
  }

</script>

<style scoped>

</style>
