<template>
  <v-container fluid>
    <EduDocList
      :items="items"
    >
    </EduDocList>
  </v-container>

</template>

<script>
import UserDataMixin from '@/mixins/UserDataMixin'
import {makeGetRequest, makePostRequestFile,} from "@/helper";
import EduDocList from "@/modules/templates/education_document/EduDocList"
import urls from "@/urls/cathedras";

export default {
  name: "EduDoc",
  components: {EduDocList},
  mixins: [UserDataMixin],

  data() {
    return {
      items: [],
      loading: true,
      URL_LIST: urls.CATHEDRA.STUDENT.EDUDOC.LIST(this.$route.params.cathedra_uid, this.$route.params.student_id),
    }
  },

  created() {
    this.$store.commit('mainpage/changeTitle', 'Документы об образовании');
    this.getItems();
  },
  methods: {

  }
}


</script>

<style scoped>

</style>
