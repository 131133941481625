<template>
    <v-card >
      <v-toolbar
        class="mb-2"
        color="style-color-main-gray"
        flat
      >
        <v-text-field v-model="search" class="ma-2"prepend-inner-icon="search"></v-text-field>
        <v-spacer></v-spacer>
        <v-btn text icon @click="createItem()" v-if="createBtn">
          <v-icon
            medium
          >
            add
          </v-icon>
        </v-btn>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="items"
        :search="search"
        class="elevation-1"
        no-data-text="Нет данных"
        :footer-props="{'items-per-page-options':[10,25,50], 'items-per-page-text': 'Число элементов'}"
      >
        <v-progress-linear v-slot:progress color="blue" indeterminate></v-progress-linear>
        <template v-slot:item.obj="props">
        <v-tooltip bottom v-if="updateBtn">
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              @click="updateItem(props.item)"
              class="mr-2"
              medium
            >
              edit
            </v-icon>
          </template>
          <span>Редактировать</span>
        </v-tooltip>
        <v-tooltip bottom v-if="deleteBtn">
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              @click="openConfirmDelete(props.item)"
              medium
            >
              delete
            </v-icon>
          </template>
          <span>Удалить</span>
        </v-tooltip>
        </template>
        <template v-slot:item.type="props">
          <a :href="props.item.document" target="_blank">{{props.item.type}}</a>
        </template>
        <template slot="no-data">
          <v-card-text>Данные не загружены</v-card-text>
        </template>
      </v-data-table>
    </v-card>
</template>

<script>

  export default {
    name: "ListAdmissions",
    components:{ },
    props:{
        items:{
          type:Array
        },
        createBtn:{
          type:Boolean,
          default: false
        },
        deleteBtn:{
          type:Boolean,
          default: false
        },
        updateBtn:{
          type:Boolean,
          default: false
        }
    },
    data () {
      return {
        search:'',
      }
    },
    computed:{
      headers: function () {
       let header = [
          {text: 'Документ', sortable: false, align: 'left',value:'type', width: '30%'},
          {text: 'Выдал', sortable: false, align: 'left',value:'who', width: '20%'},
          {text: 'Номер', sortable: false, align: 'left',value:'number', width: '10%'},
          {text: 'Место выдачи', sortable: false, align: 'left',value:'where', width: '20%'},
          {text: 'Дата выдачи', sortable: false, align: 'left',value:'when', width: '10%'},
        ]
        if(this.deleteBtn || this.updateBtn){
          header.push( {text: 'Действия', sortable: false, align: 'right',value:'obj', width: '10%'},)
        }
        return header
      }
    },
    created() {

    },
    methods: {
      updateItem(item){
        this.$emit('updateItem', item);
      },
      openConfirmDelete(item){
        this.$emit('openConfirmDelete', item);
      },
      createItem(){
        this.$emit('createItem', '');
      }
    }
  }


</script>

<style scoped>

</style>
