<template>
  <v-container fluid>
    <v-alert outlined type="info">
      Уважаемые пользователи, обращаем ваше внимание, что учебный журнал суммирует баллы, введенные в каждую контрольную точку по отдельности. <br/>
      Вы можете перемещаться между полями, используя стрелочки на клавиатуре, а навигация через кнопку "Tab" работает по столбцам, а не строчкам.
    </v-alert>
    <v-data-table
      v-if="rows.length !== 0"
      :items="loading? undefined: rows"
      :headers="headers"
      dense
      :items-per-page="rows.length"
      hide-default-footer
      :search="search"
      :loading="loading"
      :mobile-breakpoint="0"
    >
      <template v-slot:header.medium_control>
        <div class="d-flex flex-column">
          <div class="px-1 text-center">Текущий контроль</div>
          <div class="d-flex flex-row">
            <div class="d-flex flex-column px-1" v-for="(middle, index) in middles" :key="`middle_header_${index}`" :style="{'width': `${100 / middles.length}%`}" style="min-width: min-content;">
              <div class="">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-on="on" v-bind="attrs" style="max-height: 18px; overflow: hidden; text-overflow: ellipsis;">
                      {{ middle.name }}
                    </span>
                  </template>
                  {{middle.name}} {{displayDate(middle.date)}}
                </v-tooltip>
              </div>
              <div class="d-flex flex-row">
                <v-tooltip top v-for="(submiddle, subindex) in middle.sub_control_points" :key="`middle_header_${index}_submiddle_${subindex}`" >
                  <template v-slot:activator="{ on, attrs }">
                    <div class="d-flex mr-2" style="min-width: 70px; max-height: 18px; overflow: hidden; text-overflow: ellipsis;" :style="{'width': `${100 / middle.sub_control_points.length}%`}" v-on="on" v-bind="attrs">
                      {{ submiddle.name }}
                    </div>
                  </template>
                  {{ submiddle.name }}
                </v-tooltip>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:item.medium_control="props">
        <div class="d-flex flex-row" v-if="props.item.medium_control.length"  style="width: 100%">
          <div class="d-flex flex-column px-1" v-for="(middle, index) in props.item.medium_control" :key="`middle_header_${index}`" :style="{'width': `${100 / middles.length}%`}" style="min-width: min-content;">
            <template v-if="middles[index] && middles[index].can_change">
              <div class="d-flex">
                <v-text-field
                  style="min-width: 70px;"
                  type="number"
                  step="1"
                  min="0"
                  max="100"
                  v-model="middle.value"
                  dense
                  class="body-2 my-auto"
                  :rules="[rules.max, rules.min]"
                  :disabled="isRowDisabled(props.item)"
                  hide-details
                  :error="hasMiddlePointError(props.item.education, middles[index].id)"
                  @input="clearMiddlePointError(props.item.education, middles[index].id)"
                  :ref="getMiddleRefName(props.index, index)"
                  :tabindex="getMiddleTabIndex(props.index, index)"
                  @keydown.down.prevent="downMiddleWithSub(props.index, index, middle.sub_control.length)"
                  @keydown.up.prevent="upMiddleWithSub(props.index, index, middle.sub_control.length)"
                  @keydown.right.prevent="rightMiddle(props.index, index, props.item.medium_control.length)"
                  @keydown.left.prevent="leftMiddle(props.index, index, props.item.medium_control.length)"
                  @focus="$event.target.select()"
                >
                  <template v-slot:append v-if="hasMiddlePointError(props.item.education, middles[index].id)">
                    <v-tooltip bottom>
                      <template v-slot:activator="{on}">
                        <v-icon class="mt-2" v-on="on" small color="error">warning</v-icon>
                      </template>
                      <span
                        v-for="(error, eidx) in getMiddlePointError(props.item.education, middles[index].id)"
                        :key="`errr_${eidx}_${props.item.education}`"
                      >
                        {{error}}<br/>
                      </span>
                    </v-tooltip>
                  </template>
                </v-text-field>
              </div>
              <div class="d-flex flex-row">
                <div class="d-flex mr-2" v-for="(submiddle, subindex) in middle.sub_control" :key="`middle_header_${index}_submiddle_${subindex}`">
                  <v-text-field

                    style="min-width: 70px;"
                    type="number"
                    step="1"
                    min="0"
                    max="100"
                    v-model="submiddle.value"
                    dense
                    class="body-2 my-auto"
                    :rules="[rules.max, rules.min]"
                    :disabled="isRowDisabled(props.item)"
                    hide-details
                    :error="hasMiddlePointError(props.item.education, middles[index].sub_control_points[subindex].id)"
                    @input="clearMiddlePointError(props.item.education, middles[index].sub_control_points[subindex].id)"
                    :ref="getSubMiddleRefName(props.index, index, subindex)"
                    :tabindex="getSubMiddleTabIndex(props.index, index, subindex)"
                    @keydown.down.prevent="downSubMiddle(props.index, index)"
                    @keydown.up.prevent="upSubMiddle(props.index, index)"
                    @keydown.right.prevent="rightSubMiddle(props.index, index, subindex, middle.sub_control.length)"
                    @keydown.left.prevent="leftSubMiddle(props.index, index, subindex, middle.sub_control.length)"
                    @focus="$event.target.select()"
                  >
                    <template v-slot:append v-if="hasMiddlePointError(props.item.education, middles[index].sub_control_points[subindex].id)">
                      <v-tooltip bottom>
                        <template v-slot:activator="{on}">
                          <v-icon class="mt-2" v-on="on" small color="error">warning</v-icon>
                        </template>
                        <span
                          v-for="(error, eidx) in getMiddlePointError(props.item.education, middles[index].sub_control_points[subindex].id)"
                          :key="`errr_${eidx}_${props.item.education}`"
                        >
                          {{error}}<br/>
                        </span>
                      </v-tooltip>
                    </template>
                  </v-text-field>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="d-flex fill-height">
                <span class="my-auto">{{middle.value}}
                  <v-tooltip top v-if="middle.comment !== '' && middle.comment !== null">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-on="on" v-bind="attrs" small>info</v-icon>
                    </template>
                    <span>{{middle.comment}}</span>
                  </v-tooltip>
                </span>
              </div>
              <div class="d-flex flex-row fill-height">
                <div class="d-flex mr-2" v-for="(submiddle, subindex) in middle.sub_control" :key="`middle_header_${index}_submiddle_${subindex}`">
                  <span class="my-auto">{{submiddle.value}}
                  <v-tooltip top v-if="submiddle.comment !== '' && submiddle.comment !== null">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-on="on" v-bind="attrs" small>info</v-icon>
                    </template>
                    <span>{{submiddle.comment}}</span>
                  </v-tooltip>
                </span>
                </div>
              </div>
            </template>
          </div>
        </div>
        <v-row v-else>
          <v-col>
            <span>не созданы контрольные точки</span>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.addition="props">
        <v-text-field
          v-if="can_change_additional"
          style="min-width: 70px;"
          type="number"
          step="1"
          min="0"
          max="40"
          v-model="props.item.additional"
          dense
          class="body-2 my-auto"
          :disabled="isRowDisabled(props.item)"
          hide-details
          :error="hasAdditionalError(props.item.education)"
          @input="clearAdditionalError(props.item.education)"
          :ref="getAdditionalRefName(props.index)"
          :tabindex="getAdditionalTabIndex(props.index)"
          @keydown.down.prevent="downAdditional(props.index)"
          @keydown.up.prevent="upAdditional(props.index)"
          @keydown.right.prevent="rightAdditional(props.index, props.item.medium_control.length)"
          @keydown.left.prevent="leftAdditional(props.index, props.item.medium_control.length)"
          @focus="$event.target.select()"
        >
          <template v-slot:append v-if="hasAdditionalError(props.item.education)">
            <v-tooltip bottom>
              <template v-slot:activator="{on}">
                  <v-icon class="mt-2" v-on="on" small color="error">warning</v-icon>
              </template>
              <span
                v-for="(error, eidx) in getAdditionalError(props.item.education)"
                :key="`errr_${eidx}_${props.item.education}`"
              >
                {{error}}<br/>
              </span>
            </v-tooltip>
          </template>
        </v-text-field>
        <div class="d-flex fill-height" v-else>
          <span class="my-auto">{{props.item.additional}}
          </span>
        </div>
      </template>
      <template v-slot:item.absence="props">
        <v-tooltip bottom v-if="!props.item.automate">
          <template v-slot:activator="{ on, attrs }">
            <v-simple-checkbox
              v-model="props.item.absence"
              hide-details
              v-bind="attrs"
              v-on="on"
              color="warning"
              :disabled="save_execute || props.item.reload || !props.item.can_change_exam"
            >
            </v-simple-checkbox>
          </template>
          <span>Проставить неявку</span>
        </v-tooltip>
      </template>
      <template v-slot:item.exam="props">
        <v-row v-if="props.item.can_change_exam" class="d-flex">
          <v-col>
            <v-text-field
              style="min-width: 70px;"
              type="number"
              step="1"
              min="0"
              max="40"
              v-model="props.item.exam"
              dense
              class="body-2 my-auto"
              :disabled="isRowDisabled(props.item)"
              hide-details
              :error="hasExamError(props.item.education)"
              @input="clearExamError(props.item.education)"
              :ref="getExamRefName(props.index)"
              :tabindex="getExamTabIndex(props.index)"
              @keydown.down.prevent="downExam(props.index)"
              @keydown.up.prevent="upExam(props.index)"
              @keydown.right.prevent="rightExam(props.index, props.item.medium_control.length)"
              @keydown.left.prevent="leftExam(props.index, props.item.medium_control.length)"
              @focus="$event.target.select()"
            >
              <template v-slot:prepend v-if="hasExamError(props.item.education)">
                <v-tooltip bottom>
                  <template v-slot:activator="{on}">
                    <v-icon class="mt-2" v-on="on" small color="error">warning</v-icon>
                  </template>
                  <span
                    v-for="(error, eidx) in getExamError(props.item.education)"
                    :key="`errr_${eidx}_${props.item.education}`"
                  >
                  {{error}}<br/>
                </span>
                </v-tooltip>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <div v-else style="text-align: center">
          <span class="my-auto">{{props.item.exam}}</span>
        </div>
      </template>
      <template v-slot:item.automate="props">
        <v-tooltip bottom v-if="!props.item.absence">
          <template v-slot:activator="{ on, attrs }">
            <v-simple-checkbox
              v-model="props.item.automate"
              hide-details
              v-bind="attrs"
              v-on="on"
              :disabled="save_execute || props.item.reload || !props.item.can_change_exam || !props.item.can_automate"
            >
            </v-simple-checkbox>
          </template>
          <span>Проставить автомат</span>
        </v-tooltip>
      </template>
      <template v-slot:item.rating="props">
        <v-row class=" fill-height" style="min-width: 120px;">
          <v-col  cols="2" class="ma-0 pa-0 d-flex justify-space-between my-auto">
            <span class="d-flex">{{props.item.rating}}</span>
          </v-col>
          <v-col  cols="8" class="ma-0 pa-0 d-flex justify-space-between my-auto">
            <v-chip small v-if="props.item.reload">
              Перезачет
            </v-chip>
          </v-col>
          <v-col cols="2" class="ma-0 pa-0 d-flex justify-space-between my-auto">
            <v-tooltip top>
              <template v-slot:activator="{on, attrs}">
                <v-col cols="auto" class="ma-0 pa-0">
                  <v-icon
                    class="ml-auto"
                    small
                    v-on="on"
                    v-bind="attrs"
                  >
                    info
                  </v-icon>
                </v-col>
              </template>
              <span>{{props.item.text_rating}}</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </template>
      <template v-slot:loading>
        <v-skeleton-loader
          v-for="i in rows.length"
          :key="`sekeleton_${i}`"
          type="table-row"
        >
        </v-skeleton-loader>
      </template>
    </v-data-table>
    <span v-else>
      В учебной группе отсутствуют обучающиеся и журнал недоступен. Пожалуйста, обратитесь в деканат учебной группы.
    </span>
  </v-container>

</template>

<script>
import JournalTableArrowNavigateMixin from "@/mixins/JournalTableArrowNavigateMixin";
import {displayDate} from "@/helper";

export default {
  name: "JournalTableTemplate",
  components: {},
  mixins: [JournalTableArrowNavigateMixin],
  props: {
    can_change_additional: {
      type: Boolean,
      required: true,
    },
    middles: {
      type: Array,
      required: true,
    },
    rows: {
      type: Array,
      required: true,
    },
    is_exam: {
      type: Boolean,
      required: false,
    },
    search: {
      type: String,
      required: true,
    },
    save_execute: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Object,
      required: true,
    },
    headers: {
      type: Array,
      default: () => {
        return [
          {text: 'Обучающийся', value: 'fio', sortable: false, filterable: true, width: '20%'},
          {text: 'Промежуточный контроль', value: 'medium_control', sortable: false, filterable: false, width: '40%', align: 'start'},
          {text: 'Доп. опрос', value: 'addition', sortable: false, filterable: false, width: '10%'},
          {text: 'Рейтинг в семестре', value: 'rating_in_semester', sortable: false, filterable: false, width: '10%'},
          {text: 'Экзамен', value: 'exam', sortable: false, filterable: false, width: '10%'},
          {text: 'Итоговая оценка', value: 'rating', sortable: false, filterable: false, width: '10%'},]
      }
    }
  },
  data: function () {
    return {
      can_update: true,
      rules: {
        max: function (val) {
          if (val > 100)
            return 'Не более 100'
          else
            return true
        },
        min: function (val) {
          if (val < 0)
            return 'Не менее 0'
          else
            return true
        },
      },
    }
  },
  computed: {
    rows_length() {
      return this.rows.length;
    },
  },
  methods: {
    displayDate(date) {
      return displayDate(date)
    },
    isRowDisabled(item){
      return this.save_execute || item.automate || item.absence || item.reload
    },
    checker(obj){
      if (obj.value > 100)
        obj.value = 100;
      else if (obj.value < 0)
        obj.value = 0;
    },
    hasMiddlePointError(education, middle){
      if(this.errors.middles.length > 0){
        let idx = this.errors.middles.findIndex(el => el.origin === middle)
        if(idx !== -1) {
          return this.errors.middles[idx].students.findIndex(el => el.education === education) !== -1;
        }
      }
      return false
    },
    getMiddlePointError(education, middle){
      if(this.errors.middles.length > 0){
        let idx = this.errors.middles.findIndex(el => el.origin === middle)
        if(idx !== -1)
          return (
            this.errors.middles[idx].students.find(el => el.education === education) || {value: []}
          ).value;
      }
      return []
    },
    clearMiddlePointError(education, middle){
      if(this.errors.middles.length > 0){
        let idx = this.errors.middles.findIndex(el => el.origin === middle)
        if(idx !== -1) {
          let sidx = this.errors.middles[idx].students.find(el => el.education === education);
          if(sidx !== -1)
            this.errors.middles[idx].students.splice(sidx, 1);
          if(this.errors.middles.length === 0)
            this.errors.middles.splice(idx, 1);
        }
      }
      return []
    },
    hasAdditionalError(education){
      if(this.errors.additional.length > 0){
        return this.errors.additional.findIndex(el => el.education === education) !== -1
      }
      return false
    },
    getAdditionalError(education){
      if(this.errors.additional.length > 0){
        return (this.errors.additional.find(el => el.education === education) || {value_additional: []}).value_additional;
      }
      return []
    },
    clearAdditionalError(education){
      if(this.errors.additional.length > 0){
        let idx = this.errors.additional.findIndex(el => el.education === education);
        if(idx !== -1)
          this.errors.additional.splice(idx, 1);
      }
    },
    hasExamError(education){
      if(this.errors.exam.length > 0){
        return this.errors.exam.findIndex(el => el.education === education)
      }
      return false
    },
    getExamError(education){
      if(this.errors.exam.length > 0){
        return this.errors.exam.find(el => el.education === education) || [];
      }
      return []
    },
    clearExamError(education){
      if(this.errors.exam.length > 0){
        let idx = this.errors.exam.findIndex(el => el.education === education);
        if(idx !== -1)
          this.errors.exam.splice(idx, 1);
      }
    },
  },
  created() {},
}
</script>

<style scoped>

</style>
