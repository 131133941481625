<template>
  <v-data-table
    v-if="rows.length !== 0"
    :items="!loading? undefined: rows"
    :headers="headers"
    dense
    :items-per-page="rows.length"
    hide-default-footer
    :search="search"
    :loading="!loading"
  >
    <template v-slot:header.medium_control>
      <div class="d-flex flex-column">
        <div class="px-1 text-center">Текущий контроль</div>
        <v-row>
          <v-col cols="4">1 контрольная точка</v-col>
          <v-col cols="4">2 контрольная точка</v-col>
          <v-col cols="4">3 контрольная точка</v-col>
        </v-row>
      </div>
    </template>
    <template v-slot:item.medium_control="props">
      <v-row>
        <v-col v-for="(item, index) in props.item.medium_control" class="py-0 px-2 my-auto mx-0" :key="`medium_control_${item.id}_${index}`">
          <v-text-field
            type="number"
            step="1"
            min="0"
            max="100"
            v-model="item.value"
            v-if="can_update"
            hide-details
            dense
            class="body-2 my-auto"
            :disabled="isRowDisabled(props.item)"
            :error="hasMiddlePointError(item)"
            :error-messages="getMiddlePointError(item)"
            :ref="getMiddleRefName(props.index, index)"
            :tabindex="getMiddleTabIndex(props.index, index)"
            @keydown.down.prevent="downMiddle(props.index, index)"
            @keydown.up.prevent="upMiddle(props.index, index)"
            @keydown.right.prevent="rightMiddle(props.index, index, props.item.medium_control.length)"
            @keydown.left.prevent="leftMiddle(props.index, index, props.item.medium_control.length)"
            @focus="$event.target.select()"
          >
            <template v-slot:append >
              <v-tooltip bottom v-if="hasMiddlePointError(item)">
                <template v-slot:activator="{on}">
                  <v-icon class="mt-2" v-on="on" small color="error">warning</v-icon>
                </template>
                <span
                  v-for="(error, eidx) in getMiddlePointError(item)"
                  :key="`errr_${eidx}_${props.item.id}`"
                >
                  {{error}}<br/>
                </span>
              </v-tooltip>
            </template>
          </v-text-field>
          <span v-else>{{item.value}}</span>
        </v-col>
      </v-row>
    </template>
    <template v-slot:item.additional="props">
      <v-text-field
        type="number"
        step="1"
        min="0"
        max="40"
        v-model="props.item.additional"
        v-if="can_update"
        hide-details
        dense
        class="body-2 my-auto"
        :error=hasAdditionalError(props.item)
        :disabled="isRowDisabled(props.item)"
        :ref="getAdditionalRefName(props.index)"
        :tabindex="getAdditionalTabIndex(props.index)"
        @keydown.down.prevent="downAdditional(props.index)"
        @keydown.up.prevent="upAdditional(props.index)"
        @keydown.right.prevent="rightAdditional(props.index, props.item.medium_control.length)"
        @keydown.left.prevent="leftAdditional(props.index, props.item.medium_control.length)"
        @focus="$event.target.select()"
      >
        <template v-slot:append >
          <v-tooltip bottom v-if="hasAdditionalError(props.item)">
            <template v-slot:activator="{on}">
              <v-icon class="mt-2" v-on="on" small color="error">warning</v-icon>
            </template>
            {{String(getAdditionalError(props.item)) }}
          </v-tooltip>
        </template>
      </v-text-field>
      <span v-else>{{props.item.additional}}</span>
    </template>
    <template v-slot:item.absence="props">
      <v-tooltip bottom v-if="!props.item.automate && props.item.control_type!=='зачет'">
        <template v-slot:activator="{ on, attrs }">
          <v-simple-checkbox
            v-model="props.item.absence"
            hide-details
            v-bind="attrs"
            v-on="on"
            color="warning"
            :disabled="save_execute || props.item.reload || !props.item.can_change_exam || !can_update "
          >
          </v-simple-checkbox>
        </template>
        <span>Проставить неявку</span>
      </v-tooltip>
    </template>
    <template v-slot:item.exam="props">
      <v-row v-if="props.item.can_change_exam && can_update && props.item.control_type!=='зачет'" class="d-flex">
        <v-col>
          <v-text-field
            type="number"
            step="1"
            min="0"
            max="40"
            v-model="props.item.exam"
            hide-details
            dense
            class="body-2 my-auto"
            :disabled="isRowDisabled(props.item)"
            :ref="getExamRefName(props.index)"
            :tabindex="getExamTabIndex(props.index)"
            @keydown.down.prevent="downExam(props.index)"
            @keydown.up.prevent="upExam(props.index)"
            @keydown.right.prevent="rightExam(props.index, props.item.medium_control.length)"
            @keydown.left.prevent="leftExam(props.index, props.item.medium_control.length)"
            @focus="$event.target.select()"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <div v-else-if="props.item.control_type!=='зачет'">
        <span class="my-auto">{{props.item.exam}}</span>
      </div>
      <v-tooltip  bottom v-else>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>warning_amber</v-icon>
          </v-btn>
        </template>
        <span>Для зачета не предусмотрен итоговый контроль.</span>
      </v-tooltip>
    </template>
    <template v-slot:item.automate="props">
      <v-tooltip  bottom v-if="!props.item.absence  && props.item.control_type!=='зачет'">
        <template v-slot:activator="{ on, attrs }">
          <v-simple-checkbox
            v-model="props.item.automate"
            :disabled="save_execute || props.item.reload || !props.item.can_change_exam || !can_update || !props.item.can_automate"
            hide-details
            v-bind="attrs"
            v-on="on"
          >
          </v-simple-checkbox>
        </template>
        <span>Проставить автомат</span>
      </v-tooltip>
    </template>
    <template v-slot:item.rating="props">
      <v-row class=" fill-height" style="min-width: 120px;">
        <v-col  cols="2" class="ma-0 pa-0 d-flex justify-space-between my-auto">
          <span class="d-flex">{{props.item.rating}}</span>
        </v-col>
        <v-col  cols="8" class="ma-0 pa-0 d-flex justify-space-between my-auto">
          <v-chip small v-if="props.item.reload">
            Перезачет
          </v-chip>
        </v-col>
        <v-col cols="2" class="ma-0 pa-0 d-flex justify-space-between my-auto">
          <v-tooltip top>
            <template v-slot:activator="{on, attrs}">
              <v-col cols="auto" class="ma-0 pa-0">
                <v-icon
                  class="ml-auto"
                  small
                  v-on="on"
                  v-bind="attrs"
                >
                  info
                </v-icon>
              </v-col>
            </template>
            <span>{{props.item.text_rating}}</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </template>
    <template v-slot:item.download_journal="props">
      <v-tooltip top>
        <template v-slot:activator="{on, attrs}">
          <v-btn icon v-on="on" @click="loadStatement(props.item.id, props.item.semester_name)">
            <v-icon color="blue">get_app</v-icon>
          </v-btn>
        </template>
        <span>Сохранить ведомость</span>
      </v-tooltip>
    </template>
    <template v-slot:loading>
      <v-skeleton-loader
        v-for="i in rows.length"
        :key="`sekeleton_${i}`"
        type="table-row"
      >
      </v-skeleton-loader>
    </template>
  </v-data-table>
</template>

<script>
import JournalTableArrowNavigateMixin from "@/mixins/JournalTableArrowNavigateMixin";
import {makeGetRequest} from "@/helper";
import urls from "@/urls/teacher"

export default {
  name: "JournalTable",
  mixins: [JournalTableArrowNavigateMixin],
  props: {
    can_update: {
      type: Boolean,
      required: true,
    },
    rows: {
      type: Array,
      required: true,
    },
    search: {
      type: String,
      required: true,
    },
    save_execute: {
      type: Boolean,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      required: true,
    },
  },
  data: function () {
    return {
      headers: [
        {text: 'Ведомость', value: 'download_journal', sortable: false, filterable: false, align: 'center'},
        {text: 'Предмет', value: 'semester_name', sortable: false, filterable: true},
        {text: 'Тип контроля', value: 'control_type', sortable: false, filterable: false, },
        {text: 'Промежуточный контроль', value: 'medium_control', sortable: false, filterable: false, },
        {text: 'Доп. опрос', value: 'additional', sortable: false, filterable: false, },
        {text: 'В семестре', value: 'rating_in_semester', sortable: false, filterable: false,},
        {text: 'Неявка', value: 'absence', sortable: false, filterable: false,},
        {text: 'Экзамен/ Зачет с оценкой', value: 'exam', sortable: false, filterable: false, width: '8%'},
        {text: 'Автомат', value: 'automate', sortable: false, filterable: false, width: '5%'},
        {text: 'Итоговая оценка', value: 'rating', sortable: false, filterable: false, width: '12%'},
      ],
    }
  },
  watch:{
  },
  computed: {
    rows_length() {
      return this.rows.length;
    },
  },
  methods:{
    loadStatement(individual_semester,semester_name){
      makeGetRequest(
        urls.URLS.INDIVIDUAL_STUDENT.STATEMENT(this.$route.params.student_id,{'individual_semester':individual_semester})
      )
        .then(resp => {
          return resp.blob()
        }).then(data => {
        let a = document.createElement('a');
        let blob = new Blob([data],
          { 'type':'application/vnd.ms-excel'});
        a.href = window.URL.createObjectURL(blob);
        a.download = 'Журнал ' + ' '+ semester_name +'.xlsx';
        a.click();
      });
    },
    isRowDisabled(item){
      return item.automate || item.absence  || item.reload
    },
    hasMiddlePointError(item){
      if(this.errors.length > 0){
        let idx = this.errors.findIndex(el => el.message.id === item.id)
        if(idx !== -1) {
          return true
        }
      }
      return false
    },
    hasAdditionalError(item){
      if(this.errors.length > 0){
        let idx = this.errors.findIndex(el => el.semester === item.semester_id)
        if(idx !== -1) {
          if(( this.errors[idx].message ).additional)
            return true
          else
            return false
        }
      }
      return false
    },
    getAdditionalError(item){
      if(this.errors.length > 0){
        let idx = this.errors.findIndex(el => el.semester === item.semester_id)
        if(idx !== -1)
          return (
            this.errors[idx].message
          ).additional;
      }
      return []
    },
    getMiddlePointError(item){
      if(this.errors.length > 0){
        let idx = this.errors.findIndex(el => el.message.id === item.id)
        if(idx !== -1)
          return (
            this.errors[idx].message
          ).value;
      }
      return []
    },
  },
  created() {

  }
}
</script>

<style scoped>

</style>
