
const accounts = String(process.env.VUE_APP_ACCOUNTS_PREFIX).replace('/', '');
const education = String(process.env.VUE_APP_EDUCATION_PREFIX).replace('/', '');
const organization = String(process.env.VUE_APP_ORGANIZATION_PREFIX).replace('/', '');
import DeanDetailedStudent from "../pages/student/Detail.vue"
import ListFinishSubject from "../pages/student/list/ListFinishSubject.vue"
import ListAcademicWorks from "../pages/student/list/ListAcademicWorks.vue"
import  AcademicWorkDetail from "../pages/student/AcademicWorkDetail.vue"
import DeanJournalFull from "../pages/student/DeanJournalFull.vue"
import ListAdmission from "@/modules/dean/pages/student/list/ListAdmissions.vue"
import DetailAdmission from "@/modules/dean/pages/student/AdmissionDetail.vue"
import PersonalCuriculum from "../pages/student/curriculum/PersonalCuriculum.vue"
import ApproveTransfer from "@/modules/dean/pages/transferStudents/ApproveTransfer";
import PortfolioStudent from "@/modules/dean/pages/student/portfolio/Portfolio";
import EduDoc from "@/modules/dean/pages/student/educationDocument/EduDoc";


import names from "@/modules/dean/routers/names"

export default ([
  {
    path: ':idStudent',
    name: names.STUDENT.DETAIL,
    component: DeanDetailedStudent,
    meta: {
      breadcrumb: 'Обучающийся',
      requiredAuth: true,
      instances: [
        accounts,
        education,
        organization
      ],
    },
    children:[
      {
        path: 'transfer',
        name: names.STUDENT.TRANSFER,
        component: ApproveTransfer,
        meta: {
          breadcrumb: 'Перевод в другую группу',
          requiredAuth: true,
          instances: [
            accounts,
            education,
            organization
          ],
        },
      },
      {
        path: 'curiculum',
        name: names.STUDENT.CURCULUM,
        component: PersonalCuriculum,
        meta: {
          breadcrumb: 'ПУП',
          requiredAuth: true,
          instances: [
            accounts,
            education,
            organization
          ],
        },
      },
      {
        path: 'admission',
        name: names.STUDENT.ADMISSIONS.LIST,
        component: ListAdmission,
        meta: {
          breadcrumb: 'Допуски',
          requiredAuth: true,
          instances: [
            accounts,
            education,
            organization
          ],
        },
        children: [
          {
            path: ':idAdmission',
            name: names.STUDENT.ADMISSIONS.DETAIL,
            component: DetailAdmission,
            meta: {
              breadcrumb: 'Допуск',
              requiredAuth: true,
              instances: [
                accounts,
                education,
                organization
              ],
            },
          },
        ]
      },
      {
        path: 'portfolio',
        name: names.STUDENT.PORTFOLIO,
        component: PortfolioStudent,
        meta: {
          breadcrumb: 'Портфолио',
          requiredAuth: true,
          instances: [
            accounts,
            education,
            organization
          ],
        },
      },
      {
        path: 'edu/doc',
        name: names.STUDENT.EDUDOC,
        component: EduDoc,
        meta: {
          breadcrumb: 'Список документов об образовании',
          requiredAuth: true,
          instances: [
            accounts,
            education,
          ],
        },
      },
      {
        path: 'journal',
        name: names.STUDENT.JOURNAL,
        component: DeanJournalFull,
        meta: {
          breadcrumb: 'Полный журнал',
          requiredAuth: true,
          instances: [
            accounts,
            education,
            organization
          ],
        },
      },
      {
        path: 'finish/subj',
        name: names.STUDENT.FINISHSUBJECT,
        component: ListFinishSubject,
        meta: {
          breadcrumb: 'Список пройденных дисциплин',
          requiredAuth: true,
          instances: [
            accounts,
            education,
            organization
          ],
        },
      },
      {
        path: 'academic/works',
        name:  names.STUDENT.ACADEMICWORKS.LIST,
        component: ListAcademicWorks,
        meta: {
          breadcrumb: 'Список работ',
          requiredAuth: true,
          instances: [
            accounts,
            education,
            organization
          ],
        },
        children:[

          {
            path: ':idAcademicWorks',
            name:  names.STUDENT.ACADEMICWORKS.DETAIL,
            component: AcademicWorkDetail,
            meta: {
              breadcrumb: 'Документ',
              requiredAuth: true,
              instances: [
                accounts,
                education,
                organization
              ],
            },
          },

        ],
      }
    ]


  }
  ])
