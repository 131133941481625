<template>
  <v-container style="height: 100%" v-if="$route.name === names.TRANSFER.LIST_FROM">
    <v-card flat>
      <v-toolbar
        :extended='$vuetify.breakpoint.xsOnly'
        elevation="0"
        color="style-color-main-gray"
        :style="{'height':`${$vuetify.breakpoint.xsOnly ? 120: 56}px`}"
      >
        <v-btn @click="opened_form = true" text><v-icon>add</v-icon>Перевод на другой факультет</v-btn>

        <template v-if="$vuetify.breakpoint.smAndUp" >
          <v-text-field
            dense
            placeholder="Поиск"
            v-model="search"
            @change="getItems()"
            messages="Для поиска нажмите Enter или кнопку поиск"
          >
          </v-text-field>
          <v-btn icon><v-icon>search</v-icon></v-btn>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                @click="getItems()"
                class="mr-2"
                medium
              >
                update
              </v-icon>
            </template>
            <span>Обновить данные</span>
          </v-tooltip>
        </template>

        <template v-slot:extension v-if='$vuetify.breakpoint.xsOnly'>
          <v-text-field
            dense
            placeholder="Поиск"
            v-model="search"
            @change="getItems()"
            messages="Для поиска нажмите Enter или кнопку поиск"
          >
          </v-text-field>
          <v-btn icon><v-icon>search</v-icon></v-btn>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                @click="getItems()"
                class="mr-2"
                medium
              >
                update
              </v-icon>
            </template>
            <span>Обновить данные</span>
          </v-tooltip>
        </template>
      </v-toolbar>

      <v-data-table
              :headers="headers"
              :items="items"
              :loading="loading"
              class="elevation-1"
              no-data-text="Нет данных"
              :options.sync="pagination"
              :server-items-length="total"
              :footer-props="{'items-per-page-options':[10,25,50], 'items-per-page-text': 'Число элементов'}"
            >
              <template v-slot:item="props">
                <tr @click="getDetail(props.item)" style="cursor: pointer" class="text-left">
                  <td class="justify-center">{{props.item.fio}}</td>
                  <td class="justify-center">{{props.item.from_department_name}}</td>
                  <td class="justify-center">{{props.item.to_department_name}}</td>
                  <td class="justify-center">{{props.item.group	}}</td>
                  <td class="justify-center">{{props.item.status}}</td>
                  <td class="justify-center">{{props.item.comment_from}}</td>
                  <td class="justify-center">{{props.item.comment_to}}</td>
                  <td class="justify-center">{{displayDate(props.item.initiated)}}</td>
                  <td class="justify-center"><span v-if="props.item.completed">{{displayDate(props.item.completed)}}</span></td>

                </tr>

              </template>
              <template slot="no-data">
                <v-card-text>Данные не загружены</v-card-text>
              </template>
            </v-data-table>
    </v-card>
      <TransferStudentFrom @addItem="addItem"
                           @ended="closedForm"
                           @refreshItem="refreshItem"
                           :opened_form="opened_form"
                           v-bind="selected"
                           v-if="opened_form"
      >
      </TransferStudentFrom>
  </v-container>
  <router-view :key="$route.fullPath" v-else></router-view>
</template>


<script>

  import UserDataMixin from '@/mixins/UserDataMixin'
  import {makeGetRequest} from "@/helper";
  import urls from '../../urls'
  import {generateListUidToName} from "@/helper/uidToName";
  import TransferStudentFrom from '../forms/TransferStudentFrom'
  import moment from "moment";
  import names from "@/modules/dean/routers/names"
  import dean_urls from "@/urls/dean";

  export default {
    name: "Groups",
    components: {TransferStudentFrom},
    mixins: [UserDataMixin],
    props: {
    },
    data () {
      return {
        search:'',
        URL_LIST: dean_urls.DEAN.TRANSFER.OUTGOING.LIST(this.$route.params.idFaculty),
        // urls.getUrl('dean|students|education|transfer|from-list', 'list')
        //   .replace('<faculty_uid>', this.$route.params.idFaculty),
        names:names,
        items: [],
        loading: true,
        total: 0,
        pagination: {},
        headers: [
          {text: 'Обучающийся', sortable: false, align: 'justify-center',},
          {text: 'Перевод из', sortable: false, align: 'justify-center',},
          {text: 'Перевод в', sortable: false, align: 'justify-center',},
          {text: 'Группа', sortable: false, align: 'justify-center',},
          {text: 'Статус', sortable: false, align: 'justify-center',},
          {text: 'Комментарий', sortable: false, align: 'justify-center',},
          {text: 'Комментарий', sortable: false, align: 'justify-center',},
          {text: 'Дата создания', sortable: false, align: 'justify-center',},
          {text: 'Дата изменения', sortable: false, align: 'justify-center',},
        ]
      }
    },

    created() {
      this.$store.commit('mainpage/changeTitle', 'Перевод на другой факультет');
    },
    watch: {
      pagination: {
        handler () {
          if(this.$route.name === names.TRANSFER.LIST_FROM)
            this.getItems()
        },
        deep: true
      }
    },
    methods: {
      displayDate(date, minutes = false) {
        let format = 'DD.MM.YYYY';
        if (minutes)
          format += ' HH:mm';
        return moment(date).format(format)
      },
      transferFrom(){

      },

      closedForm: function () {
        this.selected = {};
        this.opened_form = false
        this.getItems();
      },

      getDetail(item){
        this.$router.push({name: names.TRANSFER.DETAIL_FROM, params: {idDetail: item.id}})
      },
      addItem(){
        this.closedForm()
        this.getItems();
      },

      getItems () {
        this.loading = true
        let url = new URL(this.URL_LIST)
        let params = {};
        if(this.search.length > 0)
          params.fio = this.search;
        if(this.pagination.page !== undefined) {
          params.page = this.pagination.page;
          params.size = this.pagination.itemsPerPage;
        }
        else{
          params.page = "1";
          params.size = "10";
        }

        url.search = new URLSearchParams(params).toString();

        let sys = true
        makeGetRequest(url).then(resp => {
          if (!resp.ok) {
            sys = false
          }
          return resp.json()
        }) .then(json => {

          this.items = json.results;
          this.total = json.count
          this.loading = false
          for (let i in this.items) {
            this.$set(this.items[i], "fio","-")
          }
          generateListUidToName(this.items,"student","fio")
        })
      },
    }
  }


</script>
<style scoped>

</style>
