let two_col_row_classes = {
  label: ['md3'],
    value: ['md3']
};
let one_col_row_classes = {
  label: ['md6'],
    value: ['md6'],
};
let one_big_col_row_classes = {
  label: ['md3'],
  value: ['md9'],
};
let three_col_row_classes = {
  label: ['md2'],
    value: ['md2']
};
let four_col_row_classes = {
  label: ['md1'],
    value: ['md2']
};

export default {
  two_col_row_classes: two_col_row_classes,
  one_col_row_classes: one_col_row_classes,
  three_col_row_classes: three_col_row_classes,
  four_col_row_classes: four_col_row_classes,
  one_big_col_row_classes: one_big_col_row_classes,
};
