import helper from "./helper";
import {getUrlAllowed} from "@/helper/urls";
// import callable from "@/helper/callable";

let getEduUrlWithParameters = helper.getEduUrlWithParameters;
let makeEduMediaUrl = helper.makeEduMediaUrl;

// class UrlFinder extends callable.Callable {
//   name = ''
//   method = ''
//   parameterizer = () => {}
//   constructor(name, method, parameterizer=()=>{}) {
//     // console.log(`UrlFinder constructor ${name}`)
//     super();
//     this.name = name;
//     this.method = method;
//     this.parameterizer = parameterizer;
//   }
//   allowed(){
//     return helper.checkEduUrlAllowed(this.name, this.method)
//   }
//   allowedWithPars(...args){
//     let inputs = this.parameterizer(...args);
//     return helper.checkEduUrlAllowedWithParameters(this.name, this.method, inputs.parameters)
//   }
//   _call(...args){
//     let inputs = this.parameterizer(...args);
//     return helper.getEduUrlWithParameters(this.name, this.method, inputs.parameters, inputs.gets);
//   }
// }



export default {
  getEduUrlWithParameters,
  makeEduMediaUrl,
  getByRecievedUrlData: (url, gets=null) => getEduUrlWithParameters(
    url.basename,
    url.action,
    url.params,
    gets
  ),
  CATHEDRA: {
    LIST:
      (gets=null) => getEduUrlWithParameters(
        'administrative|relations|cathedra-list',
        'list',
        {},
        gets
      ),
    DETAIL:
      (cathedra_uid, gets=null) => getEduUrlWithParameters(
        "administrative|relations|cathedra-detail",
        'retrieve',
        {
          uid: cathedra_uid.toLowerCase()
        },
        gets
      ),
    GROUPS: {
      LIST: {
        OWNED:
          (cathedra_uid, gets=null) => getEduUrlWithParameters(
          'administrative|group|groups_from_cathedra-list',
          'list',
          {
            cathedra_uid: cathedra_uid.toLowerCase()
          },
          gets
        ),
        TAUGHT: (cathedra_uid, gets=null) => getEduUrlWithParameters(
          'administrative|group|study_groups-list',
          'list',
          {
            cathedra_uid: cathedra_uid.toLowerCase()
          },
          gets
        ),
        ARCHIVE: (cathedra_uid, gets=null) => getEduUrlWithParameters(
          'administrative|group|archive_group-list',
          'list',
          {
            cathedra_uid: cathedra_uid.toLowerCase()
          },
          gets
        ),
      },
      DETAIL: (cathedra_uid, group_id, gets=null) => getEduUrlWithParameters(
        'administrative|group|detail_group-detail',
        "retrieve",
        {
          cathedra_uid: cathedra_uid.toLowerCase(),
          id: group_id
        },
        gets
      ),
      ARCHIVE: {
        DETAIL: (cathedra_uid, group_id, gets = null) => getEduUrlWithParameters(
          'administrative|group|archive_group-detail',
          "retrieve",
          {
            cathedra_uid: cathedra_uid.toLowerCase(),
            id: group_id
          },
          gets
        ),
        SEMESTERS: (cathedra_uid, group_id, gets = null) => getEduUrlWithParameters(
          'administrative|group|archive_group-get-semesters',
          "get_semesters",
          {
            cathedra_uid: cathedra_uid.toLowerCase(),
            id: group_id
          },
          gets
        ),
        STUDENT: (cathedra_uid, group_id, gets = null) => getEduUrlWithParameters(
          'administrative|group|archive_group-get-all-student',
          "get_all_student",
          {
            cathedra_uid: cathedra_uid.toLowerCase(),
            id: group_id
          },
          gets
        ),
        STATEMENT: (cathedra_uid, group_id, gets = null) => getEduUrlWithParameters(
          'administrative|group|archive_group-get-statement-xlsx',
          'get_statement_xlsx',
          {
            cathedra_uid:cathedra_uid.toLowerCase(),
            id: group_id
          },
          gets
        ),
        JOURNAL:{
          SUBJECTS: (cathedra_uid, group_id, gets = null) => getEduUrlWithParameters(
            'administrative|group|archive_group-semester-journal-subjects',
            "semester_journal_subjects",
            {
              cathedra_uid: cathedra_uid.toLowerCase(),
              id: group_id
            },
            gets
          ),
          STUDENTS: (cathedra_uid, group_id, gets = null) => getEduUrlWithParameters(
            'administrative|group|archive_group-semester-journal-students',
            "semester_journal_students",
            {
              cathedra_uid: cathedra_uid.toLowerCase(),
              id: group_id
            },
            gets
          ),
        },
      },
      SCIENCE_DIRECTORS: {
        LIST: (cathedra_uid, group_id, gets=null) => getEduUrlWithParameters(
          'administrative|group|detail_group-get-student-supervisor',
          "get_student_supervisor",
          {
            cathedra_uid: cathedra_uid.toLowerCase(),
            id: group_id
          },
          gets
        ),
        SAVE: (cathedra_uid, group_id, gets=null) => getEduUrlWithParameters(
          'administrative|group|detail_group-update-student-supervisor',
          "update_student_supervisor",
          {
            cathedra_uid: cathedra_uid.toLowerCase(),
            id: group_id
          },
          gets
        ),
      },
      JOURNAL: {
        STATEMENT: (cathedra_uid, group_id, gets = null) =>
          getEduUrlWithParameters(
            'administrative|group|detail_group-get-statement-xlsx',
            "get_statement_xlsx",
            {
              cathedra_uid: cathedra_uid.toLowerCase(),
              id: group_id
            },
            gets
          ),
        LOAD: (cathedra_uid, group_id, gets = null) =>
          getEduUrlWithParameters(
            'administrative|group|detail_group-current-journal',
            "current_journal",
            {
              cathedra_uid: cathedra_uid.toLowerCase(),
              id: group_id
            },
            gets
          ),
        SEMESTER:{
          LOAD: (cathedra_uid, group_id, gets = null) =>
            getEduUrlWithParameters(
              'administrative|group|detail_group-journal-students',
              "current_journal",
              {
                cathedra_uid: cathedra_uid.toLowerCase(),
                id: group_id
              },
              gets
            ),
          SAVE: (cathedra_uid, group_id, gets = null) =>
            getEduUrlWithParameters(
              'administrative|group|detail_group-update-semester-rating',
              "update_semester_rating",
              {
                cathedra_uid: cathedra_uid.toLowerCase(),
                id: group_id
              },
              gets
            ),
        }
      },
      UPDATE_HALF_SEMESTER: (cathedra_uid, id, gets=null) => getEduUrlWithParameters(
        'administrative|group|study_groups-update-half-semester',
        'update_half_semester',
        {
          cathedra_uid: cathedra_uid.toLowerCase(),
          id: id,
        },
        gets,
      ),
    },
    STUDENT: {
      PORTFOLIO:{
        LIST:
          ( faculty_uid,student_id,gets = null) => getEduUrlWithParameters(
            'administrative|relations|student|achievement|documents-list',
            'list',
            {cathedra_uid:faculty_uid,
              education_id:student_id},
            gets
          ),
        CREATE:
          ( faculty_uid,student_id,gets = null) => getEduUrlWithParameters(
            'administrative|relations|student|achievement|documents-list',
            'list',
            {cathedra_uid:faculty_uid,
              education_id:student_id},
            gets
          ),
        UPDATE:
          ( faculty_uid,student_id,id,gets = null) => getEduUrlWithParameters(
            'administrative|relations|student|achievement|documents-detail',
            'update',
            {id: id,
              cathedra_uid:faculty_uid,
              education_id:student_id},
            gets
          ),
        DELETE:
          (faculty_uid,student_id,id, gets = null) => getEduUrlWithParameters(
            'administrative|relations|student|achievement|documents-detail',
            'destroy',
            {id: id,
              cathedra_uid:faculty_uid,
              education_id:student_id},
            gets
          ),
      },
      EDUDOC: {
        LIST: (cathedra_uid,student_id, gets=null) => getEduUrlWithParameters(
          'administrative|students|education|documents-list',
          'list',
          {
            cathedra_uid: cathedra_uid.toLowerCase(),
            education_id: student_id,
          },
          gets
        ),
      },
      OWNED: {
        LIST: (cathedra_uid, gets=null) => getEduUrlWithParameters(
          'administrative|students|educations|cathedra-list',
          'list',
          {
            cathedra_uid: cathedra_uid.toLowerCase()
          },
          gets
        ),
        DETAIL: (cathedra_uid, student_id, gets=null) => getEduUrlWithParameters(
          'administrative|students|educations|cathedra-detail',
          'retrieve',
          {
            cathedra_uid: cathedra_uid.toLowerCase(),
            id: student_id,
          },
          gets
        ),
      },
      TAUGHT: {
        LIST: (cathedra_uid, gets=null) => getEduUrlWithParameters(
          'administrative|students|educations|study-list',
          'list',
          {
            cathedra_uid: cathedra_uid.toLowerCase()
          },
          gets
        ),
        DETAIL: (cathedra_uid, student_id, gets=null) => getEduUrlWithParameters(
          'administrative|students|educations|study-detail',
          'retrieve',
          {
            cathedra_uid: cathedra_uid.toLowerCase(),
            id: student_id,
          },
          gets
        ),
      },
      CUTTICULUM: {
        FROM: (cathedra_uid, student_id, gets=null) => getEduUrlWithParameters(
          'administrative|students|education|from_cathedra-individual-curriculum',
          'individual_curriculum',
          {
            cathedra_uid: cathedra_uid.toLowerCase(),
            id: student_id,
          },
          gets
        ),
        NOT_FROM: (cathedra_uid, student_id, gets=null) => getEduUrlWithParameters(
          'administrative|students|education|not_from_cathedra-individual-curriculum',
          'individual_curriculum',
          {
            cathedra_uid: cathedra_uid.toLowerCase(),
            id: student_id,
          },
          gets
        ),
      },
      JOURNAL: {
        FROM: (cathedra_uid, student_id, gets=null) => getEduUrlWithParameters(
          'administrative|students|education|from_cathedra-full-journal',
          'full_journal',
          {
            cathedra_uid: cathedra_uid.toLowerCase(),
            id: student_id,
          },
          gets
        ),
        NOT_FROM: (cathedra_uid, student_id, gets=null) => getEduUrlWithParameters(
          'administrative|students|education|not_from_cathedra-full-journal',
          'full_journal',
          {
            cathedra_uid: cathedra_uid.toLowerCase(),
            id: student_id,
          },
          gets
        ),
      },
      WORKS: {
        LIST: (cathedra_uid, student_id, gets=null) => getEduUrlWithParameters(
          'administrative|individual|work-list',
          'list',
          {
            cathedra_uid: cathedra_uid.toLowerCase(),
            education_id: student_id,
          },
          gets
        ),
        CREATE: (cathedra_uid, student_id, gets=null) => getEduUrlWithParameters(
          'administrative|individual|work-list',
          'list',
          {
            cathedra_uid: cathedra_uid.toLowerCase(),
            education_id: student_id,
          },
          gets
        ),
        DETAIL: (cathedra_uid, student_id, id, gets=null) => getEduUrlWithParameters(
          'administrative|individual|work-detail',
          'retrieve',
          {
            cathedra_uid: cathedra_uid.toLowerCase(),
            education_id: student_id,
            id: id
          },
          gets
        ),
        UPDATE: (cathedra_uid, student_id, id, gets=null) => getEduUrlWithParameters(
          'administrative|individual|work-detail',
          'update',
          {
            cathedra_uid: cathedra_uid.toLowerCase(),
            education_id: student_id,
            id: id
          },
          gets
        ),
        DELETE: (cathedra_uid, student_id, id, gets=null) => getEduUrlWithParameters(
          'administrative|individual|work-detail',
          'destroy',
          {
            cathedra_uid: cathedra_uid.toLowerCase(),
            education_id: student_id,
            id: id
          },
          gets
        ),
      },
    },
    INDIVIDUAL_STUDENT: {
      LIST: (cathedra_uid, gets=null) => getEduUrlWithParameters(
        'administrative|students|individual|educations|cathedra-list',
        'list',
        {
          cathedra_uid: cathedra_uid.toLowerCase()
        },
        gets
      ),
      TEACHERS_LINK_SAVE: (cathedra_uid, id, gets=null) => getEduUrlWithParameters(
        'administrative|students|individual|educations|cathedra-update-teachers',
        'update_teachers',
        {
          cathedra_uid: cathedra_uid.toLowerCase(),
          id: id,
        },
        gets
      ),
      JOURNAL: (cathedra_uid, id, gets=null) => getEduUrlWithParameters(
        'administrative|students|individual|educations|cathedra-student-journals',
        'student_journals',
        {
          cathedra_uid: cathedra_uid.toLowerCase(),
          id: id,
        },
        gets
      ),
      SAVE_JOURNAL: (cathedra_uid, id, gets=null) => getEduUrlWithParameters(
        'administrative|students|individual|educations|cathedra-update-semester-rating',
        'update_semester_rating',
        {
          cathedra_uid: cathedra_uid.toLowerCase(),
          id: id,
        },
        gets
      ),
      STATEMENT:(cathedra_uid, id, gets=null) => getEduUrlWithParameters(
        'administrative|students|individual|educations|cathedra-get-statement-xlsx',
        'get_statement_xlsx',
        {
          cathedra_uid: cathedra_uid.toLowerCase(),
          id: id,
        },
        gets
      ),
    },
    TEACHERS: {
      LIST: (cathedra_uid, gets=null) => getEduUrlWithParameters(
        'administrative|relations|teacher-list',
        'list',
        {
          cathedra_uid: cathedra_uid.toLowerCase(),
        },
        gets
      ),
      DETAIL: (cathedra_uid, worker_uid, gets=null) => getEduUrlWithParameters(
        'administrative|relations|teacher-detail',
        'retrieve',
        {
          cathedra_uid: cathedra_uid.toLowerCase(),
          uid: worker_uid.toLowerCase(),
        },
        gets
      ),
      GROUPS: (cathedra_uid, worker_uid, gets=null) => getEduUrlWithParameters(
        'administrative|relations|teacher-get-teacher-groups',
        'get_teacher_groups',
        {
          cathedra_uid: cathedra_uid.toLowerCase(),
          uid: worker_uid.toLowerCase(),
        },
        gets
      ),

      STUDENTS: (cathedra_uid, worker_uid, gets=null) => getEduUrlWithParameters(
        'administrative|students|teacher|students-list',
        'list',
        {
          cathedra_uid: cathedra_uid.toLowerCase(),
          teacher_uid: worker_uid.toLowerCase(),
        },
        gets
      ),
      DIPLOMS: (cathedra_uid, worker_uid, gets=null) => getEduUrlWithParameters(
        'administrative|relations|teacher-get-teacher-scientific',
        'get_teacher_scientific',
        {
          cathedra_uid: cathedra_uid.toLowerCase(),
          uid: worker_uid.toLowerCase(),
        },
        gets
      ),
      SUBJECTS: (cathedra_uid, worker_uid, gets=null) => getEduUrlWithParameters(
        'administrative|relations|teacher-get-teacher-subjects',
        'get_teacher_subjects',
        {
          cathedra_uid: cathedra_uid.toLowerCase(),
          uid: worker_uid.toLowerCase(),
        },
        gets
      ),
      INDIVIDUAL_WORK: {
        WORK_LIST: (cathedra_uid, teacher_uid, gets = null) => getEduUrlWithParameters(
          'administrative|relations|teacher|work-list',
          'list',
          {
            cathedra_uid: cathedra_uid.toLowerCase(),
            teacher_uid: teacher_uid,
          },
          gets
        ),
        WORK_DETAIL: (id,cathedra_uid, teacher_uid, gets=null) => getEduUrlWithParameters(
          'administrative|relations|teacher|work-detail',
          'retrieve',
          {
            id: id,
            cathedra_uid: cathedra_uid.toLowerCase(),
            teacher_uid: teacher_uid,
          },
          gets
        ),
        ADD_WORK: (cathedra_uid, teacher_uid, gets = null) => getEduUrlWithParameters(
          'administrative|relations|teacher|work-list',
          'create',
          {
            cathedra_uid: cathedra_uid.toLowerCase(),
            teacher_uid: teacher_uid,
          },
          gets
        ),
        UPDATE_WORK: (id,cathedra_uid, teacher_uid, gets=null) => getEduUrlWithParameters(
          'administrative|relations|teacher|work-detail',
          'partial_update',
          {
            id: id,
            cathedra_uid: cathedra_uid.toLowerCase(),
            teacher_uid: teacher_uid,
          },
          gets
        ),
        WORK_DELETE: (id,cathedra_uid, teacher_uid, gets=null) => getEduUrlWithParameters(
          'administrative|relations|teacher|work-detail',
          'destroy',
          {
            id: id,
            cathedra_uid: cathedra_uid.toLowerCase(),
            teacher_uid: teacher_uid,
          },
          gets
        ),
      },

    },
    SUBJECT: {
      LIST: (cathedra_uid, gets=null) => getEduUrlWithParameters(
        'administrative|core|subjects|from_cathedra-list',
        'list',
        {
          cathedra_uid: cathedra_uid.toLowerCase()
        },
        gets
      ),
      LOAD_GROUPS: (cathedra_uid, subject_id, gets=null) => getEduUrlWithParameters(
        'administrative|core|subjects|from_cathedra-get-groups',
        'get_groups',
        {
          cathedra_uid: cathedra_uid.toLowerCase(),
          id: subject_id,
        },
        gets
      ),
      TEACHERS_LINK_SAVE: (cathedra_uid, subject_id, gets=null) => getEduUrlWithParameters(
        'administrative|core|subjects|from_cathedra-teacher',
        'teacher',
        {
          cathedra_uid: cathedra_uid.toLowerCase(),
          id: subject_id,
        },
        gets
      ),
    },
    ADMINS: {
      LIST:
        // {
        // name: 'administrative|relations|workers-list',
        // method: 'list',
        // caller (cathedra_uid, gets = null){
        //   console.log(this)
        //   return getEduUrlWithParameters(
        //     this.name,
        //     this.method,
        //     {
        //       cathedra_uid: cathedra_uid.toLowerCase()
        //     },
        //     gets
        //   )
        // },
        // allowed(cathedra_uid) {
        //   // console.log(cathedra_uid)
        //   // console.log(this)
        //   // console.log(this.method, this.name)
        //   return helper.allowedEduUrlByParameters(
        //     this.name,
        //     this.method,
        //     {
        //       cathedra_uid: cathedra_uid.toLowerCase()
        //     }
        //   )
        // }
      // },
        (cathedra_uid, gets=null) => getEduUrlWithParameters(
        'administrative|relations|workers-list',
        'list',
        {
          cathedra_uid: cathedra_uid.toLowerCase()
        },
        gets
      ),
      ADD_TO: (cathedra_uid, gets=null) => getEduUrlWithParameters(
          'administrative|relations|workers-list',
          'create',
          {
            cathedra_uid: cathedra_uid.toLowerCase()
          },
          gets
        ),
      REMOVE_FROM: (cathedra_uid, admin_id, gets=null) => getEduUrlWithParameters(
        'administrative|relations|workers-detail',
        'destroy',
        {
          cathedra_uid: cathedra_uid.toLowerCase(),
          id: admin_id
        },
        gets
      ),
    },
    CURRICULUM: {
      LIST: (cathedra_uid, gets=null) => getEduUrlWithParameters(
        'administrative|curriculum|curriculum-list',
        'list',
        {
          cathedra_uid: cathedra_uid.toLowerCase(),
        },
        gets
      ),
      DETAIL: (cathedra_uid, curriculum_id, gets=null) => getEduUrlWithParameters(
        'administrative|curriculum|curriculum-detail',
        'retrieve',
        {
          cathedra_uid: cathedra_uid.toLowerCase(),
          id: curriculum_id,
        },
        gets
      ),
    },
    ADMISSION:{
      FILE: (cathedra_uid, gets=null) => getEduUrlWithParameters(
        'administrative|individual|cathedra|tolerance-download-xlsx',
        'download_xlsx',
        {
          cathedra_uid: cathedra_uid,
        },
        gets,
      ),
      LIST: (cathedra_uid, gets=null) => getEduUrlWithParameters(
        'administrative|individual|cathedra|tolerance-list',
        'list',
        {
          cathedra_uid: cathedra_uid,
        },
        gets,
      ),
      DETAIL: (cathedra_uid, id, gets=null) => getEduUrlWithParameters(
        'administrative|individual|cathedra|tolerance-detail',
        'retrieve',
        {
          cathedra_uid: cathedra_uid,
          id: id
        },
        gets,
      ),
      REOPEN: (cathedra_uid, id, gets=null) => getEduUrlWithParameters(
        'administrative|individual|cathedra|tolerance-reopen',
        'reopen',
        {
          cathedra_uid: cathedra_uid,
          id: id
        },
        gets,
      ),
      CLOSE: (cathedra_uid, id, gets=null) => getEduUrlWithParameters(
        'administrative|individual|cathedra|tolerance-close',
        'close',
        {
          cathedra_uid: cathedra_uid,
          id: id
        },
        gets,
      ),
      SENDEMAIL: (cathedra_uid, gets=null) => getEduUrlWithParameters(
        'administrative|individual|cathedra|tolerance-send-notifications',
        'send_notifications',
        {
          cathedra_uid: cathedra_uid,
        },
        gets,
      ),
      CREATE: (cathedra_uid, id, gets=null) => getEduUrlWithParameters(
        'administrative|individual|cathedra|tolerance-create-tolerance',
        'create_tolerance',
        {
          cathedra_uid: cathedra_uid,
          id: id
        },
        gets,
      ),
      APPOINT_TEACHER: (cathedra_uid, gets=null) => getEduUrlWithParameters(
        'administrative|individual|cathedra|tolerance-appoint-teacher',
        'appoint_teacher',
        {
          cathedra_uid: cathedra_uid.toLowerCase()
        },
        gets
      ),
    },
  },

}
