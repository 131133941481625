<template>
  <v-container v-if="journal.loaded">
<!--    <DebugJsonComponent :json="$route.params"></DebugJsonComponent>-->
    <v-card>
      <v-toolbar :extended='$vuetify.breakpoint.xsOnly'
                 class="mb-2"
                 color="style-color-main-gray"
                 flat
                 :style="{'height':`${$vuetify.breakpoint.smAndUp ? 64: 150}px`} "
      >
        <v-toolbar-title>
          Полный журнал
        </v-toolbar-title>

        <template v-if="$vuetify.breakpoint.smAndUp">
          <v-spacer></v-spacer>
        </template>

        <template v-slot:extension v-if='$vuetify.breakpoint.xsOnly'>
          <v-col>
            <v-text-field class="pt-2" v-model="search" clearable placeholder="Поиск" hide-details
                          style="min-width: 100px;" prepend-inner-icon="search" dense hint="" filled></v-text-field>
          </v-col>
        </template>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-row>
        <v-col>
          <v-data-table
            :items="student"
            :headers="filteredHederJournal"
            dense
            :items-per-page="student.length"
            hide-default-footer
            no-data-text="Нет данных"
          >
            <template v-for="(head, i) of filteredHederJournal"  v-slot:[`header.${head.value}`]="{ header }">
              <v-row :key="`header_${i}`" dense align="center">
                <v-col>
                  {{header.text}}
                </v-col>
                <v-col cols="auto" v-if="header.semester_subject">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-on="on" v-bind="attrs" @click="loadStatement(header.semester_subject)">
                        <v-icon>
                          download
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>
                      Скачать
                    </span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </template>
            <template v-for="(head, i) of filteredHederJournal"  v-slot:[`item.${head.value}`]="{ item }">
              <span v-if="head.value == 'fio'">
                   {{ item.fio}}
              </span>
              <span v-else>
                {{item[head.value]}}
              </span>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
<!--      <DebugJsonComponent :json="$route.params"></DebugJsonComponent>-->
<!--      <DebugJsonComponent :json="journal"></DebugJsonComponent>-->
    </v-card>
  </v-container>

  <v-container v-else>
    <v-row>
      <v-col>
        <v-skeleton-loader
          height="50"
          type="list-item"
        >
        </v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-skeleton-loader
          height="200"
          type="card"
        >
        </v-skeleton-loader>
      </v-col>
    </v-row>
  </v-container>

</template>

<script>

import {loadData, makeGetRequest, makePostRequest, sendGetRequest, sendPostRequest} from "@/helper";
import DebugJsonComponent from "@/modules/core/components/DebugJsonComponent";
import urls from "@/urls/cathedras"
import TitledPageMixin from "@/mixins/TitledPageMixin";
import names from "../../routers/names";
import LeftMenuChangerMixin from "../../../../mixins/LeftMenuChangerMixin";

export default {
  name: "ArchiveBase",
  components: {
    DebugJsonComponent,
  },
  mixins: [TitledPageMixin, LeftMenuChangerMixin],
  watch: {
  },
  computed: {
    filteredHederJournal() {
      let header = []
      if(this.journal.subjects)
        if (this.journal.subjects.length > 0) {
          header.push({
            text: "ФИО",
            value: 'fio',
            width: '15%',
            control_type: '',
            semester_subject: '',
            sortable: false,
            filterable: false,
          })
          this.journal.subjects.forEach(sub => {
            header.push({
              text: sub.subject,
              control_type: sub.control_type,
              semester_subject: String(sub.semester),
              sortable: false,
              width: '10%',
              value: String(sub.semester),
              filterable: false,
            })
          })
        }
      return header
    }
  },
  data: function () {
    return {
      page_title: 'Итоговый журнал успеваемости',
      cathedra_uid: this.$route.params.cathedra_uid,
      group_id: this.$route.params.group_id,
      student: [],
      year_semester: null,
      errors: [],
      journal: {
        data: [],
        subjects: [],
        students: [],
        selected: [],
        loading: false,
        loaded: false,
      },
      search: '',
      nameGroup: '',
    }
  },
  methods: {
    getLeftMenu(){
      return [
        {
          title: 'Группа',
          icon: 'arrow_back_ios',
          included: false,
          router: {
            name: names.CathedraArchiveGroupsDetail,
            params: {
              group_id: this.$route.params.group_id,
              cathedra_uid: this.$route.params.cathedra_uid,
              archive: this.$route.params.archive,
              semester: this.$route.params.semester,
              reload_left_menu: true,
            },
            query: this.$route.query,
          }
        },
      ]
    },
    loadSubjects() {
      if (this.$route.query.year_semester === undefined){
        this.year_semester = null
      }
      else if (typeof this.$route.query.year_semester === "string"){
        this.year_semester = this.$route.query.year_semester
      }
      else{
        this.year_semester = this.$route.query.year_semester.value
      }
      loadData(
        urls.CATHEDRA.GROUPS.ARCHIVE.JOURNAL.SUBJECTS(this.cathedra_uid, this.group_id,{year_semester: this.year_semester}),
        res => {
          this.journal.subjects = res;
          this.loadStudentFios()
        }
      );
    },
    loadStudentFios() {
      loadData(
        urls.CATHEDRA.GROUPS.ARCHIVE.JOURNAL.STUDENTS(this.cathedra_uid, this.group_id,{year_semester: this.year_semester}),
        res => {
          this.journal.students = res;
          let st = []
          this.journal.students.forEach(student => {
            let userData = {
              "fio": student.fio,
              "id": student.id,
            }
            student.semesters.forEach(subj => {
              userData[subj.semester] = subj.rating
            })
            st.push(
              userData
            )
          })
          this.student = st
          this.student.sort((a,b) => a.fio.toUpperCase() > b.fio.toUpperCase() ? 1: -1)
          this.journal.loaded = true;
        }
      );
    },
    loadStatement(semester) {
      if (this.$route.query.year_semester === undefined){
        this.year_semester = null
      }
      else if (typeof this.$route.query.year_semester === "string"){
        this.year_semester = this.$route.query.year_semester
      }
      else{
        this.year_semester = this.$route.query.year_semester.value
      }
      let url = urls.CATHEDRA.GROUPS.ARCHIVE.STATEMENT(this.cathedra_uid, this.group_id, {semester: semester, year_semester: this.year_semester});
      let status = true;
      makeGetRequest(
        url
      ) .then(resp => {
        if (!resp.ok) {
          status = false
          alert("Не удалось загрузить файл")
        }
        return resp.blob();
      }) .then(blob => {
        if(status) {
          let a = document.createElement("a");
          a.href = URL.createObjectURL(blob);
          a.download = this.page_title + '.xlsx';
          a.click();
        }
      })
    },
  },
  created() {
    this.loadSubjects();
  }

}
</script>

<style scoped>
</style>

