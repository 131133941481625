<template>
  <v-app id="inspire">
    <v-content >
      <v-container fluid fill-height >
        <v-layout align-center column fill-heigh justify-center  >
          <div class="action">
            <semipolar-spinner
              :animation-duration="2000"
              :size="115"
              color="#1935ff"
            />
          </div>
        </v-layout>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
  import {SemipolarSpinner} from 'epic-spinners'

  export default {
    name: 'LoadSys',
    components: {
      SemipolarSpinner,
    },
    created() {

      setTimeout(() => {
        if (window.$cookies.isKey('userMuctr')) {

          this.$router.push({ name: 'Roles' });
        }else{

          let origin = document.location.origin;
          if(this.$route.query.redirect){
            origin = `${origin}?=redirect${this.$route.query.redirect}`
          }
          document.location.href = process.env.VUE_APP_LOGIN_HOST + `?from=${origin}`;
          // console.log(process.env.VUE_APP_LOGIN_HOST + `?from=${document.location.origin}${this.$route.query.redirect}`)
        }
      }, 500);
    }
  }
</script>

<style scoped>

</style>
