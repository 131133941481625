import StudentsDetail from "../pages/students/Base";
import names from "./names";
import cathedras from "../../../urls/cathedras";
import IndividualCurriculum from "../pages/students/IndividualCurriculum";
import Journal from "../pages/students/Journal";
import VKR from "../pages/students/VKR";
import WorkDetail from "../pages/students/works/Detail";
import WorkList from "../pages/students/works/List";
import Portfolio from "@/modules/cathedra/pages/students/portfolio/Portfolio"
import EduDocList from "@/modules/cathedra/pages/students/educationDocument/EduDoc"
const organization = String(process.env.VUE_APP_ORGANIZATION_PREFIX).replace('/', '');

export default ([
  {
    path: ':student_id(\\d+)/',
    name: names.CathedraStudentsDetail,//'Administrative|Cathedra|Students',
    component: StudentsDetail,
    meta: {
      requiredAuth: true,
      breadcrumb: 'Обучающийся',
      instances: [
        organization
      ]
    },
    children: [
      {
        path: 'graduation/thesis',
        name: names.CathedraStudentsDetailThesis,//'Administrative|Cathedra|Students',
        component: VKR,
        meta: {
          requiredAuth: true,
          breadcrumb: 'Выпускная квалификационная работа',
        },
        children: []
      },
      {
        path: 'works',
        name: names.CathedraStudentsDetailWorks,//'Administrative|Cathedra|Students',
        component: WorkList,
        meta: {
          requiredAuth: true,
          breadcrumb: 'Список работ',
        },
        children: [
          {
            path: ':id_work',
            name: names.CathedraStudentsDetailWorksDetail,//'Administrative|Cathedra|Students',
            component: WorkDetail,
            meta: {
              requiredAuth: true,
              breadcrumb: 'Работа',
            },
            children: []
          },

        ]
      },
      {
        path: 'individual/curriculum',
        name: names.CathedraStudentsDetailCurriculum,//'Administrative|Cathedra|Students',
        component: IndividualCurriculum,
        meta: {
          requiredAuth: true,
          breadcrumb: 'Индивидуальный план',
        },
        children: []
      },
      {
        path: 'journal',
        name: names.CathedraStudentsDetailJournal,//'Administrative|Cathedra|Students',
        component: Journal,
        meta: {
          requiredAuth: true,
          breadcrumb: 'Журнал',
        },
        children: []
      },
      {
        path: 'portfolio',
        name: names.CathedraStudentsPortfolio,//'Administrative|Cathedra|Students',
        component: Portfolio,
        meta: {
          requiredAuth: true,
          breadcrumb: 'Портфолио',
        },
        children: []
      },
      {
        path: 'doc/edu',
        name: names.CathedraStudentsDocEdu,//'Administrative|Cathedra|Students',
        component: EduDocList,
        meta: {
          requiredAuth: true,
          breadcrumb: 'Документы об образовании',
        },
        children: []
      },
    ]
  },
  // ...curriculum,
])


