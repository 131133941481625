import store from "@/store";
import teacher_names from "@/modules/teacher/routers/names";
import cathedra_names from "@/modules/cathedra/routers/names";
import educational_names from "@/modules/educationalManagement/routers/names";
import dean_names from "@/modules/dean/routers/names";
import editor_names from "@/modules/curriculumEditor/routers/names";

export function getInstMenu() {
  /**
   * Функция которая возвращает нижнее боковое меню инстанса
   * @return {Array} - списко всех подмодулей инстанса, отображаемых в левом нижнем меню
   */
  const education = String(process.env.VUE_APP_EDUCATION_PREFIX).replace('/', '');
  let menu = []
  if (store.getters['urls/getAllowed']( education, 'student|students|student', 'get'))
    menu.push({title: 'Обучающийся', icon: 'school', router: 'Profile', name: 'student',})
  if (store.getters['urls/getAllowed']( education, 'teacher|core|subject-list', 'list'))
    menu.push({title: 'Преподаватель', icon: 'account_box', router: teacher_names.BASE, name: 'education',})
  if (store.getters['urls/getAllowed']( education, 'dean|relations|faculty-list', 'list'))
    menu.push({title: 'Администрирование деканата', icon: 'account_balance', router: dean_names.FACULTIES, name: 'dean',})
  if (store.getters['urls/getAllowed']( education, 'administrative|relations|cathedra-list', 'list'))
    menu.push({title: 'Администрирование кафедры', icon: 'ballot', router: cathedra_names.CathedraList, name: 'cathedra',})
  if (store.getters['urls/getAllowed']( education, 'admin|core|course-list', 'list'))
    menu.push({title: 'Администратор', icon: 'settings', router: 'AdministrativeBreadcrumbs', name: 'Admin',})
  if (store.getters['urls/getAllowed']( education, 'worker|ministry|training_profile-list', 'list'))
    menu.push({title: 'Учебное управление', icon: 'assessment', router: educational_names.EDUCATIONAL.BASE, name: 'management',})
  if (store.getters['urls/getAllowed']( education, 'editor|curriculum|curriculum-list', 'list'))
    menu.push({title: 'Редактор учебных планов', icon: 'web', router: editor_names.CURRICULUM.LIST, name: 'curriculum',})
  return  menu
}

export function getInstNameByRouterName(router) {
  /**
   * Функция которая возвращает нижнее боковое меню инстанса
   * @return {Array} - списко всех подмодулей инстанса, отображаемых в левом нижнем меню
   */
  return getInstMenu().find(el => {return router === el.router}).name
}
