<template>
  <div class="page-content" style="margin: 0px; width: 100%">
    <PageHeader></PageHeader>
    <Dean v-if="is_self_route" :items="faculties"></Dean>
    <router-view v-else></router-view>
  </div>
</template>

<script>
  import {mapState} from 'vuex'
  import Dean from './Dean'
  import urls from '../urls'
  import url from "@/urls/dean";
  import {makeGetRequest} from "@/helper";
  import {getInstMenuDean} from "@/modules/dean/helper"
  import {getInstMenu, getInstNameByRouterName} from "@/helper/instances";
  import PageHeader from '@/modules/menus/PageHeader.vue'
  import Teacher from "../../teacher/pages/Teacher";
  import names from '@/modules/dean/routers/names'

  export default {
    name: "DeanBreadcrumbs",
    components: {Dean, PageHeader},
    data: function () {
      return {
        names:names,
        // selected: this.$route.params.idFaculty || null,
        URL_LIST: url.DEAN.LIST(),
        faculties: [],
        is_set_left_menu: false,
      }
    },
    computed: {
      is_self_route: function() {
        return this.$route.name === names.FACULTIES
      },
      ...mapState({
        mainpage: state => state.mainpage
      }),
      has_one_faculty(){
        return this.faculties.length === 1
      },
      // is_current()
      current: function (){
        return this.$route.params.idFaculty;
      },

    },
    methods: {
      hasSelected(current=undefined){
        return this.current !== undefined || current !== undefined
      },
      getCurrentName: function (current=undefined){
        if(this.hasSelected(current)){
          return this.faculties.find(el => el.uid === this.current || el.uid === current).name
        }
        return 'Факультет'
      },
      allowed(urlname, action) {
        return urls.getUrlAllowed(urlname, action);
      },
      setLeftMenu(current=undefined, finalizer=()=>{}){
        if(this.hasSelected(current)) {
          this.is_set_left_menu = true;
          this.$store.commit(
            'leftmenu/replaceItems',
            getInstMenuDean(this.current || current, this.getCurrentName(current))
          );
        }else{
          this.setFacultiesMenu();
        }
        finalizer();
      },
      setLoadingLeftMenu(){
        this.$store.commit('' +
          'leftmenu/replaceItems',
          [{title: 'Загрузка...', icon: 'view_quilt', router: {}},]
        );
      },
      setFacultiesMenu(){
        this.$store.commit('' +
          'leftmenu/replaceItems',
          [{title: 'Список факультетов', icon: 'view_quilt', router: names.FACULTIES},]
        );
      },
      loadLeftMenu(reload=true, finalizer=()=>{}) {
        let url = new URL(this.URL_LIST)
        let params = {};
        params.page = "1";
        params.size = "15";
        url.search = new URLSearchParams(params).toString();
        makeGetRequest(url).then(resp => {
          if (!resp.ok) {
            this.setFacultiesMenu();
          }
          return resp.json()
        }).then(json => {
          this.faculties = json
          if (reload) {
            this.setLeftMenu(undefined, finalizer());
          }
        })
      },
      checkRouterIsLeftMenuReplacer(router_name){
        if(String(router_name).indexOf(names.STUDENT._BASE) !== -1 && router_name !== names.STUDENT.LIST)
          return true
        if(String(router_name).indexOf(names.DEPARTAMENTS._BASE) !== -1 && router_name !== names.DEPARTAMENTS.LIST)
          return true
        return false
      }
    },
    beforeRouteUpdate(to, from, next) {
      if(to.params.idFaculty !== from.params.idFaculty){
        this.is_set_left_menu = false;
      }
      if(to.params.reload_left_menu){
        this.is_set_left_menu = false;
      }
      if (!this.checkRouterIsLeftMenuReplacer(to.name)) {
        if(!this.is_set_left_menu) {
          this.setLoadingLeftMenu();
          if (this.faculties.length === 0)
            this.loadLeftMenu(true, next);
          else
            this.setLeftMenu(to.params.idFaculty, next);
        } else {
          next()
        }
      }else{
        next();
      }
    },

    created() {
      this.$store.commit('leftmenu/enableLeftMenu');
      this.$store.commit('mainpage/changeTitle', 'Работник деканата');
      this.$store.commit('leftmenu/replaceInstances', getInstMenu());
      // this.$store.commit('leftmenu/activeRouterChange', this.$route.name);
      this.$store.commit('leftmenu/selectInstance', getInstNameByRouterName(names.FACULTIES));
      this.setLoadingLeftMenu();
      if (!this.checkRouterIsLeftMenuReplacer(this.$route.name)) {
        this.loadLeftMenu();
      }else{
        this.loadLeftMenu(false);
      }
    }
  }
</script>

<style scoped>


</style>
}
