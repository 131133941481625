<template>
  <v-app-bar :clipped-left="clipped" app color="primary" dark fixed height="60" class="letter_spacing_disabled">
    <v-app-bar-nav-icon class="black--text" @click.stop="leftMenuToggle" v-if="leftmenu.enabled">
      <v-icon color="white">menu</v-icon>
    </v-app-bar-nav-icon>
    <v-toolbar-title class="hidden-sm-and-down">
      <v-avatar class=""  size="35">
        <v-img :src="logo" alt="logo" aspect-ratio="1" contain></v-img>
      </v-avatar>
      <span class="white--text letter_spacing_disabled" v-if="maintoolbar.title">
        {{ maintoolbar.title }}
      </span>
      <span class="white--text letter_spacing_disabled" v-else>
          <router-link class="white--text " style="text-decoration: none;" :to="{ name: 'Main'}"> Электронная информационно-образовательная среда</router-link>
      </span>
    </v-toolbar-title>
    <v-spacer></v-spacer>

<!--УПАРАВЛЕНИЕ АККАУНТОМ | ОБНОВЛЕНИЯ : НАЧАЛО -->
    <v-toolbar-items>
    <v-toolbar-items class="hidden-sm-and-down">
      <v-btn href="https://wiki.muctr.ru/pages/viewpage.action?pageId=40927355&preview=/40927355/42304386/%D0%9A%D1%80%D0%B0%D1%82%D0%BA%D0%B0%D1%8F%20%D0%B8%D0%BD%D1%81%D1%82%D1%80%D1%83%D0%BA%D1%86%D0%B8%D1%8F%20%D0%BF%D0%BE%20%D0%B8%D1%81%D0%BF%D0%BE%D0%BB%D1%8C%D0%B7%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D1%8E%20%D1%81%D0%B8%D1%81%D1%82%D0%B5%D0%BC%D1%8B.docx" class="white--text letter_spacing_disabled" target="_blank" text mr-5>
        Инструкция
      </v-btn>
    </v-toolbar-items>
    <v-toolbar-items :key="i" class="hidden-sm-and-down" v-for="(item, i) in maintoolbar.mainInstances">
      <v-btn @click="dispatchRouter(item)" class="white--text letter_spacing_disabled" text mr-5 >
        <span v-if="item.router">
          {{item.title}}
        </span>
        <span v-else>
          {{item.title}}
        </span>
      </v-btn>
    </v-toolbar-items>

    <v-menu bot flat offset-y>
      <template v-slot:activator="{ on }">
        <v-btn class="white--text letter_spacing_disabled" text mr-5 v-on="on">
          Сервисы
          <v-icon>arrow_drop_down</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item
          v-for="item in maintoolbar.secondInstances"
          :key = item.title
          @click="routerPath(item)">

          <v-list-item-title v-text = "item.title"></v-list-item-title>

        </v-list-item>
      </v-list>

    </v-menu>

    <!--КНОПКА АВТОРИЗАЦИИ : НАЧАЛО-->
    <v-menu bot offset-y v-if="isUserAuthenticated">
      <template v-slot:activator="{ on }">
        <v-btn class="white--text letter_spacing_disabled" text v-on="on">
          <v-avatar size="30">
            <img :src="NotAvatar" alt="avatar"
              v-if="maintoolbar.userAvatar== null ">
            <v-img :src="maintoolbar.userAvatar"
                    v-else></v-img>
          </v-avatar>
          <span v-if="isUserAuthenticated  && $vuetify.breakpoint.width > 500" class="pa-1" >
              {{ user.username }}
          </span>
          <v-icon>arrow_drop_down</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item :key="i" v-for="(item, i) in maintoolbar.userActions">
          <v-list-item-title>
            <router-link :to="{ name: item.route}">
              {{ item.title }}
            </router-link>
          </v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-title @click="logout" style="text-decoration: underline; cursor:pointer">
            Выйти
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <!--КНОПКА АВТОРИЗАЦИИ : КОНЕЦ-->
    <v-toolbar-items v-else>

      <v-btn  @click="routerLogin"  class="white--text letter_spacing_disabled" text mr-5>
        Войти
      </v-btn>
    </v-toolbar-items>

    </v-toolbar-items>
    <Feedback>
      <template v-slot:activator="{on, attrs}">
        <v-btn fab dark absolute color="grey darken-4" bottom right x-small v-on="on" v-bind="attrs">
          <v-icon color="orange lighten-3">feedback</v-icon>
        </v-btn>
      </template>
    </Feedback>
    <v-progress-linear v-if="maintoolbar.is_page_loading" indeterminate class="ma-0" color="black" style="position:fixed !important; top: 0; left: 0"></v-progress-linear>
  </v-app-bar>
</template>

<script>
  import {mapState,mapGetters} from 'vuex'
  import logo from "@/assets/logo.png";
  import NotAvatar from "@/assets/NotAvatar.png";
  import {makeGetRequest, makeProfileUrl} from "../../helper";
  import urls from "../student/urls";
  import Feedback from "@/modules/core/components/Feedback";

  export default {
    name: "topmenu",
    components: {Feedback},
    data() {
      return {
        clipped: true,
        drawer: true,
        logo: logo,
        NotAvatar: NotAvatar,
        feedback: {
          open: false
        }
      }
    },
    computed: {
      ...mapGetters({
        isUserAuthenticated: 'user/isAuthenticate'
      }),
      ...mapState({
        leftmenu: state => state.leftmenu,
        maintoolbar: state => state.maintoolbar,
        user: state => state.user,
      }),
    },
    methods: {
      routerLogin(){
        document.location.href = process.env.VUE_APP_LOGIN_HOST;
      },
      routerPath(item){
        if (item.router)
          this.$router.push({path: item.router});
        else if (item.url){
            window.open(item.url,'_blank');
        } else{
          this.$router.push({name: item.name});
        }
      },
      dispatchRouter(item) {
        if (item.router)
          this.$router.push({name: item.router});
      },
      leftMenuToggle() {
        this.$store.commit('leftmenu/toggleVisible');
      },
      logout: function () {
        this.$store.commit('urls/clearUrls');
        this.$store.commit('leftmenu/dissable');
        this.$store.commit('leftmenu/replaceItems', []);
        // console.log('here');
        this.$store.dispatch('user/AUTH_LOGOUT')
          .then(() => {
            document.location.href = process.env.VUE_APP_LOGIN_HOST
          });
      },
    },
    created() {
    },
    beforeRouteUpdate(to, from, next) {
    }
  }
</script>

<style scoped>
  .letter_spacing_disabled{
    letter-spacing: 0 !important;
  }
</style>
