import {addGetParameters} from "@/helper";

export default {
  data() {
    return {
      // search: {
      //   value: '',
      //   parameter: 'q',
      //   on_change: false,
      //   in_process: false,
      // },
      // pagination: {
      //   current: null,
      // },
    }
  },
  watch: {},
  methods: {
    // getCurrentUrl() {
    //   return this.pagination.current
    // },
    // firster() {
    //   this.pagination.page = 2;
    // },
    // nexter() {
    //   this.pagination.page += 1;
    // },
    // searchData: function () {
    //   console.log('MAYBE HERE BLYAT SUKA');
    //   this.search.in_process = true;
    //   this.cleanPaginationPageData();
    //   this.loadData(
    //     this.addGetParameter(
    //       this.addGetParameter(
    //         this.getCurrentUrl(),
    //         this.pagination.page_name,
    //         1,
    //       ),
    //       this.search.parameter,
    //       this.search.value,
    //     ),
    //     () => {
    //       console.log('here')
    //       this.cleanPaginationPageData();
    //       this.searchExecuted();
    //     },
    //   )
    // },
    // searchExecuted(){
    //   if(this.search.in_process)
    //     this.search.in_process = false;
    // },
    // isSearch(){
    //   return this.search.in_process;
    // },
    // addGetParameters(url, parameters){
    //   return addGetParameters(url, parameters)
    // },
    // addGetParameter(url, key, value){
    //   return addGetParameters(url, {
    //     [key]: value,
    //   })
    // },
  },
  created() {
    if(this.search.on_change)
      this.$watch('search.value', () => {
        this.searchData()
      })
  }

}
