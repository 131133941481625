<template>

    <AdmissionTemplate :admission="admission" :issued_by="true" :btnPrint="true" >
    </AdmissionTemplate>


</template>

<script>
import moment from 'moment'
import AdmissionTemplate from '@/modules/templates/admission/AdmissionTemplate.vue'
import {loadData, makeDeleteRequest, makeGetRequest} from "@/helper";
import urls from "@/urls/dean"
import {generateUidToName, uidToPositionHelds} from "@/helper/uidToName";
import Admission from "@/modules/student/forms/Admission.vue"
import UserDataMixin from "@/mixins/UserDataMixin";
import UserDataFormMixin from "@/mixins/UserDataFormMixin";
import names from "@/modules/dean/routers/names";

export default {
  name: "DetailAdmission",
  components: {
    AdmissionTemplate,
    Admission
  },
  mixins: [UserDataMixin,UserDataFormMixin],
  data: function () {
    return {
      admission: undefined,
      URL_DETAIL: urls.DEAN.ADMISSION.DETAIL(this.$route.params.idFaculty, this.$route.params.idAdmission)
    }
  },
  methods: {

    loadData( ){
      let url = new URL(this.URL_DETAIL)
      let params = {};
      url.search = new URLSearchParams(params).toString();

      let sys = true;
      makeGetRequest(url).then(resp => {
        if (!resp.ok) {
          this.$router.push({name: names.STUDENT.ADMISSIONS.LIST})
        }
        return resp.json()
      }).then(json => {
        this.admission = json
        this.$set(this.admission, "fio_teacher", "Не указан")
        generateUidToName(this.admission, "teacher", "fio_teacher")

        this.$set(this.admission, "fio_student", "Не указан")
        generateUidToName(this.admission, "student", "fio_student")

        this.$set(this.admission, "fio_issued_by", "Не указан")
        if(this.admission.issued_by)
          uidToPositionHelds(this.admission, "issued_by", "fio_issued_by")


      })
    },

  },
  created() {
    this.loadData()
  }
}
</script>

<style scoped>

</style>
